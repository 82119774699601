import { HOURLY_SALARY_DATA, HOURLY_SALARY_GET_DATA, HOURLY_SALARY_ADD_DATA, HOURLY_SALARY_UPDATE_DATA, HOURLY_SALARY_DELETE_DATA, } from './Constants'
const initialState = {
    loading: false,
    error: null,
    hourlySalaries: [],
    hourlySalary:{}
}
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case HOURLY_SALARY_DATA.HOURLY_SALARY_DATA_LOAD:
            return {
                ...state,
                loading: true,
                hourlySalaries:[],
                error:null
            }
        case HOURLY_SALARY_DATA.HOURLY_SALARY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                hourlySalaries:action.hourlySalaries
            }
        case HOURLY_SALARY_DATA.HOURLY_SALARY_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        //load single
        case HOURLY_SALARY_GET_DATA.HOURLY_SALARY_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                hourlySalary:{},
                error:null
            }                

        case HOURLY_SALARY_GET_DATA.HOURLY_SALARY_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                hourlySalary:action.hourlySalary
            }                

        case HOURLY_SALARY_GET_DATA.HOURLY_SALARY_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case HOURLY_SALARY_ADD_DATA.HOURLY_SALARY_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case HOURLY_SALARY_ADD_DATA.HOURLY_SALARY_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case HOURLY_SALARY_ADD_DATA.HOURLY_SALARY_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case HOURLY_SALARY_UPDATE_DATA.HOURLY_SALARY_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case HOURLY_SALARY_UPDATE_DATA.HOURLY_SALARY_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case HOURLY_SALARY_UPDATE_DATA.HOURLY_SALARY_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case HOURLY_SALARY_DELETE_DATA.HOURLY_SALARY_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case HOURLY_SALARY_DELETE_DATA.HOURLY_SALARY_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case HOURLY_SALARY_DELETE_DATA.HOURLY_SALARY_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        
        default:
            return {
                ...state
            }
    }
}
export default Reducer