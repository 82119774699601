import { useState } from "react";
import { useDispatch } from "react-redux";
import { CANCEL, SAVE } from "../constants/Constants";
import DefaultButton from "./DefaultButton";
import Modalpopup from "./Modalpopup";

const ImportModal = ({ show, handleClose, tableName, modalTitle, importAction, loadData=null ,isFormuplod =false, dyamicFormData = null}) => {

    const [csvFile, setCSVFile] = useState(null);
    const [fileError, setFileError] = useState(false);
    const dispatch = useDispatch();
    console.log("isFormuplod",isFormuplod, "dyamicFormData", dyamicFormData)
    let [selectedForm, setSelectedForm] = useState(null);
    console.log("selectedForm", selectedForm)
    const handleAddImport = (e) => {
        e.preventDefault();
        if (csvFile !== null) {
            let formData = new FormData();
            formData.append('csv_file', csvFile);
            formData.append('table', tableName);
            
            formData.append('form', selectedForm);
            let request = {
                data: formData
            }
            handleClose()
            dispatch(importAction(request));
            if (loadData != null) {
                dispatch(loadData());
            }
        } else {
            setFileError(true);
        }
    }

    return (
        <Modalpopup
            show={show}
            handleClose={handleClose}
            actionTitle={modalTitle?? "Import"}
            sampleFile="yes"
        >
            <form className="row g-4">
                {isFormuplod && (
                <div className="col-md-6">
                    <label htmlFor="form_data" className="form-label">
                        Select Form <span className="error-text">*</span>
                    </label>

                    <select  style={{ width: 200 }} className="form-control"
                    onChange={(e) => { setSelectedForm(Number.parseInt(e.target.value)) }} >
                        <option value={0}> Select</option>
                        {dyamicFormData.map((form) =>
                            <option key={form.id} value={form.id} >{form.name}</option>
                        )
                        }
                    </select>
                    <span className="error-text">
                        {fileError ? (
                            <div>Please select form </div>
                        ) : null}
                    </span>
                </div>
                )}
                <div className="col-md-6">
                    <label htmlFor="import_company" className="form-label">
                        Upload CSV File <span className="error-text">*</span>
                    </label>
                    <input
                        type="file"
                        className="form-control"
                        id="import_company"
                        name="import_company"
                        onChange={(event) => { setCSVFile(event.target.files[0]) }}
                    />
                    <span className="error-text">
                        {fileError ? (
                            <div>Please upload file</div>
                        ) : null}
                    </span>
                </div>
                <div className="col-md-12">
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={handleClose}
                    />
                    <DefaultButton
                        buttonClass={"btn btn-secondary default-btn-color"}
                        buttonTitle={SAVE}
                        actionBlock={handleAddImport}
                    />
                </div>
            </form>
        </Modalpopup>
    )
}
export default ImportModal