import {
    TERM_REASON_DATA,
    TERM_REASON_GET_DATA,
    TERM_REASON_ADD_DATA,
    TERM_REASON_UPDATE_DATA,
    TERM_REASON_DELETE_DATA,
} from './Constants'


//For listing
const loadTermReasonDataRequest = () => ({
    type: TERM_REASON_DATA.TERM_REASON_DATA_LOAD,
});
const setTermReasonDataSuccess = (termReasons) => ({
    type: TERM_REASON_DATA.TERM_REASON_DATA_SUCCESS,
    termReasons,
});
const setTermReasonDataFail = (error) => ({
    type: TERM_REASON_DATA.TERM_REASON_DATA_FAIL,
    error,
});

//for details
const loadTermReasonGetDataRequest = (id) => ({
    type: TERM_REASON_GET_DATA.TERM_REASON_GET_DATA_LOAD,
    id,
});
const setTermReasonGetDataSuccess = (response) => ({
    type: TERM_REASON_GET_DATA.TERM_REASON_GET_DATA_SUCCESS,
    termReason: response.termReason[0],
});
const setTermReasonGetDataFail = (error) => ({
    type: TERM_REASON_GET_DATA.TERM_REASON_GET_DATA_FAIL,
    error,
});

//For add 
const loadTermReasonAddDataRequest = (request) => ({
    type: TERM_REASON_ADD_DATA.TERM_REASON_ADD_DATA_LOAD,
    request,
});
const setTermReasonAddDataSuccess = (termReason) => ({
    type: TERM_REASON_ADD_DATA.TERM_REASON_ADD_DATA_SUCCESS,
    termReason,
});
const setTermReasonAddDataFail = (error) => ({
    type: TERM_REASON_ADD_DATA.TERM_REASON_ADD_DATA_FAIL,
    error,
});

//update
const loadTermReasonUpdateDataRequest = (request) => ({
    type: TERM_REASON_UPDATE_DATA.TERM_REASON_UPDATE_DATA_LOAD,
    request,
});
const setTermReasonUpdateDataSuccess = (termReason) => ({
    type: TERM_REASON_UPDATE_DATA.TERM_REASON_UPDATE_DATA_SUCCESS,
    termReason,
});
const setTermReasonUpdateDataFail = (error) => ({
    type: TERM_REASON_UPDATE_DATA.TERM_REASON_UPDATE_DATA_FAIL,
    error,
});

//Delete
const loadTermReasonDeleteDataRequest = (request) => ({
    type: TERM_REASON_DELETE_DATA.TERM_REASON_DELETE_DATA_LOAD,
    request,
});
const setTermReasonDeleteDataSuccess = (termReason) => ({
    type: TERM_REASON_DELETE_DATA.TERM_REASON_DELETE_DATA_SUCCESS,
    termReason,
});
const setTermReasonDeleteDataFail = (error) => ({
    type: TERM_REASON_DELETE_DATA.TERM_REASON_DELETE_DATA_FAIL,
    error,
});


export {
    loadTermReasonDataRequest, setTermReasonDataSuccess, setTermReasonDataFail,
    loadTermReasonGetDataRequest, setTermReasonGetDataSuccess, setTermReasonGetDataFail,
    loadTermReasonAddDataRequest, setTermReasonAddDataSuccess, setTermReasonAddDataFail,
    loadTermReasonUpdateDataRequest, setTermReasonUpdateDataSuccess, setTermReasonUpdateDataFail,
    loadTermReasonDeleteDataRequest, setTermReasonDeleteDataSuccess, setTermReasonDeleteDataFail,
}