import { useState } from "react"


function MenuCollapsableItem({ parent, children, className, hasLeftArrow = false, hasRightArrow = false }) {
    let [isOpen, setIsOpen] = useState(false)
    return <li className={className}>
        <button className={"sidebar-button "} onClick={() => { setIsOpen(!isOpen) }}>
            {parent}
            {hasLeftArrow ?
                <div className="parent-icon"><i className={isOpen ? "fas fa-caret-down" : "fas fa-caret-right"}></i></div> : ''}
            {hasRightArrow ?
                <div className="right-arrow-icon"><i className={isOpen ? "fas fa-caret-down rightArrow arrow-flipped" : "fas fa-caret-down rightArrow"}></i></div> : ''}
        </button>
        <ul className={isOpen ? 'mm-collapse mm-show' : 'mm-collapse'}>
            {children}
        </ul>
    </li>
}
export default MenuCollapsableItem