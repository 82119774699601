import { CHANNEL_TYPE_GET_DATA, CHANNEL_TYPE_GET_DETAILS_DATA, CHANNEL_TYPE_UPDATE_DATA, CHANNEL_TYPE_DELETE_DATA, CHANNEL_TYPE_ADD_DATA } from "./Constants";

//for listing
const loadChannelTypeGetData = () => ({
  type: CHANNEL_TYPE_GET_DATA.CHANNEL_TYPE_GET_DATA_LOAD
})
const setChannelTypeGetDataSuccess = (channelTypes) => ({
  type: CHANNEL_TYPE_GET_DATA.CHANNEL_TYPE_GET_DATA_SUCCESS,
  channelTypes
})
const setChannelTypeGetDataFail = (error) => ({
  type: CHANNEL_TYPE_GET_DATA.CHANNEL_TYPE_GET_DATA_FAIL,
  error
})



//For add ChannelType
const loadChannelTypeAddDataRequest = (request) => ({
  type: CHANNEL_TYPE_ADD_DATA.CHANNEL_TYPE_ADD_DATA_LOAD,
  request
})
const setChannelTypeAddDataSuccess = (channelType) => ({
  type: CHANNEL_TYPE_ADD_DATA.CHANNEL_TYPE_ADD_DATA_SUCCESS,
  channelType
})

const setChannelTypeAddDataFail = (error) => ({
  type: CHANNEL_TYPE_ADD_DATA.CHANNEL_TYPE_ADD_DATA_FAIL,
  error
})


//For Edit ChannelType
const loadChannelTypeUpdateDataRequest = (request) => ({
  type: CHANNEL_TYPE_UPDATE_DATA.CHANNEL_TYPE_UPDATE_DATA_LOAD,
  request
})

const setChannelTypeUpdateDataSuccess = (channelType) => ({
  type: CHANNEL_TYPE_UPDATE_DATA.CHANNEL_TYPE_UPDATE_DATA_SUCCESS,
  channelType
})

const setChannelTypeUpdateDataFail = (error) => ({
  type: CHANNEL_TYPE_UPDATE_DATA.CHANNEL_TYPE_UPDATE_DATA_FAIL,
  error
})


//To Get Channel Details
const loadChannelTypeGetDetailsRequest = (id) => ({
  type: CHANNEL_TYPE_GET_DETAILS_DATA.CHANNEL_TYPE_GET_DETAILS_DATA_LOAD,
  id
})

const setChannelTypeGetDetailsSuccess = (response) => ({
  type: CHANNEL_TYPE_GET_DETAILS_DATA.CHANNEL_TYPE_GET_DETAILS_DATA_SUCCESS,
  channelType: response[0]
})

const setChannelTypeGetDetailsFail = (error) => ({
  type: CHANNEL_TYPE_GET_DETAILS_DATA.CHANNEL_TYPE_GET_DETAILS_DATA_FAIL,
  error
})


//Delete ChannelType
const loadChannelTypeDeleteDataRequest = (request) => ({
  type: CHANNEL_TYPE_DELETE_DATA.CHANNEL_TYPE_DELETE_DATA_LOAD,
  request,
});

const setChannelTypeDeleteDataSuccess = (channelType) => ({
  type: CHANNEL_TYPE_DELETE_DATA.CHANNEL_TYPE_DELETE_DATA_SUCCESS,
  channelType,
});

const setChannelTypeDeleteDataFail = (error) => ({
  type: CHANNEL_TYPE_DELETE_DATA.CHANNEL_TYPE_DELETE_DATA_FAIL,
  error,
});

export {
  loadChannelTypeGetData,
  setChannelTypeGetDataSuccess,
  setChannelTypeGetDataFail,
  loadChannelTypeAddDataRequest,
  setChannelTypeAddDataSuccess,
  setChannelTypeAddDataFail,
  loadChannelTypeUpdateDataRequest,
  setChannelTypeUpdateDataSuccess,
  setChannelTypeUpdateDataFail,
  loadChannelTypeGetDetailsRequest,
  setChannelTypeGetDetailsSuccess,
  setChannelTypeGetDetailsFail,
  loadChannelTypeDeleteDataRequest,
  setChannelTypeDeleteDataSuccess,
  setChannelTypeDeleteDataFail,
}