
import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select';

export function DropdownFilter({ field, filterType, setFilter, existingFilter, options }) {

    let [type1, setType1] = useState([]);

    useEffect(() => {
        if (existingFilter?.filter) {
            setType1(JSON.parse(existingFilter.filter))
        } else {
            setType1('')
        }
    }, [existingFilter])

    useEffect(() => {
        let filter = {};
        if (type1 && type1.filter(v=>v!=='').length) {
            filter = {
                filterType: "dropdown",
                type: 'inArray',
                filter: JSON.stringify(type1)
            }
        }
        changeParentFilter(filter)
    }, [type1])

    function changeParentFilter(filter) {
        setFilter((oldFilter) => {
            if (JSON.stringify(oldFilter?.[field]) === JSON.stringify(filter)) {
                return oldFilter
            }
            let newFilter = { ...oldFilter }
            newFilter[field] = { ...filter }
            return newFilter;
        })
    }
    if (type1.length > 0) {
        // console.log(type1.length)
        // console.log(JSON.stringify(type1))
        // console.log(type1.split('|').map((value)=>({ value, label: value })))
    }
    return <div>
        <Select
            isMulti
            value={type1 ? type1.filter((value) => value != '').map((value) => ({ value, label: value })) : null}
            onChange={(event) => {
                console.log(event)
                setType1(event.map(v=>v.value));
            }}
            options={[...options.map((option => ({ value: option, label: option })))]}
        />
    </div>
}