import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import FormTextField from "../../../shared/components/FormTextField";
import { CANCEL, DIVISION, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, HIRE_STATUS, HOURLY_SALARY, JOB_TITLE, MARKET, PART_FULL_TIME, REGION, SAVE, STORE } from "../../../shared/constants/Constants";
import { loadJobTitlesDataRequest } from "../../jobtitles/redux/Actions";
import { loadDepartmentDataRequest } from "../../employee_departments/redux/Actions";
import { loadTermReasonDataRequest } from "../../term-reason/redux/Actions";
import { loadEmployeeStatusDataRequest } from "../../employee_status/redux/Actions";
import { loadStoreDataRequest } from "../../stores/redux/Actions";
import { loadMarketDataRequest } from "../../market/redux/Actions";
import { loadRegionDataRequest } from "../../region/redux/Actions";
import { loadDivisionDataRequest } from "../../division/redux/Actions"
import { loadHireStatusDataRequest } from "../../hire-status/redux/Actions";
import { loadEmployeeDataRequest } from "../redux/Actions";
import { loadFullPartTimeDataRequest } from "../../full_part_time_status/redux/Actions";
import { loadHourlySalaryDataRequest } from "../../hourly_salary_status/redux/Actions";

const EmployeeFormAdd = ({ employee, submit }) => {

    let navigate = useNavigate();
    let dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        const requiredValues = [
            'employee_id',
            'emp_status',
            'email_work',
            'hire_status',
            'job_title_id',
            'first_name',
            'last_name',
            'hire_date'
        ]
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(values.email_work)) {
            errors.email_work = 'Invalid email'
        }
        for (let requiredValue of requiredValues) {
            if (!values[requiredValue]) {
                errors[requiredValue] = "Required"
            }
        }

        console.log(errors)
        return errors;
    };

    let { jobtitles } = useSelector((state) => state.jobtitle);
    let { stores } = useSelector(store => store.store);
    let { markets } = useSelector(store => store.market);
    let { regions } = useSelector(store => store.region);
    let { divisions } = useSelector(store => store.division);
    let { departments } = useSelector((state) => state.department);
    let { termReasons } = useSelector((state) => state.termReason);
    let { employeeStatuses } = useSelector((state) => state.employeeStatus)
    let { hireStatuses } = useSelector((state) => state.hireStatus);
    let { active_employees } = useSelector((state) => state.employee);
    let { fullPartTimes } = useSelector((state) => state.fullPartTime);
    let { hourlySalaries } = useSelector((state) => state.hourlySalary)
    useEffect(() => {
        if (jobtitles.length === 0) {
            dispatch(loadJobTitlesDataRequest())
        }
        if (stores.length === 0) {
            dispatch(loadStoreDataRequest());
        }
        if (markets.length === 0) {
            dispatch(loadMarketDataRequest())
        }
        if (regions.length === 0) {
            dispatch(loadRegionDataRequest());
        }
        if (divisions.length === 0) {
            dispatch(loadDivisionDataRequest());
        }
        if (departments.length === 0) {
            dispatch(loadDepartmentDataRequest());
        }
        if (termReasons.length === 0) {
            dispatch(loadTermReasonDataRequest());
        }
        if (employeeStatuses.length === 0) {
            dispatch(loadEmployeeStatusDataRequest());
        }
        if (hireStatuses.length === 0) {
            dispatch(loadHireStatusDataRequest());
        }
        if (active_employees.length === 0) {
            dispatch(loadEmployeeDataRequest())
        }
        if (fullPartTimes.length === 0) {
            dispatch(loadFullPartTimeDataRequest());
        }
        if (hourlySalaries.length === 0) {
            dispatch(loadHourlySalaryDataRequest());
        }

    }, [dispatch, jobtitles.length, departments.length, employeeStatuses.length])


    const formik = useFormik({});

    let [page, setPage] = useState(1)

    let [showStore, setShowStore] = useState(false);
    let [showCovering, setShowCovering] = useState(false);
    let [showDistrict, setShowDistrict] = useState(false);
    let [showRegion, setShowRegion] = useState(false);
    let [showDivision, setShowDivision] = useState(false);

    let pageOneFields = [
        "employee_id",
        "first_name",
        "last_name",
        "preferred_name",
        "work_phone",
        "personal_phone",
        "home_address",
        "email_work",
        "email_personal",
        "ntid",
        "cnumber",
    ]

    return <Formik
        innerRef={formik}
        initialValues={{
            employee_id: null,
            first_name: null,
            last_name: null,
            preferred_name: null,
            ntid: null,
            cnumber: null,
            job_title_id: null,
            department_id: null,
            email_work: null,
            email_personal: null,
            full_part_time_id: null,
            hire_date: null,
            hire_status: null,
            hourly_salary_id: null,
            term_date: '12/31/2099',
            term_reason_id: null,
            work_phone: null,
            personal_phone: null,
            home_address: null,
            supervisor: null,
            emp_status: null,
        }}
        validate={validate}
        onSubmit={submit}
    >
        {({ values, setFieldTouched, setFieldValue, validateForm }) => (
            <Form className="row g-4">
                <Card hidden={page !== 1} className="formPage">
                    <Card.Body>
                        <Row>
                            <FormTextField name='employee_id' label="Employee Number" required />
                            <FormTextField name='first_name' label='First Name' required />
                            <FormTextField name='last_name' label='Last Name' required />
                            <FormTextField name='preferred_name' label='Preferred Name' />
                            <FormTextField name='work_phone' label='Work Phone' />
                            <FormTextField name='personal_phone' label='Personal Phone' />
                            <FormTextField name='home_address' label='Home Address' />
                            <FormTextField name='email_work' label='Work Email' required />
                            <FormTextField name='email_personal' label='Personal Email' />
                            <FormTextField name='ntid' label='NTID' />
                            <FormTextField name='cnumber' label='CNumber' />
                            <Col md={12} className="mt-3">
                                <DefaultButton
                                    buttonClass={"btn btn-secondary me-2"}
                                    buttonTitle={CANCEL}
                                    actionBlock={() => {
                                        navigate("/employees");
                                    }}
                                />
                                <DefaultButton
                                    buttonClass={"btn btn-secondary default-btn-color"}
                                    buttonTitle={"Next"}
                                    actionBlock={() => {
                                        validateForm().then((errors) => {
                                            let errorKeys = Object.keys(errors);
                                            let pageOneHasErrors = false;
                                            for (let errorKey of errorKeys) {
                                                if (pageOneFields.includes(errorKey)) {
                                                    pageOneHasErrors = true;
                                                    setFieldTouched(errorKey)
                                                }
                                            }
                                            if (!pageOneHasErrors) {
                                                setPage(2)
                                            }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card hidden={page !== 2} className="formPage">
                    <Card.Body>
                        <Row>
                            <FormikSelectField
                                name='job_title_id'
                                label={JOB_TITLE}
                                required
                                value={values.job_title_id}
                                onChange={(name, value) => {
                                    setFieldValue(name, value);
                                    setShowStore(false);
                                    setShowCovering(false);
                                    setShowDistrict(false);
                                    setShowRegion(false);
                                    setShowDivision(false);
                                    let jobTitle = jobtitles.find(jobtitle => jobtitle.id === value);
                                    switch (jobTitle.level_name) {
                                        case 'store':
                                            setShowStore(true);
                                            setFieldValue('store_id', jobTitle.default_value)
                                            break;
                                        case 'corporate':
                                            setFieldValue('store_id', stores.find((store) => store.sap === '1')?.id);
                                            break;
                                        case 'store_covering':
                                            setShowStore(true)
                                            setShowCovering(true)
                                            setFieldValue('store_id', jobTitle.default_value)
                                            break;
                                        case 'district':
                                            setFieldValue('store_id', stores.find((store) => store.sap === '1')?.id);
                                            setShowDistrict(true);
                                            setFieldValue('market_id', jobTitle.default_value)
                                            break;
                                        case 'region':
                                            setFieldValue('store_id', stores.find((store) => store.sap === '1')?.id);
                                            setShowRegion(true);
                                            setFieldValue('region_id', jobTitle.default_value)
                                            break;
                                        case 'division':
                                            setFieldValue('store_id', stores.find((store) => store.sap === '1')?.id);
                                            setShowRegion(true);
                                            setShowDivision(true);
                                            setFieldValue('division_id', jobTitle.default_value)
                                            break;
                                    }
                                    setFieldValue('department_id', jobTitle?.department_id)
                                }}
                                placeholder={"Job Title"}
                            >
                                {jobtitles.map((jobtitle) => {
                                    return <option key={jobtitle.id} value={jobtitle.id}>{jobtitle.job_title}</option>
                                })}
                            </FormikSelectField>

                            <FormikSelectField
                                name="department_id"
                                label={EMPLOYEE_DEPARTMENT}
                                placeholder={EMPLOYEE_DEPARTMENT}
                                onChange={setFieldValue}
                                value={values.department_id}
                                disabled
                            >
                                {departments.map((department) => {
                                    return <option key={department.id} value={department.id}>{department.department_name}</option>
                                })}
                            </FormikSelectField>

                            <FormikSelectField
                                name='store_id'
                                label={STORE}
                                value={values.store_id}
                                onChange={setFieldValue}
                                placeholder={STORE}
                                hidden={!showStore}
                            >
                                {stores.map(store =>
                                    <option value={store.id}> {store.store_name + ", " + store.sap} </option>
                                )}
                            </FormikSelectField>

                            <FormikSelectField
                                name='market_id'
                                label={MARKET}
                                value={values.market_id}
                                onChange={setFieldValue}
                                placeholder={MARKET}
                                hidden={!showDistrict}
                            >
                                {markets.map(market =>
                                    <option value={market.id}>
                                        {market.market_name}
                                    </option>
                                )}
                            </FormikSelectField>
                            <FormikSelectField
                                name='region_id'
                                label={REGION}
                                value={values.region_id}
                                onChange={setFieldValue}
                                placeholder={REGION}
                                hidden={!showRegion}
                            >
                                {regions.map((region) =>
                                    <option value={region.id}>{region.region_name}</option>
                                )}
                            </FormikSelectField>
                            <FormikSelectField
                                name='division_id'
                                label={DIVISION}
                                value={values.division_id}
                                onChange={setFieldValue}
                                placeholder={DIVISION}
                                hidden={!showDivision}
                            >
                                {divisions.map(division => <option value={division.id}>{division.division_name}</option>)}
                            </FormikSelectField>
                            <div className="col-md-6 datepicker">
                                <label className="form-label">
                                    Hire Date <span className="error-text">*</span>
                                </label>
                                <FormikDatePickerField name="hire_date" value={values.hire_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                                <span className='error-text'>
                                    <ErrorMessage name="hire_date" />
                                </span>
                            </div>
                            <FormikSelectField
                                name='hire_status'
                                label={HIRE_STATUS}
                                value={values.hire_status}
                                onChange={setFieldValue}
                                placeholder={HIRE_STATUS}
                                required
                            >
                                {hireStatuses.map(hireStatus => <option value={hireStatus.id}>{hireStatus.name}</option>)}
                            </FormikSelectField>
                            <div className="col-md-6 datepicker">
                                <label className="form-label">
                                    Termination Date
                                </label>
                                <FormikDatePickerField readOnly name="term_date" value={values.term_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                                <span className='error-text'>
                                    <ErrorMessage name="term_date" />
                                </span>
                            </div>
                            {/* <div className="col-md-6">
                                <label className="form-label">
                                    Termination Reason
                                </label>
                                <FormikSelectField
                                    name="term_reason_id"
                                    placeholder={"Select Term Reason"}
                                    onChange={setFieldValue}
                                    value={values.term_reason_id}
                                >
                                    {termReasons.map((termReason) => {
                                        return <option key={termReason.id} value={termReason.id}>{termReason.term_reason}</option>
                                    })}
                                </FormikSelectField>
                                <span className='error-text'>
                                    <ErrorMessage name="term_reason_id" />
                                </span>
                            </div> */}
                            <FormikSelectField
                                name="supervisor"
                                label="Supervisor"
                                placeholder={"Select Supervisor"}
                                onChange={setFieldValue}
                                value={values.supervisor}
                            >
                                <option key='0' value=''>Select Supervisor</option>
                                {active_employees.map((employee) => {
                                    return <option key={employee.id} value={employee.id}>{employee.first_name + " " + employee.last_name+","+employee.employee_id }</option>
                                })}
                            </FormikSelectField>
                            <FormikSelectField
                                name="emp_status"
                                label={EMPLOYEE_STATUS}
                                placeholder={EMPLOYEE_STATUS}
                                onChange={setFieldValue}
                                value={values.emp_status}
                                required
                            >
                                {employeeStatuses.map((employeeStatus) => {
                                    return <option key={employeeStatus.id} value={employeeStatus.id}>{employeeStatus.name}</option>
                                })}
                            </FormikSelectField>
                            <FormikSelectField
                                name='full_part_time_id'
                                label={PART_FULL_TIME}
                                placeholder={PART_FULL_TIME}
                                onChange={setFieldValue}
                                value={values.full_part_time_id}
                            >
                                {fullPartTimes.map(entry => <option value={entry.id}>{entry.name}</option>)}
                            </FormikSelectField>
                            <FormikSelectField
                                name="hourly_salary_id"
                                label={HOURLY_SALARY}
                                placeholder={HOURLY_SALARY}
                                onChange={setFieldValue}
                                value={values.hourly_salary_id}
                            >
                                {hourlySalaries.map(entry => <option value={entry.id}>{entry.name}</option>)}
                            </FormikSelectField>
                            <Col md={12} className='mt-3'>
                                <DefaultButton
                                    buttonClass={"btn btn-secondary me-2"}
                                    buttonTitle={"Back"}
                                    actionBlock={() => {
                                        setPage(1)
                                    }}
                                />
                                <DefaultButton
                                    buttonClass={"btn btn-secondary default-btn-color"}
                                    buttonTitle={SAVE}
                                    buttonType={"submit"}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
        )}
    </Formik >
}

export default EmployeeFormAdd

