import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import { CANCEL, SAVE, STARTAFTEREND, STARTNOTFUTURE, COMPANY_NAME, ULTI_COMPANY_CODE, MASTER_DEALER_CODE, START_DATE, END_DATE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";

const CompanyForm = ({ company, submit, cancel }) => {
  const validate = (values) => {
    const errors = {};
    if (!values.company_name) {
      errors.company_name = "Company Name Mandatory";
    }
    if (!values.start_date) {
      errors.start_date = "Start Date Mandatory";
    }
    if (!values.end_date) {
      errors.end_date = "End Date Mandatory";
    }
    if (new Date(values.start_date) >= new Date(values.end_date)) {
      errors.end_date = STARTAFTEREND
    }
    if (new Date() >= new Date(values.start_date)) {
      // errors.start_date = STARTNOTFUTURE
    }
    return errors;
  };
  const formik = useFormik({}); 

  useEffect(() => {
    if (company) {
      formik.current.setFieldValue("id", company?.id);
      formik.current.setFieldValue("company_name", company?.company_name);
      formik.current.setFieldValue(
        "ulti_company_code",
        company?.ulti_company_code
      );
      formik.current.setFieldValue(
        "master_dealer_code",
        company?.master_dealer_code
      );

      if (company && company.start_date && company.end_date) {
        if (company?.start_date.includes("-") && company?.end_date.includes("-")) {
          formik.current.setFieldValue("start_date", FormatDate(company?.start_date));
          formik.current.setFieldValue("end_date", FormatDate(company?.end_date)); 
        }
      }
    }
  }, [company, formik]);

  


  return (
    <Formik
      innerRef={formik}
      initialValues={{
        company_name: "",
        ulti_company_code: "",
        master_dealer_code: "",
        start_date: "",
        end_date: '12/31/2099',
      }}
      validate={validate}
      onSubmit={submit}
    >
      {({ values, setFieldTouched, setFieldValue }) => (
        <Form className="row g-4">
          <div className="col-md-6">
            <label htmlFor="company_name" className="form-label">
              { COMPANY_NAME } <span className="error-text">*</span>
            </label>
            <Field
              className="form-control"
              type="text"
              name="company_name"
              autoComplete="off"
            />
            <span className="error-text">
              <ErrorMessage name="company_name" />
            </span>
          </div>
          <div className="col-md-6">
            <label className="form-label">{ ULTI_COMPANY_CODE }</label>
            <Field
              type="text"
              className="form-control"
              name="ulti_company_code"
              autoComplete="off"
            />
            <span className="error-text">
              <ErrorMessage name="ulti_company_code" />
            </span>
          </div>
          <div className="col-md-6">
            <label className="form-label">{ MASTER_DEALER_CODE }</label>
            <Field
              type="text"
              className="form-control"
              name="master_dealer_code"
              autoComplete="off"
            />
            <span className="error-text">
              <ErrorMessage name="master_dealer_code" />
            </span>
          </div>
          <div className="col-md-6 datepicker">
            <label className="form-label">
              { START_DATE } <span className="error-text">*</span>
            </label>
            <FormikDatePickerField
              name="start_date"
              value={values.start_date}
              onChange={setFieldValue}
              handleBlur={setFieldTouched}
            />
            <span className="error-text">
              <ErrorMessage name="start_date" />
            </span>
          </div>
          <div className="col-md-6 datepicker">
            <label className="form-label">
              { END_DATE } <span className="error-text">*</span>
            </label>
            <FormikDatePickerField
              name="end_date"
              value={values.end_date}
              onChange={setFieldValue}
              handleBlur={setFieldTouched}
            />
            <span className="error-text">
              <ErrorMessage name="end_date" />
            </span>
          </div>
          <Col md={12}>
            <DefaultButton
              buttonClass={"btn btn-secondary me-2"}
              buttonTitle={CANCEL}
              actionBlock={cancel}
            />
            <DefaultButton
              buttonClass={"default-btn-color"}
              buttonTitle={SAVE}
              buttonType={"submit"}
            />
          </Col>
        </Form>
      )}
    </Formik>
  );
};
export default CompanyForm;
