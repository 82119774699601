import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import dropdownFilterFunction from "../../../shared/components/listview/dropdownFilterFunction";
import ListView from "../../../shared/components/listview/ListView";
import Modalpopup from "../../../shared/components/Modalpopup";
import Permissions from "../../../shared/components/Permissions";
import { loadHireTermQueueDataRequest, loadHireTermQueueUpdate } from "../redux/Actions";
import { HTQUpdate } from "./HTQUpdate";

export const HireTermQueue = () => {


    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Queue Table";
    let permission = Permissions(emp_no, module_name);
    let actionsPermission = !(permission.write_permission);

    let dispatch = useDispatch();

    let { hireTermQueue, loading } = useSelector(state => state.hireTermQueue)
    let mdsMessages = useMemo(() => {
        let newArray = [];
        for (let entry of hireTermQueue) {
            if (!newArray.includes(entry.mds_message)) {
                newArray.push(entry.mds_message)
            }
        }
        return newArray
    }, [hireTermQueue])
    let mds_message_func = dropdownFilterFunction(mdsMessages?.map((entry) => ({ value: entry, label: entry })))
    console.log(mdsMessages)

    let [submitted, setSubmitted] = useState(false);
    //Load Data if it is updated
    useEffect(() => {
        if (submitted && !loading) {
            dispatch(loadHireTermQueueDataRequest());
            setSubmitted(false);
        }
    }, [loading, submitted])

    let [selectedRow, setSelectedRow] = useState({});
    let [showEditModal, setShowEditModal] = useState(false);
    useEffect(() => {
        dispatch(loadHireTermQueueDataRequest())
    }, [])
    let [clickEditFunc, setClickEditFunc] = useState(null);

    useEffect(() => {
        if (permission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedRow(row);
                    setShowEditModal(true);
                }
            )
        }
    }, [permission])

    function submit(values) {
        dispatch(loadHireTermQueueUpdate({ id: values.id, status: values.status }))
        setSubmitted(true);
        setShowEditModal(false)
    }
    const columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true,
            sort: 'desc'
        },
        {
            display_name: "Actions",
            field: 'actions',
            type: 'actions',
            hide: actionsPermission,
            width: 90
        },
        {
            display_name: "command",
            field: "command",
            type: "text",
        },
        {
            display_name: "Command Info",
            field: "command_info",
            type: "text",
        },
        {
            display_name: "Employee Id",
            field: "employee_id",
            type: "text",
        },
        {
            display_name: "Event Date",
            field: "event_date",
            type: "date",
        },
        {
            display_name: "Key",
            field: "key",
            type: "text",
        },
        {
            display_name: "Log Category",
            field: "log_category",
            type: "text",
        },
        {
            display_name: "Log Message",
            field: "log_message",
            type: "text",
        },
        {
            display_name: "MDS Message",
            field: "mds_message",
            type: "dropdown",
            dropdownOptions: mdsMessages,
            dropdownFunction: mds_message_func,
        },
        {
            display_name: "Priority",
            field: "priority",
            type: "text",
        },
        {
            display_name: "Status",
            field: "status",
            type: "text",
        },
        {
            display_name: "Update Date",
            field: "update_date",
            type: "date",
        },
    ];
    return (
        <>
            <Row>
                <Col xl={4} xs={12}>
                    <Breadcrumbs
                        children={[
                            { label: "Hire/Term Queue" },
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={'hire_term_queue'}
                            columnNames={columns}
                            rowData={hireTermQueue}
                            clickEdit={clickEditFunc}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={showEditModal}
                handleClose={() => { setShowEditModal(false) }}
                actionTitle={"Update Queue Table"}
            >
                <HTQUpdate
                    rowData={selectedRow}
                    submit={submit}
                    handleClose={() => { setShowEditModal(false) }}
                />
            </Modalpopup>
        </>
    );
};