import {
  ACQUISITION_DATA,
  ACQUISITION_ADD_DATA,
  ACQUISITION_UPDATE_DATA,
  ACQUISITION_GET_DATA,
  ACQUISITION_DELETE_DATA,
} from "./Constants";

//For listing
const loadAcquisitionDataRequest = () => ({
  type: ACQUISITION_DATA.ACQUISITION_DATA_LOAD,
});
const setAcquisitionDataSuccess = (acquisitions) => ({
  type: ACQUISITION_DATA.ACQUISITION_DATA_SUCCESS,
  acquisitions,
});
const setAcquisitionDataFail = (error) => ({
  type: ACQUISITION_DATA.ACQUISITION_DATA_FAIL,
  error,
});

//For add
const loadAcquisitionAddDataRequest = (request) => ({
  type: ACQUISITION_ADD_DATA.ACQUISITION_ADD_DATA_LOAD,
  request,
});
const setAcquisitionAddDataSuccess = (acquisition) => ({
  type: ACQUISITION_ADD_DATA.ACQUISITION_ADD_DATA_SUCCESS,
  acquisition,
});

const setAcquisitionAddDataFail = (error) => ({
  type: ACQUISITION_ADD_DATA.ACQUISITION_ADD_DATA_FAIL,
  error,
});

//For Edit
const loadAcquisitionUpdateDataRequest = (request) => ({
  type: ACQUISITION_UPDATE_DATA.ACQUISITION_UPDATE_DATA_LOAD,
  request,
});

const setAcquisitionUpdateDataSuccess = (acquisition) => ({
  type: ACQUISITION_UPDATE_DATA.ACQUISITION_UPDATE_DATA_SUCCESS,
  acquisition,
});

const setAcquisitionUpdateDataFail = (error) => ({
  type: ACQUISITION_UPDATE_DATA.ACQUISITION_UPDATE_DATA_FAIL,
  error,
});

//To Get
const loadAcquisitionGetDataRequest = (id) => ({
  type: ACQUISITION_GET_DATA.ACQUISITION_GET_DATA_LOAD,
  id,
});

const setAcquisitionGetDataSuccess = (acquisition) => ({
  type: ACQUISITION_GET_DATA.ACQUISITION_GET_DATA_SUCCESS,
  acquisition,
});

const setAcquisitionGetDataFail = (error) => ({
  type: ACQUISITION_GET_DATA.ACQUISITION_GET_DATA_FAIL,
  error,
});

//Delete ACQUISITION
const loadAcquisitionDeleteDataRequest = (request) => ({
  type: ACQUISITION_DELETE_DATA.ACQUISITION_DELETE_DATA_LOAD,
  request,
});

const setAcquisitionDeleteDataSuccess = (acquisition) => ({
  type: ACQUISITION_DELETE_DATA.ACQUISITION_DELETE_DATA_SUCCESS,
  acquisition,
});

const setAcquisitionDeleteDataFail = (error) => ({
  type: ACQUISITION_DELETE_DATA.ACQUISITION_DELETE_DATA_FAIL,
  error,
});

export {
  loadAcquisitionDataRequest,
  setAcquisitionDataSuccess,
  setAcquisitionDataFail,
  loadAcquisitionAddDataRequest,
  setAcquisitionAddDataSuccess,
  setAcquisitionAddDataFail,
  loadAcquisitionUpdateDataRequest,
  setAcquisitionUpdateDataSuccess,
  setAcquisitionUpdateDataFail,
  loadAcquisitionGetDataRequest,
  setAcquisitionGetDataSuccess,
  setAcquisitionGetDataFail,
  loadAcquisitionDeleteDataRequest,
  setAcquisitionDeleteDataSuccess,
  setAcquisitionDeleteDataFail,
};
