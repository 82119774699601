import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import ListView from '../../../shared/components/listview/ListView'
import { MARKET, IMPORT, MARKETTRACKER, IMPORT_DISTRICT_TRACKER } from '../../../shared/constants/Constants'
import { loadMarketTrackerData, loadMarketTrackerImportDataRequest } from '../redux/Actions'
import DefaultButton from '../../../shared/components/DefaultButton'
import ImportModal from '../../../shared/components/ImportModal'
import Permissions from '../../../shared/components/Permissions'

const MarketTracker = () => {

    const dispatch = useDispatch();

    let { tracker } = useSelector(state => state.market);
    useEffect(() => {
        dispatch(loadMarketTrackerData());
    }, [dispatch])


    const marketTrackerColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'District Name',
            field: 'market_name',
            type: 'text'
        }, {
            display_name: 'Region Name',
            field: 'region_name',
            type: 'text'
        }, {
            display_name: 'Start Date',
            field: 'start_date',
            type: 'date'
        }, {
            display_name: 'End Date',
            field: 'end_date',
            type: 'date'
        }];

    const [showImportModal, setShowImportModal] = useState(false);

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Market Tracker";
    let permission = Permissions(emp_no, module_name);
    

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                        { href: '/district-tracker', label: MARKETTRACKER },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission?(
                        <DefaultButton
                            buttonClass={"default-btn-color me-2"}
                            buttonTitle={IMPORT}
                            actionBlock={() => { setShowImportModal(true) }}
                        />
                    ):(<></>)}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle="Districts"
                            columnNames={marketTrackerColumns}
                            rowData={tracker}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName={"market_tracker"}
                modalTitle={IMPORT_DISTRICT_TRACKER}
                importAction={loadMarketTrackerImportDataRequest}
            />
        </>
    )
}

export default MarketTracker
