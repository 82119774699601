import { takeLatest, put, call } from "redux-saga/effects";
import {
  AUDIT_DATA, AUDIT_GET_API,
  AUDIT_GET_TABLES, AUDIT_GET_TABLES_API,
} from "./Constants";
import {
  setAuditDataSuccess, setAuditDataFail,
  setAuditTablesFail, setAuditTablesSuccess,
} from "./Actions";
import {
  getRequest,
} from "../../../shared/components/Service";

function* getAuditsDataService({ id }) {
  try {
    const response = yield call(getRequest, AUDIT_GET_API + id);
    if (response.status === 200) {
      yield put(setAuditDataSuccess(response.data));
    } else {
      yield put(setAuditDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setAuditDataFail(error));
  }
}
function* getAuditTablesService() {
  try {
    const response = yield call(getRequest, AUDIT_GET_TABLES_API);
    if (response.status === 200) {
      yield put(setAuditTablesSuccess(response.data));
    } else {
      yield put(setAuditTablesFail(response.response.data));
    }
  } catch (error) {
    yield put(setAuditTablesFail(error));
  }
}

export function* watchAudit() {
  yield takeLatest(AUDIT_DATA.AUDIT_DATA_LOAD, getAuditsDataService);
  yield takeLatest(AUDIT_GET_TABLES.AUDIT_GET_TABLES_LOAD, getAuditTablesService)
}
