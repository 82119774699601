import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select';
export function ActiveFilter({ field, filterType, setFilter, existingFilter }) {
    let values = ['active', 'inactive', 'future']
    let [type1, setType1] = useState('any');
    let [type2, setType2] = useState('any');
    let [shouldShowInput2, setShouldShowInput2] = useState(false);


    useEffect(() => {
        setShouldShowInput2(
            ['active', 'inactive', 'future'].includes(type1)
        )
    }, [type1])


    useEffect(() => {
        if (existingFilter?.operator) {
            setType1(values[existingFilter.condition1.filter])
            setType2(values[existingFilter.condition2.filter])
        } else if (existingFilter?.filter) {
            setType1(values[existingFilter.filter])
        } else {
            setType1('any')
        }
    }, [existingFilter])

    useEffect(() => {
        let filter = {};
        if (type1 && type1 !== 'any') {
            if (type1 != 'any' && values.includes(type2)) {
                filter = {
                    condition1: {
                        filterType: 'text',
                        type: 'equals',
                        filter: values.indexOf(type1).toString(),
                    },
                    condition2: {
                        filterType: 'text',
                        type: 'equals',
                        filter: values.indexOf(type2).toString(),
                    },
                    filterType: "text",
                    operator: 'OR'
                }
            } else {
                filter = {
                    filterType: "text",
                    type: 'equals',
                    filter: values.indexOf(type1).toString(),
                }
            }
        }
        changeParentFilter(filter)
    }, [type1, type2])

    function changeParentFilter(filter) {
        setFilter((oldFilter) => {
            if (JSON.stringify(oldFilter[field]) === JSON.stringify(filter)) {
                return oldFilter
            }
            let newFilter = { ...oldFilter }
            newFilter[field] = { ...filter }
            return newFilter;
        })
    }
    return <div>
        <Select
            value={type1 ? { value: type1, label: type1 } : null}
            onChange={(event) => {
                setType1(event.value);
            }}
            options={[
                { value: 'any', label: 'any' },
                { value: 'active', label: 'active' },
                { value: 'inactive', label: 'inactive' },
                { value: 'future', label: 'future' },
            ]}
        />
        {shouldShowInput2 ? <Fragment>
            <Row>
                <Col></Col>
                <Col sm="auto">
                    <div>
                        <input className="radio-input" type="radio" value="AND" name={'radio_'+field} disabled readOnly/> And
                        <input className="radio-input" type="radio" value="OR" name={'radio_'+field} checked={true} readOnly/> or
                    </div>
                </Col>
                <Col></Col>
            </Row>
            <Select
                value={type2 ? { value: type2, label: type2 } : null}
                onChange={(event) => {
                    setType2(event.value);
                }}
                options={[
                    { value: 'any', label: 'any' },
                    { value: 'active', label: 'active' },
                    { value: 'inactive', label: 'inactive' },
                    { value: 'future', label: 'future' },
                ]}
            />
        </Fragment> : ""}
    </div>
}
