import React, { useState } from 'react'
import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CANCEL, SAVE } from "../../../shared/constants/Constants";
import FormikSelectField from '../../../shared/components/FormikSelectField';
import FormikRadioField from '../../../shared/components/FormikRadioField';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadTShirtAddDataRequest, loadTShirtGetDataRequest } from '../redux/Actions';
import Loader from '../../../shared/components/Loader';
import Modalpopup from '../../../shared/components/Modalpopup';
import mCut from './mcut.PNG';
import wCut from './wcut.PNG';

const TShirtSize = ({data=null, handleShirtClose=null}) => {

    let empInfo = JSON.parse(localStorage.getItem("employee"));
    if (data !== null) {
        empInfo = data
    }

    let params = useParams(); 
    const dispatch = useDispatch();     
    // const mCut = "mcut.png";
    // const wCut = "wcut.png";
    const shirtSizes = [{
        M : [{key: "5XLT (Tall)", val: "5XLT"}, {key: "4XLT (Tall)", val: "4XLT"}, {key: "3XLT (Tall)", val: "3XLT"}, {key: "2XLT (Tall)", val: "2XLT"}, {key: "XLT (Tall)", val: "XLT"}, {key: "9XL", val: "9XL"}, {key: "8XL", val: "8XL"}, {key: "7XL", val: "7XL"}, {key: "6XL", val: "6XL"}, {key: "5XL", val: "5XL"}, {key: "4XL", val: "4XL"}, {key: "3XL", val: "3XL"}, {key: "2XL", val: "2XL"}, {key: "XL", val: "XL"}, {key: "L", val: "L"}, {key: "M", val: "M"}, {key: "S", val: "S"}, {key: "XS", val: "XS"}],
    },
    {
        W: [{key: "4XL", val: "4XL"}, {key: "3XL", val: "3XL"}, {key: "XXL", val: "XXL"}, {key: "XL", val: "XL"}, {key: "L", val: "L"}, {key: "M", val: "M"}, {key: "S", val: "S"}, {key: "XS", val: "XS"}, {key: "XXS", val: "XXS"}]
    }];
    const [cutImage, setCutImage] = useState(false);
    const [tsSize, setTSSize] = useState([]);
    let [submitted, setSubmitted] = useState(false);

    let { loading, error, empAdds, empAdd } = useSelector((state) => state.tShirtReducer);

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [name, setName] = useState('');
    const [size, setSize] = useState('');
    const [vals, setVals] = useState();
    const handleOpen = () => setOpen(true);

    const oneYear = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = oneYear - new Date(empInfo?.hire_date);
    const diffInDays = Math.round(diffInTime / oneDay);
    
    let successMsg;    
    if (diffInDays < 365) {
        // successMsg = "Thank you submitting the t-shirt details. HR will get back to you";
        successMsg = "Your shirt size has been recorded. Thank you!";
    } else if (diffInDays > 365) {
        // successMsg = "Thank you submitting the t-shirt details";
        successMsg = "Your shirt size has been recorded. Thank you!";
    }

    useEffect(() => {
        dispatch(loadTShirtGetDataRequest(empInfo?.employee_id)); 
    }, [dispatch, params.id]);

    const validate = (values) => {
        const errors = {};
        if (!values.tshirt_cut) {
            errors.tshirt_cut = "T-Shirt Cut";
        }
        if (!values.tshirt_size) {
            errors.tshirt_size = "T-Shirt Size";
        }
        return errors;
    };
    const formik = useFormik({});

    useEffect(() => {
        if (submitted && !loading) {
            if (!error) {
                setOpen(true);
                if (handleShirtClose != null) {
                    handleShirtClose(true);
                }
            } else {
                setOpen(false);
            }
        }
    }, [loading, submitted])
    
    const handleSubmit = (values) => {
        values.employee_id = empInfo?.employee_id
        setConfirm(true);
        handleOpen();
        setVals(values);
        setSize(values.tshirt_size);
        if (values.tshirt_cut === "W") {
            setName("womens");
        } else if (values.tshirt_cut === "M") {
            setName("mens");
        } 
    }

    const confirmSubmit = () => {        
        dispatch(loadTShirtAddDataRequest(vals))
        setSubmitted(true);
        setConfirm(false);
    }

    useEffect(() => {
        if (empAdd[0]) {
            formik.current.setFieldValue("id", empAdd[0]?.id);
            formik.current.setFieldValue("tshirt_cut", empAdd[0]?.tshirt_cut);
            formik.current.setFieldValue("tshirt_size", empAdd[0]?.tshirt_size);
        }
    }, [empAdd[0], formik]);

    useEffect(() => {
        if (empAdd[0]?.tshirt_cut === "M") {
            setTSSize(shirtSizes[0]['M']);
            setCutImage("mCut");
        } else if (empAdd[0]?.tshirt_cut === "W") {
            setTSSize(shirtSizes[1]['W']);
            setCutImage("wCut");
        }
    }, [empAdd])
    
    const handleTShirt = (val) => {
        if (val === "M") {
            setTSSize(shirtSizes[0]['M']);
            setCutImage("mCut");
        } else if (val === "W") {
            setTSSize(shirtSizes[1]['W']);
            setCutImage("wCut");
        }
    }
    
  return (<>
    {/* <Card>
        <Card.Body> */}
            {loading && empAdd !== "" ? (
                <Loader />
            ) : (
                <Formik
                innerRef={formik}
                initialValues={{
                    tshirt_cut: "",
                    tshirt_size: "",
                }}
                validate={validate}
                onSubmit={handleSubmit}
                >
                {({ values, setFieldTouched, setFieldValue }) => (<>
                <Row style={{ minHeight: "250px" }}>
                    <div className="col-md-6">
                        <Form className="row g-4">
                        <div className="col-md-4">
                            <label htmlFor="tshirt_cut" className="form-label">
                            T-Shirt Style <span className="error-text">*</span>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <Field className="form-check-input" type="radio" name="tshirt_cut" value="M" onClick={() => handleTShirt("M")} disabled={empAdd && empAdd[0]?.employee_id !== empInfo?.employee_id ? false : true } /> Men
                            <Field className="form-check-input" type="radio" name="tshirt_cut" value="W" onClick={() => handleTShirt("W")} style={{ marginLeft: "12px" }} disabled={empAdd && empAdd[0]?.employee_id !== empInfo?.employee_id ? false : true } /> Women
                            <div style={{ clear: "both" }}>
                                <span className="error-text">
                                    <ErrorMessage name="tshirt_cut" />
                                </span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="inputMDCode" className="form-label">
                                Size <span className="error-text">*</span>
                            </label> 
                        </div>
                        <div className="col-md-8">
                            <FormikSelectField
                                name="tshirt_size"
                                placeholder={"Select Size"}
                                onChange={setFieldValue}
                                value={values.tshirt_size}
                                disabled={empAdd && empAdd[0]?.employee_id !== empInfo?.employee_id ? false : true }
                            >
                                {tsSize.map((tSize) => {
                                    return <option key={tSize.val} value={tSize.val}>{tSize.key}</option>
                                })}
                            </FormikSelectField>
                            <span className='error-text'>
                                <ErrorMessage name='tshirt_size' />
                            </span>
                        </div>
                        <Col md={12}>
                            {empAdd[0]?.employee_id !== empInfo?.employee_id && (
                                <DefaultButton
                                buttonClass={"default-btn-color"}
                                buttonTitle="Submit"
                                buttonType={"submit"}
                                />
                            )}
                        </Col>
                        </Form>
                    </div>
                    <div className="col-md-6">
                        {cutImage === "mCut" && (
                        <img
                            src={mCut}
                            alt="icon"
                            className='cutImage'
                        />
                        )}
                        {cutImage === "wCut" && (
                        <img
                            src={wCut}
                            alt="icon"
                            className='cutImage'
                        />
                        )}
                    </div> 
                    </Row>   
                </>)}
                </Formik>
            )}
        {/* </Card.Body> */}
        <Modalpopup
            show={open}
            handleClose={() => { setOpen(false) }}
            actionTitle={confirm ? "Confirm" : "Success"}
        >
            {confirm && (<>
                <p m={1} p={1}>You have selected a {name} size ({size}) t-shirt. You will not be able to edit this selection once submitted. If this is correct, please click “Submit”. If you would like to select a different style or size, click the back button.</p>
                <div class="modal-footer">
                    <button type="button" id="btn_ok_1" class="btn btn-secondary" onClick={() => { setOpen(false) }}>Go Back</button>
                    {/* <button type="button" id="btn_ok_1" class="btn btn-primary" onClick={confirmSubmit}>Okay, Proceed</button> */}
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle="Okay, Proceed"
                        buttonType={"button"}
                        actionBlock={confirmSubmit}
                    />
                </div>
            </>)}
            {!confirm && (<>
                <p m={1} p={1}>{successMsg}</p>
                <div class="modal-footer">
                    <button type="button" id="btn_ok_1" class="btn btn-primary" onClick={() => { setOpen(false) }}>OK</button>
                </div>
            </>)}
        </Modalpopup>
    {/* </Card> */}
    </>
  )
}

export default TShirtSize