//Get
export const ODOO_DESIGNATION_TRACKER_DATA = {
    ODOO_DESIGNATION_TRACKER_DATA_LOAD: "ODOO_DESIGNATION_TRACKER_DATA_LOAD",
    ODOO_DESIGNATION_TRACKER_DATA_SUCCESS: "ODOO_DESIGNATION_TRACKER_DATA_SUCCESS",
    ODOO_DESIGNATION_TRACKER_DATA_FAIL: "ODOO_DESIGNATION_TRACKER_DATA_FAIL",
};
export const ODOO_DESIGNATION_TRACKER_API = "employee/v1/DesignationHistory";

// Get one by id
export const ODOO_DESIGNATION_TRACKER_DETAILS_DATA = {
    ODOO_DESIGNATION_TRACKER_DETAILS_DATA_LOAD: "ODOO_DESIGNATION_TRACKER_DETAILS_DATA_LOAD",
    ODOO_DESIGNATION_TRACKER_DETAILS_DATA_SUCCESS: "ODOO_DESIGNATION_TRACKER_DETAILS_DATA_SUCCESS",
    ODOO_DESIGNATION_TRACKER_DETAILS_DATA_FAIL: "ODOO_DESIGNATION_TRACKER_DETAILS_DATA_FAIL",
};
export const ODOO_DESIGNATION_TRACKER_DETAILS_API = "employee/v1/DesignationHistory/"

//get by employee number
export const ODOO_DESIGNATION_TRACKER_BY_EMP_DATA ={
    ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_LOAD: "ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_LOAD",
    ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_SUCCESS: "ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_SUCCESS",
    ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_FAIL: "ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_FAIL"
}
export const ODOO_DESIGNATION_TRACKER_BY_EMP_API = "employee/v1/OdooEmpHistory/"