import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { JOB_TITLE, EDIT, DELETE, CANCEL, BACKTOLIST, COMPANY, EMPLOYEE_DEPARTMENT, ULTI_JOB_CODE } from "../../../shared/constants/Constants";
import {
  loadJobTitlesDataRequest,
  loadJobTitlesGetDataRequest,
  loadJobTitlesDeleteDataRequest,
} from "../redux/Actions";
import Modalpopup from "../../../shared/components/Modalpopup";
import { PrevNextButtons } from "../../../shared/components/PrevNextButtons";
import DeleteModal from "../../../shared/components/DeleteModal";
import Permissions from "../../../shared/components/Permissions";

const JobTitleDetails = () => {

  let params = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let [submittedDeleteRequest, setSubmittedDeleteRequest] = useState(false)

  //Delete Functionality
  const handleDelete = () => {
    let deleteRequest = { id: [params.id] };
    dispatch(loadJobTitlesDeleteDataRequest(deleteRequest));
    setSubmittedDeleteRequest(true)
  };

  let { loading, error, jobtitle } = useSelector((state) => state.jobtitle);
  useEffect(() => {
    dispatch(loadJobTitlesGetDataRequest(params.id));
  }, [dispatch, params.id]);

  let jobtitleData = jobtitle[0];
  useEffect(() => {
    if (submittedDeleteRequest && !loading) {
      setShowDeleteModal(false);
      navigate('/jobtitles')
    }
  }, [loading, jobtitle.msg, dispatch, error, navigate, submittedDeleteRequest])

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "Job Title";
  let permission = Permissions(emp_no, module_name);

  return (
    <div>
      <Row>
        <Col xl={4} xs={12}>
          <Breadcrumbs
            children={[
              { href: '/jobtitles', label: JOB_TITLE },
              { href: '/jobtitles/' + params.id, label: params.id },
            ]}
          />
        </Col>
        <Col xl={8} xs={12}>
          <div className="float-end">
            <PrevNextButtons currentId={params.id} basePath={'/jobtitles/'} name={JOB_TITLE} />
            <DefaultButton
              buttonClass={"default-btn-color me-1"}
              buttonTitle={BACKTOLIST}
              actionBlock={() => { navigate("/jobtitles"); }}
            />
            {permission.write_permission ? (<>
              <DefaultButton buttonClass={"default-btn-color"} buttonTitle={EDIT} actionBlock={() => {
                navigate("/jobtitles/edit/" + jobtitleData?.id);
              }} />
            </>) : (<></>)}
            {permission.delete_permission ? (<>
              <DefaultButton buttonClass={"btn btn-danger ms-1"} buttonTitle={<i className="fa fa-trash"></i>} actionBlock={() => { setShowDeleteModal(true) }} />
            </>) : (<></>)}
          </div>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <dl className="row">
              <dt className="col-sm-3">{JOB_TITLE}</dt>
              <dd className="col-sm-9">{jobtitleData?.job_title}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">{ULTI_JOB_CODE}</dt>
              <dd className="col-sm-9">{jobtitleData?.ulti_job_code}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">{EMPLOYEE_DEPARTMENT}</dt>
              <dd className="col-sm-9">{jobtitleData?.department_name}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">{COMPANY}</dt>
              <dd className="col-sm-9">{jobtitleData?.company_name}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Level</dt>
              <dd className="col-sm-9">{jobtitleData?.level_name}</dd>
            </dl>
          </Card.Body>
        </Card>
      </Row>
      <DeleteModal
        show={showDeleteModal}
        handleClose={() => { setShowDeleteModal(false) }}
        handleDelete={handleDelete}
      >
        <div>
          Delete {JOB_TITLE}?
        </div>
      </DeleteModal>
    </div>
  );
};

export default JobTitleDetails;
