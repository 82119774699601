import { takeLatest, put, call } from "redux-saga/effects";
import {
    MODULE_DATA, MODULE_GET_API,
    MODULE_GET_DATA, MODULE_GET_DETAIL_API,
    MODULE_ADD_DATA, MODULE_POST_API,
    MODULE_UPDATE_DATA, MODULE_PUT_API,
    MODULE_DELETE_DATA, MODULE_DELETE_API,
} from './Constants'
import {
    setModuleDataSuccess, setModuleDataFail,
    setModuleGetDataSuccess, setModuleGetDataFail,
    setModuleAddDataSuccess, setModuleAddDataFail,
    setModuleUpdateDataSuccess, setModuleUpdateDataFail,
    setModuleDeleteDataSuccess, setModuleDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest, getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";

function* getAllModuleDataService() {
    try {
        const response = yield call(getRequest, MODULE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setModuleDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setModuleDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving module"))
        yield put(setModuleDataFail(error));
    }
}

function* getModuleDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            MODULE_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setModuleGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setModuleGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving module info"))
        yield put(setModuleGetDataFail(error));
    }
}

function* postModuleDataService({ request }) {
    try {
        const response = yield call(postRequest, MODULE_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Module created"))
            yield put(setModuleAddDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setModuleAddDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error adding module"))
        yield put(setModuleAddDataFail(error));
    }
}
function* putModuleDataService({ request }) {
    try {
        const response = yield call(putRequest, MODULE_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Module updated"))
            yield put(setModuleUpdateDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setModuleUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error editing module"))
        yield put(setModuleUpdateDataFail(error));
    }
}

function* deleteModuleDataService({ request }) {
    try {
        const response = yield call(deleteRequest, MODULE_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setModuleDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setModuleDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setModuleDeleteDataFail(error));
    }
}


export function* watchModule(){
    yield takeLatest(MODULE_DATA.MODULE_DATA_LOAD, getAllModuleDataService);
    yield takeLatest(MODULE_GET_DATA.MODULE_GET_DATA_LOAD, getModuleDataService);
    yield takeLatest(MODULE_ADD_DATA.MODULE_ADD_DATA_LOAD, postModuleDataService);
    yield takeLatest(MODULE_UPDATE_DATA.MODULE_UPDATE_DATA_LOAD, putModuleDataService);
    yield takeLatest(MODULE_DELETE_DATA.MODULE_DELETE_DATA_LOAD, deleteModuleDataService); 
}