//Get
export const HIRE_TERM_QUEUE_DATA = {
    HIRE_TERM_QUEUE_DATA_LOAD: "HIRE_TERM_QUEUE_DATA_LOAD",
    HIRE_TERM_QUEUE_DATA_SUCCESS: "HIRE_TERM_QUEUE_DATA_SUCCESS",
    HIRE_TERM_QUEUE_DATA_FAIL: "HIRE_TERM_QUEUE_DATA_FAIL",
};
export const HIRE_TERM_QUEUE_GET_API = "/audit/getHireTermQueue";

//update
export const UPDATE_HIRE_TERM_QUEUE= {
    UPDATE_HIRE_TERM_QUEUE_LOAD: "UPDATE_HIRE_TERM_QUEUE_LOAD",
    UPDATE_HIRE_TERM_QUEUE_SUCCESS: "UPDATE_HIRE_TERM_QUEUE_SUCCESS",
    UPDATE_HIRE_TERM_QUEUE_FAIL: "UPDATE_HIRE_TERM_QUEUE_FAIL",
}
export const UPDATE_HIRE_TERM_QUEUE_URL = '/audit/hireTermQueue'