import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ListView from "../../../shared/components/listview/ListView"
import Modalpopup from "../../../shared/components/Modalpopup"
import DefaultButton from "../../../shared/components/DefaultButton"
import { loadCommTrackerAddDataRequest, loadCommTrackerDeleteDataRequest, loadCommTrackerUpdateDataRequest } from "../redux/Actions"
import { DELETE, CANCEL } from "../../../shared/constants/Constants"
import CommissionCompGroupTrackerForm from "./CommissionCompGroupTrackerForm"
import Permissions from "../../../shared/components/Permissions"
import FormError from "../../../shared/components/FormError"

const CommissionCompGroupTracker = ({ trackers, store, setShouldReload }) => {
    const dispatch = useDispatch();

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Comm Comp Tracker";
    let permission = Permissions(emp_no, module_name);

    let actionsPermission = !(permission.write_permission || permission.delete_permission);

    const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        // {
        //     display_name: "SAP",
        //     field: "sap",
        //     type: "text",
        // },
        {
            display_name: "Commission Comp Group",
            field: "commission_comp_group_name",
            type: "text",
        },
        {
            display_name: "Start Date",
            field: "start_date",
            type: "date",
            sort: "desc"
        },
        {
            display_name: "End Date",
            field: "end_date",
            type: "date",
        },
        {
            display_name: 'Actions',
            field: 'actions',
            hide: actionsPermission
        }
    ]

    let [showEditModal, setShowEditModal] = useState(false)
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [showAddModal, setShowAddModal] = useState(false);
    let [selectedTracker, setSelectedTracker] = useState({});

    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);
    let { loading, error } = useSelector(state => state.store)

    useEffect(() => {
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddModal();
                closeEditModal();
                setShouldReload(true)
            } else {
                console.log(error)
                setErrorMessage(error.msg);
            }
            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])

    const closeAddModal = () => {
        setShowAddModal(false);
        setErrorMessage(null);
    }
    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }

    const handleEdit = (values) => {
        dispatch(loadCommTrackerUpdateDataRequest({
            ...values,
            id: selectedTracker.id,
        }))
        setShouldCloseModal(true)
    }
    const handleAdd = (values) => {
        dispatch(loadCommTrackerAddDataRequest(
            {
                ...values,
            }
        ));
        setShouldCloseModal(true)
    }
    const handleDelete = () => {
        setShowDeleteModal(false);
        dispatch(loadCommTrackerDeleteDataRequest({
            id: [selectedTracker],
            sap_id: store.id
        }))
        setShouldReload(true)
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedTracker(row);
                    setShowEditModal(true);
                })
        }
        if (permission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedTracker(id);
                    setShowDeleteModal(true);
                })
        }
    }, [permission])
    return <>
        {permission.create_permission ? (
            <Button style={{ width: 115 }} className="btn btn-secondary default-btn-color float-end py-1" onClick={() => { setShowAddModal(true) }}>Add Tracker</Button>
        ) : (<></>)}
        <ListView
            exportTitle={'comm comp tracker'}
            columnNames={columnDefs}
            rowData={trackers}
            clickEdit={clickEditFunc}
            clickDelete={clickDeleteFunc}
            tableHeight = '50vh'
        />
        <Modalpopup show={showEditModal} actionTitle="Edit Tracker" handleClose={closeEditModal} >
            <FormError>
                {errorMessage}
            </FormError>
            <CommissionCompGroupTrackerForm tracker={selectedTracker} submit={handleEdit} cancel={closeEditModal} store={store} />
        </Modalpopup>
        <Modalpopup show={showDeleteModal} actionTitle="Delete Tracker" handleClose={() => { setShowDeleteModal(false) }}>
            Are you sure you want to delete tracker?
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>
        <Modalpopup show={showAddModal} actionTitle="Add Tracker" handleClose={closeAddModal}>
            <FormError>
                {errorMessage}
            </FormError>
            <CommissionCompGroupTrackerForm submit={handleAdd} cancel={closeAddModal} store={store} />
        </Modalpopup>
    </>
}
export default CommissionCompGroupTracker