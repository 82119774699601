//Get
const EMPLOYEE_DATA = {
  EMPLOYEE_DATA_LOAD: "EMPLOYEE_DATA_LOAD",
  EMPLOYEE_DATA_SUCCESS: "EMPLOYEE_DATA_SUCCESS",
  EMPLOYEE_DATA_FAIL: "EMPLOYEE_DATA_FAIL",
};
const EMPLOYEE_GET_API = "/employee/v1/query";

//get row count
const EMPLOYEE_COUNT = {
  EMPLOYEE_COUNT_LOAD: "EMPLOYEE_COUNT_LOAD",
  EMPLOYEE_COUNT_SUCCESS: "EMPLOYEE_COUNT_SUCCESS",
  EMPLOYEE_COUNT_FAIL: "EMPLOYEE_COUNT_FAIL",
}
const EMPLOYEE_COUNT_API = "/employee/v1/getRowCount"

// Get one by id
const EMPLOYEE_GET_DATA = {
  EMPLOYEE_GET_DATA_LOAD: "EMPLOYEE_GET_DATA_LOAD",
  EMPLOYEE_GET_DATA_SUCCESS: "EMPLOYEE_GET_DATA_SUCCESS",
  EMPLOYEE_GET_DATA_FAIL: "EMPLOYEE_GET_DATA_FAIL",
};

const EMPLOYEE_GET_DETAIL_API = "/employee/v1/getById/";

// Create / Add / Post
const EMPLOYEE_ADD_DATA = {
  EMPLOYEE_ADD_DATA_LOAD: "EMPLOYEE_ADD_DATA_LOAD",
  EMPLOYEE_ADD_DATA_SUCCESS: "EMPLOYEE_ADD_DATA_SUCCESS",
  EMPLOYEE_ADD_DATA_FAIL: "EMPLOYEE_ADD_DATA_FAIL",
};

const EMPLOYEE_POST_API = "/employee/v1/create";

//Update
const EMPLOYEE_UPDATE_DATA = {
  EMPLOYEE_UPDATE_DATA_LOAD: "EMPLOYEE_UPDATE_DATA_UPLOAD",
  EMPLOYEE_UPDATE_DATA_SUCCESS: "EMPLOYEE_UPDATE_DATA_SUCCESS",
  EMPLOYEE_UPDATE_DATA_FAIL: "EMPLOYEE_UPDATE_DATA_FAIL",
};

const EMPLOYEE_PUT_API = "/employee/v1/update";

// Delete
const EMPLOYEE_DELETE_DATA = {
  EMPLOYEE_DELETE_DATA_LOAD: "EMPLOYEE_DELETE_DATA_LOAD",
  EMPLOYEE_DELETE_DATA_SUCCESS: "EMPLOYEE_DELETE_DATA_SUCCESS",
  EMPLOYEE_DELETE_DATA_FAIL: "EMPLOYEE_DELETE_DATA_FAIL",
};

const EMPLOYEE_DELETE_API = "/employee/v1/delete";

//Import
const EMPLOYEE_IMPORT_DATA = {
  EMPLOYEE_IMPORT_DATA_LOAD: "EMPLOYEE_IMPORT_DATA_LOAD",
  EMPLOYEE_IMPORT_DATA_SUCCESS: "EMPLOYEE_IMPORT_DATA_SUCCESS",
  EMPLOYEE_IMPORT_DATA_FAIL: "EMPLOYEE_IMPORT_DATA_FAIL",
};

const EMPLOYEE_IMPORT_API = "/upload";

//Get designation tracker by emp id
const EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA = {
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_LOAD: 'EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_LOAD',
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_SUCCESS: 'EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_SUCCESS',
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_FAIL: 'EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_FAIL',
}

const EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_API = '/employee/v1/Designation/GetByEmp/'

//serverside paging query
const EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING = {
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_LOAD: "EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_LOAD",
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_SUCCESS: "EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_SUCCESS",
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_FAIL: "EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_FAIL"
}
const EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_API = '/employee/v1/designation/query'

//serverside paging rowcount
const EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING = {
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_LOAD: "EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_LOAD",
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_SUCCESS: "EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_SUCCESS",
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_FAIL: "EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_FAIL"
}
const EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_API = '/employee/v1/designation/rowCount'

//Add designation Tracker
const EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA = {
  EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_LOAD: 'EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_LOAD',
  EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_SUCCESS: 'EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_SUCCESS',
  EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_FAIL: 'EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_FAIL'
}

const EMPLOYEE_DESIGNATION_TRACKER_ADD_API = '/employee/v1/designation'

//edit designation tracker
const EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA = {
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_LOAD: 'EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_LOAD',
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_SUCCESS: 'EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_SUCCESS',
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_FAIL: 'EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_FAIL'
}

const EMPLOYEE_DESIGNATION_TRACKER_EDIT_API = '/employee/v1/Designation'

//delete designation tracker
const EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA = {
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_LOAD: 'EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_LOAD',
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_SUCCESS: 'EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_SUCCESS',
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_FAIL: 'EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_LOAD_FAIL',
}
const EMPLOYEE_DESIGNATION_TRACKER_DELETE_API = '/employee/v1/Designation'

//get employee type tracker
const EMPLOYEE_TYPE_TRACKER_DATA = {
  EMPLOYEE_TYPE_TRACKER_DATA_LOAD: 'EMPLOYEE_TYPE_DATA_TRACKER',
  EMPLOYEE_TYPE_TRACKER_DATA_SUCCESS: 'EMPLOYEE_TYPE_DATA_TRACKER',
  EMPLOYEE_TYPE_TRACKER_DATA_FAIL: 'EMPLOYEE_TYPE_DATA_TRACKER',
}

const EMPLOYEE_TYPE_TRACKER_API = '/employee/v1/EmpHireStatus'

//get employee type tracker by emp id
const EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA = {
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_LOAD: 'EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_LOAD',
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_SUCCESS: 'EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_SUCCESS',
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_FAIL: 'EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_FAIL',
}

const EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_API = '/employee/v1/EmpHireStatus/GetByEmp/'

//Add employee type Tracker
const EMPLOYEE_TYPE_TRACKER_ADD_DATA = {
  EMPLOYEE_TYPE_TRACKER_ADD_DATA_LOAD: 'EMPLOYEE_TYPE_TRACKER_ADD_DATA_LOAD',
  EMPLOYEE_TYPE_TRACKER_ADD_DATA_SUCCESS: 'EMPLOYEE_TYPE_TRACKER_ADD_DATA_SUCCESS',
  EMPLOYEE_TYPE_TRACKER_ADD_DATA_FAIL: 'EMPLOYEE_TYPE_TRACKER_ADD_DATA_FAIL'
}

const EMPLOYEE_TYPE_TRACKER_ADD_API = '/employee/v1/EmpHireStatus'

//edit employee type tracker
const EMPLOYEE_TYPE_TRACKER_EDIT_DATA = {
  EMPLOYEE_TYPE_TRACKER_EDIT_DATA_LOAD: 'EMPLOYEE_TYPE_TRACKER_EDIT_DATA_LOAD',
  EMPLOYEE_TYPE_TRACKER_EDIT_DATA_SUCCESS: 'EMPLOYEE_TYPE_TRACKER_EDIT_DATA_SUCCESS',
  EMPLOYEE_TYPE_TRACKER_EDIT_DATA_FAIL: 'EMPLOYEE_TYPE_TRACKER_EDIT_DATA_FAIL'
}

const EMPLOYEE_TYPE_TRACKER_EDIT_API = '/employee/v1/EmpHireStatus'

//delete employee type tracker
const EMPLOYEE_TYPE_TRACKER_DELETE_DATA = {
  EMPLOYEE_TYPE_TRACKER_DELETE_DATA_LOAD: 'EMPLOYEE_TYPE_TRACKER_DELETE_DATA_LOAD',
  EMPLOYEE_TYPE_TRACKER_DELETE_DATA_SUCCESS: 'EMPLOYEE_TYPE_TRACKER_DELETE_DATA_SUCCESS',
  EMPLOYEE_TYPE_TRACKER_DELETE_DATA_FAIL: 'EMPLOYEE_TYPE_TRACKER_DELETE_DATA_LOAD_FAIL',
}
const EMPLOYEE_TYPE_TRACKER_DELETE_API = '/employee/v1/EmpHireStatus'

const EMP_DESIGNATION_TRACKER_GET_DATA = {
  EMP_DESIGNATION_TRACKER_DATA_LOAD: "EMP_DESIGNATION_TRACKER_DATA_LOAD",
  EMP_DESIGNATION_TRACKER_DATA_SUCCESS: "EMP_DESIGNATION_TRACKER_DATA_SUCCESS",
  EMP_DESIGNATION_TRACKER_DATA_FAIL: "EMP_DESIGNATION_TRACKER_DATA_FAIL",
}

const EMP_DESIGNATION_TRACKER_GET_API = '/employee/v1/designation'

const EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA = {
  EMP_TYPE_HIRE_STATUS_TRACKER_DATA_LOAD: "EMP_TYPE_HIRE_STATUS_TRACKER_DATA_LOAD",
  EMP_TYPE_HIRE_STATUS_TRACKER_DATA_SUCCESS: "EMP_TYPE_HIRE_STATUS_TRACKER_DATA_SUCCESS",
  EMP_TYPE_HIRE_STATUS_TRACKER_DATA_FAIL: "EMP_TYPE_HIRE_STATUS_TRACKER_DATA_FAIL",
}

const EMP_TYPE_HIRE_STATUS_TRACKER_GET_API = '/employee/v1/EmpHireStatus'

//Get active employee
const ACTIVE_EMPLOYEE_DATA = {
  ACTIVE_EMPLOYEE_DATA_LOAD: "ACTIVE_EMPLOYEE_DATA_LOAD",
  ACTIVE_EMPLOYEE_DATA_SUCCESS: "ACTIVE_EMPLOYEE_DATA_SUCCESS",
  ACTIVE_EMPLOYEE_DATA_FAIL: "ACTIVE_EMPLOYEE_DATA_FAIL",
};
const ACTIVE_EMPLOYEE_GET_API = "/employee/v1/getAllActiveEmp";


export {
  EMPLOYEE_DATA,
  EMPLOYEE_GET_API,
  EMPLOYEE_COUNT,
  EMPLOYEE_COUNT_API,
  EMPLOYEE_GET_DATA,
  EMPLOYEE_GET_DETAIL_API,
  EMPLOYEE_ADD_DATA,
  EMPLOYEE_POST_API,
  EMPLOYEE_UPDATE_DATA,
  EMPLOYEE_PUT_API,
  EMPLOYEE_DELETE_DATA,
  EMPLOYEE_DELETE_API,
  EMPLOYEE_IMPORT_DATA,
  EMPLOYEE_IMPORT_API,
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_API,
  EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_ADD_API,
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_API,
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_API,
  EMPLOYEE_TYPE_TRACKER_DATA,
  EMPLOYEE_TYPE_TRACKER_API,
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA,
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_API,
  EMPLOYEE_TYPE_TRACKER_ADD_DATA,
  EMPLOYEE_TYPE_TRACKER_ADD_API,
  EMPLOYEE_TYPE_TRACKER_EDIT_DATA,
  EMPLOYEE_TYPE_TRACKER_EDIT_API,
  EMPLOYEE_TYPE_TRACKER_DELETE_DATA,
  EMPLOYEE_TYPE_TRACKER_DELETE_API,
  EMP_DESIGNATION_TRACKER_GET_DATA,
  EMP_DESIGNATION_TRACKER_GET_API,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_API,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_API,
  EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA,
  EMP_TYPE_HIRE_STATUS_TRACKER_GET_API,
  ACTIVE_EMPLOYEE_DATA,
  ACTIVE_EMPLOYEE_GET_API
}