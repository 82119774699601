import { QUATERLY_DATA} from './Constants'

const initialState = {
    loading: false,
    error: null,
    quaterly: []
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case QUATERLY_DATA.QUATERLY_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                quaterly:[]
            }
        case QUATERLY_DATA.QUATERLY_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                error:null,
                quaterly:action.quaterly
            }
        case QUATERLY_DATA.QUATERLY_DATA_FAIL:
            return{
                ...state,
                loading:false,
                error:action.error
            }
        
        default:
            return {
                ...state,
              };
            
    }
}

export default Reducer;