import { CANCEL, DELETE } from "../constants/Constants"
import DefaultButton from "./DefaultButton"
import Modalpopup from "./Modalpopup"

const DeleteModal = ({show, handleClose, handleDelete, children }) => {
    return <Modalpopup
        show={show}
        handleClose={() => { handleClose(false) }}
        actionTitle={"Delete"}
    >
        {children}

        <DefaultButton
            buttonClass={'btn-secondary me-1'}
            buttonTitle={CANCEL}
            actionBlock={() => handleClose(false)}
        />
        <DefaultButton
            buttonClass={'btn-danger'}
            buttonTitle={DELETE}
            actionBlock={handleDelete}
        />
    </Modalpopup>
}
export default DeleteModal