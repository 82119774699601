import { HIRE_TERM_QUEUE_DATA, UPDATE_HIRE_TERM_QUEUE } from './Constants'
const initialState = {
    loading: false,
    error: null,
    hireTermQueue: [],
}
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case HIRE_TERM_QUEUE_DATA.HIRE_TERM_QUEUE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                hireTermQueue: [],
                error: null
            }
        case HIRE_TERM_QUEUE_DATA.HIRE_TERM_QUEUE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hireTermQueue: action.hireTermQueue
            }
        case HIRE_TERM_QUEUE_DATA.HIRE_TERM_QUEUE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case UPDATE_HIRE_TERM_QUEUE.UPDATE_HIRE_TERM_QUEUE_LOAD:
            return {
                ...state,
                loading:true
            }
        case UPDATE_HIRE_TERM_QUEUE.UPDATE_HIRE_TERM_QUEUE_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case UPDATE_HIRE_TERM_QUEUE.UPDATE_HIRE_TERM_QUEUE_SUCCESS:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        default:
            return {
                ...state
            }
    }
}
export default Reducer