//Get
const HIRE_STATUS_DATA = {
    HIRE_STATUS_DATA_LOAD: "HIRE_STATUS_DATA_LOAD",
    HIRE_STATUS_DATA_SUCCESS: "HIRE_STATUS_DATA_SUCCESS",
    HIRE_STATUS_DATA_FAIL: "HIRE_STATUS_DATA_FAIL",
};
const HIRE_STATUS_GET_API = "/employee/v1/hireStatus";

// Get one by id
const HIRE_STATUS_GET_DATA = {
    HIRE_STATUS_GET_DATA_LOAD: "HIRE_STATUS_GET_DATA_LOAD",
    HIRE_STATUS_GET_DATA_SUCCESS: "HIRE_STATUS_GET_DATA_SUCCESS",
    HIRE_STATUS_GET_DATA_FAIL: "HIRE_STATUS_GET_DATA_FAIL",
};

const HIRE_STATUS_GET_DETAIL_API = "/employee/v1/hireStatus/";

// Create / Add / Post
const HIRE_STATUS_ADD_DATA = {
    HIRE_STATUS_ADD_DATA_LOAD: "HIRE_STATUS_ADD_DATA_LOAD",
    HIRE_STATUS_ADD_DATA_SUCCESS: "HIRE_STATUS_ADD_DATA_SUCCESS",
    HIRE_STATUS_ADD_DATA_FAIL: "HIRE_STATUS_ADD_DATA_FAIL",
};

const HIRE_STATUS_POST_API = "/employee/v1/hireStatus";

//Update
const HIRE_STATUS_UPDATE_DATA = {
  HIRE_STATUS_UPDATE_DATA_LOAD: "HIRE_STATUS_UPDATE_DATA_UPLOAD",
  HIRE_STATUS_UPDATE_DATA_SUCCESS: "HIRE_STATUS_UPDATE_DATA_SUCCESS",
  HIRE_STATUS_UPDATE_DATA_FAIL: "HIRE_STATUS_UPDATE_DATA_FAIL",
};

const HIRE_STATUS_PUT_API = "/employee/v1/hireStatus";

// Delete
const HIRE_STATUS_DELETE_DATA = {
  HIRE_STATUS_DELETE_DATA_LOAD: "HIRE_STATUS_DELETE_DATA_LOAD",
  HIRE_STATUS_DELETE_DATA_SUCCESS: "HIRE_STATUS_DELETE_DATA_SUCCESS",
  HIRE_STATUS_DELETE_DATA_FAIL: "HIRE_STATUS_DELETE_DATA_FAIL",
};

const HIRE_STATUS_DELETE_API = "/employee/v1/hireStatus";


export{
    HIRE_STATUS_DATA,
    HIRE_STATUS_GET_API,
    HIRE_STATUS_GET_DATA,
    HIRE_STATUS_GET_DETAIL_API,
    HIRE_STATUS_ADD_DATA,
    HIRE_STATUS_POST_API,
    HIRE_STATUS_UPDATE_DATA,
    HIRE_STATUS_PUT_API,
    HIRE_STATUS_DELETE_DATA,
    HIRE_STATUS_DELETE_API,
}