import {
  EMPLOYEE_DATA,
  EMPLOYEE_GET_DATA,
  EMPLOYEE_ADD_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA,
  EMPLOYEE_UPDATE_DATA,
  EMPLOYEE_DELETE_DATA,
  EMPLOYEE_IMPORT_DATA,
  EMPLOYEE_TYPE_TRACKER_DATA,
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA,
  EMP_DESIGNATION_TRACKER_GET_DATA,
  EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA,
  ACTIVE_EMPLOYEE_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA,
  EMPLOYEE_TYPE_TRACKER_ADD_DATA,
  EMPLOYEE_TYPE_TRACKER_EDIT_DATA,
  EMPLOYEE_COUNT,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_API,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING
} from "./Constants";
const initialState = {
  loading: false,
  error: null,
  employees: [],
  active_employees: [],
  employee: {},
  designationTrackers: [],
  typeTrackers: [],
  empDesignation: [],
  empHireStatus: [],
  rowCount: 0,
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    //load all
    case EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        employees: [],
        error: null,
      };
    case EMPLOYEE_DATA.EMPLOYEE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        employees: action.employees,
      };
    case EMPLOYEE_DATA.EMPLOYEE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        // employees: [{ id: '1', emp_number: '135074', first_name: 'Aaidan', last_name: 'Farmer', preferred_name: '', ntid: 'AFarmer10', cnumber: 'C64422960', job_title_id: 'ME', department_id: 'Sales', email_work: 'aaidan.farmer10@t-mobile.com', email_personal: 'aaidanfarmer@gmail.com', hire_date: '7/12/2021', term_date: '', term_reason: '', emp_type: 'Full Time', hire_status: 'New Hire', leave_type: '', base_store: '5595', district_id: 'NV/OR', region_id: 'Pacific Northwest', division_id: 'WV-Sales', company_id: '1', work_phone: '', personal_phone: '', home_address: '', supervisor: 'F', reporting_manager: '', emp_status: 'A', t_shirt_size: 'M', odoo_id: '65097', }]
      };
    case EMPLOYEE_COUNT.EMPLOYEE_COUNT_LOAD:
      return {
        ...state,

      }
    case EMPLOYEE_COUNT.EMPLOYEE_COUNT_SUCCESS:
      return {
        ...state,
        rowCount: action.count
      }
    case EMPLOYEE_COUNT.EMPLOYEE_COUNT_FAIL:
      return {
        ...state,
        error: action.error
      }
    //load single
    case EMPLOYEE_GET_DATA.EMPLOYEE_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        employee: {},
        error: null,
      };

    case EMPLOYEE_GET_DATA.EMPLOYEE_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.employee[0],
      };

    case EMPLOYEE_GET_DATA.EMPLOYEE_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        // employee: { id: '1', emp_number: '135074', first_name: 'Aaidan', last_name: 'Farmer', preferred_name: '', ntid: 'AFarmer10', cnumber: 'C64422960', job_title_id: 'ME', department_id: 'Sales', email_work: 'aaidan.farmer10@t-mobile.com', email_personal: 'aaidanfarmer@gmail.com', hire_date: '7/12/2021', term_date: '', term_reason: '', emp_type: 'Full Time', hire_status: 'New Hire', leave_type: '', base_store: '5595', district_id: 'NV/OR', region_id: 'Pacific Northwest', division_id: 'WV-Sales', company_id: '1', work_phone: '', personal_phone: '', home_address: '', supervisor: 'F', reporting_manager: '', emp_status: 'A', t_shirt_size: 'M', odoo_id: '65097', },
        // designationTracker: [{ id: '1', emp_number: '135074', job_title_id: '2', job_title_name: 'ME', covering_store: false, store_id: '34', store_name: '5595', district_name: "district",market_id:'201', region_name: "Midwest",region_id:'390', division_name: "division",division_id:'79',leave_type_name:"personal",leave_type_id:"2", start_date: '1/1/2022', end_date: '12/31/2099' }]
      };
    case EMPLOYEE_ADD_DATA.EMPLOYEE_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EMPLOYEE_ADD_DATA.EMPLOYEE_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EMPLOYEE_ADD_DATA.EMPLOYEE_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EMPLOYEE_UPDATE_DATA.EMPLOYEE_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EMPLOYEE_UPDATE_DATA.EMPLOYEE_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EMPLOYEE_UPDATE_DATA.EMPLOYEE_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EMPLOYEE_DELETE_DATA.EMPLOYEE_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EMPLOYEE_DELETE_DATA.EMPLOYEE_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EMPLOYEE_DELETE_DATA.EMPLOYEE_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EMPLOYEE_IMPORT_DATA.EMPLOYEE_IMPORT_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EMPLOYEE_IMPORT_DATA.EMPLOYEE_IMPORT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EMPLOYEE_IMPORT_DATA.EMPLOYEE_IMPORT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_SUCCESS:
      return {
        ...state,
        loading: false,
        empDesignation: action.designationTrackers,
      }
    case EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_SUCCESS:
      return {
        ...state,
        loading: false,
        rowCount: action.count
      }
    case EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
        designationTrackers: [],
      };
    case EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        designationTrackers: action.designationTrackers,
      };
    case EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA.EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA.EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA.EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA.EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA.EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA.EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA.EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA.EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA.EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EMPLOYEE_TYPE_TRACKER_DATA.EMPLOYEE_TYPE_TRACKER_DATA_LOAD:
      return {
        ...state,
        loading: true,
        typeTrackers: [],
        error: null,
      };
    case EMPLOYEE_TYPE_TRACKER_DATA.EMPLOYEE_TYPE_TRACKER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        typeTrackers: action.typeTrackers,
      };
    case EMPLOYEE_TYPE_TRACKER_DATA.EMPLOYEE_TYPE_TRACKER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        typeTrackers: [],
      };
    case EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        typeTrackers: action.typeTrackers,
      };
    case EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //employee hire status tracker add
    case EMPLOYEE_TYPE_TRACKER_ADD_DATA.EMPLOYEE_TYPE_TRACKER_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case EMPLOYEE_TYPE_TRACKER_ADD_DATA.EMPLOYEE_TYPE_TRACKER_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case EMPLOYEE_TYPE_TRACKER_ADD_DATA.EMPLOYEE_TYPE_TRACKER_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    // employee hire status tracker edit
    case EMPLOYEE_TYPE_TRACKER_EDIT_DATA.EMPLOYEE_TYPE_TRACKER_EDIT_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null
      }
    case EMPLOYEE_TYPE_TRACKER_EDIT_DATA.EMPLOYEE_TYPE_TRACKER_EDIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case EMPLOYEE_TYPE_TRACKER_EDIT_DATA.EMPLOYEE_TYPE_TRACKER_EDIT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    //Get all designation tracker
    case EMP_DESIGNATION_TRACKER_GET_DATA.EMP_DESIGNATION_TRACKER_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        empDesignation: []
      }
    case EMP_DESIGNATION_TRACKER_GET_DATA.EMP_DESIGNATION_TRACKER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        empDesignation: action.empDesignation,
        error: null
      }
    case EMP_DESIGNATION_TRACKER_GET_DATA.EMP_DESIGNATION_TRACKER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        empDesignation: []
      }
    //Get all emp type hire status tracker
    case EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA.EMP_TYPE_HIRE_STATUS_TRACKER_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        empHireStatus: []
      }
    case EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA.EMP_TYPE_HIRE_STATUS_TRACKER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        empHireStatus: action.empHireStatus,
        error: null
      }
    case EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA.EMP_TYPE_HIRE_STATUS_TRACKER_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        empHireStatus: []
      }
    // Get active employee
    case ACTIVE_EMPLOYEE_DATA.ACTIVE_EMPLOYEE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACTIVE_EMPLOYEE_DATA.ACTIVE_EMPLOYEE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        active_employees: action.employees,
      };
    case ACTIVE_EMPLOYEE_DATA.ACTIVE_EMPLOYEE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
