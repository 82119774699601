import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const RegionalSupport = ({info}) => {

  console.log("i", info)
  
  return (
    <>
      <Row>
        <Col md={8}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Title</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
            {info.map((data) =>
              <tr>
                <td>{data.title}</td>
                <td>{data.first_name} {data.last_name}</td>
                <td>{data.personal_phone}</td>
                <td>
                  <a href="#">{data.email_work}</a>
                </td>
              </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default RegionalSupport;
