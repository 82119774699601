import { takeLatest, put, call } from "redux-saga/effects";
import {

    DEPARTMENT_DATA, DEPARTMENT_GET_API,
    DEPARTMENT_GET_DATA, DEPARTMENT_GET_DETAIL_API,
    DEPARTMENT_ADD_DATA, DEPARTMENT_POST_API,
    DEPARTMENT_UPDATE_DATA, DEPARTMENT_PUT_API,
    DEPARTMENT_DELETE_DATA, DEPARTMENT_DELETE_API,
    DEPARTMENT_IMPORT_DATA, DEPARTMENT_IMPORT_API,
} from './Constants'
import {
    setDepartmentDataSuccess, setDepartmentDataFail,
    setDepartmentGetDataSuccess, setDepartmentGetDataFail,
    setDepartmentAddDataSuccess, setDepartmentAddDataFail,
    setDepartmentUpdateDataSuccess, setDepartmentUpdateDataFail,
    setDepartmentDeleteDataSuccess, setDepartmentDeleteDataFail,
    setDepartmentImportDataSuccess, setDepartmentImportDataFail
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest, postImportRequest } from "../../../shared/components/Service";



function* getDepartmentsDataService() {
    try {
        const response = yield call(getRequest, DEPARTMENT_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setDepartmentDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setDepartmentDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving departments"))
        yield put(setDepartmentDataFail(error));
    }
}

function* getDepartmentDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            DEPARTMENT_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setDepartmentGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setDepartmentGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving department info"))
        yield put(setDepartmentGetDataFail(error));
    }
}

function* postDepartmentDataService({ request }) {
    try {
        // let requestData = {
        //     to_insert:request
        // }
        const response = yield call(postRequest, DEPARTMENT_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Department created"))
            yield put(setDepartmentAddDataSuccess(newData));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setDepartmentAddDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error adding department"))
        yield put(setDepartmentAddDataFail(error));
    }
}
function* putDepartmentDataService({ request }) {
    try {
        // let requestData = {
        //     to_id:{
        //         id:request.id
        //     },
        //     to_update:{
        //         ...request
        //     }
        // }
        const response = yield call(putRequest, DEPARTMENT_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Department updated"))
            yield put(setDepartmentUpdateDataSuccess(response.data));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setDepartmentUpdateDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error editing department"))
        yield put(setDepartmentUpdateDataFail(error));
    }
}

function* deleteDepartmentDataService({ request }) {
    try {
        const response = yield call(deleteRequest, DEPARTMENT_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setDepartmentDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setDepartmentDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setDepartmentDeleteDataFail(error));
    }
}
function* postImportDepartmentDataService({ request }) {
  try {
    const importDepartmentDataResponse = yield call(
      postImportRequest,
      DEPARTMENT_IMPORT_API,
      request
    );
    const status = importDepartmentDataResponse.status;
    if (status === 200) {
      const newData = importDepartmentDataResponse.data;
      yield put(setDepartmentImportDataSuccess(newData));
    } else {
      yield put(setDepartmentImportDataFail(importDepartmentDataResponse.response.data));
    }
  } catch (error) {
    yield put(setDepartmentImportDataFail(error));
  }
}

export function* watchDepartment(){
    yield takeLatest(DEPARTMENT_DATA.DEPARTMENT_DATA_LOAD, getDepartmentsDataService);
    yield takeLatest(DEPARTMENT_GET_DATA.DEPARTMENT_GET_DATA_LOAD, getDepartmentDataService);
    yield takeLatest(DEPARTMENT_ADD_DATA.DEPARTMENT_ADD_DATA_LOAD, postDepartmentDataService);
    yield takeLatest(DEPARTMENT_UPDATE_DATA.DEPARTMENT_UPDATE_DATA_LOAD, putDepartmentDataService);
    yield takeLatest(DEPARTMENT_DELETE_DATA.DEPARTMENT_DELETE_DATA_LOAD, deleteDepartmentDataService);  
    yield takeLatest(DEPARTMENT_IMPORT_DATA.DEPARTMENT_IMPORT_DATA_LOAD, postImportDepartmentDataService);
}