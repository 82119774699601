import { takeLatest, put, call } from "redux-saga/effects";
import {
  TERM_REASON_DATA,
  TERM_REASON_GET_API,
  TERM_REASON_GET_DATA,
  TERM_REASON_GET_DETAIL_API,
  TERM_REASON_ADD_DATA,
  TERM_REASON_POST_API,
  TERM_REASON_UPDATE_DATA,
  TERM_REASON_PUT_API,
  TERM_REASON_DELETE_DATA,
  TERM_REASON_DELETE_API,
} from "./Constants";
import {
  setTermReasonDataSuccess,
  setTermReasonDataFail,
  setTermReasonGetDataSuccess,
  setTermReasonGetDataFail,
  setTermReasonAddDataSuccess,
  setTermReasonAddDataFail,
  setTermReasonUpdateDataSuccess,
  setTermReasonUpdateDataFail,
  setTermReasonDeleteDataSuccess,
  setTermReasonDeleteDataFail,
} from "./Actions";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import {
  getRequest,
  getDetailRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../../../shared/components/Service";

function* getTermReasonsDataService() {
  try {
    const response = yield call(getRequest, TERM_REASON_GET_API);
    if (response.status === 200) {
      const data = response.data;
      yield put(setTermReasonDataSuccess(data));
    } else {
      yield put(addErrorAlert(response.response.data.msg));
      yield put(setTermReasonDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error retrieving Term Reasons"));
    yield put(setTermReasonDataFail(error));
  }
}

function* getTermReasonDataService({ id }) {
  try {
    const response = yield call(
      getDetailRequest,
      TERM_REASON_GET_DETAIL_API + id
    );
    if (response.status === 200) {
      const newData = response.data;
      yield put(setTermReasonGetDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg));
      yield put(setTermReasonGetDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error retrieving Term Reason info"));
    yield put(setTermReasonGetDataFail(error));
  }
}

function* postTermReasonDataService({ request }) {
  try {
    // let requestData = {
    //   to_insert: request,
    // };
    const response = yield call(
      postRequest,
      TERM_REASON_POST_API,
      request
    );
    if (response.status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Term Reason created"));
      yield put(setTermReasonAddDataSuccess(newData));
    } else {
      // yield put(addErrorAlert(response.response.data.msg));
      yield put(setTermReasonAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Error adding Term Reason"));
    yield put(setTermReasonAddDataFail(error));
  }
}
function* putTermReasonDataService({ request }) {
  try {
    // let requestData = {
    //   to_id: {
    //     id: request.id,
    //   },
    //   to_update: {
    //     ...request,
    //   },
    // };
    const response = yield call(putRequest, TERM_REASON_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Term Reason updated"));
      yield put(setTermReasonUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg));
      yield put(setTermReasonUpdateDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Error editing Term Reason"));
    yield put(setTermReasonUpdateDataFail(error));
  }
}

function* deleteTermReasonDataService({ request }) {
  try {
    const response = yield call(
      deleteRequest,
      TERM_REASON_DELETE_API,
      request
    );
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg));
      yield put(setTermReasonDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg));
      yield put(setTermReasonDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error));
    yield put(setTermReasonDeleteDataFail(error));
  }
}

export function* watchTermReason() {
  yield takeLatest(
    TERM_REASON_DATA.TERM_REASON_DATA_LOAD,
    getTermReasonsDataService
  );
  yield takeLatest(
    TERM_REASON_GET_DATA.TERM_REASON_GET_DATA_LOAD,
    getTermReasonDataService
  );
  yield takeLatest(
    TERM_REASON_ADD_DATA.TERM_REASON_ADD_DATA_LOAD,
    postTermReasonDataService
  );
  yield takeLatest(
    TERM_REASON_UPDATE_DATA.TERM_REASON_UPDATE_DATA_LOAD,
    putTermReasonDataService
  );
  yield takeLatest(
    TERM_REASON_DELETE_DATA.TERM_REASON_DELETE_DATA_LOAD,
    deleteTermReasonDataService
  );
}
