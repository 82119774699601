import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import DefaultButton from "../../../shared/components/DefaultButton";
import {
  CANCEL,
  EMPLOYEE_TYPE,
  HOURS_PER_WEEK,
  SAVE,
} from "../../../shared/constants/Constants";

const EmployeeTypeForm = ({ employeeType, submit, cancel }) => {
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    }
    return errors;
  };

  // const formik = useFormik({});

  // useEffect(() => {
  //     if (employeeType) {
  //         formik.current.setFieldValue("id", employeeType?.id);
  //         formik.current.setFieldValue("name", employeeType?.name);
  //         formik.current.setFieldValue("hours_per_week",employeeType?.hours_per_week)
  //     }
  // }, [employeeType, formik]);

  return (
    <Formik
    //   innerRef={formik}
      initialValues={{
        id: employeeType?.id ?? null,
        name: employeeType?.name ?? "",
        hours_per_week: employeeType?.hours_per_week ?? "",
      }}
      validate={validate}
      onSubmit={submit}
      enableReinitialize
    >
      {() => (
        <Form className="row g-4">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              {EMPLOYEE_TYPE}
              <span className="error-text">*</span>
            </label>
            <Field
              className="form-control"
              type="text"
              name="name"
              placeholder={HOURS_PER_WEEK}
              autoComplete="off"
            />
            <span className="error-text">
              <ErrorMessage name="name" />
            </span>
          </div>
          <div className="col-md-6">
            <label htmlFor="hours_per_week" className="form-label">
              {HOURS_PER_WEEK}
            </label>
            <Field
              className="form-control"
              type="text"
              name="hours_per_week"
              placeholder="Hours"
              autoComplete="off"
            />
          </div>
          <div className="col-md-12">
            <DefaultButton
              buttonClass={"btn btn-secondary me-2"}
              buttonTitle={CANCEL}
              actionBlock={cancel}
            />
            <DefaultButton
              buttonClass={"default-btn-color"}
              buttonTitle={SAVE}
              buttonType={"submit"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EmployeeTypeForm;
