import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadLeaveTypeUpdateDataRequest } from "../redux/Actions";
import LeaveTypeForm from "./LeaveTypeForm";

const LeaveTypeEdit = ({ handleClose, leaveType }) => {
  const dispatch = useDispatch();
  let [shouldCloseModal, setShouldCloseModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState(false);

  let { loading, error } = useSelector((state) => state.leaveType);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true)
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false)
    }
  }, [loading, error, shouldCloseModal])

  function submit(values) {
    dispatch(loadLeaveTypeUpdateDataRequest(values));
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return <>
    <FormError>
      {errorMessage}
    </FormError>
    <LeaveTypeForm submit={submit} cancel={cancel} leaveType={leaveType} />;
  </>
};

export default LeaveTypeEdit;
