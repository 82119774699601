import { FULLPART_TIME_DATA, FULLPART_TIME_GET_DATA, FULLPART_TIME_ADD_DATA, FULLPART_TIME_UPDATE_DATA, FULLPART_TIME_DELETE_DATA, } from './Constants'
const initialState = {
    loading: false,
    error: null,
    fullPartTimes: [],
    fullPartTime:{}
}
const Reducer = (state = initialState, action) => {
    
    switch (action.type) {
        //load all
        case FULLPART_TIME_DATA.FULLPART_TIME_DATA_LOAD:
            return {
                ...state,
                loading: true,
                fullPartTimes:[],
                error:null
            }
        case FULLPART_TIME_DATA.FULLPART_TIME_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                fullPartTimes:action.fullPartTimes
            }
        case FULLPART_TIME_DATA.FULLPART_TIME_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        //load single
        case FULLPART_TIME_GET_DATA.FULLPART_TIME_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                fullPartTime:{},
                error:null
            }                

        case FULLPART_TIME_GET_DATA.FULLPART_TIME_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                fullPartTime:action.fullPartTime
            }                

        case FULLPART_TIME_GET_DATA.FULLPART_TIME_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case FULLPART_TIME_ADD_DATA.FULLPART_TIME_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case FULLPART_TIME_ADD_DATA.FULLPART_TIME_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case FULLPART_TIME_ADD_DATA.FULLPART_TIME_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case FULLPART_TIME_UPDATE_DATA.FULLPART_TIME_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case FULLPART_TIME_UPDATE_DATA.FULLPART_TIME_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case FULLPART_TIME_UPDATE_DATA.FULLPART_TIME_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case FULLPART_TIME_DELETE_DATA.FULLPART_TIME_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case FULLPART_TIME_DELETE_DATA.FULLPART_TIME_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case FULLPART_TIME_DELETE_DATA.FULLPART_TIME_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        
        default:
            return {
                ...state
            }
    }
}
export default Reducer