import axios from "axios";
import { API_URL } from "../constants/Constants";

const instance = axios.create({
  baseURL: API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

const loginInstance = axios.create({
  baseURL: API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  }
});

const setTokenToHeader = (token) => {
  instance.defaults.headers.common["x-api-token"] = token;
};

if (localStorage.getItem("jwt_token")){
  setTokenToHeader(localStorage.getItem('jwt_token'));
}

export { instance, setTokenToHeader, loginInstance };
