import { takeLatest, put, call } from "redux-saga/effects";
import {
    HOURLY_SALARY_DATA, HOURLY_SALARY_GET_API,
    HOURLY_SALARY_GET_DATA, HOURLY_SALARY_GET_DETAIL_API,
    HOURLY_SALARY_ADD_DATA, HOURLY_SALARY_POST_API,
    HOURLY_SALARY_UPDATE_DATA, HOURLY_SALARY_PUT_API,
    HOURLY_SALARY_DELETE_DATA, HOURLY_SALARY_DELETE_API,
} from './Constants'
import {
    setHourlySalaryDataSuccess, setHourlySalaryDataFail,
    setHourlySalaryGetDataSuccess, setHourlySalaryGetDataFail,
    setHourlySalaryAddDataSuccess, setHourlySalaryAddDataFail,
    setHourlySalaryUpdateDataSuccess, setHourlySalaryUpdateDataFail,
    setHourlySalaryDeleteDataSuccess, setHourlySalaryDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";



function* getHourlySalariesDataService() {
    try {
        const response = yield call(getRequest, HOURLY_SALARY_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setHourlySalaryDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setHourlySalaryDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee types"))
        yield put(setHourlySalaryDataFail(error));
    }
}

function* getHourlySalaryDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            HOURLY_SALARY_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setHourlySalaryGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setHourlySalaryGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee type info"))
        yield put(setHourlySalaryGetDataFail(error));
    }
}

function* postHourlySalaryDataService({ request }) {
    try {
        // let requestData = {
        //     to_insert:request
        // }
        const response = yield call(postRequest, HOURLY_SALARY_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("HourlySalary created"))
            yield put(setHourlySalaryAddDataSuccess(newData));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setHourlySalaryAddDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error adding employee type"))
        yield put(setHourlySalaryAddDataFail(error));
    }
}
function* putHourlySalaryDataService({ request }) {
    try {
        // let requestData = {
        //     to_id:{
        //         id:request.id
        //     },
        //     to_update:{
        //         ...request
        //     }
        // }
        const response = yield call(putRequest, HOURLY_SALARY_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("HourlySalary updated"))
            yield put(setHourlySalaryUpdateDataSuccess(response.data));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setHourlySalaryUpdateDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error editing employee type"))
        yield put(setHourlySalaryUpdateDataFail(error));
    }
}

function* deleteHourlySalaryDataService({ request }) {
    try {
        const response = yield call(deleteRequest, HOURLY_SALARY_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setHourlySalaryDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setHourlySalaryDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setHourlySalaryDeleteDataFail(error));
    }
}

export function* watchHourlySalary(){
    yield takeLatest(HOURLY_SALARY_DATA.HOURLY_SALARY_DATA_LOAD, getHourlySalariesDataService);
    yield takeLatest(HOURLY_SALARY_GET_DATA.HOURLY_SALARY_GET_DATA_LOAD, getHourlySalaryDataService);
    yield takeLatest(HOURLY_SALARY_ADD_DATA.HOURLY_SALARY_ADD_DATA_LOAD, postHourlySalaryDataService);
    yield takeLatest(HOURLY_SALARY_UPDATE_DATA.HOURLY_SALARY_UPDATE_DATA_LOAD, putHourlySalaryDataService);
    yield takeLatest(HOURLY_SALARY_DELETE_DATA.HOURLY_SALARY_DELETE_DATA_LOAD, deleteHourlySalaryDataService);  
}