import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField"
import DefaultButton from "../../../shared/components/DefaultButton";
import { CANCEL, END_DATE, HIRE_STATUS, HOURLY_SALARY, PART_FULL_TIME, SAVE, STARTAFTEREND, STARTNOTFUTURE, START_DATE } from "../../../shared/constants/Constants";
import FormTextField from "../../../shared/components/FormTextField";
import { Col } from "react-bootstrap";
import { loadHireStatusDataRequest } from "../../hire-status/redux/Actions";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { loadFullPartTimeDataRequest } from "../../full_part_time_status/redux/Actions";
import { loadHourlySalaryDataRequest } from "../../hourly_salary_status/redux/Actions";
import FormatDate from "../../../shared/components/FormatDate";

const EmployeeTypeForm = ({ tracker, submit, cancel, employee }) => {
    const dispatch = useDispatch()
    const validate = (values) => {
        const errors = {};

        if (!values.start_date) {
            errors.start_date = "Required"
        }
        if (!values.end_date) {
            errors.end_date = "Required"
        }
        if (new Date(values.start_date) >= new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        return errors;
    };
    const { hireStatuses } = useSelector(store => store.hireStatus)
    const { fullPartTimes } = useSelector((state) => state.fullPartTime);
    const { hourlySalaries } = useSelector((state) => state.hourlySalary)

    useEffect(() => {
        if (hireStatuses.length === 0) {
            dispatch(loadHireStatusDataRequest());
        }
        if (fullPartTimes.length === 0) {
            dispatch(loadFullPartTimeDataRequest());
        }
        if (hourlySalaries.length === 0) {
            dispatch(loadHourlySalaryDataRequest());
        }
    }, [dispatch, hireStatuses.length, fullPartTimes.length])

    let trackStartDate = '';
    let trackEndDate = '12/31/2099';
    if (tracker && tracker?.start_date && tracker?.end_date) {
        if (tracker?.start_date.includes("-") && tracker?.end_date.includes("-")) {
            trackStartDate = FormatDate(tracker?.start_date);
            trackEndDate = FormatDate(tracker?.end_date);
        }
    }

    return <Formik
        initialValues={{
            id: tracker?.id,
            employee_id: employee?.employee_id,
            // emp_number: employee?.emp_number,
            hire_status: tracker?.hire_status,
            full_part_time_id: tracker?.full_part_time_id,
            hourly_salary_id: tracker?.hourly_salary_id,
            start_date: trackStartDate,
            end_date: trackEndDate,
        }}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                {/* <FormTextField name="employee_id" label='Employee Number' disabled /> */}
                <div className="col-md-6">
                    <label htmlFor="employee_id" className="form-label">
                        Employee Number
                    </label>
                    <Field name="emp_name_num" diabled={true} className="form-control" type='text' readOnly={true} defaultValue={(employee?.first_name ?? "") + " " + (employee?.last_name ?? "") + ", " + (employee?.employee_id ?? '')} />
                    <Field type="hidden" name="employee_id" />
                </div>

                <div className="col-md-6">
                    <label htmlFor={'hire_status'} className="form-label">
                        {HIRE_STATUS}
                    </label>
                    <FormikSelectField
                        name='hire_status'
                        value={values.hire_status}
                        onChange={setFieldValue}
                        placeholder={HIRE_STATUS}
                    >
                        {hireStatuses.filter(hireStatus=>hireStatus.is_available).map(hireStatus =>
                            <option value={hireStatus.id}>
                                {hireStatus.name}
                            </option>
                        )}
                    </FormikSelectField>
                    <span className="error-text">
                        <ErrorMessage name={'hire_status'} />
                    </span>
                </div>

                <div className="col-md-6">
                    <label htmlFor={'full_part_time'} className="form-label">
                        {PART_FULL_TIME}
                    </label>
                    <FormikSelectField
                        name='full_part_time_id'
                        value={values.full_part_time_id}
                        onChange={setFieldValue}
                        placeholder={PART_FULL_TIME}
                    >
                        {fullPartTimes.map(fullPartTime =>
                            <option value={fullPartTime.id}>
                                {fullPartTime.name}
                            </option>
                        )}
                    </FormikSelectField>
                    <span className="error-text">
                        <ErrorMessage name={'full_part_time'} />
                    </span>
                </div>

                <div className="col-md-6">
                    <label htmlFor={'hourly_salary_id'} className="form-label">
                        {HOURLY_SALARY}
                    </label>
                    <FormikSelectField
                        name='hourly_salary_id'
                        value={values.hourly_salary_id}
                        onChange={setFieldValue}
                        placeholder={HOURLY_SALARY}
                    >
                        {hourlySalaries.map(hourlySalary =>
                            <option value={hourlySalary.id}>
                                {hourlySalary.name}
                            </option>
                        )}
                    </FormikSelectField>
                    <span className="error-text">
                        <ErrorMessage name={'hourly_salary_id'} />
                    </span>
                </div>

                <div className="col-md-6 datepicker">
                    <label htmlFor={'start_date'} className="form-label">
                        {START_DATE}<span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField
                        name="start_date"
                        value={values.start_date}
                        onChange={setFieldValue}
                        handleBlur={setFieldTouched}
                        placeholder={START_DATE}
                    />
                    <span className="error-text">
                        <ErrorMessage name={'start_date'} />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label htmlFor={'end_date'} className="form-label">
                        {END_DATE}<span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField
                        name="end_date"
                        value={values.end_date}
                        onChange={setFieldValue}
                        handleBlur={setFieldTouched}
                        placeholder={END_DATE}
                    />
                    <span className="error-text">
                        <ErrorMessage name={'end_date'} />
                    </span>
                </div>
                <Col md={12}>
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"btn btn-secondary default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik>
}

export default EmployeeTypeForm