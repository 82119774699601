import { LEAVE_TYPE_DATA, LEAVE_TYPE_GET_DATA, LEAVE_TYPE_ADD_DATA, LEAVE_TYPE_UPDATE_DATA, LEAVE_TYPE_DELETE_DATA, } from './Constants'
const initialState = {
    loading: false,
    error: null,
    leaveTypes: [],
    leaveType:{}
}
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case LEAVE_TYPE_DATA.LEAVE_TYPE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                leaveTypes:[],
                error:null
            }
        case LEAVE_TYPE_DATA.LEAVE_TYPE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                leaveTypes:action.leaveTypes
            }
        case LEAVE_TYPE_DATA.LEAVE_TYPE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        //load single
        case LEAVE_TYPE_GET_DATA.LEAVE_TYPE_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                leaveType:{},
                error:null
            }                

        case LEAVE_TYPE_GET_DATA.LEAVE_TYPE_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                leaveType:action.leaveType
            }                

        case LEAVE_TYPE_GET_DATA.LEAVE_TYPE_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case LEAVE_TYPE_ADD_DATA.LEAVE_TYPE_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case LEAVE_TYPE_ADD_DATA.LEAVE_TYPE_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case LEAVE_TYPE_ADD_DATA.LEAVE_TYPE_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case LEAVE_TYPE_UPDATE_DATA.LEAVE_TYPE_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case LEAVE_TYPE_UPDATE_DATA.LEAVE_TYPE_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case LEAVE_TYPE_UPDATE_DATA.LEAVE_TYPE_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case LEAVE_TYPE_DELETE_DATA.LEAVE_TYPE_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case LEAVE_TYPE_DELETE_DATA.LEAVE_TYPE_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case LEAVE_TYPE_DELETE_DATA.LEAVE_TYPE_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        default:
            return {
                ...state
            }
    }
}
export default Reducer