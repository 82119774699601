import {
  TERM_REASON_DATA,
  TERM_REASON_GET_DATA,
  TERM_REASON_ADD_DATA,
  TERM_REASON_UPDATE_DATA,
  TERM_REASON_DELETE_DATA,
} from "./Constants";
const initialState = {
  loading: false,
  error: null,
  termReasons: [],
  termReason: {},
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    //load all
    case TERM_REASON_DATA.TERM_REASON_DATA_LOAD:
      return {
        ...state,
        loading: true,
        termReasons: [],
        error: null,
      };
    case TERM_REASON_DATA.TERM_REASON_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        termReasons: action.termReasons,
      };
    case TERM_REASON_DATA.TERM_REASON_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //load single
    case TERM_REASON_GET_DATA.TERM_REASON_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        termReason: {},
        error: null,
      };

    case TERM_REASON_GET_DATA.TERM_REASON_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        termReason: action.termReason,
      };

    case TERM_REASON_GET_DATA.TERM_REASON_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TERM_REASON_ADD_DATA.TERM_REASON_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TERM_REASON_ADD_DATA.TERM_REASON_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TERM_REASON_ADD_DATA.TERM_REASON_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TERM_REASON_UPDATE_DATA.TERM_REASON_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TERM_REASON_UPDATE_DATA.TERM_REASON_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TERM_REASON_UPDATE_DATA.TERM_REASON_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TERM_REASON_DELETE_DATA.TERM_REASON_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TERM_REASON_DELETE_DATA.TERM_REASON_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TERM_REASON_DELETE_DATA.TERM_REASON_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
