import {
  MARKET_DATA,
  MARKET_ADD_DATA,
  MARKET_UPDATE_DATA,
  MARKET_GET_DATA,
  MARKET_DELETE_DATA,
  MARKET_GET_TRACKER_DATA,
  MARKET_DELETE_HISTORY,
  MARKET_IMPORT_DATA,
  MARKET_TRACKER_IMPORT_DATA,
  MARKET_GET_STORES,
} from "./Constants";

//For listing
const loadMarketDataRequest = () => ({
  type: MARKET_DATA.MARKET_DATA_LOAD
})
const setMarketDataSuccess = (markets) => ({
  type: MARKET_DATA.MARKET_DATA_SUCCESS,
  markets
})
const setMarketDataFail = (error) => ({
  type: MARKET_DATA.MARKET_DATA_FAIL,
  error
})

//For add Market
const loadMarketAddDataRequest = (request) => ({
  type: MARKET_ADD_DATA.MARKET_ADD_DATA_LOAD,
  request
})
const setMarketAddDataSuccess = (market) => ({
  type: MARKET_ADD_DATA.MARKET_ADD_DATA_SUCCESS,
  market
})

const setMarketAddDataFail = (error) => ({
  type: MARKET_ADD_DATA.MARKET_ADD_DATA_FAIL,
  error
})


//For Edit Market
const loadMarketUpdateDataRequest = (request) => ({
  type: MARKET_UPDATE_DATA.MARKET_UPDATE_DATA_LOAD,
  request
})

const setMarketUpdateDataSuccess = (market) => ({
  type: MARKET_UPDATE_DATA.MARKET_UPDATE_DATA_SUCCESS,
  market
})

const setMarketUpdateDataFail = (error) => ({
  type: MARKET_UPDATE_DATA.MARKET_UPDATE_DATA_FAIL,
  error
})

//To Get Market Details
const loadMarketGetDataRequest = (id) => ({
  type: MARKET_GET_DATA.MARKET_GET_DATA_LOAD,
  id
})

const setMarketGetDataSuccess = (response) => ({
  type: MARKET_GET_DATA.MARKET_GET_DATA_SUCCESS,
  market: response.market[0],
  tracker: response['market-tracker'],
  managers: response['manager-history'],
  activeStores: response['stores']
})

const setMarketGetDataFail = (error) => ({
  type: MARKET_GET_DATA.MARKET_GET_DATA_FAIL,
  error
})

//Delete Market
const loadMarketDeleteDataRequest = (request) => ({
  type: MARKET_DELETE_DATA.MARKET_DELETE_DATA_LOAD,
  request,
});

const setMarketDeleteDataSuccess = (market) => ({
  type: MARKET_DELETE_DATA.MARKET_DELETE_DATA_SUCCESS,
  market,
});

const setMarketDeleteDataFail = (error) => ({
  type: MARKET_DELETE_DATA.MARKET_DELETE_DATA_FAIL,
  error,
});

//GET Market Tracker

const loadMarketTrackerData = () => ({
  type: MARKET_GET_TRACKER_DATA.MARKET_GET_TRACKER_DATA_LOAD,
})

const setMarketTrackerSuccess = (tracker) => ({
  type: MARKET_GET_TRACKER_DATA.MARKET_GET_TRACKER_DATA_SUCCESS,
  tracker
})

const setMarketTrackerFail = (error) => ({
  type: MARKET_GET_TRACKER_DATA.MARKET_GET_TRACKER_DATA_FAIL,
  error
})

//Delete Market History

const loadMarketDeleteHistory = (request) => ({
  type: MARKET_DELETE_HISTORY.MARKET_DELETE_HISTORY_LOAD,
  request
})

const setMarketDeleteHistorySuccess = (market) => ({
  type: MARKET_DELETE_HISTORY.MARKET_DELETE_HISTORY_SUCCESS,
  market
})

const setMarketDeleteHistoryFail = (error) => ({
  type: MARKET_DELETE_HISTORY.MARKET_DELETE_HISTORY_FAIL,
  error
})

//Import Market
const loadMarketImportDataRequest = (request) => ({
  type: MARKET_IMPORT_DATA.MARKET_IMPORT_DATA_LOAD,
  request,
});

const setMarketImportDataSuccess = (markets) => ({
  type: MARKET_IMPORT_DATA.MARKET_IMPORT_DATA_SUCCESS,
  markets,
});

const setMarketImportDataFail = (error) => ({
  type: MARKET_IMPORT_DATA.MARKET_IMPORT_DATA_FAIL,
  error,
});

//Import Market Tracker
const loadMarketTrackerImportDataRequest = (request) => ({
  type: MARKET_TRACKER_IMPORT_DATA.MARKET_TRACKER_IMPORT_DATA_LOAD,
  request,
});

const setMarketTrackerImportDataSuccess = (tracker) => ({
  type: MARKET_TRACKER_IMPORT_DATA.MARKET_TRACKER_IMPORT_DATA_SUCCESS,
  tracker,
});

const setMarketTrackerImportDataFail = (error) => ({
  type: MARKET_TRACKER_IMPORT_DATA.MARKET_TRACKER_IMPORT_DATA_FAIL,
  error,
});

//Get Market Stores
const loadMarketGetStoresRequest = (id) => ({
  type: MARKET_GET_STORES.MARKET_GET_STORES_LOAD,
  id
})
const setMarketGetStoresSuccess = (stores) => ({
  type: MARKET_GET_STORES.MARKET_GET_STORES_SUCCESS,
  stores
})
const setMarketGetStoresFail = (error) => ({
  type: MARKET_GET_STORES.MARKET_GET_STORES_FAIL,
  error
})

export {
  loadMarketDataRequest,
  setMarketDataSuccess,
  setMarketDataFail,
  loadMarketAddDataRequest,
  setMarketAddDataSuccess,
  setMarketAddDataFail,
  loadMarketUpdateDataRequest,
  setMarketUpdateDataSuccess,
  setMarketUpdateDataFail,
  loadMarketGetDataRequest,
  setMarketGetDataSuccess,
  setMarketGetDataFail,
  loadMarketDeleteDataRequest,
  setMarketDeleteDataSuccess,
  setMarketDeleteDataFail,
  loadMarketTrackerData,
  setMarketTrackerSuccess,
  setMarketTrackerFail,
  loadMarketDeleteHistory,
  setMarketDeleteHistorySuccess,
  setMarketDeleteHistoryFail,
  loadMarketImportDataRequest,
  setMarketImportDataSuccess,
  setMarketImportDataFail,
  loadMarketTrackerImportDataRequest,
  setMarketTrackerImportDataSuccess,
  setMarketTrackerImportDataFail,
  loadMarketGetStoresRequest, setMarketGetStoresSuccess, setMarketGetStoresFail,
}
