import { takeLatest, put, call } from "redux-saga/effects";
import {
  ACQUISITION_DATA,
  ACQUISITION_GET_API,
  ACQUISITION_ADD_DATA,
  ACQUISITION_POST_API,
  ACQUISITION_GET_DATA,
  ACQUISITION_GET_DETAIL_API,
  ACQUISITION_UPDATE_DATA,
  ACQUISITION_PUT_API,
  ACQUISITION_DELETE_DATA,
  ACQUISITION_DELETE_API,
} from "./Constants";
import {
  setAcquisitionDataSuccess,
  setAcquisitionDataFail,
  setAcquisitionAddDataSuccess,
  setAcquisitionAddDataFail,
  setAcquisitionGetDataSuccess,
  setAcquisitionGetDataFail,
  setAcquisitionUpdateDataSuccess,
  setAcquisitionUpdateDataFail,
  setAcquisitionDeleteDataSuccess,
  setAcquisitionDeleteDataFail
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getAcquisitionsDataService() {
  try {
    const acquisitionResponse = yield call(getRequest, ACQUISITION_GET_API);
    const status = acquisitionResponse.status;
    if (status === 200) {
      const data = acquisitionResponse.data;
      yield put(setAcquisitionDataSuccess(data));
    } else {
      yield put(setAcquisitionDataFail(acquisitionResponse.response.data));
    }
  } catch (error) {
    yield put(setAcquisitionDataFail(error));
  }
}

function* postAcquisitionDataService({ request }) {
  try {
    const acquisitionDataResponse = yield call(
      postRequest,
      ACQUISITION_POST_API,
      request
    );
    const status = acquisitionDataResponse.status;
    if (status === 200) {
      const newData = acquisitionDataResponse.data;
      yield put(addSuccessAlert('Acquisition Created'))
      yield put(setAcquisitionAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(acquisitionDataResponse.response.data.msg))
      yield put(setAcquisitionAddDataFail(acquisitionDataResponse.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error adding acquisition"))
    yield put(setAcquisitionAddDataFail(error));
  }
}

function* getAcquisitionDataService({ id }) {
  try {
    const getAcquisitionDataResponse = yield call(
      getDetailRequest,
      ACQUISITION_GET_DETAIL_API + id
    );
    const status = getAcquisitionDataResponse.status;
    if (status === 200) {
      const newData = getAcquisitionDataResponse.data;
      yield put(setAcquisitionGetDataSuccess(newData));
    } else {
      yield put(setAcquisitionGetDataFail(getAcquisitionDataResponse.response.data));
    }
  } catch (error) {
    yield put(setAcquisitionGetDataFail(error));
  }
}

function* putAcquisitionDataService({ request }) {
  try {
    const response = yield call(putRequest, ACQUISITION_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Acquisition updated"))
      yield put(setAcquisitionUpdateDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setAcquisitionUpdateDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error updating acquisition"))
    yield put(setAcquisitionUpdateDataFail(error));
  }
}

function* deleteAcquisitionDataService({ request }) {
  try {
    const response = yield call(deleteRequest, ACQUISITION_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Acquisition deleted"))
      yield put(setAcquisitionDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setAcquisitionDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error deleting acquisition"))
    yield put(setAcquisitionDeleteDataFail(error));
  }
}

export function* watchAcquisition() {
  yield takeLatest(
    ACQUISITION_DATA.ACQUISITION_DATA_LOAD,
    getAcquisitionsDataService
  );
  yield takeLatest(
    ACQUISITION_ADD_DATA.ACQUISITION_ADD_DATA_LOAD,
    postAcquisitionDataService
  );
  yield takeLatest(
    ACQUISITION_GET_DATA.ACQUISITION_GET_DATA_LOAD,
    getAcquisitionDataService
  );
  yield takeLatest(
    ACQUISITION_UPDATE_DATA.ACQUISITION_UPDATE_DATA_LOAD,
    putAcquisitionDataService
  );
  yield takeLatest(
    ACQUISITION_DELETE_DATA.ACQUISITION_DELETE_DATA_LOAD,
    deleteAcquisitionDataService
  );
}
