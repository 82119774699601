import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { loadRegionGetDataRequest, loadRegionUpdateDataRequest } from "../redux/Actions";
import { Card, Col, Row } from "react-bootstrap";
import { REGION, BACKTODETAILS } from '../../../shared/constants/Constants';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router';
import RegionForm from "./RegionForm";
import DefaultButton from "../../../shared/components/DefaultButton";
import { addErrorAlert } from "../../alert/Actions";
import FormError from "../../../shared/components/FormError";

const RegionEdit = () => {

    let params = useParams();
    let navigate = useNavigate();

    const dispatch = useDispatch();
    let [submitted, setSubmitted] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        dispatch(loadRegionGetDataRequest(params.id))
    }, [dispatch, params.id]);
    let { region, loading, error } = useSelector(state => state.region)


    // After submition, the post request is done loading, and there's no errors
    useEffect(() => {
        console.log(error);
        if (submitted && !loading) {
            if (!error) {
                navigate('/region/' + params.id)
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setSubmitted(false)
        }
    }, [loading, error, submitted])

    function submit(values) {
        dispatch(loadRegionUpdateDataRequest(values))
        setSubmitted(true)
    }
    function cancel() {
        navigate('/region/' + params.id)
    }
    return (
        <div>

            <Row>
                <Col>
                    <Breadcrumbs
                        children={[
                            { href: '/region', label: REGION },
                            { href: '/region/' + params.id, label: params.id },
                            { label: 'edit' },
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <div className="float-end">
                        <DefaultButton
                            buttonClass={"default-btn-color mb-1"}
                            buttonTitle={BACKTODETAILS}
                            actionBlock={() => { navigate('/region/' + params.id) }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <FormError>
                            {errorMessage}
                        </FormError>
                        <RegionForm region={region} submit={submit} cancel={cancel} editType={true} />
                    </Card.Body>
                </Card>
            </Row>
        </div>
    );
};

export default RegionEdit;
