import {
  GROUPS_DATA,
  GROUPS_GET_DATA,
  GROUPS_ADD_DATA,
  GROUPS_UPDATE_DATA,
  GROUPS_DELETE_DATA,
} from "./Constants";
const initialState = {
  loading: false,
  error: null,
  groups: [],
  group: {},
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    //load all
    case GROUPS_DATA.GROUPS_DATA_LOAD:
      return {
        ...state,
        loading: true,
        groups: [],
        error: null,
      };
    case GROUPS_DATA.GROUPS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        groups: action.groups,
      };
    case GROUPS_DATA.GROUPS_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //load single
    case GROUPS_GET_DATA.GROUPS_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        group: {},
        error: null,
      };

    case GROUPS_GET_DATA.GROUPS_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        group: action.group,
      };

    case GROUPS_GET_DATA.GROUPS_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GROUPS_ADD_DATA.GROUPS_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GROUPS_ADD_DATA.GROUPS_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GROUPS_ADD_DATA.GROUPS_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GROUPS_UPDATE_DATA.GROUPS_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GROUPS_UPDATE_DATA.GROUPS_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GROUPS_UPDATE_DATA.GROUPS_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GROUPS_DELETE_DATA.GROUPS_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GROUPS_DELETE_DATA.GROUPS_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GROUPS_DELETE_DATA.GROUPS_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
