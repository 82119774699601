import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadJobTitlesGetDataRequest,
  loadJobTitlesUpdateDataRequest,
} from "../redux/Actions";
import { Card, Col, Row } from "react-bootstrap";
import { BACKTODETAILS, JOB_TITLE } from "../../../shared/constants/Constants";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import { useNavigate, useParams } from "react-router";
import JobTitlesForm from "./JobTitlesForm";
import DefaultButton from "../../../shared/components/DefaultButton";
const JobTitlesEdit = () => {

  let navigate = useNavigate();
  const dispatch = useDispatch();
  let [submitted, setSubmitted] = useState(false);
  let params = useParams();

  useEffect(() => {
    dispatch(loadJobTitlesGetDataRequest(params.id));
  }, [dispatch, params.id]);

  let jobtitleStore = useSelector((state) => state.jobtitle);
  let jobtitle = jobtitleStore.jobtitle[0];

  // After submition, the post request is done loading, and there's no errors
  if (submitted && !jobtitleStore.loading) {
    navigate("/jobtitles/" + params.id);
  }

  function submit(values) {
    dispatch(loadJobTitlesUpdateDataRequest(values));
    setSubmitted(true);
  }
  function cancel() {
    navigate("/jobtitles/" + params.id);
  }
  return (
    <div>
      <Row>
        <Col>
          <Breadcrumbs
            children={[
              { href: '/jobtitles', label: JOB_TITLE },
              { href: '/jobtitles/' + params.id, label: params.id },
              { label: 'edit' },
            ]}
          />
        </Col>
        <Col xs={6}>
          <div className="float-end">
            <DefaultButton
              buttonClass={"default-btn-color mb-1"}
              buttonTitle={BACKTODETAILS}
              actionBlock={() => { navigate('/jobtitles/' + params.id) }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <JobTitlesForm jobtitle={jobtitle} submit={submit} cancel={cancel} />
          </Card.Body>
        </Card>
      </Row>
    </div>
  );
};

export default JobTitlesEdit;
