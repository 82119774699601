import { Field } from "formik"

export default function DisabledTextField({ name, label, value }) {
    return <div className="col-md-6">
        <label className="form-label">
            {label}
        </label>
        <Field
            className="form-control"
            type="text"
            autoComplete="off"
            disabled={true}
            value={value}
        />
    </div>
}
