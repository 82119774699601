import React from 'react'
import TShirtSize from './TShirtSize'
import { Card } from 'react-bootstrap'

const MyAdditionalInfo = () => {
  return (<>
    <TShirtSize />
  </>)
}

export default MyAdditionalInfo