import {
    FULLPART_TIME_DATA,
    FULLPART_TIME_GET_DATA,
    FULLPART_TIME_ADD_DATA,
    FULLPART_TIME_UPDATE_DATA,
    FULLPART_TIME_DELETE_DATA,
} from './Constants'


//For listing
const loadFullPartTimeDataRequest = () => ({
    type: FULLPART_TIME_DATA.FULLPART_TIME_DATA_LOAD,
});
const setFullPartTimeDataSuccess = (fullPartTimes) => ({
    type: FULLPART_TIME_DATA.FULLPART_TIME_DATA_SUCCESS,
    fullPartTimes,
});
const setFullPartTimeDataFail = (error) => ({
    type: FULLPART_TIME_DATA.FULLPART_TIME_DATA_FAIL,
    error,
});

//for details
const loadFullPartTimeGetDataRequest = (id) => ({
    type: FULLPART_TIME_GET_DATA.FULLPART_TIME_GET_DATA_LOAD,
    id,
});
const setFullPartTimeGetDataSuccess = (response) => ({
    type: FULLPART_TIME_GET_DATA.FULLPART_TIME_GET_DATA_SUCCESS,
    fullPartTime: response.fullPartTime[0]
});
const setFullPartTimeGetDataFail = (error) => ({
    type: FULLPART_TIME_GET_DATA.FULLPART_TIME_GET_DATA_FAIL,
    error,
});

//For add FullPartTime
const loadFullPartTimeAddDataRequest = (request) => ({
    type: FULLPART_TIME_ADD_DATA.FULLPART_TIME_ADD_DATA_LOAD,
    request,
});
const setFullPartTimeAddDataSuccess = (fullPartTime) => ({
    type: FULLPART_TIME_ADD_DATA.FULLPART_TIME_ADD_DATA_SUCCESS,
    fullPartTime,
});
const setFullPartTimeAddDataFail = (error) => ({
    type: FULLPART_TIME_ADD_DATA.FULLPART_TIME_ADD_DATA_FAIL,
    error,
});

//update FullPartTime
const loadFullPartTimeUpdateDataRequest = (request) => ({
    type: FULLPART_TIME_UPDATE_DATA.FULLPART_TIME_UPDATE_DATA_LOAD,
    request,
});
const setFullPartTimeUpdateDataSuccess = (fullPartTime) => ({
    type: FULLPART_TIME_UPDATE_DATA.FULLPART_TIME_UPDATE_DATA_SUCCESS,
    fullPartTime,
});
const setFullPartTimeUpdateDataFail = (error) => ({
    type: FULLPART_TIME_UPDATE_DATA.FULLPART_TIME_UPDATE_DATA_FAIL,
    error,
});

//Delete FullPartTime
const loadFullPartTimeDeleteDataRequest = (request) => ({
    type: FULLPART_TIME_DELETE_DATA.FULLPART_TIME_DELETE_DATA_LOAD,
    request,
});
const setFullPartTimeDeleteDataSuccess = (fullPartTime) => ({
    type: FULLPART_TIME_DELETE_DATA.FULLPART_TIME_DELETE_DATA_SUCCESS,
    fullPartTime,
});
const setFullPartTimeDeleteDataFail = (error) => ({
    type: FULLPART_TIME_DELETE_DATA.FULLPART_TIME_DELETE_DATA_FAIL,
    error,
});


export {
    loadFullPartTimeDataRequest, setFullPartTimeDataSuccess, setFullPartTimeDataFail,
    loadFullPartTimeGetDataRequest, setFullPartTimeGetDataSuccess, setFullPartTimeGetDataFail,
    loadFullPartTimeAddDataRequest, setFullPartTimeAddDataSuccess, setFullPartTimeAddDataFail,
    loadFullPartTimeUpdateDataRequest, setFullPartTimeUpdateDataSuccess, setFullPartTimeUpdateDataFail,
    loadFullPartTimeDeleteDataRequest, setFullPartTimeDeleteDataSuccess, setFullPartTimeDeleteDataFail,
}