import { ErrorMessage, Field } from "formik"

const FormikRadioField = ({ name, label, hidden, setFieldValue, onChange, value }) => {

    console.log("filed::", setFieldValue);
    
    return <div hidden={hidden} style={{ float: "left", marginRight: "15px" }}>
        <div style={{ paddingTop: '', fontSize: '1rem' }}>
            <label>
                <Field
                    className="form-check-input"
                    type='radio'
                    name={name}
                    // value={true}
                    checked={value}
                    onChange={() => {
                        setFieldValue(name, !value)
                        if (onChange){
                            onChange(!value);
                        }
                    }}
                />
                {" "}
                {label}
            </label>
        </div>
        <span className="error-text">
            <ErrorMessage name={name} />
        </span>
    </div>
}
export default FormikRadioField