import { PT_DATA, PT_GET_DATA, PT_ADD_DATA, PT_UPDATE_DATA, PT_DELETE_DATA, PTYPE_DATA, ATYPE_DATA, LTYPE_DATA } from './Constants'
const initialState = {
    loading: false,
    error: null,
    productTables: [],
    productTable:{},
    pTypes:[],
    aTypes:[],
    lTypes:[]
}
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case PT_DATA.PT_DATA_LOAD:
            return {
                ...state,
                loading: true,
                productTables:[],
                error:null
            }
        case PT_DATA.PT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                productTables:action.productTables
            }
        case PT_DATA.PT_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        //load single
        case PT_GET_DATA.PT_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                productTable:{},
                error:null
            }                

        case PT_GET_DATA.PT_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                productTable:action.productTable
            }                

        case PT_GET_DATA.PT_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case PT_ADD_DATA.PT_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case PT_ADD_DATA.PT_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case PT_ADD_DATA.PT_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case PT_UPDATE_DATA.PT_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case PT_UPDATE_DATA.PT_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case PT_UPDATE_DATA.PT_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case PT_DELETE_DATA.PT_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case PT_DELETE_DATA.PT_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case PT_DELETE_DATA.PT_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        //load p Type all
        case PTYPE_DATA.PTYPE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                pTypes:[],
                error:null
            }
        case PTYPE_DATA.PTYPE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                pTypes:action.pTypes
            }
        case PTYPE_DATA.PTYPE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        //load a Type all
        case ATYPE_DATA.ATYPE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                aTypes:[],
                error:null
            }
        case ATYPE_DATA.ATYPE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                aTypes:action.aTypes
            }
        case ATYPE_DATA.ATYPE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        //load l Type all
        case LTYPE_DATA.LTYPE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                lTypes:[],
                error:null
            }
        case LTYPE_DATA.LTYPE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                lTypes:action.lTypes
            }
        case LTYPE_DATA.LTYPE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        default:
            return {
                ...state
            }
    }
}
export default Reducer