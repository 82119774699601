import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../../shared/components/DefaultButton";
import { BACKTOLIST, EDIT, ADDITEM, DISTRICT_NAME, DISTRICT_TRACKER, ODOO_DESIGNATION_TRACKERS, ODOO_STORE_TRANSFER_TRACKERS } from "../../../../shared/constants/Constants";
import ListView from "../../../../shared/components/listview/ListView";
import { PrevNextButtons } from "../../../../shared/components/PrevNextButtons";
import Permissions from "../../../../shared/components/Permissions";
import { Link } from "react-router-dom";
import { loadOdooStoreTransferTrackerGetDataRequest } from "../redux/Actions";

export default function OdooStoreTransferTrackerDetails() {
    let params = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    let { odooStoreTransferTracker } = useSelector((state) => state.odooStoreTransferTrackers);

    useEffect(() => {
        dispatch(loadOdooStoreTransferTrackerGetDataRequest(params.id))
    }, [dispatch, params.id])
    // let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    // let module_name = "Market";
    // let permission = Permissions(emp_no, module_name);

    // let modulePermission = "Market Tracker";
    // let trackerPermission = Permissions(emp_no, modulePermission);

    return <div>
        <Row className="detailsPageHeader">
            <Col xl={4} xs={12}>
                <Breadcrumbs
                    children={[
                        { href: '/odoo-store-transfer-trackers', label: ODOO_STORE_TRANSFER_TRACKERS },
                        { label: params.id },
                    ]}
                />
            </Col>
            <Col xl={8} xs={12}>
                <div className='float-end'>
                    <PrevNextButtons currentId={params.id} basePath='/odoo-store-transfer-trackers/' name={ODOO_STORE_TRANSFER_TRACKERS} />
                    <DefaultButton
                        buttonClass={"default-btn-color me-1"}
                        buttonTitle={BACKTOLIST}
                        actionBlock={() => { navigate("/odoo-store-transfer-trackers"); }}
                    />
                </div>
            </Col>
        </Row>
        <Row>
            <Card style={{ marginBottom: '1.5rem' }}>
                <Card.Body>
                    <dl className='row'>
                        <dt className='col-sm-3'>Assign Date</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.assign_date}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Create Date</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.create_date}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Create uid</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.create_uid}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Dealer id</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.dealer_id}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Designation id</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.designation_id}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>El Job id</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.el_job_id}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Employee</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.emp_name_number}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Employee Type</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.emp_type_name}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Move Type</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.move_type}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Name</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.name}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Store</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.store_sap_name}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Write Date</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.write_date}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Write uid</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.write_uid}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>Start Date</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.start_date}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>End Date</dt>
                        <dd className='col-sm-9'>{odooStoreTransferTracker?.end_date}</dd>
                    </dl>
                </Card.Body>
            </Card>
        </Row>
    </div>
}