const REGION_DATA = {
  REGION_DATA_LOAD: "REGION_DATA_LOAD",
  REGION_DATA_SUCCESS: "REGION_DATA_SUCCESS",
  REGION_DATA_FAIL: "REGION_DATA_FAIL",
};

const REGION_GET_API = "/region";


// Create / Add / Post
const REGION_ADD_DATA = {
    REGION_ADD_DATA_LOAD:'REGION_ADD_DATA_LOAD',
    REGION_ADD_DATA_SUCCESS:'REGION_ADD_DATA_SUCCESS', 
    REGION_ADD_DATA_FAIL:'REGION_ADD_DATA_FAIL'
}

const REGION_POST_API = '/region'


// Get one by id
const REGION_GET_DATA = {
    REGION_GET_DATA_LOAD:'REGION_GET_DATA_LOAD',
    REGION_GET_DATA_SUCCESS:'REGION_GET_DATA_SUCCESS', 
    REGION_GET_DATA_FAIL:'REGION_GET_DATA_FAIL'
}

const REGION_GET_DETAIL_API = '/regionHistory/'

//Update
const REGION_UPDATE_DATA = {
  REGION_UPDATE_DATA_LOAD: 'REGION_UPDATE_DATA_UPLOAD',
  REGION_UPDATE_DATA_SUCCESS: 'REGION_UPDATE_DATA_SUCCESS',
  REGION_UPDATE_DATA_FAIL: 'REGION_UPDATE_DATA_FAIL'
}

const REGION_PUT_API = '/region'

// Delete
const REGION_DELETE_DATA={
    REGION_DELETE_DATA_LOAD:'REGION_DELETE_DATA_LOAD',
    REGION_DELETE_DATA_SUCCESS:'REGION_DELETE_DATA_SUCCESS',
    REGION_DELETE_DATA_FAIL:'REGION_DELETE_DATA_FAIL'
}

const REGION_DELETE_API = '/region'


//Get All Region Tracker
const REGION_GET_ALL_DATA={
  REGION_GET_ALL_DATA_LOAD:'REGION_GET_ALL_DATA_LOAD',
  REGION_GET_ALL_DATA_SUCCESS:'REGION_GET_ALL_DATA_SUCCESS',
  REGION_GET_ALL_DATA_FAIL:'REGION_GET_ALL_DATA_FAIL'
}

const REGION_GET_ALL_API = '/regionHistory'

// Delete Tracker
const REGION_TRACKER_DELETE_DATA={
  REGION_TRACKER_DELETE_DATA_LOAD:'REGION_TRACKER_DELETE_DATA_LOAD',
  REGION_TRACKER_DELETE_DATA_SUCCESS:'REGION_TRACKER_DELETE_DATA_SUCCESS',
  REGION_TRACKER_DELETE_DATA_FAIL:'REGION_TRACKER_DELETE_DATA_FAIL'
}

const REGION_TRACKER_DELETE_API = '/regionHistory'

//Import Region
const REGION_IMPORT_DATA = {
  REGION_IMPORT_DATA_LOAD:'REGION_IMPORT_DATA_LOAD',
  REGION_IMPORT_DATA_SUCCESS:'REGION_IMPORT_DATA_SUCCESS', 
  REGION_IMPORT_DATA_FAIL:'REGION_IMPORT_DATA_FAIL'
}

const REGION_IMPORT_API = '/upload'

//Import Region Tracker
const REGION_TRACKER_IMPORT_DATA = {
  REGION_TRACKER_IMPORT_DATA_LOAD:'REGION_TRACKER_IMPORT_DATA_LOAD',
  REGION_TRACKER_IMPORT_DATA_SUCCESS:'REGION_TRACKER_IMPORT_DATA_SUCCESS', 
  REGION_TRACKER_IMPORT_DATA_FAIL:'REGION_TRACKER_IMPORT_DATA_FAIL'
}

const REGION_TRACKER_IMPORT_API = '/upload'

//Load markets in region
const REGION_GET_MARKETS = {
  REGION_GET_MARKETS_LOAD: "REGION_GET_MARKETS_LOAD",
  REGION_GET_MARKETS_SUCCESS: "REGION_GET_MARKETS_SUCCESS",
  REGION_GET_MARKETS_FAIL: "REGION_GET_MARKETS_FAIL",
}
const REGION_GET_MARKETS_API = '/store/region/'

export {
  REGION_DATA,
  REGION_GET_API,
  REGION_ADD_DATA,
  REGION_POST_API,
  REGION_GET_DATA,
  REGION_GET_DETAIL_API,
  REGION_UPDATE_DATA,
  REGION_PUT_API,
  REGION_DELETE_DATA,
  REGION_DELETE_API,
  REGION_GET_ALL_DATA,
  REGION_GET_ALL_API,
  REGION_TRACKER_DELETE_DATA,
  REGION_TRACKER_DELETE_API,
  REGION_IMPORT_DATA,
  REGION_IMPORT_API,
  REGION_TRACKER_IMPORT_DATA,
  REGION_TRACKER_IMPORT_API,
  REGION_GET_MARKETS, REGION_GET_MARKETS_API,
};
