import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { loadMarketGetDataRequest, loadMarketUpdateDataRequest } from "../redux/Actions";
import { Card, Col, Row } from "react-bootstrap";
import { MARKET, BACKTODETAILS } from '../../../shared/constants/Constants';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router';
import MarketForm from "./MarketForm";
import DefaultButton from "../../../shared/components/DefaultButton";

const MarketEdit = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let [submitted, setSubmitted] = useState(false);
    let params = useParams();

    useEffect(() => {
        dispatch(loadMarketGetDataRequest(params.id))
    }, [dispatch, params.id]);
    let { market, tracker, loading } = useSelector(state => state.market)

    let alteredMarket = {}
    if (market && tracker) {
        alteredMarket = { ...market }
    }

    // After submition, the post request is done loading, and there's no errors
    if (submitted && !loading) {
        navigate('/district/' + params.id)
    }

    function submit(values) {
        let submitData = {
            id: values.id,
            market: {
                market_name: values.market_name,
                ulti_market_code: values.ulti_market_code,
            }
        }
        dispatch(loadMarketUpdateDataRequest(submitData))
        setSubmitted(true)
    }
    function cancel() {
        navigate('/district/' + params.id)
    }
    return (
        <div>

            <Row>
                <Col>
                    <Breadcrumbs
                        children={[
                            { href: '/district', label: MARKET },
                            { href: '/district/' + params.id, label: params.id },
                            { label: 'edit' },
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <div className='float-end'>
                        <DefaultButton
                            buttonClass={"default-btn-color mb-1"}
                            buttonTitle={BACKTODETAILS}
                            actionBlock={() => { navigate('/district/' + params.id) }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <MarketForm market={alteredMarket} submit={submit} cancel={cancel} />
                    </Card.Body>
                </Card>
            </Row>
        </div>
    );
};

export default MarketEdit;
