import { takeLatest, put, call } from "redux-saga/effects";
import {
  STORE_HOURS_DATA,
  STORE_HOURS_GET_API,
  STORE_HOURS_DELETE_DATA,
  STORE_HOURS_DELETE_API,
  STORE_HOURS_IMPORT_DATA,
  STORE_HOURS_IMPORT_API,
} from "./Constants";
import {
  setStoreHoursDataSuccess,
  setStoreHoursDataFail,
  setStoreHoursDeleteDataSuccess,
  setStoreHoursDeleteDataFail,
  setStoreHoursImportDataSuccess,
  setStoreHoursImportDataFail,
} from "./Actions";
import {
  getRequest,
  deleteRequest,
  postImportRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getStoreHoursDataService() {
  try {
    const response = yield call(getRequest, STORE_HOURS_GET_API);
    const status = response.status;
    if (status === 200) {
      const data = response.data;
      yield put(setStoreHoursDataSuccess(data));
    } else {
      yield put(setStoreHoursDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setStoreHoursDataFail(error));
  }
}

function* deleteStoreHoursDataService({ request }) {
  try {
    const response = yield call(deleteRequest, STORE_HOURS_DELETE_API, request);
    if (response.status === 200) {
            yield put(addSuccessAlert("Store-hours created"))
      yield put(setStoreHoursDeleteDataSuccess(response.data));
    } else {
            yield put(addErrorAlert(response.response.data.msg))
      yield put(setStoreHoursDeleteDataFail(response.response.data));
    }
  } catch (error) {
        yield put(addErrorAlert("Could not create store-hours"))
    yield put(setStoreHoursDeleteDataFail(error));
  }
}


function* postImportStoreHoursDataService({ request }) {
  try {
    const response = yield call(
      postImportRequest,
      STORE_HOURS_IMPORT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(setStoreHoursImportDataSuccess(newData));
    } else {
      yield put(setStoreHoursImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setStoreHoursImportDataFail(error));
  }
}

export function* watchStoreHours() {
  yield takeLatest(STORE_HOURS_DATA.STORE_HOURS_DATA_LOAD, getStoreHoursDataService);
  yield takeLatest(STORE_HOURS_DELETE_DATA.STORE_HOURS_DELETE_DATA_LOAD, deleteStoreHoursDataService);
  yield takeLatest(STORE_HOURS_IMPORT_DATA.STORE_HOURS_IMPORT_DATA_LOAD, postImportStoreHoursDataService);
}
