import {
  COMPANY_DATA,
  COMPANY_ADD_DATA,
  COMPANY_UPDATE_DATA,
  COMPANY_GET_DATA,
  COMPANY_DELETE_DATA,
  COMPANY_IMPORT_DATA,
} from "./Constants";

//For Listing
const loadCompanyDataRequest = () => ({
  type: COMPANY_DATA.COMPANY_DATA_LOAD,
});

const setCompanyDataSuccess = (companies) => ({
  type: COMPANY_DATA.COMPANY_DATA_SUCCESS,
  companies,
});

const setCompanyDataFail = (error) => ({
  type: COMPANY_DATA.COMPANY_DATA_FAIL,
  error,
});

//For Add Company
const loadCompanyAddDataRequest = (request) => ({
  type: COMPANY_ADD_DATA.COMPANY_ADD_DATA_LOAD,
  request,
});

const setCompanyAddDataSuccess = (company) => ({
  type: COMPANY_ADD_DATA.COMPANY_ADD_DATA_SUCCESS,
  company,
});

const loadCompanyAddReset = () => ({
  type: COMPANY_ADD_DATA.COMPANY_ADD_DATA_RESET,
});

const setCompanyAddDataFail = (error) => ({
  type: COMPANY_ADD_DATA.COMPANY_ADD_DATA_FAIL,
  error,
});

//For Edit Company
const loadCompanyUpdateDataRequest = (request) => ({
  type: COMPANY_UPDATE_DATA.COMPANY_UPDATE_DATA_LOAD,
  request,
});

const setCompanyUpdateDataSuccess = (company) => ({
  type: COMPANY_UPDATE_DATA.COMPANY_UPDATE_DATA_SUCCESS,
  company,
});

const setCompanyUpdateDataFail = (error) => ({
  type: COMPANY_UPDATE_DATA.COMPANY_UPDATE_DATA_FAIL,
  error,
});

//To Get Company Details
const loadCompanyGetDataRequest = (id) => ({
  type: COMPANY_GET_DATA.COMPANY_GET_DATA_LOAD,
  id,
});

const setCompanyGetDataSuccess = (company) => ({
  type: COMPANY_GET_DATA.COMPANY_GET_DATA_SUCCESS,
  company,
});

const setCompanyGetDataFail = (error) => ({
  type: COMPANY_GET_DATA.COMPANY_GET_DATA_FAIL,
  error,
});

//Import Company
const loadCompanyImportDataRequest = (request) => ({
  type: COMPANY_IMPORT_DATA.COMPANY_IMPORT_DATA_LOAD,
  request,
});

const setCompanyImportDataSuccess = (company) => ({
  type: COMPANY_IMPORT_DATA.COMPANY_IMPORT_DATA_SUCCESS,
  company,
});

const setCompanyImportDataFail = (error) => ({
  type: COMPANY_IMPORT_DATA.COMPANY_IMPORT_DATA_FAIL,
  error,
});

//Delete Company
const loadCompanyDeleteDataRequest = (request) => ({
  type: COMPANY_DELETE_DATA.COMPANY_DELETE_DATA_LOAD,
  request,
});

const setCompanyDeleteDataSuccess = (company) => ({
  type: COMPANY_DELETE_DATA.COMPANY_DELETE_DATA_SUCCESS,
  company,
});

const setCompanyDeleteDataFail = (error) => ({
  type: COMPANY_DELETE_DATA.COMPANY_DELETE_DATA_FAIL,
  error,
});

export {
  loadCompanyDataRequest,
  setCompanyDataSuccess,
  setCompanyDataFail,
  loadCompanyAddDataRequest,
  setCompanyAddDataSuccess,
  setCompanyAddDataFail,
  loadCompanyUpdateDataRequest,
  setCompanyUpdateDataSuccess,
  setCompanyUpdateDataFail,
  loadCompanyGetDataRequest,
  setCompanyGetDataSuccess,
  setCompanyGetDataFail,
  loadCompanyDeleteDataRequest,
  setCompanyDeleteDataSuccess,
  setCompanyDeleteDataFail,
  loadCompanyImportDataRequest,
  setCompanyImportDataSuccess,
  setCompanyImportDataFail,
  loadCompanyAddReset,
};
