import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadMarketAddDataRequest } from "../redux/Actions";
import MarketForm from "./MarketForm";

const MarketAdd = ({ handleClose }) => {
    const dispatch = useDispatch();
    let [submitted, setSubmitted] = useState(false);
    let [isClosing, setIsClosing] = useState(false);

    let { loading, error } = useSelector(state => state.market)

    // After submition, the post request is done loading, and there's no errors
    let [errorMessage, setErrorMessage] = useState(null)
    useEffect(() => {
        console.log(error);
        if (submitted && !loading) {
            if (!error) {
                handleClose(true)
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setSubmitted(false)
        }
    }, [loading, error, submitted])


    function submit(values) {
        dispatch(loadMarketAddDataRequest(values))
        setSubmitted(true)
    }
    function cancel() {
        handleClose(false);
    }
    return <>
        <FormError>
            {errorMessage}
        </FormError>
        <MarketForm isNew={true} submit={submit} cancel={cancel} />
    </>
};

export default MarketAdd;
