import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import DefaultButton from "../../../shared/components/DefaultButton";
import ImportModal from '../../../shared/components/ImportModal';
import ServerSideListView from '../../../shared/components/listview/ServerSideListView';
import Permissions from '../../../shared/components/Permissions';
import TablePaging from '../../../shared/components/TablePaging';
import { COMPANY, CREATENEW, DIVISION, EMPLOYEES, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, HIRE_STATUS, HOURLY_SALARY, JOB_TITLE, MARKET, PART_FULL_TIME, REGION } from "../../../shared/constants/Constants";
import { loadCompanyDataRequest } from '../../company/redux/Actions';
import { loadDepartmentDataRequest } from "../../employee_departments/redux/Actions";
import { loadJobTitlesDataRequest } from '../../jobtitles/redux/Actions';
import { loadGroupJobTitlesDataRequest } from '../../group_jobtitles/redux/Actions';
import { addTableFilter } from '../../table_filters_redux/Actions';
import { loadEmployeeDataRequest, loadEmployeeImportDataRequest, loadEmployeeRowCount } from '../redux/Actions';

import dropdownFilterFunction from '../../../shared/components/listview/dropdownFilterFunction';
import { loadFullPartTimeDataRequest } from '../../full_part_time_status/redux/Actions';
import { loadHourlySalaryDataRequest } from '../../hourly_salary_status/redux/Actions';
import { loadHireStatusDataRequest } from '../../hire-status/redux/Actions';
import { loadMarketDataRequest } from '../../market/redux/Actions';
import { loadRegionDataRequest } from '../../region/redux/Actions';
import { loadDivisionDataRequest } from '../../division/redux/Actions';
import { loadEmployeeStatusDataRequest } from '../../employee_status/redux/Actions';
const Employees = () => {

    let dispatch = useDispatch();
    let navigate = useNavigate();
    let { employees, loading, rowCount } = useSelector(store => store.employee)

    let [showImportModal, setShowImportModal] = useState(false);
    let [selectedCompanyId, setSelectedCompanyId] = useState(JSON.parse(localStorage.getItem("selectedId")));

    let location = useLocation();
    let [query] = useSearchParams();
    useEffect(() => {
        if (query.get('filter') == 'none') {
            setSelectedCompanyId(0)
        }
    }, [])

    let exportTitle = EMPLOYEES
    let tableFilterKey = useMemo(() => location.pathname + "/" + exportTitle, [location]);
    let { tableFilters } = useSelector((state) => state.tableFilter)

    let filter = {
        // "emp_status": {
        //     filterType: "text",
        //     type: "inArray",
        //     filter: "Active"
        // }
    };
    if (query.get("filter") && query.get('filter') !== "none") {
        filter = {
            ...filter,
            ...JSON.parse(query.getAll('filter'))
        }
    }
    for (let field in filter){
        if(field == 'is_commisionable') {
            filter[field].filter = filter[field].filter
        } else {
            filter[field].filter = JSON.stringify([filter[field].filter])
        }
    }
    
    let [filterAndPage, setFilterAndPage] = useState(
        tableFilters[tableFilterKey] && !query.get("filter") ?
            tableFilters[tableFilterKey]
            :
            {
                filter: JSON.stringify(filter),
                page: 1,
                sort: 'null'
            }
    )

    const commisionableData = [
        {
          name: 'True',
          value: true
        },
        {
            name: 'False',
            value: false
        }
      ];

    useEffect(() => {
        dispatch(loadEmployeeRowCount(JSON.parse(filterAndPage.filter)));
        dispatch(loadJobTitlesDataRequest());
        dispatch(loadDepartmentDataRequest());
        dispatch(loadFullPartTimeDataRequest());
        dispatch(loadHourlySalaryDataRequest());
        dispatch(loadHireStatusDataRequest());
        dispatch(loadEmployeeStatusDataRequest());
        dispatch(loadMarketDataRequest());
        dispatch(loadRegionDataRequest());
        dispatch(loadDivisionDataRequest());
        dispatch(loadCompanyDataRequest())
        dispatch(loadGroupJobTitlesDataRequest());
    }, [dispatch])

    let { companies } = useSelector(store => store.company)

    let [employeeData, setEmployeeData] = useState([])
    useEffect(() => {
        if (selectedCompanyId === 0) {
            setEmployeeData([...employees])
        } else {
            setEmployeeData([...employees].filter(
                (employee) => {
                    return employee.company_id == selectedCompanyId
                }
            ))
        }
    }, [employees, selectedCompanyId])
    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Employees";
    let permission = Permissions(emp_no, module_name);
    localStorage.setItem("selectedId", selectedCompanyId)


    const rowClick = useCallback((row) => {
        navigate("./" + row.data["id"]);
    }, [])
    const filterCallback = useCallback((newFilters) => {
        if (JSON.stringify(newFilters.filter) != filterAndPage.filter || JSON.stringify(newFilters.sort) != filterAndPage.sort) {
            let newFilterAndPage = { filter: JSON.stringify(newFilters.filter), page: 1, sort: JSON.stringify(newFilters.sort) }
            setFilterAndPage(newFilterAndPage);
            dispatch(loadEmployeeRowCount(JSON.parse(newFilterAndPage.filter)));
        }
    }, [filterAndPage]);
    useEffect(() => {
        dispatch(addTableFilter(tableFilterKey, filterAndPage))
        dispatch(loadEmployeeDataRequest(JSON.parse(filterAndPage.filter), filterAndPage.page, JSON.parse(filterAndPage.sort)));
    }, [filterAndPage])


    let { jobtitles } = useSelector(state => state.jobtitle);      
    let jobtitleOptions = useMemo(() => jobtitles.map((jobtitle) => ({ value: jobtitle.job_title, label: jobtitle.job_title })), [jobtitles])
    let jobtitleFunc = dropdownFilterFunction(jobtitleOptions)

    let { groupjobtitles } = useSelector(state => state.groupJobtitle);   
    let groupjobtitleOptions = useMemo(() => groupjobtitles.map((groupjobtitle) => ({ value: groupjobtitle.group_name, label: groupjobtitle.group_name })));
    let groupjobtitleFunc = dropdownFilterFunction(groupjobtitleOptions)


    let { departments } = useSelector(state => state.department)
    let departmentOptions = useMemo(() => departments.map((department) => ({ value: department.department_name, label: department.department_name })));
    let departmentFunc = dropdownFilterFunction(departmentOptions)

    let { fullPartTimes } = useSelector((state) => state.fullPartTime);
    let fullPartTimeOptions = useMemo(() => fullPartTimes.map((entry) => ({ value: entry.name, label: entry.name })))
    let fullPartTimeFunc = dropdownFilterFunction(fullPartTimeOptions)

    let { hourlySalaries } = useSelector((state) => state.hourlySalary)
    let hourlySalaryOptions = useMemo(() => hourlySalaries.map((entry) => ({ value: entry.name, label: entry.name })))
    let hourlySalaryFunc = dropdownFilterFunction(hourlySalaryOptions)

    let { hireStatuses } = useSelector((state) => state.hireStatus);
    let hireStatusOptions = useMemo(() => hireStatuses.map((entry) => ({ value: entry.name, label: entry.name })))
    let hireStatusFunc = dropdownFilterFunction(hireStatusOptions)

    let { markets } = useSelector(store => store.market);
    let marketOptions = useMemo(() => markets.map((entry) => ({ value: entry.market_name, label: entry.market_name })))
    let marketFunc = dropdownFilterFunction(marketOptions)

    let { regions } = useSelector(store => store.region);
    let regionOptions = useMemo(() => regions.map((entry) => ({ value: entry.region_name, label: entry.region_name })))
    let regionFunc = dropdownFilterFunction(regionOptions)

    let { divisions } = useSelector(store => store.division);
    let divisionOptions = useMemo(() => divisions.map((entry) => ({ value: entry.division_name, label: entry.division_name })))
    let divisionFunc = dropdownFilterFunction(divisionOptions)

    let { employeeStatuses } = useSelector((state) => state.employeeStatus)
    console.log("employeeStatuses", employeeStatuses)
    let employeeStatusOptions = useMemo(() => employeeStatuses.map((entry) => ({ value: entry.name, label: entry.name })))
    let employeeStatusFunc = dropdownFilterFunction(employeeStatusOptions)

    let companyOptions = useMemo(() => companies.map((entry) => ({ value: entry.company_name, label: entry.company_name })))
    let companyFunc = dropdownFilterFunction(companyOptions)

    let commssionable  = useSelector((state) => commisionableData)    
    let commssionableOptions = useMemo(() => commssionable.map((entry) => ({ value: entry.value, label: entry.name })))
    let commssionableFunc = dropdownFilterFunction(commssionableOptions)
    //console.log("commssionableFunc", commssionableFunc)


    const columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true,
            pinned: 'left'
        },
        {
            display_name: "Emp. Number",
            field: "employee_id",
            type: "text",
            width: 130,
            pinned: 'left'
        },
        {
            display_name: "First Name",
            field: "first_name",
            type: "text",
            width: 150,
            pinned: 'left'
        },
        {
            display_name: "Last Name",
            field: "last_name",
            type: "text",
            width: 175,
            pinned: 'left'
        },
        {
            display_name: "Prefered Name",
            field: "preferred_name",
            type: "text",
            hide: true
        },
        {
            display_name: JOB_TITLE,
            field: "job_title_name",
            type: "dropdown",
            dropdownOptions: jobtitles.map(jobtitle => jobtitle.job_title),
            dropdownFunction: jobtitleFunc
        },
        {
            display_name: EMPLOYEE_DEPARTMENT,
            field: "department_name",
            type: "dropdown",
            dropdownOptions: departments.map(entry => entry.department_name),
            dropdownFunction: departmentFunc,
            width: 200,
        },
        {
            display_name: "Home/Base Store",
            field: "store_sap_name",
            type: "text"
        },
        {
            display_name: "Work Email",
            field: "email_work",
            type: "text",
            width: 300
        },
        {
            display_name: "Personal Email",
            field: "email_personal",
            type: "text",
            hide: true
        },
        {
            display_name: "Hire Date",
            field: "hire_date",
            type: "date",
            width: 115
        },
        {
            display_name: "Termination Date",
            field: "term_date",
            type: "date",
            width: 150
        },
        {
            display_name: "Termination Reason",
            field: "term_reason",
            type: "text",
            width: 165
        },
        {
            display_name: PART_FULL_TIME,
            field: "full_part_time_status",
            type: "dropdown",
            dropdownOptions: fullPartTimes.map(entry => entry.name),
            dropdownFunction: fullPartTimeFunc,
            width: 175
        },
        {
            display_name: HOURLY_SALARY,
            field: "hourly_salary_name",
            type: "dropdown",
            dropdownOptions: hourlySalaries.map(entry => entry.name),
            dropdownFunction: hourlySalaryFunc,
            width: 170
        },
        {
            display_name: HIRE_STATUS,
            field: "hire_status_name",
            type: "dropdown",
            dropdownOptions: hireStatuses.map(entry => entry.name),
            dropdownFunction: hireStatusFunc,
            width: 115
        },
        {
            display_name: "ntid",
            field: "ntid",
            type: "text",
            // hide: true,
            width: 125
        },
        {
            display_name: "cnumber",
            field: "cnumber",
            type: "text",
            // hide: true,
            width: 115
        },
        {
            display_name: MARKET,
            field: "market_name",
            type: "dropdown",
            dropdownOptions: markets.map(entry => entry.market_name),
            dropdownFunction: marketFunc,
            width: 215
        },
        {
            display_name: REGION,
            field: "region_name",
            type: "dropdown",
            dropdownOptions: regions.map(entry => entry.region_name),
            dropdownFunction: regionFunc,
            width: 215
        },
        {
            display_name: DIVISION,
            field: "division_name",
            type: "dropdown",
            dropdownOptions: divisions.map(entry => entry.division_name),
            dropdownFunction: divisionFunc,
            width: 115,
        },
        {
            display_name: COMPANY,
            field: "company_name",
            type: "dropdown",
            dropdownOptions: companies.map(entry => entry.company_name),
            dropdownFunction: companyFunc,
        },
        {
            display_name: "Work Phone",
            field: "work_phone",
            type: "text",
            width: 140
        },
        {
            display_name: "Personal Phone",
            field: "personal_phone",
            type: "text",
            hide: true
        },
        {
            display_name: "Home Address",
            field: "home_address",
            type: "text",
            // hide: true,
            width: 145
        },
        {
            display_name: "Supervisor",
            field: "supervisor_name_id",
            type: "text",
            width: 115
        },
        {
            display_name: EMPLOYEE_STATUS,
            field: "emp_status",
            type: "dropdown",
            dropdownOptions: employeeStatuses.map(entry => entry.name),
            dropdownFunction: employeeStatusFunc,
            width: 165,
        },
        {
            display_name: "Job Title By Group",
            field: "group_name",
            type: "dropdown",
            dropdownOptions: groupjobtitles.map(entry => entry.group_name),
            dropdownFunction: groupjobtitleFunc,
            hide: true
        },
        {
            display_name: "Commisionable",
            field: "is_commisionable",
            type: "bool",
            icon: "checkbox",
            width: 100,
          },
        // {
        //     display_name: "Commisionable",
        //     field: "is_commisionable",
        //     type: "dropdown",
        //     dropdownOptions: commssionable.map(entry => entry.name),
        //     dropdownFunction: commssionableFunc            
        // }
    ];
    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { href: '/employees', label: EMPLOYEES },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission ? (
                            // { <DefaultButton
                            //     buttonClass={"default-btn-color me-2"}
                            //     buttonTitle={"Import"}
                            //     actionBlock={() => {
                            //         setShowImportModal(true);
                            //     }}
                            // />
                            <DefaultButton
                                buttonClass={"default-btn-color"}
                                buttonTitle={CREATENEW}
                                actionBlock={() => {
                                    navigate('./add')
                                }}
                            />
                    ) : (<></>)}
                </Col>
            </Row>
            <Row>
                <Card className='table-card'>
                    <Card.Body>
                        <select value={selectedCompanyId} style={{ width: 250 }} className="float-end form-control" onChange={(e) => { setSelectedCompanyId(Number.parseInt(e.target.value)) }}>
                            <option value={0}>All Companies</option>
                            {companies.map((company) =>
                                <option key={company.id} value={company.id} >{company.company_name}</option>
                            )
                            }
                        </select>
                        <ServerSideListView
                            exportTitle={EMPLOYEES}
                            exportUrl='/employee/v1/export'
                            columnNames={columns}
                            rowData={employeeData}
                            shouldSetNextPrevIds
                            rowClick={rowClick}
                            activeEmployeeFilter
                            filterCallback={filterCallback}
                            filterString={filterAndPage.filter}
                            sort={filterAndPage.sort}
                            tableHeight = '70vh'
                        />
                    </Card.Body>
                    <Row>
                        <Col ></Col>
                        <Col sm="auto">
                        </Col>
                        <Col sm={"auto"}>
                            <TablePaging page={filterAndPage.page} setPage={(num) => { setFilterAndPage({ ...filterAndPage, page: num }) }} total={rowCount} />
                        </Col>
                    </Row>
                </Card>
            </Row>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName="employee"
                modalTitle="Import Employees"
                importAction={loadEmployeeImportDataRequest}
            />
        </>
    )
}

export default Employees
