import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { BACKTOLIST, EMPLOYEES } from "../../../shared/constants/Constants";
import { loadEmployeeGetDataRequest, loadEmployeeUpdateDataRequest } from "../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import EmployeeFormEdit from "./EmployeeFormEdit";

const EmployeeEdit = () => {
    let params = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        dispatch(loadEmployeeGetDataRequest(params.id))
    }, [dispatch, params.id]);

    let { loading, employee } = useSelector(state => state.employee);

    // After submition, the post request is done loading, and there's no errors
    if (submitted && !loading) {
        setSubmitted(false)
        navigate('/employees/' + params.id)
    }

    function submit(values) {
        if (values.supervisor === "") {
            values.supervisor = null;
        }
        if (values.work_phone === "" || values.work_phone === "null") {
            values.work_phone = "";
        }
        if (values.term_date === "12/31/2099") {
            values.term_date = null;
        }
        dispatch(loadEmployeeUpdateDataRequest(values))
        setSubmitted(true)
    }

    return (
        <>
            <Row>
                <Col xl={4} xs={12}>
                    <Breadcrumbs
                        children={[
                            { href: '/employees', label: EMPLOYEES },
                            { href: '/employees/' + params.id, label: (employee?.first_name ?? "") + " " + (employee?.last_name ?? "") + ", " + (employee?.employee_id ?? '') },
                            { label: 'edit' },
                        ]}
                    />
                </Col>
                <Col xl={8} xs={12}>
                    <div className="float-end">
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={BACKTOLIST}
                            actionBlock={() => {
                                navigate("/employees");
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <EmployeeFormEdit employee={employee} submit={submit} />
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
};

export default EmployeeEdit;
