import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { loadModuleAddDataRequest } from "../redux/Actions";
import ModuleMetaDataForm from "./ModuleMetaDataForm";

const ModuleMetaDataAdd = ({ handleClose }) => {
  const dispatch = useDispatch();
  let [submitted, setSubmitted] = useState(false);
  let [isClosing, setIsClosing] = useState(false);

  let { loading, error } = useSelector((state) => state.modules);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (submitted && !loading && !isClosing) {
        setIsClosing(true);
        handleClose(true)
    }
}, [loading, dispatch, error, handleClose, isClosing, submitted])

  function submit(values) {
    dispatch(loadModuleAddDataRequest(values));
    setSubmitted(true);
  }
  function cancel() {
    handleClose(false);
  }
  return <ModuleMetaDataForm submit={submit} cancel={cancel} />;
};

export default ModuleMetaDataAdd;
