import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { DIVISION, DELETE, CANCEL, BACKTOLIST, EDIT, DIVISION_NAME, ULTI_DIVISION_CODE, COMPANY, START_DATE, END_DATE, DELETE_DIVISION, DELETE_CONFIRM } from "../../../shared/constants/Constants";
import { loadCompanyDataRequest } from "../../company/redux/Actions";
import { loadDivisionGetDataRequest, loadDivisionDeleteDataRequest, loadDivisionDataRequest } from "../redux/Actions";
import Modalpopup from "../../../shared/components/Modalpopup";
import { PrevNextButtons } from "../../../shared/components/PrevNextButtons";
import Permissions from "../../../shared/components/Permissions";
import { Link } from "react-router-dom";
import FindModule from "../../../shared/components/FindModule";
import KeyTagModuleList from "../../../shared/components/tagging/KeyTagModuleList";
import Tagging from "../../../shared/components/tagging/Tagging";
export default function DivisionDetails() {
    let params = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let [submittedDeleteRequest, setSubmittedDeleteRequest] = useState(false)

    let { companies } = useSelector(state => state.company);
    //don't reload companies if they're already loaded
    useEffect(() => {
        if (companies.length === 0) {
            dispatch(loadCompanyDataRequest())
        }
    }, [dispatch, companies.length])

    //Delete Functionality
    const handleDelete = () => {
        let deleteRequest = { id: [params.id] };
        dispatch(loadDivisionDeleteDataRequest(deleteRequest));
        setSubmittedDeleteRequest(true)
    };

    let { loading, error, division } = useSelector(state => state.division);
    useEffect(() => {
        dispatch(loadDivisionGetDataRequest(params.id));
    }, [dispatch, params.id])

    let divisionData = division[0]

    useEffect(() => {
        if (submittedDeleteRequest && !loading && error === null) {
            setShowDeleteModal(false);
            navigate('/division')
        }
    }, [loading, dispatch, division.msg, error, navigate, submittedDeleteRequest])

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Division";
    let permission = Permissions(emp_no, module_name);

    //Find Tag Key Exists or not and Tagging Functionality
    let selectedModule = FindModule(module_name); 
    let keytagModules = KeyTagModuleList(selectedModule?.id); 
    let [currentTracker, setCurrentTracker] = useState(1);

    let trackers = [];
    let taggingTab = false;
    if (keytagModules.length !== 0) {
        taggingTab = true;
        trackers = [
            <Tagging resource_value={divisionData?.id} resource_id={selectedModule?.id} />
        ]
    }  

    return <div>
        <Row>
            <Col xl={4} xs={12}>
                <Breadcrumbs
                    children={[
                        { href: '/division', label: DIVISION },
                        { href: '/division/' + params.id, label: params.id },
                    ]}
                />
            </Col>
            <Col xl={8} xs={12}>
                <div className='float-end'>
                    <PrevNextButtons currentId={params.id} basePath='/division/' name={"Divisions"} />
                    <DefaultButton
                        buttonClass={"default-btn-color me-1"}
                        buttonTitle={BACKTOLIST}
                        actionBlock={() => { navigate("/division"); }}
                    />
                    {permission.write_permission ? (<>
                        <DefaultButton buttonClass={"default-btn-color"} buttonTitle={EDIT} actionBlock={() => {
                            navigate("/division/edit/" + params.id);
                        }} />
                    </>) : (<></>)}
                    {permission.delete_permission ? (<>
                        <DefaultButton buttonClass={"btn btn-danger ms-1"} buttonTitle={<i className="fa fa-trash"></i>} actionBlock={() => { setShowDeleteModal(true) }} />
                    </>) : (<></>)}
                </div>
            </Col>
        </Row>
        <Row>
            <Card>
                <Card.Body>
                    <dl className='row'>
                        <dt className='col-sm-3'>{DIVISION_NAME}</dt>
                        <dd className='col-sm-9'>{divisionData?.division_name}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{ULTI_DIVISION_CODE}</dt>
                        <dd className='col-sm-9'>{divisionData?.ulti_division_code}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{COMPANY}</dt>
                        <dd className='col-sm-9'>
                            <Link to={"/company/" + divisionData?.company_id}>
                                {divisionData?.company_name}
                            </Link>
                        </dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{START_DATE}</dt>
                        <dd className='col-sm-9'>{divisionData?.start_date}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{END_DATE}</dt>
                        <dd className='col-sm-9'>{divisionData?.end_date}</dd>
                    </dl>
                </Card.Body>
            </Card>
        </Row>
        <Modalpopup
            show={showDeleteModal}
            handleClose={() => { setShowDeleteModal(false) }}
            actionTitle={DELETE_DIVISION}
        >
            <p>{DELETE_CONFIRM}</p>
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={handleDelete}
                />
            </div>
        </Modalpopup>
        <br />
        {taggingTab ? (
        <Row>
            <Col>      
                <button className={currentTracker === 1 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(1) }}>Additional Attribute</button>
            </Col>
            <Card >
                <Card.Body >
                    {trackers[currentTracker - 1]}
                </Card.Body>
            </Card>
        </Row>
        ) : (<></>)}  
    </div>
}