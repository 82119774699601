import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { loadDivisionDataRequest } from "../../division/redux/Actions";
import { CANCEL, DIVISION, END_DATE, REGION_NAME, SAVE, STARTAFTEREND, STARTNOTFUTURE, START_DATE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";


const TrackerForm = ({ tracker, submit, cancel, regionName, regionCode, regionId }) => {
    const isEditing = !!tracker;
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (values.division_id === -1 || values.division_id === '') {
            errors.division_id = 'Required';
        }
        if (!values.start_date || values.start_date === '') {
            errors.start_date = "Required";
        }
        if (!values.end_date || values.end_date === '') {
            errors.end_date = "Required";
        }
        if (new Date(values.start_date) >= new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        if (!tracker && new Date() >= new Date(values.start_date)) {
            // errors.start_date = STARTNOTFUTURE
        }
        return errors;
    };

    let { divisions } = useSelector(state => state.division);
    useEffect(() => {
        //don't reload companies if they're already loaded
        if (divisions.length === 0) {
            dispatch(loadDivisionDataRequest())
        }
    }, [dispatch, divisions.length])

    let trackStartDate = '';
    let trackEndDate = '12/31/2099';
    if (tracker && tracker?.start_date && tracker?.end_date) {
        if (tracker?.start_date.includes("-") && tracker?.end_date.includes("-")) {
            trackStartDate = FormatDate(tracker?.start_date); 
            trackEndDate = FormatDate(tracker?.end_date); 
        }
    }

    return <Formik
        initialValues={{
            division_id: tracker?.division_id ?? '',
            tracker_id: tracker?.id ?? '',
            start_date: trackStartDate,
            end_date: trackEndDate,
            tracker: true,
            id: regionId,
            region_name: regionName,
            ulti_region_code: regionCode,
        }}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="region_name" className="form-label">
                        {REGION_NAME}
                    </label>
                    <Field className="form-control" type='text' name='region_name' defaultValue={regionName} readOnly={true} autoComplete="off" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputMDCode" className="form-label">
                        {DIVISION} <span className="error-text">*</span>
                    </label>
                    <FormikSelectField
                        name="division_id"
                        value={values.division_id}
                        onChange={setFieldValue}
                        placeholder={'Select Division'}
                    >
                        {divisions.filter(division => {
                            return (division.active === 0 || division.active === 2) || isEditing
                        }).map((division) => {
                            return <option key={division.id} value={division.id}>{division.division_name}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name='division_id' />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        {START_DATE} <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="start_date" value={values.start_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose Start Date"} readOnly={false} />
                    <span className='error-text'>
                        <ErrorMessage name="start_date" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        {END_DATE} <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="end_date" value={values.end_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose End Date"} readOnly={false} />
                    <span className='error-text'>
                        <ErrorMessage name="end_date" />
                    </span>
                </div>
                <div className="col-md-12">
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </div>
            </Form>
        )}
    </Formik>
}

export default TrackerForm