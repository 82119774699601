import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CANCEL, SAVE } from "../../../shared/constants/Constants";

const RolesForm = ({ roles, submit, cancel }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.role_name) {
            errors.role_name = "Required";
        }
        return errors;
    };

    const formik = useFormik({});

    useEffect(() => {
        if (roles) {
            formik.current.setFieldValue("id", roles?.id);
            formik.current.setFieldValue("role_name", roles?.role_name);
        }
    }, [roles, formik]);

    return (
        <Formik
            innerRef={formik}
            initialValues={{
                role_name: "",
            }}
            validate={validate}
            onSubmit={submit}
        >
            {() => (
                <Form className="row g-4">
                    <div className="col-md-6">
                        <label htmlFor="role_name" className="form-label">
                            Role Name<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="role_name"
                            placeholder="Enter Role Name"
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="role_name" />
                        </span>
                    </div>
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default RolesForm;
