//Get
const ROLE_MODULE_DATA = {
    ROLE_MODULE_DATA_LOAD: "ROLE_MODULE_DATA_LOAD",
    ROLE_MODULE_DATA_SUCCESS: "ROLE_MODULE_DATA_SUCCESS",
    ROLE_MODULE_DATA_FAIL: "ROLE_MODULE_DATA_FAIL",
};
const ROLE_MODULE_GET_API = "/admin/rolesModule";

// Get one by id
const ROLE_MODULE_GET_DATA = {
    ROLE_MODULE_GET_DATA_LOAD: "ROLE_MODULE_GET_DATA_LOAD",
    ROLE_MODULE_GET_DATA_SUCCESS: "ROLE_MODULE_GET_DATA_SUCCESS",
    ROLE_MODULE_GET_DATA_FAIL: "ROLE_MODULE_GET_DATA_FAIL",
};

const ROLE_MODULE_GET_DETAIL_API = "/admin/rolesModule/";

// Create / Add / Post
const ROLE_MODULE_ADD_DATA = {
    ROLE_MODULE_ADD_DATA_LOAD: "ROLE_MODULE_ADD_DATA_LOAD",
    ROLE_MODULE_ADD_DATA_SUCCESS: "ROLE_MODULE_ADD_DATA_SUCCESS",
    ROLE_MODULE_ADD_DATA_FAIL: "ROLE_MODULE_ADD_DATA_FAIL",
};

const ROLE_MODULE_POST_API = "/admin/rolesModule";

//Update
const ROLE_MODULE_UPDATE_DATA = {
  ROLE_MODULE_UPDATE_DATA_LOAD: "ROLE_MODULE_UPDATE_DATA_UPLOAD",
  ROLE_MODULE_UPDATE_DATA_SUCCESS: "ROLE_MODULE_UPDATE_DATA_SUCCESS",
  ROLE_MODULE_UPDATE_DATA_FAIL: "ROLE_MODULE_UPDATE_DATA_FAIL",
};

const ROLE_MODULE_PUT_API = "/admin/rolesModule";

// Delete
const ROLE_MODULE_DELETE_DATA = {
  ROLE_MODULE_DELETE_DATA_LOAD: "ROLE_MODULE_DELETE_DATA_LOAD",
  ROLE_MODULE_DELETE_DATA_SUCCESS: "ROLE_MODULE_DELETE_DATA_SUCCESS",
  ROLE_MODULE_DELETE_DATA_FAIL: "ROLE_MODULE_DELETE_DATA_FAIL",
};

const ROLE_MODULE_DELETE_API = "/admin/rolesModule";

export{
    ROLE_MODULE_DATA,
    ROLE_MODULE_GET_API,
    ROLE_MODULE_GET_DATA,
    ROLE_MODULE_GET_DETAIL_API,
    ROLE_MODULE_ADD_DATA,
    ROLE_MODULE_POST_API,
    ROLE_MODULE_UPDATE_DATA,
    ROLE_MODULE_PUT_API,
    ROLE_MODULE_DELETE_DATA,
    ROLE_MODULE_DELETE_API,
}