import React from "react";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import { Card, Col, Row } from "react-bootstrap";
import TShirtSize from "./TShirtSize";

const SalesUsers = () => {
  return (
    <>
      <Breadcrumbs children={[{ label: "T-Shirt Size" }]} />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <TShirtSize />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SalesUsers;
