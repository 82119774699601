const CLASSIFICATION_DATA = {
    CLASSIFICATION_DATA_LOAD: "CLASSIFICATION_DATA_LOAD",
    CLASSIFICATION_DATA_SUCCESS: "CLASSIFICATION_DATA_SUCCESS",
    CLASSIFICATION_DATA_FAIL: "CLASSIFICATION_DATA_FAIL",
  };
  
  const CLASSIFICATION_GET_API = "/store/classification";
  
  //Get
  const CLASSIFICATION_GET_DATA = {
    CLASSIFICATION_GET_DATA_LOAD: "CLASSIFICATION_GET_DATA_LOAD",
    CLASSIFICATION_GET_DATA_SUCCESS: "CLASSIFICATION_GET_DATA_SUCCESS",
    CLASSIFICATION_GET_DATA_FAIL: "CLASSIFICATION_GET_DATA_FAIL",
  };
  
  const CLASSIFICATION_GET_DETAIL_API = "/store/classification/";
  
  // Create
  const CLASSIFICATION_ADD_DATA = {
    CLASSIFICATION_ADD_DATA_LOAD: "CLASSIFICATION_ADD_DATA_LOAD",
    CLASSIFICATION_ADD_DATA_SUCCESS: "CLASSIFICATION_ADD_DATA_SUCCESS",
    CLASSIFICATION_ADD_DATA_FAIL: "CLASSIFICATION_ADD_DATA_FAIL",
  };
  
  const CLASSIFICATION_POST_API = "/store/classification";
  
  //Update
  const CLASSIFICATION_UPDATE_DATA = {
    CLASSIFICATION_UPDATE_DATA_LOAD: "CLASSIFICATION_UPDATE_DATA_LOAD",
    CLASSIFICATION_UPDATE_DATA_SUCCESS: "CLASSIFICATION_UPDATE_DATA_SUCCESS",
    CLASSIFICATION_UPDATE_DATA_FAIL: "CLASSIFICATION_UPDATE_DATA_FAIL",
  };
  
  const CLASSIFICATION_PUT_API = "/store/classification";
  
  //Delete
  const CLASSIFICATION_DELETE_DATA = {
    CLASSIFICATION_DELETE_DATA_LOAD: "CLASSIFICATION_DELETE_DATA_LOAD",
    CLASSIFICATION_DELETE_DATA_SUCCESS: "CLASSIFICATION_DELETE_DATA_SUCCESS",
    CLASSIFICATION_DELETE_DATA_FAIL: "CLASSIFICATION_DELETE_DATA_FAIL",
  };
  
  const CLASSIFICATION_DELETE_API = "/store/classification";
  
  export {
    CLASSIFICATION_DATA,
    CLASSIFICATION_GET_API,
    CLASSIFICATION_GET_DATA,
    CLASSIFICATION_GET_DETAIL_API,
    CLASSIFICATION_ADD_DATA,
    CLASSIFICATION_POST_API,
    CLASSIFICATION_UPDATE_DATA,
    CLASSIFICATION_PUT_API,
    CLASSIFICATION_DELETE_DATA,
    CLASSIFICATION_DELETE_API,
  };
  