import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import { DIVISION, CREATENEW, IMPORTDIVISION, IMPORT, CREATEDIVISION } from '../../../shared/constants/Constants';
import { loadDivisionDataRequest, loadDivisionImportDataRequest } from '../redux/Actions';
import DefaultButton from '../../../shared/components/DefaultButton';
import Modalpopup from '../../../shared/components/Modalpopup';
import DivisionAdd from './DivisionAdd';
import ListView from '../../../shared/components/listview/ListView';
import { loadCompanyDataRequest } from '../../company/redux/Actions';
import ImportModal from '../../../shared/components/ImportModal';
import Permissions from '../../../shared/components/Permissions';


const Division = () => {
    let dispatch = useDispatch();
    let { divisions } = useSelector(state => state.division);
    useEffect(() => {
        dispatch(loadDivisionDataRequest());
    }, [dispatch])


    // The following essentially joins divisions and companies to fill in company name.
    let { companies } = useSelector(state => state.company);
    //don't reload companies if they're already loaded
    useEffect(() => {
        if (companies.length === 0) {
            dispatch(loadCompanyDataRequest())
        }
    }, [dispatch, companies.length])



    let navigate = useNavigate();

    let [showAddModal, setShowAddModal] = useState(false);
    let [showImportModal, setShowImportModal] = useState(false);

    let divisionColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'Division Name',
            field: 'division_name',
            type: 'text'
        }, {
            display_name: 'Ulti Division Code',
            field: 'ulti_division_code',
            type: 'text'
        }, {
            display_name: 'Company',
            field: 'company_name',
            type: 'text'
        }, {
            display_name: 'Start Date',
            field: 'start_date',
            type: 'date',
        }, {
            display_name: 'End Date',
            field: 'end_date',
            type: 'date'
        }, {
            display_name: 'Status',
            field: 'active',
            type: 'active'
        }
    ];

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Division";
    let permission = Permissions(emp_no, module_name);

    return (
        <div>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { label: DIVISION },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission ? (
                        <>
                            <DefaultButton buttonClass='default-btn-color me-1' buttonTitle={IMPORT} actionBlock={() => setShowImportModal(true)} />
                            <DefaultButton buttonClass='default-btn-color' buttonTitle={CREATENEW} actionBlock={() => setShowAddModal(true)} />
                        </>
                    ) : (<></>)}
                </Col>
            </Row>
            <Row>
                <Card className='table-card'>
                    <Card.Body>
                        <ListView
                            exportTitle="Divisions"
                            columnNames={divisionColumns}
                            rowData={divisions}
                            shouldSetNextPrevIds
                            rowClick={(row) => {
                                navigate('./' + row.data['id'])
                            }}
                            tableHeight = '70vh'
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup show={showAddModal} handleClose={() => setShowAddModal(false)} actionTitle={CREATEDIVISION}>
                <DivisionAdd handleClose={(reload) => {
                    setShowAddModal(false);
                    if (reload) {
                        dispatch(loadDivisionDataRequest());
                    }
                }} />
            </Modalpopup>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName={"division"}
                modalTitle={IMPORTDIVISION}
                importAction={loadDivisionImportDataRequest}
            />

        </div>
    )
}

export default Division
