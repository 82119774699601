import { PRODUCT_DATA, PRODUCT_COUNT, PRODUCT_IMPORT_DATA, PRODUCT_ADD_DATA, PRODUCT_DELETE_DATA, PRODUCT_GET_DATA, PRODUCT_UPDATE_DATA, CATEGORY_PRODUCT_DATA, CATEGORY_PRODUCT_ADD_DATA, CATEGORY_PRODUCT_DELETE_DATA, PRODUCT_TYPE_DATA, ACCOUNT_TYPE_DATA, SUB_CATEGORY_DATA, CATEGORY_DATA, ACCESSORY_DATA, ACCESSORY_COUNT, ACCESSORY_IMPORT_DATA } from './Constants'

const initialState = {
    loading:false,
    error:null,
    products:[],
    product:{},
    accessories:[],
    accessory:{},
    rowCount: 0,
    isImportFile: false,
    isAImportFile: false,
    categoryProducts:[],
    categoryProduct:{},
    pTypes:[],
    aTypes:[],
    subcategories:[],
    categories:[]
}

const Reducer = (state=initialState, action) => {
    switch(action.type) {
        //Product List Conditions
        case PRODUCT_DATA.PRODUCT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                products:[]
            }
        case PRODUCT_DATA.PRODUCT_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                products:action.products,
                error:null
            }
        case PRODUCT_DATA.PRODUCT_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                products:[]
            }
        case PRODUCT_COUNT.PRODUCT_COUNT_LOAD:
            return {
                ...state,
            }
        case PRODUCT_COUNT.PRODUCT_COUNT_SUCCESS:
            return {
                ...state,
                rowCount: action.count
            }   
        case PRODUCT_COUNT.PRODUCT_COUNT_FAIL:
            return {
                ...state,
                error: action.error
            }
        case ACCESSORY_DATA.ACCESSORY_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                accessories:[]
            }
        case ACCESSORY_DATA.ACCESSORY_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                accessories:action.accessories,
                error:null
            }
        case ACCESSORY_DATA.ACCESSORY_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                accessories:[]
            }
        case ACCESSORY_COUNT.ACCESSORY_COUNT_LOAD:
            return {
                ...state,
            }
        case ACCESSORY_COUNT.ACCESSORY_COUNT_SUCCESS:
            return {
                ...state,
                rowCount: action.count
            }   
        case ACCESSORY_COUNT.ACCESSORY_COUNT_FAIL:
            return {
                ...state,
                error: action.error
            }
        case PRODUCT_IMPORT_DATA.PRODUCT_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                products:[],
                isImportFile: false
            }
        case PRODUCT_IMPORT_DATA.PRODUCT_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                products:action.products,
                error:null,
                isImportFile: true
            }
        case PRODUCT_IMPORT_DATA.PRODUCT_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                products:[],
                isImportFile: false
            }
        case ACCESSORY_IMPORT_DATA.ACCESSORY_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                accessories:[],
                isAImportFile: false
            }
        case ACCESSORY_IMPORT_DATA.ACCESSORY_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                accessories:action.accessories,
                error:null,
                isAImportFile: true
            }
        case ACCESSORY_IMPORT_DATA.ACCESSORY_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                accessories:[],
                isAImportFile: false
            }
        //Add
        case PRODUCT_ADD_DATA.PRODUCT_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                product:{},
                error:null
            }
        case PRODUCT_ADD_DATA.PRODUCT_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                product:action.product,
                error:null
            }
        case PRODUCT_ADD_DATA.PRODUCT_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                product:{},
                error:action.error
            }
        //Get Details
        case PRODUCT_GET_DATA.PRODUCT_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                product:{},
                error:null,
            }
        case PRODUCT_GET_DATA.PRODUCT_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                product:action.product,
                error:null
            }
        case PRODUCT_GET_DATA.PRODUCT_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                product:{},
                error:action.error
            }
        //Update Conditions
        case PRODUCT_UPDATE_DATA.PRODUCT_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                product:{},
                error:null
            }
        case PRODUCT_UPDATE_DATA.PRODUCT_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                product:action.product,
                error:null
            }
        case PRODUCT_UPDATE_DATA.PRODUCT_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                product:{},
                error:action.error
            }
        //Delete Conditions
        case PRODUCT_DELETE_DATA.PRODUCT_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                product:{},
                error:null
            }
        case PRODUCT_DELETE_DATA.PRODUCT_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                product:action.product,
                error:null
            }
        case PRODUCT_DELETE_DATA.PRODUCT_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                product:{},
                error:action.error
            }
        case CATEGORY_PRODUCT_DATA.CATEGORY_PRODUCT_DATA_LOAD:
            return {
                ...state,
                loading: true,
                categoryProducts:[],
                error:null
            }
        case CATEGORY_PRODUCT_DATA.CATEGORY_PRODUCT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                categoryProducts:action.categoryProducts
            }
        case CATEGORY_PRODUCT_DATA.CATEGORY_PRODUCT_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        case CATEGORY_PRODUCT_ADD_DATA.CATEGORY_PRODUCT_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case CATEGORY_PRODUCT_ADD_DATA.CATEGORY_PRODUCT_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case CATEGORY_PRODUCT_ADD_DATA.CATEGORY_PRODUCT_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case CATEGORY_PRODUCT_DELETE_DATA.CATEGORY_PRODUCT_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case CATEGORY_PRODUCT_DELETE_DATA.CATEGORY_PRODUCT_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case CATEGORY_PRODUCT_DELETE_DATA.CATEGORY_PRODUCT_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        //Product Type
        case PRODUCT_TYPE_DATA.PRODUCT_TYPE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                pTypes:[],
                error:null
            }
        case PRODUCT_TYPE_DATA.PRODUCT_TYPE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                pTypes:action.pTypes
            }
        case PRODUCT_TYPE_DATA.PRODUCT_TYPE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        case ACCOUNT_TYPE_DATA.ACCOUNT_TYPE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                aTypes:[],
                error:null
            }
        case ACCOUNT_TYPE_DATA.ACCOUNT_TYPE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                aTypes:action.aTypes
            }
        case ACCOUNT_TYPE_DATA.ACCOUNT_TYPE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        case SUB_CATEGORY_DATA.SUB_CATEGORY_DATA_LOAD:
            return {
                ...state,
                loading: true,
                subcategories:[],
                error:null
            }
        case SUB_CATEGORY_DATA.SUB_CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                subcategories:action.subcategories
            }
        case SUB_CATEGORY_DATA.SUB_CATEGORY_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        case CATEGORY_DATA.CATEGORY_DATA_LOAD:
            return {
                ...state,
                loading: true,
                subcategories:[],
                error:null
            }
        case CATEGORY_DATA.CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                categories:action.categories
            }
        case CATEGORY_DATA.CATEGORY_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        default: 
            return {
                ...state
            }
    }
}

export default Reducer;