import {
  PRODUCT_DATA,
  PRODUCT_COUNT,
  PRODUCT_IMPORT_DATA,
  ACCESSORY_IMPORT_DATA,
  PRODUCT_ADD_DATA,
  PRODUCT_GET_DATA,
  PRODUCT_UPDATE_DATA,
  PRODUCT_DELETE_DATA,
  CATEGORY_PRODUCT_DATA,
  CATEGORY_PRODUCT_ADD_DATA,
  CATEGORY_PRODUCT_DELETE_DATA,
  PRODUCT_TYPE_DATA,
  ACCOUNT_TYPE_DATA,
  SUB_CATEGORY_DATA,
  CATEGORY_DATA,
  ACCESSORY_COUNT,
  ACCESSORY_DATA
} from "./Constants";

//For Listing
const loadProductDataRequest = (filters, page, sort) => ({
  type: PRODUCT_DATA.PRODUCT_DATA_LOAD,
  filters,
  page,
  sort,
});

const setProductDataSuccess = (products) => ({
  type: PRODUCT_DATA.PRODUCT_DATA_SUCCESS,
  products,
});

const setProductDataFail = (error) => ({
  type: PRODUCT_DATA.PRODUCT_DATA_FAIL,
  error,
});

//Get total count
const loadProductRowCount = (filters) => ({
  type: PRODUCT_COUNT.PRODUCT_COUNT_LOAD,
  filters
})
const setProductRowCountSuccess = (count) => ({
  type: PRODUCT_COUNT.PRODUCT_COUNT_SUCCESS,
  count
})
const setProductRowCountFail = (error) => ({
  type: PRODUCT_COUNT.PRODUCT_COUNT_FAIL,
  error
})

//For accessory Listing
const loadAccessoryDataRequest = (filters, page, sort) => ({
  type: ACCESSORY_DATA.ACCESSORY_DATA_LOAD,
  filters,
  page,
  sort,
});

const setAccessoryDataSuccess = (accessories) => ({
  type: ACCESSORY_DATA.ACCESSORY_DATA_SUCCESS,
  accessories,
});

const setAccessoryDataFail = (error) => ({
  type: ACCESSORY_DATA.ACCESSORY_DATA_FAIL,
  error,
});

//Get total count
const loadAccessoryRowCount = (filters) => ({
  type: ACCESSORY_COUNT.ACCESSORY_COUNT_LOAD,
  filters
})
const setAccessoryRowCountSuccess = (count) => ({
  type: ACCESSORY_COUNT.ACCESSORY_COUNT_SUCCESS,
  count
})
const setAccessoryRowCountFail = (error) => ({
  type: ACCESSORY_COUNT.ACCESSORY_COUNT_FAIL,
  error
})

//Add
const loadProductAddDataRequest = (request) => ({
  type: PRODUCT_ADD_DATA.PRODUCT_ADD_DATA_LOAD,
  request,
});

const setProductAddDataSuccess = (product) => ({
  type: PRODUCT_ADD_DATA.PRODUCT_ADD_DATA_SUCCESS,
  product,
});

const setProductAddDataFail = (error) => ({
  type: PRODUCT_ADD_DATA.PRODUCT_ADD_DATA_FAIL,
  error,
});

//Update
const loadProductUpdateDataRequest = (request, id) => ({
  type: PRODUCT_UPDATE_DATA.PRODUCT_UPDATE_DATA_LOAD,
  request,
  id
});

const setProductUpdateDataSuccess = (product) => ({
  type: PRODUCT_UPDATE_DATA.PRODUCT_UPDATE_DATA_SUCCESS,
  product,
});

const setProductUpdateDataFail = (error) => ({
  type: PRODUCT_UPDATE_DATA.PRODUCT_UPDATE_DATA_FAIL,
  error,
});

//Get
const loadProductGetDataRequest = (id) => ({
  type: PRODUCT_GET_DATA.PRODUCT_GET_DATA_LOAD,
  id,
});

const setProductGetDataSuccess = (product) => ({
  type: PRODUCT_GET_DATA.PRODUCT_GET_DATA_SUCCESS,
  product,
});

const setProductGetDataFail = (error) => ({
  type: PRODUCT_GET_DATA.PRODUCT_GET_DATA_FAIL,
  error,
});

//Delete
const loadProductDeleteDataRequest = (request) => ({
  type: PRODUCT_DELETE_DATA.PRODUCT_DELETE_DATA_LOAD,
  request,
});

const setProductDeleteDataSuccess = () => ({
  type: PRODUCT_DELETE_DATA.PRODUCT_DELETE_DATA_SUCCESS,
});

const setProductDeleteDataFail = (error) => ({
  type: PRODUCT_DELETE_DATA.PRODUCT_DELETE_DATA_FAIL,
  error,
});

//Import
const loadProductImportDataRequest = (request, url) => ({
  type: PRODUCT_IMPORT_DATA.PRODUCT_IMPORT_DATA_LOAD,
  request,
  url
});

const setProductImportDataSuccess = (products) => ({
  type: PRODUCT_IMPORT_DATA.PRODUCT_IMPORT_DATA_SUCCESS,
  products,
});

const setProductImportDataFail = (error) => ({
  type: PRODUCT_IMPORT_DATA.PRODUCT_IMPORT_DATA_FAIL,
  error,
});

//Accessory Import
const loadAccessoryImportDataRequest = (request, url) => ({
  type: ACCESSORY_IMPORT_DATA.ACCESSORY_IMPORT_DATA_LOAD,
  request,
  url
});

const setAccessoryImportDataSuccess = (accessories) => ({
  type: ACCESSORY_IMPORT_DATA.ACCESSORY_IMPORT_DATA_SUCCESS,
  accessories,
});

const setAccessoryImportDataFail = (error) => ({
  type: ACCESSORY_IMPORT_DATA.ACCESSORY_IMPORT_DATA_FAIL,
  error,
});

//Category Product
const loadCategoryProductDataRequest = (id) => ({
  type: CATEGORY_PRODUCT_DATA.CATEGORY_PRODUCT_DATA_LOAD,
  id
});
const setCategoryProductDataSuccess = (categoryProducts) => ({
  type: CATEGORY_PRODUCT_DATA.CATEGORY_PRODUCT_DATA_SUCCESS,
  categoryProducts
});
const setCategoryProductDataFail = (error) => ({
  type: CATEGORY_PRODUCT_DATA.CATEGORY_PRODUCT_DATA_FAIL,
  error,
});

//For add 
const loadCategoryProductAddDataRequest = (request) => ({
  type: CATEGORY_PRODUCT_ADD_DATA.CATEGORY_PRODUCT_ADD_DATA_LOAD,
  request,
});
const setCategoryProductAddDataSuccess = (categoryProduct) => ({
  type: CATEGORY_PRODUCT_ADD_DATA.CATEGORY_PRODUCT_ADD_DATA_SUCCESS,
  categoryProduct,
});
const setCategoryProductAddDataFail = (error) => ({
  type: CATEGORY_PRODUCT_ADD_DATA.CATEGORY_PRODUCT_ADD_DATA_FAIL,
  error,
});

//Delete
const loadCategoryProductDeleteDataRequest = (request) => ({
  type: CATEGORY_PRODUCT_DELETE_DATA.CATEGORY_PRODUCT_DELETE_DATA_LOAD,
  request,
});
const setCategoryProductDeleteDataSuccess = (categoryProduct) => ({
  type: CATEGORY_PRODUCT_DELETE_DATA.CATEGORY_PRODUCT_DELETE_DATA_SUCCESS,
  categoryProduct,
});
const setCategoryProductDeleteDataFail = (error) => ({
  type: CATEGORY_PRODUCT_DELETE_DATA.CATEGORY_PRODUCT_DELETE_DATA_FAIL,
  error,
});

//Get Product Type
const loadProductTypeDataRequest = () => ({
  type: PRODUCT_TYPE_DATA.PRODUCT_TYPE_DATA_LOAD, 
});
const setProductTypeDataSuccess = (pTypes) => ({
  type: PRODUCT_TYPE_DATA.PRODUCT_TYPE_DATA_SUCCESS,
  pTypes,
});
const setProductTypeDataFail = (error) => ({
  type: PRODUCT_TYPE_DATA.PRODUCT_TYPE_DATA_FAIL,
  error,
});

const loadAccountTypeDataRequest = () => ({
  type: ACCOUNT_TYPE_DATA.ACCOUNT_TYPE_DATA_LOAD, 
});
const setAccountTypeDataSuccess = (aTypes) => ({
  type: ACCOUNT_TYPE_DATA.ACCOUNT_TYPE_DATA_SUCCESS,
  aTypes,
});
const setAccountTypeDataFail = (error) => ({
  type: ACCOUNT_TYPE_DATA.ACCOUNT_TYPE_DATA_FAIL,
  error,
});

const loadSubCategoryDataRequest = () => ({
  type: SUB_CATEGORY_DATA.SUB_CATEGORY_DATA_LOAD, 
});
const setSubCategoryDataSuccess = (subcategories) => ({
  type: SUB_CATEGORY_DATA.SUB_CATEGORY_DATA_SUCCESS,
  subcategories,
});
const setSubCategoryDataFail = (error) => ({
  type: SUB_CATEGORY_DATA.SUB_CATEGORY_DATA_FAIL,
  error,
});

const loadCategoryDataRequest = () => ({
  type: CATEGORY_DATA.CATEGORY_DATA_LOAD, 
});
const setCategoryDataSuccess = (categories) => ({
  type: CATEGORY_DATA.CATEGORY_DATA_SUCCESS,
  categories,
});
const setCategoryDataFail = (error) => ({
  type: CATEGORY_DATA.CATEGORY_DATA_FAIL,
  error,
});

export {
  loadProductDataRequest,
  setProductDataSuccess,
  setProductDataFail,
  loadProductRowCount,
  setProductRowCountSuccess,
  setProductRowCountFail,

  loadAccessoryDataRequest,
  setAccessoryDataSuccess,
  setAccessoryDataFail,
  loadAccessoryRowCount,
  setAccessoryRowCountSuccess,
  setAccessoryRowCountFail,

  loadProductAddDataRequest,
  setProductAddDataSuccess,
  setProductAddDataFail,
  loadProductUpdateDataRequest,
  setProductUpdateDataSuccess,
  setProductUpdateDataFail,
  loadProductGetDataRequest,
  setProductGetDataSuccess,
  setProductGetDataFail,
  loadProductDeleteDataRequest,
  setProductDeleteDataSuccess,
  setProductDeleteDataFail,

  loadProductImportDataRequest,
  setProductImportDataSuccess,
  setProductImportDataFail,
  loadAccessoryImportDataRequest,
  setAccessoryImportDataSuccess,
  setAccessoryImportDataFail,

  loadCategoryProductDataRequest,
  setCategoryProductDataSuccess,
  setCategoryProductDataFail,
  loadCategoryProductAddDataRequest,
  setCategoryProductAddDataSuccess,
  setCategoryProductAddDataFail,
  loadCategoryProductDeleteDataRequest,
  setCategoryProductDeleteDataSuccess,
  setCategoryProductDeleteDataFail,

  loadProductTypeDataRequest,
  setProductTypeDataSuccess,
  setProductTypeDataFail,
  loadAccountTypeDataRequest,
  setAccountTypeDataSuccess,
  setAccountTypeDataFail,
  loadSubCategoryDataRequest,
  setSubCategoryDataSuccess,
  setSubCategoryDataFail,
  loadCategoryDataRequest,
  setCategoryDataSuccess,
  setCategoryDataFail
};
