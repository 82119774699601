const COMPANY_DATA = {
    COMPANY_DATA_LOAD:'COMPANY_DATA_LOAD', 
    COMPANY_DATA_SUCCESS:'COMPANY_DATA_SUCCESS', 
    COMPANY_DATA_FAIL:'COMPANY_DATA_FAIL'
}

const COMPANY_GET_API = '/company'


const COMPANY_ADD_DATA = {
    COMPANY_ADD_DATA_LOAD:'COMPANY_ADD_DATA_LOAD',
    COMPANY_ADD_DATA_SUCCESS:'COMPANY_ADD_DATA_SUCCESS', 
    COMPANY_ADD_DATA_FAIL:'COMPANY_ADD_DATA_FAIL',
    COMPANY_ADD_DATA_RESET:'COMPANY_ADD_DATA_RESET'
}

const COMPANY_POST_API = '/company'


const COMPANY_UPDATE_DATA = {
    COMPANY_UPDATE_DATA_LOAD:'COMPANY_UPDATE_DATA_LOAD',
    COMPANY_UPDATE_DATA_SUCCESS:'COMPANY_UPDATE_DATA_SUCCESS', 
    COMPANY_UPDATE_DATA_FAIL:'COMPANY_UPDATE_DATA_FAIL'
}

const COMPANY_PUT_API = '/company'


const COMPANY_GET_DATA = {
    COMPANY_GET_DATA_LOAD:'COMPANY_GET_DATA_LOAD',
    COMPANY_GET_DATA_SUCCESS:'COMPANY_GET_DATA_SUCCESS', 
    COMPANY_GET_DATA_FAIL:'COMPANY_GET_DATA_FAIL'
}

const COMPANY_GET_DETAIL_API = '/company/'

//Delete Company
const COMPANY_DELETE_DATA = {
    COMPANY_DELETE_DATA_LOAD:'COMPANY_DELETE_DATA_LOAD',
    COMPANY_DELETE_DATA_SUCCESS:'COMPANY_DELETE_DATA_SUCCESS', 
    COMPANY_DELETE_DATA_FAIL:'COMPANY_DELETE_DATA_FAIL'
}

const COMPANY_DELETE_API = '/company'


//Import Company
const COMPANY_IMPORT_DATA = {
    COMPANY_IMPORT_DATA_LOAD:'COMPANY_IMPORT_DATA_LOAD',
    COMPANY_IMPORT_DATA_SUCCESS:'COMPANY_IMPORT_DATA_SUCCESS', 
    COMPANY_IMPORT_DATA_FAIL:'COMPANY_IMPORT_DATA_FAIL'
}

const COMPANY_IMPORT_API = '/upload'

export {COMPANY_DATA, COMPANY_GET_API, COMPANY_ADD_DATA, COMPANY_POST_API, COMPANY_UPDATE_DATA, COMPANY_PUT_API, COMPANY_GET_DATA, COMPANY_GET_DETAIL_API, COMPANY_DELETE_DATA, COMPANY_DELETE_API, COMPANY_IMPORT_DATA, COMPANY_IMPORT_API}

