import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loadModuleDataRequest } from "../../containers/module_meta_data/redux/Actions";

const FindModule = (moduleName) => {

    const dispatch = useDispatch();    
    let [selectedModule, setSelectedModule] = useState([]);
    let { modules } = useSelector((state) => state.modules);

    useEffect(() => {
        if (modules.length === 0) {
            dispatch(loadModuleDataRequest());
        }
    },[dispatch])

    useEffect(() => {
        setSelectedModule([...modules].filter(
            (module) => {
                return module.module_name == moduleName;
            }
        ))        
    }, [modules, setSelectedModule]);

    return selectedModule[0];
}

export default FindModule