import { Fragment, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from 'react-select';
import { getFilterOptions, jsonRenderer } from "../../tableFunctions";
export function DateFilter({ field, filterType, setFilter, existingFilter }) {
    //The type of filters being applied
    let [type1, setType1] = useState('date_equals');
    let [type2, setType2] = useState('date_equals');

    //the value to filter by
    let [value1, setValue1] = useState([''])
    let [value2, setValue2] = useState([''])

    //if the second input field should be shown
    let [shouldShowInput2, setShouldShowInput2] = useState(false);


    //options used in the type dropdown selector
    let typeOptions = useMemo(() => {
        return getFilterOptions('date').map((val) => {
            if (typeof val === 'string') {
                return { value: val, label: val }
            } else {
                return { value: val.displayKey, label: val.displayName }
            }
        });
    }, [getFilterOptions, filterType]);

    useEffect(() => {
        setShouldShowInput2(
            ['date_blank', 'date_notBlank'].includes(type1) ||
            ['date_inRange'].includes(type1) && value1[0].fromDate && value1[0].toDate ||
            ['date_equals', 'date_notEqual', 'date_lessThan', 'date_greaterThan', 'date_lessThanOrEqual', 'date_greaterThanOrEqual'].includes(type1) && value1[0] !== ''
        )
    }, [type1, value1])

    useEffect(() => {
        let filter = {};
        if (shouldShowInput2 && (['date_blank', 'date_notBlank'].includes(type2) || value2.filter(filterOutEmptyRows).length)) {
            filter = {
                condition1: {
                    filterType,
                    type: type1,
                    filter: JSON.stringify(value1.filter(filterOutEmptyRows)),
                },
                condition2: {
                    filterType,
                    type: type2,
                    filter: JSON.stringify(value2.filter(filterOutEmptyRows)),
                },
                filterType,
                operator: 'OR',
            }
        } else if (value1.filter(filterOutEmptyRows).length || ['date_blank', 'date_notBlank'].includes(type1)) {
            console.log("SETTING FILTER VAL1")
            filter = {
                filterType,
                type: type1,
                filter: JSON.stringify(value1.filter(filterOutEmptyRows)),
            }
        }
        changeParentFilter(filter)
    }, [type1, type2, value1, value2, shouldShowInput2])


    function changeParentFilter(filter) {
        setFilter((oldFilter) => {
            if (JSON.stringify(oldFilter[field]) === JSON.stringify(filter)) {
                return oldFilter
            }
            let newFilter = { ...oldFilter }
            newFilter[field] = { ...filter }
            console.log(newFilter)
            return newFilter;
        })
    }
    return <div>
        <Select
            value={typeOptions.find((v) => v.value === type1) ?? { value: type1, label: type1 }}
            onChange={(event) => {
                setType1((previousState) => {
                    if (previousState === 'date_inRange') {
                        setValue1([''])
                    } else if (event.value === 'date_inRange') {
                        setValue1([{ toDate: '', fromDate: '' }])
                    }
                    return event.value
                });
            }}
            options={typeOptions} />
        {!['date_blank', 'date_notBlank'].includes(type1) ? createInputs(value1, setValue1, type1) : ''}
        {shouldShowInput2 ? <Fragment>
            <Row>
                <hr></hr>
            </Row>
            <Select
                value={typeOptions.find((v) => v.value === type2) ?? { value: type2, label: type2 }}
                onChange={(event) => {
                    setType2((previousState) => {
                        if (previousState === 'date_inRange') {
                            setValue2([''])
                        } else if (event.value === 'date_inRange') {
                            setValue2([{ toDate: '', fromDate: '' }])
                        }
                        return event.value
                    });
                }}
                options={typeOptions} />
            {!['date_blank', 'date_notBlank'].includes(type2) ? createInputs(value2, setValue2, type2) : ''}

        </Fragment> : ""}
    </div>
    function createInputs(values, setValues, type) {
        // console.log("CREATING DATE INPUT", values, type)
        return values.map((value, index) => {
            if (index > 0 && ['date_greaterThan', 'date_lessThan', 'date_greaterThanOrEqual', 'date_lessThanOrEqual', 'date_inRange'].includes(type)) {
                return
            }
            return <div className="sidebar-value-input datepicker">
                {type === 'date_inRange' ?
                    <Fragment>
                        <ReactDatePicker
                            selected={value.fromDate ? new Date(value.fromDate) : ''}
                            placeholderText="Start Date"
                            autoComplete="off"
                            onChange={(val) => setValues((arr) => {
                                let arr2 = [...arr]
                                console.log(val)
                                arr2[index].fromDate = val?.toISOString()
                                console.log("R2", arr2)

                                if (arr2[arr2.length - 1].fromDate !== '' && arr2[arr2.length - 1].toDate !== '') {
                                    arr2.push({ fromDate: '', toDate: '' })
                                }

                                for (let [i, input] of Object.entries(arr2)) {
                                    if (input.fromDate === '' && input.toDate === '' && i != arr2.length - 1) {
                                        arr2.splice(i, 1)
                                    }
                                }
                                return arr2;
                            })}
                        ></ReactDatePicker>
                        <ReactDatePicker
                            selected={value.toDate ? new Date(value.toDate) : ''}
                            placeholderText="End Date"
                            autoComplete="off"
                            onChange={(val) => setValues((arr) => {
                                let arr2 = [...arr]
                                arr2[index].toDate = val?.toISOString()
                                console.log("R2", arr2)

                                if (arr2[arr2.length - 1].fromDate !== '' && arr2[arr2.length - 1].toDate !== '') {
                                    arr2.push({ fromDate: '', toDate: '' })
                                }

                                for (let [i, input] of Object.entries(arr2)) {
                                    if (input.fromDate === '' && input.toDate === '' && i != arr2.length - 1) {
                                        arr2.splice(i, 1)
                                    }
                                }
                                return arr2;
                            })}
                        ></ReactDatePicker>
                    </Fragment>
                    :
                    <ReactDatePicker
                        selected={value ? new Date(value) : ''}
                        placeholderText="Start Date"
                        autoComplete="off"
                        onChange={(val) => setValues((arr) => {
                            let arr2 = [...arr]
                            arr2[index] = val?.toISOString()
                            console.log("R2", arr2)

                            if (arr2[arr2.length - 1] !== '') {
                                arr2.push('')
                            }

                            for (let [i, input] of Object.entries(arr2)) {
                                if (input === '' && i != arr2.length - 1) {
                                    arr2.splice(i, 1)
                                }
                            }
                            return arr2;
                        })}
                    ></ReactDatePicker>

                }
                {index < values.length - 1 ?
                    <div className='x-icon-container' onClick={() => {
                        setValues((arr) => {
                            let arr2 = [...arr];
                            arr2.splice(index, 1);
                            return arr2;
                        })
                    }}>
                        <i className='fa fa-x'></i>
                    </div>
                    : ''
                }
            </div>
        })
    }
}
//removes any values that should not be included in filter
function filterOutEmptyRows(val) {
    if (typeof val === 'object') {
        return !(val.toDate === '' || val.fromDate === '')
    }
    return val !== ''
}
