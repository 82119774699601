//Get
const KEYTAG_DATA = {
    KEYTAG_DATA_LOAD: "KEYTAG_DATA_LOAD",
    KEYTAG_DATA_SUCCESS: "KEYTAG_DATA_SUCCESS",
    KEYTAG_DATA_FAIL: "KEYTAG_DATA_FAIL",
};
const KEYTAG_GET_API = "/admin/keytag";

// Get one by id
const KEYTAG_GET_DATA = {
    KEYTAG_GET_DATA_LOAD: "KEYTAG_GET_DATA_LOAD",
    KEYTAG_GET_DATA_SUCCESS: "KEYTAG_GET_DATA_SUCCESS",
    KEYTAG_GET_DATA_FAIL: "KEYTAG_GET_DATA_FAIL",
};

const KEYTAG_GET_DETAIL_API = "/admin/keytag/";

// Create / Add / Post
const KEYTAG_ADD_DATA = {
    KEYTAG_ADD_DATA_LOAD: "KEYTAG_ADD_DATA_LOAD",
    KEYTAG_ADD_DATA_SUCCESS: "KEYTAG_ADD_DATA_SUCCESS",
    KEYTAG_ADD_DATA_FAIL: "KEYTAG_ADD_DATA_FAIL",
};

const KEYTAG_POST_API = "/admin/keytag";

//Update
const KEYTAG_UPDATE_DATA = {
  KEYTAG_UPDATE_DATA_LOAD: "KEYTAG_UPDATE_DATA_UPLOAD",
  KEYTAG_UPDATE_DATA_SUCCESS: "KEYTAG_UPDATE_DATA_SUCCESS",
  KEYTAG_UPDATE_DATA_FAIL: "KEYTAG_UPDATE_DATA_FAIL",
};

const KEYTAG_PUT_API = "/admin/keytag";

// Delete
const KEYTAG_DELETE_DATA = {
  KEYTAG_DELETE_DATA_LOAD: "KEYTAG_DELETE_DATA_LOAD",
  KEYTAG_DELETE_DATA_SUCCESS: "KEYTAG_DELETE_DATA_SUCCESS",
  KEYTAG_DELETE_DATA_FAIL: "KEYTAG_DELETE_DATA_FAIL",
};

const KEYTAG_DELETE_API = "/admin/keytag";

//Get keytag based on module
const KEYTAGMODULE_GET_DATA = {
  KEYTAGMODULE_GET_DATA_LOAD: "KEYTAGMODULE_GET_DATA_LOAD",
  KEYTAGMODULE_GET_SUCCESS: "KEYTAGMODULE_GET_SUCCESS",
  KEYTAGMODULE_GET_FAIL: "KEYTAGMODULE_GET_FAIL",
};

const KEYTAGMODULE_GET_API = "/admin/keytag/module/";


//Key Values APIs
const KEYVALUE_GET = {
  KEYVALUE_GET_DATA_LOAD: "KEYVALUE_GET_DATA_LOAD",
  KEYVALUE_GET_DATA_SUCCESS: "KEYVALUE_GET_DATA_SUCCESS",
  KEYVALUE_GET_DATA_FAIL: "KEYVALUE_GET_DATA_FAIL"
}

const KEYVALUE_GET_API = "/common/keyvalue"

//Get Particular keyvalu based on resource value
const VALUE_RESOURCE_GET = {
  VALUE_RESOURCE_GET_DATA_LOAD: "VALUE_RESOURCE_GET_DATA_LOAD",
  VALUE_RESOURCE_GET_DATA_SUCCESS: "VALUE_RESOURCE_GET_DATA_SUCCESS",
  VALUE_RESOURCE_GET_DATA_FAIL: "VALUE_RESOURCE_GET_DATA_FAIL"
}

const VALUE_RESOURCE_GET_API = "/common/keyvalue/resource/"

//Post Key Value
const KEYVALUE_ADD = {
  KEYVALUE_ADD_DATA_LOAD: "KEYVALUE_ADD_DATA_LOAD",
  KEYVALUE_ADD_DATA_SUCCESS: "KEYVALUE_ADD_DATA_SUCCESS",
  KEYVALUE_ADD_DATA_FAIL: "KEYVALUE_ADD_DATA_FAIL"
}

const KEYVALUE_ADD_API = "/common/keyvalue"

//Update Key Value
const KEYVALUE_UPDATE = {
  KEYVALUE_UPDATE_DATA_LOAD: "KEYVALUE_UPDATE_DATA_LOAD",
  KEYVALUE_UPDATE_DATA_SUCCESS: "KEYVALUE_UPDATE_DATA_SUCCESS",
  KEYVALUE_UPDATE_DATA_FAIL: "KEYVALUE_UPDATE_DATA_FAIL"
}

const KEYVALUE_UPDATE_API = "/common/keyvalue"

//Delete Key Value
const KEYVALUE_DELETE = {
  KEYVALUE_DELETE_DATA_LOAD: "KEYVALUE_DELETE_DATA_LOAD",
  KEYVALUE_DELETE_DATA_SUCCESS: "KEYVALUE_DELETE_DATA_SUCCESS",
  KEYVALUE_DELETE_DATA_FAIL: "KEYVALUE_DELETE_DATA_FAIL",
};

const KEYVALUE_DELETE_API = "/common/keyvalue";

//Import Key Tag Value
const KEYVALUE_IMPORT_DATA = {
  KEYVALUE_IMPORT_DATA_LOAD:'KEYVALUE_IMPORT_DATA_LOAD',
  KEYVALUE_IMPORT_DATA_SUCCESS:'KEYVALUE_IMPORT_DATA_SUCCESS', 
  KEYVALUE_IMPORT_DATA_FAIL:'KEYVALUE_IMPORT_DATA_FAIL'
}

const KEYVALUE_IMPORT_API = '/upload'


export{
    KEYTAG_DATA,
    KEYTAG_GET_API,
    KEYTAG_GET_DATA,
    KEYTAG_GET_DETAIL_API,
    KEYTAG_ADD_DATA,
    KEYTAG_POST_API,
    KEYTAG_UPDATE_DATA,
    KEYTAG_PUT_API,
    KEYTAG_DELETE_DATA,
    KEYTAG_DELETE_API,
    KEYTAGMODULE_GET_DATA,
    KEYTAGMODULE_GET_API,
    KEYVALUE_GET,
    KEYVALUE_GET_API,
    VALUE_RESOURCE_GET,
    VALUE_RESOURCE_GET_API,
    KEYVALUE_ADD,
    KEYVALUE_ADD_API,
    KEYVALUE_UPDATE,
    KEYVALUE_UPDATE_API,
    KEYVALUE_DELETE,
    KEYVALUE_DELETE_API,
    KEYVALUE_IMPORT_DATA,
    KEYVALUE_IMPORT_API
}