import { EMPLOYEE_TYPE_DATA, EMPLOYEE_TYPE_GET_DATA, EMPLOYEE_TYPE_ADD_DATA, EMPLOYEE_TYPE_UPDATE_DATA, EMPLOYEE_TYPE_DELETE_DATA, } from './Constants'
const initialState = {
    loading: false,
    error: null,
    employeeTypes: [],
    employeeType:{}
}
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case EMPLOYEE_TYPE_DATA.EMPLOYEE_TYPE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                employeeTypes:[],
                error:null
            }
        case EMPLOYEE_TYPE_DATA.EMPLOYEE_TYPE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                employeeTypes:action.employeeTypes
            }
        case EMPLOYEE_TYPE_DATA.EMPLOYEE_TYPE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        //load single
        case EMPLOYEE_TYPE_GET_DATA.EMPLOYEE_TYPE_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                employeeType:{},
                error:null
            }                

        case EMPLOYEE_TYPE_GET_DATA.EMPLOYEE_TYPE_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                employeeType:action.employeeType
            }                

        case EMPLOYEE_TYPE_GET_DATA.EMPLOYEE_TYPE_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case EMPLOYEE_TYPE_ADD_DATA.EMPLOYEE_TYPE_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case EMPLOYEE_TYPE_ADD_DATA.EMPLOYEE_TYPE_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case EMPLOYEE_TYPE_ADD_DATA.EMPLOYEE_TYPE_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case EMPLOYEE_TYPE_UPDATE_DATA.EMPLOYEE_TYPE_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case EMPLOYEE_TYPE_UPDATE_DATA.EMPLOYEE_TYPE_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case EMPLOYEE_TYPE_UPDATE_DATA.EMPLOYEE_TYPE_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case EMPLOYEE_TYPE_DELETE_DATA.EMPLOYEE_TYPE_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case EMPLOYEE_TYPE_DELETE_DATA.EMPLOYEE_TYPE_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case EMPLOYEE_TYPE_DELETE_DATA.EMPLOYEE_TYPE_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        
        default:
            return {
                ...state
            }
    }
}
export default Reducer