import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { EMPLOYEE_TYPE, HOURS_PER_WEEK } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CREATENEW } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadEmployeeTypeDataRequest, loadEmployeeTypeDeleteDataRequest } from "../redux/Actions";
import EmployeeTypeAdd from "./EmployeeTypeAdd";
import EmployeeTypeEdit from "./EmployeeTypeEdit";
import DeleteModal from "../../../shared/components/DeleteModal";
import Permissions from "../../../shared/components/Permissions";

const EmployeeType = () => {
    let dispatch = useDispatch();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let { employeeTypes, loading } = useSelector((state) => state.employeeType);
    let [selectedRow, setSelectedRow] = useState({})

    let [shouldReload, setShouldReload] = useState(true)

    useEffect(() => {
        if (shouldReload && !loading){
            setShouldReload(false);
            dispatch(loadEmployeeTypeDataRequest());
        }
    }, [dispatch,loading,shouldReload]);

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Employee Type";
    let permission = Permissions(emp_no, module_name); 
    let actionsPermission = !(permission.write_permission || permission.delete_permission);

    let columns = [
        {
            display_name: EMPLOYEE_TYPE,
            field: "name",
            type: "text",
        },
        {
            display_name: HOURS_PER_WEEK,
            field: "hours_per_week",
            type: "text",
        },
        {
            display_name: "",
            field: 'actions',
            type: 'actions',
            hide: actionsPermission
        }
    ];

    const handleDelete = () => {
        dispatch(loadEmployeeTypeDeleteDataRequest({ id: [selectedRow.id] }))
        setShouldReload(true)
        setShowDeleteModal(false);
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(()=>{
        if (permission.write_permission){
            setClickEditFunc(
                () => (row)=>{
                setSelectedRow(row);
                setShowEditModal(true);
            })
        }
        if (permission.delete_permission){
            setClickDeleteFunc(
                () => (id)=>{
                setSelectedRow({ id });
                setShowDeleteModal(true);
            })
        }
    },[permission])
    

    return (
        <>
            <Row>
                <Col className="gx-0">
                <Breadcrumbs
                    children={[
                        { href: '/employee-type', label: EMPLOYEE_TYPE },
                    ]}
                />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission?(
                        <DefaultButton
                            buttonClass="btn btn-primary default-btn-color"
                            buttonTitle={CREATENEW}
                            actionBlock={() => setShowCreateModal(true)}
                        />
                    ):""}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={EMPLOYEE_TYPE}
                            columnNames={columns}
                            rowData={employeeTypes}
                            clickEdit={clickEditFunc}
                            clickDelete={clickDeleteFunc}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={showCreateModal}
                handleClose={() => setShowCreateModal(false)}
                actionTitle={"Create " + EMPLOYEE_TYPE}
            >
                <EmployeeTypeAdd
                    handleClose={(reload) => {
                        setShowCreateModal(false);
                        if (reload) {
                            dispatch(loadEmployeeTypeDataRequest());
                        }
                    }}
                />
            </Modalpopup>
            <Modalpopup
                show={showEditModal}
                handleClose={() => { setShowEditModal(false) }}
                actionTitle={"Edit " + EMPLOYEE_TYPE}
            >
                <EmployeeTypeEdit
                    employeeType={selectedRow}
                    handleClose={(reload) => {
                        if (reload) {
                            dispatch(loadEmployeeTypeDataRequest());
                        }
                        setShowEditModal(false)
                    }}
                />
            </Modalpopup>
            <DeleteModal
                handleClose={() => { setShowDeleteModal(false) }}
                handleDelete={handleDelete}
                show={showDeleteModal}
            >
                <div>
                    Delete {EMPLOYEE_TYPE}?
                </div>
            </DeleteModal>
        </>
    );
};

export default EmployeeType;
