import axios from "axios";

const instance = axios.create({
    responseType: "json",
    headers: {
        "x-api-key":'1wB4EOhzZL5bDXto8vJy5a7bOVgkItiS2kS37nR8'
    }
});


export { instance };
