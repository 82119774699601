import React, { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import Accessory from "./Accessory";
import Product from "./Product";

const Products = () => {

  let navigate = useNavigate();
  let location = useLocation();  
  let [currentTracker, setCurrentTracker] = useState(location.search === "?products" ? 2 : 1);
 

  let trackers = [
    <Accessory />,
    <Product />
  ];

  return (
    <>
      <div>
        <Row>
          <Col className='gx-0'>
            <Breadcrumbs
              children={[
                { label: "Products" },
              ]}
            />
          </Col>
          <Col xs="auto" className="gx-0">
          </Col>
        </Row>
        <Row>
            <Col>
              <button className={currentTracker === 1 ? "top-tab selected" : "top-tab"} onClick={() => {navigate('/products');setCurrentTracker(1)}}>Accessory</button>
              <button className={currentTracker === 2 ? "top-tab selected" : "top-tab"} onClick={() => {navigate('/products');setCurrentTracker(2)}}>Product</button>
            </Col>
            <Card>
                <Card.Body >
                    {trackers[currentTracker - 1]}
                </Card.Body>
            </Card>
        </Row>
      </div>
    </>
  );
};

export default Products;
