import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadKeyTagModuleGetDataRequest } from '../../../containers/tagging/redux/Actions';

const KeyTagModuleList = (moduleId) => {
    const dispatch = useDispatch();
    // let [moduleList, setModuleList] = useState([]);
    let { keyModules } = useSelector((state) => state.keyTag);
    useEffect(() => {
        if (moduleId) {
            dispatch(loadKeyTagModuleGetDataRequest(moduleId));
        }
    },[dispatch, moduleId]);

    return keyModules;
}

export default KeyTagModuleList
