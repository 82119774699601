import {
    HOURLY_SALARY_DATA,
    HOURLY_SALARY_GET_DATA,
    HOURLY_SALARY_ADD_DATA,
    HOURLY_SALARY_UPDATE_DATA,
    HOURLY_SALARY_DELETE_DATA,
} from './Constants'


//For listing
const loadHourlySalaryDataRequest = () => ({
    type: HOURLY_SALARY_DATA.HOURLY_SALARY_DATA_LOAD,
});
const setHourlySalaryDataSuccess = (hourlySalaries) => ({
    type: HOURLY_SALARY_DATA.HOURLY_SALARY_DATA_SUCCESS,
    hourlySalaries,
});
const setHourlySalaryDataFail = (error) => ({
    type: HOURLY_SALARY_DATA.HOURLY_SALARY_DATA_FAIL,
    error,
});

//for details
const loadHourlySalaryGetDataRequest = (id) => ({
    type: HOURLY_SALARY_GET_DATA.HOURLY_SALARY_GET_DATA_LOAD,
    id,
});
const setHourlySalaryGetDataSuccess = (response) => ({
    type: HOURLY_SALARY_GET_DATA.HOURLY_SALARY_GET_DATA_SUCCESS,
    hourlySalary: response.hourlySalary[0]
});
const setHourlySalaryGetDataFail = (error) => ({
    type: HOURLY_SALARY_GET_DATA.HOURLY_SALARY_GET_DATA_FAIL,
    error,
});

//For add HourlySalary
const loadHourlySalaryAddDataRequest = (request) => ({
    type: HOURLY_SALARY_ADD_DATA.HOURLY_SALARY_ADD_DATA_LOAD,
    request,
});
const setHourlySalaryAddDataSuccess = (hourlySalary) => ({
    type: HOURLY_SALARY_ADD_DATA.HOURLY_SALARY_ADD_DATA_SUCCESS,
    hourlySalary,
});
const setHourlySalaryAddDataFail = (error) => ({
    type: HOURLY_SALARY_ADD_DATA.HOURLY_SALARY_ADD_DATA_FAIL,
    error,
});

//update HourlySalary
const loadHourlySalaryUpdateDataRequest = (request) => ({
    type: HOURLY_SALARY_UPDATE_DATA.HOURLY_SALARY_UPDATE_DATA_LOAD,
    request,
});
const setHourlySalaryUpdateDataSuccess = (hourlySalary) => ({
    type: HOURLY_SALARY_UPDATE_DATA.HOURLY_SALARY_UPDATE_DATA_SUCCESS,
    hourlySalary,
});
const setHourlySalaryUpdateDataFail = (error) => ({
    type: HOURLY_SALARY_UPDATE_DATA.HOURLY_SALARY_UPDATE_DATA_FAIL,
    error,
});

//Delete HourlySalary
const loadHourlySalaryDeleteDataRequest = (request) => ({
    type: HOURLY_SALARY_DELETE_DATA.HOURLY_SALARY_DELETE_DATA_LOAD,
    request,
});
const setHourlySalaryDeleteDataSuccess = (hourlySalary) => ({
    type: HOURLY_SALARY_DELETE_DATA.HOURLY_SALARY_DELETE_DATA_SUCCESS,
    hourlySalary,
});
const setHourlySalaryDeleteDataFail = (error) => ({
    type: HOURLY_SALARY_DELETE_DATA.HOURLY_SALARY_DELETE_DATA_FAIL,
    error,
});


export {
    loadHourlySalaryDataRequest, setHourlySalaryDataSuccess, setHourlySalaryDataFail,
    loadHourlySalaryGetDataRequest, setHourlySalaryGetDataSuccess, setHourlySalaryGetDataFail,
    loadHourlySalaryAddDataRequest, setHourlySalaryAddDataSuccess, setHourlySalaryAddDataFail,
    loadHourlySalaryUpdateDataRequest, setHourlySalaryUpdateDataSuccess, setHourlySalaryUpdateDataFail,
    loadHourlySalaryDeleteDataRequest, setHourlySalaryDeleteDataSuccess, setHourlySalaryDeleteDataFail,
}