// Get All Form
const FORM_ALL_DATA = {
  FORM_ALL_DATA_LOAD:'FORM_ALL_DATA_LOAD',
  FORM_ALL_DATA_SUCCESS:'FORM_ALL_DATA_SUCCESS',
  FORM_ALL_DATA_FAIL:'FORM_ALL_DATA_FAIL'
}
const FORM_ALL_GET_API = '/form/allform'
// Get
const FORM_DATA = {
    FORM_DATA_LOAD:'FORM_DATA_LOAD',
    FORM_DATA_SUCCESS:'FORM_DATA_SUCCESS',
    FORM_DATA_FAIL:'FORM_DATA_FAIL'
}

const FORM_GET_API = '/form'

// Import Bulk data by form ID 
const FORM_IMPORT_DATA = {
  FORM_IMPORT_DATA_LOAD:'FORM_IMPORT_DATA_LOAD',
  FORM_IMPORT_DATA_SUCCESS:'FORM_IMPORT_DATA_SUCCESS', 
  FORM_IMPORT_DATA_FAIL:'FORM_IMPORT_DATA_FAIL'
}

const FORM_IMPORT_API = '/upload'

// Get one by id
const FORM_GET_DATA = {
    FORM_GET_DATA_LOAD:'FORM_GET_DATA_LOAD',
    FORM_GET_DATA_SUCCESS:'FORM_GET_DATA_SUCCESS', 
    FORM_GET_DATA_FAIL:'FORM_GET_DATA_FAIL'
}

const FORM_GET_DETAIL_API = '/form/formSavedData/'

// Get one by id
const FORM_GET_DATA_BY_SUB_ID = {
    FORM_GET_DATA_BY_SUB_ID_LOAD:'FORM_GET_DATA_BY_SUB_ID_LOAD',
    FORM_GET_DATA_BY_SUB_ID_SUCCESS:'FORM_GET_DATA_BY_SUB_ID_SUCCESS', 
    FORM_GET_DATA_BY_SUB_ID_FAIL:'FORM_GET_DATA_BY_SUB_ID_FAIL'
}

const FORM_GET_BY_SUB_ID_DETAIL_API = '/form/formSavedDataBySubId/'

//Add form
const FORM_ADD_DATA = {
    FORM_ADD_DATA_LOAD: 'FORM_ADD_DATA_LOAD',
    FORM_ADD_DATA_SUCCESS: 'FORM_ADD_DATA_SUCCESS',
    FORM_ADD_DATA_FAIL: 'FORM_ADD_DATA_FAIL'
  }  
  const FORM_ADD_API = '/form/addNewFormData/'


//Edit form
const FORM_EDIT_DATA = {
    FORM_EDIT_DATA_LOAD: 'FORM_EDIT_DATA_LOAD',
    FORM_EDIT_DATA_SUCCESS: 'FORM_EDIT_DATA_SUCCESS',
    FORM_EDIT_DATA_FAIL: 'FORM_EDIT_DATA_FAIL'
  }  
  const FORM_EDIT_API = '/form/editFormData/'


//Delete form
const FORM_DELETE_DATA = {
    FORM_DELETE_DATA_LOAD: 'FORM_DELETE_DATA_LOAD',
    FORM_DELETE_DATA_SUCCESS: 'FORM_DELETE_DATA_SUCCESS',
    FORM_DELETE_DATA_FAIL: 'FORM_DELETE_DATA_FAIL'
  }  
const FORM_DELETE_API = '/form/deleteFormData/'

export {
    FORM_ALL_DATA,
    FORM_ALL_GET_API, 
    FORM_DATA,
    FORM_GET_API,   
    FORM_GET_DATA,
    FORM_GET_DETAIL_API ,
    FORM_GET_DATA_BY_SUB_ID,
    FORM_GET_BY_SUB_ID_DETAIL_API,
    FORM_ADD_DATA,
    FORM_ADD_API,
    FORM_EDIT_DATA,
    FORM_EDIT_API,
    FORM_DELETE_DATA,
    FORM_DELETE_API,
    FORM_IMPORT_DATA,
    FORM_IMPORT_API
}

