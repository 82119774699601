import { takeLatest, put, call } from "redux-saga/effects";
import {
  STORE_DATA,
  STORE_GET_API,
  STORE_ADD_DATA,
  STORE_POST_API,
  STORE_GET_DATA,
  STORE_GET_DETAIL_API,
  STORE_UPDATE_DATA,
  STORE_PUT_API,
  STORE_DELETE_DATA,
  STORE_DELETE_API,
  STORE_IMPORT_DATA,
  STORE_IMPORT_API,

  STORE_SAP_TRACKER_ADD,
  STORE_SAP_TRACKER_ADD_API,
  STORE_SAP_TRACKER_DELETE,
  STORE_SAP_TRACKER_DELETE_API,
  STORE_SAP_TRACKER_UPDATE,
  STORE_SAP_TRACKER_UPDATE_API,

  STORE_DENSITY_TRACKER_ADD,
  STORE_DENSITY_TRACKER_ADD_API,
  STORE_DENSITY_TRACKER_UPDATE,
  STORE_DENSITY_TRACKER_UPDATE_API,
  STORE_DENSITY_TRACKER_DELETE,
  STORE_DENSITY_TRACKER_DELETE_API,

  STORE_COMM_TRACKER_ADD,
  STORE_COMM_TRACKER_ADD_API,
  STORE_COMM_TRACKER_UPDATE,
  STORE_COMM_TRACKER_UPDATE_API,
  STORE_COMM_TRACKER_DELETE,
  STORE_COMM_TRACKER_DELETE_API,

  STORE_CLASSIFICATION_TRACKER_ADD,
  STORE_CLASSIFICATION_TRACKER_ADD_API,
  STORE_CLASSIFICATION_TRACKER_UPDATE,
  STORE_CLASSIFICATION_TRACKER_UPDATE_API,
  STORE_CLASSIFICATION_TRACKER_DELETE,
  STORE_CLASSIFICATION_TRACKER_DELETE_API,

  STORE_TRACKER_GET_ALL_DATA,
  STORE_TRACKER_GET_ALL_API,
  STORE_TRACKER_IMPORT_DATA,
  STORE_TRACKER_IMPORT_API,

  DENSITYCLASS_TRACKER_GET_ALL_DATA,
  DENSITYCLASS_TRACKER_GET_ALL_API,

  COMMCOMP_TRACKER_GET_ALL_DATA,
  COMMCOMP_TRACKER_GET_ALL_API,

  SCLASSIFICATION_TRACKER_GET_ALL_DATA,
  SCLASSIFICATION_TRACKER_GET_ALL_API,

} from "./Constants";
import {
  setStoreDataSuccess,
  setStoreDataFail,
  setStoreGetDataSuccess,
  setStoreGetDataFail,
  setStoreAddDataSuccess,
  setStoreAddDataFail,
  setStoreUpdateDataSuccess,
  setStoreUpdateDataFail,
  setStoreDeleteDataSuccess,
  setStoreDeleteDataFail,
  setStoreImportDataSuccess,
  setStoreImportDataFail,

  setSapTrackerAddDataSuccess,
  setSapTrackerAddDataFail,
  setSapTrackerDeleteDataSuccess,
  setSapTrackerUpdateDataSuccess,
  setSapTrackerUpdateDataFail,
  setSapTrackerDeleteDataFail,

  setDensityTrackerAddDataSuccess,
  setDensityTrackerAddDataFail,
  setDensityTrackerUpdateDataSuccess,
  setDensityTrackerUpdateDataFail,
  setDensityTrackerDeleteDataSuccess,
  setDensityTrackerDeleteDataFail,

  setCommTrackerAddDataSuccess,
  setCommTrackerAddDataFail,
  setCommTrackerDeleteDataSuccess,
  setCommTrackerDeleteDataFail,
  setCommTrackerUpdateDataSuccess,
  setCommTrackerUpdateDataFail,

  setClassificationTrackerAddDataSuccess,
  setClassificationTrackerAddDataFail,
  setClassificationTrackerUpdateDataSuccess,
  setClassificationTrackerUpdateDataFail,
  setClassificationTrackerDeleteDataSuccess,
  setClassificationTrackerDeleteDataFail,

  setStoreTrackerImportDataSuccess,
  setStoreTrackerImportDataFail,
  setGetStoreTrackerDataSuccess,
  setGetStoreTrackerDataFail,

  setGetDensityClassTrackerDataSuccess,
  setGetDensityClassTrackerDataFail,
  setGetCommCompTrackerDataSuccess,
  setGetCommCompTrackerDataFail,
  setGetSClassificationTrackerDataSuccess,
  setGetSClassificationTrackerDataFail,
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
  postImportRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";


// Base Stores
function* getStoresDataService() {
  try {
    const storesResponse = yield call(getRequest, STORE_GET_API);
    const status = storesResponse.status;
    if (status === 200) {
      const data = storesResponse.data;
      yield put(setStoreDataSuccess(data));
    } else {
      yield put(setStoreDataFail(storesResponse.response.data));
    }
  } catch (error) {
    yield put(setStoreDataFail(error));
  }
}

function* getStoreDataService({ id }) {
  try {
    const getStoreDataResponse = yield call(
      getDetailRequest,
      STORE_GET_DETAIL_API + id
    );
    const status = getStoreDataResponse.status;
    if (status === 200) {
      const newData = getStoreDataResponse.data;
      yield put(setStoreGetDataSuccess(newData));
    } else {
      yield put(setStoreGetDataFail(getStoreDataResponse.response.data));
    }
  } catch (error) {
    yield put(setStoreGetDataFail(error));
  }
}

function* postStoreDataService({ request }) {
  try {
    let dataRequest = { "to_insert": request }
    const response = yield call(postRequest, STORE_POST_API, dataRequest);
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Store created"))
      yield put(setStoreAddDataSuccess(newData));
    } else {
      //yield put(addErrorAlert(response.response.data.msg))
      yield put(setStoreAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not create store"))
    yield put(setStoreAddDataFail(error));
  }
}
function* putStoreDataService({ request }) {
  try {
    let dataRequest = { "to_id": { "id": request.id }, "to_update": request }
    const response = yield call(putRequest, STORE_PUT_API, dataRequest);
    if (response.status === 200) {
      yield put(addSuccessAlert("Store updated"))
      yield put(setStoreUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setStoreUpdateDataFail(response.response.data));
    }
  } catch (error) {
    //yield put(addErrorAlert("Could not update store"))
    yield put(setStoreUpdateDataFail(error));
  }
}

function* deleteStoreDataService({ request }) {
  try {
    const response = yield call(deleteRequest, STORE_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setStoreDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setStoreDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setStoreDeleteDataFail(error));
  }
}

function* postImportStoreDataService({ request }) {
  try {
    const importStoreDataResponse = yield call(
      postImportRequest,
      STORE_IMPORT_API,
      request
    );
    const status = importStoreDataResponse.status;
    if (status === 200) {
      const newData = importStoreDataResponse.data;
      yield put(addSuccessAlert("Stores imported"))
      yield put(setStoreImportDataSuccess(newData));
    } else {
      yield put(addErrorAlert(importStoreDataResponse.response.data.msg))
      yield put(setStoreImportDataFail(importStoreDataResponse.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not import stores"))
    yield put(setStoreImportDataFail(error));
  }
}

//SAP Tracker 
function* addSapTrackerDataService({ request }) {
  try {
    const response = yield call(postRequest, STORE_SAP_TRACKER_ADD_API, request);
    if (response.status === 200) {
      //Response comes back with an object instead of a message.
      yield put(addSuccessAlert("Tracker Added"))
      yield put(setSapTrackerAddDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setSapTrackerAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert(error))
    yield put(setSapTrackerAddDataFail(error));
  }
}

function* updateSapTrackerDataService({ request }) {
  try {
    const response = yield call(putRequest, STORE_SAP_TRACKER_UPDATE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setSapTrackerUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setSapTrackerUpdateDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert(error))
    yield put(setSapTrackerUpdateDataFail(error));
  }
}
function* deleteSapTrackerDataService({ request }) {
  try {
    const response = yield call(deleteRequest, STORE_SAP_TRACKER_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setSapTrackerDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setSapTrackerDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setSapTrackerDeleteDataFail(error));
  }
}
//Density Tracker 
function* addDensityTrackerDataService({ request }) {
  try {
    const response = yield call(postRequest, STORE_DENSITY_TRACKER_ADD_API, request);
    if (response.status === 200) {
      //Response comes back with an object instead of a message.
      yield put(addSuccessAlert("Tracker Added"))
      yield put(setDensityTrackerAddDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setDensityTrackerAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert(error))
    yield put(setDensityTrackerAddDataFail(error));
  }
}

function* updateDensityTrackerDataService({ request }) {
  try {
    const response = yield call(putRequest, STORE_DENSITY_TRACKER_UPDATE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setDensityTrackerUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setDensityTrackerUpdateDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert(error))
    yield put(setDensityTrackerUpdateDataFail(error));
  }
}
function* deleteDensityTrackerDataService({ request }) {
  try {
    const response = yield call(deleteRequest, STORE_DENSITY_TRACKER_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setDensityTrackerDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setDensityTrackerDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setDensityTrackerDeleteDataFail(error));
  }
}
// Comm Tracker services
function* addCommTrackerDataService({ request }) {
  try {
    const response = yield call(postRequest, STORE_COMM_TRACKER_ADD_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setCommTrackerAddDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setCommTrackerAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert(error))
    yield put(setCommTrackerAddDataFail(error));
  }
}

function* updateCommTrackerDataService({ request }) {
  try {
    const response = yield call(putRequest, STORE_COMM_TRACKER_UPDATE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setCommTrackerUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setCommTrackerUpdateDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert(error))
    yield put(setCommTrackerUpdateDataFail(error));
  }
}
function* deleteCommTrackerDataService({ request }) {
  try {
    const response = yield call(deleteRequest, STORE_COMM_TRACKER_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Tracker deleted"))
      yield put(setCommTrackerDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setCommTrackerDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setCommTrackerDeleteDataFail(error));
  }
}

// Classification services
function* addClassificationTrackerDataService({ request }) {
  try {
    const response = yield call(postRequest, STORE_CLASSIFICATION_TRACKER_ADD_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setClassificationTrackerAddDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setClassificationTrackerAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert(error))
    yield put(setClassificationTrackerAddDataFail(error));
  }
}

function* updateClassificationTrackerDataService({ request }) {
  try {
    const response = yield call(putRequest, STORE_CLASSIFICATION_TRACKER_UPDATE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setClassificationTrackerUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setClassificationTrackerUpdateDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert(error))
    yield put(setClassificationTrackerUpdateDataFail(error));
  }
}
function* deleteClassificationTrackerDataService({ request }) {
  try {
    const response = yield call(deleteRequest, STORE_CLASSIFICATION_TRACKER_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Tracker Deleted"));
      yield put(setClassificationTrackerDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setClassificationTrackerDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setClassificationTrackerDeleteDataFail(error));
  }
}

function* getStoreTrackerDataService() {
  try {
    const trackerResponse = yield call(getRequest, STORE_TRACKER_GET_ALL_API);
    const status = trackerResponse.status;
    if (status === 200) {
      const data = trackerResponse.data;
      yield put(setGetStoreTrackerDataSuccess(data));
    } else {
      yield put(setGetStoreTrackerDataFail(trackerResponse.response.data));
    }
  } catch (error) {
    yield put(setGetStoreTrackerDataFail(error));
  }
}

function* postImportStoreTrackerDataService({ request }) {
  try {
    const response = yield call(
      postImportRequest,
      STORE_TRACKER_IMPORT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Store trackers imported"))
      yield put(setStoreTrackerImportDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setStoreTrackerImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not import store trackers"))
    yield put(setStoreTrackerImportDataFail(error));
  }
}

function* getDensityClassTrackerDataService() {
  try {
    const trackerResponse = yield call(getRequest, DENSITYCLASS_TRACKER_GET_ALL_API);
    const status = trackerResponse.status;
    if (status === 200) {
      const data = trackerResponse.data;
      yield put(setGetDensityClassTrackerDataSuccess(data));
    } else {
      yield put(setGetDensityClassTrackerDataFail(trackerResponse.response.data));
    }
  } catch (error) {
    yield put(setGetDensityClassTrackerDataFail(error));
  }
}

function* getCommCompTrackerDataService() {
  try {
    const trackerResponse = yield call(getRequest, COMMCOMP_TRACKER_GET_ALL_API);
    const status = trackerResponse.status;
    if (status === 200) {
      const data = trackerResponse.data;
      yield put(setGetCommCompTrackerDataSuccess(data));
    } else {
      yield put(setGetCommCompTrackerDataFail(trackerResponse.response.data));
    }
  } catch (error) {
    yield put(setGetCommCompTrackerDataFail(error));
  }
}

function* getSClassificationTrackerDataService() {
  try {
    const trackerResponse = yield call(getRequest, SCLASSIFICATION_TRACKER_GET_ALL_API);
    const status = trackerResponse.status;
    if (status === 200) {
      const data = trackerResponse.data;
      yield put(setGetSClassificationTrackerDataSuccess(data));
    } else {
      yield put(setGetSClassificationTrackerDataFail(trackerResponse.response.data));
    }
  } catch (error) {
    yield put(setGetSClassificationTrackerDataFail(error));
  }
}

export function* watchStore() {
  yield takeLatest(STORE_DATA.STORE_DATA_LOAD, getStoresDataService);
  yield takeLatest(STORE_GET_DATA.STORE_GET_DATA_LOAD, getStoreDataService);
  yield takeLatest(STORE_ADD_DATA.STORE_ADD_DATA_LOAD, postStoreDataService);
  yield takeLatest(STORE_UPDATE_DATA.STORE_UPDATE_DATA_LOAD, putStoreDataService);
  yield takeLatest(STORE_DELETE_DATA.STORE_DELETE_DATA_LOAD, deleteStoreDataService);
  yield takeLatest(STORE_IMPORT_DATA.STORE_IMPORT_DATA_LOAD, postImportStoreDataService);
  yield takeLatest(
    STORE_IMPORT_DATA.STORE_IMPORT_DATA_SUCCESS,
    getStoresDataService
  );

  yield takeLatest(STORE_SAP_TRACKER_ADD.STORE_SAP_TRACKER_ADD_LOAD, addSapTrackerDataService);
  yield takeLatest(STORE_SAP_TRACKER_UPDATE.STORE_SAP_TRACKER_UPDATE_LOAD, updateSapTrackerDataService);
  yield takeLatest(STORE_SAP_TRACKER_DELETE.STORE_SAP_TRACKER_DELETE_LOAD, deleteSapTrackerDataService);

  yield takeLatest(STORE_DENSITY_TRACKER_ADD.STORE_DENSITY_TRACKER_ADD_LOAD, addDensityTrackerDataService);
  yield takeLatest(STORE_DENSITY_TRACKER_UPDATE.STORE_DENSITY_TRACKER_UPDATE_LOAD, updateDensityTrackerDataService);
  yield takeLatest(STORE_DENSITY_TRACKER_DELETE.STORE_DENSITY_TRACKER_DELETE_LOAD, deleteDensityTrackerDataService);

  yield takeLatest(STORE_COMM_TRACKER_ADD.STORE_COMM_TRACKER_ADD_LOAD, addCommTrackerDataService);
  yield takeLatest(STORE_COMM_TRACKER_UPDATE.STORE_COMM_TRACKER_UPDATE_LOAD, updateCommTrackerDataService);
  yield takeLatest(STORE_COMM_TRACKER_DELETE.STORE_COMM_TRACKER_DELETE_LOAD, deleteCommTrackerDataService);

  yield takeLatest(STORE_CLASSIFICATION_TRACKER_ADD.STORE_CLASSIFICATION_TRACKER_ADD_LOAD, addClassificationTrackerDataService);
  yield takeLatest(STORE_CLASSIFICATION_TRACKER_UPDATE.STORE_CLASSIFICATION_TRACKER_UPDATE_LOAD, updateClassificationTrackerDataService);
  yield takeLatest(STORE_CLASSIFICATION_TRACKER_DELETE.STORE_CLASSIFICATION_TRACKER_DELETE_LOAD, deleteClassificationTrackerDataService);

  yield takeLatest(STORE_TRACKER_GET_ALL_DATA.STORE_TRACKER_GET_ALL_DATA_LOAD, getStoreTrackerDataService);   
  yield takeLatest(DENSITYCLASS_TRACKER_GET_ALL_DATA.DENSITYCLASS_TRACKER_GET_ALL_DATA_LOAD, getDensityClassTrackerDataService);
  yield takeLatest(COMMCOMP_TRACKER_GET_ALL_DATA.COMMCOMP_TRACKER_GET_ALL_DATA_LOAD, getCommCompTrackerDataService);
  yield takeLatest(SCLASSIFICATION_TRACKER_GET_ALL_DATA.SCLASSIFICATION_TRACKER_GET_ALL_DATA_LOAD, getSClassificationTrackerDataService);
  
  yield takeLatest(STORE_TRACKER_IMPORT_DATA.STORE_TRACKER_IMPORT_DATA_LOAD, postImportStoreTrackerDataService);
}
