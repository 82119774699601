// Get
const MARKET_DATA = {
  MARKET_DATA_LOAD: "MARKET_DATA_LOAD",
  MARKET_DATA_SUCCESS: "MARKET_DATA_SUCCESS",
  MARKET_DATA_FAIL: "MARKET_DATA_FAIL",
};

const MARKET_GET_API = "/market";

// Create / Add / Post
const MARKET_ADD_DATA = {
  MARKET_ADD_DATA_LOAD: "MARKET_ADD_DATA_LOAD",
  MARKET_ADD_DATA_SUCCESS: "MARKET_ADD_DATA_SUCCESS",
  MARKET_ADD_DATA_FAIL: "MARKET_ADD_DATA_FAIL",
};

const MARKET_POST_API = "/market";

// Update / Put
const MARKET_UPDATE_DATA = {
  MARKET_UPDATE_DATA_LOAD: "MARKET_UPDATE_DATA_LOAD",
  MARKET_UPDATE_DATA_SUCCESS: "MARKET_UPDATE_DATA_SUCCESS",
  MARKET_UPDATE_DATA_FAIL: "MARKET_UPDATE_DATA_FAIL",
};

const MARKET_PUT_API = "/market";

// Get one by id
const MARKET_GET_DATA = {
  MARKET_GET_DATA_LOAD: "MARKET_GET_DATA_LOAD",
  MARKET_GET_DATA_SUCCESS: "MARKET_GET_DATA_SUCCESS",
  MARKET_GET_DATA_FAIL: "MARKET_GET_DATA_FAIL",
};

const MARKET_GET_DETAIL_API = "/marketHistory/";

// Delete
const MARKET_DELETE_DATA = {
  MARKET_DELETE_DATA_LOAD: "MARKET_DELETE_DATA_LOAD",
  MARKET_DELETE_DATA_SUCCESS: "MARKET_DELETE_DATA_SUCCESS",
  MARKET_DELETE_DATA_FAIL: "MARKET_DELETE_DATA_FAIL",
};

const MARKET_DELETE_API = "/market";

const MARKET_GET_TRACKER_DATA = {
  MARKET_GET_TRACKER_DATA_LOAD: "MARKET_GET_TRACKER_DATA_LOAD",
  MARKET_GET_TRACKER_DATA_SUCCESS: "MARKET_GET_TRACKER_DATA_SUCCESS",
  MARKET_GET_TRACKER_DATA_FAIL: "MARKET_GET_TRACKER_DATA_FAIL",
};

const MARKET_GET_TRACKER_API = "/marketHistory";

//Delete history
const MARKET_DELETE_HISTORY = {
  MARKET_DELETE_HISTORY_LOAD: "MARKET_DELETE_HISTORY_LOAD",
  MARKET_DELETE_HISTORY_SUCCESS: "MARKET_DELETE_HISTORY_SUCCESS",
  MARKET_DELETE_HISTORY_FAIL: "MARKET_DELETE_HISTORY_FAIL",
};
const MARKET_DELETE_HISTORY_API = "/marketHistory";

//Import Market
const MARKET_IMPORT_DATA = {
  MARKET_IMPORT_DATA_LOAD: "MARKET_IMPORT_DATA_LOAD",
  MARKET_IMPORT_DATA_SUCCESS: "MARKET_IMPORT_DATA_SUCCESS",
  MARKET_IMPORT_DATA_FAIL: "MARKET_IMPORT_DATA_FAIL",
};

const MARKET_IMPORT_API = "/upload";

//Import Market Tracker
const MARKET_TRACKER_IMPORT_DATA = {
  MARKET_TRACKER_IMPORT_DATA_LOAD: 'MARKET_TRACKER_IMPORT_DATA_LOAD',
  MARKET_TRACKER_IMPORT_DATA_SUCCESS: 'MARKET_TRACKER_IMPORT_DATA_SUCCESS',
  MARKET_TRACKER_IMPORT_DATA_FAIL: 'MARKET_TRACKER_IMPORT_DATA_FAIL'
}

// const MARKET_TRACKER_IMPORT_API = '/upload'

//Load stores in market
const MARKET_GET_STORES = {
  MARKET_GET_STORES_LOAD: "MARKET_GET_STORES_LOAD",
  MARKET_GET_STORES_SUCCESS: "MARKET_GET_STORES_SUCCESS",
  MARKET_GET_STORES_FAIL: "MARKET_GET_STORES_FAIL",
}
const MARKET_GET_STORES_API = 'store/market/'

export {
  MARKET_DATA,
  MARKET_GET_API,
  MARKET_ADD_DATA,
  MARKET_POST_API,
  MARKET_UPDATE_DATA,
  MARKET_PUT_API,
  MARKET_GET_DATA,
  MARKET_GET_DETAIL_API,
  MARKET_DELETE_DATA,
  MARKET_DELETE_API,
  MARKET_GET_TRACKER_DATA,
  MARKET_GET_TRACKER_API,
  MARKET_DELETE_HISTORY,
  MARKET_DELETE_HISTORY_API,
  MARKET_IMPORT_DATA,
  MARKET_IMPORT_API,
  MARKET_TRACKER_IMPORT_DATA,
  MARKET_GET_STORES, MARKET_GET_STORES_API,
};
