import {
    GROUP_JOBTITLE_DATA,    
    GROUP_JOBTITLE_GET_DATA, 
  } from "./Constants";
  
  //For listing
  const loadGroupJobTitlesDataRequest = () => ({
    type: GROUP_JOBTITLE_DATA.GROUP_JOBTITLE_DATA_LOAD,
  });
  const setGroupJobTitlesDataSuccess = (groupjobtitles) => ({
    type: GROUP_JOBTITLE_DATA.GROUP_JOBTITLE_DATA_SUCCESS,
    groupjobtitles,
  });
  const setGroupJobTitlesDataFail = (error) => ({
    type: GROUP_JOBTITLE_DATA.GROUP_JOBTITLE_DATA_FAIL,
    error,
  });
  
  
  
  //To Get
  const loadGroupJobTitlesGetDataRequest = (id) => ({
    type: GROUP_JOBTITLE_GET_DATA.GROUP_JOBTITLE_GET_DATA_LOAD,
    id,
  });
  
  const setGroupJobTitlesGetDataSuccess = (groupjobtitle) => ({
    type: GROUP_JOBTITLE_GET_DATA.GROUP_JOBTITLE_GET_DATA_SUCCESS,
    groupjobtitle,
  });
  
  const setGroupJobTitlesGetDataFail = (error) => ({
    type: GROUP_JOBTITLE_GET_DATA.GROUP_JOBTITLE_GET_DATA_FAIL,
    error,
  });
  
  
  export {
    loadGroupJobTitlesDataRequest,
    setGroupJobTitlesDataSuccess,
    setGroupJobTitlesDataFail,
    loadGroupJobTitlesGetDataRequest,
    setGroupJobTitlesGetDataSuccess,
    setGroupJobTitlesGetDataFail
  };
  