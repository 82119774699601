import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadDepartmentUpdateDataRequest } from "../redux/Actions";
import DepartmentForm from "./DepartmentForm";

const DepartmentEdit = ({ handleClose, department }) => {
  const dispatch = useDispatch();
  let [shouldCloseModal, setShouldCloseModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState(null);
  let { loading, error } = useSelector((state) => state.department);

  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true)
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false)
    }
  }, [loading, error, shouldCloseModal])

  function submit(values) {
    dispatch(loadDepartmentUpdateDataRequest(values));
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return <>
    <FormError>
      {errorMessage}
    </FormError>
    <DepartmentForm submit={submit} cancel={cancel} department={department} />;
  </>
};

export default DepartmentEdit;
