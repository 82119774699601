import { takeLatest, put, call } from "redux-saga/effects";
import {
  DASHBOARD_DATA,
  DASHBOARD_GET_API,
  DASHBOARD_GET_RANGE,
  DASHBOARD_GET_RANGE_API,
  DASHBOARD_GET_STORE,
  DASHBOARD_GET_STORE_API,
  DASHBOARD_GET_STORE_METRICS,
  DASHBOARD_GET_STORE_METRICS_API,
} from "./Constants";
import {
  setDashboardDataSuccess,
  setDashboardDataFail,
  setDashboardGetRangeDataSuccess,
  setDashboardGetRangeDataFail,
  setDashboardGetStoreDataSuccess,
  setDashboardGetStoreDataFail,
  setDashboardGetStoreMetricsSuccess,
  setDashboardGetStoreMetricsFail
} from "./Actions";
import {
  getRequest, postRequest, putRequest
} from "../../../shared/components/Service";

function* getDashboardDataService({ company_id }) {
  if (company_id > 0) {
    company_id = '/' + company_id;
  } else {
    company_id = '';
  }
  try {
    const Response = yield call(getRequest, DASHBOARD_GET_API + company_id);
    const status = Response.status;
    if (status === 200) {
      const data = Response.data;
      yield put(setDashboardDataSuccess(data));
    } else {
      yield put(setDashboardDataFail(Response.response.data));
    }
  } catch (error) {
    yield put(setDashboardDataFail(error));
  }
}

function* getDashboardRangeDataService({ start_date, end_date, company_id }) {
  try {
    let request = {
      start_date,
      end_date,
      company_id
    }
    const response = yield call(postRequest, DASHBOARD_GET_RANGE_API, request);
    if (response.status === 200) {
      yield put(setDashboardGetRangeDataSuccess(response.data))
    } else {
      yield put(setDashboardGetRangeDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setDashboardGetRangeDataFail(error))
  }
}

function* getDashboardStoreDataService({ year, company_id }) {
  try {
    let request = {
      year,
      company_id
    }
    const response = yield call(postRequest, DASHBOARD_GET_STORE_API, request);
    if (response.status === 200) {
      yield put(setDashboardGetStoreDataSuccess(response.data))
    } else {
      yield put(setDashboardGetStoreDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setDashboardGetStoreDataFail(error))
  }
}
function* getStoreMetrics({company_id}) {
  console.log(company_id)
  if (company_id > 0) {
    company_id = '/' + company_id;
  } else {
    company_id = '';
  }
  try {
    console.log("Wassup", company_id)
    let response = yield call(getRequest, DASHBOARD_GET_STORE_METRICS_API + company_id);
    if (response.status === 200) {
      yield put(setDashboardGetStoreMetricsSuccess(response.data))
    } else {
      yield put(setDashboardGetStoreMetricsFail(response.response.data))
    }
  } catch (error) {
    yield put(setDashboardGetStoreMetricsFail(error))
  }
}

export function* watchDashboard() {
  yield takeLatest(DASHBOARD_DATA.DASHBOARD_DATA_LOAD, getDashboardDataService);
  yield takeLatest(DASHBOARD_GET_RANGE.DASHBOARD_GET_RANGE_LOAD, getDashboardRangeDataService)
  yield takeLatest(DASHBOARD_GET_STORE.DASHBOARD_GET_STORE_LOAD, getDashboardStoreDataService)
  yield takeLatest(DASHBOARD_GET_STORE_METRICS.DASHBOARD_GET_STORE_METRICS_LOAD, getStoreMetrics)
}
