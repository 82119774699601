export default class FloatingActiveFilter {
    init(params) {
        this.eGui = document.createElement('div');
        this.eGui.id = 'floatingActiveFilter'
        this.eGui.innerHTML = "<select class='input form-control form-select'> <option value='any'>Any</option> <option value='0'>Active</option> <option value='1'>Inactive</option> <option value='2'>Future</option> </select>";
        this.currentValue = null;
        this.eFilterInput = this.eGui.querySelector('select');
        this.eFilterInput.style.color = params.color;

        const onInputBoxChanged = () => {
            if (this.eFilterInput.value === 'any') {
                // Remove the filter
                params.parentFilterInstance((instance) => {
                    instance.onFloatingFilterChanged(null, null);
                });
                return;
            }

            this.currentValue = Number(this.eFilterInput.value);
            params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged('equals', String(this.currentValue));
            });
        };

        this.eFilterInput.addEventListener('input', onInputBoxChanged);
    }

    onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null message her
        if (!parentModel) {
            this.eFilterInput.value = '';
            this.currentValue = null;
        } else {
            this.eFilterInput.value = parentModel.filter + '';
            this.currentValue = parentModel.filter;
        }
    }

    getGui() {
        return this.eGui;
    }
}