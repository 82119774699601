import React, { useEffect, useState } from "react";
import "../Company.css";
import { COMPANY, CREATENEW, IMPORTCOMPANY, IMPORT, CREATECOMPANY } from "../../../shared/constants/Constants";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import Modalpopup from "../../../shared/components/Modalpopup";
import DefaultButton from "../../../shared/components/DefaultButton";
import { Col, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import ListView from "../../../shared/components/listview/ListView";
import CompanyAdd from "./CompanyAdd";
import { loadCompanyDataRequest, loadCompanyImportDataRequest, } from "../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../shared/components/Loader";
import ImportModal from "../../../shared/components/ImportModal";
import Permissions from "../../../shared/components/Permissions";

const Company = () => {
  let dispatch = useDispatch();
  let { loading, error, companies, isImportFile } = useSelector((state) => state.company);
  useEffect(() => {
    dispatch(loadCompanyDataRequest());
  }, [dispatch]);

  let navigate = useNavigate();
  const [showImportModal, setShowImportModal] = useState(false);
  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);

  let companyData = companies;

  let companyColumns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true
    },
    {
      display_name: "Company Name",
      field: "company_name",
      type: "text",
    },
    {
      display_name: "Ulti Company Code",
      field: "ulti_company_code",
      type: "text",
    },
    {
      display_name: "Master Dealer Code",
      field: "master_dealer_code",
      type: "text",
    },
    {
      display_name: "Start Date",
      field: "start_date",
      type: "date",
    },
    {
      display_name: "End Date",
      field: "end_date",
      type: "date",
    },
  ];

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "Company";
  let permission = Permissions(emp_no, module_name);

  return (
    <>
      <div>
        <Row>
          <Col className='gx-0'>
            <Breadcrumbs
              children={[
                { label: COMPANY },
              ]}
            />
          </Col>
          <Col xs="auto" className="gx-0">
              {permission.create_permission ? (<>
                <DefaultButton
                  buttonClass={"default-btn-color me-1"}
                  buttonTitle={IMPORT}
                  actionBlock={() => { setShowImportModal(true) }}
                />
                <DefaultButton
                  buttonClass={"default-btn-color"}
                  buttonTitle={CREATENEW}
                  actionBlock={() => setShowCreateCompanyModal(true)}
                />
              </>
              ) : (<></>)}
          </Col>
        </Row>
        {loading && companies.length === 0 ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Card className='table-card'>
                <Card.Body>
                  <ListView
                    exportTitle={COMPANY}
                    columnNames={companyColumns}
                    rowData={companyData}
                    shouldSetNextPrevIds
                    rowClick={(row) => {
                      navigate("./" + row.data["id"]);
                    }}
                    tableHeight = '70vh'
                  />
                </Card.Body>
              </Card>
            </Row>
          </>
        )}
      </div>
      <Modalpopup
        show={showCreateCompanyModal}
        handleClose={() => { setShowCreateCompanyModal(false) }}
        actionTitle={CREATECOMPANY}
      >
        <CompanyAdd
          handleClose={(reload) => {
            if (reload) {
              dispatch(loadCompanyDataRequest());
            }
            setShowCreateCompanyModal(false)
          }
          }
        />
      </Modalpopup>
      <ImportModal
        show={showImportModal}
        handleClose={() => setShowImportModal(false)}
        tableName={"company"}
        modalTitle={IMPORTCOMPANY}
        importAction={loadCompanyImportDataRequest}
      />

    </>
  );
};

export default Company;
