import React, { useState } from 'react'
import './Menu.css'
import { useNavigate, NavLink } from 'react-router-dom'
import { MYPACK, TEAMPACK, DASHBOARD, COMPANY, DIVISION, REGION, MARKET, STORES, EMPLOYEES, ACQUISITION, DENSITY, STORECLASSIFICATION, COMPGROUP, CTYPE, REGIONTRACKER, STOREHOURS, JOB_TITLE, EMPLOYEE_TYPE, EMPLOYEE_LEAVE_TYPE, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, HIRE_STATUS, TERM_REASON, PART_FULL_TIME, HOURLY_SALARY, STORETRACKER, DENSITYTRACKER, STORECLASSIFICATIONTRACKER, COMMCOMPTRACKER, DESIGNATION_TRACKER, EMP_TYPE_HIRE_STATUS_TRACKER, ROLES, GROUPS, MODULEMETADATA, EMPLOYEE_ROLE_MAPPING, ROLE_MODULE_MAPPING, ODOO_STORE_TRANSFER_TRACKERS, ODOO_DESIGNATION_TRACKERS, KEY_TAG, EMPLOYEETABLES, STORETABLES, FORM_BULK_IMPORT } from '../../shared/constants/Constants';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import { Col, Dropdown, Row } from 'react-bootstrap'
import MenuCollapsableItem from './MenuCollapsableItem'
import Permissions from '../../shared/components/Permissions';

const MenuCollapsed = () => {
    let navigate = useNavigate()
    let logoPath = "/company-logo.png";

    let empInfo = JSON.parse(localStorage.getItem('employee'));
    let securityTab = false;
    if (empInfo?.role === "admin") {
        securityTab = true;
    }

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "VMO";
    let vmoPermission = Permissions(emp_no, module_name);
    
    return (
        <div className="sidebar-wrapper d-none-sm">
            <div className="sidebar-header">
                <div>
                    <img src={logoPath} alt="logo icon" className='company-logo' />
                </div>
            </div>
            <div id="menu-spacer" style={{ height: '70px' }}></div>
            <div className="sidebar-body" style={{ overflow: 'visible' }}>
                <ul id="menu">
                    {(empInfo?.role === "hc_sales") ? (<>
                        <SubMenu
                            icon={<i className='fa fa-user-o' onClick={() => navigate('/userProfile')}></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header" as={NavLink} to={('/userProfile')}>Profile</Dropdown.Item>
                                </>
                            }
                        /> 
                        <SubMenu
                            icon={<i className='fa fa-circle-info' onClick={() => navigate('/t-shirtSize')}></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header" as={NavLink} to={('/t-shirtSize')}>Additional Info</Dropdown.Item>
                                </>
                            }
                        /> 
                        <SubMenu
                            icon={<i className='fa fa-tasks' onClick={() => navigate('/myplan?filter=none')}></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header" as={NavLink} to={('/myplan?filter=none')}>My Plan</Dropdown.Item>
                                </>
                            }
                        /> 
                        <SubMenu
                            icon={<i className='fa fa-group' onClick={() => navigate('/teamplan?filter=none')}></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header" as={NavLink} to={('/teamplan?filter=none')}> Team Plan</Dropdown.Item>
                                </>
                            }
                        /> 
                    </>) : (<>
                    <SubMenu
                        icon={<i className='fa fa-home' onClick={() => navigate('/dashboard')}></i>}
                        children={
                            <>
                                <Dropdown.Item className="header" as={NavLink} to={('/dashboard')}>{DASHBOARD}</Dropdown.Item>
                            </>
                        }

                    />
                    <SubMenu
                        icon={<i className='fa fa-map' ></i>}
                        width={240}
                        children={
                            <>
                                <Dropdown.Item className="header">Org. Hierarchy</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={'/company?status=active'}>{COMPANY}</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={'/division?status=active'}>{DIVISION}</Dropdown.Item>

                                <Dropdown.Item as={NavLink} to={'/region?status=active'}>{REGION}</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={'/district?status=active'}>{MARKET}</Dropdown.Item>

                                {/* <MenuCollapsableItem hasLeftArrow={true} parent={[
                                    <div className="sub-menu-title me-3">{REGION}</div>
                                ]}>
                                    <Dropdown.Item style={{ marginLeft: '40px' }} as={NavLink} to={'/region?filter=none'}>{REGION}</Dropdown.Item>
                                    <Dropdown.Item style={{ marginLeft: '40px' }} as={NavLink} to={'/region-tracker?filter=none'}>{REGIONTRACKER}</Dropdown.Item>
                                </MenuCollapsableItem>
                                <MenuCollapsableItem hasLeftArrow={true} parent={[
                                    <div className="sub-menu-title me-3">{MARKET}</div>
                                ]}>
                                    <Dropdown.Item style={{ marginLeft: '40px' }} as={NavLink} to={'/district?filter=none'}>District</Dropdown.Item>
                                    <Dropdown.Item style={{ marginLeft: '40px' }} as={NavLink} to={'/district-tracker?filter=none'}>District Tracker</Dropdown.Item>
                                </MenuCollapsableItem> */}
                                <Dropdown.Item as={NavLink} to={'/stores'}>{STORES}</Dropdown.Item>
                                <MenuCollapsableItem hasLeftArrow={true} parent={
                                    <div className="sub-menu-title me-3">{STORETABLES}</div>
                                }>
                                    
                                    <Dropdown.Item as={NavLink} to={'/channel-type?filter=none'}>{CTYPE}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/store-classification?filter=none'}>{STORECLASSIFICATION}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/density?filter=none'}>{DENSITY}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/acquisition?filter=none'}>{ACQUISITION}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/commission-comp-group?filter=none'}>{COMPGROUP}</Dropdown.Item>
                                    {/* <Dropdown.Item style={{ marginLeft: '40px' }} as={NavLink} to={'/store-tracker?filter=none'}>{STORETRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/densityclass-tracker?filter=none'}>{DENSITYTRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/commcomp-tracker?filter=none'}>{COMMCOMPTRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/store-classification-tracker?filter=none'}>{STORECLASSIFICATIONTRACKER}</Dropdown.Item> */}
                                    <Dropdown.Item as={NavLink} to={'/store-hours?filter=none'}>{STOREHOURS}</Dropdown.Item>
                                </MenuCollapsableItem>
                            </>
                        }
                    />
                    <SubMenu
                        icon={<i className='fa fa-users'></i>}
                        children={
                            <>
                                <Dropdown.Item className="header">{EMPLOYEES}</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={'/employees'}>{EMPLOYEES}</Dropdown.Item>
                                {/* <Dropdown.Item as={NavLink} to={'/t-shirtSize'}>My Additional Info</Dropdown.Item> */}
                                <Dropdown.Item as={NavLink} to={'/all-tshirtsizes'}>Employee Additional Info</Dropdown.Item>
                                <MenuCollapsableItem hasLeftArrow={true} parent={
                                    <div className="sub-menu-title me-3">{EMPLOYEETABLES}</div>
                                }>
                                    <Dropdown.Item as={NavLink} to={'/departments?filter=none'}>{EMPLOYEE_DEPARTMENT}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/leave-types?filter=none'}>{EMPLOYEE_LEAVE_TYPE}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/employee-types?filter=none'}>{EMPLOYEE_TYPE}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/jobtitles?filter=none'}>{JOB_TITLE}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/employee-status?filter=none'}>{EMPLOYEE_STATUS}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/hire-status?filter=none'}>{HIRE_STATUS}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/term-reason?filter=none'}>{TERM_REASON}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/full-part-time?filter=none'}>{PART_FULL_TIME}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/hourly-salary?filter=none'}>{HOURLY_SALARY}</Dropdown.Item>
                                </MenuCollapsableItem>
                                {/* <Dropdown.Item as={NavLink} to={'/designation-tracker?filter=none'}>{DESIGNATION_TRACKER}</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={'/emptype-hirestatus-tracker?filter=none'}>{EMP_TYPE_HIRE_STATUS_TRACKER}</Dropdown.Item> */}                                
                            </>
                        }
                    />
                    {securityTab ? (
                        <SubMenu
                            icon={<i className='fas fa-shield'></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header">Security</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={"/roles?filter=none"}>{ROLES}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/groups?filter=none'}>{GROUPS}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/module-meta-data?filter=none'}>{MODULEMETADATA}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/role-module-mapping?filter=none'}>{ROLE_MODULE_MAPPING}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/employee-role-mapping?filter=none'}>{EMPLOYEE_ROLE_MAPPING}</Dropdown.Item>
                                </>
                            }
                        />
                    ) : (<></>)}
                    {/* {securityTab ? ( */}
                        <SubMenu
                            icon={<i className='fas fa-dot-circle'></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header">Odoo</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/odoo-store-transfer-trackers?filter=none'}>{ODOO_STORE_TRANSFER_TRACKERS}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/odoo-designation-trackers?filter=none'}>{ODOO_DESIGNATION_TRACKERS}</Dropdown.Item>
                                </>
                            }
                        />
                    {/* ) : (<></>)}
                    {securityTab ? ( */}
                        <SubMenu
                            icon={<i className='fa fa-tags' onClick={() => navigate('/admin-key-tag?filter=none')}></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header" as={NavLink} to={('/admin-key-tag?filter=none')}>{KEY_TAG}</Dropdown.Item>
                                </>
                            }
                        />
                        <SubMenu
                            icon={<i className='fa fa-list-alt' onClick={() => navigate('/form-bulk-import?filter=none')}></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header" as={NavLink} to={('/form-bulk-import?filter=none')}>{FORM_BULK_IMPORT}</Dropdown.Item>
                                </>
                            }
                        />
                    {/* ) : (<></>)}
                    {securityTab ? ( */}
                        <SubMenu
                            icon={<i className='fa fa-history'></i>}
                            width={270}
                            children={
                                <>
                                    <Dropdown.Item className="header">Trackers</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/region-tracker?filter=none'}>{REGIONTRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/district-tracker?filter=none'}>District Tracker</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/store-tracker?filter=none'}>{STORETRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/densityclass-tracker?filter=none'}>{DENSITYTRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/commcomp-tracker?filter=none'}>{COMMCOMPTRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/store-classification-tracker?filter=none'}>{STORECLASSIFICATIONTRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/designation-tracker?filter=none'}>{DESIGNATION_TRACKER}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/emptype-hirestatus-tracker?filter=none'}>{EMP_TYPE_HIRE_STATUS_TRACKER}</Dropdown.Item>
                                    
                                </>
                            }
                        />
                    {/* ) : (<></>)}
                    {securityTab ? ( */}
                        <SubMenu
                            icon={<i className='fa fa-clipboard-list'></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header">Audits</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/queue-table?filter=none'}>{"Workflow Queue"}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/audit?filter=none'}>{"Audit Logs"}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/HireTermQueue?filter=none'}>Hire Term Queue</Dropdown.Item>   
                                </>
                            }
                        />
                    {/* ) : (<></>)} */}
                    <SubMenu
                        icon={<i className='fa fa-shopping-cart'></i>}
                        children={
                            <>
                                <Dropdown.Item className="header">Product</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={'/products'}>Products</Dropdown.Item>
                                <Dropdown.Item as={NavLink} to={'/product-tables'}>Product Tables</Dropdown.Item>
                                {/* <MenuCollapsableItem hasLeftArrow={true} parent={
                                    <div className="sub-menu-title me-3">Product Tables</div>
                                } className="last-child">
                                    <Dropdown.Item as={NavLink} to={'/product-type?filter=none'}>Product Type</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/accessories?filter=none'}>Accessories</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/account-type?filter=none'}>Account Type</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/category?filter=none'}>Category</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/category-product?filter=none'}>Category Product</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/line-type?filter=none'}>Line Type</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/relationship-type?filter=none'}>Relationship Type</Dropdown.Item>
                                </MenuCollapsableItem> */}
                            </>
                        }
                    />
                        {vmoPermission.write_permission ? (<>
                        <SubMenu
                            icon={<i className='fa fa-pencil-square-o'></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header">VMO</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/quarterlyReview?filter=none'}>{"Quarterly Review"}</Dropdown.Item>
                                    <Dropdown.Item as={NavLink} to={'/retail-foundation?filter=none'}>{"Retail Foundation"}</Dropdown.Item> 
                                </>
                            }
                        />
                        </>) : (<></>)}
                        <SubMenu
                            icon={<i className='fa fa-store' onClick={() => navigate('/search-sap?filter=none')}></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header" as={NavLink} to={('/search-sap?filter=none')}>Search SAP</Dropdown.Item>
                                </>
                            }
                        />
                        <SubMenu
                            icon={<i className='fa fa-file' onClick={() => navigate('/allplan?filter=none')}></i>}
                            children={
                                <>
                                    <Dropdown.Item className="header" as={NavLink} to={('/allplan?filter=none')}>All Priority Plans</Dropdown.Item>
                                </>
                            }
                        />
                    </>)}
                </ul>
            </div>
        </div>
    )
}

export default MenuCollapsed

function SubMenu({ icon, children, width }) {
    let [show, setShow] = useState(false)

    let list
    if (children) {
        list = <DropdownMenu show={show} style={{ marginLeft: 70, marginTop: '-52px', padding: 0, width: width??210 }}>
            {children}
        </DropdownMenu>
    }
    return < Row onMouseEnter={() => { setShow(true) }} onMouseLeave={() => { setShow(false) }}>
        <Col>
            <div className={"collapsed-menu-parent-icon" + (show ? " hovered" : '')} >{icon}</div>
            {list}
        </Col>
    </Row >
}