import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadGroupsUpdateDataRequest } from "../redux/Actions";
import GroupsForm from "./GroupsForm";

const GroupsEdit = ({ handleClose, groups }) => {
  const dispatch = useDispatch();
  let [submitted, setSubmitted] = useState(false);
  let [isClosing, setIsClosing] = useState(false);

  let { loading, error } = useSelector((state) => state.groups);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (submitted && !loading && !isClosing) {
        setIsClosing(true);
        handleClose(true)
    }
}, [loading, dispatch, error, handleClose, isClosing, submitted])

  function submit(values) {
    dispatch(loadGroupsUpdateDataRequest(values));
    setSubmitted(true);
  }
  function cancel() {
    handleClose(false);
  }
  return <GroupsForm submit={submit} cancel={cancel} groups={groups} />;
};

export default GroupsEdit;
