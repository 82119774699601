import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { CANCEL, COMPANY, EMPLOYEE_DEPARTMENT, JOB_TITLE, SAVE, ULTI_JOB_CODE } from "../../../shared/constants/Constants";
import { loadCompanyDataRequest } from "../../company/redux/Actions";
import { loadDepartmentDataRequest } from "../../employee_departments/redux/Actions";

const JobTitlesForm = ({ jobtitle, submit, cancel }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.job_title) {
            errors.job_title = "Required";
        }
        if (!values.department_id) {
            errors.department_id = "Required"
        }
        if (!values.company_id) {
            errors.company_id = "Required"
        }
        return errors;
    };
    let dispatch = useDispatch();
    let { companies } = useSelector(state => state.company)
    let { departments } = useSelector(state => state.department)
    useEffect(() => {
        if (companies.length === 0) {
            dispatch(loadCompanyDataRequest())
        }
    }, [dispatch, companies.length])

    useEffect(() => {
        if (departments.length === 0) {
            dispatch(loadDepartmentDataRequest())
        }
    }, [dispatch, departments.length])

    const formik = useFormik({});

    useEffect(() => {
        if (jobtitle) {
            formik.current.setFieldValue("id", jobtitle?.id);
            formik.current.setFieldValue("job_title", jobtitle?.job_title);
            formik.current.setFieldValue("ulti_job_code", jobtitle?.ulti_job_code);
            formik.current.setFieldValue("company_id", jobtitle?.company_id);
            formik.current.setFieldValue("department_id", jobtitle?.department_id);
        }
    }, [jobtitle, formik]);

    let [departmentList, setDepartmentList] = useState([]);
    useEffect(() => {
        setDepartmentList(departments)
    }, [departments])

    return (
        <Formik
            innerRef={formik}
            initialValues={{
                job_title: "",
                ulti_job_code: '',
                company_id: '',
                department_id: '',
            }}
            validate={validate}
            onSubmit={submit}
        >
            {({ values, setFieldValue }) => (
                <Form className="row g-4">
                    <div className="col-md-6">
                        <label htmlFor="job_title" className="form-label">
                            {JOB_TITLE}<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="job_title"
                            placeholder={"Enter " + JOB_TITLE}
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="job_title" />
                        </span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="ulti_job_code" className="form-label">
                            {ULTI_JOB_CODE}
                        </label>
                        <Field type="text" className="form-control" name="ulti_job_code" placeholder="Ulti Job Code" autoComplete="off" />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="company_id" className="form-label">
                            {COMPANY}<span className="error-text">*</span>
                        </label>
                        <FormikSelectField
                            name="company_id"
                            value={values.company_id}
                            onChange={(name, value) => {
                                setFieldValue(name, value)
                                setDepartmentList(
                                    [...departments.filter(
                                        (department) => {
                                            return department.company_id === value.toString()
                                        }
                                    )]
                                )
                                let selectedDepartmentCompanyId = departments.find(
                                    department => department.id === values.department_id
                                )?.company_id;
                                if (selectedDepartmentCompanyId !== value.toString()) {
                                    setFieldValue('department_id', null)
                                }
                            }
                            }
                            placeHolder="Select Company"
                        >
                            {companies.map((company) => {
                                return <option value={company.id}>{company.company_name}</option>
                            })
                            }
                        </FormikSelectField>
                        <span className="error-text">
                            <ErrorMessage name="company_id" />
                        </span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="department_id" className="form-label">
                            {EMPLOYEE_DEPARTMENT}<span className="error-text">*</span>
                        </label>
                        <FormikSelectField
                            name="department_id"
                            value={values.department_id}
                            onChange={(name, value) => {
                                let company_id = departments.find(department => department.id === value).company_id
                                setFieldValue(name, value)
                                setFieldValue("company_id", company_id)
                            }}
                            placeholder={"Select " + EMPLOYEE_DEPARTMENT}
                        >
                            {departmentList.map((department) => {
                                return <option value={department.id} >{department.department_name}</option>
                            })
                            }
                        </FormikSelectField>
                        <span className="error-text">
                            <ErrorMessage name="department_id" />
                        </span>
                    </div>
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )
            }
        </Formik >
    );
};

export default JobTitlesForm;
