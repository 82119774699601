import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { CANCEL, MODULEMETADATA, SAVE } from "../../../shared/constants/Constants";
import { loadModuleDataRequest } from "../../module_meta_data/redux/Actions";

const KeyTagForm = ({ keytags, submit, cancel }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.key_name) {
            errors.key_name = "Required";
        }
        if (!values.module_metadata_id) {
            errors.module_metadata_id = "Required"
        }
        if (!values.key_input_type) {
            errors.key_input_type = "Required"
        }
        return errors;
    };

    let dispatch = useDispatch();
    let { modules } = useSelector((state) => state.modules);
    useEffect(() => {
        if (modules.length === 0) {
            dispatch(loadModuleDataRequest())
        }
    }, [dispatch])

    let keyTypes = [{ "name": "freetext", "display_name": "Free Text" }, { "name": "multiple", "display_name": "Multiple" }];
    let keyTypeStatus = false;
    if (keytags) {
        keyTypeStatus = true;
    }

    console.log("status::", keyTypeStatus);

    return (
        <Formik
            initialValues={{
                id: keytags?.id ?? null,
                key_name: keytags?.key_name ?? "",
                key_input_type: keytags?.key_input_type ?? "",
                module_metadata_id: keytags?.module_metadata_id ?? "",
                key_predefined_value: keytags?.key_predefined_value ?? "",
                key_default_value: keytags?.key_default_value ?? ""
            }}
            validate={validate}
            onSubmit={submit}
            enableReinitialize
        >
            {({ values, setFieldTouched, setFieldValue }) => (
                <Form className="row g-4">
                    <div className="col-md-6">
                        <label htmlFor="traffic_reduction" className="form-label">
                            Module Meta Data<span className="error-text">*</span>
                        </label>
                        <FormikSelectField
                            name="module_metadata_id"
                            value={values.module_metadata_id}
                            onChange={setFieldValue}
                            placeHolder={"Select " + MODULEMETADATA}
                        >
                            {modules.map((module) => {
                                return <option value={module.id}>{module.module_name}</option>
                            })
                            }
                        </FormikSelectField>
                        <span className="error-text">
                            <ErrorMessage name="module_metadata_id" />
                        </span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="key_input_type" className="form-label">
                            Key Input Type<span className="error-text">*</span>
                        </label>
                        <FormikSelectField
                            name="key_input_type"
                            value={values.key_input_type}
                            onChange={setFieldValue}
                            placeHolder={"Select Key Input Type"}
                            disabled={keyTypeStatus}
                        >
                            {keyTypes.map((keyType) => {
                                return <option value={keyType.name}>{keyType.display_name}</option>
                            })
                            }
                        </FormikSelectField>
                        <span className="error-text">
                            <ErrorMessage name="key_input_type" />
                        </span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="key_name" className="form-label">
                            Key Name<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="key_name"
                            placeholder="Enter Key Name"
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="key_name" />
                        </span>
                    </div>
                    {values.key_input_type === 'multiple' ?
                        <div className="col-md-6">
                            <label htmlFor="key_predefined_value" className="form-label">
                                Key Predefined Value
                            </label>
                            <Field
                                className="form-control"
                                type="text"
                                name="key_predefined_value"
                                placeholder="Enter Predefined Value"
                                autoComplete="off"
                            />
                            <span className="error-text">
                                <ErrorMessage name="key_predefined_value" />
                            </span>
                        </div>
                        : ''
                    }
                    <div className="col-md-6">
                        <label htmlFor="key_default_value" className="form-label">
                            Key Default Value
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="key_default_value"
                            placeholder="Enter Default Name"
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="key_default_value" />
                        </span>
                    </div>
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default KeyTagForm;
