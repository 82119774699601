import {
    CLASSIFICATION_DATA,
    CLASSIFICATION_ADD_DATA,
    CLASSIFICATION_UPDATE_DATA,
    CLASSIFICATION_GET_DATA,
    CLASSIFICATION_DELETE_DATA,
  } from "./Constants";
  
  //For listing
  const loadClassificationDataRequest = () => ({
    type: CLASSIFICATION_DATA.CLASSIFICATION_DATA_LOAD,
  });
  const setClassificationDataSuccess = (classifications) => ({
    type: CLASSIFICATION_DATA.CLASSIFICATION_DATA_SUCCESS,
    classifications,
  });
  const setClassificationDataFail = (error) => ({
    type: CLASSIFICATION_DATA.CLASSIFICATION_DATA_FAIL,
    error,
  });
  
  //For add
  const loadClassificationAddDataRequest = (request) => ({
    type: CLASSIFICATION_ADD_DATA.CLASSIFICATION_ADD_DATA_LOAD,
    request,
  });
  const setClassificationAddDataSuccess = (classification) => ({
    type: CLASSIFICATION_ADD_DATA.CLASSIFICATION_ADD_DATA_SUCCESS,
    classification,
  });
  
  const setClassificationAddDataFail = (error) => ({
    type: CLASSIFICATION_ADD_DATA.CLASSIFICATION_ADD_DATA_FAIL,
    error,
  });
  
  //For Edit
  const loadClassificationUpdateDataRequest = (request) => ({
    type: CLASSIFICATION_UPDATE_DATA.CLASSIFICATION_UPDATE_DATA_LOAD,
    request,
  });
  
  const setClassificationUpdateDataSuccess = (classification) => ({
    type: CLASSIFICATION_UPDATE_DATA.CLASSIFICATION_UPDATE_DATA_SUCCESS,
    classification,
  });
  
  const setClassificationUpdateDataFail = (error) => ({
    type: CLASSIFICATION_UPDATE_DATA.CLASSIFICATION_UPDATE_DATA_FAIL,
    error,
  });
  
  //To Get
  const loadClassificationGetDataRequest = (id) => ({
    type: CLASSIFICATION_GET_DATA.CLASSIFICATION_GET_DATA_LOAD,
    id,
  });
  
  const setClassificationGetDataSuccess = (classification) => ({
    type: CLASSIFICATION_GET_DATA.CLASSIFICATION_GET_DATA_SUCCESS,
    classification,
  });
  
  const setClassificationGetDataFail = (error) => ({
    type: CLASSIFICATION_GET_DATA.CLASSIFICATION_GET_DATA_FAIL,
    error,
  });
  
  //Delete
  const loadClassificationDeleteDataRequest = (request) => ({
    type: CLASSIFICATION_DELETE_DATA.CLASSIFICATION_DELETE_DATA_LOAD,
    request,
  });
  
  const setClassificationDeleteDataSuccess = (classification) => ({
    type: CLASSIFICATION_DELETE_DATA.CLASSIFICATION_DELETE_DATA_SUCCESS,
    classification,
  });
  
  const setClassificationDeleteDataFail = (error) => ({
    type: CLASSIFICATION_DELETE_DATA.CLASSIFICATION_DELETE_DATA_FAIL,
    error,
  });
  
  export {
    loadClassificationDataRequest,
    setClassificationDataSuccess,
    setClassificationDataFail,
    loadClassificationAddDataRequest,
    setClassificationAddDataSuccess,
    setClassificationAddDataFail,
    loadClassificationUpdateDataRequest,
    setClassificationUpdateDataSuccess,
    setClassificationUpdateDataFail,
    loadClassificationGetDataRequest,
    setClassificationGetDataSuccess,
    setClassificationGetDataFail,
    loadClassificationDeleteDataRequest,
    setClassificationDeleteDataSuccess,
    setClassificationDeleteDataFail,
  };
  