import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadRegionAddDataRequest } from "../redux/Actions";
import RegionForm from "./RegionForm";

const RegionAdd = ({ handleClose }) => {
    const dispatch = useDispatch();
    let [submitted, setSubmitted] = useState(false);
    let [isClosing, setIsClosing] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);

    let { loading, error } = useSelector(state => state.region)

    // After submition, the post request is done loading, and there's no errors
    useEffect(() => {
        console.log(error);
        if (submitted && !loading) {
            if (!error) {
                handleClose(true)
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setSubmitted(false)
        }
    }, [loading, error, submitted])

    function submit(values) {
        dispatch(loadRegionAddDataRequest(values))
        setSubmitted(true)
    }
    function cancel() {
        handleClose(false);
    }
    return <>
        <FormError>
            {errorMessage}
        </FormError>
        <RegionForm submit={submit} cancel={cancel} />
    </>
};

export default RegionAdd;
