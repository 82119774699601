import {
    EMPLOYEE_TYPE_DATA,
    EMPLOYEE_TYPE_GET_DATA,
    EMPLOYEE_TYPE_ADD_DATA,
    EMPLOYEE_TYPE_UPDATE_DATA,
    EMPLOYEE_TYPE_DELETE_DATA,
} from './Constants'


//For listing
const loadEmployeeTypeDataRequest = () => ({
    type: EMPLOYEE_TYPE_DATA.EMPLOYEE_TYPE_DATA_LOAD,
});
const setEmployeeTypeDataSuccess = (employeeTypes) => ({
    type: EMPLOYEE_TYPE_DATA.EMPLOYEE_TYPE_DATA_SUCCESS,
    employeeTypes,
});
const setEmployeeTypeDataFail = (error) => ({
    type: EMPLOYEE_TYPE_DATA.EMPLOYEE_TYPE_DATA_FAIL,
    error,
});

//for details
const loadEmployeeTypeGetDataRequest = (id) => ({
    type: EMPLOYEE_TYPE_GET_DATA.EMPLOYEE_TYPE_GET_DATA_LOAD,
    id,
});
const setEmployeeTypeGetDataSuccess = (response) => ({
    type: EMPLOYEE_TYPE_GET_DATA.EMPLOYEE_TYPE_GET_DATA_SUCCESS,
    employeeType: response.employeeType[0],
    employeeType_class_tracker: response.employeeType_class_tracker
});
const setEmployeeTypeGetDataFail = (error) => ({
    type: EMPLOYEE_TYPE_GET_DATA.EMPLOYEE_TYPE_GET_DATA_FAIL,
    error,
});

//For add EmployeeType
const loadEmployeeTypeAddDataRequest = (request) => ({
    type: EMPLOYEE_TYPE_ADD_DATA.EMPLOYEE_TYPE_ADD_DATA_LOAD,
    request,
});
const setEmployeeTypeAddDataSuccess = (employeeType) => ({
    type: EMPLOYEE_TYPE_ADD_DATA.EMPLOYEE_TYPE_ADD_DATA_SUCCESS,
    employeeType,
});
const setEmployeeTypeAddDataFail = (error) => ({
    type: EMPLOYEE_TYPE_ADD_DATA.EMPLOYEE_TYPE_ADD_DATA_FAIL,
    error,
});

//update employeeType
const loadEmployeeTypeUpdateDataRequest = (request) => ({
    type: EMPLOYEE_TYPE_UPDATE_DATA.EMPLOYEE_TYPE_UPDATE_DATA_LOAD,
    request,
});
const setEmployeeTypeUpdateDataSuccess = (employeeType) => ({
    type: EMPLOYEE_TYPE_UPDATE_DATA.EMPLOYEE_TYPE_UPDATE_DATA_SUCCESS,
    employeeType,
});
const setEmployeeTypeUpdateDataFail = (error) => ({
    type: EMPLOYEE_TYPE_UPDATE_DATA.EMPLOYEE_TYPE_UPDATE_DATA_FAIL,
    error,
});

//Delete EmployeeType
const loadEmployeeTypeDeleteDataRequest = (request) => ({
    type: EMPLOYEE_TYPE_DELETE_DATA.EMPLOYEE_TYPE_DELETE_DATA_LOAD,
    request,
});
const setEmployeeTypeDeleteDataSuccess = (employeeType) => ({
    type: EMPLOYEE_TYPE_DELETE_DATA.EMPLOYEE_TYPE_DELETE_DATA_SUCCESS,
    employeeType,
});
const setEmployeeTypeDeleteDataFail = (error) => ({
    type: EMPLOYEE_TYPE_DELETE_DATA.EMPLOYEE_TYPE_DELETE_DATA_FAIL,
    error,
});


export {
    loadEmployeeTypeDataRequest, setEmployeeTypeDataSuccess, setEmployeeTypeDataFail,
    loadEmployeeTypeGetDataRequest, setEmployeeTypeGetDataSuccess, setEmployeeTypeGetDataFail,
    loadEmployeeTypeAddDataRequest, setEmployeeTypeAddDataSuccess, setEmployeeTypeAddDataFail,
    loadEmployeeTypeUpdateDataRequest, setEmployeeTypeUpdateDataSuccess, setEmployeeTypeUpdateDataFail,
    loadEmployeeTypeDeleteDataRequest, setEmployeeTypeDeleteDataSuccess, setEmployeeTypeDeleteDataFail,
}