import {
    HIRE_STATUS_DATA,
    HIRE_STATUS_GET_DATA,
    HIRE_STATUS_ADD_DATA,
    HIRE_STATUS_UPDATE_DATA,
    HIRE_STATUS_DELETE_DATA,
} from './Constants'


//For listing
const loadHireStatusDataRequest = () => ({
    type: HIRE_STATUS_DATA.HIRE_STATUS_DATA_LOAD,
});
const setHireStatusDataSuccess = (hireStatuses) => ({
    type: HIRE_STATUS_DATA.HIRE_STATUS_DATA_SUCCESS,
    hireStatuses,
});
const setHireStatusDataFail = (error) => ({
    type: HIRE_STATUS_DATA.HIRE_STATUS_DATA_FAIL,
    error,
});

//for details
const loadHireStatusGetDataRequest = (id) => ({
    type: HIRE_STATUS_GET_DATA.HIRE_STATUS_GET_DATA_LOAD,
    id,
});
const setHireStatusGetDataSuccess = (response) => ({
    type: HIRE_STATUS_GET_DATA.HIRE_STATUS_GET_DATA_SUCCESS,
    hireStatus: response.hireStatus[0],
});
const setHireStatusGetDataFail = (error) => ({
    type: HIRE_STATUS_GET_DATA.HIRE_STATUS_GET_DATA_FAIL,
    error,
});

//For add 
const loadHireStatusAddDataRequest = (request) => ({
    type: HIRE_STATUS_ADD_DATA.HIRE_STATUS_ADD_DATA_LOAD,
    request,
});
const setHireStatusAddDataSuccess = (hireStatus) => ({
    type: HIRE_STATUS_ADD_DATA.HIRE_STATUS_ADD_DATA_SUCCESS,
    hireStatus,
});
const setHireStatusAddDataFail = (error) => ({
    type: HIRE_STATUS_ADD_DATA.HIRE_STATUS_ADD_DATA_FAIL,
    error,
});

//update 
const loadHireStatusUpdateDataRequest = (request) => ({
    type: HIRE_STATUS_UPDATE_DATA.HIRE_STATUS_UPDATE_DATA_LOAD,
    request,
});
const setHireStatusUpdateDataSuccess = (hireStatus) => ({
    type: HIRE_STATUS_UPDATE_DATA.HIRE_STATUS_UPDATE_DATA_SUCCESS,
    hireStatus,
});
const setHireStatusUpdateDataFail = (error) => ({
    type: HIRE_STATUS_UPDATE_DATA.HIRE_STATUS_UPDATE_DATA_FAIL,
    error,
});

//Delete 
const loadHireStatusDeleteDataRequest = (request) => ({
    type: HIRE_STATUS_DELETE_DATA.HIRE_STATUS_DELETE_DATA_LOAD,
    request,
});
const setHireStatusDeleteDataSuccess = (hireStatus) => ({
    type: HIRE_STATUS_DELETE_DATA.HIRE_STATUS_DELETE_DATA_SUCCESS,
    hireStatus,
});
const setHireStatusDeleteDataFail = (error) => ({
    type: HIRE_STATUS_DELETE_DATA.HIRE_STATUS_DELETE_DATA_FAIL,
    error,
});


export {
    loadHireStatusDataRequest, setHireStatusDataSuccess, setHireStatusDataFail,
    loadHireStatusGetDataRequest, setHireStatusGetDataSuccess, setHireStatusGetDataFail,
    loadHireStatusAddDataRequest, setHireStatusAddDataSuccess, setHireStatusAddDataFail,
    loadHireStatusUpdateDataRequest, setHireStatusUpdateDataSuccess, setHireStatusUpdateDataFail,
    loadHireStatusDeleteDataRequest, setHireStatusDeleteDataSuccess, setHireStatusDeleteDataFail,
}