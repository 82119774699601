import { takeLatest, put, call } from "redux-saga/effects";
import {
    RF_DATA, RF_POST_API,
} from './Constants'
import {
    setRfDataSuccess, setRfDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest } from "../../../shared/components/Service";
import { instance } from "../../../shared/utilites/WvgoApiClient";

// function* getAllRfDataService() {
//     try {
//         const response = yield call(getRequest, RF_GET_API);
//         if (response.status === 200) {
//             const data = response.data;
//             yield put(setRfDataSuccess(data));
//         } else {
//             yield put(addErrorAlert(response.response.data.msg))
//             yield put(setRfDataFail(response.response.data));
//         }
//     } catch (error) {
//         yield put(addErrorAlert("Error retrieving data"))
//         yield put(setRfDataFail(error));
//     }
// }

function* getRFDataService({request}) {
    try {
        // const req = {
        //     "employee_id":"134747",
        //     "report":"rfvisit",
        //     "requsted_by":"135205"
        // };
        const response = yield call(
            async () => {
                try {
                    const response = await instance.post(RF_POST_API, request)
                    return response;
                } catch (error) {
                    return error;
                }
            }
        );
        if (response.status === 200) {
            yield put(setRfDataSuccess(response.data.body));
        } else {
            yield put(setRfDataFail(response.response.data));
        }
    } catch (error) {
        yield put(setRfDataFail(error));
    }
}


export function* watchRFVisit(){
    yield takeLatest(RF_DATA.RF_DATA_LOAD, getRFDataService);
}