//Get
const MODULE_DATA = {
    MODULE_DATA_LOAD: "MODULE_DATA_LOAD",
    MODULE_DATA_SUCCESS: "MODULE_DATA_SUCCESS",
    MODULE_DATA_FAIL: "MODULE_DATA_FAIL",
};
const MODULE_GET_API = "/admin/module";

// Get one by id
const MODULE_GET_DATA = {
    MODULE_GET_DATA_LOAD: "MODULE_GET_DATA_LOAD",
    MODULE_GET_DATA_SUCCESS: "MODULE_GET_DATA_SUCCESS",
    MODULE_GET_DATA_FAIL: "MODULE_GET_DATA_FAIL",
};

const MODULE_GET_DETAIL_API = "/admin/module/";

// Create / Add / Post
const MODULE_ADD_DATA = {
    MODULE_ADD_DATA_LOAD: "MODULE_ADD_DATA_LOAD",
    MODULE_ADD_DATA_SUCCESS: "MODULE_ADD_DATA_SUCCESS",
    MODULE_ADD_DATA_FAIL: "MODULE_ADD_DATA_FAIL",
};

const MODULE_POST_API = "/admin/module";

//Update
const MODULE_UPDATE_DATA = {
  MODULE_UPDATE_DATA_LOAD: "MODULE_UPDATE_DATA_UPLOAD",
  MODULE_UPDATE_DATA_SUCCESS: "MODULE_UPDATE_DATA_SUCCESS",
  MODULE_UPDATE_DATA_FAIL: "MODULE_UPDATE_DATA_FAIL",
};

const MODULE_PUT_API = "/admin/module";

// Delete
const MODULE_DELETE_DATA = {
  MODULE_DELETE_DATA_LOAD: "MODULE_DELETE_DATA_LOAD",
  MODULE_DELETE_DATA_SUCCESS: "MODULE_DELETE_DATA_SUCCESS",
  MODULE_DELETE_DATA_FAIL: "MODULE_DELETE_DATA_FAIL",
};

const MODULE_DELETE_API = "/admin/module";

export{
    MODULE_DATA,
    MODULE_GET_API,
    MODULE_GET_DATA,
    MODULE_GET_DETAIL_API,
    MODULE_ADD_DATA,
    MODULE_POST_API,
    MODULE_UPDATE_DATA,
    MODULE_PUT_API,
    MODULE_DELETE_DATA,
    MODULE_DELETE_API,
}