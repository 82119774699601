const ACQUISITION_DATA = {
  ACQUISITION_DATA_LOAD: "ACQUISITION_DATA_LOAD",
  ACQUISITION_DATA_SUCCESS: "ACQUISITION_DATA_SUCCESS",
  ACQUISITION_DATA_FAIL: "ACQUISITION_DATA_FAIL",
};

const ACQUISITION_GET_API = "/store/acquisition";

//Get
const ACQUISITION_GET_DATA = {
  ACQUISITION_GET_DATA_LOAD: "ACQUISITION_GET_DATA_LOAD",
  ACQUISITION_GET_DATA_SUCCESS: "ACQUISITION_GET_DATA_SUCCESS",
  ACQUISITION_GET_DATA_FAIL: "ACQUISITION_GET_DATA_FAIL",
};

const ACQUISITION_GET_DETAIL_API = "/store/acquisition/";

// Create
const ACQUISITION_ADD_DATA = {
  ACQUISITION_ADD_DATA_LOAD: "ACQUISITION_ADD_DATA_LOAD",
  ACQUISITION_ADD_DATA_SUCCESS: "ACQUISITION_ADD_DATA_SUCCESS",
  ACQUISITION_ADD_DATA_FAIL: "ACQUISITION_ADD_DATA_FAIL",
};

const ACQUISITION_POST_API = "/store/acquisition";

//Update
const ACQUISITION_UPDATE_DATA = {
  ACQUISITION_UPDATE_DATA_LOAD: "ACQUISITION_UPDATE_DATA_LOAD",
  ACQUISITION_UPDATE_DATA_SUCCESS: "ACQUISITION_UPDATE_DATA_SUCCESS",
  ACQUISITION_UPDATE_DATA_FAIL: "ACQUISITION_UPDATE_DATA_FAIL",
};

const ACQUISITION_PUT_API = "/store/acquisition";

//Delete
const ACQUISITION_DELETE_DATA = {
  ACQUISITION_DELETE_DATA_LOAD: "ACQUISITION_DELETE_DATA_LOAD",
  ACQUISITION_DELETE_DATA_SUCCESS: "ACQUISITION_DELETE_DATA_SUCCESS",
  ACQUISITION_DELETE_DATA_FAIL: "ACQUISITION_DELETE_DATA_FAIL",
};

const ACQUISITION_DELETE_API = "/store/acquisition";

export {
  ACQUISITION_DATA,
  ACQUISITION_GET_API,
  ACQUISITION_GET_DATA,
  ACQUISITION_GET_DETAIL_API,
  ACQUISITION_ADD_DATA,
  ACQUISITION_POST_API,
  ACQUISITION_UPDATE_DATA,
  ACQUISITION_PUT_API,
  ACQUISITION_DELETE_DATA,
  ACQUISITION_DELETE_API,
};
