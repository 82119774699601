import {
    ODOO_DESIGNATION_TRACKER_BY_EMP_DATA,
    ODOO_DESIGNATION_TRACKER_DATA,
    ODOO_DESIGNATION_TRACKER_DETAILS_DATA,
} from "./Constants";
const initialState = {
    loading: false,
    error: null,
    odooDesignationTrackers: [],
    odooDesignationTracker: {},
    odooDesignationTrackerByEmp:[]
};
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case ODOO_DESIGNATION_TRACKER_DATA.ODOO_DESIGNATION_TRACKER_DATA_LOAD:
            return {
                ...state,
                loading: true,
                odooDesignationTrackers: [],
                error: false,
            }
        case ODOO_DESIGNATION_TRACKER_DATA.ODOO_DESIGNATION_TRACKER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                odooDesignationTrackers: action.odooDesignationTrackers,
                error: false,
            }
        //Load one
        case ODOO_DESIGNATION_TRACKER_DETAILS_DATA.ODOO_DESIGNATION_TRACKER_DETAILS_DATA_LOAD:
            return {
                ...state,
                loading: true,
                odooDesignationTracker: {},
                error: null,
            };
        case ODOO_DESIGNATION_TRACKER_DETAILS_DATA.ODOO_DESIGNATION_TRACKER_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                loading: true,
                odooDesignationTracker: action.odooDesignationTracker[0],
            };
        case ODOO_DESIGNATION_TRACKER_DETAILS_DATA.ODOO_DESIGNATION_TRACKER_DETAILS_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        //load list by emp number
        case ODOO_DESIGNATION_TRACKER_BY_EMP_DATA.ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error:null,
            }
        case ODOO_DESIGNATION_TRACKER_BY_EMP_DATA.ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                odooDesignationTrackerByEmp:action.odooDesignationTrackers
            }
        case ODOO_DESIGNATION_TRACKER_BY_EMP_DATA.ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        default:
            return {
                ...state,
            };
    }
};
export default Reducer;
