import React from "react";

const FormatDate = (inputDate) => {

    let dateSplit = inputDate.split("-");
    let needToFormat = dateSplit[2] + "-" + dateSplit[0] + "-" + dateSplit[1]

    const dayjs = require('dayjs')

    let fullDate = dayjs(needToFormat).format('MM/DD/YYYY');
    
    return fullDate;

}

export default FormatDate;