import React from 'react'
import { NavLink } from 'react-router-dom'
import './Breadcrumbs.css'
import { Breadcrumb } from 'react-bootstrap'

const Breadcrumbs = ({ label, linkTo, children, test }) => {
    return (
        <Breadcrumb className="page-breadcrumb d-sm-flex align-items-center breadcrumb-title text-nowrap px-3">
            <Breadcrumb.Item className="breadcrumb-title" href="/dashboard"><i className="fa fa-home"></i></Breadcrumb.Item>
            {children?.map((child, index) => {
                return <Breadcrumb.Item key={index} linkAs={NavLink} linkProps={{ to: child.href }} active={index + 1 == children.length}>{child.label}</Breadcrumb.Item>
            })
            }
        </Breadcrumb>
    )
}

export default Breadcrumbs
