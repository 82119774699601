import {
  ACQUISITION_DATA,
  ACQUISITION_ADD_DATA,
  ACQUISITION_UPDATE_DATA,
  ACQUISITION_GET_DATA,
  ACQUISITION_DELETE_DATA,
} from "./Constants";

const initialState = {
  loading: false,
  error: null,
  acquisitions: [],
  acquisition: {},
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACQUISITION_DATA.ACQUISITION_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        acquisitions: [],
      };
    case ACQUISITION_DATA.ACQUISITION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        acquisitions: action.acquisitions,
        error: null,
      };
    case ACQUISITION_DATA.ACQUISITION_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        acquisitions: [],
      };
    //Add
    case ACQUISITION_ADD_DATA.ACQUISITION_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        acquisition: {},
        error: null,
      };
    case ACQUISITION_ADD_DATA.ACQUISITION_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        acquisition: action.acquisition,
        error: null,
      };
    case ACQUISITION_ADD_DATA.ACQUISITION_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        acquisition: {},
        error: action.error,
      };
    //Get
    case ACQUISITION_GET_DATA.ACQUISITION_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        acquisition: {},
        error: null,
      };
    case ACQUISITION_GET_DATA.ACQUISITION_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        acquisition: action.acquisition,
        error: null,
      };
    case ACQUISITION_GET_DATA.ACQUISITION_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        acquisition: {},
        error: action.error,
      };
    //Update
    case ACQUISITION_UPDATE_DATA.ACQUISITION_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        acquisition: {},
        error: null,
      };
    case ACQUISITION_UPDATE_DATA.ACQUISITION_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        acquisition: action.acquisition,
        error: false,
      };
    case ACQUISITION_UPDATE_DATA.ACQUISITION_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        acquisition: {},
        error: action.error,
      };
    //Delete
    case ACQUISITION_DELETE_DATA.ACQUISITION_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        acquisition: {},
        error: null,
      };
    case ACQUISITION_DELETE_DATA.ACQUISITION_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        acquisition: action.acquisition,
        error: null,
      };
    case ACQUISITION_DELETE_DATA.ACQUISITION_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        acquisition: {},
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
