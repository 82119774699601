import { takeLatest, put, call } from "redux-saga/effects";
import {

    EMPLOYEE_TYPE_DATA, EMPLOYEE_TYPE_GET_API,
    EMPLOYEE_TYPE_GET_DATA, EMPLOYEE_TYPE_GET_DETAIL_API,
    EMPLOYEE_TYPE_ADD_DATA, EMPLOYEE_TYPE_POST_API,
    EMPLOYEE_TYPE_UPDATE_DATA, EMPLOYEE_TYPE_PUT_API,
    EMPLOYEE_TYPE_DELETE_DATA, EMPLOYEE_TYPE_DELETE_API,
} from './Constants'
import {
    setEmployeeTypeDataSuccess, setEmployeeTypeDataFail,
    setEmployeeTypeGetDataSuccess, setEmployeeTypeGetDataFail,
    setEmployeeTypeAddDataSuccess, setEmployeeTypeAddDataFail,
    setEmployeeTypeUpdateDataSuccess, setEmployeeTypeUpdateDataFail,
    setEmployeeTypeDeleteDataSuccess, setEmployeeTypeDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";



function* getEmployeeTypesDataService() {
    try {
        const response = yield call(getRequest, EMPLOYEE_TYPE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setEmployeeTypeDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeTypeDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee types"))
        yield put(setEmployeeTypeDataFail(error));
    }
}

function* getEmployeeTypeDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            EMPLOYEE_TYPE_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setEmployeeTypeGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeTypeGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee type info"))
        yield put(setEmployeeTypeGetDataFail(error));
    }
}

function* postEmployeeTypeDataService({ request }) {
    try {
        // let requestData = {
        //     to_insert:request
        // }
        const response = yield call(postRequest, EMPLOYEE_TYPE_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("EmployeeType created"))
            yield put(setEmployeeTypeAddDataSuccess(newData));
        } else {
            //yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeTypeAddDataFail(response.response.data));
        }
    } catch (error) {
        //yield put(addErrorAlert("Error adding employee type"))
        yield put(setEmployeeTypeAddDataFail(error));
    }
}
function* putEmployeeTypeDataService({ request }) {
    try {
        // let requestData = {
        //     to_id:{
        //         id:request.id
        //     },
        //     to_update:{
        //         ...request
        //     }
        // }
        const response = yield call(putRequest, EMPLOYEE_TYPE_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("EmployeeType updated"))
            yield put(setEmployeeTypeUpdateDataSuccess(response.data));
        } else {
            //yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeTypeUpdateDataFail(response.response.data));
        }
    } catch (error) {
        //yield put(addErrorAlert("Error editing employee type"))
        yield put(setEmployeeTypeUpdateDataFail(error));
    }
}

function* deleteEmployeeTypeDataService({ request }) {
    try {
        const response = yield call(deleteRequest, EMPLOYEE_TYPE_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setEmployeeTypeDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeTypeDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setEmployeeTypeDeleteDataFail(error));
    }
}

export function* watchEmployeeType(){
    yield takeLatest(EMPLOYEE_TYPE_DATA.EMPLOYEE_TYPE_DATA_LOAD, getEmployeeTypesDataService);
    yield takeLatest(EMPLOYEE_TYPE_GET_DATA.EMPLOYEE_TYPE_GET_DATA_LOAD, getEmployeeTypeDataService);
    yield takeLatest(EMPLOYEE_TYPE_ADD_DATA.EMPLOYEE_TYPE_ADD_DATA_LOAD, postEmployeeTypeDataService);
    yield takeLatest(EMPLOYEE_TYPE_UPDATE_DATA.EMPLOYEE_TYPE_UPDATE_DATA_LOAD, putEmployeeTypeDataService);
    yield takeLatest(EMPLOYEE_TYPE_DELETE_DATA.EMPLOYEE_TYPE_DELETE_DATA_LOAD, deleteEmployeeTypeDataService);  
}