import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { loadRfDataRequest } from "../redux/Actions"

const RetailFoundation = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let { rf_Visits, loading } = useSelector((state) => state.rfVisits);

  const [empId, setEmpId] = useState();

  const handleReviews = () => {
    dispatch(loadRfDataRequest({
      "employee_id":empId,
      "report":"rfvisit",
      "requsted_by":JSON.parse(localStorage.getItem('emp_no'))
    }));
  }

  let columns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true,
    },
    {
      display_name: "Employee",
      field: "employee",
      type: "text",
    },
    {
      display_name: "Store Name",
      field: "name",
      type: "text",
    },
    {
      display_name: "Review By",
      field: "review_by",
      type: "text",
    },
    {
      display_name:"PDF Link",
      field: "pdf_link",
      type:"hyperlink",
      is_workflow_link:true,
      redirect_value:"link",
      width: 90
    },
    {
      display_name: "Submitted On",
      field: "submit_date",
      type: "text",
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <Breadcrumbs children={[{ label: "Retail Foundation Reviews" }]} />
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Employee ID"
            value={empId}
            onChange={(e) => setEmpId(e.target.value)}
          />
        </Col>
        <Col>
          <button type="button" class="btn btn-primary mb-3" onClick={handleReviews}>
            Submit
          </button>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        {rf_Visits.data !== undefined ? (
        <Card>
          <Card.Body>
            <ListView
              exportTitle="Retail Foundation"
              pdfBtnUrl={"https://wvservicetest.wirelessvision.com/AllDsomVisits/viewAllPdf?employee="+empId+"&ids="}
              columnNames={columns}
              rowData={rf_Visits?.data}
            />
          </Card.Body>
        </Card>
        ) : (
          <Card>
            <Card.Body>
              <center>
                {rf_Visits}
                {/* rf_Visits.length === 0 ? "No Data to Show" :  */}
              </center>
            </Card.Body>
          </Card> 
        )}
      </Row>
    </>
  );
};

export default RetailFoundation;
