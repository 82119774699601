import {
    GROUP_JOBTITLE_DATA,   
    GROUP_JOBTITLE_GET_DATA,
  } from "./Constants";
  
  const initialState = {
    loading: false,
    error: null,
    groupjobtitles: [],
    groupjobtitle: {},
  };
  
  const Reducer = (state = initialState, action) => {
    console.log("action", action)
    switch (action.type) {
      case GROUP_JOBTITLE_DATA.GROUP_JOBTITLE_DATA_LOAD:
        console.log("GROUP_JOBTITLE_DATA_LOAD case inside");
        return {
          ...state,
          loading: true,
          error: null,
          groupjobtitles: [],
        };
      case GROUP_JOBTITLE_DATA.GROUP_JOBTITLE_DATA_SUCCESS:
        console.log("GROUP_JOBTITLE_DATA_SUCCESS case inside");
        return {
          ...state,
          loading: false,
          groupjobtitles: action.groupjobtitles,
          error: null,
        };
      case GROUP_JOBTITLE_DATA.GROUP_JOBTITLE_DATA_FAIL:
        console.log("GROUP_JOBTITLE_DATA_FAIL case inside");
        return {
          ...state,
          loading: false,
          error: action.error,
          groupjobtitles: [],
        };

      
      //Get
      case GROUP_JOBTITLE_GET_DATA.GROUP_JOBTITLE_GET_DATA_LOAD:
        return {
          ...state,
          loading: true,
          groupjobtitle: {},
          error: null,
        };
      case GROUP_JOBTITLE_GET_DATA.GROUP_JOBTITLE_GET_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          groupjobtitle: action.groupjobtitle,
          error: null,
        };
      case GROUP_JOBTITLE_GET_DATA.GROUP_JOBTITLE_GET_DATA_FAIL:
        return {
          ...state,
          loading: false,
          groupjobtitle: {},
          error: action.error,
        };
          
      default:
        return {
          ...state,
        };
    }
  };
  
  export default Reducer;
  