import {
    DEPARTMENT_DATA,
    DEPARTMENT_GET_DATA,
    DEPARTMENT_ADD_DATA,
    DEPARTMENT_UPDATE_DATA,
    DEPARTMENT_DELETE_DATA,
    DEPARTMENT_IMPORT_DATA,
} from './Constants'


//For listing
const loadDepartmentDataRequest = () => ({
    type: DEPARTMENT_DATA.DEPARTMENT_DATA_LOAD,
});
const setDepartmentDataSuccess = (departments) => ({
    type: DEPARTMENT_DATA.DEPARTMENT_DATA_SUCCESS,
    departments,
});
const setDepartmentDataFail = (error) => ({
    type: DEPARTMENT_DATA.DEPARTMENT_DATA_FAIL,
    error,
});

//for details
const loadDepartmentGetDataRequest = (id) => ({
    type: DEPARTMENT_GET_DATA.DEPARTMENT_GET_DATA_LOAD,
    id,
});
const setDepartmentGetDataSuccess = (response) => ({
    type: DEPARTMENT_GET_DATA.DEPARTMENT_GET_DATA_SUCCESS,
    department: response.department[0],
    department_class_tracker: response.department_class_tracker
});
const setDepartmentGetDataFail = (error) => ({
    type: DEPARTMENT_GET_DATA.DEPARTMENT_GET_DATA_FAIL,
    error,
});

//For add Department
const loadDepartmentAddDataRequest = (request) => ({
    type: DEPARTMENT_ADD_DATA.DEPARTMENT_ADD_DATA_LOAD,
    request,
});
const setDepartmentAddDataSuccess = (department) => ({
    type: DEPARTMENT_ADD_DATA.DEPARTMENT_ADD_DATA_SUCCESS,
    department,
});
const setDepartmentAddDataFail = (error) => ({
    type: DEPARTMENT_ADD_DATA.DEPARTMENT_ADD_DATA_FAIL,
    error,
});

//update department
const loadDepartmentUpdateDataRequest = (request) => ({
    type: DEPARTMENT_UPDATE_DATA.DEPARTMENT_UPDATE_DATA_LOAD,
    request,
});
const setDepartmentUpdateDataSuccess = (department) => ({
    type: DEPARTMENT_UPDATE_DATA.DEPARTMENT_UPDATE_DATA_SUCCESS,
    department,
});
const setDepartmentUpdateDataFail = (error) => ({
    type: DEPARTMENT_UPDATE_DATA.DEPARTMENT_UPDATE_DATA_FAIL,
    error,
});

//Delete Department
const loadDepartmentDeleteDataRequest = (request) => ({
    type: DEPARTMENT_DELETE_DATA.DEPARTMENT_DELETE_DATA_LOAD,
    request,
});
const setDepartmentDeleteDataSuccess = (department) => ({
    type: DEPARTMENT_DELETE_DATA.DEPARTMENT_DELETE_DATA_SUCCESS,
    department,
});
const setDepartmentDeleteDataFail = (error) => ({
    type: DEPARTMENT_DELETE_DATA.DEPARTMENT_DELETE_DATA_FAIL,
    error,
});

//Import Department
const loadDepartmentImportDataRequest = (request) => ({
    type: DEPARTMENT_IMPORT_DATA.DEPARTMENT_IMPORT_DATA_LOAD,
    request,
});
const setDepartmentImportDataSuccess = (departments) => ({
    type: DEPARTMENT_IMPORT_DATA.DEPARTMENT_IMPORT_DATA_SUCCESS,
    departments,
});
const setDepartmentImportDataFail = (error) => ({
    type: DEPARTMENT_IMPORT_DATA.DEPARTMENT_IMPORT_DATA_FAIL,
    error,
});

export {
    loadDepartmentDataRequest, setDepartmentDataSuccess, setDepartmentDataFail,
    loadDepartmentGetDataRequest, setDepartmentGetDataSuccess, setDepartmentGetDataFail,
    loadDepartmentAddDataRequest, setDepartmentAddDataSuccess, setDepartmentAddDataFail,
    loadDepartmentUpdateDataRequest, setDepartmentUpdateDataSuccess, setDepartmentUpdateDataFail,
    loadDepartmentDeleteDataRequest, setDepartmentDeleteDataSuccess, setDepartmentDeleteDataFail,
    loadDepartmentImportDataRequest, setDepartmentImportDataSuccess, setDepartmentImportDataFail
}