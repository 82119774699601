import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import { loadRegionDataRequest } from "../../region/redux/Actions";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { CANCEL, DISTRICT_NAME, END_DATE, REGION, SAVE, STARTAFTEREND, STARTNOTFUTURE, START_DATE, ULTI_DISTRICT_CODE  } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormatDate from "../../../shared/components/FormatDate";

const MarketForm = ({ market, submit, cancel, isNew = false }) => {

    const params = useParams();
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (!values.market_name) {
            errors.market_name = 'Required';
        }
        if (!values.region_id || values.region_id === -1 || values.region_id === '') {
            errors.region_id = 'Required';
        }
        if (new Date(values.start_date) >= new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        if (new Date() >= new Date(values.start_date)){
            // errors.start_date = STARTNOTFUTURE
        }
        return errors;
    };

    let { regions } = useSelector(store => store.region)
    useEffect(() => {
        if (regions.length === 0) {
            dispatch(loadRegionDataRequest());
        }
    }, [dispatch, regions.length])


    const formik = useFormik({});



    useEffect(() => {
        if (market) {
            formik.current.setFieldValue('id', market?.id);
            formik.current.setFieldValue('market_name', market?.market_name);
            formik.current.setFieldValue('region_id', market?.region_id);
            formik.current.setFieldValue('ulti_market_code', market?.ulti_market_code);
            if (market.start_date && market.end_date) {
                if (market?.start_date.includes("-") && market?.end_date.includes("-")) {
                    formik.current.setFieldValue('start_date', FormatDate(market?.start_date)) 
                    formik.current.setFieldValue('end_date', FormatDate(market?.end_date))
                }
            }

        }
    }, [market, formik])

    return <Formik
        innerRef={formik}
        initialValues={{
            id: params.id,
            market_name: '',
            ulti_market_code: '',
            region_id: '',
            start_date: '',
            end_date: '12/31/2099'
        }}
        validate={validate}
        onSubmit={submit}
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="market_name" className="form-label">
                        {DISTRICT_NAME} <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='market_name' autoComplete="off" />
                    <span className="error-text">
                        <ErrorMessage name="market_name" />
                    </span>
                </div>
                <div className="col-md-6">
                    <label htmlFor="ulti_market_code" className="form-label">
                        {ULTI_DISTRICT_CODE}
                    </label>
                    <Field className="form-control" type='text' name='ulti_market_code' autoComplete="off" />
                    <span className="error-text">
                        <ErrorMessage name="ulti_market_code" />
                    </span>
                </div>
                <div className="col-md-6">
                    <label g="form-label">
                        {REGION} <span className="error-text">*</span>
                    </label>
                    <FormikSelectField
                        name="region_id" 
                        value={values.region_id} 
                        onChange={setFieldValue}
                        placeholder="Select Region"
                        disabled={!isNew}
                    >
                        {regions.filter(region => region.active === 0 || region.active === 2).map((region) => {
                            return <option key={region.id} value={region.id}>{region.region_name}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name='region_id' />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">{START_DATE} <span className="error-text">*</span></label>
                    <FormikDatePickerField readOnly={!isNew} name="start_date" value={values.start_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                    <span className='error-text'>
                        <ErrorMessage name="start_date" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">{END_DATE} <span className="error-text">*</span></label>
                    <FormikDatePickerField readOnly={!isNew} name="end_date" value={values.end_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                    <span className='error-text'>
                        <ErrorMessage name="end_date" />
                    </span>
                </div>
                <Col md={12}>
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik >
}

export default MarketForm