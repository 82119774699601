const COMP_GROUP_DATA = {
    COMP_GROUP_DATA_LOAD: "COMP_GROUP_DATA_LOAD",
    COMP_GROUP_DATA_SUCCESS: "COMP_GROUP_DATA_SUCCESS",
    COMP_GROUP_DATA_FAIL: "COMP_GROUP_DATA_FAIL",
  };
  
  const COMP_GROUP_GET_API = "/store/comm_group";
  
  //Get
  const COMP_GROUP_GET_DATA = {
    COMP_GROUP_GET_DATA_LOAD: "COMP_GROUP_GET_DATA_LOAD",
    COMP_GROUP_GET_DATA_SUCCESS: "COMP_GROUP_GET_DATA_SUCCESS",
    COMP_GROUP_GET_DATA_FAIL: "COMP_GROUP_GET_DATA_FAIL",
  };
  
  const COMP_GROUP_GET_DETAIL_API = "/store/comm_group/";
  
  // Create
  const COMP_GROUP_ADD_DATA = {
    COMP_GROUP_ADD_DATA_LOAD: "COMP_GROUP_ADD_DATA_LOAD",
    COMP_GROUP_ADD_DATA_SUCCESS: "COMP_GROUP_ADD_DATA_SUCCESS",
    COMP_GROUP_ADD_DATA_FAIL: "COMP_GROUP_ADD_DATA_FAIL",
  };
  
  const COMP_GROUP_POST_API = "/store/comm_group";
  
  //Update
  const COMP_GROUP_UPDATE_DATA = {
    COMP_GROUP_UPDATE_DATA_LOAD: "COMP_GROUP_UPDATE_DATA_LOAD",
    COMP_GROUP_UPDATE_DATA_SUCCESS: "COMP_GROUP_UPDATE_DATA_SUCCESS",
    COMP_GROUP_UPDATE_DATA_FAIL: "COMP_GROUP_UPDATE_DATA_FAIL",
  };
  
  const COMP_GROUP_PUT_API = "/store/comm_group";
  
  //Delete
  const COMP_GROUP_DELETE_DATA = {
    COMP_GROUP_DELETE_DATA_LOAD: "COMP_GROUP_DELETE_DATA_LOAD",
    COMP_GROUP_DELETE_DATA_SUCCESS: "COMP_GROUP_DELETE_DATA_SUCCESS",
    COMP_GROUP_DELETE_DATA_FAIL: "COMP_GROUP_DELETE_DATA_FAIL",
  };
  
  const COMP_GROUP_DELETE_API = "/store/comm_group";
  
  export {
    COMP_GROUP_DATA,
    COMP_GROUP_GET_API,
    COMP_GROUP_GET_DATA,
    COMP_GROUP_GET_DETAIL_API,
    COMP_GROUP_ADD_DATA,
    COMP_GROUP_POST_API,
    COMP_GROUP_UPDATE_DATA,
    COMP_GROUP_PUT_API,
    COMP_GROUP_DELETE_DATA,
    COMP_GROUP_DELETE_API,
  };
  