import { takeLatest, put, call } from "redux-saga/effects";
import {
    PT_DATA, PT_GET_API,
    PT_GET_DATA, PT_GET_DETAIL_API,
    PT_ADD_DATA, PT_POST_API,
    PT_UPDATE_DATA, PT_PUT_API,
    PT_DELETE_DATA, PT_DELETE_API,
    PTYPE_DATA, PTYPE_GET_API,
    ATYPE_DATA, ATYPE_GET_API,
    LTYPE_DATA, LTYPE_GET_API,
} from './Constants'
import {
    setPTDataSuccess, setPTDataFail,
    setPTGetDataSuccess, setPTGetDataFail,
    setPTAddDataSuccess, setPTAddDataFail,
    setPTUpdateDataSuccess, setPTUpdateDataFail,
    setPTDeleteDataSuccess, setPTDeleteDataFail,
    setPTypeDataSuccess, setPTypeDataFail,
    setATypeDataSuccess, setATypeDataFail,
    setLTypeDataSuccess, setLTypeDataFail
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../../shared/components/Service";


function* getPTDataService() {
    try {
        const response = yield call(getRequest, PT_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setPTDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setPTDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving data"))
        yield put(setPTDataFail(error));
    }
}

function* getProductTableDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            PT_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setPTGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setPTGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving data info"))
        yield put(setPTGetDataFail(error));
    }
}

function* postPTDataService({ request, url }) {
    try {
        const response = yield call(postRequest, url, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Data created"))
            yield put(setPTAddDataSuccess(newData));
        } else {
            yield put(setPTAddDataFail(response.response.data));
        }
    } catch (error) {
        yield put(setPTAddDataFail(error));
    }
}
function* putPTDataService({ request, url }) {
    try {
        const response = yield call(putRequest, url, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Data updated"))
            yield put(setPTUpdateDataSuccess(response.data));
        } else {
            yield put(setPTUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(setPTUpdateDataFail(error));
    }
}

function* deletePTDataService({ request }) {
    try {
        const response = yield call(deleteRequest, PT_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setPTDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setPTDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setPTDeleteDataFail(error));
    }
}

function* getPTypeDataService() {
    try {
        const response = yield call(getRequest, PTYPE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setPTypeDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setPTypeDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving data"))
        yield put(setPTypeDataFail(error));
    }
}

function* getATypeDataService() {
    try {
        const response = yield call(getRequest, ATYPE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setATypeDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setATypeDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving data"))
        yield put(setATypeDataFail(error));
    }
}

function* getLTypeDataService() {
    try {
        const response = yield call(getRequest, LTYPE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setLTypeDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setLTypeDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving data"))
        yield put(setLTypeDataFail(error));
    }
}


export function* watchProductTables(){
    yield takeLatest(PT_DATA.PT_DATA_LOAD, getPTDataService);
    yield takeLatest(PT_GET_DATA.PT_GET_DATA_LOAD, getProductTableDataService);
    yield takeLatest(PT_ADD_DATA.PT_ADD_DATA_LOAD, postPTDataService);
    yield takeLatest(PT_UPDATE_DATA.PT_UPDATE_DATA_LOAD, putPTDataService);
    yield takeLatest(PT_DELETE_DATA.PT_DELETE_DATA_LOAD, deletePTDataService); 
    yield takeLatest(PTYPE_DATA.PTYPE_DATA_LOAD, getPTypeDataService);
    yield takeLatest(ATYPE_DATA.ATYPE_DATA_LOAD, getATypeDataService);
    yield takeLatest(LTYPE_DATA.LTYPE_DATA_LOAD, getLTypeDataService);
}