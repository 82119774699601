//Get
const EMPLOYEE_TYPE_DATA = {
    EMPLOYEE_TYPE_DATA_LOAD: "EMPLOYEE_TYPE_DATA_LOAD",
    EMPLOYEE_TYPE_DATA_SUCCESS: "EMPLOYEE_TYPE_DATA_SUCCESS",
    EMPLOYEE_TYPE_DATA_FAIL: "EMPLOYEE_TYPE_DATA_FAIL",
};
const EMPLOYEE_TYPE_GET_API = "/employee/v1/employeeType";

// Get one by id
const EMPLOYEE_TYPE_GET_DATA = {
    EMPLOYEE_TYPE_GET_DATA_LOAD: "EMPLOYEE_TYPE_GET_DATA_LOAD",
    EMPLOYEE_TYPE_GET_DATA_SUCCESS: "EMPLOYEE_TYPE_GET_DATA_SUCCESS",
    EMPLOYEE_TYPE_GET_DATA_FAIL: "EMPLOYEE_TYPE_GET_DATA_FAIL",
};

const EMPLOYEE_TYPE_GET_DETAIL_API = "/employee/v1/employeeType/";

// Create / Add / Post
const EMPLOYEE_TYPE_ADD_DATA = {
    EMPLOYEE_TYPE_ADD_DATA_LOAD: "EMPLOYEE_TYPE_ADD_DATA_LOAD",
    EMPLOYEE_TYPE_ADD_DATA_SUCCESS: "EMPLOYEE_TYPE_ADD_DATA_SUCCESS",
    EMPLOYEE_TYPE_ADD_DATA_FAIL: "EMPLOYEE_TYPE_ADD_DATA_FAIL",
};

const EMPLOYEE_TYPE_POST_API = "/employee/v1/employeeType";

//Update
const EMPLOYEE_TYPE_UPDATE_DATA = {
  EMPLOYEE_TYPE_UPDATE_DATA_LOAD: "EMPLOYEE_TYPE_UPDATE_DATA_UPLOAD",
  EMPLOYEE_TYPE_UPDATE_DATA_SUCCESS: "EMPLOYEE_TYPE_UPDATE_DATA_SUCCESS",
  EMPLOYEE_TYPE_UPDATE_DATA_FAIL: "EMPLOYEE_TYPE_UPDATE_DATA_FAIL",
};

const EMPLOYEE_TYPE_PUT_API = "/employee/v1/employeeType";

// Delete
const EMPLOYEE_TYPE_DELETE_DATA = {
  EMPLOYEE_TYPE_DELETE_DATA_LOAD: "EMPLOYEE_TYPE_DELETE_DATA_LOAD",
  EMPLOYEE_TYPE_DELETE_DATA_SUCCESS: "EMPLOYEE_TYPE_DELETE_DATA_SUCCESS",
  EMPLOYEE_TYPE_DELETE_DATA_FAIL: "EMPLOYEE_TYPE_DELETE_DATA_FAIL",
};

const EMPLOYEE_TYPE_DELETE_API = "/employee/v1/employeeType";


export{
    EMPLOYEE_TYPE_DATA,
    EMPLOYEE_TYPE_GET_API,
    EMPLOYEE_TYPE_GET_DATA,
    EMPLOYEE_TYPE_GET_DETAIL_API,
    EMPLOYEE_TYPE_ADD_DATA,
    EMPLOYEE_TYPE_POST_API,
    EMPLOYEE_TYPE_UPDATE_DATA,
    EMPLOYEE_TYPE_PUT_API,
    EMPLOYEE_TYPE_DELETE_DATA,
    EMPLOYEE_TYPE_DELETE_API,
}