import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadDepartmentAddDataRequest } from "../redux/Actions";
import DepartmentForm from "./DepartmentForm";

const DepartmentAdd = ({ handleClose }) => {
  const dispatch = useDispatch();

  let { loading, error } = useSelector((state) => state.department);
  let [shouldCloseModal, setShouldCloseModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState(null);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true)
      } else {
        console.log(error)
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false)
    }
  }, [loading, error, shouldCloseModal])


  function submit(values) {
    dispatch(loadDepartmentAddDataRequest(values));
    setShouldCloseModal(true)
  }
  function cancel() {
    handleClose(false);
  }
  return <>
    <FormError>
      {errorMessage}
    </FormError>
    <DepartmentForm submit={submit} cancel={cancel} />;
  </>
};

export default DepartmentAdd;
