import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadEmployeeTypeAddDataRequest } from "../redux/Actions";
import EmployeeTypeForm from "./EmployeeTypeForm";

const EmployeeTypeAdd = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [shouldCloseModal, setShouldCloseModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  let { loading, error } = useSelector((state) => state.employeeType);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true)
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false)
    }
  }, [loading, error, shouldCloseModal])

  function submit(values) {
    delete values.id
    dispatch(loadEmployeeTypeAddDataRequest(values));
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return (
    <>
      <FormError>
        {errorMessage}
      </FormError>
      <EmployeeTypeForm submit={submit} cancel={cancel} />;
    </>
  );
};

export default EmployeeTypeAdd;
