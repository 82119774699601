import { HIRE_STATUS_DATA, HIRE_STATUS_GET_DATA, HIRE_STATUS_ADD_DATA, HIRE_STATUS_UPDATE_DATA, HIRE_STATUS_DELETE_DATA } from './Constants'
const initialState = {
    loading: false,
    error: null,
    hireStatuses: [],
    hireStatus:{}
}
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case HIRE_STATUS_DATA.HIRE_STATUS_DATA_LOAD:
            return {
                ...state,
                loading: true,
                hireStatuses:[],
                error:null
            }
        case HIRE_STATUS_DATA.HIRE_STATUS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                hireStatuses:action.hireStatuses
            }
        case HIRE_STATUS_DATA.HIRE_STATUS_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
            }
        //load single
        case HIRE_STATUS_GET_DATA.HIRE_STATUS_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                hireStatus:{},
                error:null
            }                

        case HIRE_STATUS_GET_DATA.HIRE_STATUS_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                hireStatus:action.hireStatus
            }                

        case HIRE_STATUS_GET_DATA.HIRE_STATUS_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case HIRE_STATUS_ADD_DATA.HIRE_STATUS_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case HIRE_STATUS_ADD_DATA.HIRE_STATUS_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case HIRE_STATUS_ADD_DATA.HIRE_STATUS_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case HIRE_STATUS_UPDATE_DATA.HIRE_STATUS_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case HIRE_STATUS_UPDATE_DATA.HIRE_STATUS_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case HIRE_STATUS_UPDATE_DATA.HIRE_STATUS_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case HIRE_STATUS_DELETE_DATA.HIRE_STATUS_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case HIRE_STATUS_DELETE_DATA.HIRE_STATUS_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case HIRE_STATUS_DELETE_DATA.HIRE_STATUS_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        default:
            return {
                ...state
            }
    }
}
export default Reducer