import { Navigate } from "react-router-dom"

export default function Logout() {
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('jwt_time')
    localStorage.removeItem('employee');
    localStorage.removeItem('permission_count');
    localStorage.removeItem('emp_no');
    localStorage.removeItem('admin_employee');
    localStorage.removeItem('admin_emp_no');
    return <Navigate to='/login'/>
}