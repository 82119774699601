import { takeLatest, put, call } from "redux-saga/effects";
import {

    LEAVE_TYPE_DATA, LEAVE_TYPE_GET_API,
    LEAVE_TYPE_GET_DATA, LEAVE_TYPE_GET_DETAIL_API,
    LEAVE_TYPE_ADD_DATA, LEAVE_TYPE_POST_API,
    LEAVE_TYPE_UPDATE_DATA, LEAVE_TYPE_PUT_API,
    LEAVE_TYPE_DELETE_DATA, LEAVE_TYPE_DELETE_API,
} from './Constants'
import {
    setLeaveTypeDataSuccess, setLeaveTypeDataFail,
    setLeaveTypeGetDataSuccess, setLeaveTypeGetDataFail,
    setLeaveTypeAddDataSuccess, setLeaveTypeAddDataFail,
    setLeaveTypeUpdateDataSuccess, setLeaveTypeUpdateDataFail,
    setLeaveTypeDeleteDataSuccess, setLeaveTypeDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";



function* getLeaveTypesDataService() {
    try {
        const response = yield call(getRequest, LEAVE_TYPE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setLeaveTypeDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setLeaveTypeDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving leave types"))
        yield put(setLeaveTypeDataFail(error));
    }
}

function* getLeaveTypeDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            LEAVE_TYPE_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setLeaveTypeGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setLeaveTypeGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving leave type info"))
        yield put(setLeaveTypeGetDataFail(error));
    }
}

function* postLeaveTypeDataService({ request }) {
    try {
        // let requestData = {
        //     to_insert:request
        // }
        const response = yield call(postRequest, LEAVE_TYPE_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("LeaveType created"))
            yield put(setLeaveTypeAddDataSuccess(newData));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setLeaveTypeAddDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error adding leave type"))
        yield put(setLeaveTypeAddDataFail(error));
    }
}
function* putLeaveTypeDataService({ request }) {
    try {
        // let requestData = {
        //     to_id:{
        //         id:request.id
        //     },
        //     to_update:{
        //         ...request
        //     }
        // }
        const response = yield call(putRequest, LEAVE_TYPE_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("LeaveType updated"))
            yield put(setLeaveTypeUpdateDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setLeaveTypeUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error editing leave type"))
        yield put(setLeaveTypeUpdateDataFail(error));
    }
}

function* deleteLeaveTypeDataService({ request }) {
    try {
        const response = yield call(deleteRequest, LEAVE_TYPE_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setLeaveTypeDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setLeaveTypeDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setLeaveTypeDeleteDataFail(error));
    }
}

export function* watchLeaveType(){
    yield takeLatest(LEAVE_TYPE_DATA.LEAVE_TYPE_DATA_LOAD, getLeaveTypesDataService);
    yield takeLatest(LEAVE_TYPE_GET_DATA.LEAVE_TYPE_GET_DATA_LOAD, getLeaveTypeDataService);
    yield takeLatest(LEAVE_TYPE_ADD_DATA.LEAVE_TYPE_ADD_DATA_LOAD, postLeaveTypeDataService);
    yield takeLatest(LEAVE_TYPE_UPDATE_DATA.LEAVE_TYPE_UPDATE_DATA_LOAD, putLeaveTypeDataService);
    yield takeLatest(LEAVE_TYPE_DELETE_DATA.LEAVE_TYPE_DELETE_DATA_LOAD, deleteLeaveTypeDataService);  
}