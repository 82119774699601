import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { BACKTOLIST, EMPLOYEES, STORES } from "../../../shared/constants/Constants";
import EmployeeFormAdd from "./EmployeeFormAdd";
import { loadEmployeeAddDataRequest } from "../redux/Actions";
import { useDispatch, useSelector } from "react-redux";

const EmployeeAdd = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let [submitted, setSubmitted] = useState(false);

    let { loading } = useSelector(state => state.employee)

    function submit(values) {
        dispatch(loadEmployeeAddDataRequest(values));
        setSubmitted(true);
    }

    if (submitted && !loading) {
        setSubmitted(false)
        navigate('/employees')
    }

    return (
        <>
            <Row>
                <Col xl={4} xs={12}>
                    <Breadcrumbs
                        children={[
                            { href: '/Employees', label: EMPLOYEES },
                            { label: 'Create' },
                        ]}
                    />
                </Col>
                <Col xl={8} xs={12}>
                    <div className="float-end">
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={BACKTOLIST}
                            actionBlock={() => {
                                navigate("/employees");
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <EmployeeFormAdd submit={submit} />

        </>
    );
};

export default EmployeeAdd;
