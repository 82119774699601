import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadRolesUpdateDataRequest } from "../redux/Actions";
import RolesForm from "./RolesForm";

const RolesEdit = ({ handleClose, roles }) => {
  const dispatch = useDispatch();
  let [submitted, setSubmitted] = useState(false);
  let [isClosing, setIsClosing] = useState(false);

  let { loading, error } = useSelector((state) => state.roles);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (submitted && !loading && !isClosing) {
        setIsClosing(true);
        handleClose(true)
    }
}, [loading, dispatch, error, handleClose, isClosing, submitted])

  function submit(values) {
    dispatch(loadRolesUpdateDataRequest(values));
    setSubmitted(true);
  }
  function cancel() {
    handleClose(false);
  }
  return <RolesForm submit={submit} cancel={cancel} roles={roles} />;
};

export default RolesEdit;
