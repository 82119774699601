import { LOGIN_DATA, ROLE_ACCESS_DATA, SET_LOGGED_OUT, TOKEN_DATA } from "./Constants";

//For add
const loadLoginDataRequest = (request) => ({
  type: LOGIN_DATA.LOGIN_DATA_LOAD,
  request,
});
const setLoginDataSuccess = (login) => ({
  type: LOGIN_DATA.LOGIN_DATA_SUCCESS,
  login,
});

const setLoginDataFail = (error) => ({
  type: LOGIN_DATA.LOGIN_DATA_FAIL,
  error,
});

const setLoginRedirect = (error) => ({
  type: LOGIN_DATA.LOGIN_DATA_REDIRECT,
  error,
});

const loadRoleAccessGetDataRequest = (employee_id) => ({
  type: ROLE_ACCESS_DATA.ROLE_ACCESS_DATA_LOAD,
  employee_id,
});
const setRoleAccessGetDataSuccess = (roleAccess) => ({
  type: ROLE_ACCESS_DATA.ROLE_ACCESS_DATA_SUCCESS,
  roleAccess,
});
const setRoleAccessGetDataFail = (error) => ({
  type: ROLE_ACCESS_DATA.ROLE_ACCESS_DATA_FAIL,
  error,
});

const setLoggedOut = () =>({
  type: SET_LOGGED_OUT
})

// //For Token
// const loadTokenDataRequest = (request) => ({
//   type: TOKEN_DATA.TOKEN_DATA_LOAD,
//   request,
// });
// const setTokenDataSuccess = (token) => ({
//   type: TOKEN_DATA.TOKEN_DATA_SUCCESS,
//   token,
// });

// const setTokenDataFail = (error) => ({
//   type: TOKEN_DATA.TOKEN_DATA_FAIL,
//   error,
// });

export {
  loadLoginDataRequest,
  setLoginDataSuccess,
  setLoginDataFail,
  setLoginRedirect,
  loadRoleAccessGetDataRequest,
  setRoleAccessGetDataSuccess,
  setRoleAccessGetDataFail,
  setLoggedOut,
  // loadTokenDataRequest,
  // setTokenDataSuccess,
  // setTokenDataFail
};
