// Get
const DIVISION_DATA = {
    DIVISION_DATA_LOAD:'DIVISION_DATA_LOAD',
    DIVISION_DATA_SUCCESS:'DIVISION_DATA_SUCCESS',
    DIVISION_DATA_FAIL:'DIVISION_DATA_FAIL'
}

const DIVISION_GET_API = '/division'

// Create / Add / Post
const DIVISION_ADD_DATA = {
    DIVISION_ADD_DATA_LOAD:'DIVISION_ADD_DATA_LOAD',
    DIVISION_ADD_DATA_SUCCESS:'DIVISION_ADD_DATA_SUCCESS', 
    DIVISION_ADD_DATA_FAIL:'DIVISION_ADD_DATA_FAIL'
}

const DIVISION_POST_API = '/division'

// Update / Put
const DIVISION_UPDATE_DATA = {
    DIVISION_UPDATE_DATA_LOAD:'DIVISION_UPDATE_DATA_LOAD',
    DIVISION_UPDATE_DATA_SUCCESS:'DIVISION_UPDATE_DATA_SUCCESS', 
    DIVISION_UPDATE_DATA_FAIL:'DIVISION_UPDATE_DATA_FAIL'
}

const DIVISION_PUT_API = '/division'

// Get one by id
const DIVISION_GET_DATA = {
    DIVISION_GET_DATA_LOAD:'DIVISION_GET_DATA_LOAD',
    DIVISION_GET_DATA_SUCCESS:'DIVISION_GET_DATA_SUCCESS', 
    DIVISION_GET_DATA_FAIL:'DIVISION_GET_DATA_FAIL'
}

const DIVISION_GET_DETAIL_API = '/division/'

// Delete
const DIVISION_DELETE_DATA={
    DIVISION_DELETE_DATA_LOAD:'DIVISION_DELETE_DATA_LOAD',
    DIVISION_DELETE_DATA_SUCCESS:'DIVISION_DELETE_DATA_SUCCESS',
    DIVISION_DELETE_DATA_FAIL:'DIVISION_DELETE_DATA_FAIL'
}

const DIVISION_DELETE_API = '/division'


//Import Division
const DIVISION_IMPORT_DATA = {
    DIVISION_IMPORT_DATA_LOAD:'DIVISION_IMPORT_DATA_LOAD',
    DIVISION_IMPORT_DATA_SUCCESS:'DIVISION_IMPORT_DATA_SUCCESS', 
    DIVISION_IMPORT_DATA_FAIL:'DIVISION_IMPORT_DATA_FAIL'
}

const DIVISION_IMPORT_API = '/upload'

export {
    DIVISION_DATA,
    DIVISION_GET_API,
    DIVISION_ADD_DATA,
    DIVISION_POST_API,
    DIVISION_UPDATE_DATA,
    DIVISION_PUT_API,
    DIVISION_GET_DATA,
    DIVISION_GET_DETAIL_API,
    DIVISION_DELETE_DATA,
    DIVISION_DELETE_API,
    DIVISION_IMPORT_DATA,
    DIVISION_IMPORT_API
}

