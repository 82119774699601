import { takeLatest, put, call } from "redux-saga/effects";
import {
  LOGIN_DATA,
  LOGIN_POST_API,
  ROLE_ACCESS_DATA,
  ROLE_ACCESS_GET_API,
  TOKEN_DATA,
  TOKEN_POST_API
} from "./Constants";
import {
  setLoginDataSuccess,
  setLoginDataFail,
  setLoginRedirect,
  setRoleAccessGetDataSuccess,
  setRoleAccessGetDataFail,
  setTokenDataSuccess,
  setTokenDataFail
} from "./Actions";
import {
  loginPostRequest,
  getDetailRequest
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";


function* postLoginDataService({ request }) {
  try {
    const response = yield call(
      loginPostRequest,
      LOGIN_POST_API,
      request
    );
    const status = response.status;
    if (status === 201) {
      const newData = response.data;
      localStorage.setItem("jwt_token", newData.token);
      localStorage.setItem("jwt_time", JSON.stringify(Date.now()));
      localStorage.setItem("employee", JSON.stringify(newData));
      localStorage.setItem("emp_no", JSON.stringify(newData.employee_id));
      // localStorage.setItem("permission_count", "false");
      yield put(setLoginDataSuccess(newData));
    } else {
      if((response.response.data.msg).hasOwnProperty("errorCode") && response.response.data.msg?.errorCode == '101' ) {
        //yield put(addErrorAlert(response.response.data.msg)) // yield put(navigateTo('/reset'));
        yield put(setLoginRedirect(response.response.data));
      } else  {
        yield put(addErrorAlert(response.response.data.msg))
      }
      yield put(setLoginDataFail(response.response.data.msg));
    }
  } catch (error) {
    yield put(addErrorAlert("Authentication failed"))
    yield put(setLoginDataFail(error));
  }
}

function* getRoleAccessDataService({ employee_id }) {
  try {
    const response = yield call(
      getDetailRequest,
      ROLE_ACCESS_GET_API + employee_id
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data[0];
      localStorage.setItem("permissions", newData.data[0]);
      if (newData.length === 0) {
        localStorage.setItem("permission_count", "false");
      } else {
        localStorage.setItem("permission_count", "true");
      }
      yield put(setRoleAccessGetDataSuccess(newData.data[0]));
    } else {
      yield put(setRoleAccessGetDataFail(response.response.msg));
    }
  } catch (error) {
    yield put(setRoleAccessGetDataFail(error));
  }
}

// function* postLoginDataService({ request }) {
//   try {
//     const response = yield call(
//       loginPostRequest,
//       LOGIN_POST_API,
//       request
//     );
//     const status = response.data.statusCode;
//     if (status === 200) {
//       const newData = response.data.body[0];
//       localStorage.setItem("employee", JSON.stringify(newData));
//       yield put(setLoginDataSuccess(newData));
//     } else {
//       yield put(addErrorAlert(response.data.body))
//       yield put(setLoginDataFail(response.data.body));
//     }
//   } catch (error) {
//     yield put(addErrorAlert("Authentication failed"))
//     yield put(setLoginDataFail(error));
//   }
// }

// function* postTokenDataService({ request }) {
//   try {
//     const response = yield call(
//       postRequest,
//       TOKEN_POST_API,
//       request
//     );
//     const status = response.status;
//     if (status === 200) {
//       const newData = response.data;
//       console.log("token saga", response);
//       yield put(setTokenDataSuccess(newData));
//     } else {
//       yield put(addErrorAlert(response.response.data.msg))
//       yield put(setTokenDataFail(response.response.data.msg));
//     }
//   } catch (error) {
//     yield put(addErrorAlert("Authentication failed, Unable to generate token"))
//     yield put(setTokenDataFail(error));
//   }
// }


export function* watchLogin() {
  yield takeLatest(
    LOGIN_DATA.LOGIN_DATA_LOAD,
    postLoginDataService
  );
  yield takeLatest(
    ROLE_ACCESS_DATA.ROLE_ACCESS_DATA_LOAD,
    getRoleAccessDataService
  );
  // yield takeLatest(
  //   TOKEN_DATA.TOKEN_DATA_LOAD,
  //   postTokenDataService
  // );
  
}
