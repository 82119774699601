//Get
const HOURLY_SALARY_DATA = {
    HOURLY_SALARY_DATA_LOAD: "HOURLY_SALARY_DATA_LOAD",
    HOURLY_SALARY_DATA_SUCCESS: "HOURLY_SALARY_DATA_SUCCESS",
    HOURLY_SALARY_DATA_FAIL: "HOURLY_SALARY_DATA_FAIL",
};
const HOURLY_SALARY_GET_API = "/employee/v1/hourlySalaryStatus";

// Get one by id
const HOURLY_SALARY_GET_DATA = {
    HOURLY_SALARY_GET_DATA_LOAD: "HOURLY_SALARY_GET_DATA_LOAD",
    HOURLY_SALARY_GET_DATA_SUCCESS: "HOURLY_SALARY_GET_DATA_SUCCESS",
    HOURLY_SALARY_GET_DATA_FAIL: "HOURLY_SALARY_GET_DATA_FAIL",
};

const HOURLY_SALARY_GET_DETAIL_API = "/employee/v1/hourlySalaryStatus/";

// Create / Add / Post
const HOURLY_SALARY_ADD_DATA = {
    HOURLY_SALARY_ADD_DATA_LOAD: "HOURLY_SALARY_ADD_DATA_LOAD",
    HOURLY_SALARY_ADD_DATA_SUCCESS: "HOURLY_SALARY_ADD_DATA_SUCCESS",
    HOURLY_SALARY_ADD_DATA_FAIL: "HOURLY_SALARY_ADD_DATA_FAIL",
};

const HOURLY_SALARY_POST_API = "/employee/v1/hourlySalaryStatus";

//Update
const HOURLY_SALARY_UPDATE_DATA = {
  HOURLY_SALARY_UPDATE_DATA_LOAD: "HOURLY_SALARY_UPDATE_DATA_UPLOAD",
  HOURLY_SALARY_UPDATE_DATA_SUCCESS: "HOURLY_SALARY_UPDATE_DATA_SUCCESS",
  HOURLY_SALARY_UPDATE_DATA_FAIL: "HOURLY_SALARY_UPDATE_DATA_FAIL",
};

const HOURLY_SALARY_PUT_API = "/employee/v1/hourlySalaryStatus";

// Delete
const HOURLY_SALARY_DELETE_DATA = {
  HOURLY_SALARY_DELETE_DATA_LOAD: "HOURLY_SALARY_DELETE_DATA_LOAD",
  HOURLY_SALARY_DELETE_DATA_SUCCESS: "HOURLY_SALARY_DELETE_DATA_SUCCESS",
  HOURLY_SALARY_DELETE_DATA_FAIL: "HOURLY_SALARY_DELETE_DATA_FAIL",
};

const HOURLY_SALARY_DELETE_API = "/employee/v1/hourlySalaryStatus";


export{
    HOURLY_SALARY_DATA,
    HOURLY_SALARY_GET_API,
    HOURLY_SALARY_GET_DATA,
    HOURLY_SALARY_GET_DETAIL_API,
    HOURLY_SALARY_ADD_DATA,
    HOURLY_SALARY_POST_API,
    HOURLY_SALARY_UPDATE_DATA,
    HOURLY_SALARY_PUT_API,
    HOURLY_SALARY_DELETE_DATA,
    HOURLY_SALARY_DELETE_API,
}