import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultButton from "./DefaultButton";

export function PrevNextButtons({ currentId, basePath, name }) {
    let navigate = useNavigate();
    let [previous, setPrevious] = useState(null);
    let [next, setNext] = useState(null);

    let {ids} = useSelector(store => store.nextPrev)

    useEffect(() => {
        if (ids[name]) {
            let index = ids[name].findIndex((element) => {
                return Number(element) === Number(currentId)
            })
            setPrevious(ids[name][index - 1])
            setNext(ids[name][index + 1])
        }
    }, [ids, currentId])

    return <>
            <DefaultButton
                buttonClass={"default-btn-color me-1"}
                buttonTitle={<i className="fas fa-arrow-left"></i>}
                actionBlock={() => navigate(basePath + previous)}
                disabled = {!(previous)}
            />
            <DefaultButton
                buttonClass={"default-btn-color me-1"}
                buttonTitle={<i className="fas fa-arrow-right"></i>}
                actionBlock={() => navigate(basePath + next)}
                disabled = {!(next)}
            />
        </>
}