const initialState = {
    tableFilters: {}
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "TABLE_FILTER_ADD":
            let newFilters = { ...state.tableFilters };
            newFilters[action.name] = action.filter
            return { ...state, 
                tableFilters: newFilters};
        default:
            return {
                ...state
            }
    }
}

export default Reducer;