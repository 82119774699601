import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ListView from "../../../shared/components/listview/ListView"
import Modalpopup from "../../../shared/components/Modalpopup"
import { loadMarketDataRequest } from "../../market/redux/Actions"
import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import DefaultButton from "../../../shared/components/DefaultButton"
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField"
import { loadSapTrackerAddDataRequest, loadSapTrackerDeleteDataRequest, loadSapTrackerUpdateDataRequest } from "../redux/Actions"
import { DELETE, CANCEL, SAVE, STARTAFTEREND, STARTNOTFUTURE } from "../../../shared/constants/Constants"
import FormikSelectField from "../../../shared/components/FormikSelectField"
import Permissions from "../../../shared/components/Permissions"
import FormError from "../../../shared/components/FormError"
import FormatDate from "../../../shared/components/FormatDate"


const SAPTracker = ({ sap_tracker, store, setShouldReload, companyId }) => {
    const dispatch = useDispatch();

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "SAP Tracker";
    let permission = Permissions(emp_no, module_name);

    let actionsPermission = !(permission.write_permission || permission.delete_permission);

    const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        // {
        //     display_name: "SAP",
        //     field: "sap_id",
        //     type: "text",
        // },
        {
            display_name: "District",
            field: "market_id",
            type: "text",
        },
        {
            display_name: "Start Date",
            field: "start_date",
            type: "date",
            sort: "desc"
        },
        {
            display_name: "End Date",
            field: "end_date",
            type: "date",
        },
        {
            display_name: 'Actions',
            field: 'actions',
            hide: actionsPermission
        }
    ]
    let { markets } = useSelector(store => store.market);
    let { loading, error } = useSelector(store => store.store);

    useEffect(() => {
        dispatch(loadMarketDataRequest());
    }, [dispatch])

    let [trackerData, setTrackerData] = useState([])
    useEffect(() => {
        let newData = []
        if (sap_tracker) {

            sap_tracker.forEach(element => {
                let newMarket = markets.find(market => market.id == element.market_id)
                newData.push({
                    ...element,
                    market_id: newMarket?.market_name,
                    sap_id: store?.sap
                })
            });
            setTrackerData(newData)
        }
    }, [sap_tracker, store, markets]);

    let [showEditModal, setShowEditModal] = useState(false)
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [showAddModal, setShowAddModal] = useState(false);
    let [selectedTracker, setSelectedTracker] = useState({});

    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddModal();
                closeEditModal();
                setShouldReload(true)
            } else {
                setErrorMessage(error.msg);
            }
            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])

    const closeAddModal = () => {
        setShowAddModal(false);
        setErrorMessage(null);
    }
    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }

    const handleEdit = (values) => {
        dispatch(loadSapTrackerUpdateDataRequest({
            ...values,
            id: selectedTracker.id,
        }))
        setShouldCloseModal(true);
    }
    const handleAdd = (values) => {
        dispatch(loadSapTrackerAddDataRequest(
            {
                ...values,
            }
        ));
        setShouldCloseModal(true);
    }
    const handleDelete = () => {
        setShowDeleteModal(false);

        dispatch(loadSapTrackerDeleteDataRequest({
            id: [selectedTracker],
            sap_id: store?.id,
        }))
        setShouldReload(true)
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedTracker(row);
                    setShowEditModal(true);
                })
        }
        if (permission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedTracker(id);
                    setShowDeleteModal(true);
                })
        }
    }, [permission])
    return <>
        {permission.create_permission ? (
            <Button style={{ width: 115 }} className="btn btn-secondary default-btn-color float-end py-1" onClick={() => { setShowAddModal(true) }}>Add Tracker</Button>
        ) : (<></>)}
        <ListView
            exportTitle={'sap tracker'}
            columnNames={columnDefs}
            rowData={trackerData}
            clickEdit={clickEditFunc}
            clickDelete={clickDeleteFunc}
            tableHeight = '40vh'
        />
        <Modalpopup show={showEditModal} actionTitle="Edit Tracker" handleClose={closeEditModal} >
            <FormError>
                {errorMessage}
            </FormError>
            <SAPTrackerForm tracker={selectedTracker} submit={handleEdit} cancel={closeEditModal} store={store} companyId={companyId} />
        </Modalpopup>
        <Modalpopup show={showDeleteModal} actionTitle="Delete Tracker" handleClose={() => { setShowDeleteModal(false) }}>
            Are you sure you want to delete tracker?
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>
        <Modalpopup show={showAddModal} actionTitle="Add Tracker" handleClose={closeAddModal}>
            <FormError>
                {errorMessage}
            </FormError>
            <SAPTrackerForm submit={handleAdd} cancel={closeAddModal} store={store} companyId={companyId} />
        </Modalpopup>
    </>
}
export default SAPTracker

const SAPTrackerForm = ({ tracker, submit, cancel, store, companyId = 0 }) => {
    const dispatch = useDispatch()
    const validate = (values) => {
        const errors = {};
        if (values.sap_id === -1 || values.sap_id === '') {
            errors.sap_id = 'Required';
        }
        if (values.market_id === -1 || values.market_id === '') {
            errors.market_id = 'Required';
        }
        if (!values.start_date || values.start_date === '') {
            errors.start_date = "Required";
        }
        if (!values.end_date || values.end_date === '') {
            errors.end_date = "Required";
        }
        if (new Date(values.start_date) >= new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        if (new Date() >= new Date(values.start_date)) {
            // errors.start_date = STARTNOTFUTURE
        }
        return errors;
    };

    let { markets } = useSelector(state => state.market)

    useEffect(() => {
        dispatch(loadMarketDataRequest())
    }, [])

    let [marketData, setMarketData] = useState([])
    useEffect(() => {
        setMarketData([...markets].filter(
            (market) => {
                return true //show all markets.
                // if (!market.active) {  // this filter is backwards. Active = 0,  inactive = 1, future = 2
                //     console.log(market.market_name)
                //     return false;
                // }

                if (!companyId || companyId === 0) {
                    return true
                }
                if (market.market_name == 'Desert East') {
                    console.log(market.company_id, companyId)
                }
                return market.company_id === companyId

            }
        ))
    }, [companyId, markets])

    let trackStartDate = '';
    let trackEndDate = '12/31/2099';
    if (tracker && tracker?.start_date && tracker?.end_date) {
        if (tracker?.start_date.includes("-") && tracker?.end_date.includes("-")) {
            trackStartDate = FormatDate(tracker?.start_date);
            trackEndDate = FormatDate(tracker?.end_date); 
        }
    }

    return <Formik
        initialValues={{
            market_id: markets.find((market) => market.market_name == tracker?.market_id)?.id ?? '',
            sap_id: store?.id ?? '',
            start_date: trackStartDate,
            end_date: trackEndDate,
        }}
        validate={validate}
        onSubmit={submit}
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="region_name" className="form-label">
                        SAP
                    </label>
                    {/* <Field diabled={true} className="form-control" type='text' name='sap' readOnly={true} autoComplete="off" /> */}
                    <Field className="form-control" type='text' name='store_name' readOnly={true} autoComplete="off" defaultValue={store?.store_name + ", " + store?.sap} />
                    <Field type="hidden" name="sap" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputMDCode" className="form-label">
                        District <span className="error-text">*</span>
                    </label>
                    <FormikSelectField
                        name="market_id"
                        placeholder={"Select District"}
                        onChange={setFieldValue}
                        value={values.market_id}
                    >
                        {marketData.filter(market => market.active === 0 || market.active === 1 || market.active === 2).map((market) => {
                            return <option key={market.id} value={market.id}>{market.market_name}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name='market_id' />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        Start Date <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="start_date" value={values.start_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose Start Date"} readOnly={false} />
                    <span className='error-text'>
                        <ErrorMessage name="start_date" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        End Date <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="end_date" value={values.end_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose End Date"} readOnly={false} />
                    <span className='error-text'>
                        <ErrorMessage name="end_date" />
                    </span>
                </div>
                <div className="col-md-12">
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"btn btn-secondary default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </div>
            </Form>
        )}
    </Formik>
}