import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { ROLES } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CREATENEW } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadRolesDataRequest, loadRolesDeleteDataRequest } from "../redux/Actions";
import RolesAdd from "./RolesAdd";
import RolesEdit from "./RolesEdit";
import DeleteModal from "../../../shared/components/DeleteModal";

const Roles = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let { roles, loading } = useSelector((state) => state.roles);
    let [selectedRow, setSelectedRow] = useState({})
    const [shouldReload, setShouldReload] = useState(true)

    useEffect(() => {
        if (!loading && shouldReload) {
            setShouldReload(false)
            dispatch(loadRolesDataRequest());
        }
    }, [dispatch, loading, shouldReload]);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: "Role Name",
            field: "role_name",
            type: "text",
        },
        // {
        //     display_name: "",
        //     field: 'actions',
        //     type: 'actions'
        // }
    ];

    const clickEdit = (row) => {
        setSelectedRow(row)
        setShowEditModal(true)
    }
    const clickDelete = (id) => {
        setShowDeleteModal(true)
        setSelectedRow({ id });
    }
    const handleDelete = () => {
        dispatch(loadRolesDeleteDataRequest({ id: [selectedRow.id] }))
        setShouldReload(true)
        setShowDeleteModal(false);
    }

    return (
        <>
            <Row>
                <Col>
                    <Breadcrumbs
                        children={[
                            { label: ROLES },
                        ]}
                    />
                </Col>
                {/* <Col>
                    <div className="float-end">
                        <DefaultButton
                            buttonClass="btn btn-primary default-btn-color"
                            buttonTitle={CREATENEW}
                            actionBlock={() => setShowCreateModal(true)}
                        />
                    </div> 
                </Col> */}
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={ROLES}
                            columnNames={columns}
                            rowData={roles}
                            clickEdit={clickEdit}
                            clickDelete={clickDelete}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={showCreateModal}
                handleClose={() => setShowCreateModal(false)}
                actionTitle={"Create " + ROLES}
            >
                <RolesAdd
                    handleClose={(reload) => {
                        setShowCreateModal(false);
                        if (reload) {
                            dispatch(loadRolesDataRequest());
                        }
                    }}
                />
            </Modalpopup>
            <Modalpopup
                show={showEditModal}
                handleClose={() => { setShowEditModal(false) }}
                actionTitle={"Edit " + ROLES}
            >
                <RolesEdit
                    roles={selectedRow}
                    handleClose={(reload) => {
                        if (reload) {
                            dispatch(loadRolesDataRequest());
                        }
                        setShowEditModal(false)
                    }}
                />
            </Modalpopup>
            <DeleteModal
                handleClose={() => { setShowDeleteModal(false) }}
                handleDelete={handleDelete}
                show={showDeleteModal}
            >
                <div>
                    Delete {ROLES}?
                </div>
            </DeleteModal>
        </>
    );
};

export default Roles;
