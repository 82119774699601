import React from 'react'
import { ErrorMessage, Field, Formik, Form } from "formik";
import DefaultButton from "../DefaultButton";
import { CANCEL, SAVE } from "../../constants/Constants";
import FormikSelectField from '../FormikSelectField';
import KeyTagModuleList from './KeyTagModuleList';

const KeyTagValueForm = ({ keyValue, submit, cancel, keyvalues }) => {

    let keytags = KeyTagModuleList(keyValue.resource_id)
    const validate = (values) => {
        const errors = {};
        if (!values.key_value) {
            errors.key_value = "Required";
        }
        return errors;
    };

    return (
        <Formik
            initialValues={{
                id: keyValue?.id ?? null,
                key_tag_id: keyValue?.key_tag_id ?? null,
                key_value: keyValue?.key_value ?? "",
                resource_id: keyValue?.resource_id ?? null,
                resource_value: keyValue?.resource_value ?? ""
            }}
            validate={validate}
            onSubmit={submit}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form className="row g-4">
                    <div className="col-md-6" style={{ display: "none" }}>
                        <label htmlFor="resource_value" className="form-label">
                            Resource Value <span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="resource_value"
                            placeholder="Enter Resource Value"
                            autoComplete="off"
                            disabled={true}
                            defaultValue={keyValue?.resource_value ?? ""}
                        />
                        <span className="error-text">
                            <ErrorMessage name="resource_value" />
                        </span>
                    </div>
                    <FormikSelectField
                        name="key_tag_id"
                        placeholder={"Select Attribute Name"}
                        onChange={setFieldValue}
                        value={values.key_tag_id}
                        label="Attribute Name"
                    >
                        {keytags.filter((keytag) =>
                            !keyvalues.some((element) =>
                                element.key_tag_id == keytag.id && keytag.id != keyValue.key_tag_id
                            )
                        ).map((keytag) => {
                            return <option key={keytag.id} value={keytag.id}>{keytag.key_name}</option>
                        })}
                    </FormikSelectField>
                    {createValueInput(keytags.find((value) => value.id == values.key_tag_id)?.key_input_type, setFieldValue, values, keytags)}
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default KeyTagValueForm;

function createValueInput(type, setFieldValue, values, keytags) {
    switch (type) {
        case 'freetext':
            return <div className="col-md-6">
                <label htmlFor="key_value" className="form-label">
                    Attribute Value<span className="error-text">*</span>
                </label>
                <Field
                    className="form-control"
                    type="text"
                    name="key_value"
                    placeholder="Enter Attribute Value"
                    autoComplete="off"
                />
                <span className="error-text">
                    <ErrorMessage name="key_value" />
                </span>
            </div>
        case 'multiple':
            return <FormikSelectField
                name="key_value"
                placeholder={"Select Attribute Value"}
                onChange={setFieldValue}
                value={values.key_value}
                label="Attribute Value"
            >
                {keytags.find(value => value.id == values.key_tag_id)?.key_predefined_value?.split(',')?.map((value) => {
                    return <option key={value} value={value}>{value}</option>
                })}
            </FormikSelectField>
        default :
            return <div className="col-md-6">
                        <label htmlFor="key_value" className="form-label">
                            Attribute Value<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="key_value"
                            placeholder="Enter Attribute Value"
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="key_value" />
                        </span>
                    </div>
    }
}