import {
    KEYTAG_DATA,
    KEYTAG_GET_DATA,
    KEYTAG_ADD_DATA,
    KEYTAG_UPDATE_DATA,
    KEYTAG_DELETE_DATA,
    KEYVALUE_GET,
    KEYTAGMODULE_GET_DATA,
    VALUE_RESOURCE_GET,
    KEYVALUE_ADD,
    KEYVALUE_UPDATE,
    KEYVALUE_DELETE,
    KEYVALUE_IMPORT_DATA
} from './Constants'


//For listing
const loadKeyTagDataRequest = () => ({
    type: KEYTAG_DATA.KEYTAG_DATA_LOAD,
});
const setKeyTagDataSuccess = (keytags) => ({
    type: KEYTAG_DATA.KEYTAG_DATA_SUCCESS,
    keytags,
});
const setKeyTagDataFail = (error) => ({
    type: KEYTAG_DATA.KEYTAG_DATA_FAIL,
    error,
});

//for details
const loadKeyTagGetDataRequest = (id) => ({
    type: KEYTAG_GET_DATA.KEYTAG_GET_DATA_LOAD,
    id,
});
const setKeyTagGetDataSuccess = (response) => ({
    type: KEYTAG_GET_DATA.KEYTAG_GET_DATA_SUCCESS,
    keytags: response.keytags[0],
});
const setKeyTagGetDataFail = (error) => ({
    type: KEYTAG_GET_DATA.KEYTAG_GET_DATA_FAIL,
    error,
});

//For add KeyTag
const loadKeyTagAddDataRequest = (request) => ({
    type: KEYTAG_ADD_DATA.KEYTAG_ADD_DATA_LOAD,
    request,
});
const setKeyTagAddDataSuccess = (keytags) => ({
    type: KEYTAG_ADD_DATA.KEYTAG_ADD_DATA_SUCCESS,
    keytags,
});
const setKeyTagAddDataFail = (error) => ({
    type: KEYTAG_ADD_DATA.KEYTAG_ADD_DATA_FAIL,
    error,
});

//update KeyTag
const loadKeyTagUpdateDataRequest = (request) => ({
    type: KEYTAG_UPDATE_DATA.KEYTAG_UPDATE_DATA_LOAD,
    request,
});
const setKeyTagUpdateDataSuccess = (keytags) => ({
    type: KEYTAG_UPDATE_DATA.KEYTAG_UPDATE_DATA_SUCCESS,
    keytags,
});
const setKeyTagUpdateDataFail = (error) => ({
    type: KEYTAG_UPDATE_DATA.KEYTAG_UPDATE_DATA_FAIL,
    error,
});

//Delete KeyTag
const loadKeyTagDeleteDataRequest = (request) => ({
    type: KEYTAG_DELETE_DATA.KEYTAG_DELETE_DATA_LOAD,
    request,
});
const setKeyTagDeleteDataSuccess = (keytags) => ({
    type: KEYTAG_DELETE_DATA.KEYTAG_DELETE_DATA_SUCCESS,
    keytags,
});
const setKeyTagDeleteDataFail = (error) => ({
    type: KEYTAG_DELETE_DATA.KEYTAG_DELETE_DATA_FAIL,
    error,
});

//Get Keytag Module
const loadKeyTagModuleGetDataRequest = (id) => ({
    type: KEYTAGMODULE_GET_DATA.KEYTAGMODULE_GET_DATA_LOAD,
    id,
});
const setKeyTagModuleGetDataSuccess = (keyModules) => ({
    type: KEYTAGMODULE_GET_DATA.KEYTAGMODULE_GET_SUCCESS,
    keyModules,
});
const setKeyTagModuleGetDataFail = (error) => ({
    type: KEYTAGMODULE_GET_DATA.KEYTAGMODULE_GET_FAIL,
    error,
});

//GET key value
const loadKeyValueDataRequest = () => ({
    type: KEYVALUE_GET.KEYVALUE_GET_DATA_LOAD,
});
const setKeyValueDataSuccess = (keyvalues) => ({
    type: KEYVALUE_GET.KEYVALUE_GET_DATA_SUCCESS,
    keyvalues,
});
const setKeyValueDataFail = (error) => ({
    type: KEYVALUE_GET.KEYVALUE_GET_DATA_FAIL,
    error,
});

const loadValueGetDataRequest = (id) => ({
    type: VALUE_RESOURCE_GET.VALUE_RESOURCE_GET_DATA_LOAD,
    id,
});
const setValueGetDataSuccess = (keyvalues) => ({
    type: VALUE_RESOURCE_GET.VALUE_RESOURCE_GET_DATA_SUCCESS,
    keyvalues
});
const setValueGetDataFail = (error) => ({
    type: VALUE_RESOURCE_GET.VALUE_RESOURCE_GET_DATA_FAIL,
    error,
});

//For add Key Value
const loadKeyValueAddDataRequest = (request) => ({
    type: KEYVALUE_ADD.KEYVALUE_ADD_DATA_LOAD,
    request,
});
const setKeyValueAddDataSuccess = (keytags) => ({
    type: KEYVALUE_ADD.KEYVALUE_ADD_DATA_SUCCESS,
    keytags,
});
const setKeyValueAddDataFail = (error) => ({
    type: KEYVALUE_ADD.KEYVALUE_ADD_DATA_FAIL,
    error,
});

//update Key Value
const loadKeyValueUpdateDataRequest = (request) => ({
    type: KEYVALUE_UPDATE.KEYVALUE_UPDATE_DATA_LOAD,
    request,
});
const setKeyValueUpdateDataSuccess = (keytags) => ({
    type: KEYVALUE_UPDATE.KEYVALUE_UPDATE_DATA_SUCCESS,
    keytags,
});
const setKeyValueUpdateDataFail = (error) => ({
    type: KEYVALUE_UPDATE.KEYVALUE_UPDATE_DATA_FAIL,
    error,
});

//Delete Key Value
const loadKeyValueDeleteDataRequest = (request) => ({
    type: KEYVALUE_DELETE.KEYVALUE_DELETE_DATA_LOAD,
    request,
});
const setKeyValueDeleteDataSuccess = (keytags) => ({
    type: KEYVALUE_DELETE.KEYVALUE_DELETE_DATA_SUCCESS,
    keytags,
});
const setKeyValueDeleteDataFail = (error) => ({
    type: KEYVALUE_DELETE.KEYVALUE_DELETE_DATA_FAIL,
    error,
});

//Import
const loadKeyValueImportDataRequest = (request) => ({
    type: KEYVALUE_IMPORT_DATA.KEYVALUE_IMPORT_DATA_LOAD,
    request,
});

const setKeyValueImportDataSuccess = (keytags) => ({
    type: KEYVALUE_IMPORT_DATA.KEYVALUE_IMPORT_DATA_SUCCESS,
    keytags,
});

const setKeyValueImportDataFail = (error) => ({
    type: KEYVALUE_IMPORT_DATA.KEYVALUE_IMPORT_DATA_FAIL,
    error,
});

export {
    loadKeyTagDataRequest, setKeyTagDataSuccess, setKeyTagDataFail,
    loadKeyTagGetDataRequest, setKeyTagGetDataSuccess, setKeyTagGetDataFail,
    loadKeyTagAddDataRequest, setKeyTagAddDataSuccess, setKeyTagAddDataFail,
    loadKeyTagUpdateDataRequest, setKeyTagUpdateDataSuccess, setKeyTagUpdateDataFail,
    loadKeyTagDeleteDataRequest, setKeyTagDeleteDataSuccess, setKeyTagDeleteDataFail,
    loadKeyValueDataRequest, setKeyValueDataSuccess, setKeyValueDataFail,
    loadKeyTagModuleGetDataRequest, setKeyTagModuleGetDataSuccess, setKeyTagModuleGetDataFail,
    loadValueGetDataRequest, setValueGetDataSuccess, setValueGetDataFail,
    loadKeyValueAddDataRequest, setKeyValueAddDataSuccess, setKeyValueAddDataFail,
    loadKeyValueUpdateDataRequest, setKeyValueUpdateDataSuccess, setKeyValueUpdateDataFail,
    loadKeyValueDeleteDataRequest, setKeyValueDeleteDataSuccess, setKeyValueDeleteDataFail,
    loadKeyValueImportDataRequest, setKeyValueImportDataSuccess, setKeyValueImportDataFail
}