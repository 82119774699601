import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import ListView from "../../../shared/components/listview/ListView";
import { STORES, CREATENEW } from "../../../shared/constants/Constants";
import {
  loadStoreDataRequest,
  loadStoreImportDataRequest,
} from "../redux/Actions";
import { loadFormAllDataRequest, loadFormImportDataRequest} from "../../../containers/dynamic_form/redux/Actions"
import { loadCompanyDataRequest } from '../../company/redux/Actions'
import ImportModal from "../../../shared/components/ImportModal";
import Permissions from "../../../shared/components/Permissions";

const Stores = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { loading, stores } = useSelector((state) => state.store);
  let { companies } = useSelector((state) => state.company);
  let [selectedCompanyId, setSelectedCompanyId] = useState(JSON.parse(localStorage.getItem("selectedId")));
  let [storeData, setStoreData] = useState([]);
  let [query] = useSearchParams();
  useEffect(() => {
      if (query.get('filter') == 'none') {
          setSelectedCompanyId(0)
      } 
  }, [])

  // let [storeProcessedData,setStoreProcessedData] = useState([]);
  // useEffect(()=>{
  //   for (let store of stores){
  //     // console.log("active stores::", store.active);
  //     // store.active = store.active ? 1 : 0
  //   }
  //   setStoreProcessedData(stores);
  // },[stores])

  let storesColumns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true,
      pinned:'left',
    },
    {
      display_name: "SAP",
      field: "sap",
      type: "text",
      width: 90,
      pinned:'left'
    },
    {
      display_name: "Store Name",
      field: "store_name",
      type: "text",
      pinned:'left'
    },
    {
      display_name: "Manager",
      field: "manager",
      type: "text"
    },
    {
      display_name: "Covering",
      field: "covering_store",
      type: "icon",
      icon: "checkbox",
      width: 100,
    },
    {
      display_name: "T Mobile Name",
      field: "tmob_name",
      type: "text",
      width: 250,
    },
    {
      display_name: "Sprint SAP",
      field: "sprint_sap",
      type: "text",
      width: 105
    },
    {
      display_name: "Density Class",
      field: "density_class_name",
      type: "text",
      width: 125
    },
    {
      display_name: "Store Classification",
      field: "store_classification_name",
      type: "text",
      width: 160
    },
    {
      display_name: "Channel Name",
      field: "channel_name",
      type: "text",
      width: 130
    },
    {
      display_name: "Acquisition Dealer",
      field: "acquisition_dealer_name",
      type: "text",
      width: 160
    },
    {
      display_name: "Sub Dealer Id",
      field: "sub_dealer_id",
      type: "text",
      width: 145
    },
    {
      display_name: "Comm Comp Group",
      field: "commission_comp_group_name",
      type: "text",
      width: 170
    },
    {
      display_name: "Phone",
      field: "phone",
      type: "text",
    },
    {
      display_name: "Address",
      field: "address_street",
      type: "text",
    },
    {
      display_name: "City",
      field: "city",
      type: "text",
    },
    {
      display_name: "County",
      field: "county",
      type: "text",
      width: 165
    },
    {
      display_name: "State",
      field: "state",
      type: "text",
      width: 90
    },
    {
      display_name: "Zip",
      field: "zip",
      type: "text",
      width: 115
    },
    {
      display_name: "Acquisition Date",
      field: "acquisition_date",
      type: "date",
      width: 150
    },
    {
      display_name: "Open Date",
      field: "open_date",
      type: "date",
      width: 115
    },
    {
      display_name: "Closed Date",
      field: "closed_date",
      type: "date",
      width: 115
    },
    {
      display_name: "Temp Closed Date",
      field: "temp_closed_date",
      type: "date",
      width: 155
    },
    {
      display_name: "Relocation Date",
      field: "relocation_date",
      type: "date",
      width: 150
    },
    {
      display_name: "District",
      field: "market",
      type: "text",
    },
    {
      display_name: "Region",
      field: "region_name",
      type: "text",
    },
    {
      display_name: "Division",
      field: "division_name",
      type: "text",
      width: 115
    },
    {
      display_name: "Website",
      field: "website",
      type: "text",
    },
    {
      display_name: "Comments",
      field: "comments",
      type: "text",
    },
    {
      display_name: "Active",
      field: "active",
      type: 'active',
      width: 115
    }
  ];


  useEffect(() => {
    dispatch(loadStoreDataRequest());
    dispatch(loadCompanyDataRequest())
  }, [dispatch]);

  let [showImportModal, setShowImportModal] = useState(false);
  const [importStatus, setImportStatus] = useState(false);

  let [showFormImportModal, setShowFormImportModal] = useState(false);
  const [formImportStatus, setFormImportStatus] = useState(false);
  let { allforms,  error} = useSelector((state) => state.forms);
  let [isFormupload, setIsFormupload] = useState(false);
  let [dynamicForm, setDynamicForm] = useState([]);

  useEffect(() => {
    if (importStatus && !loading) {
      setShowImportModal(false);
      setImportStatus(false);
      setIsFormupload(false);
      dispatch(loadStoreDataRequest());
    }
  }, [importStatus, loading, dispatch])

  useEffect(() => {
    console.log("loading loadFormGetDataRequest")
      dispatch(loadFormAllDataRequest());      
  }, [dispatch]);

  useEffect(() => {
    if (formImportStatus) {
      setShowFormImportModal(false);
      setFormImportStatus(false);
      dispatch(setShowFormImportModal());
    }
  }, [formImportStatus, loading, dispatch])

  useEffect(() => {
    let newStoreData = stores.filter( store => store.sap !== 'B000')
    if (selectedCompanyId !== 0) {
      newStoreData = stores.filter(
        (store) => {
          return store.company_id === selectedCompanyId
        }
      )
    }
    setStoreData(newStoreData)
  }, [selectedCompanyId, stores])

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "Stores";
  let permission = Permissions(emp_no, module_name);
  localStorage.setItem("selectedId", selectedCompanyId)
  console.log("showFormImportModal" , showFormImportModal , "showImportModal" , showImportModal )
  return (
    <>
      <Row>
        <Col xl={4} xs={12}>
          <Breadcrumbs
            children={[
              { label: STORES },
            ]}
          />
        </Col>
        <Col xl={8} xs={12}>
          {permission.create_permission ? (
            <div className="float-end">
              <DefaultButton
                buttonClass={"default-btn-color me-2"}
                buttonTitle={"Form Import"}
                actionBlock={() => {
                  setShowFormImportModal(true);
                  setIsFormupload(true);
                  setDynamicForm(allforms);
                }}
              />
              <DefaultButton
                buttonClass={"default-btn-color me-2"}
                buttonTitle={"Import"}
                actionBlock={() => {
                  setShowImportModal(true);
                  setIsFormupload(false);
                  setDynamicForm([]);
                }}
              />              
              <DefaultButton
                buttonClass={"default-btn-color"}
                buttonTitle={CREATENEW}
                actionBlock={() => {
                  navigate("/stores/add");
                }}
              />
            </div>
          ) : (<></>)}
        </Col>
      </Row>
      <Row>
        <Card className='table-card'>
          <Card.Body >
            <select value={selectedCompanyId} style={{ width: 250 }} className="float-end form-control" onChange={(e) => { setSelectedCompanyId(Number.parseInt(e.target.value)) }}>
              <option value={0}>All Companies</option>
              {companies.map((company) =>
                <option key={company.id} value={company.id} >{company.company_name}</option>
              )
              }
            </select>
            <ListView
              exportTitle={"Stores"}
              columnNames={storesColumns}
              //rowData={storeProcessedData}
              // rowData={stores}
              rowData={storeData}
              shouldSetNextPrevIds
              rowClick={(row) => {
                navigate("./" + row.data["id"]);
              }}
              tableHeight = '70vh'
            />
          </Card.Body>
        </Card>
      </Row>

      <ImportModal
        show={showImportModal}
        handleClose={() => {
          setShowImportModal(false);
          setIsFormupload(false);
          setDynamicForm([]);
        } }
        tableName={"store"}
        modalTitle={"Import Stores"}
        importAction={loadStoreImportDataRequest}
        loadData = { null}
        isFormuplod = {isFormupload}
        dyamicFormData = {dynamicForm}
      />

      <ImportModal
        show={showFormImportModal}
        handleClose={() => { 
          setShowFormImportModal(false);
          setIsFormupload(false);
          setDynamicForm([]);
         } }
        tableName={"form_data"}
        modalTitle={"Import Stores Data for Store"}
        importAction={loadStoreImportDataRequest}
        loadData = { null}
        isFormuplod = {isFormupload}
        dyamicFormData = {dynamicForm}

      />

    </>
  );
};

export default Stores;
