import { takeLatest, put, call } from "redux-saga/effects";
import {
    HIRE_STATUS_DATA, HIRE_STATUS_GET_API,
    HIRE_STATUS_GET_DATA, HIRE_STATUS_GET_DETAIL_API,
    HIRE_STATUS_ADD_DATA, HIRE_STATUS_POST_API,
    HIRE_STATUS_UPDATE_DATA, HIRE_STATUS_PUT_API,
    HIRE_STATUS_DELETE_DATA, HIRE_STATUS_DELETE_API,
} from './Constants'
import {
    setHireStatusDataSuccess, setHireStatusDataFail,
    setHireStatusGetDataSuccess, setHireStatusGetDataFail,
    setHireStatusAddDataSuccess, setHireStatusAddDataFail,
    setHireStatusUpdateDataSuccess, setHireStatusUpdateDataFail,
    setHireStatusDeleteDataSuccess, setHireStatusDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest, getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";

function* getHireStatusDataService() {
    try {
        const response = yield call(getRequest, HIRE_STATUS_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setHireStatusDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setHireStatusDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving hire status"))
        yield put(setHireStatusDataFail(error));
    }
}

function* getHireStatusDetailDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            HIRE_STATUS_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setHireStatusGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setHireStatusGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving hire status info"))
        yield put(setHireStatusGetDataFail(error));
    }
}

function* postHireStatusDataService({ request }) {
    try {
        // let requestData = {
        //     to_insert:request
        // }
        const response = yield call(postRequest, HIRE_STATUS_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Hire Status created"))
            yield put(setHireStatusAddDataSuccess(newData));
        } else {
            //yield put(addErrorAlert(response.response.data.msg))
            yield put(setHireStatusAddDataFail(response.response.data));
        }
    } catch (error) {
        //yield put(addErrorAlert("Error adding hire status"))
        yield put(setHireStatusAddDataFail(error));
    }
}
function* putHireStatusDataService({ request }) {
    try {
        // let requestData = {
        //     to_id:{
        //         id:request.id
        //     },
        //     to_update:{
        //         ...request
        //     }
        // }
        const response = yield call(putRequest, HIRE_STATUS_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Hire Status updated"))
            yield put(setHireStatusUpdateDataSuccess(response.data));
        } else {
            //yield put(addErrorAlert(response.response.data.msg))
            yield put(setHireStatusUpdateDataFail(response.response.data));
        }
    } catch (error) {
        //yield put(addErrorAlert("Error editing hire status"))
        yield put(setHireStatusUpdateDataFail(error));
    }
}

function* deleteHireStatusDataService({ request }) {
    try {
        const response = yield call(deleteRequest, HIRE_STATUS_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setHireStatusDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setHireStatusDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setHireStatusDeleteDataFail(error));
    }
}


export function* watchHireStatus(){
    yield takeLatest(HIRE_STATUS_DATA.HIRE_STATUS_DATA_LOAD, getHireStatusDataService);
    yield takeLatest(HIRE_STATUS_GET_DATA.HIRE_STATUS_GET_DATA_LOAD, getHireStatusDetailDataService);
    yield takeLatest(HIRE_STATUS_ADD_DATA.HIRE_STATUS_ADD_DATA_LOAD, postHireStatusDataService);
    yield takeLatest(HIRE_STATUS_UPDATE_DATA.HIRE_STATUS_UPDATE_DATA_LOAD, putHireStatusDataService);
    yield takeLatest(HIRE_STATUS_DELETE_DATA.HIRE_STATUS_DELETE_DATA_LOAD, deleteHireStatusDataService); 
}