import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ListView from "../../../shared/components/listview/ListView"
import Modalpopup from "../../../shared/components/Modalpopup"
import DefaultButton from "../../../shared/components/DefaultButton"
import { DELETE, CANCEL, DELETE_CONFIRM, JOB_TITLE, MARKET, REGION, DIVISION, EMPLOYEE_LEAVE_TYPE, START_DATE, END_DATE, STORE } from "../../../shared/constants/Constants"
import EmployeeDesignationForm from "./EmployeeDesignationForm"
import { loadEmployeeDesignationTrackerAddDataRequest, loadEmployeeDesignationTrackerDeleteDataRequest, loadEmployeeDesignationTrackerEditDataRequest } from "../redux/Actions"
import Permissions from "../../../shared/components/Permissions"
import FormError from "../../../shared/components/FormError"
import { useNavigate } from "react-router-dom"


const EmployeeDesignationTracker = ({ employee_designation_tracker, employee, setShouldReload }) => {

    const dispatch = useDispatch();
    const [modifiedEDT, setModifiedEDT] = useState([]);
    useEffect(() => {
        setModifiedEDT(employee_designation_tracker.map( (empDT) => {
            let storeIds;
            let storeNames;
            let marketIds;
            let marketNames;
            if (empDT.is_multiple) {
                // to get store ids and store names
                if (empDT.store_ids != null) {
                    storeIds = empDT.store_ids.map((edt) => {
                        return edt.id;
                    })
                    storeNames = empDT.store_ids.map((sedt) => {
                        return sedt.name;
                    })
                } else {
                    storeIds = empDT.store_id;
                    storeNames = empDT.store_sap_name;
                }
                
                //to get market ids and names
                if (empDT.market_ids != null) {
                    marketIds = empDT.market_ids.map((medt) => {
                        return medt.id;
                    })
                    marketNames = empDT.market_ids.map((msedt) => {
                        return msedt.name;
                    })
                } else {
                    marketIds = empDT.market_id;
                    marketNames = empDT.market_name;
                }
            } else {
                storeIds = empDT.store_id;
                storeNames = empDT.store_sap_name;
                marketIds = empDT.market_id;
                marketNames = empDT.market_name;
            }
            
            empDT['store_id'] = storeIds;
            empDT['store_sap_name'] = storeNames;
            empDT['market_id'] = marketIds;
            empDT['market_name'] = marketNames;
            
            return {
                ...employee_designation_tracker,
            }

        }));
    },[employee_designation_tracker])


    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Designation Tracker";
    let permission = Permissions(emp_no, module_name);
    let actionsPermission = !(permission.write_permission || permission.delete_permission);
    let [errorMessage, setErrorMessage] = useState(null);
    let navigate = useNavigate();

    let empInfo = JSON.parse(localStorage.getItem('employee'));
    let isAdmin = false;
    if (empInfo?.role === "admin") {
        isAdmin = true;
    }
    const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true,
            pinned:'left',
        },
        {
            display_name: "Employee Number",
            field: "employee_id",
            type: "text",
            hide: true
        },
        {
            display_name: JOB_TITLE,
            field: "job_title_name",
            type: "text",
            pinned:'left',
        },
        {
            display_name: "Covering",
            field: "covering",
            type: "icon",
            icon: "checkbox",
            width: 80,
            pinned:'left',
        },
        {
            display_name: "Commisionable",
            field: "is_commisionable",
            type: "commisionable",
            icon: "checkbox",
            width: 120,
            pinned:'left',
        },
        {
            display_name: STORE,
            field: "store_sap_name",
            type: "text",
            pinned:'left',
        },
        {
            display_name: MARKET,
            field: "market_name",
            type: "text",
        },
        {
            display_name: REGION,
            field: "region_name",
            type: "text",
        },
        {
            display_name: DIVISION,
            field: "division_name",
            type: "text",
        },
        {
            display_name: EMPLOYEE_LEAVE_TYPE,
            field: "leave_type_name",
            type: "text",
        },
        {
            display_name: START_DATE,
            field: "start_date",
            type: "date",
            sort: "desc"
        },
        {
            display_name: END_DATE,
            field: "end_date",
            type: "date",
        },
        {
            display_name: '',
            field: 'actions',
            hide: actionsPermission
        }
    ]
    let [showEditModal, setShowEditModal] = useState(false)
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [showAddModal, setShowAddModal] = useState(false);
    let [selectedTracker, setSelectedTracker] = useState({});

    let { loading, error } = useSelector(state => state.employee);
    let [shouldCloseModal, setShouldCloseModal] = useState(false);

    useEffect(() => {
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddModal();
                closeEditModal();
                setShouldReload(true)
            } else {
                setErrorMessage(error?.msg?.toString());
            }

            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])


    const closeAddModal = () => {
        setShowAddModal(false);
        setErrorMessage(null);
    }
    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(false);
    }
    const handleEdit = (values) => {

        let storeId;   
        if (values.leave_type === true) {
            storeId = 0;
        } else {
            storeId = values.store_id;
        }
        console.log("s:", storeId);
        console.log("leave:", values.leave_type);

        let requestValues = {
            id: values.id,
            employee_id: values.employee_id,
            job_title_id: values.job_title_id,
            is_commisionable: values.is_commisionable,
            covering_store: values.covering_store,
            covering_market: values.covering_market,
            covering_region: values.covering_region,
            store_id: storeId,
            market_id: values.market_id,
            region_id: values.region_id,
            division_id: values.division_id,
            leave_type: values.leave_type ? true : null,
            start_date: values.start_date,
            end_date: values.end_date,
        }
        dispatch(loadEmployeeDesignationTrackerEditDataRequest(requestValues))
        setShouldCloseModal(true);
    }
    const handleAdd = (values) => {
        let requestValues = {
            employee_id: values.employee_id,
            job_title_id: values.job_title_id,
            is_commisionable: values.is_commisionable,
            covering_store: values.covering_store,
            covering_market: values.covering_market,
            covering_region: values.covering_region,
            store_id: values.store_id,
            market_id: values.market_id,
            region_id: values.region_id,
            division_id: values.division_id,
            leave_type: values.leave_type ? true : null,
            start_date: values.start_date,
            end_date: values.end_date
        }
        dispatch(loadEmployeeDesignationTrackerAddDataRequest(requestValues))
        setShouldCloseModal(true);
    }
    const handleDelete = () => {
        setShowDeleteModal(false);
        let values = {
            id: [selectedTracker],
            employee_id: employee.employee_id
        }
        dispatch(loadEmployeeDesignationTrackerDeleteDataRequest(values))
        setShouldReload(true)
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedTracker(row);
                    setShowEditModal(true);
                })
        }
        if (permission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedTracker(id);
                    setShowDeleteModal(true);
                })
        }
    }, [permission])

    let disableBtn = "";
    if (employee?.emp_status == "Terminated") {
        if (JSON.parse(localStorage.getItem("emp_no")) == "admin") {
            disableBtn = <Button style={{ width: 115 }} className={"btn btn-secondary default-btn-color float-end py-1"} onClick={() => { setShowAddModal(true); }}>Add Tracker</Button>
        } else {
            disableBtn = <Button style={{ width: 115 }} disabled className={"btn btn-secondary default-btn-color float-end py-1"} onClick={() => { setShowAddModal(true); }}>Add Tracker</Button>
        }
    } else {
        disableBtn = <Button style={{ width: 115 }} className={"btn btn-secondary default-btn-color float-end py-1"} onClick={() => { setShowAddModal(true); }}>Add Tracker</Button>
    }


    return <>
        
        {permission.create_permission ? (<>   
            {disableBtn} 
        </>) : (<></>)}
        {isAdmin ?
            <Button style={{ width: 115 }} className="btn btn-secondary default-btn-color mx-1 float-end py-1" onClick={() => { navigate(`/odoo-trackers/${employee?.employee_id}?back=${employee?.id}`) }}>Odoo</Button>
            : <></>
        }
        <ListView
            exportTitle={'employee designation tracker'}
            columnNames={columnDefs}
            rowData={employee_designation_tracker}
            // rowData={modifiedEDT}
            clickEdit={clickEditFunc}
            clickDelete={clickDeleteFunc}
            tableHeight = '40vh'
        />
        <Modalpopup show={showDeleteModal} actionTitle="Delete Employee Designation Tracker" handleClose={() => { setShowDeleteModal(false) }}>
            {DELETE_CONFIRM}
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>
        <Modalpopup show={showAddModal} actionTitle="Add Employee Designation Tracker" handleClose={() => { closeAddModal() }} size={"lg"}>
            <FormError>
                {errorMessage}
            </FormError>
            <EmployeeDesignationForm submit={handleAdd} employee={employee} cancel={() => closeAddModal()} />
        </Modalpopup>
        <Modalpopup show={showEditModal} actionTitle="Edit Employee Designation Tracker" handleClose={() => { closeEditModal() }} size={"lg"}>
            <FormError>
                {errorMessage}
            </FormError>
            <EmployeeDesignationForm submit={handleEdit} employee={employee} cancel={() => closeEditModal()} tracker={selectedTracker} allTrackers={employee_designation_tracker} />
        </Modalpopup>
    </>
}
export default EmployeeDesignationTracker
