import {
  KEYTAG_DATA,
  KEYTAG_GET_DATA,
  KEYTAG_ADD_DATA,
  KEYTAG_UPDATE_DATA,
  KEYTAG_DELETE_DATA,
  KEYVALUE_GET,
  KEYTAGMODULE_GET_DATA,
  VALUE_RESOURCE_GET,
  KEYVALUE_ADD,
  KEYVALUE_UPDATE,
  KEYVALUE_DELETE,
  KEYVALUE_IMPORT_DATA
} from "./Constants";
const initialState = {
  loading: false,
  error: null,
  keytags: [],
  keytag: {},
  keyvalues: [],
  keyModules:[]
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    //load all
    case KEYTAG_DATA.KEYTAG_DATA_LOAD:
      return {
        ...state,
        loading: true,
        keytags: [],
        error: null,
      };
    case KEYTAG_DATA.KEYTAG_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        keytags: action.keytags,
      };
    case KEYTAG_DATA.KEYTAG_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //load single
    case KEYTAG_GET_DATA.KEYTAG_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        keytag: {},
        error: null,
      };

    case KEYTAG_GET_DATA.KEYTAG_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        keytag: action.keytag,
      };

    case KEYTAG_GET_DATA.KEYTAG_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Add
    case KEYTAG_ADD_DATA.KEYTAG_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case KEYTAG_ADD_DATA.KEYTAG_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case KEYTAG_ADD_DATA.KEYTAG_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Update
    case KEYTAG_UPDATE_DATA.KEYTAG_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case KEYTAG_UPDATE_DATA.KEYTAG_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case KEYTAG_UPDATE_DATA.KEYTAG_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Delete
    case KEYTAG_DELETE_DATA.KEYTAG_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case KEYTAG_DELETE_DATA.KEYTAG_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case KEYTAG_DELETE_DATA.KEYTAG_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Get Module
    case KEYTAGMODULE_GET_DATA.KEYTAGMODULE_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        // keyModules: [],
        error: null,
      };

    case KEYTAGMODULE_GET_DATA.KEYTAGMODULE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        keyModules: action.keyModules,
      };

    case KEYTAGMODULE_GET_DATA.KEYTAGMODULE_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        keyModules: [],
      };
    //Key Value
    case KEYVALUE_GET.KEYVALUE_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        keyvalues: [],
        error: null,
      };
    case KEYVALUE_GET.KEYVALUE_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        keyvalues: action.keyvalues,
      };
    case KEYVALUE_GET.KEYVALUE_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Value Resource 
    case VALUE_RESOURCE_GET.VALUE_RESOURCE_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        keyvalues: [],
        error: null,
      };
    case VALUE_RESOURCE_GET.VALUE_RESOURCE_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        keyvalues: action.keyvalues,
      };
    case VALUE_RESOURCE_GET.VALUE_RESOURCE_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Key Value Add
    case KEYVALUE_ADD.KEYVALUE_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case KEYVALUE_ADD.KEYVALUE_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case KEYVALUE_ADD.KEYVALUE_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Key Value Update
    case KEYVALUE_UPDATE.KEYVALUE_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case KEYVALUE_UPDATE.KEYVALUE_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case KEYVALUE_UPDATE.KEYVALUE_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Key Value Delete
    case KEYVALUE_DELETE.KEYVALUE_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case KEYVALUE_DELETE.KEYVALUE_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case KEYVALUE_DELETE.KEYVALUE_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //Import KeyTag Value
    case KEYVALUE_IMPORT_DATA.KEYVALUE_IMPORT_DATA_LOAD:
        return {
            ...state,
            loading:true,
            error:null,
            keyvalues:[]
        }
    case KEYVALUE_IMPORT_DATA.KEYVALUE_IMPORT_DATA_SUCCESS:
        return {
            ...state,
            loading:false,
            keyvalues:action.keyvalues,
            error:null
        }
    case KEYVALUE_IMPORT_DATA.KEYVALUE_IMPORT_DATA_FAIL:
        return {
            ...state,
            loading:false,
            error:action.error,
            keyvalues:[]
        }
    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
