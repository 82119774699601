import { takeLatest, put, call } from "redux-saga/effects";
import {
  COMPANY_DATA,
  COMPANY_GET_API,
  COMPANY_ADD_DATA,
  COMPANY_POST_API,
  COMPANY_GET_DATA,
  COMPANY_GET_DETAIL_API,
  COMPANY_UPDATE_DATA,
  COMPANY_PUT_API,
  COMPANY_DELETE_DATA,
  COMPANY_DELETE_API,
  COMPANY_IMPORT_DATA,
  COMPANY_IMPORT_API,
} from "./Constants";
import {
  setCompanyDataSuccess,
  setCompanyDataFail,
  setCompanyAddDataSuccess,
  setCompanyAddDataFail,
  setCompanyGetDataSuccess,
  setCompanyGetDataFail,
  setCompanyUpdateDataSuccess,
  setCompanyUpdateDataFail,
  setCompanyDeleteDataSuccess,
  setCompanyDeleteDataFail,
  setCompanyImportDataSuccess,
  setCompanyImportDataFail,
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
  postImportRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getCompaniesDataService() {
  try {
    const companiesResponse = yield call(getRequest, COMPANY_GET_API);
    const status = companiesResponse.status;
    if (status === 200) {
      const data = companiesResponse.data;
      yield put(setCompanyDataSuccess(data));
    } else {
      yield put(setCompanyDataFail(companiesResponse.response.data));
    }
  } catch (error) {
    yield put(setCompanyDataFail(error));
  }
}

function* postCompanyDataService({ request }) {
  try {
    const response = yield call(
      postRequest,
      COMPANY_POST_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Company created"))
      yield put(setCompanyAddDataSuccess(newData));
    } else {
      yield put(setCompanyAddDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setCompanyAddDataFail(error));
  }
}

function* getCompanyDataService({ id }) {
  try {
    const getCompanyDataResponse = yield call(
      getDetailRequest,
      COMPANY_GET_DETAIL_API + id
    );
    const status = getCompanyDataResponse.status;
    if (status === 200) {
      const newData = getCompanyDataResponse.data;
      yield put(setCompanyGetDataSuccess(newData));
    } else {
      yield put(setCompanyGetDataFail(getCompanyDataResponse.response.data));
    }
  } catch (error) {
    yield put(setCompanyGetDataFail(error));
  }
}

function* putCompanyDataService({ request }) {
  try {
    const response = yield call(
      putRequest,
      COMPANY_PUT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Company updated"))
      yield put(setCompanyUpdateDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setCompanyUpdateDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not update company"))
    yield put(setCompanyUpdateDataFail(error));
  }
}

function* deleteCompanyDataService({ request }) {
  try {
    const response = yield call(
      deleteRequest,
      COMPANY_DELETE_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Company deleted"))
      yield put(setCompanyDeleteDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setCompanyDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not delete company"))
    yield put(setCompanyDeleteDataFail(error));
  }
}

function* postImportCompanyDataService({ request }) {
  try {
    const response = yield call(
      postImportRequest,
      COMPANY_IMPORT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Company imported"))
      yield put(setCompanyImportDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setCompanyImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert('Could not import company'))
    yield put(setCompanyImportDataFail(error));
  }
}

export function* watchCompany() {
  yield takeLatest(COMPANY_DATA.COMPANY_DATA_LOAD, getCompaniesDataService);
  yield takeLatest(
    COMPANY_ADD_DATA.COMPANY_ADD_DATA_LOAD,
    postCompanyDataService
  );
  yield takeLatest(
    COMPANY_GET_DATA.COMPANY_GET_DATA_LOAD,
    getCompanyDataService
  );
  yield takeLatest(
    COMPANY_UPDATE_DATA.COMPANY_UPDATE_DATA_LOAD,
    putCompanyDataService
  );
  yield takeLatest(
    COMPANY_DELETE_DATA.COMPANY_DELETE_DATA_LOAD,
    deleteCompanyDataService
  );
  yield takeLatest(
    COMPANY_IMPORT_DATA.COMPANY_IMPORT_DATA_LOAD,
    postImportCompanyDataService
  );
  //Reload companies after successfully importing
  yield takeLatest(COMPANY_IMPORT_DATA.COMPANY_IMPORT_DATA_SUCCESS,
    getCompaniesDataService
  );
}
