//Get
const FULLPART_TIME_DATA = {
    FULLPART_TIME_DATA_LOAD: "FULLPART_TIME_DATA_LOAD",
    FULLPART_TIME_DATA_SUCCESS: "FULLPART_TIME_DATA_SUCCESS",
    FULLPART_TIME_DATA_FAIL: "FULLPART_TIME_DATA_FAIL",
};
const FULLPART_TIME_GET_API = "/employee/v1/fullPartTimeStatus";

// Get one by id
const FULLPART_TIME_GET_DATA = {
    FULLPART_TIME_GET_DATA_LOAD: "FULLPART_TIME_GET_DATA_LOAD",
    FULLPART_TIME_GET_DATA_SUCCESS: "FULLPART_TIME_GET_DATA_SUCCESS",
    FULLPART_TIME_GET_DATA_FAIL: "FULLPART_TIME_GET_DATA_FAIL",
};

const FULLPART_TIME_GET_DETAIL_API = "/employee/v1/fullPartTimeStatus/";

// Create / Add / Post
const FULLPART_TIME_ADD_DATA = {
    FULLPART_TIME_ADD_DATA_LOAD: "FULLPART_TIME_ADD_DATA_LOAD",
    FULLPART_TIME_ADD_DATA_SUCCESS: "FULLPART_TIME_ADD_DATA_SUCCESS",
    FULLPART_TIME_ADD_DATA_FAIL: "FULLPART_TIME_ADD_DATA_FAIL",
};

const FULLPART_TIME_POST_API = "/employee/v1/fullPartTimeStatus";

//Update
const FULLPART_TIME_UPDATE_DATA = {
  FULLPART_TIME_UPDATE_DATA_LOAD: "FULLPART_TIME_UPDATE_DATA_UPLOAD",
  FULLPART_TIME_UPDATE_DATA_SUCCESS: "FULLPART_TIME_UPDATE_DATA_SUCCESS",
  FULLPART_TIME_UPDATE_DATA_FAIL: "FULLPART_TIME_UPDATE_DATA_FAIL",
};

const FULLPART_TIME_PUT_API = "/employee/v1/fullPartTimeStatus";

// Delete
const FULLPART_TIME_DELETE_DATA = {
  FULLPART_TIME_DELETE_DATA_LOAD: "FULLPART_TIME_DELETE_DATA_LOAD",
  FULLPART_TIME_DELETE_DATA_SUCCESS: "FULLPART_TIME_DELETE_DATA_SUCCESS",
  FULLPART_TIME_DELETE_DATA_FAIL: "FULLPART_TIME_DELETE_DATA_FAIL",
};

const FULLPART_TIME_DELETE_API = "/employee/v1/fullPartTimeStatus";


export{
    FULLPART_TIME_DATA,
    FULLPART_TIME_GET_API,
    FULLPART_TIME_GET_DATA,
    FULLPART_TIME_GET_DETAIL_API,
    FULLPART_TIME_ADD_DATA,
    FULLPART_TIME_POST_API,
    FULLPART_TIME_UPDATE_DATA,
    FULLPART_TIME_PUT_API,
    FULLPART_TIME_DELETE_DATA,
    FULLPART_TIME_DELETE_API,
}