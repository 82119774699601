import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Breadcrumbs from "../../shared/components/Breadcrumbs";
import { COMPANY, DIVISION, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, EMPLOYEE_TYPE, HIRE_STATUS, JOB_TITLE, MARKET, REGION, STORE } from "../../shared/constants/Constants";
import { Link } from "react-router-dom";

const UserProfile = () => {

  let employee = JSON.parse(localStorage.getItem("employee"));
  console.log("emp info::", employee);

  return (
    <>
      <Breadcrumbs children={[{ label: "User Profile" }]} />
      
      <Row>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <dl className="row">
                  <dt className="col-sm-4">Emp. Number</dt>
                  <dd className="col-sm-8">{employee?.employee_id}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">First Name</dt>
                  <dd className="col-sm-8">{employee?.first_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Last Name</dt>
                  <dd className="col-sm-8">{employee?.last_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Prefered Name</dt>
                  <dd className="col-sm-8">{employee?.preferred_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">NTID</dt>
                  <dd className="col-sm-8">{employee?.ntid}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">C Number</dt>
                  <dd className="col-sm-8">{employee?.cnumber}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{JOB_TITLE}</dt>
                  <dd className="col-sm-8">{employee?.job_title_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{EMPLOYEE_DEPARTMENT}</dt>
                  <dd className="col-sm-8">{employee?.department_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Work Email</dt>
                  <dd className="col-sm-8">{employee?.email_work}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Personal Email</dt>
                  <dd className="col-sm-8">{employee?.email_personal}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Hire Date</dt>
                  <dd className="col-sm-8">{employee?.hire_date}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Termination Date</dt>
                  <dd className="col-sm-8">
                    {employee?.term_date === "12-31-2099"
                      ? ""
                      : employee?.term_date}
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Termination Reason</dt>
                  <dd className="col-sm-8">{employee?.term_reason}</dd>
                </dl>
              </Col>
              <Col>
                <dl className="row">
                  <dt className="col-sm-4">{EMPLOYEE_TYPE}</dt>
                  <dd className="col-sm-8">{employee?.full_part_time_status}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{HIRE_STATUS}</dt>
                  <dd className="col-sm-8">{employee?.hire_status_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{STORE}</dt>
                  <dd className="col-sm-8">{employee?.store_sap_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{MARKET}</dt>
                  <dd className="col-sm-8">{employee?.market_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{REGION}</dt>
                  <dd className="col-sm-8">{employee?.region_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{DIVISION}</dt>
                  <dd className="col-sm-8">
                      {employee?.division_name}
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{COMPANY}</dt>
                  <dd className="col-sm-8">{employee?.company_name}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Work Phone</dt>
                  <dd className="col-sm-8">{employee?.work_phone}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Personal Phone</dt>
                  <dd className="col-sm-8">{employee?.personal_phone}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Home Address</dt>
                  <dd className="col-sm-8">{employee?.home_address}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Supervisor</dt>
                  <dd className="col-sm-8">
                    {(employee?.supervisor_first_name ?? "") +
                      " " +
                      (employee?.supervisor_last_name ?? "") +
                      "," +
                      (employee?.supervisor_employee_id ?? "")}
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">Commisionable</dt>
                  <dd className="col-sm-8">
                    {employee?.is_commisionable ? (
                      <i className="covering_store_checked"></i>
                    ) : (
                      <i className="greyOutIcon"></i>
                    )}
                  </dd>
                  <span></span>
                </dl>
                <dl className="row">
                  <dt className="col-sm-4">{EMPLOYEE_STATUS}</dt>
                  <dd className="col-sm-8">{employee?.emp_status}</dd>
                </dl>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default UserProfile;
