import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { CANCEL, EMPLOYEE_STATUS, SAVE } from "../../../../shared/constants/Constants";
import DefaultButton from "../../../../shared/components/DefaultButton";
import FormikSelectField from "../../../../shared/components/FormikSelectField";

const PTForm = ({ name, description, categories=null, ptData, submit, cancel }) => {

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Required";
        }
        return errors;
    };

    return (
        <Formik
            initialValues={{
                id: ptData?.id ?? null,
                name: ptData?.name ?? "",
                description: ptData?.description ?? "",
                category_id: ptData?.category_id ?? 0
            }}
            validate={validate}
            onSubmit={submit}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form className="row g-4">
                    {categories != null && (
                    <FormikSelectField
                        name='category_id'
                        label="Category"
                        value={values.category_id}
                        onChange={setFieldValue}
                        placeholder="Select Category"
                    >
                        {categories.map(category =>
                            <option value={category.id}>
                                {category.name}
                            </option>
                        )}
                    </FormikSelectField>
                    )}
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                            Name<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={"Enter Name"}
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="name" />
                        </span>
                    </div>
                    {description ? (
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                            Description
                        </label>
                        <Field as="textarea"
                            className="form-control"
                            name="description"
                            placeholder={"Enter Description"}
                            autoComplete="off"
                        />
                    </div>
                    ) : ""}
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        /> 
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default PTForm;
