//Get
const LEAVE_TYPE_DATA = {
    LEAVE_TYPE_DATA_LOAD: "LEAVE_TYPE_DATA_LOAD",
    LEAVE_TYPE_DATA_SUCCESS: "LEAVE_TYPE_DATA_SUCCESS",
    LEAVE_TYPE_DATA_FAIL: "LEAVE_TYPE_DATA_FAIL",
};
const LEAVE_TYPE_GET_API = "/employee/v1/leaveType";

// Get one by id
const LEAVE_TYPE_GET_DATA = {
    LEAVE_TYPE_GET_DATA_LOAD: "LEAVE_TYPE_GET_DATA_LOAD",
    LEAVE_TYPE_GET_DATA_SUCCESS: "LEAVE_TYPE_GET_DATA_SUCCESS",
    LEAVE_TYPE_GET_DATA_FAIL: "LEAVE_TYPE_GET_DATA_FAIL",
};

const LEAVE_TYPE_GET_DETAIL_API = "/employee/v1/leaveType/";

// Create / Add / Post
const LEAVE_TYPE_ADD_DATA = {
    LEAVE_TYPE_ADD_DATA_LOAD: "LEAVE_TYPE_ADD_DATA_LOAD",
    LEAVE_TYPE_ADD_DATA_SUCCESS: "LEAVE_TYPE_ADD_DATA_SUCCESS",
    LEAVE_TYPE_ADD_DATA_FAIL: "LEAVE_TYPE_ADD_DATA_FAIL",
};

const LEAVE_TYPE_POST_API = "/employee/v1/leaveType";

//Update
const LEAVE_TYPE_UPDATE_DATA = {
  LEAVE_TYPE_UPDATE_DATA_LOAD: "LEAVE_TYPE_UPDATE_DATA_UPLOAD",
  LEAVE_TYPE_UPDATE_DATA_SUCCESS: "LEAVE_TYPE_UPDATE_DATA_SUCCESS",
  LEAVE_TYPE_UPDATE_DATA_FAIL: "LEAVE_TYPE_UPDATE_DATA_FAIL",
};

const LEAVE_TYPE_PUT_API = "/employee/v1/leaveType";

// Delete
const LEAVE_TYPE_DELETE_DATA = {
  LEAVE_TYPE_DELETE_DATA_LOAD: "LEAVE_TYPE_DELETE_DATA_LOAD",
  LEAVE_TYPE_DELETE_DATA_SUCCESS: "LEAVE_TYPE_DELETE_DATA_SUCCESS",
  LEAVE_TYPE_DELETE_DATA_FAIL: "LEAVE_TYPE_DELETE_DATA_FAIL",
};

const LEAVE_TYPE_DELETE_API = "/employee/v1/leaveType";

export{
    LEAVE_TYPE_DATA,
    LEAVE_TYPE_GET_API,
    LEAVE_TYPE_GET_DATA,
    LEAVE_TYPE_GET_DETAIL_API,
    LEAVE_TYPE_ADD_DATA,
    LEAVE_TYPE_POST_API,
    LEAVE_TYPE_UPDATE_DATA,
    LEAVE_TYPE_PUT_API,
    LEAVE_TYPE_DELETE_DATA,
    LEAVE_TYPE_DELETE_API,
}