import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CANCEL, EMPLOYEE_LEAVE_TYPE, SAVE, ULTI_LEAVE_CODE } from "../../../shared/constants/Constants";

const LeaveTypeForm = ({ leaveType, submit, cancel }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Required";
        }
        return errors;
    };

    return (
        <Formik
            initialValues={{
                id: leaveType?.id ?? null,
                name: leaveType?.name ?? "",
                ulti_leave_code: leaveType?.ulti_leave_code ?? ''
            }}
            validate={validate}
            onSubmit={submit}
            enableReinitialize
        >
            {() => (
                <Form className="row g-4">
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                            {EMPLOYEE_LEAVE_TYPE}<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={"Enter " + EMPLOYEE_LEAVE_TYPE}
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="name" />
                        </span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="ulti_leave_code" className="form-label">
                            {ULTI_LEAVE_CODE}
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="ulti_leave_code"
                            placeholder={ULTI_LEAVE_CODE}
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="ulti_leave_code" />
                        </span>
                    </div>
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LeaveTypeForm;
