import { CHANNEL_TYPE_ADD_DATA, CHANNEL_TYPE_DELETE_DATA, CHANNEL_TYPE_GET_DATA, CHANNEL_TYPE_GET_DETAILS_DATA, CHANNEL_TYPE_UPDATE_DATA } from "./Constants";

const initialState = {
    loading: false,
    error: null,
    channelTypes: [],
    channel:{}
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANNEL_TYPE_GET_DATA.CHANNEL_TYPE_GET_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                channelTypes: []
            }
        case CHANNEL_TYPE_GET_DATA.CHANNEL_TYPE_GET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                channelTypes: action.channelTypes
            }
        case CHANNEL_TYPE_GET_DATA.CHANNEL_TYPE_GET_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case CHANNEL_TYPE_GET_DETAILS_DATA.CHANNEL_TYPE_GET_DETAILS_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                channel:{},
            }
        case CHANNEL_TYPE_GET_DETAILS_DATA.CHANNEL_TYPE_GET_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                channel:action.channelType
            }
        case CHANNEL_TYPE_GET_DETAILS_DATA.CHANNEL_TYPE_GET_DETAILS_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                channel:{},
            }
        case CHANNEL_TYPE_DELETE_DATA.CHANNEL_TYPE_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                channel:{},
            }
        case CHANNEL_TYPE_DELETE_DATA.CHANNEL_TYPE_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                error:null,
                channel:action.channelType
            }
        case CHANNEL_TYPE_DELETE_DATA.CHANNEL_TYPE_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                channel:{}
            }
        case CHANNEL_TYPE_ADD_DATA.CHANNEL_TYPE_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case CHANNEL_TYPE_ADD_DATA.CHANNEL_TYPE_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case CHANNEL_TYPE_ADD_DATA.CHANNEL_TYPE_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case CHANNEL_TYPE_UPDATE_DATA.CHANNEL_TYPE_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
            }
        case CHANNEL_TYPE_UPDATE_DATA.CHANNEL_TYPE_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case CHANNEL_TYPE_UPDATE_DATA.CHANNEL_TYPE_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        default:
            return {
                ...state
            }
    }
}

export default Reducer