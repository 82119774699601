import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import ListView from "../../../../shared/components/listview/ListView";
import { ODOO_STORE_TRANSFER_TRACKERS, ROLES } from "../../../../shared/constants/Constants";
import { loadOdooStoreTransferTrackerDataRequest, loadOdooStoreTransferTrackerGetDataRequest } from "../redux/Actions"; 

export const OdooStoreTransferTrackers = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let { odooStoreTransferTrackers, loading } = useSelector((state) => state.odooStoreTransferTrackers);
    const [shouldReload, setShouldReload] = useState(true)

    useEffect(() => {
        if (!loading && shouldReload) {
            setShouldReload(false)
            dispatch(loadOdooStoreTransferTrackerDataRequest());
        }
    }, [dispatch, loading, shouldReload]);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: "Store",
            field: "store_sap_name",
            type: "text",
        },
        {
            display_name:"Employee",
            field:"emp_name_number",
            type:'text',
        },
        {
            display_name: "Employee Type",
            field: "emp_type_name",
            type: "text",
        },
        {
            display_name: "Start Date",
            field: "start_date",
            type: "date",
        },
        {
            display_name: "End Date",
            field: "end_date",
            type: "date",
        },
    ];

    return (
        <>
            <Row>
                <Col>
                    <Breadcrumbs
                        children={[
                            { label: ODOO_STORE_TRANSFER_TRACKERS },
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={ODOO_STORE_TRANSFER_TRACKERS}
                            columnNames={columns}
                            rowData={odooStoreTransferTrackers}
                            rowClick={(row)=>{navigate(row.data.id)}}
                            shouldSetNextPrevIds
                        />
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
};

