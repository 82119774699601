import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import { loadChannelTypeGetData } from "../../storeChannelType/redux/Actions";
import { loadAcquisitionDataRequest } from "../../acquisition/redux/Actions";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { CANCEL, OPENDATEFUTURE, SAVE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";

const StoreForm = ({ store, submit, editType = null }) => {

    let navigate = useNavigate();
    let dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (!values.sap) {
            errors.sap = "Required";
        }
        if (!values.store_name) {
            errors.store_name = "Required";
        }
        // if (new Date(values.acquisition_date) <= new Date(values.open_date)) {
        //     errors.open_date = OPENDATEFUTURE
        // }
        return errors;
    };

    let { channelTypes } = useSelector(state => state.channelType);
    let { acquisitions } = useSelector(state => state.acquisition);
    useEffect(() => {
        //don't reload companies if they're already loaded
        if (channelTypes.length === 0) {
            dispatch(loadChannelTypeGetData())
        }
        if (acquisitions.length === 0) {
            dispatch(loadAcquisitionDataRequest())
        }
    }, [dispatch, channelTypes.length, acquisitions.length])

    const formik = useFormik({});

    useEffect(() => {
        if (store) {
            formik.current.setFieldValue('id', store?.id);
            formik.current.setFieldValue('sap', store?.sap);
            formik.current.setFieldValue('store_name', store?.store_name);
            formik.current.setFieldValue('tmob_name', store?.tmob_name);
            formik.current.setFieldValue('sprint_sap', store?.sprint_sap);
            formik.current.setFieldValue('channel_name', store?.channel_id);
            formik.current.setFieldValue('acquisition_dealer', store?.acquisition_dealer_id);
            formik.current.setFieldValue('sub_dealer_id', store?.sub_dealer_id);
            formik.current.setFieldValue('phone', store?.phone);
            formik.current.setFieldValue('address_street', store?.address_street);
            formik.current.setFieldValue('city', store?.city);
            formik.current.setFieldValue('county', store?.county);
            formik.current.setFieldValue('state', store?.state);
            formik.current.setFieldValue('zip', store?.zip);
            formik.current.setFieldValue('website', store?.website);
            formik.current.setFieldValue('comments', store?.comments);
            if (store && store.open_date && store.acquisition_date && store.closed_date && store.temp_closed_date && store.relocation_date) {
                if (store?.open_date.includes("-") && store?.acquisition_date.includes("-") && store?.closed_date.includes("-") && store?.temp_closed_date.includes("-") && store?.relocation_date.includes("-")) {
                    formik.current.setFieldValue('open_date', FormatDate(store?.open_date))
                    formik.current.setFieldValue('acquisition_date', FormatDate(store?.acquisition_date))
                    formik.current.setFieldValue('closed_date', FormatDate(store?.closed_date))
                    formik.current.setFieldValue('temp_closed_date', FormatDate(store?.temp_closed_date))
                    formik.current.setFieldValue('relocation_date', FormatDate(store?.relocation_date))
                }
            }
        }
    }, [store, formik])


    return <Formik
        innerRef={formik}
        initialValues={{
            sap: null,
            store_name: null,
            tmob_name: null,
            sprint_sap: null,
            channel_name: null,
            acquisition_dealer: null,
            sub_dealer_id: null,
            phone: null,
            address_street: null,
            city: null,
            county: null,
            state: null,
            zip: null,            
            acquisition_date: store?.acquisition_date,
            open_date: store?.open_date,
            closed_date: store?.closed_date,
            temp_closed_date: store?.temp_closed_date,
            relocation_date: store?.relocation_date,
            website: null,
            comments: null
        }}
        validate={validate}
        onSubmit={submit}
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="sap" className="form-label">
                    SAP <span className="error-text">*</span>
                    </label>
                    <Field
                    className="form-control"
                    type="text"
                    name="sap"
                    autoComplete="off"
                    />
                    <span className="error-text">
                        <ErrorMessage name="region_name" />
                    </span>
                </div>
                <div className="col-md-6">
                    <label className="form-label">Store Name <span className="error-text">*</span></label>
                    <Field
                    type="text"
                    className="form-control"
                    name="store_name"
                    autoComplete="off"
                    />
                    <span className="error-text">
                        <ErrorMessage name="region_name" />
                    </span>
                </div>
                <div className="col-md-6">
                    <label className="form-label">Tmobile Name </label>
                    <Field
                    type="text"
                    className="form-control"
                    name="tmob_name"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Sprint SAP </label>
                    <Field
                    type="text"
                    className="form-control"
                    name="sprint_sap"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Channel Name</label>
                    <FormikSelectField
                        name="channel_name"
                        value={values.channel_name}
                        onChange={setFieldValue}
                        placeholder={'Select Store Classification'}
                    >
                        {channelTypes.map((channel) => {
                            return <option key={channel.id} value={channel.id}>{channel.channel_name}</option>
                        })}
                    </FormikSelectField>
                </div>
                <div className="col-md-6">
                    <label className="form-label">Acquisition Dealer</label>
                    <FormikSelectField
                        name="acquisition_dealer"
                        value={values.acquisition_dealer}
                        onChange={setFieldValue}
                        placeholder={'Select Acquisition'}
                    >
                        {acquisitions.map((acquisition) => {
                            return <option key={acquisition.id} value={acquisition.id}>{acquisition.acquisition_dealer_name}</option>
                        })}
                    </FormikSelectField>
                </div>
                <div className="col-md-6">
                    <label className="form-label">Sub Dealer Id</label>
                    <Field
                    type="text"
                    className="form-control"
                    name="sub_dealer_id"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Phone</label>
                    <Field
                    type="text"
                    className="form-control"
                    name="phone"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Address/Street</label>
                    <Field
                    type="text"
                    className="form-control"
                    name="address_street"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">City</label>
                    <Field
                    type="text"
                    className="form-control"
                    name="city"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">County</label>
                    <Field
                    type="text"
                    className="form-control"
                    name="county"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">State</label>
                    <Field
                    type="text"
                    className="form-control"
                    name="state"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Zip</label>
                    <Field
                    type="text"
                    className="form-control"
                    name="zip"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                    Acquisition Date
                    </label>
                    <FormikDatePickerField
                    name="acquisition_date"
                    value={values.acquisition_date}
                    onChange={setFieldValue}
                    handleBlur={setFieldTouched}
                    />
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                    Open Date
                    </label>
                    <FormikDatePickerField
                    name="open_date"
                    value={values.open_date}
                    onChange={setFieldValue}
                    handleBlur={setFieldTouched}
                    />
                    {/* <span className="error-text">
                        <ErrorMessage name="open_date" />
                    </span> */}
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                    Closed Date 
                    </label>
                    <FormikDatePickerField
                    name="closed_date"
                    value={values.closed_date}
                    onChange={setFieldValue}
                    handleBlur={setFieldTouched}
                    />
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                    Temp Closed Date
                    </label>
                    <FormikDatePickerField
                    name="temp_closed_date"
                    value={values.temp_closed_date}
                    onChange={setFieldValue}
                    handleBlur={setFieldTouched}
                    />
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                    Relocation Date
                    </label>
                    <FormikDatePickerField
                    name="relocation_date"
                    value={values.relocation_date}
                    onChange={setFieldValue}
                    handleBlur={setFieldTouched}
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Website</label>
                    <Field
                    type="text"
                    className="form-control"
                    name="website"
                    autoComplete="off"
                    />
                </div>
                <div className="col-md-6">
                    <label className="form-label">Comments</label>
                    <Field as="textarea"
                    className="form-control"
                    name="comments"
                    autoComplete="off"
                    />
                </div>
                <Col md={12}>
                    <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => {
                        navigate("/stores");
                    }}
                    />
                    <DefaultButton
                        buttonClass={"btn btn-secondary default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik>
}

export default StoreForm