import { REGION_DATA, REGION_ADD_DATA, REGION_GET_DATA, REGION_UPDATE_DATA, REGION_DELETE_DATA, REGION_GET_ALL_DATA, REGION_TRACKER_DELETE_DATA, REGION_IMPORT_DATA, REGION_TRACKER_IMPORT_DATA, REGION_GET_MARKETS } from './Constants'

const initialState = {
    loading: false,
    error: null,
    regions: [],
    region: {},
    isImportFile: false,
    trackers: [],
    tracker: {},
    markets: [],
    managers: [],
    activeMarkets: []
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case REGION_DATA.REGION_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                regions: []
            }
        case REGION_DATA.REGION_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                regions: action.regions,
                error: null
            }
        case REGION_DATA.REGION_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                regions: []
            }
        //add
        case REGION_ADD_DATA.REGION_ADD_DATA_LOAD:
            return {
                ...state,
                loading: true,
                region: {},
                error: null
            }
        case REGION_ADD_DATA.REGION_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                region: action.region,
                error: null
            }
        case REGION_ADD_DATA.REGION_ADD_DATA_FAIL:
            return {
                ...state,
                loading: false,
                region: {},
                error: action.error
            }
        //Get 
        case REGION_GET_DATA.REGION_GET_DATA_LOAD:
            return {
                ...state,
                loading: true,
                region: {},
                trackers: [],
                error: null,
                isImportFile: false
            }
        case REGION_GET_DATA.REGION_GET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                region: action.region,
                trackers:action.trackers,
                managers:action.managers,
                activeMarkets:action.activeMarkets,
                error: null
            }
        case REGION_GET_DATA.REGION_GET_DATA_FAIL:
            return {
                ...state,
                loading: false,
                region: {},
                error: action.error
            }
        //Update
        case REGION_UPDATE_DATA.REGION_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null
            }
        case REGION_UPDATE_DATA.REGION_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            }
        case REGION_UPDATE_DATA.REGION_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        //Delete
        case REGION_DELETE_DATA.REGION_DELETE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                region: {},
                error: null
            }
        case REGION_DELETE_DATA.REGION_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                region: action.region,
                error: null
            }
        case REGION_DELETE_DATA.REGION_DELETE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                region: {}, 
                error: action.error
            }
        //Get all 
        case REGION_GET_ALL_DATA.REGION_GET_ALL_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                trackers: []
            }
        case REGION_GET_ALL_DATA.REGION_GET_ALL_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                trackers: action.trackers,
                error: null
            }
        case REGION_GET_ALL_DATA.REGION_GET_ALL_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                trackers: []
            }
        //Delete tracker
        case REGION_TRACKER_DELETE_DATA.REGION_TRACKER_DELETE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                tracker: {},
                error: null
            }
        case REGION_TRACKER_DELETE_DATA.REGION_TRACKER_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                tracker: action.tracker,
                error: null
            }
        case REGION_TRACKER_DELETE_DATA.REGION_TRACKER_DELETE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                tracker: {}, 
                error: action.error
            }
        //Import 
        case REGION_IMPORT_DATA.REGION_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                regions:[],
                isImportFile: false
            }
        case REGION_IMPORT_DATA.REGION_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                regions:action.regions,
                error:null,
                isImportFile: true
            }
        case REGION_IMPORT_DATA.REGION_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                regions:[],
                isImportFile: false
            }
        
        //Import Tracker
        case REGION_TRACKER_IMPORT_DATA.REGION_TRACKER_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                trackers:[]
            }
        case REGION_TRACKER_IMPORT_DATA.REGION_TRACKER_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                trackers:action.trackers,
                error:null
            }
        case REGION_TRACKER_IMPORT_DATA.REGION_TRACKER_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                trackers:[]
            }
        //Load Region's Markets
        case REGION_GET_MARKETS.REGION_GET_MARKETS_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                markets: [],
            }
        case REGION_GET_MARKETS.REGION_GET_MARKETS_SUCCESS:
            return {
                ...state,
                loading:false,
                markets:action.markets,
            }
        case REGION_GET_MARKETS.REGION_GET_MARKETS_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        default:
            return {
                ...state
            }
    }
}

export default Reducer;