import {
    EMPLOYEE_STATUS_DATA,
    EMPLOYEE_STATUS_GET_DATA,
    EMPLOYEE_STATUS_ADD_DATA,
    EMPLOYEE_STATUS_UPDATE_DATA,
    EMPLOYEE_STATUS_DELETE_DATA,
} from './Constants'


//For listing
const loadEmployeeStatusDataRequest = () => ({
    type: EMPLOYEE_STATUS_DATA.EMPLOYEE_STATUS_DATA_LOAD,
});
const setEmployeeStatusDataSuccess = (employeeStatuses) => ({
    type: EMPLOYEE_STATUS_DATA.EMPLOYEE_STATUS_DATA_SUCCESS,
    employeeStatuses,
});
const setEmployeeStatusDataFail = (error) => ({
    type: EMPLOYEE_STATUS_DATA.EMPLOYEE_STATUS_DATA_FAIL,
    error,
});

//for details
const loadEmployeeStatusGetDataRequest = (id) => ({
    type: EMPLOYEE_STATUS_GET_DATA.EMPLOYEE_STATUS_GET_DATA_LOAD,
    id,
});
const setEmployeeStatusGetDataSuccess = (response) => ({
    type: EMPLOYEE_STATUS_GET_DATA.EMPLOYEE_STATUS_GET_DATA_SUCCESS,
    empStatus: response.empStatus[0],
});
const setEmployeeStatusGetDataFail = (error) => ({
    type: EMPLOYEE_STATUS_GET_DATA.EMPLOYEE_STATUS_GET_DATA_FAIL,
    error,
});

//For add EmployeeStatus
const loadEmployeeStatusAddDataRequest = (request) => ({
    type: EMPLOYEE_STATUS_ADD_DATA.EMPLOYEE_STATUS_ADD_DATA_LOAD,
    request,
});
const setEmployeeStatusAddDataSuccess = (empStatus) => ({
    type: EMPLOYEE_STATUS_ADD_DATA.EMPLOYEE_STATUS_ADD_DATA_SUCCESS,
    empStatus,
});
const setEmployeeStatusAddDataFail = (error) => ({
    type: EMPLOYEE_STATUS_ADD_DATA.EMPLOYEE_STATUS_ADD_DATA_FAIL,
    error,
});

//update EmployeeStatus
const loadEmployeeStatusUpdateDataRequest = (request) => ({
    type: EMPLOYEE_STATUS_UPDATE_DATA.EMPLOYEE_STATUS_UPDATE_DATA_LOAD,
    request,
});
const setEmployeeStatusUpdateDataSuccess = (empStatus) => ({
    type: EMPLOYEE_STATUS_UPDATE_DATA.EMPLOYEE_STATUS_UPDATE_DATA_SUCCESS,
    empStatus,
});
const setEmployeeStatusUpdateDataFail = (error) => ({
    type: EMPLOYEE_STATUS_UPDATE_DATA.EMPLOYEE_STATUS_UPDATE_DATA_FAIL,
    error,
});

//Delete EmployeeStatus
const loadEmployeeStatusDeleteDataRequest = (request) => ({
    type: EMPLOYEE_STATUS_DELETE_DATA.EMPLOYEE_STATUS_DELETE_DATA_LOAD,
    request,
});
const setEmployeeStatusDeleteDataSuccess = (empStatus) => ({
    type: EMPLOYEE_STATUS_DELETE_DATA.EMPLOYEE_STATUS_DELETE_DATA_SUCCESS,
    empStatus,
});
const setEmployeeStatusDeleteDataFail = (error) => ({
    type: EMPLOYEE_STATUS_DELETE_DATA.EMPLOYEE_STATUS_DELETE_DATA_FAIL,
    error,
});


export {
    loadEmployeeStatusDataRequest, setEmployeeStatusDataSuccess, setEmployeeStatusDataFail,
    loadEmployeeStatusGetDataRequest, setEmployeeStatusGetDataSuccess, setEmployeeStatusGetDataFail,
    loadEmployeeStatusAddDataRequest, setEmployeeStatusAddDataSuccess, setEmployeeStatusAddDataFail,
    loadEmployeeStatusUpdateDataRequest, setEmployeeStatusUpdateDataSuccess, setEmployeeStatusUpdateDataFail,
    loadEmployeeStatusDeleteDataRequest, setEmployeeStatusDeleteDataSuccess, setEmployeeStatusDeleteDataFail,
}