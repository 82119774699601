import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ListView from "../../../../shared/components/listview/ListView";
import Modalpopup from "../../../../shared/components/Modalpopup";
import Permissions from "../../../../shared/components/Permissions";
import DefaultButton from "../../../../shared/components/DefaultButton";
import PTAdd from "../../productTables/components/PTAdd";
import PTEdit from "../../productTables/components/PTEdit";
import DeleteModal from "../../../../shared/components/DeleteModal";
import { loadPTDataRequest, loadPTDeleteDataRequest } from "../../productTables/redux/Actions";

const ProductType = ({ data }) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let { productTables } = useSelector((state) => state.productTables);

  let [selectedRow, setSelectedRow] = useState({});
  const [shouldReload, setShouldReload] = useState(true);

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "Product Tables";
  let permission = Permissions(emp_no, module_name); 

  let actionsPermission = !(
    permission.write_permission || permission.delete_permission
  );

  const columns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true,
    },
    {
      display_name: "Product Type Name",
      field: "name",
      type: "text",
    },
    {
      display_name: "Description",
      field: "description",
      type: "text",
    },
    {
      display_name: "",
      field: "actions",
      type: "actions",
      hide: actionsPermission,
    },
  ];

  let [clickEditFunc, setClickEditFunc] = useState(null);
  let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
  useEffect(() => {
    if (permission.write_permission) {
      setClickEditFunc(() => (row) => {
        setSelectedRow(row);
        setShowEditModal(true);
      });
    }
    if (permission.delete_permission) {
      setClickDeleteFunc(() => (id) => {
        setSelectedRow(id);
        setShowDeleteModal(true);
      });
    }
  }, [permission]);

  const handleDelete = () => {
    dispatch(loadPTDeleteDataRequest({ id: [selectedRow] })) 
    setShouldReload(true);
    setShowDeleteModal(false);
  };

  // if (data === null) {
    data = productTables?.productTypeData;
  // }

  return (
    <>
      <div className="createNewBtn">
        <DefaultButton
          buttonClass={"default-btn-color"}
          buttonTitle={"Create New"}
          actionBlock={() => setShowCreateModal(true)}
        />
      </div>
      <Row>
        <Card>
          <Card.Body>
            <ListView
              columnNames={columns}
              rowData={data}
              clickEdit={clickEditFunc}
              clickDelete={clickDeleteFunc}
            />
          </Card.Body>
        </Card>
      </Row>
      <Modalpopup
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        actionTitle={"Create Product Type"}
      >
        <PTAdd
          name={true}
          description={true}
          formName={'/product/producttype/'}
          handleClose={(reload) => {
            setShowCreateModal(false);
            if (reload) {
              dispatch(loadPTDataRequest());  
            }
          }}
        />
      </Modalpopup>
      <Modalpopup
        show={showEditModal}
        handleClose={() => {
          setShowEditModal(false);
        }}
        actionTitle={"Edit Product Type"}
      >
        <PTEdit
          name={true}
          description={true}
          formName={'/product/producttype/' + selectedRow?.id}
          ptData={selectedRow}
          handleClose={(reload) => {
            if (reload) {
              dispatch(loadPTDataRequest());
            }
            setShowEditModal(false);
          }}
        />
      </Modalpopup> 
      <DeleteModal
        handleClose={() => {
          setShowDeleteModal(false);
        }}
        handleDelete={handleDelete}
        show={showDeleteModal}
      >
        <div>Delete Product Type?</div>
      </DeleteModal>
    </>
  );
};

export default ProductType;
