import { takeLatest, put, call } from "redux-saga/effects";
import {
    FULLPART_TIME_DATA, FULLPART_TIME_GET_API,
    FULLPART_TIME_GET_DATA, FULLPART_TIME_GET_DETAIL_API,
    FULLPART_TIME_ADD_DATA, FULLPART_TIME_POST_API,
    FULLPART_TIME_UPDATE_DATA, FULLPART_TIME_PUT_API,
    FULLPART_TIME_DELETE_DATA, FULLPART_TIME_DELETE_API,
} from './Constants'
import {
    setFullPartTimeDataSuccess, setFullPartTimeDataFail,
    setFullPartTimeGetDataSuccess, setFullPartTimeGetDataFail,
    setFullPartTimeAddDataSuccess, setFullPartTimeAddDataFail,
    setFullPartTimeUpdateDataSuccess, setFullPartTimeUpdateDataFail,
    setFullPartTimeDeleteDataSuccess, setFullPartTimeDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";



function* getFullPartTimesDataService() {
    try {
        const response = yield call(getRequest, FULLPART_TIME_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setFullPartTimeDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setFullPartTimeDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee types"))
        yield put(setFullPartTimeDataFail(error));
    }
}

function* getFullPartTimeDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            FULLPART_TIME_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setFullPartTimeGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setFullPartTimeGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee type info"))
        yield put(setFullPartTimeGetDataFail(error));
    }
}

function* postFullPartTimeDataService({ request }) {
    try {
        // let requestData = {
        //     to_insert:request
        // }
        const response = yield call(postRequest, FULLPART_TIME_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("FullPartTime created"))
            yield put(setFullPartTimeAddDataSuccess(newData));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setFullPartTimeAddDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error adding employee type"))
        yield put(setFullPartTimeAddDataFail(error));
    }
}
function* putFullPartTimeDataService({ request }) {
    try {
        // let requestData = {
        //     to_id:{
        //         id:request.id
        //     },
        //     to_update:{
        //         ...request
        //     }
        // }
        const response = yield call(putRequest, FULLPART_TIME_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("FullPartTime updated"))
            yield put(setFullPartTimeUpdateDataSuccess(response.data));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setFullPartTimeUpdateDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error editing employee type"))
        yield put(setFullPartTimeUpdateDataFail(error));
    }
}

function* deleteFullPartTimeDataService({ request }) {
    try {
        const response = yield call(deleteRequest, FULLPART_TIME_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setFullPartTimeDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setFullPartTimeDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setFullPartTimeDeleteDataFail(error));
    }
}

export function* watchFullPartTime(){
    yield takeLatest(FULLPART_TIME_DATA.FULLPART_TIME_DATA_LOAD, getFullPartTimesDataService);
    yield takeLatest(FULLPART_TIME_GET_DATA.FULLPART_TIME_GET_DATA_LOAD, getFullPartTimeDataService);
    yield takeLatest(FULLPART_TIME_ADD_DATA.FULLPART_TIME_ADD_DATA_LOAD, postFullPartTimeDataService);
    yield takeLatest(FULLPART_TIME_UPDATE_DATA.FULLPART_TIME_UPDATE_DATA_LOAD, putFullPartTimeDataService);
    yield takeLatest(FULLPART_TIME_DELETE_DATA.FULLPART_TIME_DELETE_DATA_LOAD, deleteFullPartTimeDataService);  
}