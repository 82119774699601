import { QUEUE_TABLE_DATA, QUEUE_UPDATE_TABLE_DATA } from "./Constants";

const loadQueueTableDataRequest = () => ({
    type: QUEUE_TABLE_DATA.QUEUE_TABLE_DATA_LOAD
})
const setQueueTableDataSuccess = (queue_data) => ({
    type: QUEUE_TABLE_DATA.QUEUE_TABLE_DATA_SUCCESS,
    queue_data
})
const setQueueTableDataFail = (error) => ({
    type: QUEUE_TABLE_DATA.QUEUE_TABLE_DATA_FAIL,
    error
})

const loadQTUpdateDataRequest = (request) => ({
    type: QUEUE_UPDATE_TABLE_DATA.QUEUE_UPDATE_TABLE_DATA_LOAD,
    request,
});
const setQTUpdateDataSuccess = (module) => ({
    type: QUEUE_UPDATE_TABLE_DATA.QUEUE_UPDATE_TABLE_DATA_SUCCESS,
    module,
});
const setQTUpdateDataFail = (error) => ({
    type: QUEUE_UPDATE_TABLE_DATA.QUEUE_UPDATE_TABLE_DATA_FAIL,
    error,
});

export {
    loadQueueTableDataRequest, setQueueTableDataSuccess, setQueueTableDataFail,
    loadQTUpdateDataRequest, setQTUpdateDataSuccess, setQTUpdateDataFail
};