import { takeLatest, put, call } from "redux-saga/effects";
import {
    GROUPS_DATA, GROUPS_GET_API,
    GROUPS_GET_DATA, GROUPS_GET_DETAIL_API,
    GROUPS_ADD_DATA, GROUPS_POST_API,
    GROUPS_UPDATE_DATA, GROUPS_PUT_API,
    GROUPS_DELETE_DATA, GROUPS_DELETE_API,
} from './Constants'
import {
    setGroupsDataSuccess, setGroupsDataFail,
    setGroupsGetDataSuccess, setGroupsGetDataFail,
    setGroupsAddDataSuccess, setGroupsAddDataFail,
    setGroupsUpdateDataSuccess, setGroupsUpdateDataFail,
    setGroupsDeleteDataSuccess, setGroupsDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";

function* getAllGroupsDataService() {
    try {
        const response = yield call(getRequest, GROUPS_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setGroupsDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setGroupsDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving groups"))
        yield put(setGroupsDataFail(error));
    }
}

function* getGroupsDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            GROUPS_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setGroupsGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setGroupsGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving groups info"))
        yield put(setGroupsGetDataFail(error));
    }
}

function* postGroupsDataService({ request }) {
    try {
        const response = yield call(postRequest, GROUPS_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Group created"))
            yield put(setGroupsAddDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setGroupsAddDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error adding groups"))
        yield put(setGroupsAddDataFail(error));
    }
}
function* putGroupsDataService({ request }) {
    try {
        const response = yield call(putRequest, GROUPS_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Group updated"))
            yield put(setGroupsUpdateDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setGroupsUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error editing groups"))
        yield put(setGroupsUpdateDataFail(error));
    }
}

function* deleteGroupsDataService({ request }) {
    try {
        const response = yield call(deleteRequest, GROUPS_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setGroupsDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setGroupsDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setGroupsDeleteDataFail(error));
    }
}


export function* watchGroups(){
    yield takeLatest(GROUPS_DATA.GROUPS_DATA_LOAD, getAllGroupsDataService);
    yield takeLatest(GROUPS_GET_DATA.GROUPS_GET_DATA_LOAD, getGroupsDataService);
    yield takeLatest(GROUPS_ADD_DATA.GROUPS_ADD_DATA_LOAD, postGroupsDataService);
    yield takeLatest(GROUPS_UPDATE_DATA.GROUPS_UPDATE_DATA_LOAD, putGroupsDataService);
    yield takeLatest(GROUPS_DELETE_DATA.GROUPS_DELETE_DATA_LOAD, deleteGroupsDataService); 
}