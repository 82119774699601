import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import { instance, setTokenToHeader } from "../../../shared/utilites/ApiClient";
import {
    loadLoginDataRequest,
    loadRoleAccessGetDataRequest,
} from "../redux/Actions";

const Login2 = () => {
    const logoPath = "/company-logo.png";
    const [successful, setSuccessful] = useState(0);

    const validate = (values) => {
        let errors = {};
        if (!values.employee_id) {
            errors.employee_id = "Required";
        }
        if (!values.password) {
            errors.password = "Required";
        }
        return errors;
    };
    
    const submit = (values) => {
        instance.post('/loginv2', {
            employee_id: values.employee_id,
            password: values.password
        }).then(response => {
            setSuccessful(1)
        }).catch(e => {
            setSuccessful(-1)
        })
    };

    return (
        <div>
            <Row>
                <Col lg={4} md={2} />
                <Col lg={4} md={8}>
                    <Card style={{ marginTop: "10vh" }}>
                        <Card.Body>
                            <div style={{ margin: "30px" }}>
                                <span style={{ fontSize: "2rem" }}>
                                    <img
                                        src={logoPath}
                                        alt="logo icon"
                                        className="company-logo"
                                        style={{ marginRight: "15px", marginLeft: "40px" }}
                                    />
                                    Wireless Vision
                                </span>
                                <hr></hr>
                                <Formik
                                    validate={validate}
                                    initialValues={{
                                        employee_id: "",
                                        password: "",
                                    }}
                                    onSubmit={submit}
                                >
                                    <Form>
                                        <Row>
                                            <Col>
                                                <label className="form-label mt-3 mb-0">User ID</label>
                                                <Field
                                                    className="form-control"
                                                    name="employee_id"
                                                    autoComplete="off"
                                                    autoFocus
                                                ></Field>
                                                <span className="error-text">
                                                    <ErrorMessage name="employee_id" />
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <label className="form-label mt-3 mb-0">Password</label>
                                                <Field
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                    autoComplete="off"
                                                ></Field>
                                                <span className="error-text">
                                                    <ErrorMessage name="password" />
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Link to="/reset">Reset Password</Link>
                                            </Col>
                                            <Col className="col-auto">
                                                <DefaultButton
                                                    buttonTitle={"Login"}
                                                    buttonClass={"default-btn-color"}
                                                    buttonType={"submit"}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Formik>
                            </div>
                            {successful === 1 ? <div>Login successful</div> : ""}
                            {successful === -1 ? <div>Login failed</div> : ""}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Login2;
