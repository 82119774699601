import {
    DIVISION_DATA,
    DIVISION_ADD_DATA,
    DIVISION_UPDATE_DATA,
    DIVISION_GET_DATA,
    DIVISION_DELETE_DATA,
    DIVISION_IMPORT_DATA
} from "./Constants";

//For listing
const loadDivisionDataRequest = () => ({
    type: DIVISION_DATA.DIVISION_DATA_LOAD
})
const setDivisionDataSuccess = (divisions) => ({
    type: DIVISION_DATA.DIVISION_DATA_SUCCESS,
    divisions
})
const setDivisionDataFail = (error) => ({
    type: DIVISION_DATA.DIVISION_DATA_FAIL,
    error
})

//For add Division
const loadDivisionAddDataRequest = (request) => ({
    type: DIVISION_ADD_DATA.DIVISION_ADD_DATA_LOAD,
    request
})
const setDivisionAddDataSuccess = (division) => ({
    type: DIVISION_ADD_DATA.DIVISION_ADD_DATA_SUCCESS,
    division
})

const setDivisionAddDataFail = (error) => ({
    type: DIVISION_ADD_DATA.DIVISION_ADD_DATA_FAIL,
    error
})


//For Edit Division
const loadDivisionUpdateDataRequest = (request) => ({
    type: DIVISION_UPDATE_DATA.DIVISION_UPDATE_DATA_LOAD,
    request
})

const setDivisionUpdateDataSuccess = (division) => ({
    type: DIVISION_UPDATE_DATA.DIVISION_UPDATE_DATA_SUCCESS,
    division
})

const setDivisionUpdateDataFail = (error) => ({
    type: DIVISION_UPDATE_DATA.DIVISION_UPDATE_DATA_FAIL,
    error
})

//To Get Division Details
const loadDivisionGetDataRequest = (id) => ({
    type: DIVISION_GET_DATA.DIVISION_GET_DATA_LOAD,
    id
})

const setDivisionGetDataSuccess = (division) => ({
    type: DIVISION_GET_DATA.DIVISION_GET_DATA_SUCCESS,
    division
})

const setDivisionGetDataFail = (error) => ({
    type: DIVISION_GET_DATA.DIVISION_GET_DATA_FAIL,
    error
})

//Import Division
const loadDivisionImportDataRequest = (request) => ({
    type: DIVISION_IMPORT_DATA.DIVISION_IMPORT_DATA_LOAD,
    request,
  });
  
  const setDivisionImportDataSuccess = (division) => ({
    type: DIVISION_IMPORT_DATA.DIVISION_IMPORT_DATA_SUCCESS,
    division,
  });
  
  const setDivisionImportDataFail = (error) => ({
    type: DIVISION_IMPORT_DATA.DIVISION_IMPORT_DATA_FAIL,
    error,
  });


//Delete Division
const loadDivisionDeleteDataRequest = (request) => ({
    type: DIVISION_DELETE_DATA.DIVISION_DELETE_DATA_LOAD,
    request,
});

const setDivisionDeleteDataSuccess = (division) => ({
    type: DIVISION_DELETE_DATA.DIVISION_DELETE_DATA_SUCCESS,
    division,
});

const setDivisionDeleteDataFail = (error) => ({
    type: DIVISION_DELETE_DATA.DIVISION_DELETE_DATA_FAIL,
    error,
});


export {
    loadDivisionDataRequest,
    setDivisionDataSuccess,
    setDivisionDataFail,
    loadDivisionAddDataRequest,
    setDivisionAddDataSuccess,
    setDivisionAddDataFail,
    loadDivisionUpdateDataRequest,
    setDivisionUpdateDataSuccess,
    setDivisionUpdateDataFail,
    loadDivisionGetDataRequest,
    setDivisionGetDataSuccess,
    setDivisionGetDataFail,
    loadDivisionDeleteDataRequest,
    setDivisionDeleteDataSuccess,
    setDivisionDeleteDataFail,
    loadDivisionImportDataRequest,
    setDivisionImportDataSuccess,
    setDivisionImportDataFail
}
