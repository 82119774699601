import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { loadCompanyDataRequest } from "../../company/redux/Actions";
import { CANCEL, COMPANY, DIVISION_NAME, END_DATE, SAVE, STARTAFTEREND, STARTNOTFUTURE, START_DATE, ULTI_DIVISION_CODE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";


const DivisionForm = ({ division, submit, cancel }) => {
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (!values.division_name) {
            errors.division_name = "Required";
        }
        if (values.company_id === '') {
            errors.company_id = 'Required';
        }
        if (!values.start_date || values.start_date === '') {
            errors.start_date = "Required";
        }
        if (!values.end_date || values.end_date === '') {
            errors.end_date = "Required";
        }
        if (new Date(values.start_date) >= new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        if (new Date() >= new Date(values.start_date)){
            // errors.start_date = STARTNOTFUTURE
        }
        return errors;
    };

    let { companies } = useSelector(state => state.company);
    useEffect(() => {
        //don't reload companies if they're already loaded
        if (companies.length === 0) {
            dispatch(loadCompanyDataRequest())
        }
    }, [dispatch, companies.length])

    const formik = useFormik({});


    useEffect(() => {
        if (division) {
            formik.current.setFieldValue('id', division?.id);
            formik.current.setFieldValue('division_name', division?.division_name);
            formik.current.setFieldValue('ulti_division_code', division?.ulti_division_code);
            formik.current.setFieldValue('company_id', division?.company_id);
            if (division && division.start_date && division.end_date) {
                if (division?.start_date.includes("-") && division?.end_date.includes("-")) {
                    formik.current.setFieldValue('start_date', FormatDate(division?.start_date));
                    formik.current.setFieldValue('end_date', FormatDate(division?.end_date));
                }
            }
        }
    }, [division, formik])

    return <Formik
        innerRef={formik}
        initialValues={{
            division_name: '',
            ulti_division_code: '',
            company_id: '',
            start_date: '',
            end_date: '12/31/2099',
        }}
        validate={validate}
        onSubmit={submit}
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="division_name" className="form-label">
                        {DIVISION_NAME} <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='division_name' autoComplete="off" />
                    <span className="error-text">
                        <ErrorMessage name="division_name" />
                    </span>
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputCCode" className="form-label">
                        {ULTI_DIVISION_CODE}
                    </label>
                    <Field type="text" className="form-control" name="ulti_division_code" autoComplete="off" />
                    <span className="error-text">
                        <ErrorMessage name='ulti_division_code' />
                    </span>
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputMDCode" className="form-label">
                        {COMPANY} <span className="error-text">*</span>
                    </label>
                    <FormikSelectField
                        name="company_id"
                        placeholder={"Select Company"}
                        onChange={setFieldValue}
                        value={values.company_id}
                    >
                        {companies.map((company) => {
                            return <option key={company.id} value={company.id}>{company.company_name}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name='company_id' />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        {START_DATE} <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="start_date" value={values.start_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                    <span className='error-text'>
                        <ErrorMessage name="start_date" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        {END_DATE} <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="end_date" value={values.end_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                    <span className='error-text'>
                        <ErrorMessage name="end_date" />
                    </span>
                </div>
                <Col md={12}>
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik>
}

export default DivisionForm