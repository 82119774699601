//Get
const STORE_DATA = {
  STORE_DATA_LOAD: "STORE_DATA_LOAD",
  STORE_DATA_SUCCESS: "STORE_DATA_SUCCESS",
  STORE_DATA_FAIL: "STORE_DATA_FAIL",
};
const STORE_GET_API = "/store";

// Create / Add / Post
const STORE_ADD_DATA = {
  STORE_ADD_DATA_LOAD: "STORE_ADD_DATA_LOAD",
  STORE_ADD_DATA_SUCCESS: "STORE_ADD_DATA_SUCCESS",
  STORE_ADD_DATA_FAIL: "STORE_ADD_DATA_FAIL",
};

const STORE_POST_API = "/store";

// Get one by id
const STORE_GET_DATA = {
  STORE_GET_DATA_LOAD: "STORE_GET_DATA_LOAD",
  STORE_GET_DATA_SUCCESS: "STORE_GET_DATA_SUCCESS",
  STORE_GET_DATA_FAIL: "STORE_GET_DATA_FAIL",
};

const STORE_GET_DETAIL_API = "/store/tracker/";

//Update
const STORE_UPDATE_DATA = {
  STORE_UPDATE_DATA_LOAD: "STORE_UPDATE_DATA_UPLOAD",
  STORE_UPDATE_DATA_SUCCESS: "STORE_UPDATE_DATA_SUCCESS",
  STORE_UPDATE_DATA_FAIL: "STORE_UPDATE_DATA_FAIL",
};

const STORE_PUT_API = "/store";

// Delete
const STORE_DELETE_DATA = {
  STORE_DELETE_DATA_LOAD: "STORE_DELETE_DATA_LOAD",
  STORE_DELETE_DATA_SUCCESS: "STORE_DELETE_DATA_SUCCESS",
  STORE_DELETE_DATA_FAIL: "STORE_DELETE_DATA_FAIL",
};

const STORE_DELETE_API = "/store";

//Import
const STORE_IMPORT_DATA = {
  STORE_IMPORT_DATA_LOAD: "STORE_IMPORT_DATA_LOAD",
  STORE_IMPORT_DATA_SUCCESS: "STORE_IMPORT_DATA_SUCCESS",
  STORE_IMPORT_DATA_FAIL: "STORE_IMPORT_DATA_FAIL",
};

const STORE_IMPORT_API = "/upload";


//Add SAP Tracker
const STORE_SAP_TRACKER_ADD = {
  STORE_SAP_TRACKER_ADD_LOAD : 'STORE_SAP_TRACKER_ADD_LOAD',
  STORE_SAP_TRACKER_ADD_SUCCESS : 'STORE_SAP_TRACKER_ADD_SUCCESS',
  STORE_SAP_TRACKER_ADD_FAIL : 'STORE_SAP_TRACKER_ADD_FAIL',
}
const STORE_SAP_TRACKER_ADD_API = '/store/sap_tracker'


//Update SAP Tracker
const STORE_SAP_TRACKER_UPDATE = {
  STORE_SAP_TRACKER_UPDATE_LOAD : 'STORE_SAP_TRACKER_UPDATE_LOAD',
  STORE_SAP_TRACKER_UPDATE_SUCCESS : 'STORE_SAP_TRACKER_UPDATE_SUCCESS',
  STORE_SAP_TRACKER_UPDATE_FAIL : 'STORE_SAP_TRACKER_UPDATE_FAIL',
}
const STORE_SAP_TRACKER_UPDATE_API = '/store/sap_tracker'


//Delete SAP Tracker
const STORE_SAP_TRACKER_DELETE = {
  STORE_SAP_TRACKER_DELETE_LOAD : 'STORE_SAP_TRACKER_DELETE_LOAD',
  STORE_SAP_TRACKER_DELETE_SUCCESS : 'STORE_SAP_TRACKER_DELETE_SUCCESS',
  STORE_SAP_TRACKER_DELETE_FAIL : 'STORE_SAP_TRACKER_DELETE_FAIL',
}
const STORE_SAP_TRACKER_DELETE_API = '/store/sap_tracker'

//Add Density Tracker
const STORE_DENSITY_TRACKER_ADD = {
  STORE_DENSITY_TRACKER_ADD_LOAD : 'STORE_DENSITY_TRACKER_ADD_LOAD',
  STORE_DENSITY_TRACKER_ADD_SUCCESS : 'STORE_DENSITY_TRACKER_ADD_SUCCESS',
  STORE_DENSITY_TRACKER_ADD_FAIL : 'STORE_DENSITY_TRACKER_ADD_FAIL',
}
const STORE_DENSITY_TRACKER_ADD_API = '/store/density_tracker'


//Update Density Tracker
const STORE_DENSITY_TRACKER_UPDATE = {
  STORE_DENSITY_TRACKER_UPDATE_LOAD : 'STORE_DENSITY_TRACKER_UPDATE_LOAD',
  STORE_DENSITY_TRACKER_UPDATE_SUCCESS : 'STORE_DENSITY_TRACKER_UPDATE_SUCCESS',
  STORE_DENSITY_TRACKER_UPDATE_FAIL : 'STORE_DENSITY_TRACKER_UPDATE_FAIL',
}
const STORE_DENSITY_TRACKER_UPDATE_API = '/store/density_tracker'


//Delete Density Tracker
const STORE_DENSITY_TRACKER_DELETE = {
  STORE_DENSITY_TRACKER_DELETE_LOAD : 'STORE_DENSITY_TRACKER_DELETE_LOAD',
  STORE_DENSITY_TRACKER_DELETE_SUCCESS : 'STORE_DENSITY_TRACKER_DELETE_SUCCESS',
  STORE_DENSITY_TRACKER_DELETE_FAIL : 'STORE_DENSITY_TRACKER_DELETE_FAIL',
}
const STORE_DENSITY_TRACKER_DELETE_API = '/store/density_tracker'

//Add Comm Comp Group Tracker
const STORE_COMM_TRACKER_ADD = {
  STORE_COMM_TRACKER_ADD_LOAD : 'STORE_COMM_TRACKER_ADD_LOAD',
  STORE_COMM_TRACKER_ADD_SUCCESS : 'STORE_COMM_TRACKER_ADD_SUCCESS',
  STORE_COMM_TRACKER_ADD_FAIL : 'STORE_COMM_TRACKER_ADD_FAIL',
}
const STORE_COMM_TRACKER_ADD_API = '/store/comm_tracker'

//Update Comm Comp Group Tracker
const STORE_COMM_TRACKER_UPDATE = {
  STORE_COMM_TRACKER_UPDATE_LOAD : 'STORE_COMM_TRACKER_UPDATE_LOAD',
  STORE_COMM_TRACKER_UPDATE_SUCCESS : 'STORE_COMM_TRACKER_UPDATE_SUCCESS',
  STORE_COMM_TRACKER_UPDATE_FAIL : 'STORE_COMM_TRACKER_UPDATE_FAIL',
}
const STORE_COMM_TRACKER_UPDATE_API = '/store/comm_tracker'

//Delete Comm Comp Group Tracker
const STORE_COMM_TRACKER_DELETE = {
  STORE_COMM_TRACKER_DELETE_LOAD : 'STORE_COMM_TRACKER_DELETE_LOAD',
  STORE_COMM_TRACKER_DELETE_SUCCESS : 'STORE_COMM_TRACKER_DELETE_SUCCESS',
  STORE_COMM_TRACKER_DELETE_FAIL : 'STORE_COMM_TRACKER_DELETE_FAIL',
}
const STORE_COMM_TRACKER_DELETE_API = '/store/comm_tracker'

//Add Classification Tracker
const STORE_CLASSIFICATION_TRACKER_ADD = {
  STORE_CLASSIFICATION_TRACKER_ADD_LOAD : 'STORE_CLASSIFICATION_TRACKER_ADD_LOAD',
  STORE_CLASSIFICATION_TRACKER_ADD_SUCCESS : 'STORE_CLASSIFICATION_TRACKER_ADD_SUCCESS',
  STORE_CLASSIFICATION_TRACKER_ADD_FAIL : 'STORE_CLASSIFICATION_TRACKER_ADD_FAIL',
}
const STORE_CLASSIFICATION_TRACKER_ADD_API = '/store/classification_tracker'

//Update Classification Tracker
const STORE_CLASSIFICATION_TRACKER_UPDATE = {
  STORE_CLASSIFICATION_TRACKER_UPDATE_LOAD : 'STORE_CLASSIFICATION_TRACKER_UPDATE_LOAD',
  STORE_CLASSIFICATION_TRACKER_UPDATE_SUCCESS : 'STORE_CLASSIFICATION_TRACKER_UPDATE_SUCCESS',
  STORE_CLASSIFICATION_TRACKER_UPDATE_FAIL : 'STORE_CLASSIFICATION_TRACKER_UPDATE_FAIL',
}
const STORE_CLASSIFICATION_TRACKER_UPDATE_API = '/store/classification_tracker'

//Delete Classification Tracker
const STORE_CLASSIFICATION_TRACKER_DELETE = {
  STORE_CLASSIFICATION_TRACKER_DELETE_LOAD : 'STORE_CLASSIFICATION_TRACKER_DELETE_LOAD',
  STORE_CLASSIFICATION_TRACKER_DELETE_SUCCESS : 'STORE_CLASSIFICATION_TRACKER_DELETE_SUCCESS',
  STORE_CLASSIFICATION_TRACKER_DELETE_FAIL : 'STORE_CLASSIFICATION_TRACKER_DELETE_FAIL',
}
const STORE_CLASSIFICATION_TRACKER_DELETE_API = '/store/classification_tracker'

//Get All Store Tracker
const STORE_TRACKER_GET_ALL_DATA = {
  STORE_TRACKER_GET_ALL_DATA_LOAD:'STORE_TRACKER_GET_ALL_DATA_LOAD',
  STORE_TRACKER_GET_ALL_DATA_SUCCESS:'STORE_TRACKER_GET_ALL_DATA_SUCCESS', 
  STORE_TRACKER_GET_ALL_DATA_FAIL:'STORE_TRACKER_GET_ALL_DATA_FAIL'
}

const STORE_TRACKER_GET_ALL_API = '/store/sap_tracker'

//Import Store Tracker
const STORE_TRACKER_IMPORT_DATA = {
  STORE_TRACKER_IMPORT_DATA_LOAD:'STORE_TRACKER_IMPORT_DATA_LOAD',
  STORE_TRACKER_IMPORT_DATA_SUCCESS:'STORE_TRACKER_IMPORT_DATA_SUCCESS', 
  STORE_TRACKER_IMPORT_DATA_FAIL:'STORE_TRACKER_IMPORT_DATA_FAIL'
}

const STORE_TRACKER_IMPORT_API = '/upload'

//Get All Density Class Tracker
const DENSITYCLASS_TRACKER_GET_ALL_DATA = {
  DENSITYCLASS_TRACKER_GET_ALL_DATA_LOAD:'DENSITYCLASS_TRACKER_GET_ALL_DATA_LOAD',
  DENSITYCLASS_TRACKER_GET_ALL_DATA_SUCCESS:'DENSITYCLASS_TRACKER_GET_ALL_DATA_SUCCESS', 
  DENSITYCLASS_TRACKER_GET_ALL_DATA_FAIL:'DENSITYCLASS_TRACKER_GET_ALL_DATA_FAIL'
}

const DENSITYCLASS_TRACKER_GET_ALL_API = '/store/density_tracker'

//Get All Comm Comp Tracker
const COMMCOMP_TRACKER_GET_ALL_DATA = {
  COMMCOMP_TRACKER_GET_ALL_DATA_LOAD:'COMMCOMP_TRACKER_GET_ALL_DATA_LOAD',
  COMMCOMP_TRACKER_GET_ALL_DATA_SUCCESS:'COMMCOMP_TRACKER_GET_ALL_DATA_SUCCESS', 
  COMMCOMP_TRACKER_GET_ALL_DATA_FAIL:'COMMCOMP_TRACKER_GET_ALL_DATA_FAIL'
}

const COMMCOMP_TRACKER_GET_ALL_API = '/store/comm_tracker'

//Get All Store Classification Tracker
const SCLASSIFICATION_TRACKER_GET_ALL_DATA = {
  SCLASSIFICATION_TRACKER_GET_ALL_DATA_LOAD:'SCLASSIFICATION_TRACKER_GET_ALL_DATA_LOAD',
  SCLASSIFICATION_TRACKER_GET_ALL_DATA_SUCCESS:'SCLASSIFICATION_TRACKER_GET_ALL_DATA_SUCCESS', 
  SCLASSIFICATION_TRACKER_GET_ALL_DATA_FAIL:'SCLASSIFICATION_TRACKER_GET_ALL_DATA_FAIL'
}

const SCLASSIFICATION_TRACKER_GET_ALL_API = '/store/classification_tracker'


export {
  STORE_DATA,
  STORE_GET_API,
  STORE_ADD_DATA,
  STORE_POST_API,
  STORE_GET_DATA,
  STORE_GET_DETAIL_API,
  STORE_UPDATE_DATA,
  STORE_PUT_API,
  STORE_DELETE_DATA,
  STORE_DELETE_API,
  STORE_IMPORT_DATA,
  STORE_IMPORT_API,
  STORE_SAP_TRACKER_ADD,
  STORE_SAP_TRACKER_ADD_API,
  STORE_SAP_TRACKER_UPDATE,
  STORE_SAP_TRACKER_UPDATE_API,
  STORE_SAP_TRACKER_DELETE,
  STORE_SAP_TRACKER_DELETE_API,
  STORE_DENSITY_TRACKER_ADD,
  STORE_DENSITY_TRACKER_ADD_API,
  STORE_DENSITY_TRACKER_UPDATE,
  STORE_DENSITY_TRACKER_UPDATE_API,
  STORE_DENSITY_TRACKER_DELETE,
  STORE_DENSITY_TRACKER_DELETE_API,
  STORE_COMM_TRACKER_ADD,
  STORE_COMM_TRACKER_ADD_API,
  STORE_COMM_TRACKER_UPDATE,
  STORE_COMM_TRACKER_UPDATE_API,
  STORE_COMM_TRACKER_DELETE,
  STORE_COMM_TRACKER_DELETE_API,
  STORE_CLASSIFICATION_TRACKER_ADD,
  STORE_CLASSIFICATION_TRACKER_ADD_API,
  STORE_CLASSIFICATION_TRACKER_UPDATE,
  STORE_CLASSIFICATION_TRACKER_UPDATE_API,
  STORE_CLASSIFICATION_TRACKER_DELETE,
  STORE_CLASSIFICATION_TRACKER_DELETE_API,
  STORE_TRACKER_GET_ALL_DATA,
  STORE_TRACKER_GET_ALL_API,
  STORE_TRACKER_IMPORT_DATA,
  STORE_TRACKER_IMPORT_API,

  DENSITYCLASS_TRACKER_GET_ALL_DATA,
  DENSITYCLASS_TRACKER_GET_ALL_API,
  
  COMMCOMP_TRACKER_GET_ALL_DATA,
  COMMCOMP_TRACKER_GET_ALL_API,

  SCLASSIFICATION_TRACKER_GET_ALL_DATA,
  SCLASSIFICATION_TRACKER_GET_ALL_API,
};
