import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { CANCEL, EMPLOYEES, SAVE } from "../../../shared/constants/Constants";
import { loadActiveEmployeeDataRequest } from "../../employees/redux/Actions";
import { loadGroupsDataRequest } from "../../groups/redux/Actions";
import { loadRoleModulesDataRequest } from "../../role_module_mapping/redux/Actions";

const EmployeeRoleMappingForm = ({ empRole, submit, cancel }) => {

    const dispatch = useDispatch();
    const validate = (values) => {
        const errors = {};
        if (!values.employee_id || values.employee_id === '') {
            errors.employee_id = "Required";
        }
        console.log(errors)
        return errors;
    };

    let { active_employees } = useSelector(state => state.employee);
    let { groups } = useSelector(state => state.groups);
    let { roleModules } = useSelector(state => state.roleModules);
    useEffect(() => {
        if (active_employees.length === 0) {
            dispatch(loadActiveEmployeeDataRequest())
        }
        if (groups.length === 0) {
            dispatch(loadGroupsDataRequest());
        }
        if (roleModules.length === 0) {
            dispatch(loadRoleModulesDataRequest());
        }
    }, [dispatch, active_employees.length, groups.length, roleModules.length])

    let [activeRoles, setActiveRoles] = useState([])
    useEffect(() => {
        if (empRole){
            setActiveRoles(roleModules.filter((entry)=>{
                return empRole.groupIds.includes(Number(entry.group_id))
            }))
        }else{
            setActiveRoles(roleModules)
        }
    }, [groups, roleModules, empRole])

    return (
        <Formik
            initialValues={{
                employee_id: empRole?.employee_id ?? [],
                group_id: empRole?.groupIds ?? [],
                role_module_id: empRole?.roleIds ?? []
            }}
            validate={validate}
            onSubmit={submit}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form className="row g-4">
                    <FormikSelectField
                        name="employee_id"
                        placeholder={"Select Employee"}
                        onChange={setFieldValue}
                        value={[values.employee_id]}
                        label={EMPLOYEES}
                        required
                        isMulti
                    >
                        {active_employees.map((employee) => {
                            return <option key={employee.employee_id} value={employee.employee_id}>{(employee?.first_name ?? "") + " " + (employee?.last_name ?? "") + ", " + (employee?.employee_id ?? '')}</option>
                        })}
                    </FormikSelectField>
                    <FormikSelectField
                        name="group_id"
                        placeholder={"Select Group"}
                        onChange={(name, value) => {
                            setFieldValue(name, value)
                            console.log(value)
                            setActiveRoles(roleModules.filter((role) => {
                                for (let entry of value){
                                    if (role.group_id == entry){
                                        return true
                                    }
                                }
                                return role.group_id == value
                            }
                            ))
                            let newRoles = values.role_module_id.filter((role)=>{
                                return value.includes(Number(role.split(',')[1]))
                            })
                            setFieldValue('role_module_id', newRoles)
                        }}
                        value={values.group_id}
                        label={"Group Name"}
                        required
                        isMulti
                    >
                        {groups.map((group) => {
                            return <option key={group.id} value={group.id}>{group.group_name}</option>
                        })}
                    </FormikSelectField>
                    <FormikSelectField
                        name="role_module_id"
                        placeholder={"Select Role"}
                        onChange={setFieldValue}
                        value={values.role_module_id}
                        label={"Role"}
                        required
                        isMulti
                    >
                        {activeRoles.map((roleModule) => {
                            return <option key={roleModule.id} value={roleModule.id + ","+roleModule.group_id}>{roleModule.role_name}</option>
                        })}
                    </FormikSelectField>
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default EmployeeRoleMappingForm;
