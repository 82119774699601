import { Component } from 'react';
import Select from "react-select";

export default class FloatingDropdownFilter extends Component {
    constructor(params) {
        super()
        this.state = {
            options: params.dropdownOptions.map((option) => ({ value: option, label: option })),
            value: ''
        }

        params.dropdownFunction(() => (newOptions) => {
            this.setState({
                options: newOptions
            })
        })
    }
    onParentModelChanged(parentModel) {
        this.setState({ value: parentModel?.filter?? null })
    }

    onInputBoxChanged = input => {
        this.setState({
            value: input?.value
        })
        this.props.parentFilterInstance(instance => {
            if (input) {
                instance.onFloatingFilterChanged('equals', input.value);
            } else {
                instance.onFloatingFilterChanged(null, null);
            }
        });
    }
    customStyles = {
        menu: (provided, state) => ({
            ...provided,
            maxWidth: 600,
            width: 'auto',
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: 0,
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
        valueContainer: (provided, state) =>({
            ...provided,
            padding:'0 9px',
        })
    }
    render() {
        return <Select isClearable styles={this.customStyles} value={this.state.options.find((option) => option.value === this.state.value)??null} options={this.state.options} onChange={this.onInputBoxChanged} />
    }
}
