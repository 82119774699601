import { ErrorMessage, Field, Formik, useFormik, Form, FieldArray } from "formik";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import FormTextField from "../../../shared/components/FormTextField";
import { CANCEL, GROUPS, ROLES, SAVE } from "../../../shared/constants/Constants";
import { loadGroupsDataRequest } from "../../groups/redux/Actions";
import { loadModuleDataRequest } from "../../module_meta_data/redux/Actions";
import { loadRolesDataRequest } from "../../roles/redux/Actions";

export const RoleModuleMappingForm = ({ roleModule, submit, cancel }) => {

    const dispatch = useDispatch();
    const validate = (values) => {
        const errors = {};
        if (values.role_name == '') {
            errors.role_name = 'Required';
        }
        if (!values.group_id || values.group_id == '') {
            errors.group_id = "Required";
        }
        let moduleErrors = []
        values.modules.forEach((value, i) => {
            if (value.module_metadata_id == '') {
                moduleErrors[i] = 'required'
            }
        })
        if (moduleErrors.length > 0) {
            errors.modules = moduleErrors;
        }
        return errors;
    };

    let { roles } = useSelector(state => state.roles);
    let { modules } = useSelector(state => state.modules);
    let { groups } = useSelector(state => state.groups);
    useEffect(() => {
        //don't reload roles if they're already loaded
        if (roles.length === 0) {
            dispatch(loadRolesDataRequest())
        }
        if (modules.length === 0) {
            dispatch(loadModuleDataRequest());
        }
        if (groups.length === 0) {
            dispatch(loadGroupsDataRequest());
        }
    }, [dispatch, roles.length, modules.length])

    return (
        <Formik
            initialValues={{
                role_id: roleModule?.role_id ?? null,
                role_name: roleModule?.role_name ?? "",
                group_id: roleModule?.group_id ?? "",
                modules: roleModule?.data?.[0] ?? [{
                    module_metadata_id: '',
                    read_permission: false,
                    write_permission: false,
                    create_permission: false,
                    delete_permission: false
                }]
            }}
            validate={validate}
            onSubmit={submit}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form className="row g-4">
                    <FormTextField
                        name="role_name"
                        label="Role"
                        required
                    />
                    <FormikSelectField
                        name="group_id"
                        placeholder={"Select Group"}
                        onChange={setFieldValue}
                        value={values.group_id}
                        label={GROUPS}
                        required
                    >
                        {groups.map((group) => {
                            return <option key={group.id} value={group.id}>{group.group_name}</option>
                        })}
                    </FormikSelectField>
                    <FieldArray
                        name="modules"
                        render={arrayHelpers => (
                            <div>
                                <span style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                                    Modules
                                </span>
                                {values.modules && values.modules.length > 0 ? (
                                    values.modules.map((module, index) => (
                                        <Row className="role-permissions-row" key={index}>
                                            <Col xl={4} xs={12}> 
                                                <FormikSelectField
                                                    name={`modules.${index}.module_metadata_id`}
                                                    placeholder={"Select Module"}
                                                    onChange={setFieldValue}
                                                    value={values.modules[index].module_metadata_id}
                                                >
                                                    {modules.filter((module)=>{

                                                        if (index == 1){
                                                            // console.log(values.modules, module.id)
                                                        }
                                                        for (let [i, m] of values.modules.entries()){
                                                            if (m.module_metadata_id === module.id && i != index){
                                                                return false
                                                            }
                                                        }
                                                        return true
                                                    }).map((module) => {
                                                        return <option key={module.id} value={module.id}>{module.module_name}</option>
                                                    })}
                                                </FormikSelectField>
                                                <span className="error-text">
                                                    <ErrorMessage name={`modules.${index}`} />
                                                </span>
                                            </Col>
                                            <Col>
                                                <label>
                                                    <Field className="form-check-input" type='checkbox' name={`modules.${index}.read_permission`} />
                                                    {" Read"}
                                                </label>
                                            </Col>
                                            <Col>
                                                <label>
                                                    <Field className="form-check-input" type='checkbox' name={`modules.${index}.write_permission`} />
                                                    {" Write"}
                                                </label>
                                            </Col>
                                            <Col>
                                                <label>
                                                    <Field className="form-check-input" type='checkbox' name={`modules.${index}.delete_permission`} />
                                                    {" Delete"}
                                                </label>
                                            </Col>
                                            <Col>
                                                <label>
                                                    <Field className="form-check-input" type='checkbox' name={`modules.${index}.create_permission`} />
                                                    {" Create"}
                                                </label>
                                            </Col>
                                            <Col>
                                                <DefaultButton
                                                    buttonClass="mx-1"
                                                    buttonTitle={'-'}
                                                    actionBlock={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                />
                                                <DefaultButton
                                                    buttonTitle={'+'}
                                                    actionBlock={() => arrayHelpers.insert(index + 1,
                                                        {
                                                            module_metadata_id: '',
                                                            read_permission: false,
                                                            write_permission: false,
                                                            create_permission: false,
                                                            delete_permission: false
                                                        })
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    ))
                                ) : (
                                    <DefaultButton
                                        buttonTitle={"Add a module"}
                                        actionBlock={() => arrayHelpers.push({
                                            module_metadata_id: '',
                                            read_permission: false,
                                            write_permission: false,
                                            create_permission: false,
                                            delete_permission: false
                                        })}
                                    />
                                )}
                            </div>
                        )}
                    />
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};