import DatePicker from "react-datepicker"
// import moment from "moment/moment";
// import { format } from "date-fns";

const FormikDatePickerField = ({ name, value, onChange, handleBlur, placeholder, readOnly=false }) => {
    let dateObj = new Date(value);
    // let dateObj = format(value, "M-dd-yyyy")

    // let date = dateObj.getUTCDate();
    // let month = dateObj.getUTCMonth();
    // let year = dateObj.getUTCFullYear();
    
    // dateObj.setDate(date)
    // dateObj.setMonth(month)
    // dateObj.setFullYear(year)

    return (
        <DatePicker
            selected={value? dateObj:null}
            // selected={moment(value).format('ddd MMM DD YYYY')}
            onChange={val => {
                handleBlur(name,true);
                onChange(name, val? val : null);
            }}
            readOnly={readOnly}
            placeholderText={placeholder}
            autoComplete="off"
            dateFormat="MM-dd-yyyy"
        />
    );
};

export default FormikDatePickerField