import { takeLatest, put, call } from "redux-saga/effects";
import {
  COMP_GROUP_DATA,
  COMP_GROUP_GET_API,
  COMP_GROUP_ADD_DATA,
  COMP_GROUP_POST_API,
  COMP_GROUP_GET_DATA,
  COMP_GROUP_GET_DETAIL_API,
  COMP_GROUP_UPDATE_DATA,
  COMP_GROUP_PUT_API,
  COMP_GROUP_DELETE_DATA,
  COMP_GROUP_DELETE_API,
} from "./Constants";
import {
  setCompGroupDataSuccess,
  setCompGroupDataFail,
  setCompGroupAddDataSuccess,
  setCompGroupAddDataFail,
  setCompGroupGetDataSuccess,
  setCompGroupGetDataFail,
  setCompGroupUpdateDataSuccess,
  setCompGroupUpdateDataFail,
  setCompGroupDeleteDataSuccess,
  setCompGroupDeleteDataFail
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getCompGroupsDataService() {
  try {
    const groupsResponse = yield call(
      getRequest,
      COMP_GROUP_GET_API
    );
    const status = groupsResponse.status;
    if (status === 200) {
      const data = groupsResponse.data;
      yield put(setCompGroupDataSuccess(data));
    } else {
      yield put(setCompGroupDataFail(groupsResponse.response.data));
    }
  } catch (error) {
    yield put(setCompGroupDataFail(error));
  }
}

function* postCompGroupDataService({ request }) {
  try {
    const response = yield call(
      postRequest,
      COMP_GROUP_POST_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Commission comp group created"))
      yield put(setCompGroupAddDataSuccess(newData));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setCompGroupAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not create commission comp group"))
    yield put(setCompGroupAddDataFail(error));
  }
}

function* getCompGroupDataService({ id }) {
  try {
    const getCompGroupDataResponse = yield call(
      getDetailRequest,
      COMP_GROUP_GET_DETAIL_API + id
    );
    const status = getCompGroupDataResponse.status;
    if (status === 200) {
      const newData = getCompGroupDataResponse.data;
      yield put(setCompGroupGetDataSuccess(newData));
    } else {
      yield put(setCompGroupGetDataFail(getCompGroupDataResponse.response.data));
    }
  } catch (error) {
    yield put(setCompGroupGetDataFail(error));
  }
}

function* putCompGroupDataService({ request }) {
  try {
    const response = yield call(putRequest, COMP_GROUP_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Commission comp group updated"))
      yield put(setCompGroupUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setCompGroupUpdateDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not update commission comp group"))
    yield put(setCompGroupUpdateDataFail(error));
  }
}

function* deleteCompGroupDataService({ request }) {
  try {
    const response = yield call(deleteRequest, COMP_GROUP_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Commission comp group deleted"))
      yield put(setCompGroupDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setCompGroupDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not delete commission comp group"))
    yield put(setCompGroupDeleteDataFail(error));
  }
}

export function* watchCompGroup() {
  yield takeLatest(
    COMP_GROUP_DATA.COMP_GROUP_DATA_LOAD,
    getCompGroupsDataService
  );
  yield takeLatest(
    COMP_GROUP_ADD_DATA.COMP_GROUP_ADD_DATA_LOAD,
    postCompGroupDataService
  );
  yield takeLatest(
    COMP_GROUP_GET_DATA.COMP_GROUP_GET_DATA_LOAD,
    getCompGroupDataService
  );
  yield takeLatest(
    COMP_GROUP_UPDATE_DATA.COMP_GROUP_UPDATE_DATA_LOAD,
    putCompGroupDataService
  );
  yield takeLatest(
    COMP_GROUP_DELETE_DATA.COMP_GROUP_DELETE_DATA_LOAD,
    deleteCompGroupDataService
  );
}
