import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const GeneralInfo = ({info}) => {

  // {info}
  console.log("info::", info);

  return (
    <>
      <Row>
        <Col>
          <dl className="row">
            <dt className="col-sm-4">Store Name</dt>
            <dd className="col-sm-8">{info?.store_name}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Address</dt>
            <dd className="col-sm-8">{info?.address}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Acquisition Dealer</dt>
            <dd className="col-sm-8">{info?.acquisition_dealer_name}</dd>
          </dl>
          <dl className="row">
            <dt className="col-sm-4">Store Classification</dt>
            <dd className="col-sm-8">{info?.store_classification_name}</dd>
          </dl>
          {/* <dl className="row">
            <dt className="col-sm-4">Current Location Time</dt>
            <dd className="col-sm-8">{info[0]?.store_name}</dd>
          </dl> */}
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col md={8}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Title</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>RSM</td>
                <td>{info?.rsm.first_name}</td>
                <td>{info?.rsm.last_name}</td>
                <td>{info?.rsm.phone}</td>
                <td>{info?.rsm.Email}</td>
              </tr>
              <tr>
                <td>DSOM</td>
                <td>{info?.dsom.first_name}</td>
                <td>{info?.dsom.last_name}</td>
                <td>{info?.dsom.phone}</td>
                <td>{info?.dsom.Email}</td>
              </tr>
              <tr>
                <td>DOSO</td>
                <td>{info?.doso.first_name}</td>
                <td>{info?.doso.last_name}</td>
                <td>{info?.doso.phone}</td>
                <td>{info?.doso.Email}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default GeneralInfo;
