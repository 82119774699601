import {
  STORE_HOURS_DATA,
  STORE_HOURS_DELETE_DATA,
  STORE_HOURS_IMPORT_DATA,
} from "./Constants";

const initialState = {
    loading: false,
    error: null,
    shours: [],
    shour: {}
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_HOURS_DATA.STORE_HOURS_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                shours: []
            }
        case STORE_HOURS_DATA.STORE_HOURS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                shours: action.shours,
                error: null
            }
        case STORE_HOURS_DATA.STORE_HOURS_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                shours: []
            }
       
        //Delete
        case STORE_HOURS_DELETE_DATA.STORE_HOURS_DELETE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                shour: {},
                error: null
            }
        case STORE_HOURS_DELETE_DATA.STORE_HOURS_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                shour: action.shour,
                error: null
            }
        case STORE_HOURS_DELETE_DATA.STORE_HOURS_DELETE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                shour: {}, 
                error: action.error
            }
        
        //Import 
        case STORE_HOURS_IMPORT_DATA.STORE_HOURS_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                shours:[]
            }
        case STORE_HOURS_IMPORT_DATA.STORE_HOURS_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                shours:action.shours,
                error:null
            }
        case STORE_HOURS_IMPORT_DATA.STORE_HOURS_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                shours:[]
            }

        default:
            return {
                ...state
            }
    }
}

export default Reducer;