import { QUEUE_TABLE_DATA, QUEUE_UPDATE_TABLE_DATA } from './Constants'

const initialState = {
    loading: false,
    error: null,
    queue_data: []
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case QUEUE_TABLE_DATA.QUEUE_TABLE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                queue_data:[]
            }
        case QUEUE_TABLE_DATA.QUEUE_TABLE_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                error:null,
                queue_data:action.queue_data
            }
        case QUEUE_TABLE_DATA.QUEUE_TABLE_DATA_FAIL:
            return{
                ...state,
                loading:false,
                error:action.error
            }
        //Update Queue Table
        case QUEUE_UPDATE_TABLE_DATA.QUEUE_UPDATE_TABLE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case QUEUE_UPDATE_TABLE_DATA.QUEUE_UPDATE_TABLE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case QUEUE_UPDATE_TABLE_DATA.QUEUE_UPDATE_TABLE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return {
                ...state
            }
    }
}

export default Reducer;