import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import DefaultButton from '../../../shared/components/DefaultButton';
import ImportModal from '../../../shared/components/ImportModal';
import ListView from '../../../shared/components/listview/ListView';
import Permissions from '../../../shared/components/Permissions';
import { REGIONTRACKER, IMPORTREGION, IMPORT } from '../../../shared/constants/Constants'
import { loadGetRegionTrackerDataRequest, loadRegionTrackerImportDataRequest } from '../redux/Actions'

const RegionTracker = () => {

    let [showImportModal, setShowImportModal] = useState(false);
    let dispatch = useDispatch();
    let { trackers } = useSelector((state) => state.region);


    let regionColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'Region Name',
            field: 'region_name',
            type: 'text'
        }, {
            display_name: 'Division Name',
            field: 'division_name',
            type: 'text'
        }, {
            display_name: 'Start Date',
            field: 'start_date',
            type: 'date'
        }, {
            display_name: 'End Date',
            field: 'end_date',
            type: 'date'
        }
    ];

    useEffect(() => {
        dispatch(loadGetRegionTrackerDataRequest());
    }, [dispatch])

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Region Tracker";
    let permission = Permissions(emp_no, module_name);

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { href: '/region-tracker', label: REGIONTRACKER },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission?(
                        <DefaultButton
                            buttonClass={"default-btn-color me-2"}
                            buttonTitle={IMPORT}
                            actionBlock={() => { setShowImportModal(true) }}
                        />
                    ):(<></>)}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle="Region Tracker"
                            columnNames={regionColumns}
                            rowData={trackers}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName={"region_tracker"}
                modalTitle={IMPORTREGION}
                importAction={loadRegionTrackerImportDataRequest}
            />
        </>
    )
}

export default RegionTracker
