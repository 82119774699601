import { instance, loginInstance } from "../utilites/ApiClient";

const getRequest = async (url, options) => {
  try {
    const response = await instance.get(url, options);
    return response;
  } catch (error) {
    if (error.response.status === 401){
      window.location.reload()
    }
    return error;
  }
};

const postRequest = async (url, request) => {
  try {
    const response = await instance.post(url, request);
    return response;
  } catch (error) {
    if (error.response.status === 401){
      window.location.reload()
    }
    return error;
  }
};

const getDetailRequest = async (url, id) => {
  try {
    const response = await instance.get(url, id);
    return response;
  } catch (error) {
    if (error.response.status === 401){
      window.location.reload()
    }
    return error;
  }
};

const putRequest = async (url, request) => {
  try {
    const response = await instance.put(url, request);
    return response;
  } catch (error) {
    if (error.response.status === 401){
      window.location.reload()
    }
    return error;
  }
};

const deleteRequest = async (url, request) => {
  try {
    const response = await instance.delete(url, { data: request });
    return response;
  } catch (error) {
    if (error.response.status === 401){
      window.location.reload()
    }
    return error;
  }
};

const postImportRequest = async (url, request) => {
  try {
    instance.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    const response = await instance.post(url, request['data']);
    return response;
  } catch (error) {
    if (error.response.status === 401){
      window.location.reload()
    }
    return error;
  }
};

const loginPostRequest = async (url, request) => {
  try {
    const response = await loginInstance.post(url, request);
    return response;
  } catch (error) {
    if (error.response.status === 401){
      window.location.reload()
    }
    return error;
  }
};

export {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
  postImportRequest,
  loginPostRequest
};
