import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { SAVE, CANCEL } from "../../../shared/constants/Constants";


const QTUpdate = ({ qData, submit, handleClose }) => {

    let empNo = JSON.parse(localStorage.getItem("emp_no"));
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (values.status === '') {
            errors.status = 'Required';
        }
        return errors;
    };


    const formik = useFormik({});

    useEffect(() => {
        if (qData) {
            formik.current.setFieldValue('id', qData?.id);
            formik.current.setFieldValue('employee_id', empNo);
            formik.current.setFieldValue('status', qData?.status);
            formik.current.setFieldValue('reference_id', qData?.reference_id);            
        }
    }, [qData, formik])

    let statuses = [{ "name": "Done", "value": "done" }, {"name": "Odoo Pending", "value": "odoo_pending"}]

    return <Formik
        innerRef={formik}
        initialValues={{
            status: '',
        }}
        validate={validate}
        onSubmit={submit}
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="employee_id" className="form-label">
                        Employee Number <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='employee_id' readOnly={true} autoComplete="off" defaultValue={qData?.updated_employee_no} />
                </div>
                <div className="col-md-6">
                    <label htmlFor="reference_id" className="form-label">
                        Reference Id <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='reference_id' readOnly={true} autoComplete="off" defaultValue={qData?.reference_id} />
                </div>
                <div className="col-md-6">
                    <label htmlFor="command" className="form-label">
                        Command <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='command' readOnly={true} autoComplete="off" defaultValue={qData?.command} />
                </div>
                <div className="col-md-6">
                    <label htmlFor="object_type" className="form-label">
                        Object Type <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='object_type' readOnly={true} autoComplete="off" defaultValue={qData?.object_type} />
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputMDCode" className="form-label">
                        Status <span className="error-text">*</span>
                    </label>                    
                    <FormikSelectField
                        name="status"
                        placeholder={"Select Status"}
                        onChange={setFieldValue}
                        value={values.status}
                    >
                        {statuses.map(entry => <option value={entry.value}>{entry.name}</option>)}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name='status' />
                    </span>
                </div>
                <Col md={12}>
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={handleClose}
                    />
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik>
}

export default QTUpdate