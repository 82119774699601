import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CANCEL, SAVE, STORE_CLASSIFICATION_NAME, TRAFFIC_REDUCTION } from "../../../shared/constants/Constants";

const ClassificationForm = ({ classification, submit, cancel }) => {
  const validate = (values) => {
    const errors = {};
    if (!values.store_classification_name) {
      errors.store_classification_name = "Required";
    }
    if (!values.traffic_reduction) {
      errors.traffic_reduction = "Required"
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{
        id:classification?.id??'',
        store_classification_name: classification?.store_classification_name ?? "",
        traffic_reduction: classification?.traffic_reduction ?? "",
      }}
      validate={validate}
      onSubmit={submit}
      enableReinitialize
    >
      {() => (
        <Form className="row g-4">
          <div className="col-md-6">
            <label htmlFor="store_classification_name" className="form-label">
              {STORE_CLASSIFICATION_NAME}<span className="error-text">*</span>
            </label>
            <Field
              className="form-control"
              type="text"
              name="store_classification_name"
              placeholder="Enter Store Classification Name"
              autoComplete="off"
            />
            <span className="error-text">
              <ErrorMessage name="store_classification_name" />
            </span>
          </div>
          <div className="col-md-6">
            <label htmlFor="traffic_reduction" className="form-label">
              {TRAFFIC_REDUCTION}<span className="error-text">*</span>
            </label>
            <Field
              className="form-control"
              type="text"
              name="traffic_reduction"
              placeholder="Enter Traffic Reduction"
              autoComplete="off"
            />
            <span className="error-text">
              <ErrorMessage name="traffic_reduction" />
            </span>
          </div>
          <div className="col-md-12">
            <DefaultButton
              buttonClass={"btn btn-secondary me-2"}
              buttonTitle={CANCEL}
              actionBlock={cancel}
            />
            <DefaultButton
              buttonClass={"default-btn-color"}
              buttonTitle={SAVE}
              buttonType={"submit"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ClassificationForm;
