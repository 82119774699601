import {
    RF_DATA,
} from './Constants'


//For listing
const loadRfDataRequest = (request) => ({
    type: RF_DATA.RF_DATA_LOAD,
    request
});
const setRfDataSuccess = (rf_Visits) => ({
    type: RF_DATA.RF_DATA_SUCCESS,
    rf_Visits,
});
const setRfDataFail = (error) => ({
    type: RF_DATA.RF_DATA_FAIL,
    error,
});

export {
    loadRfDataRequest, setRfDataSuccess, setRfDataFail
}