import { ErrorMessage, Formik, Form } from "formik";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import FormTextField from "../../../shared/components/FormTextField";
import { DIVISION, EMPLOYEE_DEPARTMENT, HIRE_STATUS, HOURLY_SALARY, JOB_TITLE, MARKET, PART_FULL_TIME, REGION, SAVE, STORE, TERM_REASON } from "../../../shared/constants/Constants";
import { loadTermReasonDataRequest } from "../../term-reason/redux/Actions";
import { loadEmployeeStatusDataRequest } from "../../employee_status/redux/Actions";
import { loadActiveEmployeeDataRequest } from "../redux/Actions"
import DisabledTextField from "../../../shared/components/DisabledTextField";
import FormatDate from "../../../shared/components/FormatDate";

const EmployeeFormEdit = ({ employee, submit }) => {

    let navigate = useNavigate();
    let dispatch = useDispatch();

    let {role} = JSON.parse(localStorage.getItem('employee'));

    const validate = (values) => {
        const errors = {};
        if (!values.employee_id) {
            errors.employee_id = "Required";
        }
        if (new Date(values.hire_date) > new Date(values.term_date)) {
            errors.term_date = "Termination date should not be earlier than the hire date."
        }
        return errors;
    };

    let { jobtitles } = useSelector((state) => state.jobtitle);
    let { departments } = useSelector((state) => state.department);
    let { termReasons } = useSelector((state) => state.termReason);
    let { employeeStatuses } = useSelector((state) => state.employeeStatus)
    let { active_employees } = useSelector((state) => state.employee)
    useEffect(() => {
        if (termReasons.length === 0) {
            dispatch(loadTermReasonDataRequest());
        }
        if (employeeStatuses.length === 0) {
            dispatch(loadEmployeeStatusDataRequest());
        }
        if (active_employees.length === 0) {
            dispatch(loadActiveEmployeeDataRequest())
        }
    }, [dispatch, jobtitles.length, departments.length, employeeStatuses.length])


    let hireDate = '';
    if (employee && employee?.hire_date) {
        if (employee?.hire_date.includes("-")) {
            hireDate = FormatDate(employee?.hire_date); 
        }
    }
    let termDate = '12/31/2099';
    if (employee && employee?.term_date) {
        if (employee?.term_date.includes("-")) {
            termDate = FormatDate(employee?.term_date);
        }
    }


    return <Formik
        initialValues={{
            id: employee?.id,
            employee_id: employee?.employee_id,
            first_name: employee?.first_name,
            last_name: employee?.last_name,
            preferred_name: employee?.preferred_name,
            ntid: employee?.ntid,
            cnumber: employee?.cnumber,
            email_work: employee?.email_work,
            email_personal: employee?.email_personal,
            hire_date: hireDate,
            term_date: termDate,
            term_reason_id: employee?.term_reason_id,
            work_phone: (employee?.work_phone != "" || employee?.work_phone != "null") ? employee?.work_phone : null,
            personal_phone: employee?.personal_phone,
            home_address: employee?.home_address,
            supervisor: (employee?.supervisor != "") ? employee?.supervisor : null,
        }}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">

                <FormTextField name='employee_id' label="Employee Number" required disabled />
                <FormTextField name='first_name' label='First Name' required />
                <FormTextField name='last_name' label='Last Name' required />
                <FormTextField name='preferred_name' label='Preferred Name' />
                <FormTextField name='work_phone' label='Work Phone' />
                <FormTextField name='personal_phone' label='Personal Phone' />
                <FormTextField name='home_address' label='Home Address' />
                <FormTextField name='email_work' label='Work Email' required />
                <FormTextField name='email_personal' label='Personal Email' />
                <FormTextField name='ntid' label='NTID' />
                <FormTextField name='cnumber' label='CNumber' />
                <DisabledTextField label={JOB_TITLE} value={employee?.job_title_name} />
                <DisabledTextField label={EMPLOYEE_DEPARTMENT} value={employee?.department_name} />
                <DisabledTextField label={STORE} value={employee?.store_sap_name} />
                <DisabledTextField label={MARKET} value={employee?.market_name} />
                <DisabledTextField label={REGION} value={employee?.region_name} />
                <DisabledTextField label={DIVISION} value={employee?.division_name} />
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        Hire Date
                    </label>
                    <FormikDatePickerField name="hire_date" value={values.hire_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                    <span className='error-text'>
                        <ErrorMessage name="hire_date" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        Termination Date
                    </label>
                    <FormikDatePickerField name="term_date" value={values.term_date} onChange={setFieldValue} handleBlur={setFieldTouched} readOnly={employee?.emp_status === 'Terminated' && role !== 'admin'}/>
                    <span className='error-text'>
                        <ErrorMessage name="term_date" />
                    </span>
                </div>
                <div className="col-md-6">
                    <label className="form-label">
                        {TERM_REASON}
                    </label>
                    <FormikSelectField
                        name="term_reason_id"
                        placeholder={"Select Term Reason"}
                        onChange={setFieldValue}
                        value={values.term_reason_id}
                    >
                        <option value={null}>Select Term Reason</option>
                        {termReasons.map((termReason) => {
                            return <option key={termReason.id} value={termReason.id}>{termReason.term_reason}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name="term_reason_id" />
                    </span>
                </div>
                <FormikSelectField
                    name="supervisor"
                    label="Supervisor"
                    placeholder={"Select Supervisor"}
                    onChange={setFieldValue}
                    value={values.supervisor}
                >
                    <option key='0' value=''>Select Supervisor</option>
                    {active_employees.map((employee) => {
                        return <option key={employee.id} value={employee.id}>{employee.first_name + " " + employee.last_name+","+employee.employee_id }</option>
                    })}
                </FormikSelectField>
                {/* <div className="col-md-6">
                    <label className="form-label">
                        Employee Status
                    </label>
                    <FormikSelectField
                        name="emp_status"
                        placeholder={"Select Status"}
                        onChange={setFieldValue}
                        value={values.emp_status}
                    >
                        {employeeStatuses.map((employeeStatus) => {
                            return <option key={employeeStatus.id} value={employeeStatus.id}>{employeeStatus.name}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name="emp_status" />
                    </span>
                </div> */}
                <FormTextField name="hire_status_name" label={HIRE_STATUS} disabled />
                <FormTextField name="full_part_time_name" label={PART_FULL_TIME} disabled />
                <FormTextField name="hourly_salary_name" label={HOURLY_SALARY} disabled />
                <Col md={12} className='mt-3'>
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={"Back"}
                        actionBlock={() => {
                            navigate('/employees/' + employee.id)
                        }}
                    />
                    <DefaultButton
                        buttonClass={"btn btn-secondary default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik>
}

export default EmployeeFormEdit

