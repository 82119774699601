import { SEARCH_SAP_GET_DATA } from "./Constants";

//To Get
const loadSearchSAPGetDataRequest = (id) => ({
  type: SEARCH_SAP_GET_DATA.SEARCH_SAP_GET_DATA_LOAD,
  id,
});

const setSearchSAPGetDataSuccess = (searchsap) => ({
  type: SEARCH_SAP_GET_DATA.SEARCH_SAP_GET_DATA_SUCCESS,
  searchsap,
});

const setSearchSAPGetDataFail = (error) => ({
  type: SEARCH_SAP_GET_DATA.SEARCH_SAP_GET_DATA_FAIL,
  error,
});

export {
  loadSearchSAPGetDataRequest,
  setSearchSAPGetDataSuccess,
  setSearchSAPGetDataFail
}