import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { EMPLOYEE_ROLE_MAPPING } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CREATENEW } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadEmployeeRolesDataRequest, loadEmployeeRolesDeleteDataRequest } from "../redux/Actions";
import EmployeeRoleMappingAdd from "./EmployeeRoleMappingAdd";
import EmployeeRoleMappingEdit from "./EmployeeRoleMappingEdit";
import DeleteModal from "../../../shared/components/DeleteModal";
import Permissions from "../../../shared/components/Permissions";

const EmployeeRoleMapping = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let { empRoles, loading } = useSelector((state) => state.empRoles);
    let [selectedRow, setSelectedRow] = useState({})
    const [shouldReload, setShouldReload] = useState(true)

    useEffect(() => {
        if (!loading && shouldReload){
            setShouldReload(false)
            dispatch(loadEmployeeRolesDataRequest());
        }
    }, [dispatch, loading, shouldReload]);

    // let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    // let module_name = "Employee Role Mapping";
    // let permission = Permissions(emp_no, module_name); 
    // let actionsPermission = !(permission.write_permission || permission.delete_permission);

    let [tableData, setTableData] = useState([]);
    useEffect(()=>{
        setTableData(
            empRoles.map((empRole)=>{
                let roles = []
                let roleIds = []
                let groups = []
                let groupIds = []
                let i = 0;
                empRole.data[0].forEach(element => {
                    let name = element.role_name
                    if(++i === empRole.data[0].length) {
                        name += "";
                    } else {
                        name += ", ";
                    }
                    roles.push(name)
                    roleIds.push(element.role_id + "," + element.group_id)
                    if (!groups.includes(element.group_name)){
                        groups.push(element.group_name)
                        groupIds.push(element.group_id)
                    }
                });
                return {
                    ...empRole,
                    roles,
                    roleIds,
                    groups,
                    groupIds,
                }
            })
        )
    },[empRoles])

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: "Employee",
            field: 'emp_name',
            type: "hyperlink",
            redirect_link: "/employees/",
            redirect_value: "emp_pk_id"
        },
        {
            display_name: "Roles",
            field: "roles",
            type: "hyperlink",
            redirect_link: "/role-module-mapping/edit/",
            redirect_value: "roles"
        },
        {
            display_name: "Groups",
            field: "groups",
            type: "text",
        },
        {
            display_name: "",
            field: 'actions',
            type: 'actions',
            // hide: actionsPermission
        }
    ];

    // let [clickEditFunc, setClickEditFunc] = useState(null);
    // let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    // useEffect(() => {
    //     if (permission.write_permission) {
    //       setClickEditFunc(
    //         () => (row) => {
    //           setSelectedRow(row);
    //           setShowEditModal(true);
    //         }
    //       )
    //     }
    //     if (permission.delete_permission) {
    //       setClickDeleteFunc(
    //         () => (id) => {
    //           setSelectedRow({ id });
    //           setShowDeleteModal(true);
    //         })
    //     }
    // }, [permission])

    const clickEdit = (row) => {
        setSelectedRow(row)
        setShowEditModal(true)
    }
    const clickDelete = (id) => {
        console.log("selected::", id);
        setShowDeleteModal(true)
        setSelectedRow({ id });
    }
    const handleDelete = () => {
        dispatch(loadEmployeeRolesDeleteDataRequest({ id: selectedRow.id }))
        setShouldReload(true)
        setShowDeleteModal(false);
    }

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { href: '/density', label: EMPLOYEE_ROLE_MAPPING },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {/* {permission.create_permission ? ( */}
                        <DefaultButton
                            buttonClass="btn btn-primary default-btn-color"
                            buttonTitle={CREATENEW}
                            actionBlock={() => setShowCreateModal(true)}
                        />
                    {/* ) : (<></>)} */}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={EMPLOYEE_ROLE_MAPPING}
                            columnNames={columns}
                            rowData={tableData}
                            // clickEdit={clickEditFunc}
                            // clickDelete={clickDeleteFunc}
                            clickEdit={clickEdit}
                            clickDelete={clickDelete}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={showCreateModal}
                handleClose={() => setShowCreateModal(false)}
                actionTitle={"Create " + EMPLOYEE_ROLE_MAPPING}
            >
                <EmployeeRoleMappingAdd
                    handleClose={(reload) => {
                        setShowCreateModal(false);
                        setShouldReload(reload)
                    }}
                />
            </Modalpopup>
            <Modalpopup
                show={showEditModal}
                handleClose={() => { setShowEditModal(false) }}
                actionTitle={"Edit " + EMPLOYEE_ROLE_MAPPING}
            >
                <EmployeeRoleMappingEdit
                    empRole={selectedRow}
                    handleClose={(reload) => {
                        if (reload) {
                            dispatch(loadEmployeeRolesDataRequest());
                        }
                        setShowEditModal(false)
                    }}
                />
            </Modalpopup>
            <DeleteModal
                handleClose={() => { setShowDeleteModal(false) }}
                handleDelete={handleDelete}
                show={showDeleteModal}
            >
                <div>
                    Delete {EMPLOYEE_ROLE_MAPPING}?
                </div>
            </DeleteModal>
        </>
    );
};

export default EmployeeRoleMapping;
