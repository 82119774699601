import { call, put, takeLatest } from "redux-saga/effects";
import { getRequest, putRequest } from "../../../shared/components/Service";
import { addErrorAlert } from "../../alert/Actions";
import { setHireTermQueueDataFail, setHireTermQueueDataSuccess, setHireTermQueueUpdateFail, setHireTermQueueUpdateSuccess } from './Actions';
import { HIRE_TERM_QUEUE_DATA, HIRE_TERM_QUEUE_GET_API, UPDATE_HIRE_TERM_QUEUE, UPDATE_HIRE_TERM_QUEUE_URL } from './Constants';

function* getHireTermQueueDataService() {
    try {
        const response = yield call(getRequest, HIRE_TERM_QUEUE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setHireTermQueueDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setHireTermQueueDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving hire term queue"))
        yield put(setHireTermQueueDataFail(error));
    }
}
function* updateHireTermQueueService({ request }) {
    try {
        const response = yield call(putRequest, UPDATE_HIRE_TERM_QUEUE_URL, request)
        if (response.status === 200) {
            yield put(setHireTermQueueUpdateSuccess(response.data))
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setHireTermQueueUpdateFail(response.response.data))
        }
    } catch (error) {
        yield put(setHireTermQueueUpdateFail(error))
    }
}

export function* watchHireTermQueue() {
    yield takeLatest(HIRE_TERM_QUEUE_DATA.HIRE_TERM_QUEUE_DATA_LOAD, getHireTermQueueDataService);
    yield takeLatest(UPDATE_HIRE_TERM_QUEUE.UPDATE_HIRE_TERM_QUEUE_LOAD, updateHireTermQueueService);
}