import { ErrorMessage, Field } from "formik"

export default function FormTextAreaField({ name, label, required = false, disabled = false,placeholder, ...props }) {
    return <div className="row p-2">
        <div className="col-2">
            <label htmlFor={name} className="form-label">
                {label} {required ? <span className="error-text">*</span> : ''}
            </label>
        </div>
        <div className="col-10">
            <Field
                className="form-control"            
                component="textarea"                     
                name={name}
                autoComplete="off"
                disabled={disabled}
                placeholder = {placeholder}  
                {...props}
            />
            <span className="error-text">
                <ErrorMessage name={name} />
            </span>
        </div>
    </div>
}
