import { takeLatest, put, call } from "redux-saga/effects";
import {
    EMPLOYEE_STATUS_DATA, EMPLOYEE_STATUS_GET_API,
    EMPLOYEE_STATUS_GET_DATA, EMPLOYEE_STATUS_GET_DETAIL_API,
    EMPLOYEE_STATUS_ADD_DATA, EMPLOYEE_STATUS_POST_API,
    EMPLOYEE_STATUS_UPDATE_DATA, EMPLOYEE_STATUS_PUT_API,
    EMPLOYEE_STATUS_DELETE_DATA, EMPLOYEE_STATUS_DELETE_API,
} from './Constants'
import {
    setEmployeeStatusDataSuccess, setEmployeeStatusDataFail,
    setEmployeeStatusGetDataSuccess, setEmployeeStatusGetDataFail,
    setEmployeeStatusAddDataSuccess, setEmployeeStatusAddDataFail,
    setEmployeeStatusUpdateDataSuccess, setEmployeeStatusUpdateDataFail,
    setEmployeeStatusDeleteDataSuccess, setEmployeeStatusDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";

function* getEmployeeStatusDataService() {
    try {
        const response = yield call(getRequest, EMPLOYEE_STATUS_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setEmployeeStatusDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeStatusDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee status"))
        yield put(setEmployeeStatusDataFail(error));
    }
}

function* getEmpStatusDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            EMPLOYEE_STATUS_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setEmployeeStatusGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeStatusGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee status info"))
        yield put(setEmployeeStatusGetDataFail(error));
    }
}

function* postEmployeeStatusDataService({ request }) {
    try {
        // let requestData = {
        //     to_insert:request
        // }
        const response = yield call(postRequest, EMPLOYEE_STATUS_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Employee Status created"))
            yield put(setEmployeeStatusAddDataSuccess(newData));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeStatusAddDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error adding employee status"))
        yield put(setEmployeeStatusAddDataFail(error));
    }
}
function* putEmployeeStatusDataService({ request }) {
    try {
        // let requestData = {
        //     to_id:{
        //         id:request.id
        //     },
        //     to_update:{
        //         ...request
        //     }
        // }
        const response = yield call(putRequest, EMPLOYEE_STATUS_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Employee Status updated"))
            yield put(setEmployeeStatusUpdateDataSuccess(response.data));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeStatusUpdateDataFail(response.response.data));
        }
    } catch (error) {
        // yield put(addErrorAlert("Error editing employee status"))
        yield put(setEmployeeStatusUpdateDataFail(error));
    }
}

function* deleteEmployeeStatusDataService({ request }) {
    try {
        const response = yield call(deleteRequest, EMPLOYEE_STATUS_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setEmployeeStatusDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeStatusDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setEmployeeStatusDeleteDataFail(error));
    }
}


export function* watchEmployeeStatus(){
    yield takeLatest(EMPLOYEE_STATUS_DATA.EMPLOYEE_STATUS_DATA_LOAD, getEmployeeStatusDataService);
    yield takeLatest(EMPLOYEE_STATUS_GET_DATA.EMPLOYEE_STATUS_GET_DATA_LOAD, getEmpStatusDataService);
    yield takeLatest(EMPLOYEE_STATUS_ADD_DATA.EMPLOYEE_STATUS_ADD_DATA_LOAD, postEmployeeStatusDataService);
    yield takeLatest(EMPLOYEE_STATUS_UPDATE_DATA.EMPLOYEE_STATUS_UPDATE_DATA_LOAD, putEmployeeStatusDataService);
    yield takeLatest(EMPLOYEE_STATUS_DELETE_DATA.EMPLOYEE_STATUS_DELETE_DATA_LOAD, deleteEmployeeStatusDataService); 
}