import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { MARKET, DELETE, CANCEL, BACKTOLIST, EDIT, ADDITEM, DISTRICT_NAME, ULTI_DISTRICT_CODE, REGION_NAME, START_DATE, END_DATE, DISTRICT_TRACKER, DELETE_CONFIRM, TRACKER } from "../../../shared/constants/Constants";
import { loadMarketGetDataRequest, loadMarketDeleteDataRequest, loadMarketUpdateDataRequest, loadMarketDeleteHistory, loadMarketDataRequest, loadMarketGetStoresRequest } from "../redux/Actions";
import Modalpopup from "../../../shared/components/Modalpopup";
import ListView from "../../../shared/components/listview/ListView";
import MarketTrackerForm from "./MarketTrackerForm";
import { PrevNextButtons } from "../../../shared/components/PrevNextButtons";
import Permissions from "../../../shared/components/Permissions";
import { Link } from "react-router-dom";
import { loadStoreDataRequest } from "../../stores/redux/Actions";
import FormError from "../../../shared/components/FormError";
import FindModule from "../../../shared/components/FindModule";
import KeyTagModuleList from "../../../shared/components/tagging/KeyTagModuleList";
import Tagging from "../../../shared/components/tagging/Tagging";
export default function MarketDetails() {
    let params = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showTrackerDeleteModal, setShowTrackerDeleteModal] = useState(false)
    const [showTrackerEditModal, setShowTrackerEditModal] = useState(false);
    const [selectedTracker, setSelectedTracker] = useState('');
    const [showTrackerAddModal, setShowTrackerAddModal] = useState(false)
    const [currentTab, setCurrentTab] = useState('District Tracker');

    const [shouldCloseModal, setShouldCloseModal] = useState(false);

    let [submittedDeleteRequest, setSubmittedDeleteRequest] = useState(false)

    let { loading, error, market, tracker, activeStores, managers } = useSelector(state => state.market);

    useEffect(() => {
        dispatch(loadMarketGetDataRequest(params.id));
        dispatch(loadMarketGetStoresRequest(params.id));
    }, [dispatch, params.id])

    //Delete Functionality
    const handleDelete = () => {
        let deleteRequest = { id: [params.id] };
        dispatch(loadMarketDeleteDataRequest(deleteRequest));
        setSubmittedDeleteRequest(true)
    };

    //Triggers a reload of data once add/update/delete tracker request is finished
    let [needsReload, setNeedsReload] = useState(false);
    useEffect(() => {
        if (needsReload && !loading) {
            setNeedsReload(false);
            dispatch(loadMarketGetDataRequest(params.id))
        }
    }, [needsReload, loading, setNeedsReload, dispatch, params.id])

    let handleTrackerEdit = (values) => {
        let putRequestValues = {
            id: market.id,
            activeTracker: market.tracker_id,
            market: {
                market_name: market.market_name,
                ulti_market_code: market.ulti_market_code
            },
            tracker: {
                region_id: values.region_id,
                start_date: values.start_date,
                end_date: values.end_date,
                id: values.tracker_id
            },
        }
        dispatch(loadMarketUpdateDataRequest(putRequestValues))
        setShouldCloseModal(true);
    }
    let handleTrackerDelete = () => {
        let deleteRequestValues = {
            id: selectedTracker,
            market_id: market.id
        }
        dispatch(loadMarketDeleteHistory(deleteRequestValues))
        setShowTrackerDeleteModal(false);
        setNeedsReload(true);
    }
    let handleTrackerAdd = (values) => {
        let putRequestValues = {
            id: market.id,
            activeTracker: market.tracker_id,
            market: {
                market_name: market.market_name,
                ulti_market_code: market.ulti_market_code
            },
            tracker: {
                region_id: values.region_id,
                start_date: values.start_date,
                end_date: values.end_date,
            },
        }
        dispatch(loadMarketUpdateDataRequest(putRequestValues));
        setShouldCloseModal(true);
    }
    useEffect(() => {
        if (submittedDeleteRequest && !loading && error === null) {
            navigate('/district')
            setShowDeleteModal(false);
        }
    }, [loading, dispatch, error, market?.msg, navigate, submittedDeleteRequest])


    const closeAddTrackerModal = () => {
        setShowTrackerAddModal(false);
        setErrorMessage(null);
    }
    const closeEditTrackerModal = () => {
        setShowTrackerEditModal(false);
        setErrorMessage(null);
    }

    let [errorMessage, setErrorMessage] = useState(null);
    useEffect(() => {
        console.log(error);
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeEditTrackerModal();
                closeAddTrackerModal();
                setNeedsReload(true);
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])



    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Market";
    let permission = Permissions(emp_no, module_name);

    let modulePermission = "Market Tracker";
    let trackerPermission = Permissions(emp_no, modulePermission);
    let actionsPermission = !(trackerPermission.write_permission || trackerPermission.delete_permission);

    let trackerColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'District Name',
            field: 'market_name',
            type: 'text',
            hide: true
        },
        {
            display_name: "Region",
            field: 'region_name',
            type: 'text'
        },
        {
            display_name: "Start Date",
            field: 'start_date',
            type: 'date',
            sort: 'desc'
        },
        {
            display_name: "End Date",
            field: 'end_date',
            type: 'date'
        },
        {
            display_name: "Actions",
            field: 'actions',
            hide: actionsPermission
        }
    ]
    const storeColumns = [
        {
            display_name: "id",
            field: 'store_id',
            type: 'number',
            hide: true,
        },
        {
            display_name: "Store Name",
            field: 'store_name',
            // type: "text" 
        },
        {
            display_name: "SAP #",
            field: 'sap',
            type: "hyperlink",
            redirect_link: "/stores/",
            redirect_value: "store_id"
        },
        {
            display_name: "Store Manager",
            field: 'emp_name',
            type: "hyperlink",
            redirect_link: "/employees/",
            redirect_value: "emp_pk_id"
        },
        {
            display_name: "T Mobile Name",
            field: 'tmob_name',
            // type: 'text',
        },
        {
            display_name: "Store Classification",
            field: 'store_classification_name',
            // type: 'text',
        },
        // {
        //     display_name: 'Active',
        //     field: "active",
        //     type: 'active'
        // }

    ]
    const managerColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true,
        },
        {
            display_name: "Manager",
            field: "store_manager",
            type: "text",
        },
        {
            display_name: 'Covering',
            field: 'covering',
            type: "icon",
            icon: "checkbox",
            width: 100,
        },
        {
            display_name: "Start Date",
            field: "start_date",
            type: "date",
        },
        {
            display_name: "End Date",
            field: "end_date",
            type: "date",
        },

    ]

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (trackerPermission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedTracker(row);
                    setShowTrackerEditModal(true);
                })
        }
        if (trackerPermission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedTracker(id);
                    setShowTrackerDeleteModal(true);
                })
        }
    }, [trackerPermission])

    //Find Tag Key Exists or not and Tagging Functionality
    let selectedModule = FindModule(module_name);
    let keytagModules = KeyTagModuleList(selectedModule?.id);

    let tabs = {
        "District Tracker": <>
            {trackerPermission.create_permission ? (
                <Button style={{ width: 115 }} className='btn btn-secondary default-btn-color float-end py-1' onClick={() => { setShowTrackerAddModal(true) }}>{ADDITEM}</Button>
            ) : (<></>)}
            <ListView
                exportTitle={"District Tracker"}
                columnNames={trackerColumns}
                rowData={tracker}
                clickEdit={clickEditFunc}
                clickDelete={clickDeleteFunc}                 
                tableHeight = '50vh'/>
        </>,
        Stores: < ListView
            columnNames={storeColumns}
            rowData={activeStores}
            rowClick={(row) => { navigate('/stores/' + row.data.store_id) }}
            tableHeight = '50vh'
        // setActiveFilter
        // shouldSetNextPrevIds
        />,
        "District Managers": <ListView
            columnNames={managerColumns}
            rowData={managers}
            tableHeight = '50vh'
            // rowClick={(row)=> navigate('/employees/' + row.data.employee_id)}
        />
    };

    if (keytagModules.length !== 0) {
        tabs['Additional Attribute'] = <Tagging resource_value={market?.id} resource_id={selectedModule?.id} />;
    }

    return <div>
        <Row className="detailsPageHeader">
            <Col xl={4} xs={12}>
                <Breadcrumbs
                    children={[
                        { href: '/district', label: MARKET },
                        { href: '/jobtitles/' + params.id, label: market?.market_name },
                    ]}
                />
            </Col>
            <Col xl={8} xs={12}>
                <div className='float-end'>
                    <PrevNextButtons currentId={params.id} basePath='/district/' name={'Districts'} />
                    <DefaultButton
                        buttonClass={"default-btn-color me-1"}
                        buttonTitle={BACKTOLIST}
                        actionBlock={() => { navigate("/district"); }}
                    />
                    {permission.write_permission ? (<>
                        <DefaultButton buttonClass={"default-btn-color"} buttonTitle={EDIT} actionBlock={() => {
                            navigate("/district/edit/" + market?.id)
                        }} />
                    </>) : (<></>)}
                    {permission.delete_permission ? (<>
                        <DefaultButton buttonClass={"btn btn-danger ms-1"} buttonTitle={<i className="fa fa-trash"></i>} actionBlock={() => { setShowDeleteModal(true) }} />
                    </>) : (<></>)}
                </div>
            </Col>
        </Row>
        <Row>
            <Card >
                <Card.Body>
                    <dl className='row'>
                        <dt className='col-sm-3'>{DISTRICT_NAME}</dt>
                        <dd className='col-sm-9'>{market?.market_name}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{ULTI_DISTRICT_CODE}</dt>
                        <dd className='col-sm-9'>{market?.ulti_market_code}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{REGION_NAME}</dt>
                        <dd className='col-sm-9'>
                            <Link to={"/region/" + market?.region_id}>
                                {market?.region_name}
                            </Link>
                        </dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{START_DATE}</dt>
                        <dd className='col-sm-9'>{market?.start_date}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{END_DATE}</dt>
                        <dd className='col-sm-9'>{market?.end_date}</dd>
                    </dl>
                </Card.Body>
            </Card>
        </Row>
        <Row>
            <Col xs={6}>
                {/* <h6 className='mb-0 text-uppercase'>{DISTRICT_TRACKER}</h6> */}
                <hr></hr>
            </Col>
        </Row>
        <Row>
            <Col>
                {Object.keys(tabs).map(tab =>
                    <button className={currentTab === tab ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTab(tab) }}>{tab}</button>
                )}
            </Col>
            <Card>
                <Card.Body>
                    {tabs[currentTab]}
                </Card.Body>
            </Card>
        </Row>
        <Modalpopup
            show={showDeleteModal}
            handleClose={() => { setShowDeleteModal(false) }}
            actionTitle={DELETE + MARKET}
        >
            <p>{DELETE_CONFIRM}</p>
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={handleDelete}
                />
            </div>
        </Modalpopup>
        <Modalpopup show={showTrackerEditModal} handleClose={closeEditTrackerModal} actionTitle={'Edit District Tracker'}>
            <FormError>
                {errorMessage}
            </FormError>
            <MarketTrackerForm companyId={market?.company_id} tracker={selectedTracker} submit={handleTrackerEdit} cancel={closeEditTrackerModal} />
        </Modalpopup>
        <Modalpopup
            actionTitle={DELETE + DISTRICT_TRACKER}
            show={showTrackerDeleteModal}
            handleClose={() => { setShowTrackerDeleteModal(false); console.log(selectedTracker) }}
        >
            <p>{DELETE_CONFIRM}</p>
            <div>
                <Row>
                    <Col md={12}>
                        <button className={"btn btn-secondary me-2"} onClick={() => { setShowTrackerDeleteModal(false) }}>{CANCEL}</button>
                        <button className={"btn btn-danger me-2"} onClick={handleTrackerDelete}>{DELETE}</button>
                    </Col>
                </Row>
            </div>
        </Modalpopup>
        <Modalpopup show={showTrackerAddModal} handleClose={closeAddTrackerModal} actionTitle={'Add Market Tracker'}>
            <FormError>
                {errorMessage}
            </FormError>
            <MarketTrackerForm companyId={market?.company_id} tracker={{ market_name: market?.market_name }} submit={handleTrackerAdd} cancel={closeAddTrackerModal} />
        </Modalpopup>
    </div>
}