import { getDetailRequest, getRequest } from "../../../../shared/components/Service";
import { takeLatest, put, call } from "redux-saga/effects";
import { ODOO_STORE_TRANSFER_TRACKER_API, ODOO_STORE_TRANSFER_TRACKER_DATA, ODOO_STORE_TRANSFER_TRACKER_DETAILS_API, ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA } from "./Constants";
import { addErrorAlert } from "../../../alert/Actions";
import { setOdooStoreTransferTrackerDataFail, setOdooStoreTransferTrackerDataSuccess, setOdooStoreTransferTrackerGetDataFail, setOdooStoreTransferTrackerGetDataSuccess } from "./Actions";

function* getAllOdooStoreTransferTrackersDataService() {
    try {
        const response = yield call(getRequest, ODOO_STORE_TRANSFER_TRACKER_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setOdooStoreTransferTrackerDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setOdooStoreTransferTrackerDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving Odoo Store Tranfer Trackers"))
        yield put(setOdooStoreTransferTrackerDataFail(error));
    }
}

function* getOdooStoreTransferTrackerDetailsDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            ODOO_STORE_TRANSFER_TRACKER_DETAILS_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setOdooStoreTransferTrackerGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setOdooStoreTransferTrackerGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving odoo store transfer tracker details"))
        yield put(setOdooStoreTransferTrackerGetDataFail(error));
    }
}

export function* watchOdooStoreTransferTrackers(){
    yield takeLatest(ODOO_STORE_TRANSFER_TRACKER_DATA.ODOO_STORE_TRANSFER_TRACKER_DATA_LOAD, getAllOdooStoreTransferTrackersDataService);
    yield takeLatest(ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA.ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_LOAD, getOdooStoreTransferTrackerDetailsDataService);
}