//Get
const ROLES_DATA = {
    ROLES_DATA_LOAD: "ROLES_DATA_LOAD",
    ROLES_DATA_SUCCESS: "ROLES_DATA_SUCCESS",
    ROLES_DATA_FAIL: "ROLES_DATA_FAIL",
};
const ROLES_GET_API = "/admin/roles";

// Get one by id
const ROLES_GET_DATA = {
    ROLES_GET_DATA_LOAD: "ROLES_GET_DATA_LOAD",
    ROLES_GET_DATA_SUCCESS: "ROLES_GET_DATA_SUCCESS",
    ROLES_GET_DATA_FAIL: "ROLES_GET_DATA_FAIL",
};

const ROLES_GET_DETAIL_API = "/admin/roles/";

// Create / Add / Post
const ROLES_ADD_DATA = {
    ROLES_ADD_DATA_LOAD: "ROLES_ADD_DATA_LOAD",
    ROLES_ADD_DATA_SUCCESS: "ROLES_ADD_DATA_SUCCESS",
    ROLES_ADD_DATA_FAIL: "ROLES_ADD_DATA_FAIL",
};

const ROLES_POST_API = "/admin/roles";

//Update
const ROLES_UPDATE_DATA = {
  ROLES_UPDATE_DATA_LOAD: "ROLES_UPDATE_DATA_UPLOAD",
  ROLES_UPDATE_DATA_SUCCESS: "ROLES_UPDATE_DATA_SUCCESS",
  ROLES_UPDATE_DATA_FAIL: "ROLES_UPDATE_DATA_FAIL",
};

const ROLES_PUT_API = "/admin/roles";

// Delete
const ROLES_DELETE_DATA = {
  ROLES_DELETE_DATA_LOAD: "ROLES_DELETE_DATA_LOAD",
  ROLES_DELETE_DATA_SUCCESS: "ROLES_DELETE_DATA_SUCCESS",
  ROLES_DELETE_DATA_FAIL: "ROLES_DELETE_DATA_FAIL",
};

const ROLES_DELETE_API = "/admin/roles";

export{
    ROLES_DATA,
    ROLES_GET_API,
    ROLES_GET_DATA,
    ROLES_GET_DETAIL_API,
    ROLES_ADD_DATA,
    ROLES_POST_API,
    ROLES_UPDATE_DATA,
    ROLES_PUT_API,
    ROLES_DELETE_DATA,
    ROLES_DELETE_API,
}