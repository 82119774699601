//Get
const TERM_REASON_DATA = {
    TERM_REASON_DATA_LOAD: "TERM_REASON_DATA_LOAD",
    TERM_REASON_DATA_SUCCESS: "TERM_REASON_DATA_SUCCESS",
    TERM_REASON_DATA_FAIL: "TERM_REASON_DATA_FAIL",
};
const TERM_REASON_GET_API = "/employee/v1/termReason";

// Get one by id
const TERM_REASON_GET_DATA = {
    TERM_REASON_GET_DATA_LOAD: "TERM_REASON_GET_DATA_LOAD",
    TERM_REASON_GET_DATA_SUCCESS: "TERM_REASON_GET_DATA_SUCCESS",
    TERM_REASON_GET_DATA_FAIL: "TERM_REASON_GET_DATA_FAIL",
};

const TERM_REASON_GET_DETAIL_API = "/employee/v1/termReason/";

// Create / Add / Post
const TERM_REASON_ADD_DATA = {
    TERM_REASON_ADD_DATA_LOAD: "TERM_REASON_ADD_DATA_LOAD",
    TERM_REASON_ADD_DATA_SUCCESS: "TERM_REASON_ADD_DATA_SUCCESS",
    TERM_REASON_ADD_DATA_FAIL: "TERM_REASON_ADD_DATA_FAIL",
};

const TERM_REASON_POST_API = "/employee/v1/termReason";

//Update
const TERM_REASON_UPDATE_DATA = {
  TERM_REASON_UPDATE_DATA_LOAD: "TERM_REASON_UPDATE_DATA_UPLOAD",
  TERM_REASON_UPDATE_DATA_SUCCESS: "TERM_REASON_UPDATE_DATA_SUCCESS",
  TERM_REASON_UPDATE_DATA_FAIL: "TERM_REASON_UPDATE_DATA_FAIL",
};

const TERM_REASON_PUT_API = "/employee/v1/termReason";

// Delete
const TERM_REASON_DELETE_DATA = {
  TERM_REASON_DELETE_DATA_LOAD: "TERM_REASON_DELETE_DATA_LOAD",
  TERM_REASON_DELETE_DATA_SUCCESS: "TERM_REASON_DELETE_DATA_SUCCESS",
  TERM_REASON_DELETE_DATA_FAIL: "TERM_REASON_DELETE_DATA_FAIL",
};

const TERM_REASON_DELETE_API = "/employee/v1/termReason";


export{
    TERM_REASON_DATA,
    TERM_REASON_GET_API,
    TERM_REASON_GET_DATA,
    TERM_REASON_GET_DETAIL_API,
    TERM_REASON_ADD_DATA,
    TERM_REASON_POST_API,
    TERM_REASON_UPDATE_DATA,
    TERM_REASON_PUT_API,
    TERM_REASON_DELETE_DATA,
    TERM_REASON_DELETE_API,
}