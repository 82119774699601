import {
  STORE_DATA,
  STORE_GET_DATA,
  STORE_IMPORT_DATA,
  STORE_ADD_DATA,
  STORE_UPDATE_DATA,
  STORE_DELETE_DATA,
  STORE_SAP_TRACKER_ADD,
  STORE_SAP_TRACKER_UPDATE,
  STORE_SAP_TRACKER_DELETE,
  STORE_DENSITY_TRACKER_ADD,
  STORE_DENSITY_TRACKER_UPDATE,
  STORE_DENSITY_TRACKER_DELETE,
  STORE_COMM_TRACKER_ADD,
  STORE_COMM_TRACKER_UPDATE,
  STORE_COMM_TRACKER_DELETE,
  STORE_CLASSIFICATION_TRACKER_ADD,
  STORE_CLASSIFICATION_TRACKER_UPDATE,
  STORE_CLASSIFICATION_TRACKER_DELETE,
  STORE_TRACKER_GET_ALL_DATA,
  STORE_TRACKER_IMPORT_DATA,
  DENSITYCLASS_TRACKER_GET_ALL_DATA,
  COMMCOMP_TRACKER_GET_ALL_DATA,
  SCLASSIFICATION_TRACKER_GET_ALL_DATA
} from "./Constants";

//For listing
const loadStoreDataRequest = () => ({
  type: STORE_DATA.STORE_DATA_LOAD,
});
const setStoreDataSuccess = (stores) => ({
  type: STORE_DATA.STORE_DATA_SUCCESS,
  stores,
});
const setStoreDataFail = (error) => ({
  type: STORE_DATA.STORE_DATA_FAIL,
  error,
});

//for details
const loadStoreGetDataRequest = (id) => ({
  type: STORE_GET_DATA.STORE_GET_DATA_LOAD,
  id,
});
const setStoreGetDataSuccess = (response) => ({
  type: STORE_GET_DATA.STORE_GET_DATA_SUCCESS,
  store: response.store[0],
  sap_tracker: response.sap_tracker,
  density_tracker: response.density_tracker,
  comm_comp_tracker: response.comm_comp_tracker,
  store_class_tracker: response.store_class_tracker,
  manager_history: response.manager_history,
});
const setStoreGetDataFail = (error) => ({
  type: STORE_GET_DATA.STORE_GET_DATA_FAIL,
  error,
});

//For add Store
const loadStoreAddDataRequest = (request) => ({
  type: STORE_ADD_DATA.STORE_ADD_DATA_LOAD,
  request,
});
const setStoreAddDataSuccess = (store) => ({
  type: STORE_ADD_DATA.STORE_ADD_DATA_SUCCESS,
  store,
});
const setStoreAddDataFail = (error) => ({
  type: STORE_ADD_DATA.STORE_ADD_DATA_FAIL,
  error,
});

//update store
const loadStoreUpdateDataRequest = (request) => ({
  type: STORE_UPDATE_DATA.STORE_UPDATE_DATA_LOAD,
  request,
});
const setStoreUpdateDataSuccess = (store) => ({
  type: STORE_UPDATE_DATA.STORE_UPDATE_DATA_SUCCESS,
  store,
});
const setStoreUpdateDataFail = (error) => ({
  type: STORE_UPDATE_DATA.STORE_UPDATE_DATA_FAIL,
  error,
});

//Delete Store
const loadStoreDeleteDataRequest = (request) => ({
  type: STORE_DELETE_DATA.STORE_DELETE_DATA_LOAD,
  request,
});
const setStoreDeleteDataSuccess = (store) => ({
  type: STORE_DELETE_DATA.STORE_DELETE_DATA_SUCCESS,
  store,
});
const setStoreDeleteDataFail = (error) => ({
  type: STORE_DELETE_DATA.STORE_DELETE_DATA_FAIL,
  error,
});

//Import Store
const loadStoreImportDataRequest = (request) => ({
  type: STORE_IMPORT_DATA.STORE_IMPORT_DATA_LOAD,
  request,
});
const setStoreImportDataSuccess = (stores) => ({
  type: STORE_IMPORT_DATA.STORE_IMPORT_DATA_SUCCESS,
  stores,
});
const setStoreImportDataFail = (error) => ({
  type: STORE_IMPORT_DATA.STORE_IMPORT_DATA_FAIL,
  error,
});

//add sap tracker
const loadSapTrackerAddDataRequest = (request) => ({
  type: STORE_SAP_TRACKER_ADD.STORE_SAP_TRACKER_ADD_LOAD,
  request
})
const setSapTrackerAddDataSuccess = (trackers) => ({
  type: STORE_SAP_TRACKER_ADD.STORE_SAP_TRACKER_ADD_SUCCESS,
  trackers
})
const setSapTrackerAddDataFail = (error) => ({
  type: STORE_SAP_TRACKER_ADD.STORE_SAP_TRACKER_ADD_FAIL,
  error
})
//update sap tracker

const loadSapTrackerUpdateDataRequest = (request) => ({
  type: STORE_SAP_TRACKER_UPDATE.STORE_SAP_TRACKER_UPDATE_LOAD,
  request
})
const setSapTrackerUpdateDataSuccess = (trackers) => ({
  type: STORE_SAP_TRACKER_UPDATE.STORE_SAP_TRACKER_UPDATE_SUCCESS,
  trackers
})
const setSapTrackerUpdateDataFail = (error) => ({
  type: STORE_SAP_TRACKER_UPDATE.STORE_SAP_TRACKER_UPDATE_FAIL,
  error
})


//Delete sap tracker
const loadSapTrackerDeleteDataRequest = (request) => ({
  type: STORE_SAP_TRACKER_DELETE.STORE_SAP_TRACKER_DELETE_LOAD,
  request
})
const setSapTrackerDeleteDataSuccess = (trackers) => ({
  type: STORE_SAP_TRACKER_DELETE.STORE_SAP_TRACKER_DELETE_SUCCESS,
  trackers
})
const setSapTrackerDeleteDataFail = (error) => ({
  type: STORE_SAP_TRACKER_DELETE.STORE_SAP_TRACKER_DELETE_FAIL,
  error
})

//add Density tracker
const loadDensityTrackerAddDataRequest = (request) => ({
  type: STORE_DENSITY_TRACKER_ADD.STORE_DENSITY_TRACKER_ADD_LOAD,
  request
})
const setDensityTrackerAddDataSuccess = (trackers) => ({
  type: STORE_DENSITY_TRACKER_ADD.STORE_DENSITY_TRACKER_ADD_SUCCESS,
  trackers
})
const setDensityTrackerAddDataFail = (error) => ({
  type: STORE_DENSITY_TRACKER_ADD.STORE_DENSITY_TRACKER_ADD_FAIL,
  error
})

//update Density tracker
const loadDensityTrackerUpdateDataRequest = (request) => ({
  type: STORE_DENSITY_TRACKER_UPDATE.STORE_DENSITY_TRACKER_UPDATE_LOAD,
  request
})
const setDensityTrackerUpdateDataSuccess = (trackers) => ({
  type: STORE_DENSITY_TRACKER_UPDATE.STORE_DENSITY_TRACKER_UPDATE_SUCCESS,
  trackers
})
const setDensityTrackerUpdateDataFail = (error) => ({
  type: STORE_DENSITY_TRACKER_UPDATE.STORE_DENSITY_TRACKER_UPDATE_FAIL,
  error
})


//Delete Density tracker
const loadDensityTrackerDeleteDataRequest = (request) => ({
  type: STORE_DENSITY_TRACKER_DELETE.STORE_DENSITY_TRACKER_DELETE_LOAD,
  request
})
const setDensityTrackerDeleteDataSuccess = (trackers) => ({
  type: STORE_DENSITY_TRACKER_DELETE.STORE_DENSITY_TRACKER_DELETE_SUCCESS,
  trackers
})
const setDensityTrackerDeleteDataFail = (error) => ({
  type: STORE_DENSITY_TRACKER_DELETE.STORE_DENSITY_TRACKER_DELETE_FAIL,
  error
})

//add comm comp group tracker
const loadCommTrackerAddDataRequest = (request) => ({
  type: STORE_COMM_TRACKER_ADD.STORE_COMM_TRACKER_ADD_LOAD,
  request
})
const setCommTrackerAddDataSuccess = (trackers) => ({
  type: STORE_COMM_TRACKER_ADD.STORE_COMM_TRACKER_ADD_SUCCESS,
  trackers
})
const setCommTrackerAddDataFail = (error) => ({
  type: STORE_COMM_TRACKER_ADD.STORE_COMM_TRACKER_ADD_FAIL,
  error
})

//update comm comp group tracker
const loadCommTrackerUpdateDataRequest = (request) => ({
  type: STORE_COMM_TRACKER_UPDATE.STORE_COMM_TRACKER_UPDATE_LOAD,
  request
})
const setCommTrackerUpdateDataSuccess = (trackers) => ({
  type: STORE_COMM_TRACKER_UPDATE.STORE_COMM_TRACKER_UPDATE_SUCCESS,
  trackers
})
const setCommTrackerUpdateDataFail = (error) => ({
  type: STORE_COMM_TRACKER_UPDATE.STORE_COMM_TRACKER_UPDATE_FAIL,
  error
})

//Delete comm comp group tracker
const loadCommTrackerDeleteDataRequest = (request) => ({
  type: STORE_COMM_TRACKER_DELETE.STORE_COMM_TRACKER_DELETE_LOAD,
  request
})
const setCommTrackerDeleteDataSuccess = (trackers) => ({
  type: STORE_COMM_TRACKER_DELETE.STORE_COMM_TRACKER_DELETE_SUCCESS,
  trackers
})
const setCommTrackerDeleteDataFail = (error) => ({
  type: STORE_COMM_TRACKER_DELETE.STORE_COMM_TRACKER_DELETE_FAIL,
  error
})

//add Classification tracker
const loadClassificationTrackerAddDataRequest = (request) => ({
  type: STORE_CLASSIFICATION_TRACKER_ADD.STORE_CLASSIFICATION_TRACKER_ADD_LOAD,
  request
})
const setClassificationTrackerAddDataSuccess = (trackers) => ({
  type: STORE_CLASSIFICATION_TRACKER_ADD.STORE_CLASSIFICATION_TRACKER_ADD_SUCCESS,
  trackers
})
const setClassificationTrackerAddDataFail = (error) => ({
  type: STORE_CLASSIFICATION_TRACKER_ADD.STORE_CLASSIFICATION_TRACKER_ADD_FAIL,
  error
})

//update Classification tracker
const loadClassificationTrackerUpdateDataRequest = (request) => ({
  type: STORE_CLASSIFICATION_TRACKER_UPDATE.STORE_CLASSIFICATION_TRACKER_UPDATE_LOAD,
  request
})
const setClassificationTrackerUpdateDataSuccess = (trackers) => ({
  type: STORE_CLASSIFICATION_TRACKER_UPDATE.STORE_CLASSIFICATION_TRACKER_UPDATE_SUCCESS,
  trackers
})
const setClassificationTrackerUpdateDataFail = (error) => ({
  type: STORE_CLASSIFICATION_TRACKER_UPDATE.STORE_CLASSIFICATION_TRACKER_UPDATE_FAIL,
  error
})

//Delete Classification tracker
const loadClassificationTrackerDeleteDataRequest = (request) => ({
  type: STORE_CLASSIFICATION_TRACKER_DELETE.STORE_CLASSIFICATION_TRACKER_DELETE_LOAD,
  request
})
const setClassificationTrackerDeleteDataSuccess = (trackers) => ({
  type: STORE_CLASSIFICATION_TRACKER_DELETE.STORE_CLASSIFICATION_TRACKER_DELETE_SUCCESS,
  trackers
})
const setClassificationTrackerDeleteDataFail = (error) => ({
  type: STORE_CLASSIFICATION_TRACKER_DELETE.STORE_CLASSIFICATION_TRACKER_DELETE_FAIL,
  error
})

//Get All Store Tracker
const loadGetStoreTrackerDataRequest = () => ({
  type: STORE_TRACKER_GET_ALL_DATA.STORE_TRACKER_GET_ALL_DATA_LOAD,
});
const setGetStoreTrackerDataSuccess = (trackers) => ({
  type: STORE_TRACKER_GET_ALL_DATA.STORE_TRACKER_GET_ALL_DATA_SUCCESS,
  trackers,
});
const setGetStoreTrackerDataFail = (error) => ({
  type: STORE_TRACKER_GET_ALL_DATA.STORE_TRACKER_GET_ALL_DATA_FAIL,
  error,
});

//Import Store Tracker
const loadStoreTrackerImportDataRequest = (request) => ({
  type: STORE_TRACKER_IMPORT_DATA.STORE_TRACKER_IMPORT_DATA_LOAD,
  request,
});

const setStoreTrackerImportDataSuccess = (trackers) => ({
  type: STORE_TRACKER_IMPORT_DATA.STORE_TRACKER_IMPORT_DATA_SUCCESS,
  trackers,
});

const setStoreTrackerImportDataFail = (error) => ({
  type: STORE_TRACKER_IMPORT_DATA.STORE_TRACKER_IMPORT_DATA_FAIL,
  error,
});

//Get All Density Class Tracker
const loadGetDensityClassTrackerDataRequest = () => ({
  type: DENSITYCLASS_TRACKER_GET_ALL_DATA.DENSITYCLASS_TRACKER_GET_ALL_DATA_LOAD,
});
const setGetDensityClassTrackerDataSuccess = (trackers) => ({
  type: DENSITYCLASS_TRACKER_GET_ALL_DATA.DENSITYCLASS_TRACKER_GET_ALL_DATA_SUCCESS,
  trackers,
});
const setGetDensityClassTrackerDataFail = (error) => ({
  type: DENSITYCLASS_TRACKER_GET_ALL_DATA.DENSITYCLASS_TRACKER_GET_ALL_DATA_FAIL,
  error,
});

//Get All Comm Comp Tracker
const loadGetCommCompTrackerDataRequest = () => ({
  type: COMMCOMP_TRACKER_GET_ALL_DATA.COMMCOMP_TRACKER_GET_ALL_DATA_LOAD,
});
const setGetCommCompTrackerDataSuccess = (trackers) => ({
  type: COMMCOMP_TRACKER_GET_ALL_DATA.COMMCOMP_TRACKER_GET_ALL_DATA_SUCCESS,
  trackers,
});
const setGetCommCompTrackerDataFail = (error) => ({
  type: COMMCOMP_TRACKER_GET_ALL_DATA.COMMCOMP_TRACKER_GET_ALL_DATA_FAIL,
  error,
});

//Get All Store Classification Tracker
const loadGetSClassificationTrackerDataRequest = () => ({
  type: SCLASSIFICATION_TRACKER_GET_ALL_DATA.SCLASSIFICATION_TRACKER_GET_ALL_DATA_LOAD,
});
const setGetSClassificationTrackerDataSuccess = (trackers) => ({
  type: SCLASSIFICATION_TRACKER_GET_ALL_DATA.SCLASSIFICATION_TRACKER_GET_ALL_DATA_SUCCESS,
  trackers,
});
const setGetSClassificationTrackerDataFail = (error) => ({
  type: SCLASSIFICATION_TRACKER_GET_ALL_DATA.SCLASSIFICATION_TRACKER_GET_ALL_DATA_FAIL,
  error,
});


export {
  loadStoreDataRequest,
  setStoreDataSuccess,
  setStoreDataFail,
  loadStoreGetDataRequest,
  setStoreGetDataSuccess,
  setStoreGetDataFail,
  loadStoreAddDataRequest,
  setStoreAddDataSuccess,
  setStoreAddDataFail,
  loadStoreUpdateDataRequest,
  setStoreUpdateDataSuccess,
  setStoreUpdateDataFail,
  loadStoreDeleteDataRequest,
  setStoreDeleteDataSuccess,
  setStoreDeleteDataFail,
  loadStoreImportDataRequest,
  setStoreImportDataSuccess,
  setStoreImportDataFail,

  loadSapTrackerAddDataRequest,
  setSapTrackerAddDataSuccess,
  setSapTrackerAddDataFail,
  loadSapTrackerUpdateDataRequest,
  setSapTrackerUpdateDataSuccess,
  setSapTrackerUpdateDataFail,
  loadSapTrackerDeleteDataRequest,
  setSapTrackerDeleteDataSuccess,
  setSapTrackerDeleteDataFail,

  loadDensityTrackerAddDataRequest,
  setDensityTrackerAddDataSuccess,
  setDensityTrackerAddDataFail,
  loadDensityTrackerUpdateDataRequest,
  setDensityTrackerUpdateDataSuccess,
  setDensityTrackerUpdateDataFail,
  loadDensityTrackerDeleteDataRequest,
  setDensityTrackerDeleteDataSuccess,
  setDensityTrackerDeleteDataFail,

  loadCommTrackerAddDataRequest,
  setCommTrackerAddDataSuccess,
  setCommTrackerAddDataFail,
  loadCommTrackerUpdateDataRequest,
  setCommTrackerUpdateDataSuccess,
  setCommTrackerUpdateDataFail,
  loadCommTrackerDeleteDataRequest,
  setCommTrackerDeleteDataSuccess,
  setCommTrackerDeleteDataFail,

  loadClassificationTrackerAddDataRequest,
  setClassificationTrackerAddDataSuccess,
  setClassificationTrackerAddDataFail,
  loadClassificationTrackerUpdateDataRequest,
  setClassificationTrackerUpdateDataSuccess,
  setClassificationTrackerUpdateDataFail,
  loadClassificationTrackerDeleteDataRequest,
  setClassificationTrackerDeleteDataSuccess,
  setClassificationTrackerDeleteDataFail,

  loadGetStoreTrackerDataRequest,
  setGetStoreTrackerDataSuccess,
  setGetStoreTrackerDataFail,
  loadStoreTrackerImportDataRequest,
  setStoreTrackerImportDataSuccess,
  setStoreTrackerImportDataFail,

  loadGetDensityClassTrackerDataRequest,
  setGetDensityClassTrackerDataSuccess,
  setGetDensityClassTrackerDataFail,
  
  loadGetCommCompTrackerDataRequest,
  setGetCommCompTrackerDataSuccess,
  setGetCommCompTrackerDataFail,

  loadGetSClassificationTrackerDataRequest,
  setGetSClassificationTrackerDataSuccess,
  setGetSClassificationTrackerDataFail,
};
