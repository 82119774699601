import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { GROUPS } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CREATENEW } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadGroupsDataRequest, loadGroupsDeleteDataRequest } from "../redux/Actions";
import GroupsAdd from "./GroupsAdd";
import GroupsEdit from "./GroupsEdit";
import DeleteModal from "../../../shared/components/DeleteModal";
import Permissions from "../../../shared/components/Permissions";

const Groups = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let { groups, loading } = useSelector((state) => state.groups);
    let [selectedRow, setSelectedRow] = useState({})
    const [shouldReload, setShouldReload] = useState(true)

    useEffect(() => {
        if (!loading && shouldReload){
            setShouldReload(false)
            dispatch(loadGroupsDataRequest());
        }
    }, [dispatch, loading, shouldReload]);

    // let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    // let module_name = "Groups";
    // let permission = Permissions(emp_no, module_name); 
    // let actionsPermission = !(permission.write_permission || permission.delete_permission);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: "Group Name",
            field: "group_name",
            type: "text",
        },
        {
            display_name: "",
            field: 'actions',
            type: 'actions',
            //hide: actionsPermission
        }
    ];

    // let [clickEditFunc, setClickEditFunc] = useState(null);
    // let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    // useEffect(() => {
    //     if (permission.write_permission) {
    //       setClickEditFunc(
    //         () => (row) => {
    //           setSelectedRow(row);
    //           setShowEditModal(true);
    //         }
    //       )
    //     }
    //     if (permission.delete_permission) {
    //       setClickDeleteFunc(
    //         () => (id) => {
    //           setSelectedRow({ id });
    //           setShowDeleteModal(true);
    //         })
    //     }
    // }, [permission])

    const clickEdit = (row) => {
        setSelectedRow(row)
        setShowEditModal(true)
    }
    const clickDelete = (id) => {
        setShowDeleteModal(true)
        setSelectedRow({ id });
    }
    const handleDelete = () => {
        dispatch(loadGroupsDeleteDataRequest({ id: [selectedRow.id] }))
        setShouldReload(true)
        setShowDeleteModal(false);
    }

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { href: '/groups', label: GROUPS },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {/* {permission.create_permission ? ( */}
                        <DefaultButton
                            buttonClass="btn btn-primary default-btn-color"
                            buttonTitle={CREATENEW}
                            actionBlock={() => setShowCreateModal(true)}
                        />
                    {/* ) : (<></>)} */}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={GROUPS}
                            columnNames={columns}
                            rowData={groups}
                            // clickEdit={clickEditFunc}
                            // clickDelete={clickDeleteFunc}
                            clickEdit={clickEdit}
                            clickDelete={clickDelete}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={showCreateModal}
                handleClose={() => setShowCreateModal(false)}
                actionTitle={"Create " + GROUPS}
            >
                <GroupsAdd
                    handleClose={(reload) => {
                        setShowCreateModal(false);
                        if (reload) {
                            dispatch(loadGroupsDataRequest());
                        }
                    }}
                />
            </Modalpopup>
            <Modalpopup
                show={showEditModal}
                handleClose={() => { setShowEditModal(false) }}
                actionTitle={"Edit " + GROUPS}
            >
                <GroupsEdit
                    groups={selectedRow}
                    handleClose={(reload) => {
                        if (reload) {
                            dispatch(loadGroupsDataRequest());
                        }
                        setShowEditModal(false)
                    }}
                />
            </Modalpopup>
            <DeleteModal
                handleClose={() => { setShowDeleteModal(false) }}
                handleDelete={handleDelete}
                show={showDeleteModal}
            >
                <div>
                    Delete {GROUPS}?
                </div>
            </DeleteModal>
        </>
    );
};

export default Groups;
