import {
  MODULE_DATA,
  MODULE_GET_DATA,
  MODULE_ADD_DATA,
  MODULE_UPDATE_DATA,
  MODULE_DELETE_DATA,
} from "./Constants";
const initialState = {
  loading: false,
  error: null,
  modules: [],
  module: {},
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    //load all
    case MODULE_DATA.MODULE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        modules: [],
        error: null,
      };
    case MODULE_DATA.MODULE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        modules: action.modules,
      };
    case MODULE_DATA.MODULE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //load single
    case MODULE_GET_DATA.MODULE_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        module: {},
        error: null,
      };

    case MODULE_GET_DATA.MODULE_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        module: action.module,
      };

    case MODULE_GET_DATA.MODULE_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MODULE_ADD_DATA.MODULE_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MODULE_ADD_DATA.MODULE_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MODULE_ADD_DATA.MODULE_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MODULE_UPDATE_DATA.MODULE_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MODULE_UPDATE_DATA.MODULE_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MODULE_UPDATE_DATA.MODULE_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MODULE_DELETE_DATA.MODULE_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MODULE_DELETE_DATA.MODULE_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MODULE_DELETE_DATA.MODULE_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
