import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ListView from "../../../shared/components/listview/ListView"
import Modalpopup from "../../../shared/components/Modalpopup"
import DefaultButton from "../../../shared/components/DefaultButton"
import { loadClassificationTrackerAddDataRequest, loadClassificationTrackerDeleteDataRequest, loadClassificationTrackerUpdateDataRequest } from "../redux/Actions"
import { DELETE, CANCEL, STORECLASSIFICATION, START_DATE, END_DATE, DELETE_CONFIRM } from "../../../shared/constants/Constants"
import { loadClassificationDataRequest } from "../../store-classification/redux/Actions"
import StoreClassificationTrackerForm from "./StoreClassificationTrackerForm"
import Permissions from "../../../shared/components/Permissions"
import FormError from "../../../shared/components/FormError"


const StoreClassificationTracker = ({ store_class_tracker, store, setShouldReload }) => {

    const dispatch = useDispatch();

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Store Classification Tracker";
    let permission = Permissions(emp_no, module_name);

    let actionsPermission = !(permission.write_permission || permission.delete_permission);

    const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        // {
        //     display_name: "SAP",
        //     field: "sap_id",
        //     type: "text",
        // },
        {
            display_name: STORECLASSIFICATION,
            field: "store_classification_name",
            type: "text",
        },
        {
            display_name: START_DATE,
            field: "start_date",
            type: "date",
            sort: "desc"
        },
        {
            display_name: END_DATE,
            field: "end_date",
            type: "date",
        },
        {
            display_name: 'Actions',
            field: 'actions',
            hide: actionsPermission
        }
    ]
    let { classifications } = useSelector(store => store.classification);

    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);
    let { loading, error } = useSelector(state => state.store)

    useEffect(() => {
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddModal();
                closeEditModal();
                setShouldReload(true)
            } else {
                console.log(error)
                setErrorMessage(error.msg);
            }
            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])

    const closeAddModal = () => {
        setShowAddModal(false);
        setErrorMessage(null);
    }
    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }
    useEffect(() => {
        dispatch(loadClassificationDataRequest());
    }, [dispatch])

    let [trackerData, setTrackerData] = useState([])
    useEffect(() => {
        let newData = []
        if (store_class_tracker) {
            store_class_tracker.forEach(element => {
                let newClassification = classifications.find(classification => classification.id == element.store_classification_id)
                newData.push({
                    ...element,
                    // store_classification_id: newClassification?.store_classification_name,
                    sap_id: store.sap
                })
            });
            setTrackerData(newData)
        }
    }, [store_class_tracker, store, classifications]);

    let [showEditModal, setShowEditModal] = useState(false)
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [showAddModal, setShowAddModal] = useState(false);
    let [selectedTracker, setSelectedTracker] = useState({});

    const handleEdit = (values) => {
        dispatch(loadClassificationTrackerUpdateDataRequest({
            ...values,
            id: selectedTracker.id,
        }))
        setShouldCloseModal(true)
    }
    const handleAdd = (values) => {
        dispatch(loadClassificationTrackerAddDataRequest(
            {
                ...values,
            }
        ));
        setShouldCloseModal(true)
    }
    const handleDelete = () => {
        setShowDeleteModal(false);
        dispatch(loadClassificationTrackerDeleteDataRequest({
            id: [selectedTracker],
            sap_id: store.id
        }))
        setShouldReload(true)
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedTracker(row);
                    setShowEditModal(true);
                })
        }
        if (permission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedTracker(id);
                    setShowDeleteModal(true);
                })
        }
    }, [permission])
    return <>
        {permission.create_permission ? (
            <Button style={{ width: 115 }} className="btn btn-secondary default-btn-color float-end py-1" onClick={() => { setShowAddModal(true) }}>Add Tracker</Button>
        ) : (<></>)}
        <ListView
            exportTitle={'store classification tracker'}
            columnNames={columnDefs}
            rowData={trackerData}
            clickEdit={clickEditFunc}
            clickDelete={clickDeleteFunc}
            tableHeight = '50vh'
        />
        <Modalpopup show={showEditModal} actionTitle="Edit Store Classification Tracker" handleClose={closeEditModal} >
            <FormError>
                {errorMessage}
            </FormError>
            <StoreClassificationTrackerForm tracker={selectedTracker} submit={handleEdit} cancel={closeEditModal} store={store} />
        </Modalpopup>
        <Modalpopup show={showDeleteModal} actionTitle="Delete Store Classification Tracker" handleClose={() => { setShowDeleteModal(false) }}>
            {DELETE_CONFIRM}
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>
        <Modalpopup show={showAddModal} actionTitle="Add Store Classification Tracker" handleClose={closeAddModal}>
            <FormError>
                {errorMessage}
            </FormError>
            <StoreClassificationTrackerForm submit={handleAdd} cancel={closeAddModal} store={store} />
        </Modalpopup>
    </>
}
export default StoreClassificationTracker
