import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import { instance } from "../../../shared/utilites/ApiClient";

const CreateNewPassword = () => {
    const logoPath = "/company-logo.png";
    let { key } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    let employee_id = searchParams.get('id');
    let isExpire = searchParams.get('Expire');
    console.log("Password" +isExpire )
    const removeQueryParams = () => {
        if (employee_id) {     
            console.log("Calling remove function")   ;
            console.log(searchParams.get('id'));
            searchParams.delete('id');  
            setSearchParams({});      
            console.log(searchParams.get('id')); 
            
        }
    };    
    let [message, setMessage] = useState('');
    let [errorMsg, setErrorMsg] = useState('');
    //const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(values.password);
    const validate = (values) => {
        let errors = {};
        if (!values.password) {
            errors.password = "Password cannot be blank"
        } else if (values.password.length < 9) {
            errors.password = "Password too short"
        } else if (!/[A-Z]/.test(values.password)) {
            errors.password = "Password must contain an uppercase letter"
        } else if (!/[a-z]/.test(values.password)) {
            errors.password = "Password must contain a lowercase letter"
        } else if (!/\d/.test(values.password)) {
            errors.password = "Password must contain a number"
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(values.password)) {
            errors.password = "Password must contain at least one special character"
        }
        if (values.password != values.password2) {
            errors.password2 = "Passwords do not match"
        }
        return errors;
    }
    // removeQueryParams(); - check and add this back
    const submit = (values) => {
        instance.post("/login/changePassword", { password: values.password, key, employee_id:employee_id }).then(response => {
            console.log("response", response)
            if(response?.data?.msg?.error_code =='RES_001') {
                setErrorMsg(response?.data?.msg?.error);
            } else {
                setErrorMsg('');
                setMessage(response?.data?.msg);
            }
        }).catch(e => {
            setErrorMsg('');
            setMessage(e?.response?.data?.msg)
        })
    }

    return (
        <div>
            <Row>
                <Col lg={4} md={2} />
                <Col lg={4} md={8}>
                    <Card style={{ marginTop: "10vh" }}>
                        <Card.Body>
                            <div style={{ margin: "30px" }}>
                                <span style={{ fontSize: "2rem" }}>
                                    <img
                                        src={logoPath}
                                        alt="logo icon"
                                        className="company-logo"
                                        style={{ marginRight: "15px", marginLeft: "40px" }}
                                    />
                                    Wireless Vision
                                </span>
                                <hr></hr>
                                {isExpire && message ==''  ?
                                <Row>
                                    <Col>
                                        <span style={{ fontSize: "0.9rem", color:"red" }}>
                                            Your password has expired. Please change your password to continue using your account.
                                        </span>
                                    </Col>
                                </Row>
                                 : <> </>
                                }
                                {errorMsg !='' ?
                                <Row>
                                    <Col>
                                        <span style={{ fontSize: "0.9rem", color:"red" }}>                                            
                                            {errorMsg}
                                        </span>
                                    </Col>
                                </Row>
                                 : <> </>
                                }

                                <Row>
                                    <Col>
                                        <span style={{ fontSize: "1.3rem" }}>
                                            Reset Password 
                                        </span>
                                    </Col>
                                </Row>
                                {message ?
                                    <Fragment>
                                        <div>{message}</div>
                                        <div> <a href="https://wireless-vision.helpjuice.com">Back to login</a></div>
                                    </Fragment>
                                    : 
                                    <Formik
                                        validate={validate}
                                        initialValues={{
                                            password: "",
                                            password2: "",
                                        }}
                                        onSubmit={submit}
                                    >
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <label className="form-label mt-3 mb-0">Employee ID</label>
                                                    <Field
                                                        className="form-control"
                                                        name="emp_id"
                                                        autoComplete="off"
                                                        type="text"
                                                        autoFocus
                                                        readOnly={true} 
                                                        value = {(employee_id)}
                                                    ></Field>
                                                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <label className="form-label mt-3 mb-0">New Password</label>
                                                    <Field
                                                        className="form-control"
                                                        name="password"
                                                        autoComplete="off"
                                                        type="password"
                                                        autoFocus
                                                    ></Field>
                                                    <span className="error-text">
                                                        <ErrorMessage name="password" />
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <label className="form-label mt-3 mb-0">Confirm Password</label>
                                                    <Field
                                                        className="form-control"
                                                        name="password2"
                                                        autoComplete="off"
                                                        type='password'
                                                    ></Field>
                                                    <span className="error-text">
                                                        <ErrorMessage name="password2" />
                                                    </span>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col>

                                                    Password must contain at least:
                                                    <ul>
                                                        <li>
                                                            9 characters length
                                                        </li>
                                                        <li>
                                                            one uppercase letter
                                                        </li>
                                                        <li>
                                                            one lowercase letter
                                                        </li>
                                                        <li>
                                                            one number
                                                        </li>
                                                        <li>
                                                            one special character
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col>
                                                    <DefaultButton
                                                        buttonTitle={"Reset"}
                                                        buttonClass={"default-btn-color float-end"}
                                                        buttonType={"submit"}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Formik>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CreateNewPassword;
