import {
    ODOO_STORE_TRANSFER_TRACKER_DATA,
    ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA,
} from "./Constants";
const initialState = {
    loading: false,
    error: null,
    odooStoreTransferTrackers: [],
    odooStoreTransferTracker: {},
};
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case ODOO_STORE_TRANSFER_TRACKER_DATA.ODOO_STORE_TRANSFER_TRACKER_DATA_LOAD:
            return {
                ...state,
                loading: true,
                odooStoreTransferTrackers: [],
                error: false,
            }
        case ODOO_STORE_TRANSFER_TRACKER_DATA.ODOO_STORE_TRANSFER_TRACKER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                odooStoreTransferTrackers: action.odooStoreTransferTrackers,
                error: false,
            }
        //Load one
        case ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA.ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_LOAD:
            return {
                ...state,
                loading: true,
                odooStoreTransferTracker: {},
                error: null,
            };
        case ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA.ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                loading: true,
                odooStoreTransferTracker: action.odooStoreTransferTracker[0],
            };
        case ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA.ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return {
                ...state,
            };
    }
};
export default Reducer;
