import {
    EMPLOYEE_ROLE_DATA,
    EMPLOYEE_ROLE_GET_DATA,
    EMPLOYEE_ROLE_ADD_DATA,
    EMPLOYEE_ROLE_UPDATE_DATA,
    EMPLOYEE_ROLE_DELETE_DATA,
} from './Constants'


//For listing
const loadEmployeeRolesDataRequest = () => ({
    type: EMPLOYEE_ROLE_DATA.EMPLOYEE_ROLE_DATA_LOAD,
});
const setEmployeeRolesDataSuccess = (empRoles) => ({
    type: EMPLOYEE_ROLE_DATA.EMPLOYEE_ROLE_DATA_SUCCESS,
    empRoles,
});
const setEmployeeRolesDataFail = (error) => ({
    type: EMPLOYEE_ROLE_DATA.EMPLOYEE_ROLE_DATA_FAIL,
    error,
});

//for details
const loadEmployeeRolesGetDataRequest = (id) => ({
    type: EMPLOYEE_ROLE_GET_DATA.EMPLOYEE_ROLE_GET_DATA_LOAD,
    id,
});
const setEmployeeRolesGetDataSuccess = (response) => ({
    type: EMPLOYEE_ROLE_GET_DATA.EMPLOYEE_ROLE_GET_DATA_SUCCESS,
    empRole: response.empRole[0],
});
const setEmployeeRolesGetDataFail = (error) => ({
    type: EMPLOYEE_ROLE_GET_DATA.EMPLOYEE_ROLE_GET_DATA_FAIL,
    error,
});

//For add Roles Module mapping
const loadEmployeeRolesAddDataRequest = (request) => ({
    type: EMPLOYEE_ROLE_ADD_DATA.EMPLOYEE_ROLE_ADD_DATA_LOAD,
    request,
});
const setEmployeeRolesAddDataSuccess = (empRoles) => ({
    type: EMPLOYEE_ROLE_ADD_DATA.EMPLOYEE_ROLE_ADD_DATA_SUCCESS,
    empRoles,
});
const setEmployeeRolesAddDataFail = (error) => ({
    type: EMPLOYEE_ROLE_ADD_DATA.EMPLOYEE_ROLE_ADD_DATA_FAIL,
    error,
});

//update Roles Module Mapping
const loadEmployeeRolesUpdateDataRequest = (request) => ({
    type: EMPLOYEE_ROLE_UPDATE_DATA.EMPLOYEE_ROLE_UPDATE_DATA_LOAD,
    request,
});
const setEmployeeRolesUpdateDataSuccess = (empRoles) => ({
    type: EMPLOYEE_ROLE_UPDATE_DATA.EMPLOYEE_ROLE_UPDATE_DATA_SUCCESS,
    empRoles,
});
const setEmployeeRolesUpdateDataFail = (error) => ({
    type: EMPLOYEE_ROLE_UPDATE_DATA.EMPLOYEE_ROLE_UPDATE_DATA_FAIL,
    error,
});

//Delete Roles Module mapping
const loadEmployeeRolesDeleteDataRequest = (request) => ({
    type: EMPLOYEE_ROLE_DELETE_DATA.EMPLOYEE_ROLE_DELETE_DATA_LOAD,
    request,
});
const setEmployeeRolesDeleteDataSuccess = (empRoles) => ({
    type: EMPLOYEE_ROLE_DELETE_DATA.EMPLOYEE_ROLE_DELETE_DATA_SUCCESS,
    empRoles,
});
const setEmployeeRolesDeleteDataFail = (error) => ({
    type: EMPLOYEE_ROLE_DELETE_DATA.EMPLOYEE_ROLE_DELETE_DATA_FAIL,
    error,
});


export {
    loadEmployeeRolesDataRequest, setEmployeeRolesDataSuccess, setEmployeeRolesDataFail,
    loadEmployeeRolesGetDataRequest, setEmployeeRolesGetDataSuccess, setEmployeeRolesGetDataFail,
    loadEmployeeRolesAddDataRequest, setEmployeeRolesAddDataSuccess, setEmployeeRolesAddDataFail,
    loadEmployeeRolesUpdateDataRequest, setEmployeeRolesUpdateDataSuccess, setEmployeeRolesUpdateDataFail,
    loadEmployeeRolesDeleteDataRequest, setEmployeeRolesDeleteDataSuccess, setEmployeeRolesDeleteDataFail,
}