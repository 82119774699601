import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Alert = ({ alertType, message, onDismiss }) => {
  const [stimer, setSTimer] = useState(true);
  const [pageChangeClose, setPageChangeClose] = useState(false);
  let location = useLocation();

  useEffect(() => {
    // when the component is mounted, the alert is displayed for 3 seconds
    if (alertType.includes('alert-success')) {
      setTimeout(() => {
        setSTimer(false);
      }, 3000);
    }

    //After 2 seconds, navigating to a different page will clear the notificiation.
    setTimeout(() => {
      setPageChangeClose(true);
    }, 2000)
  }, []);

  useEffect(() => {
    if (pageChangeClose) {
      setSTimer(false);
    }
  }, [location])

  return stimer ? (
    <div className={alertType + " position-fixed"} role="alert" style={{ zIndex: 11, left: '10%', right: '10%', top: '90px' }}>
      {message}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={onDismiss}
      ></button>
    </div>
  ) : (
    <div />
  );
};

export default Alert;
