import React, { useEffect, useState } from "react";
import { COMPANY } from "../../../shared/constants/Constants";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import { Col, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import ListView from "../../../shared/components/listview/ListView";
import { loadTShirtDataRequest } from "../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../shared/components/Loader";
import Permissions from "../../../shared/components/Permissions";
import TShirtSizeEdit from "./TShirtSizeEdit";
import Modalpopup from "../../../shared/components/Modalpopup";
import TShirtSize from "./TShirtSize";

const GetAllTShirtSize = () => {
  let dispatch = useDispatch();
  let { loading, error, empAdds } = useSelector((state) => state.tShirtReducer);
  useEffect(() => {
    dispatch(loadTShirtDataRequest());
  }, [dispatch]);

  let navigate = useNavigate();

  let empAddData = empAdds;

  const [showEditModal, setShowEditModal] = useState(false);

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "TShirt Size";
  let permission = Permissions(emp_no, module_name);
  let actionsPermission = !permission.write_permission;

  let empAddColumns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true,
    },
    {
      display_name: "Employee Id",
      field: "employee_id",
      type: "text",
    },
    {
      display_name: "Employee Name",
      field: "emp_name",
      type: "text",
    },
    {
      display_name: "Hire Date",
      field: "hire_date",
      type: "text",
    },
    {
      display_name: "T-Shirt Style",
      field: "tshirt_cut",
      type: "text",
    },
    {
      display_name: "T-Shirt Size",
      field: "tshirt_size",
      type: "text",
    },
    {
      display_name: "Store Name",
      field: "store_name",
      type: "text",
    },
    {
      display_name: "Market Name",
      field: "market_name",
      type: "text",
    },
    {
      display_name: "Region Name",
      field: "region_name",
      type: "text",
    },
    {
      display_name: "Address",
      field: "address_street",
      type: "text",
      // renderCell: (params) => {
      //   console.log("parama::", params);
      //   // return <img src={params.value} width="50" />
      // },
    },
    {
      display_name: "MLC Location",
      field: "mlc_location",
      type: "text",
    },
    {
      display_name: "DSOM",
      field: "dsom",
      type: "text",
    },
    {
      display_name: "",
      field: "actions",
      type: "actions",
      hide: actionsPermission,
    },
  ];

  let [selectedRow, setSelectedRow] = useState({});
  let [clickEditFunc, setClickEditFunc] = useState(null);
  const [addFlag, setAddFlag] = useState(false);
  useEffect(() => {
    if (permission.write_permission) {
      setClickEditFunc(() => (row) => {
        if (row.id === null) {
          setAddFlag(true);
        } else {
          setAddFlag(false);
        }
        setSelectedRow(row);
        setShowEditModal(true);
      });
    }
  }, [permission]);

  return (
    <>
      <div>
        <Row>
          <Col className="gx-0">
            <Breadcrumbs children={[{ label: "Employee Additional Info" }]} />
          </Col>
        </Row>
        {loading && empAdds.length === 0 ? (
          <Loader />
        ) : (
          <>
            <Row>
              <Card className="table-card">
                <Card.Body>
                  <ListView
                    exportTitle="Get All T-Shirt Size"
                    columnNames={empAddColumns}
                    rowData={empAddData}
                    shouldSetNextPrevIds
                    // rowClick={(row) => {
                    //   navigate("./" + row.data["id"]);
                    // }}
                    tableHeight="70vh"
                    clickEdit={clickEditFunc}
                  />
                </Card.Body>
              </Card>
            </Row>
            {addFlag === true ? (<>
              <Modalpopup
                show={showEditModal}
                handleClose={closeEditModal}
                actionTitle={"Add T-shirt Size"}
              >
                <TShirtSize data={selectedRow} handleShirtClose={(reload) => {
                    if (reload) {
                      dispatch(loadTShirtDataRequest());
                    }
                    closeEditModal();
                  }
                  }  />
              </Modalpopup>
            </>) : (<>
              <Modalpopup
                show={showEditModal}
                handleClose={closeEditModal}
                actionTitle={"Edit T-shirt Size"}
              >
                <TShirtSizeEdit
                  data={selectedRow}
                  handleShirtClose={(reload) => {
                    if (reload) {
                      dispatch(loadTShirtDataRequest());
                    }
                    closeEditModal();
                  }
                  }
                />
              </Modalpopup>
            </>)}
          </>
        )}
      </div>
    </>
  );
};

export default GetAllTShirtSize;

