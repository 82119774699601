import {
  REGION_DATA,
  REGION_ADD_DATA,
  REGION_GET_DATA,
  REGION_DELETE_DATA,
  REGION_UPDATE_DATA,
  REGION_GET_ALL_DATA,
  REGION_TRACKER_DELETE_DATA,
  REGION_IMPORT_DATA,
  REGION_TRACKER_IMPORT_DATA,
  REGION_GET_MARKETS,
} from "./Constants";

//For listing
const loadRegionDataRequest = (request) => ({
  type: REGION_DATA.REGION_DATA_LOAD,
  request
});
const setRegionDataSuccess = (regions) => ({
  type: REGION_DATA.REGION_DATA_SUCCESS,
  regions,
});
const setRegionDataFail = (error) => ({
  type: REGION_DATA.REGION_DATA_FAIL,
  error,
});

//For add Region
const loadRegionAddDataRequest = (request) => ({
  type: REGION_ADD_DATA.REGION_ADD_DATA_LOAD,
  request,
});
const setRegionAddDataSuccess = (region) => ({
  type: REGION_ADD_DATA.REGION_ADD_DATA_SUCCESS,
  region,
});

const setRegionAddDataFail = (error) => ({
  type: REGION_ADD_DATA.REGION_ADD_DATA_FAIL,
  error,
});

//To Get Region Details
const loadRegionGetDataRequest = (id) => ({
  type: REGION_GET_DATA.REGION_GET_DATA_LOAD,
  id,
});

const setRegionGetDataSuccess = (response) => ({
  type: REGION_GET_DATA.REGION_GET_DATA_SUCCESS,
  region:response.region[0],
  trackers:response['region-tracker'],
  managers:response['manager-history'],
  activeMarkets:response['markets'],
});

const setRegionGetDataFail = (error) => ({
  type: REGION_GET_DATA.REGION_GET_DATA_FAIL,
  error,
});

//For Edit Region
const loadRegionUpdateDataRequest = (request) => ({
  type: REGION_UPDATE_DATA.REGION_UPDATE_DATA_LOAD,
  request,
});

const setRegionUpdateDataSuccess = (region) => ({
  type: REGION_UPDATE_DATA.REGION_UPDATE_DATA_SUCCESS,
  region,
});

const setRegionUpdateDataFail = (error) => ({
  type: REGION_UPDATE_DATA.REGION_UPDATE_DATA_FAIL,
  error,
});

//Delete Region
const loadRegionDeleteDataRequest = (request) => ({
  type: REGION_DELETE_DATA.REGION_DELETE_DATA_LOAD,
  request,
});

const setRegionDeleteDataSuccess = (region) => ({
  type: REGION_DELETE_DATA.REGION_DELETE_DATA_SUCCESS,
  region,
});

const setRegionDeleteDataFail = (error) => ({
  type: REGION_DELETE_DATA.REGION_DELETE_DATA_FAIL,
  error,
});

//Get All Region Tracker
const loadGetRegionTrackerDataRequest = () => ({
  type: REGION_GET_ALL_DATA.REGION_GET_ALL_DATA_LOAD,
});
const setGetRegionTrackerDataSuccess = (trackers) => ({
  type: REGION_GET_ALL_DATA.REGION_GET_ALL_DATA_SUCCESS,
  trackers,
});
const setGetRegionTrackerDataFail = (error) => ({
  type: REGION_GET_ALL_DATA.REGION_GET_ALL_DATA_FAIL,
  error,
});

//Delete Region Tracker
const loadRegionTrackerDeleteDataRequest = (request) => ({
  type: REGION_TRACKER_DELETE_DATA.REGION_TRACKER_DELETE_DATA_LOAD,
  request,
});

const setRegionTrackerDeleteDataSuccess = (tracker) => ({
  type: REGION_TRACKER_DELETE_DATA.REGION_TRACKER_DELETE_DATA_SUCCESS,
  tracker,
});

const setRegionTrackerDeleteDataFail = (error) => ({
  type: REGION_TRACKER_DELETE_DATA.REGION_TRACKER_DELETE_DATA_FAIL,
  error,
});

//Import Region
const loadRegionImportDataRequest = (request) => ({
  type: REGION_IMPORT_DATA.REGION_IMPORT_DATA_LOAD,
  request,
});

const setRegionImportDataSuccess = (regions) => ({
  type: REGION_IMPORT_DATA.REGION_IMPORT_DATA_SUCCESS,
  regions,
});

const setRegionImportDataFail = (error) => ({
  type: REGION_IMPORT_DATA.REGION_IMPORT_DATA_FAIL,
  error,
});

//Import Region Tracker
const loadRegionTrackerImportDataRequest = (request) => ({
  type: REGION_TRACKER_IMPORT_DATA.REGION_TRACKER_IMPORT_DATA_LOAD,
  request,
});

const setRegionTrackerImportDataSuccess = (trackers) => ({
  type: REGION_TRACKER_IMPORT_DATA.REGION_TRACKER_IMPORT_DATA_SUCCESS,
  trackers,
});

const setRegionTrackerImportDataFail = (error) => ({
  type: REGION_TRACKER_IMPORT_DATA.REGION_TRACKER_IMPORT_DATA_FAIL,
  error,
});
//Get Region's markets
const loadRegionGetMarketsRequest = (id) => ({
  type: REGION_GET_MARKETS.REGION_GET_MARKETS_LOAD,
  id
})
const setRegionGetMarketsSuccess = (markets) => ({
  type: REGION_GET_MARKETS.REGION_GET_MARKETS_SUCCESS,
  markets
})
const setRegionGetMarketsFail = (error) => ({
  type: REGION_GET_MARKETS.REGION_GET_MARKETS_FAIL,
  error
})

export {
  loadRegionDataRequest,
  setRegionDataSuccess,
  setRegionDataFail,
  loadRegionAddDataRequest,
  setRegionAddDataSuccess,
  setRegionAddDataFail,
  loadRegionGetDataRequest,
  setRegionGetDataSuccess,
  setRegionGetDataFail,
  loadRegionUpdateDataRequest,
  setRegionUpdateDataSuccess,
  setRegionUpdateDataFail,
  loadRegionDeleteDataRequest,
  setRegionDeleteDataSuccess,
  setRegionDeleteDataFail,
  loadGetRegionTrackerDataRequest,
  setGetRegionTrackerDataSuccess,
  setGetRegionTrackerDataFail,
  loadRegionTrackerDeleteDataRequest,
  setRegionTrackerDeleteDataSuccess,
  setRegionTrackerDeleteDataFail,
  loadRegionImportDataRequest,
  setRegionImportDataSuccess,
  setRegionImportDataFail,
  loadRegionTrackerImportDataRequest,
  setRegionTrackerImportDataSuccess,
  setRegionTrackerImportDataFail,
  loadRegionGetMarketsRequest, setRegionGetMarketsSuccess, setRegionGetMarketsFail,
};
