import React, { useState, useEffect } from "react";
import { SiteAlerts } from "../containers/alert/SiteAlerts";
import Header from "../containers/header/Header";
import Menu from "../containers/menu/Menu";
import MenuCollapsed from "../containers/menu/MenuCollapsed";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadRoleAccessGetDataRequest, setLoggedOut } from "../containers/login/redux/Actions";
import { setTokenToHeader } from "../shared/utilites/ApiClient";
import useWindowDimensions from "../shared/components/useWindowDimensions";

export const MainLayout = () => {

  let [sidebarToggled, setSidebarToggled] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let wrapperClass = sidebarToggled ? " toggled" : "";

  let { width } = useWindowDimensions();


  const toggle = () => {
    setSidebarToggled(!sidebarToggled);
  };

  let { isLoggedIn, roleAccess } = useSelector((state) => state.login);

  const [userLoggedIn, setUserLoggedIn] = useState(isLoggedIn)

  useEffect(() => {
    if (!!localStorage.getItem("employee")) {
      let empInfo = JSON.parse(localStorage.getItem('employee'));
      dispatch(loadRoleAccessGetDataRequest(empInfo.emp_no))
      setTokenToHeader(empInfo.token);
    }
  }, [dispatch]);

  // let userLoggedIn =  isLoggedIn || !!localStorage.getItem("permission_count");

  const checkForExpiration = () => {
    try {
      let jwt_token = localStorage.getItem('jwt_token');
      if (jwt_token) {
        let parsed_jwt = Buffer.from(jwt_token.split('.')[1], 'base64').toString()
        let jwt_object = JSON.parse(parsed_jwt);
        let jwt_exp = jwt_object.exp

        //Check if jwt expired
        if (new Date() > new Date(jwt_exp * 1000)) {
          setUserLoggedIn(false)
        }
      } else {
        setUserLoggedIn(false)
      }
    }catch(e){
      setUserLoggedIn(false)
    }
  }
  useEffect(() => {
    checkForExpiration();
    if (width <= 768) {
      setSidebarToggled(true)
    }
  }, [location.pathname])

  // Check if the jwt expired every 3 minutes. This will redirect them if they 
  // leave the page open, which could be annoying if they're just looking at data,
  // but it will prevent them from trying to make changes with an expired jwt

  // useEffect(()=>{
  //   let interval = setInterval(checkForExpiration,1000 * 60 * 3)
  //   return ()=>{ clearInterval(interval)}
  // },[])

  useEffect(() => {
    if (!userLoggedIn) {
      localStorage.removeItem('jwt_token');
      localStorage.removeItem('jwt_time');
      localStorage.removeItem('employee');
      dispatch(setLoggedOut());
      navigate("/login", { replace: true, state: { from: location.pathname } });
    }
  }, [userLoggedIn, roleAccess]);

  return (
    <div className={"wrapper " + wrapperClass}>
      {(sidebarToggled && width > 768) ? (
        <div>
          <MenuCollapsed />
        </div>
        // <></>
      ) : (
        <div className="menu">
          <Menu toggled={sidebarToggled} toggleMenu={toggle} />
        </div>
        // <></>
      )}

      <div className="main">
        <Header toggleMenu={toggle} />
        {/* <SiteAlerts /> */}
        {userLoggedIn && <Outlet />}
      </div>
    </div>
  );
};

export default MainLayout;
