import {
    COMP_GROUP_DATA,
    COMP_GROUP_ADD_DATA,
    COMP_GROUP_UPDATE_DATA,
    COMP_GROUP_GET_DATA,
    COMP_GROUP_DELETE_DATA,
  } from "./Constants";
  
  const initialState = {
    loading: false,
    error: null,
    compgroups: [],
    compgroup: {},
  };
  
  const Reducer = (state = initialState, action) => {
    switch (action.type) {
      case COMP_GROUP_DATA.COMP_GROUP_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
          compgroups: [],
        };
      case COMP_GROUP_DATA.COMP_GROUP_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          compgroups: action.compgroups,
          error: null,
        };
      case COMP_GROUP_DATA.COMP_GROUP_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
          compgroups: [],
        };

      //Add
      case COMP_GROUP_ADD_DATA.COMP_GROUP_ADD_DATA_LOAD:
        return {
          ...state,
          loading: true,
          compgroup: {},
          error: null,
        };
      case COMP_GROUP_ADD_DATA.COMP_GROUP_ADD_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          compgroup: action.compgroup,
          error: null,
        };
      case COMP_GROUP_ADD_DATA.COMP_GROUP_ADD_DATA_FAIL:
        return {
          ...state,
          loading: false,
          compgroup: {},
          error: action.error,
        };
      //Get
      case COMP_GROUP_GET_DATA.COMP_GROUP_GET_DATA_LOAD:
        return {
          ...state,
          loading: true,
          compgroup: {},
          error: null,
        };
      case COMP_GROUP_GET_DATA.COMP_GROUP_GET_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          compgroup: action.compgroup,
          error: null,
        };
      case COMP_GROUP_GET_DATA.COMP_GROUP_GET_DATA_FAIL:
        return {
          ...state,
          loading: false,
          compgroup: {},
          error: action.error,
        };
      //Update
      case COMP_GROUP_UPDATE_DATA.COMP_GROUP_UPDATE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          compgroup: {},
          error: null,
        };
      case COMP_GROUP_UPDATE_DATA.COMP_GROUP_UPDATE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          compgroup: action.compgroup,
          error: false,
        };
      case COMP_GROUP_UPDATE_DATA.COMP_GROUP_UPDATE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          compgroup: {},
          error: action.error,
        };
      //Delete
      case COMP_GROUP_DELETE_DATA.COMP_GROUP_DELETE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          compgroup: {},
          error: null,
        };
      case COMP_GROUP_DELETE_DATA.COMP_GROUP_DELETE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          compgroup: action.compgroup,
          error: null,
        };
      case COMP_GROUP_DELETE_DATA.COMP_GROUP_DELETE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          compgroup: {},
          error: action.error,
        };
      default:
        return {
          ...state,
        };
    }
  };
  
  export default Reducer;
  