const JOBTITLE_DATA = {
    JOBTITLE_DATA_LOAD: "JOBTITLE_DATA_LOAD",
    JOBTITLE_DATA_SUCCESS: "JOBTITLE_DATA_SUCCESS",
    JOBTITLE_DATA_FAIL: "JOBTITLE_DATA_FAIL",
  };
  
  const JOBTITLE_GET_API = "/employee/v1/jobtitle";
  
  //Get
  const JOBTITLE_GET_DATA = {
    JOBTITLE_GET_DATA_LOAD: "JOBTITLE_GET_DATA_LOAD",
    JOBTITLE_GET_DATA_SUCCESS: "JOBTITLE_GET_DATA_SUCCESS",
    JOBTITLE_GET_DATA_FAIL: "JOBTITLE_GET_DATA_FAIL",
  };
  
  const JOBTITLE_GET_DETAIL_API = "/employee/v1/jobtitle/";
  
  // Create
  const JOBTITLE_ADD_DATA = {
    JOBTITLE_ADD_DATA_LOAD: "JOBTITLE_ADD_DATA_LOAD",
    JOBTITLE_ADD_DATA_SUCCESS: "JOBTITLE_ADD_DATA_SUCCESS",
    JOBTITLE_ADD_DATA_FAIL: "JOBTITLE_ADD_DATA_FAIL",
  };
  
  const JOBTITLE_POST_API = "/employee/v1/jobtitle";
  
  //Update
  const JOBTITLE_UPDATE_DATA = {
    JOBTITLE_UPDATE_DATA_LOAD: "JOBTITLE_UPDATE_DATA_LOAD",
    JOBTITLE_UPDATE_DATA_SUCCESS: "JOBTITLE_UPDATE_DATA_SUCCESS",
    JOBTITLE_UPDATE_DATA_FAIL: "JOBTITLE_UPDATE_DATA_FAIL",
  };
  
  const JOBTITLE_PUT_API = "/employee/v1/jobtitle";
  
  //Delete
  const JOBTITLE_DELETE_DATA = {
    JOBTITLE_DELETE_DATA_LOAD: "JOBTITLE_DELETE_DATA_LOAD",
    JOBTITLE_DELETE_DATA_SUCCESS: "JOBTITLE_DELETE_DATA_SUCCESS",
    JOBTITLE_DELETE_DATA_FAIL: "JOBTITLE_DELETE_DATA_FAIL",
  };
  
  const JOBTITLE_DELETE_API = "/employee/v1/jobtitle";

//Import Company
const JOBTITLE_IMPORT_DATA = {
  JOBTITLE_IMPORT_DATA_LOAD:'JOBTITLE_IMPORT_DATA_LOAD',
  JOBTITLE_IMPORT_DATA_SUCCESS:'JOBTITLE_IMPORT_DATA_SUCCESS', 
  JOBTITLE_IMPORT_DATA_FAIL:'JOBTITLE_IMPORT_DATA_FAIL'
}

const JOBTITLE_IMPORT_API = '/upload'
  
  export {
    JOBTITLE_DATA,
    JOBTITLE_GET_API,
    JOBTITLE_GET_DATA,
    JOBTITLE_GET_DETAIL_API,
    JOBTITLE_ADD_DATA,
    JOBTITLE_POST_API,
    JOBTITLE_UPDATE_DATA,
    JOBTITLE_PUT_API,
    JOBTITLE_DELETE_DATA,
    JOBTITLE_DELETE_API,
    JOBTITLE_IMPORT_DATA,
    JOBTITLE_IMPORT_API,
  };
  