import {
    ROLE_MODULE_DATA,
    ROLE_MODULE_GET_DATA,
    ROLE_MODULE_ADD_DATA,
    ROLE_MODULE_UPDATE_DATA,
    ROLE_MODULE_DELETE_DATA,
} from './Constants'


//For listing
const loadRoleModulesDataRequest = () => ({
    type: ROLE_MODULE_DATA.ROLE_MODULE_DATA_LOAD,
});
const setRoleModulesDataSuccess = (roleModules) => ({
    type: ROLE_MODULE_DATA.ROLE_MODULE_DATA_SUCCESS,
    roleModules,
});
const setRoleModulesDataFail = (error) => ({
    type: ROLE_MODULE_DATA.ROLE_MODULE_DATA_FAIL,
    error,
});

//for details
const loadRoleModulesGetDataRequest = (id) => ({
    type: ROLE_MODULE_GET_DATA.ROLE_MODULE_GET_DATA_LOAD,
    id,
});
const setRoleModulesGetDataSuccess = (response) => ({
    type: ROLE_MODULE_GET_DATA.ROLE_MODULE_GET_DATA_SUCCESS,
    roleModule: response[0],
});
const setRoleModulesGetDataFail = (error) => ({
    type: ROLE_MODULE_GET_DATA.ROLE_MODULE_GET_DATA_FAIL,
    error,
});

//For add Roles Module mapping
const loadRoleModulesAddDataRequest = (request) => ({
    type: ROLE_MODULE_ADD_DATA.ROLE_MODULE_ADD_DATA_LOAD,
    request,
});
const setRoleModulesAddDataSuccess = (roleModules) => ({
    type: ROLE_MODULE_ADD_DATA.ROLE_MODULE_ADD_DATA_SUCCESS,
    roleModules,
});
const setRoleModulesAddDataFail = (error) => ({
    type: ROLE_MODULE_ADD_DATA.ROLE_MODULE_ADD_DATA_FAIL,
    error,
});

//update Roles Module Mapping
const loadRoleModulesUpdateDataRequest = (request) => ({
    type: ROLE_MODULE_UPDATE_DATA.ROLE_MODULE_UPDATE_DATA_LOAD,
    request,
});
const setRoleModulesUpdateDataSuccess = (roleModules) => ({
    type: ROLE_MODULE_UPDATE_DATA.ROLE_MODULE_UPDATE_DATA_SUCCESS,
    roleModules,
});
const setRoleModulesUpdateDataFail = (error) => ({
    type: ROLE_MODULE_UPDATE_DATA.ROLE_MODULE_UPDATE_DATA_FAIL,
    error,
});

//Delete Roles Module mapping
const loadRoleModulesDeleteDataRequest = (request) => ({
    type: ROLE_MODULE_DELETE_DATA.ROLE_MODULE_DELETE_DATA_LOAD,
    request,
});
const setRoleModulesDeleteDataSuccess = (roleModules) => ({
    type: ROLE_MODULE_DELETE_DATA.ROLE_MODULE_DELETE_DATA_SUCCESS,
    roleModules,
});
const setRoleModulesDeleteDataFail = (error) => ({
    type: ROLE_MODULE_DELETE_DATA.ROLE_MODULE_DELETE_DATA_FAIL,
    error,
});


export {
    loadRoleModulesDataRequest, setRoleModulesDataSuccess, setRoleModulesDataFail,
    loadRoleModulesGetDataRequest, setRoleModulesGetDataSuccess, setRoleModulesGetDataFail,
    loadRoleModulesAddDataRequest, setRoleModulesAddDataSuccess, setRoleModulesAddDataFail,
    loadRoleModulesUpdateDataRequest, setRoleModulesUpdateDataSuccess, setRoleModulesUpdateDataFail,
    loadRoleModulesDeleteDataRequest, setRoleModulesDeleteDataSuccess, setRoleModulesDeleteDataFail,
}