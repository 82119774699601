import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { COMPANY, DELETE, CANCEL, BACKTOLIST, EDIT, COMPANY_NAME, ULTI_COMPANY_CODE, MASTER_DEALER_CODE, START_DATE, END_DATE, DELETE_CONFIRM } from "../../../shared/constants/Constants";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCompanyGetDataRequest,
  loadCompanyDeleteDataRequest,
  loadCompanyDataRequest,
} from "../redux/Actions";
import { useNavigate } from "react-router";
import Modalpopup from "../../../shared/components/Modalpopup";
import DefaultButton from "../../../shared/components/DefaultButton";
import { PrevNextButtons } from "../../../shared/components/PrevNextButtons";
import Permissions from "../../../shared/components/Permissions";
import FindModule from "../../../shared/components/FindModule";
import KeyTagModuleList from "../../../shared/components/tagging/KeyTagModuleList";
import Tagging from "../../../shared/components/tagging/Tagging";
const CompanyDetails = () => {
  let params = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let [submittedDeleteRequest, setSubmittedDeleteRequest] = useState(false)


  let { loading, error, company, companies } = useSelector((state) => state.company);

  let [companyData, setCompanyData] = useState(company);
  useEffect(() => {
    if (companies.length === 0) {
      dispatch(loadCompanyDataRequest());
    }
    dispatch(loadCompanyGetDataRequest(params.id));
  }, [dispatch, companies.length, params.id]);



  const [show, setShow] = useState(false);
  useEffect(() => {
    if (company.length > 0) {
      setCompanyData(company[0]);
    }
  }, [company]);

  //Delete Functionality
  const handleDelete = () => {
    let deleteRequest = { id: [params.id] };
    dispatch(loadCompanyDeleteDataRequest(deleteRequest));
    setSubmittedDeleteRequest(true)
  };

  useEffect(() => {
    if (submittedDeleteRequest && !loading) {
      navigate('/company')
    }
  }, [loading, company.msg, dispatch, error, navigate, submittedDeleteRequest])

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "Company";
  let permission = Permissions(emp_no, module_name);

  //Find Tag Key Exists or not and Tagging Functionality
  let selectedModule = FindModule(module_name);
  let keytagModules = KeyTagModuleList(selectedModule?.id); 
  let [currentTracker, setCurrentTracker] = useState(1);


  let trackers = [];
  let taggingTab = false;
  if (keytagModules.length !== 0) {
      taggingTab = true;
      trackers = [
          <Tagging resource_value={companyData?.id} resource_id={selectedModule?.id} />
      ]
  }  

  return (
    <>
      <Row>
        <Col xl={4} xs={12}>
          <Breadcrumbs
            label={COMPANY}
            children={[
              { href: '/company', label: COMPANY },
              { label: params.id }
            ]}
          />
        </Col>
        <Col xl={8} xs={12}>
          <div className="float-end">
            <PrevNextButtons currentId={params.id} basePath="/company/" name={COMPANY}/>
            <DefaultButton
              buttonClass={"default-btn-color me-1"}
              buttonTitle={BACKTOLIST}
              actionBlock={() => { navigate("/company"); }}
            />
            {permission.write_permission ? (<>
              <DefaultButton buttonClass={"default-btn-color"} buttonTitle={EDIT} actionBlock={() => {
                navigate("/company/edit/" + companyData.id);
              }} />
            </>) : (<></>)}
            {permission.delete_permission ? (<>
              <DefaultButton buttonClass={"btn btn-danger ms-1"} buttonTitle={<i className="fa fa-trash"></i>} actionBlock={handleShow} />
            </>) : (<></>)}
          </div>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <dl className="row">
              <dt className="col-sm-3">{COMPANY_NAME} :</dt>
              <dd className="col-sm-9">{companyData.company_name}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">{ULTI_COMPANY_CODE} :</dt>
              <dd className="col-sm-9">{companyData.ulti_company_code}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">{MASTER_DEALER_CODE} : </dt>
              <dd className="col-sm-9">{companyData.master_dealer_code}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">{START_DATE} :</dt>
              <dd className="col-sm-9">{companyData.start_date}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">{END_DATE} : </dt>
              <dd className="col-sm-9">{companyData.end_date}</dd>
            </dl>
          </Card.Body>
        </Card>
      </Row>
      <Modalpopup
        show={show}
        handleClose={handleClose}
        actionTitle={"Delete Company"}
      >
        <p>{DELETE_CONFIRM}</p>
        <div className="col-md-12">
          <DefaultButton
            buttonClass={"btn btn-secondary me-2"}
            buttonTitle={CANCEL}
            actionBlock={handleClose}
          />
          <DefaultButton
            buttonClass={"btn btn-danger"}
            buttonTitle={DELETE}
            actionBlock={handleDelete}
          />
        </div>

      </Modalpopup>
      <br />
      {taggingTab ? (
      <Row>
          <Col>      
            <button className={currentTracker === 1 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(1) }}>Additional Attribute</button>
          </Col>
          <Card >
              <Card.Body >
                  {trackers[currentTracker - 1]}
              </Card.Body>
          </Card>
      </Row>
      ) : (<></>)}   
    </>
  );
};

export default CompanyDetails;
