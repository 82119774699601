import ListView from "../../../shared/components/listview/ListView"

export default function ManagerTracker({manager_history_tracker}) {
    console.log(manager_history_tracker)
    const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'Manager',
            field: 'store_manager',
            type: 'text',
        },
        {
            display_name: 'Covering',
            field: 'covering_store',
            type: "icon",
            icon: "checkbox",
            width: 100,
        },
        {
            display_name: 'Start Date',
            field: 'start_date',
            type: 'date',
        },
        {
            display_name: 'End Date',
            field: 'end_date',
            type: 'date',
        }
    ]

    return <ListView
    exportTitle={"store_managers"}
        columnNames={columnDefs}
        rowData={manager_history_tracker}
        tableHeight = '50vh'
    />
}