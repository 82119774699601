import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ListView from "../../../shared/components/listview/ListView"
import Modalpopup from "../../../shared/components/Modalpopup"
import DefaultButton from "../../../shared/components/DefaultButton"
import { loadDensityTrackerAddDataRequest, loadDensityTrackerDeleteDataRequest, loadDensityTrackerUpdateDataRequest } from "../redux/Actions"
import { DELETE, CANCEL } from "../../../shared/constants/Constants"
import { loadDensityDataRequest } from "../../density/redux/Actions"
import DensityTrackerForm from "./DensityTrackerForm"
import Permissions from "../../../shared/components/Permissions"
import FormError from "../../../shared/components/FormError"


const DensityTracker = ({ density_tracker, store, setShouldReload }) => {
    const dispatch = useDispatch();

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Density Class Tracker";
    let permission = Permissions(emp_no, module_name);

    let actionsPermission = !(permission.write_permission || permission.delete_permission);

    const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        // {
        //     display_name: "SAP",
        //     field: "sap_id",
        //     type: "text",
        // },
        {
            display_name: "Density Class Name",
            field: "density_class_name",
            type: "text",
        },
        {
            display_name: "Start Date",
            field: "start_date",
            type: "date",
            sort: "desc"
        },
        {
            display_name: "End Date",
            field: "end_date",
            type: "date",
        },
        {
            display_name: 'Actions',
            field: 'actions',
            hide: actionsPermission
        }
    ]
    let { densities } = useSelector(store => store.density);
    let { loading, error } = useSelector(state => state.store);

    useEffect(() => {
        dispatch(loadDensityDataRequest());
    }, [dispatch])

    let [trackerData, setTrackerData] = useState([])
    useEffect(() => {
        let newData = []
        if (density_tracker) {
            density_tracker.forEach(element => {
                let newDensity = densities.find(density => density.id == element.density_class_id)
                newData.push({
                    ...element,
                    density_class_id: newDensity?.density_class_name,
                    sap_id: store.sap
                })
            });
            setTrackerData(newData)
        }
    }, [density_tracker, store, densities]);

    let [showEditModal, setShowEditModal] = useState(false)
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [showAddModal, setShowAddModal] = useState(false);
    let [selectedTracker, setSelectedTracker] = useState({});

    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddModal();
                closeEditModal();
                setShouldReload(true)
            } else {
                console.log(error)
                setErrorMessage(error.msg);
            }
            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])

    const closeAddModal = () => {
        setShowAddModal(false);
        setErrorMessage(null);
    }
    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }

    const handleEdit = (values) => {
        console.log(values)
        dispatch(loadDensityTrackerUpdateDataRequest({
            ...values,
            id: selectedTracker.id,
        }))
        setShouldCloseModal(true)
    }
    const handleAdd = (values) => {
        dispatch(loadDensityTrackerAddDataRequest(
            {
                ...values,
            }
        ));
        setShouldCloseModal(true)
    }
    const handleDelete = () => {
        setShowDeleteModal(false);
        dispatch(loadDensityTrackerDeleteDataRequest({
            id: [selectedTracker],
            sap_id: store.id,
        }))
        setShouldReload(true)
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    console.log(row)
                    setSelectedTracker(row);
                    setShowEditModal(true);
                })
        }
        if (permission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedTracker(id);
                    setShowDeleteModal(true);
                })
        }
    }, [permission])
    return <>
        {permission.create_permission ? (
            <Button style={{ width: 115 }} className="btn btn-secondary default-btn-color float-end py-1" onClick={() => { setShowAddModal(true) }}>Add Tracker</Button>
        ) : (<></>)}
        <ListView
            exportTitle={'Density class tracker'}
            columnNames={columnDefs}
            rowData={trackerData}
            clickEdit={clickEditFunc}
            clickDelete={clickDeleteFunc}
            tableHeight = '50vh'
        />
        <Modalpopup show={showEditModal} actionTitle="Edit Density Class Tracker" handleClose={closeEditModal} >
            <FormError>
                {errorMessage}
            </FormError>
            <DensityTrackerForm tracker={selectedTracker} submit={handleEdit} cancel={() => setShowEditModal(false)} store={store} />
        </Modalpopup>
        <Modalpopup show={showDeleteModal} actionTitle="Delete Density Class Tracker" handleClose={() => { setShowDeleteModal(false) }}>
            Are you sure you want to delete tracker?
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>
        <Modalpopup show={showAddModal} actionTitle="Add Density Class Tracker" handleClose={closeAddModal}>
            <FormError>
                {errorMessage}
            </FormError>
            <DensityTrackerForm submit={handleAdd} cancel={closeAddModal} store={store} />
        </Modalpopup>
    </>
}
export default DensityTracker
