import {
  EMPLOYEE_DATA,
  EMPLOYEE_GET_DATA,
  EMPLOYEE_ADD_DATA,
  EMPLOYEE_UPDATE_DATA,
  EMPLOYEE_DELETE_DATA,
  EMPLOYEE_IMPORT_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA,
  EMPLOYEE_TYPE_TRACKER_ADD_DATA,
  EMPLOYEE_TYPE_TRACKER_EDIT_DATA,
  EMPLOYEE_TYPE_TRACKER_DELETE_DATA,
  EMPLOYEE_TYPE_TRACKER_DATA,
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA,
  EMP_DESIGNATION_TRACKER_GET_DATA,
  EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA,
  ACTIVE_EMPLOYEE_DATA,
  ACTIVE_EMPLOYEE_GET_API,
  EMPLOYEE_COUNT,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING
} from "./Constants";

//For listing
const loadEmployeeDataRequest = (filters, page, sort) => ({
  type: EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD,
  filters,
  page,
  sort,
});
const setEmployeeDataSuccess = (employees) => ({
  type: EMPLOYEE_DATA.EMPLOYEE_DATA_SUCCESS,
  employees
});
const setEmployeeDataFail = (error) => ({
  type: EMPLOYEE_DATA.EMPLOYEE_DATA_FAIL,
  error,
});
//Get total count
const loadEmployeeRowCount = (filters) => ({
  type: EMPLOYEE_COUNT.EMPLOYEE_COUNT_LOAD,
  filters
})
const setEmployeeRowCountSuccess = (count) => ({
  type: EMPLOYEE_COUNT.EMPLOYEE_COUNT_SUCCESS,
  count
})
const setEmployeeRowCountFail = (error) => ({
  type: EMPLOYEE_COUNT.EMPLOYEE_COUNT_FAIL,
  error
})

//for details
const loadEmployeeGetDataRequest = (id) => ({
  type: EMPLOYEE_GET_DATA.EMPLOYEE_GET_DATA_LOAD,
  id,
});
const setEmployeeGetDataSuccess = (response) => ({
  type: EMPLOYEE_GET_DATA.EMPLOYEE_GET_DATA_SUCCESS,
  employee: response,
});
const setEmployeeGetDataFail = (error) => ({
  type: EMPLOYEE_GET_DATA.EMPLOYEE_GET_DATA_FAIL,
  error,
});

//For add Employee
const loadEmployeeAddDataRequest = (request) => ({
  type: EMPLOYEE_ADD_DATA.EMPLOYEE_ADD_DATA_LOAD,
  request,
});
const setEmployeeAddDataSuccess = (employee) => ({
  type: EMPLOYEE_ADD_DATA.EMPLOYEE_ADD_DATA_SUCCESS,
  employee,
});
const setEmployeeAddDataFail = (error) => ({
  type: EMPLOYEE_ADD_DATA.EMPLOYEE_ADD_DATA_FAIL,
  error,
});

//update employee
const loadEmployeeUpdateDataRequest = (request) => ({
  type: EMPLOYEE_UPDATE_DATA.EMPLOYEE_UPDATE_DATA_LOAD,
  request,
});
const setEmployeeUpdateDataSuccess = (employee) => ({
  type: EMPLOYEE_UPDATE_DATA.EMPLOYEE_UPDATE_DATA_SUCCESS,
  employee,
});
const setEmployeeUpdateDataFail = (error) => ({
  type: EMPLOYEE_UPDATE_DATA.EMPLOYEE_UPDATE_DATA_FAIL,
  error,
});

//Delete Employee
const loadEmployeeDeleteDataRequest = (request) => ({
  type: EMPLOYEE_DELETE_DATA.EMPLOYEE_DELETE_DATA_LOAD,
  request,
});
const setEmployeeDeleteDataSuccess = (employee) => ({
  type: EMPLOYEE_DELETE_DATA.EMPLOYEE_DELETE_DATA_SUCCESS,
  employee,
});
const setEmployeeDeleteDataFail = (error) => ({
  type: EMPLOYEE_DELETE_DATA.EMPLOYEE_DELETE_DATA_FAIL,
  error,
});

//Import Employee
const loadEmployeeImportDataRequest = (request) => ({
  type: EMPLOYEE_IMPORT_DATA.EMPLOYEE_IMPORT_DATA_LOAD,
  request,
});
const setEmployeeImportDataSuccess = (employees) => ({
  type: EMPLOYEE_IMPORT_DATA.EMPLOYEE_IMPORT_DATA_SUCCESS,
  employees,
});
const setEmployeeImportDataFail = (error) => ({
  type: EMPLOYEE_IMPORT_DATA.EMPLOYEE_IMPORT_DATA_FAIL,
  error,
});

//Employee Designations

//row count
const loadEmployeeDesignationTrackerRowCount = filters => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_LOAD,
  filters
})
const setEmployeeDesignationTrackerRowCountSuccess = (count) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_SUCCESS,
  count
})
const setEmployeeDesignationTrackerRowCountFail = (error) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_FAIL,
  error
})
//get serverside paging
const loadEmployeeDesignationTrackerRequest = (filter, page, sort) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_LOAD,
  filter,
  page,
  sort
})
const setEmployeeDesignationTrackerRequestSuccess = (designationTrackers) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_SUCCESS,
  designationTrackers
})
const setEmployeeDesignationTrackerRequestFail = (error) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_FAIL,
  error
})

//Get by emp id
const loadEmployeeDesignationTrackerByEmpIdDataRequest = (id) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_LOAD,
  id,
});
const setEmployeeDesignationTrackerByEmpIdDataSuccess = (
  designationTrackers
) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_SUCCESS,
  designationTrackers,
});
const setEmployeeDesignationTrackerByEmpIdDataFail = (error) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_FAIL,
  error,
});
//add
const loadEmployeeDesignationTrackerAddDataRequest = (request) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA.EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_LOAD,
  request,
});
const setEmployeeDesignationTrackerAddDataSuccess = (designationTrackers) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA.EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_SUCCESS,
  designationTrackers,
});
const setEmployeeDesignationTrackerAddDataFail = (error) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA.EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_FAIL,
  error,
});

//edit designation tracker
const loadEmployeeDesignationTrackerEditDataRequest = (request) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA.EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_LOAD,
  request,
});
const setEmployeeDesignationTrackerEditDataSuccess = (tracker) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA.EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_SUCCESS,
  tracker,
});
const setEmployeeDesignationTrackerEditDataFail = (error) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA.EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_FAIL,
  error,
});

//delete designation tracker
const loadEmployeeDesignationTrackerDeleteDataRequest = (request) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA.EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_LOAD,
  request,
});
const setEmployeeDesignationTrackerDeleteDataSuccess = () => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA.EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_SUCCESS,
});
const setEmployeeDesignationTrackerDeleteDataFail = (error) => ({
  type: EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA.EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_FAIL,
  error,
});

//get Employee Type Trackers
const loadEmployeeTypeTrackerDataRequest = () => ({
  type: EMPLOYEE_TYPE_TRACKER_DATA.EMPLOYEE_TYPE_TRACKER_DATA_LOAD,
});
const setEmployeeTypeTrackerDataSuccess = (typeTrackers) => ({
  type: EMPLOYEE_TYPE_TRACKER_DATA.EMPLOYEE_TYPE_TRACKER_DATA_SUCCESS,
  typeTrackers,
});
const setEmployeeTypeTrackerDataFail = (error) => ({
  type: EMPLOYEE_TYPE_TRACKER_DATA.EMPLOYEE_TYPE_TRACKER_DATA_FAIL,
  error,
});
//get employee type tracker by emp id
const loadEmployeeTypeTrackerByEmpId = (id) => ({
  type: EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_LOAD,
  id,
});
const setEmployeeTypeTrackerByEmpIdSuccess = (typeTrackers) => ({
  type: EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_SUCCESS,
  typeTrackers,
});

const setEmployeeTypeTrackersByEmpIdFail = (error) => ({
  type: EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_FAIL,
  error,
});

//add Employee Type tracker
const loadEmployeeTypeTrackerAddDataRequest = (request) => ({
  type: EMPLOYEE_TYPE_TRACKER_ADD_DATA.EMPLOYEE_TYPE_TRACKER_ADD_DATA_LOAD,
  request,
});
const setEmployeeTypeTrackerAddDataSuccess = (employeeTypeTrackers) => ({
  type: EMPLOYEE_TYPE_TRACKER_ADD_DATA.EMPLOYEE_TYPE_TRACKER_ADD_DATA_SUCCESS,
  employeeTypeTrackers,
});
const setEmployeeTypeTrackerAddDataFail = (error) => ({
  type: EMPLOYEE_TYPE_TRACKER_ADD_DATA.EMPLOYEE_TYPE_TRACKER_ADD_DATA_FAIL,
  error,
});

//edit Employee Type tracker
const loadEmployeeTypeTrackerEditDataRequest = (request) => ({
  type: EMPLOYEE_TYPE_TRACKER_EDIT_DATA.EMPLOYEE_TYPE_TRACKER_EDIT_DATA_LOAD,
  request,
});
const setEmployeeTypeTrackerEditDataSuccess = (typeTracker) => ({
  type: EMPLOYEE_TYPE_TRACKER_EDIT_DATA.EMPLOYEE_TYPE_TRACKER_EDIT_DATA_SUCCESS,
  typeTracker,
});
const setEmployeeTypeTrackerEditDataFail = (error) => ({
  type: EMPLOYEE_TYPE_TRACKER_EDIT_DATA.EMPLOYEE_TYPE_TRACKER_EDIT_DATA_FAIL,
  error,
});

//delete Employee Type tracker
const loadEmployeeTypeTrackerDeleteDataRequest = (request) => ({
  type: EMPLOYEE_TYPE_TRACKER_DELETE_DATA.EMPLOYEE_TYPE_TRACKER_DELETE_DATA_LOAD,
  request,
});
const setEmployeeTypeTrackerDeleteDataSuccess = () => ({
  type: EMPLOYEE_TYPE_TRACKER_DELETE_DATA.EMPLOYEE_TYPE_TRACKER_DELETE_DATA_SUCCESS,
});
const setEmployeeTypeTrackerDeleteDataFail = (error) => ({
  type: EMPLOYEE_TYPE_TRACKER_DELETE_DATA.EMPLOYEE_TYPE_TRACKER_DELETE_DATA_FAIL,
  error,
});

const loadEmpDesignationTrackerDataRequest = () => ({
  type: EMP_DESIGNATION_TRACKER_GET_DATA.EMP_DESIGNATION_TRACKER_DATA_LOAD,
});
const setEmpDesignationTrackerDataSuccess = (empDesignation) => ({
  type: EMP_DESIGNATION_TRACKER_GET_DATA.EMP_DESIGNATION_TRACKER_DATA_SUCCESS,
  empDesignation,
});
const setEmpDesignationTrackerDataFail = (error) => ({
  type: EMP_DESIGNATION_TRACKER_GET_DATA.EMP_DESIGNATION_TRACKER_DATA_FAIL,
  error,
});

const loadEmpTypeHireStatusTrackerDataRequest = () => ({
  type: EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA.EMP_TYPE_HIRE_STATUS_TRACKER_DATA_LOAD,
});
const setEmpTypeHireStatusTrackerDataSuccess = (empHireStatus) => ({
  type: EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA.EMP_TYPE_HIRE_STATUS_TRACKER_DATA_SUCCESS,
  empHireStatus,
});
const setEmpTypeHireStatusTrackerDataFail = (error) => ({
  type: EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA.EMP_TYPE_HIRE_STATUS_TRACKER_DATA_FAIL,
  error,
});

//For Active employee listing
const loadActiveEmployeeDataRequest = () => ({
  type: ACTIVE_EMPLOYEE_DATA.ACTIVE_EMPLOYEE_DATA_LOAD,
});
const setActiveEmployeeDataSuccess = (employees) => ({
  type: ACTIVE_EMPLOYEE_DATA.ACTIVE_EMPLOYEE_DATA_SUCCESS,
  employees,
});
const setActiveEmployeeDataFail = (error) => ({
  type: ACTIVE_EMPLOYEE_DATA.ACTIVE_EMPLOYEE_DATA_FAIL,
  error,
});

export {
  loadEmployeeDataRequest,
  setEmployeeDataSuccess,
  setEmployeeDataFail,
  loadEmployeeRowCount,
  setEmployeeRowCountSuccess,
  setEmployeeRowCountFail,
  loadEmployeeGetDataRequest,
  setEmployeeGetDataSuccess,
  setEmployeeGetDataFail,
  loadEmployeeAddDataRequest,
  setEmployeeAddDataSuccess,
  setEmployeeAddDataFail,
  loadEmployeeUpdateDataRequest,
  setEmployeeUpdateDataSuccess,
  setEmployeeUpdateDataFail,
  loadEmployeeDeleteDataRequest,
  setEmployeeDeleteDataSuccess,
  setEmployeeDeleteDataFail,
  loadEmployeeImportDataRequest,
  setEmployeeImportDataSuccess,
  setEmployeeImportDataFail,
  loadEmployeeDesignationTrackerByEmpIdDataRequest,
  setEmployeeDesignationTrackerByEmpIdDataSuccess,
  setEmployeeDesignationTrackerByEmpIdDataFail,
  loadEmployeeDesignationTrackerAddDataRequest,
  setEmployeeDesignationTrackerAddDataSuccess,
  setEmployeeDesignationTrackerAddDataFail,
  loadEmployeeDesignationTrackerEditDataRequest,
  setEmployeeDesignationTrackerEditDataSuccess,
  setEmployeeDesignationTrackerEditDataFail,
  loadEmployeeDesignationTrackerDeleteDataRequest,
  setEmployeeDesignationTrackerDeleteDataSuccess,
  setEmployeeDesignationTrackerDeleteDataFail,
  loadEmployeeTypeTrackerDataRequest,
  setEmployeeTypeTrackerDataSuccess,
  setEmployeeTypeTrackerDataFail,
  loadEmployeeTypeTrackerByEmpId,
  setEmployeeTypeTrackerByEmpIdSuccess,
  setEmployeeTypeTrackersByEmpIdFail,
  loadEmployeeTypeTrackerAddDataRequest,
  setEmployeeTypeTrackerAddDataSuccess,
  setEmployeeTypeTrackerAddDataFail,
  loadEmployeeTypeTrackerEditDataRequest,
  setEmployeeTypeTrackerEditDataSuccess,
  setEmployeeTypeTrackerEditDataFail,
  loadEmployeeDesignationTrackerRowCount, setEmployeeDesignationTrackerRowCountSuccess, setEmployeeDesignationTrackerRowCountFail,
  loadEmployeeDesignationTrackerRequest, setEmployeeDesignationTrackerRequestSuccess, setEmployeeDesignationTrackerRequestFail,
  loadEmployeeTypeTrackerDeleteDataRequest,
  setEmployeeTypeTrackerDeleteDataSuccess,
  setEmployeeTypeTrackerDeleteDataFail,
  loadEmpDesignationTrackerDataRequest,
  setEmpDesignationTrackerDataSuccess,
  setEmpDesignationTrackerDataFail,
  loadEmpTypeHireStatusTrackerDataRequest,
  setEmpTypeHireStatusTrackerDataSuccess,
  setEmpTypeHireStatusTrackerDataFail,
  loadActiveEmployeeDataRequest,
  setActiveEmployeeDataSuccess,
  setActiveEmployeeDataFail
};
