import { useEffect } from "react"
import { Card, Row, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../shared/components/Breadcrumbs"
import DefaultButton from "../../../shared/components/DefaultButton"
import Permissions from "../../../shared/components/Permissions"
import { BACKTOLIST, ROLE_MODULE_MAPPING } from "../../../shared/constants/Constants"
import { loadRoleModulesGetDataRequest, loadRoleModulesUpdateDataRequest } from "../redux/Actions"
import { RoleModuleMappingForm } from "./RoleModuleMappingForm"


export const RoleModuleMappingEditPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    // let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    // let module_name = "Role Module Mapping";
    // let permission = Permissions(emp_no, module_name);
    // if (!permission.write_permission) {
    //     navigate('/role-module-mapping')
    // }

    const submit = (values) => {
        let request = {
            roles: {
                role_name: values.role_name,
                group_id: values.group_id,
                role_id: values.role_id,
            },
            module_metadata: values.modules.map((module) => {
                return module.module_metadata_id
            }),
            mapping: values.modules.map((module) => {
                return {
                    module_metadata_id: module.module_metadata_id,
                    read_permission: module.read_permission,
                    write_permission: module.write_permission,
                    create_permission: module.create_permission,
                    delete_permission: module.delete_permission,
                }
            })
        }
        dispatch(loadRoleModulesUpdateDataRequest(request))
        navigate('/role-module-mapping')
    }
    const cancel = () => {
        navigate('/role-module-mapping')
    }

    const { roleModule } = useSelector(state => state.roleModules)
    useEffect(() => {
        dispatch(loadRoleModulesGetDataRequest(params.id))
    }, [dispatch, params.id])

    return <>
        <Row>
            <Col xl={4} xs={12}>
                <Breadcrumbs
                    children={[
                        { href: '/role-module-mapping', label: ROLE_MODULE_MAPPING },
                        { label: params.id },
                    ]}
                />
            </Col>
            <Col xl={8} xs={12}>
                <div className="float-end">
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={BACKTOLIST}
                        actionBlock={() => {
                            navigate("/role-module-mapping")
                        }}
                    />
                </div>
            </Col>
        </Row>
        <Row>
            <Card>
                <Card.Body>
                    <RoleModuleMappingForm
                        submit={submit}
                        roleModule={roleModule}
                        cancel={cancel}
                    />
                </Card.Body>
            </Card>
        </Row>
    </>

}