import { TSHIRT_DATA, TSHIRT_ADD_DATA, TSHIRT_GET_DATA, TSHIRT_UPDATE_DATA } from './Constants'

const initialState = {
    loading:false,
    error:null,
    empAdds:[],
    empAdd:{}
}

const Reducer = (state=initialState, action) => {
    switch(action.type) {
        //TShirt List Conditions
        case TSHIRT_DATA.TSHIRT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                empAdds:[]
            }
        case TSHIRT_DATA.TSHIRT_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                empAdds:action.empAdds,
                error:null
            }
        case TSHIRT_DATA.TSHIRT_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                empAdds:[]
            }
        //TShirt Add Conditions
        case TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                empAdd:{},
                error:null
            }
        case TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                empAdd:action.empAdd,
                error:null
            }
        case TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                empAdd:{},
                error:action.error
            }
        case TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_RESET:
            return {
                ...state,
                loading:false,
                empAdd:{},
                error:action.null
            }
        //TShirt Update Conditions
        case TSHIRT_UPDATE_DATA.TSHIRT_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                empAdd:{},
                error:null
            }
        case TSHIRT_UPDATE_DATA.TSHIRT_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                empAdd:action.empAdd,
                error:null
            }
        case TSHIRT_UPDATE_DATA.TSHIRT_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                empAdd:{},
                error:action.error
            }
        //TShirt Get Details Conditions
        case TSHIRT_GET_DATA.TSHIRT_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                empAdd:{},
                error:null,
                isImportFile: false
            }
        case TSHIRT_GET_DATA.TSHIRT_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                empAdd:action.empAdd,
                error:null
            }
        case TSHIRT_GET_DATA.TSHIRT_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                empAdd:{},
                error:action.error
            }
        default: 
            return {
                ...state
            }
    }
}

export default Reducer;