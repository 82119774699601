//Get
const SEARCH_SAP_GET_DATA = {
  SEARCH_SAP_GET_DATA_LOAD: "SEARCH_SAP_GET_DATA_LOAD",
  SEARCH_SAP_GET_DATA_SUCCESS: "SEARCH_SAP_GET_DATA_SUCCESS",
  SEARCH_SAP_GET_DATA_FAIL: "SEARCH_SAP_GET_DATA_FAIL",
};

const SEARCH_SAP_GET_DETAIL_API = "/store/searchBySAP/";

//{SAP}

export {
    SEARCH_SAP_GET_DATA,
    SEARCH_SAP_GET_DETAIL_API
}
