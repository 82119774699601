import { useEffect, useState } from "react";
import Select from 'react-select';
export function BooleanFilter({ field, filterType, setFilter, existingFilter }) {
    let [type1, setType1] = useState(null);

    useEffect(() => {
        setType1(existingFilter?.filter ?? null)
    }, [existingFilter])

    useEffect(() => {
        let filter = {};
        if (type1 !== null) {
            filter = {
                filterType: "text",
                type: 'equals',
                filter: type1.toString(),
            }
        }
        changeParentFilter(filter)
    }, [type1])

    function changeParentFilter(filter) {
        setFilter((oldFilter) => {
            if (JSON.stringify(oldFilter[field]) === JSON.stringify(filter)) {
                return oldFilter
            }
            let newFilter = { ...oldFilter }
            newFilter[field] = { ...filter }
            return newFilter;
        })
    }
    let options = {
        null: { value: null, label: 'Any' },
        true: { value: true, label: 'True' },
        false: { value: false, label: 'False' },
    }

    return <div>
        <Select
            value={options[type1]}
            onChange={(event) => {
                setType1(event.value);
            }}
            options={Object.values(options)}
        />
    </div>
}