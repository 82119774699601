import { QUATERLY_DATA } from "./Constants";

const loadQuaterlyDataRequest = (request) => ({
    type: QUATERLY_DATA.QUATERLY_DATA_LOAD,
    request
})
const setQuaterlyDataSuccess = (quaterly) => ({
    type: QUATERLY_DATA.QUATERLY_DATA_SUCCESS,
    quaterly
})
const setQuaterlyDataFail = (error) => ({
    type: QUATERLY_DATA.QUATERLY_DATA_FAIL,
    error
})


export {
    loadQuaterlyDataRequest, setQuaterlyDataSuccess, setQuaterlyDataFail  
}