const initialState = {
    count: 0,
    alerts: []
}
const Reducer = (state=initialState,action) =>{
    switch (action.type){
        case 'Alerts/AddAlert':
            return {
                ...state,
                alerts: [...state.alerts,{
                    ...action.alert,
                    id:state.count+1
                }],
                count:state.count+1
            }
        default:
            return {...state}
    }
}

export default Reducer