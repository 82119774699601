import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { REGION, DELETE, CANCEL, ADDITEM, BACKTOLIST, EDIT, REGION_NAME, ULTI_REGION_CODE, DIVISION, START_DATE, END_DATE, REGION_TRACKER, DELETE_CONFIRM, DELETE_REGION, ADD } from "../../../shared/constants/Constants";
import { loadRegionDataRequest, loadRegionGetDataRequest, loadRegionDeleteDataRequest, loadRegionTrackerDeleteDataRequest, loadRegionUpdateDataRequest, loadRegionGetMarketsRequest } from "../redux/Actions";
import Modalpopup from "../../../shared/components/Modalpopup";
import ListView from "../../../shared/components/listview/ListView";
import TrackerForm from "./TrackerForm";
import { PrevNextButtons } from "../../../shared/components/PrevNextButtons";
import Permissions from "../../../shared/components/Permissions";
import { Link } from "react-router-dom";
import FormError from "../../../shared/components/FormError";
import FindModule from "../../../shared/components/FindModule";
import KeyTagModuleList from "../../../shared/components/tagging/KeyTagModuleList";
import Tagging from "../../../shared/components/tagging/Tagging";
const RegionDetails = () => {
    let params = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let [submittedDeleteRequest, setSubmittedDeleteRequest] = useState(false)
    let [shouldReload, setShouldReload] = useState(true)
    const [currentTab, setCurrentTab] = useState('Region Tracker');

    const [showAddTrackerModal, setShowAddTrackerModal] = useState(false);

    //Delete Functionality
    const handleDelete = () => {
        let deleteRequest = { id: [params.id] };
        dispatch(loadRegionDeleteDataRequest(deleteRequest));
        setSubmittedDeleteRequest(true)
    };

    let { loading, error, region, trackers, markets, activeMarkets, managers } = useSelector(state => state.region);


    useEffect(() => {
        if (shouldReload && !loading) {
            setShouldReload(false)
            dispatch(loadRegionGetDataRequest(params.id));
        }
    }, [params.id, loading, shouldReload])
    useEffect(() => {
        dispatch(loadRegionGetDataRequest(params.id));
        dispatch(loadRegionGetMarketsRequest(params.id));
    }, [dispatch, params.id])

    useEffect(() => {
        if (submittedDeleteRequest) {
            if (submittedDeleteRequest && !loading) {
                setShowDeleteModal(false);
                navigate('/region')
            }
        }
    }, [loading, dispatch, error, navigate, region.msg, submittedDeleteRequest])

    let [showEditTrackerModal, setShowEditTrackerModal] = useState(false)
    let [showDeleteTrackerModal, setShowDeleteTrackerModal] = useState(false);
    let [selectedTracker, setSelectedTracker] = useState(0)
    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState()

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Region";
    let permission = Permissions(emp_no, module_name);

    let modulePermission = "Region Tracker";
    let trackerPermission = Permissions(emp_no, modulePermission);
    let actionsPermission = !(trackerPermission.write_permission || trackerPermission.delete_permission);

    let rtrackerColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'Region Name',
            field: 'region_name',
            type: 'text',
            hide: true
        }, {
            display_name: 'Division Name',
            field: 'division_name',
            type: 'text'
        }, {
            display_name: 'Start Date',
            field: 'start_date',
            type: 'date',
            sort: 'desc'
        }, {
            display_name: 'End Date',
            field: 'end_date',
            type: 'date'
        },
        {
            display_name: 'Actions',
            field: 'actions',
            hide: actionsPermission
        }
    ];
    let marketColumns = [
        {
            display_name: 'id',
            field: 'id',
            type: 'number',
            hide: true,
        },
        {
            display_name: 'District',
            field: 'market_name',
            type: "hyperlink",
            redirect_link: "/district/",
            redirect_value: "id"
        },
        {
            display_name: 'District Manager',
            field: 'emp_name',
            type: "hyperlink",
            redirect_link: "/employees/",
            redirect_value: "emp_pk_id"
        },
        // {
        //     display_name: 'Active',
        //     field: "active",
        //     type: 'active'
        // }
    ]
    const managerColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true,
        },
        {
            display_name: "Manager",
            field: "store_manager",
            type: "text",
        },
        {
            display_name: "Start Date",
            field: "start_date",
            type: "date",
        },
        {
            display_name: "End Date",
            field: "end_date",
            type: "date",
        },

    ]
    //Tracker Delete Handling
    const handleTrackerDelete = (tid) => {
        let deleteRequest = { id: tid, region_id: region?.id };
        setShowDeleteTrackerModal(false);
        dispatch(loadRegionTrackerDeleteDataRequest(deleteRequest));
        setShouldReload(true)
    };

    function submitEditTracker(values) {
        dispatch(loadRegionUpdateDataRequest(values))
        setShouldCloseModal(true)
    }

    function addTrackerSubmit(values) {
        dispatch(loadRegionUpdateDataRequest(values))
        setShouldCloseModal(true)
    }

    useEffect(() => {
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddTrackerModal();
                closeEditTrackerModal();
                setShouldReload(true)
            } else {
                console.log(error)
                setErrorMessage(error?.msg?.toString());
            }

            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])

    const closeEditTrackerModal = () => {
        setShowEditTrackerModal(false);
        setErrorMessage(null);
    }
    const closeAddTrackerModal = () => {
        setShowAddTrackerModal(false);
        setErrorMessage(null);
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (trackerPermission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedTracker(row);
                    setShowEditTrackerModal(true);
                })
        }
        if (trackerPermission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedTracker(id);
                    setShowDeleteTrackerModal(true);
                })
        }
    }, [trackerPermission])

    //Find Tag Key Exists or not and Tagging Functionality
    let selectedModule = FindModule(module_name); 
    let keytagModules = KeyTagModuleList(selectedModule?.id);

    let tabs = {
        "Region Tracker": <>
            {trackerPermission.create_permission ? (
                <Button style={{ width: 115 }} className='btn btn-secondary default-btn-color float-end py-1' onClick={() => { setShowAddTrackerModal(true) }}>{ADDITEM}</Button>
            ) : (<></>)}
            <ListView
                exportTitle={"Region Tracker"}
                columnNames={rtrackerColumns}
                rowData={trackers}
                clickEdit={clickEditFunc}
                clickDelete={clickDeleteFunc}
                tableHeight = '45vh' />
        </>
        ,
        "Districts": <ListView
            columnNames={marketColumns}
            rowData={activeMarkets}
            tableHeight = '45vh'
        // rowClick={(row) => { navigate('/district/' + row.data.market_id) }}
        // setActiveFilter
        />,
        "Directors": <ListView
            columnNames={managerColumns}
            rowData={managers}
            tableHeight = '45vh'
            // rowClick={(row)=> navigate('/employees/' + row.data.employee_id)}
        />
    };
    
    if (keytagModules.length !== 0) {
        tabs['Additional Attribute'] = <Tagging resource_value={region?.id} resource_id={selectedModule?.id} />; 
    }

    return <div>
        <Row className="detailsPageHeader">
            <Col xl={4} xs={12}>
                <Breadcrumbs
                    children={[
                        { href: '/region', label: REGION },
                        { href: '/region/' + params.id, label: region?.region_name },
                    ]}
                />
            </Col>
            <Col xl={8} xs={12}>
                <div className='float-end'>
                    <PrevNextButtons currentId={params.id} basePath={'/region/'} name={"Region"} />
                    <DefaultButton
                        buttonClass={"default-btn-color me-1"}
                        buttonTitle={BACKTOLIST}
                        actionBlock={() => { navigate("/region"); }}
                    />
                    {permission.write_permission ? (<>
                        <DefaultButton buttonClass={"default-btn-color"} buttonTitle={EDIT} actionBlock={() => {
                            navigate("/region/edit/" + region?.id);
                        }} />
                    </>) : (<></>)}
                    {permission.delete_permission ? (<>
                        <DefaultButton buttonClass={"btn btn-danger ms-1"} buttonTitle={<i className="fa fa-trash"></i>} actionBlock={() => { setShowDeleteModal(true) }} />
                    </>) : (<></>)}
                </div>
            </Col>
        </Row>
        <Row>
            <Card>
                <Card.Body>
                    <dl className='row'>
                        <dt className='col-sm-3'>{REGION_NAME}</dt>
                        <dd className='col-sm-9'>{region?.region_name}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{ULTI_REGION_CODE}</dt>
                        <dd className='col-sm-9'>{region?.ulti_region_code}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{DIVISION}</dt>
                        <dd className='col-sm-9'>
                            <Link to={"/division/" + region?.division_id} >
                                {region?.division_name}
                            </Link>
                        </dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{START_DATE}</dt>
                        <dd className='col-sm-9'>{region?.start_date}</dd>
                    </dl>
                    <dl className='row'>
                        <dt className='col-sm-3'>{END_DATE}</dt>
                        <dd className='col-sm-9'>{region?.end_date}</dd>
                    </dl>
                </Card.Body>
            </Card>
        </Row>
        <Row>
            <Col>
                {/* <h3 className="title-heading pe-3">{REGION_TRACKER}</h3> */}
                <hr></hr>
            </Col>
        </Row>
        <Row>
            <Col>
                {Object.keys(tabs).map(tab =>
                    <button className={currentTab === tab ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTab(tab) }}>{tab}</button>
                )} 
            </Col>
            <Card>
                <Card.Body>
                    {tabs[currentTab]}
                </Card.Body>
            </Card>
        </Row>
        <Modalpopup
            show={showDeleteModal}
            handleClose={() => { setShowDeleteModal(false) }}
            actionTitle={DELETE_REGION}
        >
            <p>{DELETE_CONFIRM}</p>
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={handleDelete}
                />
            </div>
        </Modalpopup>

        <Modalpopup show={showAddTrackerModal}
            handleClose={closeAddTrackerModal}
            actionTitle={ADD + REGION_TRACKER}
        >
            <FormError>
                {errorMessage}
            </FormError>
            <TrackerForm
                submit={addTrackerSubmit}
                cancel={closeAddTrackerModal}
                regionName={region?.region_name}
                regionCode={region?.ulti_region_code}
                regionId={region?.id}
            />
        </Modalpopup>

        <Modalpopup
            show={showEditTrackerModal}
            handleClose={closeEditTrackerModal}
            actionTitle={EDIT + REGION_TRACKER}
        >
            <FormError>
                {errorMessage}
            </FormError>
            <TrackerForm
                tracker={selectedTracker}
                submit={submitEditTracker}
                cancel={closeEditTrackerModal}
                regionName={region?.region_name}
                regionCode={region?.ulti_region_code}
                regionId={region?.id}
            />
        </Modalpopup>
        <Modalpopup
            show={showDeleteTrackerModal}
            handleClose={() => { setShowDeleteTrackerModal(false) }}
            actionTitle={DELETE + REGION_TRACKER}
        >
            <p>{DELETE_CONFIRM}</p>
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteTrackerModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleTrackerDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>

    </div>
}


export default RegionDetails 
