import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import DefaultButton from '../../../shared/components/DefaultButton'
import ImportModal from '../../../shared/components/ImportModal';
import ListView from '../../../shared/components/listview/ListView';
import Modalpopup from '../../../shared/components/Modalpopup';
import Permissions from '../../../shared/components/Permissions';
import { COMPANY, CREATENEW, EMPLOYEE_DEPARTMENT, IMPORT, IMPORT_JOBTITLE, JOB_TITLE, ULTI_JOB_CODE } from '../../../shared/constants/Constants'
import { loadJobTitlesDataRequest, loadJobTitlesImportDataRequest } from '../redux/Actions';
import JobTitlesAdd from './JobTitlesAdd';

const JobTitles = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    let { jobtitles } = useSelector((state) => state.jobtitle);

    useEffect(() => {
        dispatch(loadJobTitlesDataRequest());
    }, [dispatch]);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: JOB_TITLE,
            field: "job_title",
            type: "text",
        },
        {
            display_name: ULTI_JOB_CODE,
            field: "ulti_job_code",
            type: "text",
        },
        {
            display_name: EMPLOYEE_DEPARTMENT,
            field: "department_name",
            type: "text",
        },
        {
            display_name: COMPANY,
            field: "company_name",
            type: "text",
        },
        {
            display_name: "Level",
            field: "level_name",
            type: "text",
        },

    ];

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Job Title";
    let permission = Permissions(emp_no, module_name);

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { href: '/jobtitles', label: JOB_TITLE },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission ? (
                        <>
                            <DefaultButton
                                buttonClass={"default-btn-color me-1"}
                                buttonTitle={IMPORT}
                                actionBlock={() => { setShowImportModal(true) }}
                            />
                            <DefaultButton
                                buttonClass="btn btn-primary default-btn-color"
                                buttonTitle={CREATENEW}
                                actionBlock={handleShow}
                            />
                        </>
                    ) : ""}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={JOB_TITLE}
                            columnNames={columns}
                            rowData={jobtitles}
                            shouldSetNextPrevIds
                            rowClick={(row) => {
                                navigate("./" + row.data["id"]);
                            }}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={show}
                handleClose={() => handleClose()}
                actionTitle={"Create " + JOB_TITLE}
            >
                <JobTitlesAdd
                    handleClose={(reload) => {
                        setShow(false);
                        if (reload) {
                            dispatch(loadJobTitlesDataRequest());
                        }
                    }}
                />
            </Modalpopup>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName={"job_titles"}
                modalTitle={IMPORT_JOBTITLE}
                importAction={loadJobTitlesImportDataRequest}
            />
        </>
    )
}

export default JobTitles
