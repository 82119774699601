import {
    ROLE_MODULE_DATA,
    ROLE_MODULE_GET_DATA,
    ROLE_MODULE_ADD_DATA,
    ROLE_MODULE_UPDATE_DATA,
    ROLE_MODULE_DELETE_DATA,
  } from "./Constants";
  const initialState = {
    loading: false,
    error: null,
    roleModules: [],
    roleModule: {},
  };
  const Reducer = (state = initialState, action) => {
    switch (action.type) {
      //load all
      case ROLE_MODULE_DATA.ROLE_MODULE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          roleModules: [],
          error: null,
        };
      case ROLE_MODULE_DATA.ROLE_MODULE_DATA_SUCCESS:
        //copies 'role_id' to 'id' for each entry. Needed for delete from table functionality
        let newData = action.roleModules.map((roleModule)=>{
          return {
            ...roleModule,
            id:roleModule.role_id
          }
        })
        return {
          ...state,
          loading: false,
          error: null,
          roleModules: newData,
        };
      case ROLE_MODULE_DATA.ROLE_MODULE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      //load single
      case ROLE_MODULE_GET_DATA.ROLE_MODULE_GET_DATA_LOAD:
        return {
          ...state,
          loading: true,
          roleModule: {},
          error: null,
        };
  
      case ROLE_MODULE_GET_DATA.ROLE_MODULE_GET_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          roleModule: action.roleModule,
        };
  
      case ROLE_MODULE_GET_DATA.ROLE_MODULE_GET_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case ROLE_MODULE_ADD_DATA.ROLE_MODULE_ADD_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case ROLE_MODULE_ADD_DATA.ROLE_MODULE_ADD_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case ROLE_MODULE_ADD_DATA.ROLE_MODULE_ADD_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case ROLE_MODULE_UPDATE_DATA.ROLE_MODULE_UPDATE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case ROLE_MODULE_UPDATE_DATA.ROLE_MODULE_UPDATE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case ROLE_MODULE_UPDATE_DATA.ROLE_MODULE_UPDATE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case ROLE_MODULE_DELETE_DATA.ROLE_MODULE_DELETE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case ROLE_MODULE_DELETE_DATA.ROLE_MODULE_DELETE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case ROLE_MODULE_DELETE_DATA.ROLE_MODULE_DELETE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return {
          ...state,
        };
    }
  };
  export default Reducer;
  