import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ListView from "../../../shared/components/listview/ListView"
import Modalpopup from "../../../shared/components/Modalpopup"
import DefaultButton from "../../../shared/components/DefaultButton"
import { DELETE, CANCEL, HIRE_STATUS, PART_FULL_TIME, HOURLY_SALARY, START_DATE, END_DATE, DELETE_CONFIRM } from "../../../shared/constants/Constants"
import EmployeeTypeForm from "./EmployeeTypeForm"
import { loadEmployeeTypeTrackerAddDataRequest, loadEmployeeTypeTrackerDeleteDataRequest, loadEmployeeTypeTrackerEditDataRequest } from "../redux/Actions"
import Permissions from "../../../shared/components/Permissions"
import FormError from "../../../shared/components/FormError"

const EmployeeTypeTracker = ({ employee_type_tracker, employee, setShouldReload }) => {

    const dispatch = useDispatch();

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Employee Type Hire Status Tracker";
    let permission = Permissions(emp_no, module_name);

    let actionsPermission = !(permission.write_permission || permission.delete_permission);

    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState(false);

    const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: HIRE_STATUS,
            field: "hire_status_name",
            type: "text",
        },
        {
            display_name: PART_FULL_TIME,
            field: "full_part_time_name",
            type: "text",
        },
        {
            display_name: HOURLY_SALARY,
            field: "hourly_salary_name",
            type: "text",
        },
        {
            display_name: START_DATE,
            field: "start_date",
            type: "date",
            sort: "desc"
        },
        {
            display_name: END_DATE,
            field: "end_date",
            type: "date",
        },
        {
            display_name: 'Actions',
            field: 'actions',
            hide: actionsPermission
        }
    ]

    let [showEditModal, setShowEditModal] = useState(false)
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [showAddModal, setShowAddModal] = useState(false);
    let [selectedTracker, setSelectedTracker] = useState({});

    const handleEdit = (values) => {
        dispatch(loadEmployeeTypeTrackerEditDataRequest(values))
        setShouldCloseModal(true);
    }
    const handleAdd = (values) => {
        dispatch(loadEmployeeTypeTrackerAddDataRequest({
            employee_id: values?.employee_id,
            hire_status: values?.hire_status,
            full_part_time_id: values?.full_part_time_id,
            hourly_salary_id: values?.hourly_salary_id,
            start_date: values?.start_date,
            end_date: values?.end_date
        }))
        setShouldCloseModal(true);
    }
    const handleDelete = () => {
        setShowDeleteModal(false);
        dispatch(loadEmployeeTypeTrackerDeleteDataRequest({
            id: [selectedTracker],
            employee_id: employee.employee_id
        }))
        setShouldReload(true)
    }

    let { loading, error } = useSelector(state => state.employee)

    useEffect(() => {
        console.log(error);
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddModal();
                closeEditModal();
                setShouldReload(true)
            } else {
                console.log(error)
                setErrorMessage(error?.msg?.toString());
            }

            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])


    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }
    const closeAddModal = () => {
        setShowAddModal(false);
        setErrorMessage(null);
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedTracker(row);
                    setShowEditModal(true);
                })
        }
        if (permission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedTracker(id);
                    setShowDeleteModal(true);
                })
        }
    }, [permission])
    return <>
        {permission.create_permission ? (
            <Button style={{ width: 115 }} className="btn btn-secondary default-btn-color float-end py-1" onClick={() => { setShowAddModal(true) }}>Add Tracker</Button>
        ) : (<></>)}
        <ListView
            exportTitle={'employee type hire status tracker'}
            columnNames={columnDefs}
            rowData={employee_type_tracker}
            clickEdit={clickEditFunc}
            clickDelete={clickDeleteFunc}
            tableHeight = '40vh'
        />
        <Modalpopup show={showDeleteModal} actionTitle="Delete Employee Type Hire Status Tracker" handleClose={() => { setShowDeleteModal(false) }}>
            {DELETE_CONFIRM}
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>
        <Modalpopup show={showAddModal} actionTitle="Add Employee Type Hire Status Tracker" handleClose={closeAddModal} size={"lg"}>
            <FormError>
                {errorMessage}
            </FormError>
            <EmployeeTypeForm submit={handleAdd} employee={employee} cancel={closeAddModal} />
        </Modalpopup>
        <Modalpopup show={showEditModal} actionTitle="Edit Employee Type Hire Status Tracker" handleClose={closeEditModal} size={"lg"}>
            <FormError>
                {errorMessage}
            </FormError>
            <EmployeeTypeForm submit={handleEdit} employee={employee} cancel={closeEditModal} tracker={selectedTracker} />
        </Modalpopup>
    </>
}
export default EmployeeTypeTracker
