import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import ListView from '../../../shared/components/listview/ListView';
import Loader from '../../../shared/components/Loader';
import { EMP_TYPE_HIRE_STATUS_TRACKER, START_DATE, END_DATE, HIRE_STATUS, PART_FULL_TIME, HOURLY_SALARY } from '../../../shared/constants/Constants'
import { loadEmpTypeHireStatusTrackerDataRequest } from '../redux/Actions'

const EmpTypeHireStatusTrackerGetAll = () => {

    let dispatch = useDispatch();
    let { loading, empHireStatus } = useSelector((state) => state.employee);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: "Employee Number",
            field: "emp_name_number",
            type: "text",
        },
        {
            display_name: HIRE_STATUS,
            field: "hire_status_name",
            type: "text",
        },
        {
            display_name: PART_FULL_TIME,
            field: "full_part_time_name",
            type: "text",
        },
        {
            display_name: HOURLY_SALARY,
            field: "hourly_salary_name",
            type: "text",
        },
        {
            display_name: START_DATE,
            field: "start_date",
            type: "date",
            sort: "desc"
        },
        {
            display_name: END_DATE,
            field: "end_date",
            type: "date",
        }
    ];

    useEffect(() => {
        dispatch(loadEmpTypeHireStatusTrackerDataRequest());
    }, [dispatch])

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Row>
                        <Col>
                            <Breadcrumbs
                                children={[
                                    { href: '/emptype-hirestatus-tracker', label: EMP_TYPE_HIRE_STATUS_TRACKER },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Card>
                            <Card.Body>
                                <ListView
                                    exportTitle="Employee Type Hire Status Tracker"
                                    columnNames={columns}
                                    rowData={empHireStatus}
                                />
                            </Card.Body>
                        </Card>
                    </Row>
                </>
            )}
        </>
    )
}

export default EmpTypeHireStatusTrackerGetAll
