//Get
const GROUPS_DATA = {
    GROUPS_DATA_LOAD: "GROUPS_DATA_LOAD",
    GROUPS_DATA_SUCCESS: "GROUPS_DATA_SUCCESS",
    GROUPS_DATA_FAIL: "GROUPS_DATA_FAIL",
};
const GROUPS_GET_API = "/admin/group";

// Get one by id
const GROUPS_GET_DATA = {
    GROUPS_GET_DATA_LOAD: "GROUPS_GET_DATA_LOAD",
    GROUPS_GET_DATA_SUCCESS: "GROUPS_GET_DATA_SUCCESS",
    GROUPS_GET_DATA_FAIL: "GROUPS_GET_DATA_FAIL",
};

const GROUPS_GET_DETAIL_API = "/admin/group/";

// Create / Add / Post
const GROUPS_ADD_DATA = {
    GROUPS_ADD_DATA_LOAD: "GROUPS_ADD_DATA_LOAD",
    GROUPS_ADD_DATA_SUCCESS: "GROUPS_ADD_DATA_SUCCESS",
    GROUPS_ADD_DATA_FAIL: "GROUPS_ADD_DATA_FAIL",
};

const GROUPS_POST_API = "/admin/group";

//Update
const GROUPS_UPDATE_DATA = {
  GROUPS_UPDATE_DATA_LOAD: "GROUPS_UPDATE_DATA_UPLOAD",
  GROUPS_UPDATE_DATA_SUCCESS: "GROUPS_UPDATE_DATA_SUCCESS",
  GROUPS_UPDATE_DATA_FAIL: "GROUPS_UPDATE_DATA_FAIL",
};

const GROUPS_PUT_API = "/admin/group";

// Delete
const GROUPS_DELETE_DATA = {
  GROUPS_DELETE_DATA_LOAD: "GROUPS_DELETE_DATA_LOAD",
  GROUPS_DELETE_DATA_SUCCESS: "GROUPS_DELETE_DATA_SUCCESS",
  GROUPS_DELETE_DATA_FAIL: "GROUPS_DELETE_DATA_FAIL",
};

const GROUPS_DELETE_API = "/admin/group";

export{
    GROUPS_DATA,
    GROUPS_GET_API,
    GROUPS_GET_DATA,
    GROUPS_GET_DETAIL_API,
    GROUPS_ADD_DATA,
    GROUPS_POST_API,
    GROUPS_UPDATE_DATA,
    GROUPS_PUT_API,
    GROUPS_DELETE_DATA,
    GROUPS_DELETE_API,
}