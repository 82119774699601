import { takeLatest, put, call } from "redux-saga/effects";
import {
  CLASSIFICATION_DATA,
  CLASSIFICATION_GET_API,
  CLASSIFICATION_ADD_DATA,
  CLASSIFICATION_POST_API,
  CLASSIFICATION_GET_DATA,
  CLASSIFICATION_GET_DETAIL_API,
  CLASSIFICATION_UPDATE_DATA,
  CLASSIFICATION_PUT_API,
  CLASSIFICATION_DELETE_DATA,
  CLASSIFICATION_DELETE_API,
} from "./Constants";
import {
  setClassificationDataSuccess,
  setClassificationDataFail,
  setClassificationAddDataSuccess,
  setClassificationAddDataFail,
  setClassificationGetDataSuccess,
  setClassificationGetDataFail,
  setClassificationUpdateDataSuccess,
  setClassificationUpdateDataFail,
  setClassificationDeleteDataSuccess,
  setClassificationDeleteDataFail
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getClassificationsDataService() {
  try {
    const classificationResponse = yield call(
      getRequest,
      CLASSIFICATION_GET_API
    );
    const status = classificationResponse.status;
    if (status === 200) {
      const data = classificationResponse.data;
      yield put(setClassificationDataSuccess(data));
    } else {
      yield put(setClassificationDataFail(classificationResponse.response.data));
    }
  } catch (error) {
    yield put(setClassificationDataFail(error));
  }
}

function* postClassificationDataService({ request }) {
  try {
    const response = yield call(
      postRequest,
      CLASSIFICATION_POST_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Classification created"))
      yield put(setClassificationAddDataSuccess(newData));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setClassificationAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not create classification"))
    yield put(setClassificationAddDataFail(error));
  }
}

function* getClassificationDataService({ id }) {
  try {
    const getClassificationDataResponse = yield call(
      getDetailRequest,
      CLASSIFICATION_GET_DETAIL_API + id
    );
    const status = getClassificationDataResponse.status;
    if (status === 200) {
      const newData = getClassificationDataResponse.data;
      yield put(setClassificationGetDataSuccess(newData));
    } else {
      yield put(setClassificationGetDataFail(getClassificationDataResponse.response.data));
    }
  } catch (error) {
    yield put(setClassificationGetDataFail(error));
  }
}

function* putClassificationDataService({ request }) {
  try {
    const response = yield call(putRequest, CLASSIFICATION_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Classification updated"))
      yield put(setClassificationUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(
        setClassificationUpdateDataFail(response.response.data)
      );
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not update classification"))
    yield put(setClassificationUpdateDataFail(error));
  }
}

function* deleteClassificationDataService({ request }) {
  try {
    const response = yield call(
      deleteRequest,
      CLASSIFICATION_DELETE_API,
      request
    );
    if (response.status === 200) {
      yield put(addSuccessAlert("Classification Deleted"))
      yield put(setClassificationDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(
        setClassificationDeleteDataFail(response.response.data)
      );
    }
  } catch (error) {
    yield put(addErrorAlert("Could not delete classification"))
    yield put(setClassificationDeleteDataFail(error));
  }
}

export function* watchClassification() {
  yield takeLatest(
    CLASSIFICATION_DATA.CLASSIFICATION_DATA_LOAD,
    getClassificationsDataService
  );
  yield takeLatest(
    CLASSIFICATION_ADD_DATA.CLASSIFICATION_ADD_DATA_LOAD,
    postClassificationDataService
  );
  yield takeLatest(
    CLASSIFICATION_GET_DATA.CLASSIFICATION_GET_DATA_LOAD,
    getClassificationDataService
  );
  yield takeLatest(
    CLASSIFICATION_UPDATE_DATA.CLASSIFICATION_UPDATE_DATA_LOAD,
    putClassificationDataService
  );
  yield takeLatest(
    CLASSIFICATION_DELETE_DATA.CLASSIFICATION_DELETE_DATA_LOAD,
    deleteClassificationDataService
  );
}
