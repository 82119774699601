//Get
const EMPLOYEE_STATUS_DATA = {
    EMPLOYEE_STATUS_DATA_LOAD: "EMPLOYEE_STATUS_DATA_LOAD",
    EMPLOYEE_STATUS_DATA_SUCCESS: "EMPLOYEE_STATUS_DATA_SUCCESS",
    EMPLOYEE_STATUS_DATA_FAIL: "EMPLOYEE_STATUS_DATA_FAIL",
};
const EMPLOYEE_STATUS_GET_API = "/employee/v1/employeeStatus";

// Get one by id
const EMPLOYEE_STATUS_GET_DATA = {
    EMPLOYEE_STATUS_GET_DATA_LOAD: "EMPLOYEE_STATUS_GET_DATA_LOAD",
    EMPLOYEE_STATUS_GET_DATA_SUCCESS: "EMPLOYEE_STATUS_GET_DATA_SUCCESS",
    EMPLOYEE_STATUS_GET_DATA_FAIL: "EMPLOYEE_STATUS_GET_DATA_FAIL",
};

const EMPLOYEE_STATUS_GET_DETAIL_API = "/employee/v1/employeeStatus/";

// Create / Add / Post
const EMPLOYEE_STATUS_ADD_DATA = {
    EMPLOYEE_STATUS_ADD_DATA_LOAD: "EMPLOYEE_STATUS_ADD_DATA_LOAD",
    EMPLOYEE_STATUS_ADD_DATA_SUCCESS: "EMPLOYEE_STATUS_ADD_DATA_SUCCESS",
    EMPLOYEE_STATUS_ADD_DATA_FAIL: "EMPLOYEE_STATUS_ADD_DATA_FAIL",
};

const EMPLOYEE_STATUS_POST_API = "/employee/v1/employeeStatus";

//Update
const EMPLOYEE_STATUS_UPDATE_DATA = {
  EMPLOYEE_STATUS_UPDATE_DATA_LOAD: "EMPLOYEE_STATUS_UPDATE_DATA_UPLOAD",
  EMPLOYEE_STATUS_UPDATE_DATA_SUCCESS: "EMPLOYEE_STATUS_UPDATE_DATA_SUCCESS",
  EMPLOYEE_STATUS_UPDATE_DATA_FAIL: "EMPLOYEE_STATUS_UPDATE_DATA_FAIL",
};

const EMPLOYEE_STATUS_PUT_API = "/employee/v1/employeeStatus";

// Delete
const EMPLOYEE_STATUS_DELETE_DATA = {
  EMPLOYEE_STATUS_DELETE_DATA_LOAD: "EMPLOYEE_STATUS_DELETE_DATA_LOAD",
  EMPLOYEE_STATUS_DELETE_DATA_SUCCESS: "EMPLOYEE_STATUS_DELETE_DATA_SUCCESS",
  EMPLOYEE_STATUS_DELETE_DATA_FAIL: "EMPLOYEE_STATUS_DELETE_DATA_FAIL",
};

const EMPLOYEE_STATUS_DELETE_API = "/employee/v1/employeeStatus";

export{
    EMPLOYEE_STATUS_DATA,
    EMPLOYEE_STATUS_GET_API,
    EMPLOYEE_STATUS_GET_DATA,
    EMPLOYEE_STATUS_GET_DETAIL_API,
    EMPLOYEE_STATUS_ADD_DATA,
    EMPLOYEE_STATUS_POST_API,
    EMPLOYEE_STATUS_UPDATE_DATA,
    EMPLOYEE_STATUS_PUT_API,
    EMPLOYEE_STATUS_DELETE_DATA,
    EMPLOYEE_STATUS_DELETE_API,
}