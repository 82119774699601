import React, { useState, useEffect } from "react";
import FormError from "../../../shared/components/FormError";
import { useDispatch, useSelector } from "react-redux";
import { loadKeyTagAddDataRequest } from "../redux/Actions";
import KeyTagForm from "./KeyTagForm";

const KeyTagAdd = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  let { loading, error } = useSelector((state) => state.keyTag);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true);
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false);
    }
  }, [loading, error, shouldCloseModal]);

  function submit(values) {
    delete values.id;
    dispatch(loadKeyTagAddDataRequest(values));
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return (
    <>
      <FormError>{errorMessage}</FormError>
      <KeyTagForm submit={submit} cancel={cancel} />
    </>
  );
};

export default KeyTagAdd;
