import { takeLatest, put, call } from "redux-saga/effects";
import {
  GROUP_JOBTITLE_DATA,
  GROUP_JOBTITLE_GET_API,
  GROUP_JOBTITLE_ADD_DATA,
  GROUP_JOBTITLE_POST_API,
  GROUP_JOBTITLE_GET_DATA,
  GROUP_JOBTITLE_GET_DETAIL_API,
  GROUP_JOBTITLE_UPDATE_DATA,
  GROUP_JOBTITLE_PUT_API,
  GROUP_JOBTITLE_DELETE_DATA,
  GROUP_JOBTITLE_DELETE_API
} from "./Constants";
import {
  setGroupJobTitlesDataSuccess,
  setGroupJobTitlesDataFail,
  // setGroupJobTitlesAddDataSuccess,
  // setGroupJobTitlesAddDataFail,
  setGroupJobTitlesGetDataSuccess,
  setGroupJobTitlesGetDataFail,
  // setGroupJobTitlesUpdateDataSuccess,
  // setGroupJobTitlesUpdateDataFail,
  // setGroupJobTitlesDeleteDataSuccess,
  // setGroupJobTitlesDeleteDataFail,
} from "./Actions";
import {
  getRequest,
  // postRequest,
  getDetailRequest,
  // putRequest,
  // deleteRequest
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";


function* getGroupJobTitlesDataService() {
  try {
    console.log("calling get api");
    const response = yield call(
      getRequest,
      GROUP_JOBTITLE_GET_API
    );
    const status = response.status;
    if (status === 200) {
      const data = response.data;
      yield put(setGroupJobTitlesDataSuccess(data));
    } else {
      yield put(setGroupJobTitlesDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setGroupJobTitlesDataFail(error));
  }
}

// function* postGroupJobTitlesDataService({ request }) {
//   try {
//     let requestData = {
//       to_insert: request
//     }
//     const response = yield call(
//       postRequest,
//       GROUP_JOBTITLE_POST_API,
//       requestData
//     );
//     const status = response.status;
//     if (status === 200) {
//       const newData = response.data;
//       yield put(addSuccessAlert("Group Job title created"))
//       yield put(setGroupJobTitlesAddDataSuccess(newData));
//     } else {
//       yield put(addErrorAlert(response.response.data.msg))
//       yield put(setGroupJobTitlesAddDataFail(response.response.data));
//     }
//   } catch (error) {
//     yield put(addErrorAlert("Could not create group job title"))
//     yield put(setGroupJobTitlesAddDataFail(error));
//   }
// }

function* getGroupJobTitleDataService({ id }) {
  try {
    const response = yield call(
      getDetailRequest,
      GROUP_JOBTITLE_GET_DETAIL_API + id
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(setGroupJobTitlesGetDataSuccess(newData));
    } else {
      yield put(setGroupJobTitlesGetDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setGroupJobTitlesGetDataFail(error));
  }
}

// function* putGroupJobTitlesDataService({ request }) {
//   let requestData = {
//     to_id: {
//       id: request.id
//     },
//     to_update: request
//   }
//   try {
//     const response = yield call(putRequest, GROUP_JOBTITLE_PUT_API, requestData);
//     if (response.status === 200) {
//             yield put(addSuccessAlert("Job title updated"))
//       yield put(setGroupJobTitlesUpdateDataSuccess(response.data));
//     } else {
//             yield put(addErrorAlert(response.response.data.msg))
//       yield put(
//         setGroupJobTitlesUpdateDataFail(response.response.data)
//       );
//     }
//   } catch (error) {
//         yield put(addErrorAlert("Could not update job title"))
//     yield put(setGroupJobTitlesUpdateDataFail(error));
//   }
// }

// function* deleteGroupJobTitlesDataService({ request }) {
//   try {
//     const response = yield call(
//       deleteRequest,
//       GROUP_JOBTITLE_DELETE_API,
//       request
//     );
//     if (response.status === 200) {
//             yield put(addSuccessAlert("Job title deleted"))
//       yield put(setGroupJobTitlesDeleteDataSuccess(response.data));
//     } else {
//             yield put(addErrorAlert(response.response.data.msg))
//       yield put(
//         setGroupJobTitlesDeleteDataFail(response.response.data)
//       );
//     }
//   } catch (error) {
//         yield put(addErrorAlert("Could not delete job title"))
//     yield put(setGroupJobTitlesDeleteDataFail(error));
//   }
// }


export function* watchGroupJobTitles() {
  
  yield takeLatest(   
    GROUP_JOBTITLE_DATA.GROUP_JOBTITLE_DATA_LOAD,
    getGroupJobTitlesDataService
  );
  // yield takeLatest(
  //   GROUP_JOBTITLE_ADD_DATA.GROUP_JOBTITLE_ADD_DATA_LOAD,
  //   postGroupJobTitlesDataService
  // );
  yield takeLatest(
    GROUP_JOBTITLE_GET_DATA.GROUP_JOBTITLE_GET_DATA_LOAD,
    getGroupJobTitleDataService
  );
  // yield takeLatest(
  //   GROUP_JOBTITLE_UPDATE_DATA.GROUP_JOBTITLE_UPDATE_DATA_LOAD,
  //   putGroupJobTitlesDataService
  // );
  // yield takeLatest(
  //   GROUP_JOBTITLE_DELETE_DATA.GROUP_JOBTITLE_DELETE_DATA_LOAD,
  //   deleteGroupJobTitlesDataService
  // );
  
}
