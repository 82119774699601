import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { BACKTOLIST, STORES } from "../../../shared/constants/Constants";
import StoreForm from "./StoreForm";
import { loadStoreGetDataRequest, loadStoreUpdateDataRequest } from "../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";

const StoresEdit = () => {
  let params = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [submitted, setSubmitted] = useState(false);
  let [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    dispatch(loadStoreGetDataRequest(params.id))
  }, [dispatch, params.id]);

  let { loading, error, store } = useSelector(state => state.store);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
		if (submitted && !loading) {
			if (!error) {
				setSubmitted(false)
        navigate('/stores/' + params.id)
			} else {
				setErrorMessage(error?.msg?.toString());
			}
			setSubmitted(true)
		}
	}, [loading, error, submitted])

  function submit(values) {
    let store_status;
    if (values.closed_date != null) { 
      store_status = "inactive";
    } else {
      store_status = "active";
      if (values.acquisition_date != null) { 
        if (new Date(values.acquisition_date) > new Date()) {
          store_status = "future";
        }    
        if (new Date(values.acquisition_date) < new Date()) {
          store_status = "active";
        }
      }
    }

    dispatch(loadStoreUpdateDataRequest({
      ...values,
      store_status: store_status,
    }))
    setSubmitted(true)
  }

  return (
    <>
      <FormError> 
			{errorMessage}
		  </FormError>
      <Row>
        <Col>
          <Breadcrumbs
              children={[
                  { href: '/stores', label: STORES },
                  { href: '/stores/' + params.id, label: (store?.store_name ?? "") + ", " + (store?.sap ?? '')},
                  { label: 'edit' },
              ]}
          />
        </Col>
        <Col>
          <div className="float-end">
            <DefaultButton
              buttonClass={"default-btn-color"}
              buttonTitle={BACKTOLIST}
              actionBlock={() => {
                navigate("/stores");
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <StoreForm store={store} submit={submit} />
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default StoresEdit;
