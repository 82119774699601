import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { CREATE, DENSITY, EDIT } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CREATENEW } from "../../../shared/constants/Constants";
import { loadDensityAddDataRequest, loadDensityDataRequest, loadDensityDeleteDataRequest, loadDensityUpdateDataRequest } from "../redux/Actions";
import Modalpopup from "../../../shared/components/Modalpopup";
import Permissions from "../../../shared/components/Permissions";
import DensityForm from "./DensityForm";
import FormError from "../../../shared/components/FormError";
import DeleteModal from "../../../shared/components/DeleteModal";

const Density = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  let dispatch = useDispatch();

  let { densities, error, loading } = useSelector((state) => state.density);

  useEffect(() => {
    dispatch(loadDensityDataRequest());
  }, [dispatch]);
  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "Store Classification";
  let permission = Permissions(emp_no, module_name);
  let actionsPermission = !(permission.write_permission || permission.delete_permission);
  let densityColumns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true
    },
    {
      display_name: "Density Class Name",
      field: "density_class_name",
      type: "text",
    },
    {
      field: "actions",
      type: "actions",
      hide: actionsPermission
    }
  ];

  //Send Requests
  function handleAdd(values) {
    delete values.id;
    dispatch(loadDensityAddDataRequest(values));
    setShouldCloseModal(true);
  }
  function handleEdit(values) {
    dispatch(loadDensityUpdateDataRequest(values))
    setShouldCloseModal(true)
  }
  const handleDelete = () => {
    let deleteRequest = { id: [selectedRow] };
    dispatch(loadDensityDeleteDataRequest(deleteRequest));
    setShouldCloseModal(true)
  };

  //close Modals
  const closeAddModal = () => {
    setShowAddModal(false);
    setErrorMessage(null);
  }
  const closeEditModal = () => {
    setShowEditModal(false);
    setErrorMessage(false);
  }
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  }


  //Attempt to close modal if no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        closeAddModal();
        closeEditModal();
        closeDeleteModal();
        setShouldReload(true);
      } else {
        setErrorMessage(error?.msg?.toString());
      }
      setShouldCloseModal(false)
    }
  }, [loading, error, shouldCloseModal])

  //Reload page after request processed
  useEffect(() => {
    if (shouldReload && !loading) {
      dispatch(loadDensityDataRequest());
      setShouldReload(false)
    }
  }, [loading, shouldReload])

  //Set action buttons
  let [clickEditFunc, setClickEditFunc] = useState(null);
  let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
  useEffect(() => {
    if (permission.write_permission) {
      setClickEditFunc(
        () => (row) => {
          setSelectedRow(row);
          setShowEditModal(true);
        }
      )
    }
    if (permission.delete_permission) {
      setClickDeleteFunc(
        () => (id) => {
          setSelectedRow(id);
          setShowDeleteModal(true);
        })
    }
  }, [permission])
  return (
    <>
      <Row>
        <Col className="gx-0">
          <Breadcrumbs
            children={[
              { href: '/density', label: DENSITY }
            ]}
          />
        </Col>
        <Col xs="auto" className="gx-0">
          {permission.create_permission ? (
            <DefaultButton
              buttonClass="default-btn-color"
              buttonTitle={CREATENEW}
              actionBlock={() => setShowAddModal(true)}
            />
          ) : (<></>)}
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <ListView
              exportTitle={DENSITY}
              columnNames={densityColumns}
              rowData={densities}
              clickEdit={clickEditFunc}
              clickDelete={clickDeleteFunc}
            />
          </Card.Body>
        </Card>
      </Row>
      <Modalpopup
        show={showAddModal}
        handleClose={closeAddModal}
        actionTitle={CREATE + DENSITY}
      >
        <FormError>
          {errorMessage}
        </FormError>
        <DensityForm
          submit={handleAdd}
          cancel={closeAddModal}
        />
      </Modalpopup>
      <Modalpopup
        show={showEditModal}
        handleClose={closeEditModal}
        actionTitle={EDIT + DENSITY}
      >
        <FormError>
          {errorMessage}
        </FormError>
        <DensityForm
          density={selectedRow}
          submit={handleEdit}
          cancel={closeEditModal}
        />
      </Modalpopup>
      <DeleteModal show={showDeleteModal} handleClose={closeDeleteModal} handleDelete={handleDelete} />
    </>
  );
};

export default Density;
