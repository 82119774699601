import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Card, Col, Row, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import Alert from "../../../shared/components/Alert";
import DefaultButton from "../../../shared/components/DefaultButton";
import { instance } from "../../../shared/utilites/ApiClient";

const RequestPasswordReset = () => {
  const logoPath = "/company-logo.png";

  const [submitted, setSubmitted] = useState(false);

  const validate = (values) => {
    let errors = {};
    if (!values.employee_id) {
      errors.employee_id = "Enter your employee id"
    }
    return errors;
  }
  const [errorMsg, setErrorMessage] = useState("");
  const submit = (values) => {
    instance.post("/login/requestPasswordReset", { user_id: values.employee_id }).then((response) => {
      setSubmitted(true);
    }).catch(e => {
      setErrorMessage(e?.response?.data?.msg);
    })
  }

  return (
    <div>
      <Row>
        <Col lg={4} md={2} />
        <Col lg={4} md={8}>
          <Card style={{ marginTop: "20vh" }}>
            <Card.Body>
              <div style={{ margin: "30px" }}>
                <span style={{ fontSize: "2rem" }}>
                  <img
                    src={logoPath}
                    alt="logo icon"
                    className="company-logo"
                    style={{ marginRight: "15px", marginLeft: "40px" }}
                  />
                  Wireless Vision
                </span>
                <hr></hr>
                {errorMsg ? (
                <Alert key="danger" alertType="alert alert-dismissible fade show alert-danger" message={errorMsg} />
                ) : ""}
                <Row>
                  <Col>
                    <span style={{ fontSize: "1.3rem" }}>
                      Reset Password
                    </span>
                  </Col>
                </Row>
                {submitted ? <div>Request sumbitted. Please check the email associated with the account.</div> :

                  <Formik
                    validate={validate}
                    initialValues={{
                      employee_id: "",
                      password: "",
                    }}
                    onSubmit={submit}
                  >
                    <Form>
                      <Row>
                        <Col>
                          <label className="form-label mt-3 mb-0">User ID</label>
                          <Field
                            className="form-control"
                            name="employee_id"
                            autoComplete="off"
                            autoFocus
                          ></Field>
                          <span className="error-text">
                            <ErrorMessage name="employee_id" />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Link to="/login" className="mt-3">Return to Login</Link>
                        </Col>
                        <Col className="col-auto">
                          <DefaultButton
                            buttonTitle={"Reset"}
                            buttonClass={"default-btn-color float-end mt-3"}
                            buttonType={"submit"}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Formik>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RequestPasswordReset;
