import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";


const CategoryProduct = () => {

  let dispatch = useDispatch();
  let navigate = useNavigate();

  return (
    <>
      <div>
        <Row>
          <Col className='gx-0'>
            <Breadcrumbs
              children={[
                { label: "Category Product" },
              ]}
            />
          </Col>
        </Row>
        <>
            <Row>
                <Card>
                    <Card.Body>
                    </Card.Body>
                </Card>
            </Row>
        </>
      </div>
    </>
  );
};

export default CategoryProduct;
