import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../../shared/components/FormError";
import { loadProductUpdateDataRequest } from "../redux/Actions";
import ProductForm from "./ProductForm";

const ProductEdit = ({ handleClose, pData, productType }) => {
  const dispatch = useDispatch();
  let [shouldCloseModal, setShouldCloseModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState(false);

  let { loading, error } = useSelector((state) => state.products);

  // After submition, the post request is done loading, and there's no errors
  
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true);
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false);
    }
  }, [loading, error, shouldCloseModal]);

  function submit(values) {
    dispatch(loadProductUpdateDataRequest(values, values.id)); 
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return (
    <>
      <FormError>
        {errorMessage}
      </FormError>
      <ProductForm
        submit={submit}
        cancel={cancel}
        pData={pData}
        productType={productType}
        formType = {"edit"}
      />
    </>
  );
};

export default ProductEdit;
