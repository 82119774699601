import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormTextField from "../../../shared/components/FormTextField";
import { CANCEL, CHANNEL_NAME, SAVE } from "../../../shared/constants/Constants";

const ChannelForm = ({ channel, submit, cancel }) => {

    const validate = (values) => {
        const errors = {};
        if (!values.channel_name) {
            errors.channel_name = 'Required';
        }
        return errors;
    };

    return <Formik
        initialValues={{
            id: channel?.id ?? '',
            channel_name: channel?.channel_name ?? '',
        }}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
    >
        {() => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="channel_name" className="form-label">
                        {CHANNEL_NAME} <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='channel_name' autoComplete="off" />
                    <span className="error-text">
                        <ErrorMessage name="channel_name" />
                    </span>
                </div>
                <Col md={12}>
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik>
}

export default ChannelForm