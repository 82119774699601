import { Form, Formik } from "formik";
import { Col } from "react-bootstrap";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import FormTextField from "../../../shared/components/FormTextField";
import { CANCEL, SAVE } from "../../../shared/constants/Constants";

export const HTQUpdate = ({ rowData, submit, handleClose }) => {
    const validate = values => {
        const errors = {}
        return errors;
    }
    return <Formik
        initialValues={{
            id: rowData?.id,
            command: rowData?.command,
            employee_id: rowData?.employee_id,
            status: rowData?.status,
        }}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
    >
        {({ values, setFieldValue }) => (
            <Form className="row g-4">
                <FormTextField name="command" label="Command" disabled/>
                <FormTextField name="employee_id" label="Employee id" disabled />
                <FormikSelectField
                    name="status"
                    placeholder={"Select Status"}
                    onChange={setFieldValue}
                    value={values.status}
                    label="Status"
                    // disabled={rowData.status === 'pending'}
                >
                    <option value={'done'}>Done</option>
                    <option value={'odoo_pending'}>Odoo Pending</option>
                </FormikSelectField>
                <Col md={12}>
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={handleClose}
                    />
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}

    </Formik>
}