//Get
const PT_DATA = {
    PT_DATA_LOAD: "PT_DATA_LOAD",
    PT_DATA_SUCCESS: "PT_DATA_SUCCESS",
    PT_DATA_FAIL: "PT_DATA_FAIL",
};
const PT_GET_API = "/product";

// Get one by id
const PT_GET_DATA = {
    PT_GET_DATA_LOAD: "PT_GET_DATA_LOAD",
    PT_GET_DATA_SUCCESS: "PT_GET_DATA_SUCCESS",
    PT_GET_DATA_FAIL: "PT_GET_DATA_FAIL",
};

const PT_GET_DETAIL_API = "/product/";

// Create / Add / Post
const PT_ADD_DATA = {
    PT_ADD_DATA_LOAD: "PT_ADD_DATA_LOAD",
    PT_ADD_DATA_SUCCESS: "PT_ADD_DATA_SUCCESS",
    PT_ADD_DATA_FAIL: "PT_ADD_DATA_FAIL",
};

const PT_POST_API = "/product";

//Update
const PT_UPDATE_DATA = {
  PT_UPDATE_DATA_LOAD: "PT_UPDATE_DATA_UPLOAD",
  PT_UPDATE_DATA_SUCCESS: "PT_UPDATE_DATA_SUCCESS",
  PT_UPDATE_DATA_FAIL: "PT_UPDATE_DATA_FAIL",
};

const PT_PUT_API = "/product";

// Delete
const PT_DELETE_DATA = {
  PT_DELETE_DATA_LOAD: "PT_DELETE_DATA_LOAD",
  PT_DELETE_DATA_SUCCESS: "PT_DELETE_DATA_SUCCESS",
  PT_DELETE_DATA_FAIL: "PT_DELETE_DATA_FAIL",
};

const PT_DELETE_API = "/product";


//Get all product type
const PTYPE_DATA = {
  PTYPE_DATA_LOAD: "PTYPE_DATA_LOAD",
  PTYPE_DATA_SUCCESS: "PTYPE_DATA_SUCCESS",
  PTYPE_DATA_FAIL: "PTYPE_DATA_FAIL",
};
const PTYPE_GET_API = "/product/pType";

//Get all account type
const ATYPE_DATA = {
  ATYPE_DATA_LOAD: "ATYPE_DATA_LOAD",
  ATYPE_DATA_SUCCESS: "ATYPE_DATA_SUCCESS",
  ATYPE_DATA_FAIL: "ATYPE_DATA_FAIL",
};
const ATYPE_GET_API = "/product/aType";

//Get all line type
const LTYPE_DATA = {
  LTYPE_DATA_LOAD: "LTYPE_DATA_LOAD",
  LTYPE_DATA_SUCCESS: "LTYPE_DATA_SUCCESS",
  LTYPE_DATA_FAIL: "LTYPE_DATA_FAIL",
};
const LTYPE_GET_API = "/product/scType";

export{
    PT_DATA,
    PT_GET_API,
    PT_GET_DATA,
    PT_GET_DETAIL_API,
    PT_ADD_DATA,
    PT_POST_API,
    PT_UPDATE_DATA,
    PT_PUT_API,
    PT_DELETE_DATA,
    PT_DELETE_API,
    PTYPE_DATA,
    PTYPE_GET_API,
    ATYPE_DATA,
    ATYPE_GET_API,
    LTYPE_DATA,
    LTYPE_GET_API
}