import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div>
            <footer className="page-footer">
                <p className="mb-0">Copyright © 2022. All right reserved.</p>
            </footer>
        </div>
    )
}

export default Footer