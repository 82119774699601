import { AUDIT_DATA, AUDIT_GET_TABLES } from "./Constants";

const initialState = {
  loading: false,
  error: null,
  audits: [],
  tables: [],
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case AUDIT_DATA.AUDIT_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        audits: [],
      };
    case AUDIT_DATA.AUDIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        audits: action.audits.map(audit => ({ ...audit, change_log: JSON.stringify(audit.change_log) })),
        error: null,
      };
    case AUDIT_DATA.AUDIT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        audits: [],
      };
    case AUDIT_GET_TABLES.AUDIT_GET_TABLES_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case AUDIT_GET_TABLES.AUDIT_GET_TABLES_SUCCESS:
      return {
        ...state,
        loading: false,
        tables: action.tables
      }
    case AUDIT_GET_TABLES.AUDIT_GET_TABLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return {
        ...state,
      };
  }
};