import { useSelector } from "react-redux"
import Alert from "../../shared/components/Alert"
export function SiteAlerts() {
	let alertStore = useSelector(store => store.alert);
	
	return [
		alertStore.alerts.map((alert) => {			
			return <Alert
				key={alert.id}
				alertType={"alert alert-dismissible fade show " + (alert.alertType === 'success' ? 'alert-success' : 'alert-danger')}
				message={alert.message}
			/>
		}
		)
	]
}

// useEffect(() => {
// 	const timer = setTimeout(() => {
// 	  //console.log('This will run after 1 second!')
// 	}, 1000);
// 	return () => clearTimeout(timer);
//   }, []);