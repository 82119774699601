import { ErrorMessage, Field } from "formik"

export default function FormTextField({ name, label, required = false, disabled = false,placeholder }) {
    return <div className="col-md-6">
        <label htmlFor={name} className="form-label">
            {label} {required ? <span className="error-text">*</span> : ''}
        </label>
        <Field
            className="form-control"
            type="text"
            name={name}
            autoComplete="off"
            disabled={disabled}
            placeholder = {placeholder}
        />
        <span className="error-text">
            <ErrorMessage name={name} />
        </span>
    </div>
}
