import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import DefaultButton from '../../../shared/components/DefaultButton'
import ListView from '../../../shared/components/listview/ListView'
import Modalpopup from '../../../shared/components/Modalpopup'
import { loadChannelTypeAddDataRequest, loadChannelTypeDeleteDataRequest, loadChannelTypeGetData, loadChannelTypeUpdateDataRequest } from '../redux/Actions'
import { ADD, CTYPE } from '../../../shared/constants/Constants'
import Permissions from '../../../shared/components/Permissions'
import DeleteModal from '../../../shared/components/DeleteModal'
import ChannelForm from './ChannelForm'
import FormError from '../../../shared/components/FormError'

const ChannelType = () => {

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [shouldCloseModal, setShouldCloseModal] = useState(false);
    const [shouldReload, setShouldReload] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = useDispatch();

    let { channelTypes, error, loading } = useSelector(state => state.channelType);
    useEffect(() => {
        dispatch(loadChannelTypeGetData());
    }, [dispatch])

    //Permissions
    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Channel Type";
    let permission = Permissions(emp_no, module_name);
    let actionsPermission = !(permission.write_permission || permission.delete_permission);


    const channelColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'Channel Name',
            field: 'channel_name',
            type: 'text'
        },
        {
            field: 'actions',
            type: 'actions',
            hide: actionsPermission
        }
    ];


    //Send Requests
    function handleAdd(values) {
        delete values.id;
        dispatch(loadChannelTypeAddDataRequest(values));
        setShouldCloseModal(true);
    }
    function handleEdit(values) {
        dispatch(loadChannelTypeUpdateDataRequest(values))
        setShouldCloseModal(true)
    }
    const handleDelete = () => {
        let deleteRequest = { id: [selectedRow] };
        dispatch(loadChannelTypeDeleteDataRequest(deleteRequest));
        setShouldCloseModal(true)
    };

    //close Modals
    const closeAddModal = () => {
        setShowAddModal(false);
        setErrorMessage(null);
    }
    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(false);
    }
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }

    //Attempt to close modal if no errors
    useEffect(() => {
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddModal();
                closeEditModal();
                closeDeleteModal();
                setShouldReload(true);
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])

    //Reload page after request processed
    useEffect(() => {
        if (shouldReload && !loading) {
            dispatch(loadChannelTypeGetData());
            setShouldReload(false)
        }
    }, [loading, shouldReload])


    //Set action buttons
    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
            setClickEditFunc(
                () => (row) => {
                    setSelectedRow(row);
                    setShowEditModal(true);
                }
            )
        }
        if (permission.delete_permission) {
            setClickDeleteFunc(
                () => (id) => {
                    setSelectedRow(id);
                    setShowDeleteModal(true);
                })
        }
    }, [permission])
    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { href: '/channel-type', label: CTYPE },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission ? (
                            <DefaultButton buttonClass={'btn btn-primary default-btn-color'} buttonTitle={"Create new"} actionBlock={() => { setShowAddModal(true) }}></DefaultButton>
                    ) : (<></>)}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={CTYPE}
                            columnNames={channelColumns}
                            rowData={channelTypes}
                            shouldSetNextPrevIds
                            clickEdit={clickEditFunc}
                            clickDelete={clickDeleteFunc}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                actionTitle={ADD + CTYPE}
                show={showAddModal}
                handleClose={closeAddModal}
            >
                <FormError>
                    {errorMessage}
                </FormError>
                <ChannelForm
                    submit={handleAdd}
                    cancel={closeAddModal}
                />
            </Modalpopup>
            <Modalpopup
                actionTitle={"Edit Channel"}
                handleClose={closeEditModal}
                show={showEditModal}
            >
                <FormError>
                    {errorMessage}
                </FormError>
                <ChannelForm
                    channel={selectedRow}
                    submit={handleEdit}
                    cancel={closeEditModal}
                />
            </Modalpopup>
            <DeleteModal show={showDeleteModal} handleClose={closeDeleteModal} handleDelete={handleDelete} />
        </>
    )
}

export default ChannelType
