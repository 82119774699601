import React, { useEffect, useState,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadRoleAccessGetDataRequest } from "../../containers/login/redux/Actions";
import { useNavigate } from "react-router-dom";

const Permissions = (emp_no, module_name) => {

  // console.log("module name::", module_name);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { roleAccess, login } = useSelector((state) => state.login);

  let [role, setRole] = useState(null);
  useEffect(() => {
    setRole(login?.role);
  }, [setRole, login])
 
  /*
  if (login?.role === "hc_sales") {
    console.log("login?.role",login?.role)
    navigate('/t-shirtSize')
  }
  */
  
  useEffect(() => {
    if (login?.role === "hc_sales") {
      //navigate('/myplan')
    }
  }, [login?.role]);

  let new_permissions = {
    read_permission: true,
    write_permission: false,
    create_permission: false,
    delete_permission: false,
  };
  const [item, setItem] = useState(new_permissions);

  useEffect(() => {
    if (role === 'admin') {
      setItem({
        read_permission: true,
        write_permission: true,
        create_permission: true,
        delete_permission: true,
      })
    } else {
      if (roleAccess.length === 0) {
        setItem({ ...new_permissions });
      } else {

        let roles = [...roleAccess].filter((permission) => {
          return permission.module_name === module_name;
        })
        let modulePermissions = {
          module_name: module_name,
          read_permission: true,
          write_permission: false,
          create_permission: false,
          delete_permission: false,
        }
        roles.forEach((role) => {
          modulePermissions.read_permission = modulePermissions.read_permission || role.read_permission;
          modulePermissions.write_permission = modulePermissions.write_permission || role.write_permission;
          modulePermissions.create_permission = modulePermissions.create_permission || role.create_permission;
          modulePermissions.delete_permission = modulePermissions.delete_permission || role.delete_permission;
        })

        setItem(
          modulePermissions
        );
      }
    }
  }, [roleAccess, module_name, role]);

  // console.log("new per::", item);

  return item;
};

export default Permissions;
