//Get
export const ODOO_STORE_TRANSFER_TRACKER_DATA = {
    ODOO_STORE_TRANSFER_TRACKER_DATA_LOAD: "ODOO_STORE_TRANSFER_TRACKER_DATA_LOAD",
    ODOO_STORE_TRANSFER_TRACKER_DATA_SUCCESS: "ODOO_STORE_TRANSFER_TRACKER_DATA_SUCCESS",
    ODOO_STORE_TRANSFER_TRACKER_DATA_FAIL: "ODOO_STORE_TRANSFER_TRACKER_DATA_FAIL",
};
export const ODOO_STORE_TRANSFER_TRACKER_API = "employee/v1/StoreMovementHistory";

// Get one by id
export const ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA = {
    ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_LOAD: "ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_LOAD",
    ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_SUCCESS: "ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_SUCCESS",
    ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_FAIL: "ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_FAIL",
};
export const ODOO_STORE_TRANSFER_TRACKER_DETAILS_API = "employee/v1/StoreMovementHistory/"