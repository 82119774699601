import {
  STORE_HOURS_DATA,
  STORE_HOURS_DELETE_DATA,
  STORE_HOURS_IMPORT_DATA,
} from "./Constants";

//For listing
const loadStoreHoursDataRequest = (request) => ({
  type: STORE_HOURS_DATA.STORE_HOURS_DATA_LOAD,
  request,
});
const setStoreHoursDataSuccess = (shours) => ({
  type: STORE_HOURS_DATA.STORE_HOURS_DATA_SUCCESS,
  shours,
});
const setStoreHoursDataFail = (error) => ({
  type: STORE_HOURS_DATA.STORE_HOURS_DATA_FAIL,
  error,
});

//Delete
const loadStoreHoursDeleteDataRequest = (request) => ({
  type: STORE_HOURS_DELETE_DATA.STORE_HOURS_DELETE_DATA_LOAD,
  request,
});

const setStoreHoursDeleteDataSuccess = (shour) => ({
  type: STORE_HOURS_DELETE_DATA.STORE_HOURS_DELETE_DATA_SUCCESS,
  shour,
});

const setStoreHoursDeleteDataFail = (error) => ({
  type: STORE_HOURS_DELETE_DATA.STORE_HOURS_DELETE_DATA_FAIL,
  error,
});

//Import 
const loadStoreHoursImportDataRequest = (request) => ({
  type: STORE_HOURS_IMPORT_DATA.STORE_HOURS_IMPORT_DATA_LOAD,
  request,
});

const setStoreHoursImportDataSuccess = (shours) => ({
  type: STORE_HOURS_IMPORT_DATA.STORE_HOURS_IMPORT_DATA_SUCCESS,
  shours,
});

const setStoreHoursImportDataFail = (error) => ({
  type: STORE_HOURS_IMPORT_DATA.STORE_HOURS_IMPORT_DATA_FAIL,
  error,
});

export {
    loadStoreHoursDataRequest,
    setStoreHoursDataSuccess,
    setStoreHoursDataFail,
    loadStoreHoursDeleteDataRequest,
    setStoreHoursDeleteDataSuccess,
    setStoreHoursDeleteDataFail,
    loadStoreHoursImportDataRequest,
    setStoreHoursImportDataSuccess,
    setStoreHoursImportDataFail
};