import {
    GROUPS_DATA,
    GROUPS_GET_DATA,
    GROUPS_ADD_DATA,
    GROUPS_UPDATE_DATA,
    GROUPS_DELETE_DATA,
} from './Constants'


//For listing
const loadGroupsDataRequest = () => ({
    type: GROUPS_DATA.GROUPS_DATA_LOAD,
});
const setGroupsDataSuccess = (groups) => ({
    type: GROUPS_DATA.GROUPS_DATA_SUCCESS,
    groups,
});
const setGroupsDataFail = (error) => ({
    type: GROUPS_DATA.GROUPS_DATA_FAIL,
    error,
});

//for details
const loadGroupsGetDataRequest = (id) => ({
    type: GROUPS_GET_DATA.GROUPS_GET_DATA_LOAD,
    id,
});
const setGroupsGetDataSuccess = (response) => ({
    type: GROUPS_GET_DATA.GROUPS_GET_DATA_SUCCESS,
    groups: response.groups[0],
});
const setGroupsGetDataFail = (error) => ({
    type: GROUPS_GET_DATA.GROUPS_GET_DATA_FAIL,
    error,
});

//For add Groups
const loadGroupsAddDataRequest = (request) => ({
    type: GROUPS_ADD_DATA.GROUPS_ADD_DATA_LOAD,
    request,
});
const setGroupsAddDataSuccess = (groups) => ({
    type: GROUPS_ADD_DATA.GROUPS_ADD_DATA_SUCCESS,
    groups,
});
const setGroupsAddDataFail = (error) => ({
    type: GROUPS_ADD_DATA.GROUPS_ADD_DATA_FAIL,
    error,
});

//update Groups
const loadGroupsUpdateDataRequest = (request) => ({
    type: GROUPS_UPDATE_DATA.GROUPS_UPDATE_DATA_LOAD,
    request,
});
const setGroupsUpdateDataSuccess = (groups) => ({
    type: GROUPS_UPDATE_DATA.GROUPS_UPDATE_DATA_SUCCESS,
    groups,
});
const setGroupsUpdateDataFail = (error) => ({
    type: GROUPS_UPDATE_DATA.GROUPS_UPDATE_DATA_FAIL,
    error,
});

//Delete Groups
const loadGroupsDeleteDataRequest = (request) => ({
    type: GROUPS_DELETE_DATA.GROUPS_DELETE_DATA_LOAD,
    request,
});
const setGroupsDeleteDataSuccess = (groups) => ({
    type: GROUPS_DELETE_DATA.GROUPS_DELETE_DATA_SUCCESS,
    groups,
});
const setGroupsDeleteDataFail = (error) => ({
    type: GROUPS_DELETE_DATA.GROUPS_DELETE_DATA_FAIL,
    error,
});


export {
    loadGroupsDataRequest, setGroupsDataSuccess, setGroupsDataFail,
    loadGroupsGetDataRequest, setGroupsGetDataSuccess, setGroupsGetDataFail,
    loadGroupsAddDataRequest, setGroupsAddDataSuccess, setGroupsAddDataFail,
    loadGroupsUpdateDataRequest, setGroupsUpdateDataSuccess, setGroupsUpdateDataFail,
    loadGroupsDeleteDataRequest, setGroupsDeleteDataSuccess, setGroupsDeleteDataFail,
}