const GROUP_JOBTITLE_DATA = {
    GROUP_JOBTITLE_DATA_LOAD: "GROUP_JOBTITLE_DATA_LOAD",
    GROUP_JOBTITLE_DATA_SUCCESS: "GROUP_JOBTITLE_DATA_SUCCESS",
    GROUP_JOBTITLE_DATA_FAIL: "GROUP_JOBTITLE_DATA_FAIL",
  };
  
  const GROUP_JOBTITLE_GET_API = "/employee/v1/GroupByJobTitle";
  
  //Get
  const GROUP_JOBTITLE_GET_DATA = {
    GROUP_JOBTITLE_GET_DATA_LOAD: "GROUP_JOBTITLE_GET_DATA_LOAD",
    GROUP_JOBTITLE_GET_DATA_SUCCESS: "GROUP_JOBTITLE_GET_DATA_SUCCESS",
    GROUP_JOBTITLE_GET_DATA_FAIL: "GROUP_JOBTITLE_GET_DATA_FAIL",
  };
  
  const GROUP_JOBTITLE_GET_DETAIL_API = "/employee/v1/GroupByJobTitle/";
  
  export {
    GROUP_JOBTITLE_DATA,
    GROUP_JOBTITLE_GET_API,
    GROUP_JOBTITLE_GET_DATA,
    GROUP_JOBTITLE_GET_DETAIL_API   
  };
  