import {
    JOBTITLE_DATA,
    JOBTITLE_ADD_DATA,
    JOBTITLE_UPDATE_DATA,
    JOBTITLE_GET_DATA,
    JOBTITLE_DELETE_DATA,
    JOBTITLE_IMPORT_DATA,
  } from "./Constants";
  
  const initialState = {
    loading: false,
    error: null,
    jobtitles: [],
    jobtitle: {},
  };
  
  const Reducer = (state = initialState, action) => {
    switch (action.type) {
      case JOBTITLE_DATA.JOBTITLE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
          jobtitles: [],
        };
      case JOBTITLE_DATA.JOBTITLE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          jobtitles: action.jobtitles,
          error: null,
        };
      case JOBTITLE_DATA.JOBTITLE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
          jobtitles: [],
        };

      //Add
      case JOBTITLE_ADD_DATA.JOBTITLE_ADD_DATA_LOAD:
        return {
          ...state,
          loading: true,
          jobtitle: {},
          error: null,
        };
      case JOBTITLE_ADD_DATA.JOBTITLE_ADD_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          jobtitle: action.jobtitle,
          error: null,
        };
      case JOBTITLE_ADD_DATA.JOBTITLE_ADD_DATA_FAIL:
        return {
          ...state,
          loading: false,
          jobtitle: {},
          error: action.error,
        };
      //Get
      case JOBTITLE_GET_DATA.JOBTITLE_GET_DATA_LOAD:
        return {
          ...state,
          loading: true,
          jobtitle: {},
          error: null,
        };
      case JOBTITLE_GET_DATA.JOBTITLE_GET_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          jobtitle: action.jobtitle,
          error: null,
        };
      case JOBTITLE_GET_DATA.JOBTITLE_GET_DATA_FAIL:
        return {
          ...state,
          loading: false,
          jobtitle: {},
          error: action.error,
        };
      //Update
      case JOBTITLE_UPDATE_DATA.JOBTITLE_UPDATE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          jobtitle: {},
          error: null,
        };
      case JOBTITLE_UPDATE_DATA.JOBTITLE_UPDATE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          jobtitle: action.jobtitle,
          error: false,
        };
      case JOBTITLE_UPDATE_DATA.JOBTITLE_UPDATE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          jobtitle: {},
          error: action.error,
        };
      //Delete
      case JOBTITLE_DELETE_DATA.JOBTITLE_DELETE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          jobtitle: {},
          error: null,
        };
      case JOBTITLE_DELETE_DATA.JOBTITLE_DELETE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          jobtitle: action.jobtitle,
          error: null,
        };
      case JOBTITLE_DELETE_DATA.JOBTITLE_DELETE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          jobtitle: {},
          error: action.error,
        };
      //Import
      case JOBTITLE_IMPORT_DATA.JOBTITLE_IMPORT_DATA_LOAD:
          return {
              ...state,
              loading:true,
              error:null,
              jobtitles:[]
          }
      case JOBTITLE_IMPORT_DATA.JOBTITLE_IMPORT_DATA_SUCCESS:
          return {
              ...state,
              loading:false,
              jobtitles:action.jobtitles,
              error:null
          }
      case JOBTITLE_IMPORT_DATA.JOBTITLE_IMPORT_DATA_FAIL:
          return {
              ...state,
              loading:false,
              error:action.error,
              jobtitles:[]
          }
      default:
        return {
          ...state,
        };
    }
  };
  
  export default Reducer;
  