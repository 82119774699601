import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import DefaultButton from '../../../shared/components/DefaultButton'
import { REGION, CREATENEW, IMPORTREGION, IMPORT, CREATEREGION, ALL_COMPANIES } from '../../../shared/constants/Constants'
import ListView from '../../../shared/components/listview/ListView'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadRegionDataRequest, loadRegionImportDataRequest } from '../redux/Actions'
import Modalpopup from '../../../shared/components/Modalpopup'
import RegionAdd from './RegionAdd'
import { loadCompanyDataRequest } from '../../company/redux/Actions'
import ImportModal from '../../../shared/components/ImportModal'
import Permissions from '../../../shared/components/Permissions'

const Region = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    let { loading, error, regions, isImportFile } = useSelector((state) => state.region);
    let { companies } = useSelector((state) => state.company);

    let [selectedCompanyId, setSelectedCompanyId] = useState(JSON.parse(localStorage.getItem("selectedId")))
    let [query] = useSearchParams();
    useEffect(() => {
        if (query.get('filter') == 'none') {
            setSelectedCompanyId(0)
        } 
    }, [])
    useEffect(() => {
        dispatch(loadCompanyDataRequest())
    }, [dispatch])


    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };


    let regionColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'Region Name',
            field: 'region_name',
            type: 'text'
        }, {
            display_name: 'Ulti Region Code',
            field: 'ulti_region_code',
            type: 'text'
        }, 
        {
            display_name: 'Directors',
            field: 'manager',
            type: 'text'
        }, {
            display_name: 'Division Name',
            field: 'division_name',
            type: 'text',
            sort: 'desc'
        }, {
            display_name: 'Start Date',
            field: 'start_date',
            type: 'date'
        }, {
            display_name: 'End Date',
            field: 'end_date',
            type: 'date'
        }, {
            display_name: 'Status',
            field: 'active',
            type: 'active'
        }
    ];

    const [showImportModal, setShowImportModal] = useState(false);

    useEffect(() => {
        dispatch(loadRegionDataRequest());
    }, [dispatch])

    let [regionData, setRegionData] = useState([])
    useEffect(() => {
        if (selectedCompanyId === 0) {
            setRegionData([...regions])
        } else {
            setRegionData([...regions].filter(
                (region) => {
                    return region.company_id === selectedCompanyId
                }
            ))
        }
    }, [selectedCompanyId, regions])

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Region";
    let permission = Permissions(emp_no, module_name);
    localStorage.setItem("selectedId", selectedCompanyId)
    

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { href: '/region', label:REGION },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission?(
                    <>
                        <DefaultButton
                            buttonClass={"default-btn-color me-1"}
                            buttonTitle={IMPORT}
                            actionBlock={() => { setShowImportModal(true) }}
                        />
                        <DefaultButton buttonClass={'default-btn-color'} buttonTitle={CREATENEW} actionBlock={handleShow}></DefaultButton>
                    </>
                    ):(<></>)}
                </Col>
            </Row>
            <Row>

                <Card className='table-card'>
                    <Card.Body>                        
                        <select value={selectedCompanyId} style={{ width: 250 }} className="float-end form-control" onChange={(e) => { setSelectedCompanyId(Number.parseInt(e.target.value)) }}>
                            <option value={0}>{ALL_COMPANIES}</option>
                            {companies.map((company) =>
                                <option key={company.id} value={company.id} >{company.company_name}</option>
                            )
                            }
                        </select>
                        <ListView
                            exportTitle="Region"
                            columnNames={regionColumns}
                            rowData={regionData}
                            shouldSetNextPrevIds
                            rowClick={(row) => {
                                navigate('./' + row.data['id'])
                            }}
                            tableHeight = '70vh'
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={show}
                handleClose={() => handleClose()}
                actionTitle={CREATEREGION}
            >
                <RegionAdd handleClose={(reload) => {
                    setShow(false);
                    if (reload) {
                        dispatch(loadRegionDataRequest());
                    }
                }} />
            </Modalpopup>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName={"region"}
                modalTitle={IMPORTREGION}
                importAction={loadRegionImportDataRequest}
            />
        </>
    )
}

export default Region
