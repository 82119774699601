import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CREATENEW, IMPORT, KEY_TAG } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadKeyTagDataRequest, loadKeyTagDeleteDataRequest, loadKeyValueImportDataRequest } from "../redux/Actions";
import KeyTagAdd from "./KeyTagAdd";
import KeyTagEdit from "./KeyTagEdit";
import DeleteModal from "../../../shared/components/DeleteModal";
import ImportModal from "../../../shared/components/ImportModal";
import Permissions from "../../../shared/components/Permissions";

const KeyTag = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [showImportModal, setShowImportModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let { keytags, loading } = useSelector((state) => state.keyTag);
    let [selectedRow, setSelectedRow] = useState({})
    const [shouldReload, setShouldReload] = useState(true)

    useEffect(() => {
        if (!loading && shouldReload){
            setShouldReload(false)
            dispatch(loadKeyTagDataRequest());
        }
    }, [dispatch, loading, shouldReload]);

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Admin Key Tag";
    let permission = Permissions(emp_no, module_name); 
    let actionsPermission = !(permission.write_permission || permission.delete_permission);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: "Key Name",
            field: "key_name",
            type: "text",
        },
        {
            display_name: "Key Value",
            field: "key_predefined_value",
            type: "text",
        },
        {
            display_name: "Key Type",
            field: "key_input_type",
            type: "text",
        },
        {
            display_name: "Resource Name",
            field: "module_name",
            type: "text",
        },
        {
            display_name: "Key Default Value",
            field: "key_default_value",
            type: "text",
        },
        {
            display_name: "",
            field: 'actions',
            type: 'actions',
            hide: actionsPermission
        }
    ];


    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
          setClickEditFunc(
            () => (row) => {
              setSelectedRow(row);
              setShowEditModal(true);
            }
          )
        }
        if (permission.delete_permission) {
          setClickDeleteFunc(
            () => (id) => {
              setSelectedRow({ id });
              setShowDeleteModal(true);
            })
        }
    }, [permission])
    // const clickEdit = (row) => {
    //     setSelectedRow(row)
    //     setShowEditModal(true)
    // }
    // const clickDelete = (id) => {
    //     setShowDeleteModal(true)
    //     setSelectedRow({ id });
    // }
    const handleDelete = () => {
        dispatch(loadKeyTagDeleteDataRequest({ id: [selectedRow.id] }))
        setShouldReload(true)
        setShowDeleteModal(false);
    }

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { href: '/admin-key-tag', label: KEY_TAG },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission ? (
                        <>
                            <DefaultButton
                                buttonClass={"default-btn-color me-1"}
                                buttonTitle={IMPORT}
                                actionBlock={() => { setShowImportModal(true) }}
                            />
                            <DefaultButton
                                buttonClass="btn btn-primary default-btn-color"
                                buttonTitle={CREATENEW}
                                actionBlock={() => setShowCreateModal(true)}
                            />
                        </>
                    ) : (<></>)}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={KEY_TAG}
                            columnNames={columns}
                            rowData={keytags}
                            clickEdit={clickEditFunc}
                            clickDelete={clickDeleteFunc}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={showCreateModal}
                handleClose={() => setShowCreateModal(false)}
                actionTitle={"Create " + KEY_TAG}
            >
                <KeyTagAdd
                    handleClose={(reload) => {
                        setShowCreateModal(false);
                        if (reload) {
                            dispatch(loadKeyTagDataRequest());
                        }
                    }}
                />
            </Modalpopup>
            <Modalpopup
                show={showEditModal}
                handleClose={() => { setShowEditModal(false) }}
                actionTitle={"Edit " + KEY_TAG}
            >
                <KeyTagEdit
                    keytags={selectedRow}
                    handleClose={(reload) => {
                        if (reload) {
                            dispatch(loadKeyTagDataRequest());
                        }
                        setShowEditModal(false)
                    }}
                />
            </Modalpopup>
            <DeleteModal
                handleClose={() => { setShowDeleteModal(false) }}
                handleDelete={handleDelete}
                show={showDeleteModal}
            >
                <div>
                    Delete {KEY_TAG}?
                </div>
            </DeleteModal>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName={"key_tag_value"}
                modalTitle={'Import KeyTag Values'}
                importAction={loadKeyValueImportDataRequest}
            />
        </>
    );
};

export default KeyTag;
