import { takeLatest, put, call } from "redux-saga/effects";
import {
  DIVISION_DATA,
  DIVISION_GET_API,
  DIVISION_ADD_DATA,
  DIVISION_POST_API,
  DIVISION_GET_DATA,
  DIVISION_GET_DETAIL_API,
  DIVISION_UPDATE_DATA,
  DIVISION_PUT_API,
  DIVISION_DELETE_DATA,
  DIVISION_DELETE_API,
  DIVISION_IMPORT_DATA,
  DIVISION_IMPORT_API
} from "./Constants";
import {
  setDivisionDataSuccess,
  setDivisionDataFail,
  setDivisionAddDataSuccess,
  setDivisionAddDataFail,
  setDivisionGetDataSuccess,
  setDivisionGetDataFail,
  setDivisionUpdateDataSuccess,
  setDivisionUpdateDataFail,
  setDivisionImportDataSuccess,
  setDivisionImportDataFail,
  setDivisionDeleteDataSuccess,
  setDivisionDeleteDataFail
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
  postImportRequest
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getDivisionsDataService() {
  try {
    const divisionsResponse = yield call(getRequest, DIVISION_GET_API);
    const status = divisionsResponse.status;
    if (status === 200) {
      const data = divisionsResponse.data;
      yield put(setDivisionDataSuccess(data));
    } else {
      yield put(setDivisionDataFail(divisionsResponse.response.data));
    }
  } catch (error) {
    yield put(setDivisionDataFail(error));
  }
}

function* postDivisionDataService({ request }) {
  try {
    const response = yield call(
      postRequest,
      DIVISION_POST_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Division created"))
      yield put(setDivisionAddDataSuccess(newData));
    } else {
      yield put(setDivisionAddDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setDivisionAddDataFail(error));
  }
}

function* getDivisionDataService({ id }) {
  try {
    const getDivisionDataResponse = yield call(
      getDetailRequest,
      DIVISION_GET_DETAIL_API + id
    );
    const status = getDivisionDataResponse.status;
    if (status === 200) {
      const newData = getDivisionDataResponse.data;
      yield put(setDivisionGetDataSuccess(newData));
    } else {
      yield put(setDivisionGetDataFail(getDivisionDataResponse.response.data));
    }
  } catch (error) {
    yield put(setDivisionGetDataFail(error));
  }
}

function* putDivisionDataService({ request }) {
  try {
    const response = yield call(putRequest, DIVISION_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Division updated"))
      yield put(setDivisionUpdateDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setDivisionUpdateDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not update division"))
    yield put(setDivisionUpdateDataFail(error));
  }
}

function* postImportDivisionDataService({ request }) {
  try {
    const response = yield call(
      postImportRequest,
      DIVISION_IMPORT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Divisions imported"))
      yield put(setDivisionImportDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setDivisionImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not import divisons"))
    yield put(setDivisionImportDataFail(error));
  }
}

function* deleteDivisionDataService({ request }) {
  try {
    const response = yield call(deleteRequest, DIVISION_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Division deleted"))
      yield put(setDivisionDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setDivisionDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not delete division"))
    yield put(setDivisionDeleteDataFail(error));
  }
}

export function* watchDivision() {
  yield takeLatest(DIVISION_DATA.DIVISION_DATA_LOAD, getDivisionsDataService);
  yield takeLatest(
    DIVISION_ADD_DATA.DIVISION_ADD_DATA_LOAD,
    postDivisionDataService
  );
  yield takeLatest(
    DIVISION_GET_DATA.DIVISION_GET_DATA_LOAD,
    getDivisionDataService
  );
  yield takeLatest(
    DIVISION_UPDATE_DATA.DIVISION_UPDATE_DATA_LOAD,
    putDivisionDataService
  );
  yield takeLatest(
    DIVISION_DELETE_DATA.DIVISION_DELETE_DATA_LOAD,
    deleteDivisionDataService
  );
  yield takeLatest(
    DIVISION_IMPORT_DATA.DIVISION_IMPORT_DATA_LOAD,
    postImportDivisionDataService
  );
  //Reload divisions after successfully importing
  yield takeLatest(
    DIVISION_IMPORT_DATA.DIVISION_IMPORT_DATA_SUCCESS,
    getDivisionsDataService
  );

}
