import { RF_DATA } from "./Constants";
const initialState = {
  loading: false,
  error: null,
  rf_Visits: [],
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    //load all
    case RF_DATA.RF_DATA_LOAD:
      return {
        ...state,
        loading: true,
        rf_Visits: [],
        error: null,
      };
    case RF_DATA.RF_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        rf_Visits: action.rf_Visits,
      };
    case RF_DATA.RF_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
