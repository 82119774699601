import { takeLatest, put, call } from "redux-saga/effects";
import {
    KEYTAG_DATA, KEYTAG_GET_API,
    KEYTAG_GET_DATA, KEYTAG_GET_DETAIL_API,
    KEYTAG_ADD_DATA, KEYTAG_POST_API,
    KEYTAG_UPDATE_DATA, KEYTAG_PUT_API,
    KEYTAG_DELETE_DATA, KEYTAG_DELETE_API,
    KEYVALUE_GET, KEYVALUE_GET_API,
    KEYTAGMODULE_GET_DATA, KEYTAGMODULE_GET_API,
    VALUE_RESOURCE_GET, VALUE_RESOURCE_GET_API,
    KEYVALUE_ADD, KEYVALUE_ADD_API,
    KEYVALUE_UPDATE, KEYVALUE_UPDATE_API,
    KEYVALUE_DELETE, KEYVALUE_DELETE_API,
    KEYVALUE_IMPORT_DATA, KEYVALUE_IMPORT_API
} from './Constants'
import {
    setKeyTagDataSuccess, setKeyTagDataFail,
    setKeyTagGetDataSuccess, setKeyTagGetDataFail,
    setKeyTagAddDataSuccess, setKeyTagAddDataFail,
    setKeyTagUpdateDataSuccess, setKeyTagUpdateDataFail,
    setKeyTagDeleteDataSuccess, setKeyTagDeleteDataFail,
    setKeyValueDataSuccess, setKeyValueDataFail,
    setKeyTagModuleGetDataSuccess, setKeyTagModuleGetDataFail,
    setValueGetDataSuccess, setValueGetDataFail,
    setKeyValueAddDataSuccess, setKeyValueAddDataFail,
    setKeyValueUpdateDataSuccess, setKeyValueUpdateDataFail,
    setKeyValueDeleteDataSuccess, setKeyValueDeleteDataFail,
    setKeyValueImportDataSuccess, setKeyValueImportDataFail
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest, postImportRequest } from "../../../shared/components/Service";

function* getAllKeyTagDataService() {
    try {
        const response = yield call(getRequest, KEYTAG_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setKeyTagDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setKeyTagDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving keytags"))
        yield put(setKeyTagDataFail(error));
    }
}

function* getKeyTagDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            KEYTAG_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setKeyTagGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setKeyTagGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving key tag info"))
        yield put(setKeyTagGetDataFail(error));
    } 
}

function* postKeyTagDataService({ request }) {
    try {
        const response = yield call(postRequest, KEYTAG_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Key Tag created"))
            yield put(setKeyTagAddDataSuccess(newData));
        } else {
            //yield put(addErrorAlert(response.response.data.msg))
            yield put(setKeyTagAddDataFail(response.response.data));
        }
    } catch (error) {
        //yield put(addErrorAlert("Error adding key tag"))
        yield put(setKeyTagAddDataFail(error));
    }
}
function* putKeyTagDataService({ request }) {
    try {
        const response = yield call(putRequest, KEYTAG_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Key Tag updated"))
            yield put(setKeyTagUpdateDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setKeyTagUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error editing key tag"))
        yield put(setKeyTagUpdateDataFail(error));
    }
}

function* deleteKeyTagDataService({ request }) {
    try {
        const response = yield call(deleteRequest, KEYTAG_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setKeyTagDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setKeyTagDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setKeyTagDeleteDataFail(error));
    }
}

//Get KeyTag based on Module
function* getKeyTagModuleDataService({ id }) {
    try {
        console.log("request id::", id);
        const response = yield call(
            getDetailRequest,
            KEYTAGMODULE_GET_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setKeyTagModuleGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setKeyTagModuleGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving key tag info"))
        yield put(setKeyTagModuleGetDataFail(error));
    } 
}

//Key Value functions
function* getAllKeyValueDataService() {
    try {
        const response = yield call(getRequest, KEYVALUE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setKeyValueDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setKeyValueDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving keyvalues"))
        yield put(setKeyValueDataFail(error));
    }
}

function* getValueDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            VALUE_RESOURCE_GET_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setValueGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setValueGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving key value info"))
        yield put(setValueGetDataFail(error));
    } 
}

function* postKeyValueDataService({ request }) {
    try {
        const response = yield call(postRequest, KEYVALUE_ADD_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Key Value created"))
            yield put(setKeyValueAddDataSuccess(newData));
        } else {
            yield put(setKeyValueAddDataFail(response.response.data));
        }
    } catch (error) {
        yield put(setKeyValueAddDataFail(error));
    }
}
function* putKeyValueDataService({ request }) {
    try {
        const response = yield call(putRequest, KEYVALUE_UPDATE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Key Value updated"))
            yield put(setKeyValueUpdateDataSuccess(response.data));
        } else {
            yield put(setKeyValueUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(setKeyValueUpdateDataFail(error));
    }
}

function* deleteKeyValueDataService({ request }) {
    try {
        const response = yield call(deleteRequest, KEYVALUE_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setKeyValueDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setKeyValueDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setKeyValueDeleteDataFail(error));
    }
}

//Import 
function* postImportKeyValueDataService({ request }) {
    try {
      const response = yield call(
        postImportRequest,
        KEYVALUE_IMPORT_API,
        request
      );
      const status = response.status;
      if (status === 200) {
        const newData = response.data;
        yield put(addSuccessAlert("KeyTag values imported"))
        yield put(setKeyValueImportDataSuccess(newData));
      } else {
        yield put(addErrorAlert(response.response.data.msg))
        yield put(setKeyValueImportDataFail(response.response.data));
      }
    } catch (error) {
      yield put(addErrorAlert('Could not import keytag values'))
      yield put(setKeyValueImportDataFail(error));
    }
  }

export function* watchKeyTag(){
    yield takeLatest(KEYTAG_DATA.KEYTAG_DATA_LOAD, getAllKeyTagDataService);
    yield takeLatest(KEYTAG_GET_DATA.KEYTAG_GET_DATA_LOAD, getKeyTagDataService);
    yield takeLatest(KEYTAG_ADD_DATA.KEYTAG_ADD_DATA_LOAD, postKeyTagDataService);
    yield takeLatest(KEYTAG_UPDATE_DATA.KEYTAG_UPDATE_DATA_LOAD, putKeyTagDataService);
    yield takeLatest(KEYTAG_DELETE_DATA.KEYTAG_DELETE_DATA_LOAD, deleteKeyTagDataService); 
    yield takeLatest(KEYTAGMODULE_GET_DATA.KEYTAGMODULE_GET_DATA_LOAD, getKeyTagModuleDataService);
    yield takeLatest(KEYVALUE_GET.KEYVALUE_GET_DATA_LOAD, getAllKeyValueDataService);
    yield takeLatest(VALUE_RESOURCE_GET.VALUE_RESOURCE_GET_DATA_LOAD, getValueDataService);  
    yield takeLatest(KEYVALUE_ADD.KEYVALUE_ADD_DATA_LOAD, postKeyValueDataService);
    yield takeLatest(KEYVALUE_UPDATE.KEYVALUE_UPDATE_DATA_LOAD, putKeyValueDataService);  
    yield takeLatest(KEYVALUE_DELETE.KEYVALUE_DELETE_DATA_LOAD, deleteKeyValueDataService); 

    yield takeLatest(
    KEYVALUE_IMPORT_DATA.KEYVALUE_IMPORT_DATA_LOAD,
    postImportKeyValueDataService
    );
    //Reload companies after successfully importing
    yield takeLatest(KEYVALUE_IMPORT_DATA.KEYVALUE_IMPORT_DATA_SUCCESS,
    getAllKeyValueDataService
    );
    
}