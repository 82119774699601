import {
    LEAVE_TYPE_DATA,
    LEAVE_TYPE_GET_DATA,
    LEAVE_TYPE_ADD_DATA,
    LEAVE_TYPE_UPDATE_DATA,
    LEAVE_TYPE_DELETE_DATA,
} from './Constants'


//For listing
const loadLeaveTypeDataRequest = () => ({
    type: LEAVE_TYPE_DATA.LEAVE_TYPE_DATA_LOAD,
});
const setLeaveTypeDataSuccess = (leaveTypes) => ({
    type: LEAVE_TYPE_DATA.LEAVE_TYPE_DATA_SUCCESS,
    leaveTypes,
});
const setLeaveTypeDataFail = (error) => ({
    type: LEAVE_TYPE_DATA.LEAVE_TYPE_DATA_FAIL,
    error,
});

//for details
const loadLeaveTypeGetDataRequest = (id) => ({
    type: LEAVE_TYPE_GET_DATA.LEAVE_TYPE_GET_DATA_LOAD,
    id,
});
const setLeaveTypeGetDataSuccess = (response) => ({
    type: LEAVE_TYPE_GET_DATA.LEAVE_TYPE_GET_DATA_SUCCESS,
    leaveType: response.leaveType[0],
    leaveType_class_tracker: response.leaveType_class_tracker
});
const setLeaveTypeGetDataFail = (error) => ({
    type: LEAVE_TYPE_GET_DATA.LEAVE_TYPE_GET_DATA_FAIL,
    error,
});

//For add LeaveType
const loadLeaveTypeAddDataRequest = (request) => ({
    type: LEAVE_TYPE_ADD_DATA.LEAVE_TYPE_ADD_DATA_LOAD,
    request,
});
const setLeaveTypeAddDataSuccess = (leaveType) => ({
    type: LEAVE_TYPE_ADD_DATA.LEAVE_TYPE_ADD_DATA_SUCCESS,
    leaveType,
});
const setLeaveTypeAddDataFail = (error) => ({
    type: LEAVE_TYPE_ADD_DATA.LEAVE_TYPE_ADD_DATA_FAIL,
    error,
});

//update leaveType
const loadLeaveTypeUpdateDataRequest = (request) => ({
    type: LEAVE_TYPE_UPDATE_DATA.LEAVE_TYPE_UPDATE_DATA_LOAD,
    request,
});
const setLeaveTypeUpdateDataSuccess = (leaveType) => ({
    type: LEAVE_TYPE_UPDATE_DATA.LEAVE_TYPE_UPDATE_DATA_SUCCESS,
    leaveType,
});
const setLeaveTypeUpdateDataFail = (error) => ({
    type: LEAVE_TYPE_UPDATE_DATA.LEAVE_TYPE_UPDATE_DATA_FAIL,
    error,
});

//Delete LeaveType
const loadLeaveTypeDeleteDataRequest = (request) => ({
    type: LEAVE_TYPE_DELETE_DATA.LEAVE_TYPE_DELETE_DATA_LOAD,
    request,
});
const setLeaveTypeDeleteDataSuccess = (leaveType) => ({
    type: LEAVE_TYPE_DELETE_DATA.LEAVE_TYPE_DELETE_DATA_SUCCESS,
    leaveType,
});
const setLeaveTypeDeleteDataFail = (error) => ({
    type: LEAVE_TYPE_DELETE_DATA.LEAVE_TYPE_DELETE_DATA_FAIL,
    error,
});

export {
    loadLeaveTypeDataRequest, setLeaveTypeDataSuccess, setLeaveTypeDataFail,
    loadLeaveTypeGetDataRequest, setLeaveTypeGetDataSuccess, setLeaveTypeGetDataFail,
    loadLeaveTypeAddDataRequest, setLeaveTypeAddDataSuccess, setLeaveTypeAddDataFail,
    loadLeaveTypeUpdateDataRequest, setLeaveTypeUpdateDataSuccess, setLeaveTypeUpdateDataFail,
    loadLeaveTypeDeleteDataRequest, setLeaveTypeDeleteDataSuccess, setLeaveTypeDeleteDataFail,
}