import { takeLatest, put, call } from "redux-saga/effects";
import {
  EMPLOYEE_DATA,
  EMPLOYEE_GET_API,
  EMPLOYEE_GET_DATA,
  EMPLOYEE_GET_DETAIL_API,
  EMPLOYEE_ADD_DATA,
  EMPLOYEE_POST_API,
  EMPLOYEE_UPDATE_DATA,
  EMPLOYEE_PUT_API,
  EMPLOYEE_DELETE_DATA,
  EMPLOYEE_DELETE_API,
  EMPLOYEE_IMPORT_DATA,
  EMPLOYEE_IMPORT_API,
  EMPLOYEE_DESIGNATION_TRACKER_ADD_API,
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_API,
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_API,
  EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA,
  EMPLOYEE_TYPE_TRACKER_ADD_API,
  EMPLOYEE_TYPE_TRACKER_ADD_DATA,
  EMPLOYEE_TYPE_TRACKER_EDIT_API,
  EMPLOYEE_TYPE_TRACKER_EDIT_DATA,
  EMPLOYEE_TYPE_TRACKER_DELETE_API,
  EMPLOYEE_TYPE_TRACKER_DELETE_DATA,
  EMPLOYEE_TYPE_TRACKER_API,
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_API,
  EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA,
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_API,
  EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA,
  EMP_DESIGNATION_TRACKER_GET_DATA,
  EMP_DESIGNATION_TRACKER_GET_API,
  EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA,
  EMP_TYPE_HIRE_STATUS_TRACKER_GET_API,
  ACTIVE_EMPLOYEE_DATA,
  ACTIVE_EMPLOYEE_GET_API,
  EMPLOYEE_COUNT_API,
  EMPLOYEE_COUNT,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_API,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_API,
  EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING
} from "./Constants";
import {
  setEmployeeDataSuccess,
  setEmployeeDataFail,
  setEmployeeGetDataSuccess,
  setEmployeeGetDataFail,
  setEmployeeAddDataSuccess,
  setEmployeeAddDataFail,
  setEmployeeUpdateDataSuccess,
  setEmployeeUpdateDataFail,
  setEmployeeDeleteDataSuccess,
  setEmployeeDeleteDataFail,
  setEmployeeImportDataSuccess,
  setEmployeeImportDataFail,
  setEmployeeDesignationTrackerAddDataSuccess,
  setEmployeeDesignationTrackerAddDataFail,
  setEmployeeDesignationTrackerEditDataSuccess,
  setEmployeeDesignationTrackerEditDataFail,
  setEmployeeDesignationTrackerDeleteDataSuccess,
  setEmployeeDesignationTrackerDeleteDataFail,
  setEmployeeTypeTrackerAddDataSuccess,
  setEmployeeTypeTrackerAddDataFail,
  setEmployeeTypeTrackerEditDataSuccess,
  setEmployeeTypeTrackerEditDataFail,
  setEmployeeTypeTrackerDeleteDataSuccess,
  setEmployeeTypeTrackerDeleteDataFail,
  setEmployeeTypeTrackerDataSuccess,
  setEmployeeTypeTrackerDataFail,
  setEmployeeTypeTrackerByEmpIdSuccess,
  setEmployeeTypeTrackersByEmpIdFail,
  setEmployeeDesignationTrackerByEmpIdDataSuccess,
  setEmployeeDesignationTrackerByEmpIdDataFail,
  setEmpDesignationTrackerDataSuccess,
  setEmpDesignationTrackerDataFail,
  setEmpTypeHireStatusTrackerDataSuccess,
  setEmpTypeHireStatusTrackerDataFail,
  setActiveEmployeeDataSuccess,
  setActiveEmployeeDataFail,
  setEmployeeRowCountFail,
  setEmployeeRowCountSuccess,
  setEmployeeDesignationTrackerRequestSuccess,
  setEmployeeDesignationTrackerRequestFail,
  setEmployeeDesignationTrackerRowCountSuccess,
  setEmployeeDesignationTrackerRowCountFail
} from "./Actions";
import {
  getRequest,
  getDetailRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postImportRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getEmployeesDataService({ filters, page, sort }) {
  try {
    const employeesResponse = yield call(postRequest, EMPLOYEE_GET_API, { filter: filters, page, sort });
    const status = employeesResponse.status;
    if (status === 200) {
      const data = employeesResponse.data;
      yield put(setEmployeeDataSuccess(data.data, data.rowCount));
    } else {
      yield put(setEmployeeDataFail(employeesResponse.response.data));
    }
  } catch (error) {
    yield put(setEmployeeDataFail(error));
  }
}
function* getEmployeesCountService({ filters }) {
  try {
    const employeesResponse = yield call(postRequest, EMPLOYEE_COUNT_API, { filter: filters });
    const status = employeesResponse.status;
    if (status === 200) {
      const data = employeesResponse.data;
      yield put(setEmployeeRowCountSuccess(data.rowCount));
    } else {
      yield put(setEmployeeRowCountFail(employeesResponse.response.data));
    }
  } catch (error) {
    yield put(setEmployeeRowCountFail(error));
  }
}

function* getEmployeeDataService({ id }) {
  try {
    const getEmployeeDataResponse = yield call(
      getDetailRequest,
      EMPLOYEE_GET_DETAIL_API + id
    );
    const status = getEmployeeDataResponse.status;
    if (status === 200) {
      const newData = getEmployeeDataResponse.data;
      yield put(setEmployeeGetDataSuccess(newData));
    } else {
      yield put(setEmployeeGetDataFail(getEmployeeDataResponse.response.data));
    }
  } catch (error) {
    yield put(setEmployeeGetDataFail(error));
  }
}

function* postEmployeeDataService({ request }) {
  try {
    let dataRequest = request;
    const response = yield call(postRequest, EMPLOYEE_POST_API, dataRequest);
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Employee created"));
      yield put(setEmployeeAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg));
      yield put(setEmployeeAddDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not create employee"));
    yield put(setEmployeeAddDataFail(error));
  }
}
function* putEmployeeDataService({ request }) {
  try {
    //let dataRequest = { "to_id": { "id": request.id }, "to_update": request }
    const response = yield call(putRequest, EMPLOYEE_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Employee updated"));
      yield put(setEmployeeUpdateDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg));
      yield put(setEmployeeUpdateDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not update employee"));
    yield put(setEmployeeUpdateDataFail(error));
  }
}

function* deleteEmployeeDataService({ request }) {
  try {
    const response = yield call(deleteRequest, EMPLOYEE_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg));
      yield put(setEmployeeDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data));
      yield put(setEmployeeDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error));
    yield put(setEmployeeDeleteDataFail(error));
  }
}
function* postImportEmployeeDataService({ request }) {
  try {
    const importEmployeeDataResponse = yield call(
      postImportRequest,
      EMPLOYEE_IMPORT_API,
      request
    );
    const status = importEmployeeDataResponse.status;
    if (status === 200) {
      const newData = importEmployeeDataResponse.data;
      yield put(setEmployeeImportDataSuccess(newData));
    } else {
      yield put(
        setEmployeeImportDataFail(importEmployeeDataResponse.response.data)
      );
    }
  } catch (error) {
    yield put(setEmployeeImportDataFail(error));
  }
}

//Employee designation Tracker

function* getEmployeeDesignationTrackerByEmpId({ id }) {
  try {
    const response = yield call(
      getDetailRequest,
      EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_API + id
    );
    if (response.status === 200) {
      yield put(setEmployeeDesignationTrackerByEmpIdDataSuccess(response.data));
    } else {
      yield put(
        setEmployeeDesignationTrackerByEmpIdDataFail(response.response.data)
      );
    }
  } catch (error) {
    yield put(setEmployeeDesignationTrackerByEmpIdDataFail(error));
  }
}
function* postEmployeeDesignationTrackerDataService({ request }) {
  try {
    let dataRequest = request;
    const response = yield call(
      postRequest,
      EMPLOYEE_DESIGNATION_TRACKER_ADD_API,
      dataRequest
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Tracker created"));
      yield put(setEmployeeDesignationTrackerAddDataSuccess(newData));
    } else {
      // yield put(addErrorAlert(response.response.data.msg));
      yield put(
        setEmployeeDesignationTrackerAddDataFail(response.response.data)
      );
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not create tracker"));
    yield put(setEmployeeDesignationTrackerAddDataFail(error));
  }
}
function* putEmployeeDesignationTrackerDataService({ request }) {
  try {
    let dataRequest = request;
    const response = yield call(
      putRequest,
      EMPLOYEE_DESIGNATION_TRACKER_EDIT_API,
      dataRequest
    );
    if (response.status === 200) {
      yield put(addSuccessAlert("Tracker updated"));
      yield put(setEmployeeDesignationTrackerEditDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg));
      yield put(
        setEmployeeDesignationTrackerEditDataFail(response.response.data)
      );
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not update employee"));
    yield put(setEmployeeDesignationTrackerEditDataFail(error));
  }
}

function* deleteEmployeeDesignationTrackerDataService({ request }) {
  try {
    const response = yield call(
      deleteRequest,
      EMPLOYEE_DESIGNATION_TRACKER_DELETE_API,
      request
    );
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg));
      yield put(setEmployeeDesignationTrackerDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg));
      yield put(
        setEmployeeDesignationTrackerDeleteDataFail(response.response.data)
      );
    }
  } catch (error) {
    yield put(addErrorAlert(error));
    yield put(setEmployeeDesignationTrackerDeleteDataFail(error));
  }
}

//Employee type Tracker
function* getEmployeeTypeTrackerDataService() {
  try {
    const response = yield call(getRequest, EMPLOYEE_TYPE_TRACKER_API);
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(setEmployeeTypeTrackerDataSuccess(newData));
    } else {
      yield put(setEmployeeTypeTrackerDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setEmployeeTypeTrackerDataFail(error));
  }
}
function* getEmployeeTypeTrackerByEmpId({ id }) {
  try {
    const response = yield call(
      getDetailRequest,
      EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_API + id
    );
    if (response.status === 200) {
      yield put(setEmployeeTypeTrackerByEmpIdSuccess(response.data));
    } else {
      yield put(setEmployeeTypeTrackersByEmpIdFail(response.response.data));
    }
  } catch (error) {
    yield put(setEmployeeTypeTrackersByEmpIdFail(error));
  }
}
function* postEmployeeTypeTrackerDataService({ request }) {
  try {
    let dataRequest = request;
    const response = yield call(
      postRequest,
      EMPLOYEE_TYPE_TRACKER_ADD_API,
      dataRequest
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Tracker created"));
      yield put(setEmployeeTypeTrackerAddDataSuccess(newData));
    } else {
      // yield put(addErrorAlert(response.response.data.msg));
      yield put(setEmployeeTypeTrackerAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not create tracker"));
    yield put(setEmployeeTypeTrackerAddDataFail(error));
  }
}
function* putEmployeeTypeTrackerDataService({ request }) {
  try {
    let dataRequest = request;
    const response = yield call(
      putRequest,
      EMPLOYEE_TYPE_TRACKER_EDIT_API,
      dataRequest
    );
    if (response.status === 200) {
      yield put(addSuccessAlert("Tracker updated"));
      yield put(setEmployeeTypeTrackerEditDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg));
      yield put(setEmployeeTypeTrackerEditDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not update employee"));
    yield put(setEmployeeTypeTrackerEditDataFail(error));
  }
}

function* deleteEmployeeTypeTrackerDataService({ request }) {
  try {
    const response = yield call(
      deleteRequest,
      EMPLOYEE_TYPE_TRACKER_DELETE_API,
      request
    );
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg));
      yield put(setEmployeeTypeTrackerDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data));
      yield put(setEmployeeTypeTrackerDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error));
    yield put(setEmployeeTypeTrackerDeleteDataFail(error));
  }
}
function* getEmployeesDesignationTrackerDataService({ filter, page, sort }) {
  try {
    const employeesResponse = yield call(postRequest, EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_API, { filter: filter, page, sort });
    const status = employeesResponse.status;
    if (status === 200) {
      const data = employeesResponse.data;
      yield put(setEmployeeDesignationTrackerRequestSuccess(data.data, data.rowCount));
    } else {
      yield put(setEmployeeDesignationTrackerRequestFail(employeesResponse.response.data));
    }
  } catch (error) {
    yield put(setEmployeeDesignationTrackerRequestFail(error));
  }
}
function* getEmployeesDesignationTrackerCountService({ filters }) {
  try {
    const employeesResponse = yield call(postRequest, EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_API, { filter: filters });
    const status = employeesResponse.status;
    if (status === 200) {
      const data = employeesResponse.data;
      yield put(setEmployeeDesignationTrackerRowCountSuccess(data.rowCount));
    } else {
      yield put(setEmployeeDesignationTrackerRowCountFail(employeesResponse.response.data));
    }
  } catch (error) {
    yield put(setEmployeeDesignationTrackerRowCountFail(error));
  }
}

function* getEmpDesignationDataService() {
  try {
    const empResponse = yield call(getRequest, EMP_DESIGNATION_TRACKER_GET_API);
    const status = empResponse.status;
    if (status === 200) {
      const data = empResponse.data;
      yield put(setEmpDesignationTrackerDataSuccess(data));
    } else {
      yield put(setEmpDesignationTrackerDataFail(empResponse.response.data));
    }
  } catch (error) {
    yield put(setEmpDesignationTrackerDataFail(error));
  }
}

function* getEmpTypeHireStatusDataService() {
  try {
    const empResponse = yield call(getRequest, EMP_TYPE_HIRE_STATUS_TRACKER_GET_API);
    const status = empResponse.status;
    if (status === 200) {
      const data = empResponse.data;
      yield put(setEmpTypeHireStatusTrackerDataSuccess(data));
    } else {
      yield put(setEmpTypeHireStatusTrackerDataFail(empResponse.response.data));
    }
  } catch (error) {
    yield put(setEmpTypeHireStatusTrackerDataFail(error));
  }
}

function* getActiveEmployeesDataService() {
  try {
    const response = yield call(getRequest, ACTIVE_EMPLOYEE_GET_API);
    const status = response.status;
    if (status === 200) {
      const data = response.data;
      yield put(setActiveEmployeeDataSuccess(data));
    } else {
      yield put(setActiveEmployeeDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setActiveEmployeeDataFail(error));
  }
}

export function* watchEmployee() {
  yield takeLatest(EMPLOYEE_DATA.EMPLOYEE_DATA_LOAD, getEmployeesDataService);
  yield takeLatest(EMPLOYEE_COUNT.EMPLOYEE_COUNT_LOAD, getEmployeesCountService);
  yield takeLatest(
    EMPLOYEE_GET_DATA.EMPLOYEE_GET_DATA_LOAD,
    getEmployeeDataService
  );
  yield takeLatest(
    EMPLOYEE_ADD_DATA.EMPLOYEE_ADD_DATA_LOAD,
    postEmployeeDataService
  );
  yield takeLatest(
    EMPLOYEE_UPDATE_DATA.EMPLOYEE_UPDATE_DATA_LOAD,
    putEmployeeDataService
  );
  yield takeLatest(
    EMPLOYEE_DELETE_DATA.EMPLOYEE_DELETE_DATA_LOAD,
    deleteEmployeeDataService
  );
  yield takeLatest(
    EMPLOYEE_IMPORT_DATA.EMPLOYEE_IMPORT_DATA_LOAD,
    postImportEmployeeDataService
  );
  yield takeLatest(
    EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_DESIGNATION_TRACKER_BY_EMP_ID_DATA_LOAD,
    getEmployeeDesignationTrackerByEmpId
  );
  yield takeLatest(
    EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA.EMPLOYEE_DESIGNATION_TRACKER_ADD_DATA_LOAD,
    postEmployeeDesignationTrackerDataService
  );
  yield takeLatest(
    EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA.EMPLOYEE_DESIGNATION_TRACKER_EDIT_DATA_LOAD,
    putEmployeeDesignationTrackerDataService
  );
  yield takeLatest(
    EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA.EMPLOYEE_DESIGNATION_TRACKER_DELETE_DATA_LOAD,
    deleteEmployeeDesignationTrackerDataService
  );
  yield takeLatest(
    EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA.EMPLOYEE_TYPE_TRACKER_BY_EMP_ID_DATA_LOAD,
    getEmployeeTypeTrackerByEmpId
  );
  yield takeLatest(
    EMPLOYEE_TYPE_TRACKER_ADD_DATA.EMPLOYEE_TYPE_TRACKER_ADD_DATA_LOAD,
    postEmployeeTypeTrackerDataService
  );
  yield takeLatest(
    EMPLOYEE_TYPE_TRACKER_EDIT_DATA.EMPLOYEE_TYPE_TRACKER_EDIT_DATA_LOAD,
    putEmployeeTypeTrackerDataService
  );
  yield takeLatest(
    EMPLOYEE_TYPE_TRACKER_DELETE_DATA.EMPLOYEE_TYPE_TRACKER_DELETE_DATA_LOAD,
    deleteEmployeeTypeTrackerDataService
  );
  yield takeLatest(EMP_DESIGNATION_TRACKER_GET_DATA.EMP_DESIGNATION_TRACKER_DATA_LOAD, getEmpDesignationDataService);
  yield takeLatest(EMP_TYPE_HIRE_STATUS_TRACKER_GET_DATA.EMP_TYPE_HIRE_STATUS_TRACKER_DATA_LOAD, getEmpTypeHireStatusDataService);
  yield takeLatest(ACTIVE_EMPLOYEE_DATA.ACTIVE_EMPLOYEE_DATA_LOAD, getActiveEmployeesDataService);
  yield takeLatest(EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ROWS_SERVERSIDE_PAGING_LOAD, getEmployeesDesignationTrackerCountService)
  yield takeLatest(EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING.EMPLOYEE_DESIGNATION_TRACKER_GET_ALL_SERVERSIDE_PAGING_LOAD, getEmployeesDesignationTrackerDataService)
}
