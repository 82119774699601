import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ServerSideListView from "../../../shared/components/listview/ServerSideListView";
import Loader from "../../../shared/components/Loader";
import { addTableFilter } from '../../table_filters_redux/Actions';

import {
  DESIGNATION_TRACKER, DIVISION,
  EMPLOYEE_LEAVE_TYPE, END_DATE, JOB_TITLE, MARKET,
  REGION, START_DATE, STORE
} from "../../../shared/constants/Constants";
import { loadEmpDesignationTrackerDataRequest, loadEmployeeDesignationTrackerRequest, loadEmployeeDesignationTrackerRowCount } from "../redux/Actions";
import TablePaging from "../../../shared/components/TablePaging";
import { loadJobTitlesDataRequest } from "../../jobtitles/redux/Actions";
import dropdownFilterFunction from "../../../shared/components/listview/dropdownFilterFunction";

const DesignationTrackerGetAll = () => {
  let dispatch = useDispatch();
  let { loading, empDesignation, rowCount } = useSelector((state) => state.employee);

  let { jobtitles } = useSelector(state => state.jobtitle);
  let jobtitleOptions = useMemo(() => jobtitles.map((jobtitle) => ({ value: jobtitle.job_title, label: jobtitle.job_title })), [jobtitles])
  let jobtitleFunc = dropdownFilterFunction(jobtitleOptions)

  let columns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true,
      pinned: 'left',
    },
    {
      display_name: "Employee Number",
      field: "emp_name_number",
      type: "text",
      pinned:'left',
    },
    {
      display_name: JOB_TITLE,
      field: "job_title_name",
      type: "dropdown",
      pinned:'left',
      dropdownOptions: jobtitles.map(jobtitle => jobtitle.job_title),
      dropdownFunction: jobtitleFunc
    },
    {
      display_name: "Covering",
      field: "covering",
      type: "icon",
      icon: "checkbox",
      width: 100,
      pinned:'left',
    },
    {
      display_name: STORE,
      field: "store_sap_name",
      type: "text",
      pinned:'left',
    },
    {
      display_name: MARKET,
      field: "market_name",
      type: "text",
    },
    {
      display_name: REGION,
      field: "region_name",
      type: "text",
    },
    {
      display_name: DIVISION,
      field: "division_name",
      type: "text",
    },
    {
      display_name: EMPLOYEE_LEAVE_TYPE,
      field: "leave_type_name",
      type: "text",
    },
    {
      display_name: START_DATE,
      field: "start_date",
      type: "date",
      sort: "desc",
    },
    {
      display_name: END_DATE,
      field: "end_date",
      type: "date",
    },
  ];
  let location = useLocation();
  let exportTitle = "Designation Tracker"

  let [query] = useSearchParams();
  let queryFilter = query.get('filter');

  let initialFilter = queryFilter === 'none' ? {} : JSON.parse(queryFilter);

  let [filterAndPage, setFilterAndPage] = useState(
    {
      filter: JSON.stringify(initialFilter),
      page: 1,
      sort: 'null'
    }
  )

  useEffect(() => {
    setFilterAndPage(
      {
        filter: JSON.stringify(initialFilter),
        page: 1,
        sort: 'null'
      }
    )
  }, [])
  const filterCallback = useCallback((newFilters) => {
    if (JSON.stringify(newFilters.filter) != filterAndPage.filter || JSON.stringify(newFilters.sort) != filterAndPage.sort) {
      let newFilterAndPage = { filter: JSON.stringify(newFilters.filter), page: 1, sort: JSON.stringify(newFilters.sort) }
      setFilterAndPage(newFilterAndPage);
      dispatch(loadEmployeeDesignationTrackerRowCount(JSON.parse(newFilterAndPage.filter)));
    }
  }, [filterAndPage]);
  useEffect(() => {
    // dispatch(addTableFilter(tableFilterKey, filterAndPage))
    dispatch(loadEmployeeDesignationTrackerRequest(JSON.parse(filterAndPage.filter), filterAndPage.page, JSON.parse(filterAndPage.sort)));
  }, [filterAndPage])
  useEffect(() => {
    
    dispatch(loadJobTitlesDataRequest());
    dispatch(loadEmployeeDesignationTrackerRowCount({}));
  }, [dispatch])
  return (
    <>
      <Row>
        <Col>
          <Breadcrumbs
            children={[
              { href: '/designation-tracker', label: DESIGNATION_TRACKER },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <ServerSideListView
              exportTitle="Designation Tracker"
              columnNames={columns}
              rowData={empDesignation}
              filterCallback={filterCallback}
              filterString={filterAndPage.filter}
              sort={filterAndPage.sort}
              exportUrl="/employee/v1/designation/export"
            />
          </Card.Body>
          <Row>
            <Col ></Col>
            <Col sm="auto">
            </Col>
            <Col sm={"auto"}>
              <TablePaging page={filterAndPage.page} setPage={(num) => { setFilterAndPage({ ...filterAndPage, page: num }) }} total={rowCount} />
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default DesignationTrackerGetAll;
