import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { loadCompanyGetDataRequest, loadCompanyUpdateDataRequest } from "../redux/Actions";
import { Card, Col, Row } from "react-bootstrap";
import { COMPANY, BACKTOLIST } from '../../../shared/constants/Constants';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router';
import CompanyForm from "./CompanyForm";
import DefaultButton from "../../../shared/components/DefaultButton";

const CompanyEdit = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let [submitted, setSubmitted] = useState(false);
    let params = useParams();

    useEffect(() => {
        dispatch(loadCompanyGetDataRequest(params.id))
    }, [dispatch, params.id]);
    let { company, loading, error } = useSelector(state => state.company)

	let [errorMessage, setErrorMessage] = useState(false);
    // After submition, the post request is done loading, and there's no errors
    useEffect(() => {
        console.log(error);
        if (submitted && !loading) {
            if (!error) {
                navigate('/company/' + params.id)
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setSubmitted(false)
        }
    }, [loading, error, submitted])

    function submit(values) {
        dispatch(loadCompanyUpdateDataRequest(values))
        setSubmitted(true)
    }
    function cancel() {
        navigate('/company/' + params.id)
    }
    return (
        <div>

            <Row>
                <Col>
                    <Breadcrumbs
                        children={[
                            { href: '/company', label: COMPANY },
                            { href: '/company/' + params.id, label: params.id },
                            { label: 'edit' },
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <div className="float-end">
                        <DefaultButton
                            buttonClass={"default-btn-color mb-1"}
                            buttonTitle={BACKTOLIST}
                            actionBlock={() => { navigate("/company"); }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <CompanyForm company={company[0]} submit={submit} cancel={cancel} />
                    </Card.Body>
                </Card>
            </Row>
        </div>
    );
};

export default CompanyEdit;
