import { ErrorMessage, Field } from "formik"

const FormikCheckboxField = ({ name, label, hidden, setFieldValue, onChange, value }) => {
    return <div className="col-md-6 my-auto" hidden={hidden}>
        <div style={{ paddingTop: '', fontSize: '1rem' }}>
            <label>
                <Field
                    className="form-check-input"
                    type='checkbox'
                    name={name}
                    // value={true}
                    onChange={() => {
                        setFieldValue(name, !value)
                        if (onChange){
                            onChange(!value);
                        }
                    }}
                />
                {" "}
                {label}
            </label>
        </div>
        <span className="error-text">
            <ErrorMessage name={name} />
        </span>
    </div>
}
export default FormikCheckboxField