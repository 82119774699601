import { takeLatest, put, call } from "redux-saga/effects";
import { instance } from "../../../shared/utilites/WvgoApiClient";
import { setQueueTableDataFail, setQueueTableDataSuccess, setQTUpdateDataSuccess, setQTUpdateDataFail } from "./Actions";
import { QUEUE_TABLE_API, QUEUE_TABLE_DATA, QUEUE_UPDATE_TABLE_DATA } from "./Constants";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getQueueTableDataService() {
    try {
        const response = yield call(
            async () => {
                try {
                    const response = await instance.get(QUEUE_TABLE_API)
                    return response;
                } catch (error) {
                    return error;
                }
            }
        );
        if (response.status === 200) {
            yield put(setQueueTableDataSuccess(response.data.body));
        } else {
            yield put(setQueueTableDataFail(response.response.data));
        }
    } catch (error) {
        yield put(setQueueTableDataFail(error));
    }
}

function* putQueueTableDataService({ request }) {
    try {
        console.log("request::", request);
        const response = yield call(
            async () => {
                try {
                    const response = await instance.post(QUEUE_TABLE_API, request)
                    return response;
                } catch (error) {
                    return error;
                }
            }
        );
        if (response.status === 200) {
            yield put(setQTUpdateDataSuccess(response.data.body));
        } else {
            yield put(addErrorAlert(response.response.data))
            yield put(setQTUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(setQTUpdateDataFail(error));
    }
}


export function* watchQueueTable() {
    yield takeLatest(QUEUE_TABLE_DATA.QUEUE_TABLE_DATA_LOAD, getQueueTableDataService);
    yield takeLatest(QUEUE_UPDATE_TABLE_DATA.QUEUE_UPDATE_TABLE_DATA_LOAD, putQueueTableDataService);
}