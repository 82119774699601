import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadDivisionAddDataRequest } from "../redux/Actions";
import DivisionForm from "./DivisionForm";

const DivisionAdd = ({ handleClose }) => {
    const dispatch = useDispatch();
    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let { loading, error } = useSelector(state => state.division)
    let [errorMessage, setErrorMessage] = useState(false);

    // After submition, the post request is done loading, and there's no errors
    useEffect(() => {
        console.log(error);
        if (shouldCloseModal && !loading) {
            if (!error) {
                handleClose(true)
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])

    function submit(values) {
        dispatch(loadDivisionAddDataRequest(values))
        setShouldCloseModal(true)
    }
    function cancel() {
        handleClose(false);
    }
    return <>
        <FormError>
            {errorMessage}
        </FormError>
        <DivisionForm submit={submit} cancel={cancel} />
    </>
};

export default DivisionAdd;
