import { Col, Pagination, Row } from "react-bootstrap"
import './TablePaging.css'

export default function TablePaging({ page, setPage, total }) {

    let totalPages = Math.ceil(total / 80);

    return <Row>
        <Col>
            <div className="result-count">
                {1 + ((page - 1) * 80)} - {Math.min(page * 80, total)} of {total}
            </div>
        </Col>
        <Col>
            <Pagination>
                {page > 1 ?
                    <Pagination.Item className="paging first" onClick={() => setPage(1)} />
                    :
                    <Pagination.Item className="paging first" disabled />
                }
                {page > 1 ?
                    <Pagination.Item className="paging previous" onClick={() => setPage(page - 1)} />
                    :
                    <Pagination.Item className="paging previous" disabled />
                }
                {/* {page > 2 ? <Pagination.Item onClick={() => setPage(page - 2)}>{page - 2}</Pagination.Item> : <></>}
                            {page > 1 ? <Pagination.Item onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item> : <></>} */}
                <Pagination.Item className="page-of" disabled>
                    Page {page} of {totalPages}
                </Pagination.Item>
                {/* <Pagination.Item onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>
                            <Pagination.Item onClick={() => setPage(page + 2)}>{page + 2}</Pagination.Item> */}
                {page < totalPages ?
                    <Pagination.Item className="paging next" onClick={() => setPage(page + 1)} >  </Pagination.Item>
                    :
                    <Pagination.Item className="paging next" disabled />
                }
                {page < totalPages ?
                    <Pagination.Item className="paging last" onClick={() => { setPage(totalPages) }} />
                    :
                    <Pagination.Item className="paging last" disabled />
                }
            </Pagination>
        </Col>
    </ Row>
}