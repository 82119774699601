import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DefaultButton from "../../DefaultButton";
import Modalpopup from "../../Modalpopup";
import IndividualFilter from "./IndividualFilter";
import './SideBarFilters.css';
import { LoadForm, SaveForm } from "./SidebarForms";

export default function SidebarFilters({ colDefs, grid, filtersFromTable, hiddenColumns, setHiddenColumns, barHeight = '65vh' }) {
    // console.log(colDefs)

    let [isOpen, setIsOpen] = useState(true);
    let [isRowOpen, setIsRowOpen] = useState([]);
    let [filterState, setFilterState] = useState(filtersFromTable)
    let [showSaveModal, setShowSaveModal] = useState(false);
    let [showLoadModal, setShowLoadModal] = useState(false);
    let [searchValue, setSearchValue] = useState("")

    let location = useLocation();

    const className = isOpen ? "isOpen" : "";

    function toggleRow(index) {
        setIsRowOpen((value) => {
            let i = value.indexOf(index);
            if (i >= 0) {
                let arr = value.slice()
                arr.splice(i, 1)
                return arr;
            } else {
                return [...value, index]
            }
        })
    }

    function save(name) {
        let filters = localStorage.getItem('filters')
        try {
            filters = JSON.parse(filters ?? '{}')
        } catch (error) {
            filters = {};
        }
        filters[location.pathname] = filters[location.pathname] ?? {};
        filters[location.pathname][name] = {
            filterState,
            hiddenColumns
        }
        localStorage.setItem('filters', JSON.stringify(filters))
        console.log(JSON.stringify(filters))
        setShowSaveModal(false);
    }
    function load({ filterState, hiddenColumns }) {
        console.log(filterState)
        setFilterState(filterState ?? {})
        setHiddenColumns(hiddenColumns ?? [])
        setShowLoadModal(false)
    }
    useEffect(() => {
        let columns = grid?.columnApi?.getColumnState()
        if (columns) {
            for (let column of columns) {
                let isColumnHidden = hiddenColumns.includes(column.colId)
                if (column.colId !== 'actions') {
                    grid?.columnApi?.setColumnVisible(column.colId, !isColumnHidden)
                }
            }
        }
    }, [hiddenColumns, grid, grid?.columnApi])

    useEffect(() => {
        console.log(filterState)
        grid?.api?.setFilterModel(filterState);
        console.log(grid?.api?.getFilterModel())
    }, [filterState])

    useEffect(() => {
        setFilterState((oldFilters) => {
            return filtersFromTable
        })
    }, [filtersFromTable])
    return <div id="sidebarContainer" style={{
        height: `${barHeight}`
    }}  className = {className} >
        <div id='sidebarLabel' className={className} onClick={() => setIsOpen((prevState) => !prevState)}>
            Filters
        </div>
        <div id='sidebarFilters'>
            <div id='headerTitle'>
                <Row className="gx-1">
                    <Col>
                        Fitlers
                    </Col>
                    {/* <Col className="col-auto">
                        <DefaultButton buttonClass={"default-btn-color"} actionBlock={() => setShowSaveModal(true)} buttonTitle="Save" />
                    </Col>
                    <Col className="col-auto">
                        <DefaultButton buttonClass={"default-btn-color"} actionBlock={() => setShowLoadModal(true)} buttonTitle="Load" />
                    </Col> */}
                </Row>
            </div>
            <div className="search-container">
                <input className="form-input form-control search-input" placeholder="Search" value={searchValue} onChange={(event) => setSearchValue(event.target.value)}></input>
            </div>
            <div className={'individualFilterContainer'}>

                {colDefs.map((col, index) => {
                    if (col.field === 'actions' || col.field === 'id') {
                        return null
                    }
                    if (searchValue !== '' && !col.display_name.toLowerCase().replace(' ', '').includes(searchValue.toLowerCase().replace(' ', ''))) {
                        return null
                    }
                    let height = 0
                    if (isRowOpen.includes(index)) {
                        height = 340;
                        let thisFilter = filterState?.[col.field];
                        if (thisFilter) {
                            if (thisFilter.filter) {
                                height += thisFilter.filter?.split('|').length * 40
                            } else if (thisFilter.condition1) {
                                height += thisFilter.condition1.filter?.split('|').length * 40
                                height += thisFilter.condition2.filter?.split('|').length * 40
                            }
                        }
                    }
                    let heightStyleObject = height > 0 ? { maxHeight: height } : {};

                    return <div key={col.field}>
                        <div className={'rowTitle' + (isRowOpen.includes(index) ? " isOpen" : "") + (isFiltered(filterState?.[col?.field]) ? ' filtered' : '')}>
                            <div className="checkboxContainer">
                                <input type='checkbox' defaultChecked={true} checked={!hiddenColumns.includes(col.field)} onChange={({ target }) => {
                                    if (target.checked) {
                                        setHiddenColumns((columns) => {
                                            return columns.filter((column) => column != col.field)
                                        })
                                    } else {
                                        setHiddenColumns((columns) => {
                                            return [...columns, col.field]
                                        })
                                    }
                                    grid.columnApi.setColumnVisible(col.field, target.checked)
                                }}></input>
                            </div>
                            <div onClick={() => { toggleRow(index) }}>
                                <div className={"rowTitleText"}>
                                    {col.display_name}
                                </div>
                            </div>
                        </div>
                        <div className="rowFilters" style={heightStyleObject}>
                            <div style={{ position: 'relative' }} >
                                <IndividualFilter
                                    dropdownOptions={col.dropdownOptions}
                                    field={col.field}
                                    filterType={col.type}
                                    setFilter={setFilterState}
                                    existingFilter={filterState?.[col.field]}
                                />
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <Modalpopup
            size='sm'
            actionTitle={'Save filters'}
            show={showSaveModal}
            handleClose={() => setShowSaveModal(false)}
        >
            <SaveForm submit={save} />
        </Modalpopup>
        <Modalpopup
            size='sm'
            actionTitle={'Load filters'}
            show={showLoadModal}
            handleClose={() => setShowLoadModal(false)}
        >
            <LoadForm
                pathname={location.pathname}
                submit={load}
            />
        </Modalpopup>
    </div >
}
function isFiltered(field) {
    if (field === null) {
        return false;
    }
    return (['blank', 'notBlank'].includes(field?.type) ||
        field?.filter ||
        field?.condition1 ||
        field?.dateTo ||
        !['inRange'].includes(field?.type) && field?.dateFrom
    )
}