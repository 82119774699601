import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import listData from './formlist.json';
import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { Col } from "react-bootstrap";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import { loadFormDataRequest, loadFormGetDataRequest, loadFormGetDataBySubIDRequest} from "../../../containers/dynamic_form/redux/Actions"
import { CANCEL, SAVE, STARTAFTEREND, STARTNOTFUTURE, COMPANY_NAME, ULTI_COMPANY_CODE, MASTER_DEALER_CODE, START_DATE, END_DATE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";


const DynamicFormEdit = ({ form_id, data, headers, submission_id, resource_id, submit, cancel }) => {
  const validate = (values) => {
    const errors = {};
    return errors;
  };
  const dispatch = useDispatch(); 
  const formik = useFormik({}); 

  let { formsubid, form, error, loading } = useSelector((state) => state.forms);  
  console.log("DynamicFormEdit", form_id, submission_id, resource_id )

  let req = form_id + '/' + resource_id + '/' + submission_id;

  useEffect(() => {
    console.log("loading loadFormGetDataBySubIDRequest")
      dispatch(loadFormGetDataBySubIDRequest(req));      
  }, [dispatch, req]);

  const initialValues = {};
  Object.entries(headers).forEach(([key, value]) => {
    console.log("reinitialing")
    // Assuming value is the initial value for the form field
    initialValues[value.field] = data[value.field];
  });
  initialValues['form_id'] = form_id;
  initialValues['submission_id'] = submission_id;
  initialValues['resource_id'] = resource_id;

  return (    
    <Formik
      innerRef={formik}
      initialValues={initialValues}
      validate={validate}
      onSubmit={submit}
    >
      {({ values, setFieldTouched, setFieldValue }) => (
        <Form className="row g-4">
            <Field
                className="form-control"
                type="hidden"
                name= "form_id"                                      
                autoComplete="off"   
                value = {form_id}           
            />
            <Field
                className="form-control"
                type="hidden"
                name= "submission_id"                                      
                autoComplete="off"  
                value = {submission_id}            
            />
            <Field
                className="form-control"
                type="hidden"
                name= "resource_id"                                      
                autoComplete="off"  
                value = {resource_id}            
            />
          {Object.entries(headers).length > 0 && (
            Object.entries(headers).map(([key, value]) => {
            if (value.field != "actions") {
                
                return createFormElement(value,values[value.field],setFieldValue, setFieldTouched )      
              }
              })
          )}
          <Col md={12}>
            <DefaultButton
              buttonClass={"btn btn-secondary me-2"}
              buttonTitle={CANCEL}
              actionBlock={cancel}
            />
            <DefaultButton
              buttonClass={"default-btn-color"}
              buttonTitle={SAVE}
              buttonType={"submit"}
            />
          </Col>
        </Form>
      )}
    </Formik>
  );
};
export default DynamicFormEdit;


function createFormElement(header,value, setFieldValue, setFieldTouched) {
  console.log("createFormElement called",header.type );
  switch (header.type) {
    case 'text':
        return <div className="col-md-6">
            <label htmlFor={header.field} className="form-label">
              { header.display_name } <span className="error-text">*</span>
            </label>   
            <Field
                className="form-control"
                type="text"
                name={header.field}
                placeholder={ "Enter "+ header.display_name } 
                autoComplete="off"
            />
            <span className="error-text">
                <ErrorMessage name={header.field} />
            </span>
        </div>
      case 'timestamp': 
        return <div className="col-md-6">
          <label htmlFor={header.field} className="form-label">
            { header.display_name } <span className="error-text">*</span>
          </label>   
          <FormikDatePickerField
                        name={header.field}
                        value={value}
                        onChange={setFieldValue}
                        handleBlur={setFieldTouched}
                        />     
          <span className="error-text">
              <ErrorMessage name={header.field} />
          </span>
        </div>
    default :
      return <div className="col-md-6">
        <label htmlFor={header.field} className="form-label">
          { header.display_name } <span className="error-text">*</span>
        </label>   
        <Field
                  className="form-control"
                  type="text"
                  name={header.field}
                  placeholder={ "Enter "+ header.display_name } 
                  autoComplete="off"
              />
        <span className="error-text">
            <ErrorMessage name={header.field} />
        </span>
      </div>
  }
}
