import {
    ODOO_DESIGNATION_TRACKER_BY_EMP_DATA,
    ODOO_DESIGNATION_TRACKER_DATA,
    ODOO_DESIGNATION_TRACKER_DETAILS_DATA,
} from './Constants'

//For listing
export const loadOdooDesignationTrackerDataRequest = () => ({
    type: ODOO_DESIGNATION_TRACKER_DATA.ODOO_DESIGNATION_TRACKER_DATA_LOAD,
});
export const setOdooDesignationTrackerDataSuccess = (odooDesignationTrackers) => ({
    type: ODOO_DESIGNATION_TRACKER_DATA.ODOO_DESIGNATION_TRACKER_DATA_SUCCESS,
    odooDesignationTrackers,
})
export const setOdooDesignationTrackerDataFail = (error) => ({
    type: ODOO_DESIGNATION_TRACKER_DATA.ODOO_DESIGNATION_TRACKER_DATA_FAIL,
    error,
});

//for details
export const loadOdooDesignationTrackerGetDataRequest = (id) => ({
    type: ODOO_DESIGNATION_TRACKER_DETAILS_DATA.ODOO_DESIGNATION_TRACKER_DETAILS_DATA_LOAD,
    id,
});
export const setOdooDesignationTrackerGetDataSuccess = (response) => ({
    type: ODOO_DESIGNATION_TRACKER_DETAILS_DATA.ODOO_DESIGNATION_TRACKER_DETAILS_DATA_SUCCESS,
    odooDesignationTracker: response,
});
export const setOdooDesignationTrackerGetDataFail = (error) => ({
    type: ODOO_DESIGNATION_TRACKER_DETAILS_DATA.ODOO_DESIGNATION_TRACKER_DETAILS_DATA_FAIL,
    error,
});

//list by emp number

export const loadOdooDesignationTrackerByEmpId = (emp_no) => ({
    type: ODOO_DESIGNATION_TRACKER_BY_EMP_DATA.ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_LOAD,
    emp_no,
});
export const setOdooDesignationTrackerByEmpIdSuccess = (response) => ({
    type: ODOO_DESIGNATION_TRACKER_BY_EMP_DATA.ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_SUCCESS,
    odooDesignationTrackers: response,
});
export const setOdooDesignationTrackerByEmpIdFail = (error) => ({
    type: ODOO_DESIGNATION_TRACKER_BY_EMP_DATA.ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_FAIL,
    error,
});