import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CANCEL, COMM_GROUP_NAME, SAVE } from "../../../shared/constants/Constants";

const CompGroupForm = ({ compgroup, submit, cancel }) => {
  const validate = (values) => {
    const errors = {};
    if (!values.commission_comp_group_name) {
      errors.commission_comp_group_name = "Required";
    }
    return errors;
  };

  const formik = useFormik({});

  useEffect(() => {
    if (compgroup) {
      formik.current.setFieldValue("id", compgroup?.id);
      formik.current.setFieldValue(
        "commission_comp_group_name",
        compgroup?.commission_comp_group_name
      );
    }
  }, [compgroup, formik]);

  return (
    <Formik
      innerRef={formik}
      initialValues={{
        commission_comp_group_name: ""
      }}
      validate={validate}
      onSubmit={submit}
    >
      {() => (
        <Form className="row g-4">
          <div className="col-md-8">
            <label htmlFor="commission_comp_group_name" className="form-label">
              {COMM_GROUP_NAME}<span className="error-text">*</span>
            </label>
            <Field
              className="form-control"
              type="text"
              name="commission_comp_group_name"
              placeholder="Enter Commission Comp Group Name"
              autoComplete="off"
            />
            <span className="error-text">
              <ErrorMessage name="commission_comp_group_name" />
            </span>
          </div>
          <div className="col-md-12">
            <DefaultButton
              buttonClass={"btn btn-secondary me-2"}
              buttonTitle={CANCEL}
              actionBlock={cancel}
            />
            <DefaultButton
              buttonClass={"btn btn-secondary default-btn-color"}
              buttonTitle={SAVE}
              buttonType={"submit"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompGroupForm;
