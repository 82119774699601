import { takeLatest, put, call } from "redux-saga/effects";
import {
  CHANNEL_TYPE_GET_DATA,
  CHANNEL_TYPE_GET_API,
  CHANNEL_TYPE_ADD_DATA,
  CHANNEL_TYPE_ADD_API,
  CHANNEL_TYPE_GET_DETAILS_DATA,
  CHANNEL_TYPE_GET_DETAILS_API,
  CHANNEL_TYPE_UPDATE_DATA,
  CHANNEL_TYPE_UPDATE_API,
  CHANNEL_TYPE_DELETE_API,
  CHANNEL_TYPE_DELETE_DATA,
} from "./Constants";
import {
  setChannelTypeGetDataSuccess,
  setChannelTypeGetDataFail,
  setChannelTypeAddDataSuccess,
  setChannelTypeAddDataFail,
  setChannelTypeGetDetailsFail,
  setChannelTypeGetDetailsSuccess,
  setChannelTypeUpdateDataFail,
  setChannelTypeUpdateDataSuccess,
  setChannelTypeDeleteDataSuccess,
  setChannelTypeDeleteDataFail,
} from "./Actions";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getChannelTypesService() {
  try {
    const response = yield call(getRequest, CHANNEL_TYPE_GET_API);
    if (response.status === 200) {
      yield put(setChannelTypeGetDataSuccess(response.data));
    } else {
      yield put(setChannelTypeGetDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setChannelTypeGetDataFail(error));
  }
}

function* postChannelDataService({ request }) {
  try {
    const response = yield call(
      postRequest,
      CHANNEL_TYPE_ADD_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Channel Type created"))
      yield put(setChannelTypeAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(
        setChannelTypeAddDataFail(response.response.data)
      );
    }
  } catch (error) {
    yield put(addErrorAlert("Could not create channel type"))
    yield put(setChannelTypeAddDataFail(error));
  }
}

function* getChannelDetailsDataService({ id }) {
  try {
    const response = yield call(getRequest, CHANNEL_TYPE_GET_DETAILS_API + id);
    if (response.status === 200) {
      yield put(setChannelTypeGetDetailsSuccess(response.data));
    } else {
      yield put(setChannelTypeGetDetailsFail(response.response.data));
    }
  } catch (error) {
    yield put(setChannelTypeGetDetailsFail(error));
  }
}

function* putChannelDataService({ request }) {
  try {
    const response = yield call(putRequest, CHANNEL_TYPE_UPDATE_API, request);

    if (response.status === 200) {
            yield put(addSuccessAlert("Channel Type updated"))
      yield put(setChannelTypeUpdateDataSuccess(response.data));
    } else {
            yield put(addErrorAlert(response.response.data.msg))
      yield put(setChannelTypeUpdateDataFail(response.response.data));
    }
  } catch (error) {
        yield put(addErrorAlert("Could not update channel type"))
    yield put(setChannelTypeUpdateDataFail(error));
  }
}

function* deleteChannelDataService({ request }) {
  try {
    const response = yield call(
      deleteRequest,
      CHANNEL_TYPE_DELETE_API,
      request
    );
    if (response.status === 200) {
            yield put(addSuccessAlert("Channel type deleted"))
      yield put(setChannelTypeDeleteDataSuccess(response.data));
    } else {
            yield put(addErrorAlert(response.response.data.msg))
      yield put(setChannelTypeDeleteDataFail(response.response.data));
    }
  } catch (error) {
        yield put(addErrorAlert("Could not delete channel type"))
    yield put(setChannelTypeDeleteDataFail(error));
  }
}

export function* watchChannelType() {
  yield takeLatest(
    CHANNEL_TYPE_GET_DATA.CHANNEL_TYPE_GET_DATA_LOAD,
    getChannelTypesService
  );
  yield takeLatest(
    CHANNEL_TYPE_ADD_DATA.CHANNEL_TYPE_ADD_DATA_LOAD,
    postChannelDataService
  );
  yield takeLatest(
    CHANNEL_TYPE_GET_DETAILS_DATA.CHANNEL_TYPE_GET_DETAILS_DATA_LOAD,
    getChannelDetailsDataService
  );
  yield takeLatest(
    CHANNEL_TYPE_UPDATE_DATA.CHANNEL_TYPE_UPDATE_DATA_LOAD,
    putChannelDataService
  );
  yield takeLatest(
    CHANNEL_TYPE_DELETE_DATA.CHANNEL_TYPE_DELETE_DATA_LOAD,
    deleteChannelDataService
  );
}
