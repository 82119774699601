import { takeLatest, put, call } from "redux-saga/effects";
import {
    ROLES_DATA, ROLES_GET_API,
    ROLES_GET_DATA, ROLES_GET_DETAIL_API,
    ROLES_ADD_DATA, ROLES_POST_API,
    ROLES_UPDATE_DATA, ROLES_PUT_API,
    ROLES_DELETE_DATA, ROLES_DELETE_API,
} from './Constants'
import {
    setRolesDataSuccess, setRolesDataFail,
    setRolesGetDataSuccess, setRolesGetDataFail,
    setRolesAddDataSuccess, setRolesAddDataFail,
    setRolesUpdateDataSuccess, setRolesUpdateDataFail,
    setRolesDeleteDataSuccess, setRolesDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";

function* getAllRolesDataService() {
    try {
        const response = yield call(getRequest, ROLES_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setRolesDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRolesDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving roles"))
        yield put(setRolesDataFail(error));
    }
}

function* getRolesDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            ROLES_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setRolesGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRolesGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving roles info"))
        yield put(setRolesGetDataFail(error));
    }
}

function* postRolesDataService({ request }) {
    try {
        const response = yield call(postRequest, ROLES_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Role created"))
            yield put(setRolesAddDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRolesAddDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error adding roles"))
        yield put(setRolesAddDataFail(error));
    }
}
function* putRolesDataService({ request }) {
    try {
        const response = yield call(putRequest, ROLES_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Role updated"))
            yield put(setRolesUpdateDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRolesUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error editing roles"))
        yield put(setRolesUpdateDataFail(error));
    }
}

function* deleteRolesDataService({ request }) {
    try {
        const response = yield call(deleteRequest, ROLES_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setRolesDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRolesDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setRolesDeleteDataFail(error));
    }
}


export function* watchRoles(){
    yield takeLatest(ROLES_DATA.ROLES_DATA_LOAD, getAllRolesDataService);
    yield takeLatest(ROLES_GET_DATA.ROLES_GET_DATA_LOAD, getRolesDataService);
    yield takeLatest(ROLES_ADD_DATA.ROLES_ADD_DATA_LOAD, postRolesDataService);
    yield takeLatest(ROLES_UPDATE_DATA.ROLES_UPDATE_DATA_LOAD, putRolesDataService);
    yield takeLatest(ROLES_DELETE_DATA.ROLES_DELETE_DATA_LOAD, deleteRolesDataService); 
}