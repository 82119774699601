import { getDetailRequest, getRequest } from "../../../../shared/components/Service";
import { takeLatest, put, call } from "redux-saga/effects";
import { ODOO_DESIGNATION_TRACKER_API, ODOO_DESIGNATION_TRACKER_BY_EMP_API, ODOO_DESIGNATION_TRACKER_BY_EMP_DATA, ODOO_DESIGNATION_TRACKER_DATA, ODOO_DESIGNATION_TRACKER_DETAILS_API, ODOO_DESIGNATION_TRACKER_DETAILS_DATA } from "./Constants";
import { addErrorAlert } from "../../../alert/Actions";
import { setOdooDesignationTrackerByEmpIdFail, setOdooDesignationTrackerByEmpIdSuccess, setOdooDesignationTrackerDataFail, setOdooDesignationTrackerDataSuccess, setOdooDesignationTrackerGetDataFail, setOdooDesignationTrackerGetDataSuccess } from "./Actions";

function* getAllOdooDesignationTrackersDataService() {
    try {
        const response = yield call(getRequest, ODOO_DESIGNATION_TRACKER_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setOdooDesignationTrackerDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setOdooDesignationTrackerDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving Odoo Store Tranfer Trackers"))
        yield put(setOdooDesignationTrackerDataFail(error));
    }
}

function* getOdooDesignationTrackerDetailsDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            ODOO_DESIGNATION_TRACKER_DETAILS_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setOdooDesignationTrackerGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setOdooDesignationTrackerGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving odoo designation tracker details"))
        yield put(setOdooDesignationTrackerGetDataFail(error));
    }
}
function* getOdooDesignationTrackerByEmpId({ emp_no }) {
    try {
        const response = yield call(getDetailRequest, ODOO_DESIGNATION_TRACKER_BY_EMP_API + emp_no);
        if (response.status === 200) {
            const newData = response.data;
            yield put(setOdooDesignationTrackerByEmpIdSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setOdooDesignationTrackerByEmpIdFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving odoo tracker details"))
        yield put(setOdooDesignationTrackerByEmpIdFail(error));
    }
}

export function* watchOdooDesignationTrackers() {
    yield takeLatest(ODOO_DESIGNATION_TRACKER_DATA.ODOO_DESIGNATION_TRACKER_DATA_LOAD, getAllOdooDesignationTrackersDataService);
    yield takeLatest(ODOO_DESIGNATION_TRACKER_DETAILS_DATA.ODOO_DESIGNATION_TRACKER_DETAILS_DATA_LOAD, getOdooDesignationTrackerDetailsDataService);
    yield takeLatest(ODOO_DESIGNATION_TRACKER_BY_EMP_DATA.ODOO_DESIGNATION_TRACKER_BY_EMP_DATA_LOAD, getOdooDesignationTrackerByEmpId);
}