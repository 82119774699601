import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ListView from "../listview/ListView"
import Modalpopup from "../Modalpopup"
import DefaultButton from "../DefaultButton"
import { DELETE, CANCEL, DELETE_CONFIRM } from "../../constants/Constants"
import { loadKeyValueAddDataRequest, loadKeyValueUpdateDataRequest, loadValueGetDataRequest, loadKeyValueDeleteDataRequest } from "../../../containers/tagging/redux/Actions"
import FormError from "../FormError"
import KeyTagValueForm from "./KeyTagValueForm"

const Tagging = ({ resource_id, resource_value }) => {
    const dispatch = useDispatch();

    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [errorMessage, setErrorMessage] = useState(false);
    let [showEditModal, setShowEditModal] = useState(false)
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [showAddModal, setShowAddModal] = useState(false);
    let [selectedTracker, setSelectedTracker] = useState({});


    let { keyvalues, loading, error } = useSelector(state => state.keyTag)

    let getValues = resource_value + '/' + resource_id;
    useEffect(() => {
        dispatch(loadValueGetDataRequest(getValues));
    }, [dispatch, getValues]);

    useEffect(() => {
        if (shouldCloseModal && !loading) {
            if (!error) {
                closeAddModal();
                closeEditModal();
                dispatch(loadValueGetDataRequest(getValues));
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setShouldCloseModal(false)
        }
    }, [loading, error, shouldCloseModal])

    let actionsPermission = false;

    const tagColumns = [
        {
            display_name: "Key",
            field: "key_name",
            type: "text",
        },
        {
            display_name: "Value",
            field: "key_value",
            type: "text",
        },
        {
            display_name: "",
            field: 'actions',
            type: 'actions',
            hide: actionsPermission
        }
    ];

    const handleEdit = (values) => {
        dispatch(loadKeyValueUpdateDataRequest(values))
        setShouldCloseModal(true);
    }
    const handleAdd = (values) => {
        delete values.id;
        dispatch(loadKeyValueAddDataRequest(values))
        setShouldCloseModal(true);
    }
    const handleDelete = () => {
        setShowDeleteModal(false);
        dispatch(loadKeyValueDeleteDataRequest({
            id: [selectedTracker]
        }))
        setShouldCloseModal(true)
    }

    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }
    const closeAddModal = () => {
        setShowAddModal(false);
        setErrorMessage(null);
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(() => {
        setClickEditFunc(
            () => (row) => {
                setSelectedTracker(row);
                setShowEditModal(true);
            })
        setClickDeleteFunc(
            () => (id) => {
                setSelectedTracker(id);
                setShowDeleteModal(true);
            })
    }, [])

    //let keyValue = {"resource_value":employee?.employee_id, "resource_id":selectedModule?.id}

    return <>
        <Button style={{ width: 230 }} className="btn btn-secondary default-btn-color float-end py-1" onClick={() => { setShowAddModal(true) }}>Add Additional Attribute</Button>
        <ListView
            exportTitle={'employee keytag'}
            columnNames={tagColumns}
            rowData={keyvalues}
            clickEdit={clickEditFunc}
            clickDelete={clickDeleteFunc}
        />
        <Modalpopup show={showDeleteModal} actionTitle="Delete Additional Attribute" handleClose={() => { setShowDeleteModal(false) }}>
            {DELETE_CONFIRM}
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => { handleDelete(selectedTracker) }}
                />
            </div>
        </Modalpopup>
        <Modalpopup show={showAddModal} actionTitle="Add Additional Attribute" handleClose={closeAddModal}>
            <FormError>
                {errorMessage}
            </FormError>
            <KeyTagValueForm submit={handleAdd} keyValue={{ resource_id, resource_value }} cancel={closeAddModal} keyvalues={keyvalues} />
        </Modalpopup>
        <Modalpopup show={showEditModal} actionTitle="Edit Additional Attribute" handleClose={closeEditModal} >
            <FormError>
                {errorMessage}
            </FormError>
            <KeyTagValueForm submit={handleEdit} keyValue={selectedTracker} cancel={closeEditModal} keyvalues={keyvalues} />
        </Modalpopup>
    </>
}
export default Tagging
