import { DIVISION_DATA, DIVISION_ADD_DATA, DIVISION_UPDATE_DATA, DIVISION_GET_DATA, DIVISION_DELETE_DATA } from './Constants'

const initialState = {
    loading: false,
    error: null,
    divisions: [],
    division: {}
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case DIVISION_DATA.DIVISION_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                divisions: []
            }
        case DIVISION_DATA.DIVISION_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                divisions: action.divisions,
                error: null
            }
        case DIVISION_DATA.DIVISION_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                divisions: []
            }
        case DIVISION_ADD_DATA.DIVISION_ADD_DATA_LOAD:
            return {
                ...state,
                loading: true,
                division: {},
                error: null
            }
        case DIVISION_ADD_DATA.DIVISION_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                division: action.division,
                error: null
            }
        case DIVISION_ADD_DATA.DIVISION_ADD_DATA_FAIL:
            return {
                ...state,
                loading: false,
                division: {},
                error: action.error
            }

        case DIVISION_GET_DATA.DIVISION_GET_DATA_LOAD:
            return {
                ...state,
                loading: true,
                division: {},
                error: null
            }
        case DIVISION_GET_DATA.DIVISION_GET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                division: action.division,
                error: null
            }
        case DIVISION_GET_DATA.DIVISION_GET_DATA_FAIL:
            return {
                ...state,
                loading: false,
                division: {},
                error: action.error
            }
        case DIVISION_UPDATE_DATA.DIVISION_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                division: {},
                error: null
            }
        case DIVISION_UPDATE_DATA.DIVISION_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                division: action.division,
                error: false
            }
        case DIVISION_UPDATE_DATA.DIVISION_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                division: {},
                error: action.error
            }
        case DIVISION_DELETE_DATA.DIVISION_DELETE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                division: {},
                error: null
            }
        case DIVISION_DELETE_DATA.DIVISION_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                division: action.division,
                error: null
            }
        case DIVISION_DELETE_DATA.DIVISION_DELETE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                division: {},
                error: action.error
            }
        default:
            return {
                ...state
            }
    }
}

export default Reducer;