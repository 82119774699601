import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadHourlySalaryAddDataRequest } from "../redux/Actions";
import HourlySalaryForm from "./HourlySalaryForm";

const HourlySalaryAdd = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  let { loading, error } = useSelector((state) => state.hourlySalary);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true);
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false);
    }
  }, [loading, error, shouldCloseModal]);

  function submit(values) {
    delete values.id;
    dispatch(loadHourlySalaryAddDataRequest(values));
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return (
    <>
      <FormError>
        {errorMessage}
      </FormError>
      <HourlySalaryForm submit={submit} cancel={cancel} />;
    </>
  );
};

export default HourlySalaryAdd;
