import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CANCEL, SAVE } from "../../../shared/constants/Constants";

const ModuleMetaDataForm = ({ module, submit, cancel }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.module_name) {
            errors.module_name = "Required";
        }
        return errors;
    };

    const formik = useFormik({});

    useEffect(() => {
        if (module) {
            formik.current.setFieldValue("id", module?.id);
            formik.current.setFieldValue("module_name", module?.module_name);
        }
    }, [module, formik]);

    return (
        <Formik
            innerRef={formik}
            initialValues={{
                module_name: "",
            }}
            validate={validate}
            onSubmit={submit}
        >
            {() => (
                <Form className="row g-4">
                    <div className="col-md-6">
                        <label htmlFor="module_name" className="form-label">
                            Module Name<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="module_name"
                            placeholder="Enter Module Name"
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="module_name" />
                        </span>
                    </div>
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ModuleMetaDataForm;
