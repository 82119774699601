import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../../shared/components/DefaultButton";
import { BACKTOLIST, EDIT, ADDITEM, DISTRICT_NAME, DISTRICT_TRACKER, ODOO_DESIGNATION_TRACKERS } from "../../../../shared/constants/Constants";
import ListView from "../../../../shared/components/listview/ListView";
import { PrevNextButtons } from "../../../../shared/components/PrevNextButtons";
import Permissions from "../../../../shared/components/Permissions";
import { Link } from "react-router-dom";
import { loadOdooDesignationTrackerGetDataRequest } from "../redux/Actions";

export default function OdooDesignationTrackerDetails() {
    let params = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    let { odooDesignationTracker } = useSelector((state) => state.odooDesignationTrackers);

    useEffect(() => {
        dispatch(loadOdooDesignationTrackerGetDataRequest(params.id))
    }, [dispatch, params.id])
    // let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    // let module_name = "Market";
    // let permission = Permissions(emp_no, module_name);

    // let modulePermission = "Market Tracker";
    // let trackerPermission = Permissions(emp_no, modulePermission);

    return <div>
        <Row className="detailsPageHeader">
            <Col xl={4} xs={12}>
                <Breadcrumbs
                    children={[
                        { href: '/odoo-designation-trackers', label: ODOO_DESIGNATION_TRACKERS },
                        { label: params.id },
                    ]}
                />
            </Col>
            <Col xl={8} xs={12}>
                <div className='float-end'>
                    <PrevNextButtons currentId={params.id} basePath='/odoo-designation-trackers/' name={ODOO_DESIGNATION_TRACKERS} />
                    <DefaultButton
                        buttonClass={"default-btn-color me-1"}
                        buttonTitle={BACKTOLIST}
                        actionBlock={() => { navigate("/odoo-designation-trackers"); }}
                    />
                </div>
            </Col>
        </Row>
        <Row>
            <Card style={{ marginBottom: '1.5rem' }}>
                <Card.Body>
                    <Row>
                        <Col>
                            <dl className='row'>
                                <dt className='col-sm-3'>Employee id</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.employee_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Employee</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.emp_name_number}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Store</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.store_sap_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Market</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.market_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Region</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.region_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Division</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.division_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Create uid</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.create_uid}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Create Date</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.create_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Write Date</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.write_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Write uid</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.write_uid}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Designation id</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.designation_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Dealer id</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.dealer_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Start Date</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.start_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>End Date</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.end_date}</dd>
                            </dl>
                        </Col>
                        <Col>
                            <dl className='row'>
                                <dt className='col-sm-3'>Heirarchy Level</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.heirarchy_level}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Covering str check</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.covering_str_check}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Covering Region Check</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.covering_region_check}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Covering Market Check</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.covering_market_check}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Termination Reason</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.termination_reason}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Secondary Designation id</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.secondary_designation_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Sec Heirarchy Level</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.sec_heirarchy_level}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>DT Secondary Job Bonus</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.dt_secondary_job_bonus}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Work Goal</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.work_goal}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Hire Status</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.hire_status}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Emp Hire Status</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.emp_hire_status}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>EL Job id</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.el_job_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Company Division id</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.company_division_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>T Company Division id</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.t_company_division_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-3'>Leave type</dt>
                                <dd className='col-sm-9'>{odooDesignationTracker?.leave_type}</dd>
                            </dl>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>
    </div>
}