import {
    FORM_ALL_DATA,   
    FORM_DATA,   
    FORM_GET_DATA,   
    FORM_GET_DATA_BY_SUB_ID,
    FORM_ADD_DATA,
    FORM_EDIT_DATA,
    FORM_DELETE_DATA,
    FORM_IMPORT_DATA
} from "./Constants";


//For listing all forms
const loadFormAllDataRequest = () => ({
  type: FORM_ALL_DATA.FORM_ALL_DATA_LOAD
})
const setFormAllDataSuccess = (forms) => ({
  type: FORM_ALL_DATA.FORM_ALL_DATA_SUCCESS,
  forms
})
const setFormAllDataFail = (error) => ({
  type: FORM_ALL_DATA.FORM_ALL_DATA_FAIL,
  error
})

//For listing
const loadFormDataRequest = () => ({
    type: FORM_DATA.FORM_DATA_LOAD
})
const setFormDataSuccess = (forms) => ({
    type: FORM_DATA.FORM_DATA_SUCCESS,
    forms
})
const setFormDataFail = (error) => ({
    type: FORM_DATA.FORM_DATA_FAIL,
    error
})

//For import form data by form id
const loadFormImportDataRequest = ( request) => ({
  type: FORM_IMPORT_DATA.FORM_IMPORT_DATA_LOAD,
  request,
})

const setFormAImportDataSuccess = ( importData ) => ({
  type: FORM_IMPORT_DATA.FORM_IMPORT_DATA_SUCCESS,
  importData
})

const setFormImportDataFail = (error) => ({
  type: FORM_IMPORT_DATA.FORM_IMPORT_DATA_FAIL,
  error
})


//To Get Form Details
const loadFormGetDataRequest = (id) => ({
    type: FORM_GET_DATA.FORM_GET_DATA_LOAD,
    id
})

const setFormGetDataSuccess = (form) => ({
    type: FORM_GET_DATA.FORM_GET_DATA_SUCCESS,
    form
})

const setFormGetDataFail = (error) => ({
    type: FORM_GET_DATA.FORM_GET_DATA_FAIL,
    error
})

//To Get Form Details by submission id
const loadFormGetDataBySubIDRequest = (id) => ({
    type: FORM_GET_DATA_BY_SUB_ID.FORM_GET_DATA_BY_SUB_ID_LOAD,
    id
})

const setFormGetDataBySubIDSuccess = (formsubid) => ({
    type: FORM_GET_DATA_BY_SUB_ID.FORM_GET_DATA_BY_SUB_ID_SUCCESS,
    formsubid
})

const setFormGetDataBySubIDFail = (error) => ({
    type: FORM_GET_DATA_BY_SUB_ID.FORM_GET_DATA_BY_SUB_ID_FAIL,
    error
})

//To Add Form Details 
const loadFormAddDataRequest = (request) => ({
  type: FORM_ADD_DATA.FORM_ADD_DATA_LOAD,
  request,
});
const setFormAddDataSuccess = (typeTracker) => ({
  type: FORM_ADD_DATA.FORM_ADD_DATA_SUCCESS,
  typeTracker,
});
const setFormAddDataFail = (error) => ({
  type: FORM_ADD_DATA.FORM_ADD_DATA_FAIL,
  error,
});

//To edit Form Details by submission id
const loadFormEditDataRequest = (request) => ({
    type: FORM_EDIT_DATA.FORM_EDIT_DATA_LOAD,
    request,
  });
  const setFormEditDataSuccess = (typeTracker) => ({
    type: FORM_EDIT_DATA.FORM_EDIT_DATA_SUCCESS,
    typeTracker,
  });
  const setFormEditDataFail = (error) => ({
    type: FORM_EDIT_DATA.FORM_EDIT_DATA_FAIL,
    error,
  });

  //To delete Form Details by submission id
const loadFormDeleteDataRequest = (request) => ({
    type: FORM_DELETE_DATA.FORM_DELETE_DATA_LOAD,
    request,
  });
  const setFormDeleteDataSuccess = (typeTracker) => ({
    type: FORM_DELETE_DATA.FORM_DELETE_DATA_SUCCESS,
    typeTracker,
  });
  const setFormDeleteDataFail = (error) => ({
    type: FORM_DELETE_DATA.FORM_DELETE_DATA_FAIL,
    error,
  });


export {
    loadFormAllDataRequest,
    setFormAllDataSuccess,
    setFormAllDataFail, 
    loadFormDataRequest,
    setFormDataSuccess,
    setFormDataFail,    
    loadFormGetDataRequest,
    setFormGetDataSuccess,
    setFormGetDataFail, 
    loadFormGetDataBySubIDRequest,
    setFormGetDataBySubIDSuccess,
    setFormGetDataBySubIDFail,
    loadFormAddDataRequest,
    setFormAddDataSuccess,
    setFormAddDataFail,
    loadFormEditDataRequest,
    setFormEditDataSuccess,
    setFormEditDataFail,
    loadFormDeleteDataRequest,
    setFormDeleteDataSuccess,
    setFormDeleteDataFail,
    loadFormImportDataRequest,
    setFormAImportDataSuccess,
    setFormImportDataFail
}
