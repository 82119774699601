//Get
const EMPLOYEE_ROLE_DATA = {
    EMPLOYEE_ROLE_DATA_LOAD: "EMPLOYEE_ROLE_DATA_LOAD",
    EMPLOYEE_ROLE_DATA_SUCCESS: "EMPLOYEE_ROLE_DATA_SUCCESS",
    EMPLOYEE_ROLE_DATA_FAIL: "EMPLOYEE_ROLE_DATA_FAIL",
};
const EMPLOYEE_ROLE_GET_API = "/admin/empRolesMapping";

// Get one by id
const EMPLOYEE_ROLE_GET_DATA = {
    EMPLOYEE_ROLE_GET_DATA_LOAD: "EMPLOYEE_ROLE_GET_DATA_LOAD",
    EMPLOYEE_ROLE_GET_DATA_SUCCESS: "EMPLOYEE_ROLE_GET_DATA_SUCCESS",
    EMPLOYEE_ROLE_GET_DATA_FAIL: "EMPLOYEE_ROLE_GET_DATA_FAIL",
};

const EMPLOYEE_ROLE_GET_DETAIL_API = "/admin/empRolesMapping/";

// Create / Add / Post
const EMPLOYEE_ROLE_ADD_DATA = {
    EMPLOYEE_ROLE_ADD_DATA_LOAD: "EMPLOYEE_ROLE_ADD_DATA_LOAD",
    EMPLOYEE_ROLE_ADD_DATA_SUCCESS: "EMPLOYEE_ROLE_ADD_DATA_SUCCESS",
    EMPLOYEE_ROLE_ADD_DATA_FAIL: "EMPLOYEE_ROLE_ADD_DATA_FAIL",
};

const EMPLOYEE_ROLE_POST_API = "/admin/empRolesMapping";

//Update
const EMPLOYEE_ROLE_UPDATE_DATA = {
  EMPLOYEE_ROLE_UPDATE_DATA_LOAD: "EMPLOYEE_ROLE_UPDATE_DATA_UPLOAD",
  EMPLOYEE_ROLE_UPDATE_DATA_SUCCESS: "EMPLOYEE_ROLE_UPDATE_DATA_SUCCESS",
  EMPLOYEE_ROLE_UPDATE_DATA_FAIL: "EMPLOYEE_ROLE_UPDATE_DATA_FAIL",
};

const EMPLOYEE_ROLE_PUT_API = "/admin/empRolesMapping";

// Delete
const EMPLOYEE_ROLE_DELETE_DATA = {
  EMPLOYEE_ROLE_DELETE_DATA_LOAD: "EMPLOYEE_ROLE_DELETE_DATA_LOAD",
  EMPLOYEE_ROLE_DELETE_DATA_SUCCESS: "EMPLOYEE_ROLE_DELETE_DATA_SUCCESS",
  EMPLOYEE_ROLE_DELETE_DATA_FAIL: "EMPLOYEE_ROLE_DELETE_DATA_FAIL",
};

const EMPLOYEE_ROLE_DELETE_API = "/admin/empRolesMapping";

export{
    EMPLOYEE_ROLE_DATA,
    EMPLOYEE_ROLE_GET_API,
    EMPLOYEE_ROLE_GET_DATA,
    EMPLOYEE_ROLE_GET_DETAIL_API,
    EMPLOYEE_ROLE_ADD_DATA,
    EMPLOYEE_ROLE_POST_API,
    EMPLOYEE_ROLE_UPDATE_DATA,
    EMPLOYEE_ROLE_PUT_API,
    EMPLOYEE_ROLE_DELETE_DATA,
    EMPLOYEE_ROLE_DELETE_API,
}