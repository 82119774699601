import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../../shared/components/DefaultButton";
import ListView from "../../../../shared/components/listview/ListView";
import { ODOO_DESIGNATION_TRACKERS, ROLES } from "../../../../shared/constants/Constants";
import { loadOdooDesignationTrackerByEmpId, loadOdooDesignationTrackerDataRequest, loadOdooDesignationTrackerGetDataRequest } from "../redux/Actions";

const designationColumns = [
    {
        display_name: "id",
        field: "id",
        type: "number",
        hide: true
    },
    {
        display_name: "Employee",
        field: "emp_name_number",
        type: 'text',
    },
    {
        display_name: "Store",
        field: "store_sap_name",
        type: "text",
    },
    {
        display_name: "Market",
        field: "market_name",
        type: "text",
    },
    {
        display_name: "Region",
        field: "region_name",
        type: "text",
    },
    {
        display_name: "Division",
        field: "division_name",
        type: "text",
    },
    {
        display_name: "Start Date",
        field: "start_date",
        type: "date",
    },
    {
        display_name: "End Date",
        field: "end_date",
        type: "date",
    },
];
const storeColumns = [
    {
        display_name: "id",
        field: "id",
        type: "number",
        hide: true
    },
    {
        display_name: "Store",
        field: "store_sap_name",
        type: "text",
    },
    {
        display_name: "Employee",
        field: "emp_name_number",
        type: 'text',
    },
    {
        display_name: "Employee Type",
        field: "emp_type_name",
        type: "text",
    },
    {
        display_name: "Start Date",
        field: "start_date",
        type: "date",
    },
    {
        display_name: "End Date",
        field: "end_date",
        type: "date",
    },

]
export const OdooTrackersByEmp = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let params = useParams();
    let [query] = useSearchParams();

    let id = query.get('back')
    let { odooDesignationTrackerByEmp } = useSelector((state) => state.odooDesignationTrackers);

    let [currentTab, setCurrentTab] = useState("Designation")

    useEffect(() => {
        dispatch(loadOdooDesignationTrackerByEmpId(params.id));
    }, []);
    let tabs = {
        "Designation": <ListView
            // exportTitle={ODOO_DESIGNATION_TRACKERS}
            columnNames={designationColumns}
            rowData={odooDesignationTrackerByEmp?.[0]?.designation}
        // rowClick={(row) => { navigate(row.data.id) }}
        />,
        "Store": <ListView
            columnNames={storeColumns}
            rowData={odooDesignationTrackerByEmp?.[1]?.storeTracker}
        />
    }

    return (
        <>
            <Row>
                <Col>
                    <Breadcrumbs
                        children={[
                            { label: ODOO_DESIGNATION_TRACKERS },
                        ]}
                    />
                </Col>
                <Col xs={'auto'}>
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={"Back"}
                        actionBlock={() => {
                            navigate(`/employees/${id}`)
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {Object.keys(tabs).map(key =>
                        <button className={currentTab === key ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTab(key) }}>{key}</button>
                    )}
                </Col>
                <Card>
                    <Card.Body>
                        {tabs[currentTab]}
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
};

