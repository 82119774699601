import {
  ROLES_DATA,
  ROLES_GET_DATA,
  ROLES_ADD_DATA,
  ROLES_UPDATE_DATA,
  ROLES_DELETE_DATA,
} from "./Constants";
const initialState = {
  loading: false,
  error: null,
  roles: [],
  role: {},
};
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    //load all
    case ROLES_DATA.ROLES_DATA_LOAD:
      return {
        ...state,
        loading: true,
        roles: [],
        error: null,
      };
    case ROLES_DATA.ROLES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        roles: action.roles,
      };
    case ROLES_DATA.ROLES_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //load single
    case ROLES_GET_DATA.ROLES_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        role: {},
        error: null,
      };

    case ROLES_GET_DATA.ROLES_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.role,
      };

    case ROLES_GET_DATA.ROLES_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ROLES_ADD_DATA.ROLES_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ROLES_ADD_DATA.ROLES_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ROLES_ADD_DATA.ROLES_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ROLES_UPDATE_DATA.ROLES_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ROLES_UPDATE_DATA.ROLES_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ROLES_UPDATE_DATA.ROLES_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ROLES_DELETE_DATA.ROLES_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ROLES_DELETE_DATA.ROLES_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ROLES_DELETE_DATA.ROLES_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
