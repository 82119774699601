import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState } from "react";
import { Card,Button,Collapse,  Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import ListView from "../../../shared/components/listview/ListView"
import { loadMyPlanAddDataRequest,  loadPlanByEmpLocPerGetDataRequest, loadMyPlanGetDataRequest, loadMyPlanNoteAddDataRequest } from "../redux/Actions"
import FormTextAreaField from "../../../shared/components/FormTextAreaField";

import { CANCEL, DIVISION, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, HIRE_STATUS, HOURLY_SALARY, JOB_TITLE, MARKET, PART_FULL_TIME, REGION, SAVE, STORE } from "../../../shared/constants/Constants";

const MyPlanNotesEdit = ({ isManager, editnotes, submit, cancel}) => {
    const dispatch = useDispatch();
    const formik = useFormik({});   
    const [textareaValue, setTextareaValue] = useState('');
    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    let empInfo = JSON.parse(localStorage.getItem("employee"));
    console.log("editnotes", editnotes)
    const handleNoteSave = (event) => {        
        editnotes["note"] = textareaValue
        console.log(editnotes)
        submit(editnotes)
    }
    return (
        <>
            <Row>
                <Card className="p-0" style={{ border: '0px' }}>
                    <Card.Body className="p-0">
                        <Row className="m-2">
                            <Col className="col-4"><strong>Notes</strong></Col>
                            <Col className="col-8">
                                <textarea
                                    style={{ width: '100%', border: '1px solid #ff7f0e' }}
                                    id =  {"notes-" + editnotes['business_priority_id'] + "-" + editnotes['id']}
                                    name = {editnotes['id']}
                                    defaultValue = {editnotes['note']}
                                    rows="4"
                                    cols="50"
                                    onChange={handleTextareaChange}
                                />     
                            </Col>
                        </Row>
                        <Col md={12}>
                            <DefaultButton
                                buttonClass={"btn btn-secondary me-2"}
                                buttonTitle={CANCEL}
                                actionBlock={cancel}
                            />
                            <DefaultButton
                                buttonClass={"btn btn-secondary default-btn-color"}
                                buttonTitle={SAVE}
                                buttonType={"submit"}
                                actionBlock={handleNoteSave}
                            />
                        </Col> 
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
};

export default MyPlanNotesEdit;