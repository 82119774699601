import {
    EMPLOYEE_ROLE_DATA,
    EMPLOYEE_ROLE_GET_DATA,
    EMPLOYEE_ROLE_ADD_DATA,
    EMPLOYEE_ROLE_UPDATE_DATA,
    EMPLOYEE_ROLE_DELETE_DATA,
  } from "./Constants";
  const initialState = {
    loading: false,
    error: null,
    empRoles: [],
    empRole: {},
  };
  const Reducer = (state = initialState, action) => {
    switch (action.type) {
      //load all
      case EMPLOYEE_ROLE_DATA.EMPLOYEE_ROLE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          empRoles: [],
          error: null,
        };
      case EMPLOYEE_ROLE_DATA.EMPLOYEE_ROLE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          empRoles: action.empRoles,
        };
      case EMPLOYEE_ROLE_DATA.EMPLOYEE_ROLE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      //load single
      case EMPLOYEE_ROLE_GET_DATA.EMPLOYEE_ROLE_GET_DATA_LOAD:
        return {
          ...state,
          loading: true,
          empRole: {},
          error: null,
        };
  
      case EMPLOYEE_ROLE_GET_DATA.EMPLOYEE_ROLE_GET_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          empRole: action.empRole,
        };
  
      case EMPLOYEE_ROLE_GET_DATA.EMPLOYEE_ROLE_GET_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case EMPLOYEE_ROLE_ADD_DATA.EMPLOYEE_ROLE_ADD_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case EMPLOYEE_ROLE_ADD_DATA.EMPLOYEE_ROLE_ADD_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case EMPLOYEE_ROLE_ADD_DATA.EMPLOYEE_ROLE_ADD_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case EMPLOYEE_ROLE_UPDATE_DATA.EMPLOYEE_ROLE_UPDATE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case EMPLOYEE_ROLE_UPDATE_DATA.EMPLOYEE_ROLE_UPDATE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case EMPLOYEE_ROLE_UPDATE_DATA.EMPLOYEE_ROLE_UPDATE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      case EMPLOYEE_ROLE_DELETE_DATA.EMPLOYEE_ROLE_DELETE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case EMPLOYEE_ROLE_DELETE_DATA.EMPLOYEE_ROLE_DELETE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case EMPLOYEE_ROLE_DELETE_DATA.EMPLOYEE_ROLE_DELETE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return {
          ...state,
        };
    }
  };
  export default Reducer;
  