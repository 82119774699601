import { takeLatest, put, call } from "redux-saga/effects";
import {
  TSHIRT_DATA,
  TSHIRT_GET_API,
  TSHIRT_ADD_DATA,
  TSHIRT_POST_API,
  TSHIRT_GET_DATA,
  TSHIRT_GET_DETAIL_API,
  TSHIRT_UPDATE_DATA,
  TSHIRT_PUT_API
} from "./Constants";
import {
  setTShirtDataSuccess,
  setTShirtDataFail,
  setTShirtAddDataSuccess,
  setTShirtAddDataFail,
  setTShirtGetDataSuccess,
  setTShirtGetDataFail,
  setTShirtUpdateDataSuccess,
  setTShirtUpdateDataFail
} from "./Actions";
import {
  getRequest,
  postRequest,
  putRequest,
  getDetailRequest
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getAllTShirtDataService() {
  try {
    const tShirtResponse = yield call(getRequest, TSHIRT_GET_API);
    const status = tShirtResponse.status;
    if (status === 200) {
      const data = tShirtResponse.data;
      yield put(setTShirtDataSuccess(data));
    } else {
      yield put(setTShirtDataFail(tShirtResponse.response.data));
    }
  } catch (error) {
    yield put(setTShirtDataFail(error));
  }
}

function* postTShirtDataService({ request }) {
  try {
    const response = yield call(
      postRequest,
      TSHIRT_POST_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      // yield put(addSuccessAlert("T-Shirt Size Saved"))
      yield put(setTShirtAddDataSuccess(newData));
    } else {
      yield put(setTShirtAddDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setTShirtAddDataFail(error));
  }
}

function* putTShirtDataService({ request }) {
  try {
    const response = yield call(
      putRequest,
      TSHIRT_PUT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(setTShirtUpdateDataSuccess(newData));
    } else {
      yield put(setTShirtUpdateDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setTShirtUpdateDataFail(error));
  }
}

function* getTShirtDataService({ id }) {
  try {
    const gettShirtDataResponse = yield call(
      getDetailRequest,
      TSHIRT_GET_DETAIL_API + id
    );
    const status = gettShirtDataResponse.status;
    if (status === 200) {
      const newData = gettShirtDataResponse.data;
      yield put(setTShirtGetDataSuccess(newData));
    } else {
      yield put(setTShirtGetDataFail(gettShirtDataResponse.response.data));
    }
  } catch (error) {
    yield put(setTShirtGetDataFail(error));
  }
}

export function* watchTShirt() {
  yield takeLatest(TSHIRT_DATA.TSHIRT_DATA_LOAD, getAllTShirtDataService);
  yield takeLatest(
    TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_LOAD,
    postTShirtDataService
  );
  yield takeLatest(
    TSHIRT_UPDATE_DATA.TSHIRT_UPDATE_DATA_LOAD,
    putTShirtDataService
  );
  yield takeLatest(
    TSHIRT_GET_DATA.TSHIRT_GET_DATA_LOAD,
    getTShirtDataService
  );
}
