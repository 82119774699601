import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadKeyTagUpdateDataRequest } from "../redux/Actions";
import KeyTagForm from "./KeyTagForm";

const KeyTagEdit = ({ handleClose, keytags }) => {
  
  const dispatch = useDispatch();
  let [submitted, setSubmitted] = useState(false);
  let [isClosing, setIsClosing] = useState(false);

  let { loading, error } = useSelector((state) => state.keyTag);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (submitted && !loading && !isClosing) {
        setIsClosing(true);
        handleClose(true)
    }
}, [loading, dispatch, error, handleClose, isClosing, submitted])

  function submit(values) {
    dispatch(loadKeyTagUpdateDataRequest(values));
    setSubmitted(true);
  }
  function cancel() {
    handleClose(false);
  }
  return <KeyTagForm submit={submit} cancel={cancel} keytags={keytags} />;
};

export default KeyTagEdit;
