export const AUDIT_DATA = {
  AUDIT_DATA_LOAD: "AUDIT_DATA_LOAD",
  AUDIT_DATA_SUCCESS: "AUDIT_DATA_SUCCESS",
  AUDIT_DATA_FAIL: "AUDIT_DATA_FAIL",
};

export const AUDIT_GET_API = "/audit/";

export const AUDIT_GET_TABLES = {
  AUDIT_GET_TABLES_LOAD: "AUDIT_GET_TABLES_LOAD",
  AUDIT_GET_TABLES_SUCCESS: "AUDIT_GET_TABLES_SUCCESS",
  AUDIT_GET_TABLES_FAIL: "AUDIT_GET_TABLES_FAIL",
}
export const AUDIT_GET_TABLES_API = "/audit/tables"