import React, { useEffect, useState } from 'react'
import './Header.css'
// import logo from '../../assets/company-logo.png'
import userIcon from '../../assets/avatar.png'
// import DefaultButton from '../../shared/components/DefaultButton';
// import Modalpopup from '../../shared/components/Modalpopup';
// import EmpSelection from '../../shared/components/EmpSelection';
// import { useDispatch, useSelector } from 'react-redux';
// import { loadEmployeeGetDataRequest } from '../employees/redux/Actions';
let logoPath = "/company-logo.png";

const Header = ({ toggleMenu }) => {

    let empInfo = JSON.parse(localStorage.getItem("employee"));
    
    // const dispatch = useDispatch(); 
    // let [empInfo, setEmpInfo] = useState(JSON.parse(localStorage.getItem("employee")))
    // let [hideReturnButton, setHideReturnButton] = useState( !localStorage.getItem('admin_employee'))
    // let [adminEmpInfo, setAdminEmpInfo] = useState(JSON.parse(localStorage.getItem('admin_employee')));
    // // let [hideReturnButton, setHideReturnButton] = useState( true)

    // let { employee } = useSelector((store) => store.employee);
    // const [showModal, setShowModal] = useState(false);

    // // useEffect(()=>{
    // //     if (employee && Object.keys(employee).length > 0){
    // //         localStorage.setItem("employee", JSON.stringify(employee));
    // //         setEmpInfo(employee)
    // //         localStorage.setItem("emp_no", JSON.stringify(employee.employee_id));
    // //     }
    // // },[employee])
    // function submit(values) {
    //     dispatch(loadEmployeeGetDataRequest(values.employee_id));
    //     localStorage.setItem("admin_employee", localStorage.getItem("employee"));
    //     localStorage.setItem("admin_emp_no", JSON.parse(localStorage.getItem("emp_no")));
    //     setAdminEmpInfo(JSON.parse(localStorage.getItem('admin_employee')));
    //     setHideReturnButton(false)
    //     setShowModal(false);
    // }
    // function handleClose() {
    //     setShowModal(false);
    // }

    return (
        <div>
            <header>
                <div className="topbar d-flex align-items-center">
                    <div style={{ height: '100%', background: "#313a46", padding: '.2rem' }} className="d-block d-md-none">
                        <img style={{ padding: "5px 0px", }} src={logoPath} alt="logo icon" className='company-logo' />
                    </div>
                    <div className="toggle-menu" onClick={toggleMenu}>
                        <i className='fa fa-bars'></i>
                    </div>
                    <nav className="navbar navbar-expand px-0 px-sm-4">
                        {/* {empInfo?.emp_no === "admin" ? (
                            <>
                                <DefaultButton
                                    buttonClass={"default-btn-color me-2"}
                                    buttonTitle={'Employee Selection'}
                                    actionBlock={() => { setShowModal(true) }}
                                />
                        </>
                        ) : (<></>)}
                        {adminEmpInfo?.emp_no === 'admin' ? (<>
                            {!hideReturnButton ?
                            <DefaultButton
                                buttonClass={"default-btn-color me-2"}
                                buttonTitle={"Return to admin"}
                                actionBlock={()=>{
                                    let employee = localStorage.getItem("admin_employee")
                                    let emp_no = JSON.stringify(localStorage.getItem("admin_emp_no"));
                                    if (employee && emp_no){
                                        localStorage.setItem("employee",localStorage.getItem("admin_employee"))
                                        setEmpInfo(JSON.parse(localStorage.getItem("admin_employee")))
                                        localStorage.setItem('emp_no',emp_no)
                                        localStorage.removeItem('admin_employee');
                                        localStorage.removeItem('admin_emp_no')
                                        setHideReturnButton(true)
                                    }
                                }}
                            />:<></>}
                        </>) : (<></>)}                          */}
                        <div className="user-menu">
                            <div className="user-box dropdown">
                                <a className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="/dashboard" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {/* <img src={userIcon} className="user-img" alt="user avatar" /> */}
                                    <div className="user-info ps-3 d-none d-sm-block">
                                        <p className="user-name mb-0">{empInfo?.first_name} {empInfo?.last_name}</p>
                                        <p className="designattion mb-0">{empInfo?.job_title || empInfo?.job_title_name}</p>
                                    </div>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {empInfo?.role === "hc_sales" ? (
                                        <li><a className="dropdown-item" href={"/userProfile"}><i className="fa fa-user-o"></i><span>Profile</span></a>
                                        </li>
                                    ) : (
                                        <li><a className="dropdown-item" href={"/employees/" + empInfo?.user_id}><i className="fa fa-user-o"></i><span>Profile</span></a>
                                        </li>
                                    )}
                                    <li><a className="dropdown-item" href="/logout"><i className='fa fa-sign-out'></i><span>Logout</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            
            {/* <Modalpopup
                show={showModal}
                handleClose={() => { setShowModal(false) }}
                actionTitle={'Employee Selection For Admin View'}
            >
                <EmpSelection employee={employee} cancel={handleClose} submit={submit} />
            </Modalpopup> */}
        </div>
    )
}

export default Header
