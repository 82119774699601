import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import { ACQUISITION_DATE, ACQUISITION_DEALER_NAME, CANCEL, SAVE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";


const AcquisitionForm = ({ acquisition, submit, cancel }) => {

    const validate = (values) => {
        const errors = {};
        if (!values.acquisition_dealer_name) {
            errors.acquisition_dealer_name = "Required";
        }
        return errors;
    };

    let acquisitionDate = '';
    if (acquisition && acquisition?.acquisition_date) {
        if (acquisition?.acquisition_date.includes("-")) {
            acquisitionDate = FormatDate(acquisition.acquisition_date); 
        }
    }

    return <Formik
        initialValues={{
            id: acquisition?.id ?? '',
            acquisition_dealer_name: acquisition?.acquisition_dealer_name ?? '',
            acquisition_date: acquisitionDate
        }}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="acquisition_dealer_name" className="form-label">
                        {ACQUISITION_DEALER_NAME} <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='acquisition_dealer_name' placeholder="Enter Acquisition Dealer Name" autoComplete="off" />
                    <span className="error-text">
                        <ErrorMessage name="acquisition_dealer_name" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        {ACQUISITION_DATE}
                    </label>
                    <FormikDatePickerField name="acquisition_date" value={values.acquisition_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder="Choose Acquisition Date" />
                    <span className='error-text'>
                        <ErrorMessage name="acquisition_date" />
                    </span>
                </div>
                <div className="col-md-12">
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"btn btn-secondary default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </div>
            </Form>
        )}
    </Formik>
}

export default AcquisitionForm