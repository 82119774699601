// Get
const CHANNEL_TYPE_GET_DATA = {
    CHANNEL_TYPE_GET_DATA_LOAD: 'CHANNEL_TYPE_GET_DATA_LOAD',
    CHANNEL_TYPE_GET_DATA_SUCCESS: 'CHANNEL_TYPE_GET_DATA_SUCCESS',
    CHANNEL_TYPE_GET_DATA_FAIL: 'CHANNEL_TYPE_GET_DATA_FAIL'
}

const CHANNEL_TYPE_GET_API = '/store/channel';

const CHANNEL_TYPE_ADD_DATA = {
    CHANNEL_TYPE_ADD_DATA_LOAD: 'CHANNEL_TYPE_ADD_DATA_LOAD',
    CHANNEL_TYPE_ADD_DATA_SUCCESS: 'CHANNEL_TYPE_ADD_DATA_SUCCESS',
    CHANNEL_TYPE_ADD_DATA_FAIL: 'CHANNEL_TYPE_ADD_DATA_FAIL'
}
const CHANNEL_TYPE_ADD_API = '/store/channel'

const CHANNEL_TYPE_UPDATE_DATA = {
    CHANNEL_TYPE_UPDATE_DATA_LOAD: 'CHANNEL_TYPE_UPDATE_DATA_LOAD',
    CHANNEL_TYPE_UPDATE_DATA_SUCCESS: 'CHANNEL_TYPE_UPDATE_DATA_SUCCESS',
    CHANNEL_TYPE_UPDATE_DATA_FAIL: 'CHANNEL_TYPE_UPDATE_DATA_FAIL'
}
const CHANNEL_TYPE_UPDATE_API = "/store/channel"

const CHANNEL_TYPE_GET_DETAILS_DATA = {
    CHANNEL_TYPE_GET_DETAILS_DATA_LOAD: 'CHANNEL_TYPE_GET_DETAILS_DATA_LOAD',
    CHANNEL_TYPE_GET_DETAILS_DATA_SUCCESS: 'CHANNEL_TYPE_GET_DETAILS_DATA_SUCCESS',
    CHANNEL_TYPE_GET_DETAILS_DATA_FAIL: 'CHANNEL_TYPE_GET_DETAILS_DATA_FAIL'
}
const CHANNEL_TYPE_GET_DETAILS_API = '/store/channel/'

const CHANNEL_TYPE_DELETE_DATA = {
    CHANNEL_TYPE_DELETE_DATA_LOAD: 'CHANNEL_TYPE_DELETE_DATA_LOAD',
    CHANNEL_TYPE_DELETE_DATA_SUCCESS: 'CHANNEL_TYPE_DELETE_DATA_SUCCESS',
    CHANNEL_TYPE_DELETE_DATA_FAIL: 'CHANNEL_TYPE_DELETE_DATA_FAIL'
}
const CHANNEL_TYPE_DELETE_API = '/store/channel'

export {
    CHANNEL_TYPE_GET_DATA,
    CHANNEL_TYPE_GET_API,
    CHANNEL_TYPE_ADD_DATA,
    CHANNEL_TYPE_ADD_API,
    CHANNEL_TYPE_UPDATE_DATA,
    CHANNEL_TYPE_UPDATE_API,
    CHANNEL_TYPE_GET_DETAILS_DATA,
    CHANNEL_TYPE_GET_DETAILS_API,
    CHANNEL_TYPE_DELETE_DATA,
    CHANNEL_TYPE_DELETE_API,
}

