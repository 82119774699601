import React, { useEffect, useState } from "react"
import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import FormTextField from "../../../shared/components/FormTextField";
import { Button, Card, Col, Row} from 'react-bootstrap'
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import { FORM_BULK_IMPORT } from "../../../shared/constants/Constants";
import DynamicFormAdd from './DynamicFormAdd';
import DynamicFormEdit from './DynamicFormEdit';
import Modalpopup from '../../../shared/components/Modalpopup';
import ListView from "../../../shared/components/listview/ListView"
import { loadFormAllDataRequest, loadFormImportDataRequest} from "../../../containers/dynamic_form/redux/Actions"
import FormError from "../../../shared/components/FormError"
import {  CANCEL, SAVE } from "../../../shared/constants/Constants"
import DefaultButton from "../../../shared/components/DefaultButton"

export default function DynamicFormBulkImport() {

  const dispatch = useDispatch();
  let { loading, employee, designationTrackers, typeTrackers } = useSelector((store) => store.employee);
  let { allforms,  error} = useSelector((state) => state.forms);
  const [csvFile, setCSVFile] = useState(null);
  const [fileError, setFileError] = useState(false);

//   const handleFileChange = (event, setFieldValue) => {
//     const file = event.currentTarget.files[0];
//     console.log("file",file)
//     setFieldValue('csvFile', file);
// };
  useEffect(() => {
    console.log("loading loadFormGetDataRequest")
      dispatch(loadFormAllDataRequest());      
  }, [dispatch]);

  console.log("allforms", allforms)

  const handleAddImport = (e) => {
    e.preventDefault();
    if (csvFile !== null) {
        let formData = new FormData();
        formData.append('csv_file', csvFile);
        formData.append('table', 'form_data');
        let request = {
            data: formData
        }
       
        dispatch(loadFormImportDataRequest(request))
        
    } else {
        setFileError(true);
    }
}

  return <div>
    <Row className="detailsPageHeader">
      <Col xl={4} xs={12}>
        <Breadcrumbs
            children={[
                { href: '/form-bulk-import', label: FORM_BULK_IMPORT },
            ]}
        />
      </Col>
      <Row>
            <Card>
                <Card.Body>
                    <Row>
                    <form className="row g-4">
                        <div className="col-md-6">
                            <label htmlFor="import_data" className="form-label">
                                Upload CSV File <span className="error-text">*</span>
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                id="import_data"
                                name="import_data"
                                onChange={(event) => { setCSVFile(event.target.files[0]) }}
                            />
                            <span className="error-text">
                                {fileError ? (
                                    <div>Please upload file</div>
                                ) : null}
                            </span>
                        </div>
                        <div className="col-md-12">
                            
                            <DefaultButton
                                buttonClass={"btn btn-secondary default-btn-color"}
                                buttonTitle={SAVE}
                                actionBlock={handleAddImport}
                            />
                        </div>
                    </form> 
                    </Row>
                </Card.Body>
            </Card>
      </Row>


    </Row>
  </div >
}

