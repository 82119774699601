import { takeLatest, put, call } from "redux-saga/effects";
import {
  JOBTITLE_DATA,
  JOBTITLE_GET_API,
  JOBTITLE_ADD_DATA,
  JOBTITLE_POST_API,
  JOBTITLE_GET_DATA,
  JOBTITLE_GET_DETAIL_API,
  JOBTITLE_UPDATE_DATA,
  JOBTITLE_PUT_API,
  JOBTITLE_DELETE_DATA,
  JOBTITLE_DELETE_API,
  JOBTITLE_IMPORT_DATA,
  JOBTITLE_IMPORT_API
} from "./Constants";
import {
  setJobTitlesDataSuccess,
  setJobTitlesDataFail,
  setJobTitlesAddDataSuccess,
  setJobTitlesAddDataFail,
  setJobTitlesGetDataSuccess,
  setJobTitlesGetDataFail,
  setJobTitlesUpdateDataSuccess,
  setJobTitlesUpdateDataFail,
  setJobTitlesDeleteDataSuccess,
  setJobTitlesDeleteDataFail,
  setJobTitlesImportDataSuccess,
  setJobTitlesImportDataFail,
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
  postImportRequest
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";


function* getJobTitlesDataService() {
  try {
    const response = yield call(
      getRequest,
      JOBTITLE_GET_API
    );
    const status = response.status;
    if (status === 200) {
      const data = response.data;
      yield put(setJobTitlesDataSuccess(data));
    } else {
      yield put(setJobTitlesDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setJobTitlesDataFail(error));
  }
}

function* postJobTitlesDataService({ request }) {
  try {
    let requestData = {
      to_insert: request
    }
    const response = yield call(
      postRequest,
      JOBTITLE_POST_API,
      requestData
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Job title created"))
      yield put(setJobTitlesAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setJobTitlesAddDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not create job title"))
    yield put(setJobTitlesAddDataFail(error));
  }
}

function* getJobTitleDataService({ id }) {
  try {
    const response = yield call(
      getDetailRequest,
      JOBTITLE_GET_DETAIL_API + id
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(setJobTitlesGetDataSuccess(newData));
    } else {
      yield put(setJobTitlesGetDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setJobTitlesGetDataFail(error));
  }
}

function* putJobTitlesDataService({ request }) {
  let requestData = {
    to_id: {
      id: request.id
    },
    to_update: request
  }
  try {
    const response = yield call(putRequest, JOBTITLE_PUT_API, requestData);
    if (response.status === 200) {
            yield put(addSuccessAlert("Job title updated"))
      yield put(setJobTitlesUpdateDataSuccess(response.data));
    } else {
            yield put(addErrorAlert(response.response.data.msg))
      yield put(
        setJobTitlesUpdateDataFail(response.response.data)
      );
    }
  } catch (error) {
        yield put(addErrorAlert("Could not update job title"))
    yield put(setJobTitlesUpdateDataFail(error));
  }
}

function* deleteJobTitlesDataService({ request }) {
  try {
    const response = yield call(
      deleteRequest,
      JOBTITLE_DELETE_API,
      request
    );
    if (response.status === 200) {
            yield put(addSuccessAlert("Job title deleted"))
      yield put(setJobTitlesDeleteDataSuccess(response.data));
    } else {
            yield put(addErrorAlert(response.response.data.msg))
      yield put(
        setJobTitlesDeleteDataFail(response.response.data)
      );
    }
  } catch (error) {
        yield put(addErrorAlert("Could not delete job title"))
    yield put(setJobTitlesDeleteDataFail(error));
  }
}

function* postImportJobTitlesDataService({ request }) {
  try {
    const response = yield call(
      postImportRequest,
      JOBTITLE_IMPORT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Jobtitles imported"))
      yield put(setJobTitlesImportDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setJobTitlesImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert('Could not import Jobtitles'))
    yield put(setJobTitlesImportDataFail(error));
  }
}

export function* watchJobTitles() {
  yield takeLatest(
    JOBTITLE_DATA.JOBTITLE_DATA_LOAD,
    getJobTitlesDataService
  );
  yield takeLatest(
    JOBTITLE_ADD_DATA.JOBTITLE_ADD_DATA_LOAD,
    postJobTitlesDataService
  );
  yield takeLatest(
    JOBTITLE_GET_DATA.JOBTITLE_GET_DATA_LOAD,
    getJobTitleDataService
  );
  yield takeLatest(
    JOBTITLE_UPDATE_DATA.JOBTITLE_UPDATE_DATA_LOAD,
    putJobTitlesDataService
  );
  yield takeLatest(
    JOBTITLE_DELETE_DATA.JOBTITLE_DELETE_DATA_LOAD,
    deleteJobTitlesDataService
  );
  yield takeLatest(
    JOBTITLE_IMPORT_DATA.JOBTITLE_IMPORT_DATA_LOAD,
    postImportJobTitlesDataService
  );
  yield takeLatest(
    JOBTITLE_IMPORT_DATA.JOBTITLE_IMPORT_DATA_SUCCESS,
    getJobTitlesDataService
  );
}
