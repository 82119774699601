import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { CANCEL, COMPANY, EMPLOYEE_DEPARTMENT, SAVE } from "../../../shared/constants/Constants";
import { loadCompanyDataRequest } from "../../company/redux/Actions";

const DepartmentForm = ({ department, submit, cancel }) => {
    const validate = (values) => {
        const errors = {};
        if (!values.department_name) {
            errors.department_name = "Required";
        }
        if (!values.company_id) {
            errors.company_id = "Required"
        }
        return errors;
    };
    let dispatch = useDispatch();
    let { companies } = useSelector(store => store.company)
    useEffect(() => {
        if (companies.length === 0) {
            dispatch(loadCompanyDataRequest())
        }
    }, [dispatch])

    const formik = useFormik({});

    useEffect(() => {
        if (department) {
            formik.current.setFieldValue("id", department?.id);
            formik.current.setFieldValue("department_name", department?.department_name);
            formik.current.setFieldValue("company_id", department?.company_id);
        }
    }, [department, formik]);

    return (
        <Formik
            innerRef={formik}
            initialValues={{
                department_name: "",
                company_id: '',
            }}
            validate={validate}
            onSubmit={submit}
        >
            {({ values, setFieldValue }) => (
                <Form className="row g-4">
                    <div className="col-md-6">
                        <label htmlFor="department_name" className="form-label">
                            {EMPLOYEE_DEPARTMENT}<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="department_name"
                            placeholder={"Enter " + EMPLOYEE_DEPARTMENT + " Name"}
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="department_name" />
                        </span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="traffic_reduction" className="form-label">
                            {COMPANY}<span className="error-text">*</span>
                        </label>
                        <FormikSelectField
                            name="company_id"
                            value={values.company_id}
                            onChange={setFieldValue}
                            placeHolder={"Select " + COMPANY}
                        >
                            {companies.map((company) => {
                                return <option value={company.id}>{company.company_name}</option>
                            })
                            }
                        </FormikSelectField>
                        <span className="error-text">
                            <ErrorMessage name="company_id" />
                        </span>
                    </div>
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default DepartmentForm;
