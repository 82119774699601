import { useEffect, useState, Children } from "react";
import Select from "react-select";
import { ErrorMessage } from 'formik'


const FormikSelectField = ({ name, value, onChange, placeholder, disabled = false, children, isClearable, label = null, required = false, hidden = false, isMulti = false }) => {
    let [options, setOptions] = useState([])
    useEffect(() => {
        let newOptions = []
        Children.forEach(children, (child)=>{
            newOptions.push({
                value:child.props.value,
                label:child.props.children
            })
        })
        setOptions(newOptions)
    }, [children])

    let selectedValue;
    if (isMulti && value?.length) {
        selectedValue = []
        for (let entry of value) {
            let newValue = options.find((option) => option.value == entry);
            if (newValue) {
                selectedValue.push(newValue);
            }
        }
    } else {
        selectedValue = options.find((option) => option.value == value) || null
    }


    let select = (
        <Select
            value={selectedValue}
            options={options}
            onChange={
                (val) => {
                    if (isMulti) {
                        onChange(name, val.map((option) => option?.value))
                    } else {
                        onChange(name, val?.value)
                    }
                }
            }
            placeholder={placeholder}
            isDisabled={disabled}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
    if (label) {
        return (
            <div className="col-md-6" hidden={hidden}>
                <label htmlFor={name} className="form-label">
                    {label} {required ? <span className="error-text">*</span> : ''}
                </label>
                {select}
                <span className="error-text">
                    <ErrorMessage name={name} />
                </span>
            </div>
        )
    } else {
        return select
    }
};

export default FormikSelectField