import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadEmployeeRolesUpdateDataRequest } from "../redux/Actions";
import EmployeeRoleMappingForm from "./EmployeeRoleMappingForm";

const EmployeeRoleMappingEdit = ({ handleClose, empRole }) => {
  const dispatch = useDispatch();
  let [submitted, setSubmitted] = useState(false);
  let [isClosing, setIsClosing] = useState(false);

  let { loading, error } = useSelector((state) => state.empRoles);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (submitted && !loading && !isClosing) {
        setIsClosing(true);
        handleClose(true)
    }
}, [loading, dispatch, error, handleClose, isClosing, submitted])

  function submit(values) {
    let request = {
      employee_id: values.employee_id,
      mapping: 
        values.role_module_id.map((values)=>{
          return {
            role_id: Number(values.split(',')[0]),
            group_id: Number(values.split(',')[1])
          }
        })
    }
    for (let entry of request.mapping){
      for (let existingEntry of empRole.data[0]){
        if (existingEntry.role_id == entry.role_id && existingEntry.group_id == entry.group_id ){
          entry.id = existingEntry.id
        }
      }
    }
    dispatch(loadEmployeeRolesUpdateDataRequest(request));
    setSubmitted(true);
  }
  function cancel() {
    handleClose(false);
  }
  return <EmployeeRoleMappingForm submit={submit} cancel={cancel} empRole={empRole} />;
};

export default EmployeeRoleMappingEdit;
