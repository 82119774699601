import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { loadDivisionGetDataRequest, loadDivisionUpdateDataRequest } from "../redux/Actions";
import { Card, Col, Row } from "react-bootstrap";
import { BACKTODETAILS, DIVISION } from '../../../shared/constants/Constants';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router';
import DivisionForm from "./DivisionForm";
import { BACKTOLIST } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";

const DivisionEdit = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let [submitted, setSubmitted] = useState(false);
    let params = useParams();
    let { loading, error } = useSelector(state => state.division)
    let [errorMessage, setErrorMessage] = useState(false);


    useEffect(() => {
        dispatch(loadDivisionGetDataRequest(params.id))
    }, [dispatch, params.id]);
    let divisionStore = useSelector(state => state.division)
    let division = divisionStore.division[0];

    // After submition, the post request is done loading, and there's no errors
    useEffect(() => {
        console.log(error);
        if (submitted && !loading) {
            if (!error) {
                navigate('/division/' + params.id)
            } else {
                setErrorMessage(error?.msg?.toString());
            }
            setSubmitted(false)
        }
    }, [loading, error, submitted])

    function submit(values) {
        dispatch(loadDivisionUpdateDataRequest(values))
        setSubmitted(true)
    }
    function cancel() {
        navigate('/division/' + params.id)
    }
    return (
        <div>
            <Row>
                <Col>
                    <Breadcrumbs
                        children={[
                            { href: '/division', label: DIVISION },
                            { href: '/division/' + params.id, label: params.id },
                            { label: 'edit' },
                        ]}
                    />
                </Col>
                <Col xs={6}>
                    <div className="float-end">
                        <DefaultButton
                            buttonClass={"default-btn-color me-1"}
                            buttonTitle={BACKTODETAILS}
                            actionBlock={() => { navigate("/division/" + params.id);}}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <DivisionForm division={division} submit={submit} cancel={cancel} />
                    </Card.Body>
                </Card>
            </Row>
        </div>
    );
};

export default DivisionEdit;
