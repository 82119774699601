import { takeLatest, put, call } from "redux-saga/effects";
import {
  REGION_DATA,
  REGION_GET_API,
  REGION_ADD_DATA,
  REGION_POST_API,
  REGION_GET_DATA,
  REGION_GET_DETAIL_API,
  REGION_UPDATE_DATA,
  REGION_PUT_API,
  REGION_DELETE_DATA,
  REGION_DELETE_API,
  REGION_GET_ALL_DATA,
  REGION_GET_ALL_API,
  REGION_TRACKER_DELETE_DATA,
  REGION_TRACKER_DELETE_API,
  REGION_IMPORT_DATA,
  REGION_IMPORT_API,
  REGION_TRACKER_IMPORT_DATA,
  REGION_TRACKER_IMPORT_API,
  REGION_GET_MARKETS_API,
  REGION_GET_MARKETS
} from "./Constants";
import {
  setRegionDataSuccess,
  setRegionDataFail,
  setRegionAddDataSuccess,
  setRegionAddDataFail,
  setRegionGetDataSuccess,
  setRegionGetDataFail,
  setRegionUpdateDataSuccess,
  setRegionUpdateDataFail,
  setRegionDeleteDataFail,
  setRegionDeleteDataSuccess,
  setGetRegionTrackerDataSuccess,
  setGetRegionTrackerDataFail,
  setRegionTrackerDeleteDataSuccess,
  setRegionTrackerDeleteDataFail,
  setRegionImportDataSuccess,
  setRegionImportDataFail,
  setRegionTrackerImportDataSuccess,
  setRegionTrackerImportDataFail,
  setRegionGetMarketsSuccess,
  setRegionGetMarketsFail
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
  postImportRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getRegionsDataService() {
  try {
    const regionsResponse = yield call(getRequest, REGION_GET_API);
    const status = regionsResponse.status;
    if (status === 200) {
      const data = regionsResponse.data;
      yield put(setRegionDataSuccess(data));
    } else {
      yield put(setRegionDataFail(regionsResponse.response.data));
    }
  } catch (error) {
    yield put(setRegionDataFail(error));
  }
}

function* postRegionDataService({ request }) {
  try {
    const response = yield call(
      postRequest,
      REGION_POST_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Region created"))
      yield put(setRegionAddDataSuccess(newData));
    } else {
      yield put(setRegionAddDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setRegionAddDataFail(error));
  }
}

function* getRegionDataService({ id }) {
  try {
    const getRegionDataResponse = yield call(
      getDetailRequest,
      REGION_GET_DETAIL_API + id
    );
    const status = getRegionDataResponse.status;
    if (status === 200) {
      const newData = getRegionDataResponse.data;
      yield put(setRegionGetDataSuccess(newData));
    } else {
      yield put(setRegionGetDataFail(getRegionDataResponse.response.data));
    }
  } catch (error) {
    yield put(setRegionGetDataFail(error));
  }
}

function* putRegionDataService({ request }) {
  try {
    const response = yield call(putRequest, REGION_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Region updated"))
      yield put(setRegionUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg));
      yield put(setRegionUpdateDataFail(response.response?.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not update region"));
    yield put(setRegionUpdateDataFail(error));
  }
}

function* deleteRegionDataService({ request }) {
  try {
    const response = yield call(deleteRequest, REGION_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Region deleted"))
      yield put(setRegionDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setRegionDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not delete region"))
    yield put(setRegionDeleteDataFail(error));
  }
}

function* getRegionTrackerDataService() {
  try {
    const trackerResponse = yield call(getRequest, REGION_GET_ALL_API);
    const status = trackerResponse.status;
    if (status === 200) {
      const data = trackerResponse.data;
      yield put(setGetRegionTrackerDataSuccess(data));
    } else {
      yield put(setGetRegionTrackerDataFail(trackerResponse.response.data));
    }
  } catch (error) {
    yield put(setGetRegionTrackerDataFail(error));
  }
}

function* deleteRegionTrackerDataService({ request }) {
  try {
    const response = yield call(deleteRequest, REGION_TRACKER_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert(response.data.msg))
      yield put(setRegionTrackerDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setRegionTrackerDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert('Could not delete region tracker'))
    yield put(setRegionTrackerDeleteDataFail(error));
  }
}

function* postImportRegionDataService({ request }) {
  try {
    const response = yield call(
      postImportRequest,
      REGION_IMPORT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Regions imported"))
      yield put(setRegionImportDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setRegionImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not import regions"))
    yield put(setRegionImportDataFail(error));
  }
}

function* postImportRegionTrackerDataService({ request }) {
  try {
    const response = yield call(
      postImportRequest,
      REGION_TRACKER_IMPORT_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Region trackers imported"))
      yield put(setRegionTrackerImportDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setRegionTrackerImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not import region trackers"))
    yield put(setRegionTrackerImportDataFail(error));
  }
}
function* getRegionMarketsDataService({ id }) {
  try {
    const response = yield call(getRequest, REGION_GET_MARKETS_API + id);
    if (response.status === 200) {
      yield put(setRegionGetMarketsSuccess(response.data))
    } else {
      yield put(addErrorAlert(response.response.data.msg));
      yield put(setRegionGetMarketsFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not load region's markets"));
    yield put(setRegionGetMarketsFail(error));
  }
}

export function* watchRegion() {
  yield takeLatest(REGION_DATA.REGION_DATA_LOAD, getRegionsDataService);
  yield takeLatest(REGION_ADD_DATA.REGION_ADD_DATA_LOAD, postRegionDataService);
  yield takeLatest(REGION_GET_DATA.REGION_GET_DATA_LOAD, getRegionDataService);
  yield takeLatest(REGION_UPDATE_DATA.REGION_UPDATE_DATA_LOAD, putRegionDataService);
  yield takeLatest(REGION_DELETE_DATA.REGION_DELETE_DATA_LOAD, deleteRegionDataService);
  yield takeLatest(REGION_GET_ALL_DATA.REGION_GET_ALL_DATA_LOAD, getRegionTrackerDataService);
  yield takeLatest(REGION_TRACKER_DELETE_DATA.REGION_TRACKER_DELETE_DATA_LOAD, deleteRegionTrackerDataService);
  yield takeLatest(REGION_IMPORT_DATA.REGION_IMPORT_DATA_LOAD, postImportRegionDataService);
  yield takeLatest(REGION_TRACKER_IMPORT_DATA.REGION_TRACKER_IMPORT_DATA_LOAD, postImportRegionTrackerDataService);
  //Reload regions after successfully importing
  yield takeLatest(
    REGION_IMPORT_DATA.REGION_IMPORT_DATA_SUCCESS,
    getRegionsDataService
  );
  yield takeLatest(
    REGION_TRACKER_IMPORT_DATA.REGION_TRACKER_IMPORT_DATA_SUCCESS,
    getRegionTrackerDataService
  );
  yield takeLatest(REGION_GET_MARKETS.REGION_GET_MARKETS_LOAD, getRegionMarketsDataService);
}
