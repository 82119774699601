import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import DefaultButton from '../../../shared/components/DefaultButton'
import ListView from '../../../shared/components/listview/ListView'
import Modalpopup from '../../../shared/components/Modalpopup'
import { MARKET, CREATENEW, IMPORTMARKET, ALL_COMPANIES } from '../../../shared/constants/Constants'
import { loadMarketDataRequest, loadMarketImportDataRequest } from '../redux/Actions'
import MarketAdd from './MarketAdd'
import { loadCompanyDataRequest } from '../../company/redux/Actions'
import ImportModal from '../../../shared/components/ImportModal'
import Permissions from '../../../shared/components/Permissions'

const Market = () => {

    const [showAddModal, setShowAddModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { loading, error, markets } = useSelector(state => state.market);
    let { companies } = useSelector((state) => state.company);
    let [selectedCompanyId, setSelectedCompanyId] = useState(JSON.parse(localStorage.getItem("selectedId")))
    let [query] = useSearchParams();
    useEffect(() => {
        if (query.get('filter') == 'none') {
            setSelectedCompanyId(0)
        } 
    }, [])
    let [marketData, setMarketData] = useState([])

    useEffect(() => {
        dispatch(loadMarketDataRequest());
        dispatch(loadCompanyDataRequest());
    }, [dispatch])

    function closeModal(reload) {
        setShowAddModal(false);
        if (reload) {
            dispatch(loadMarketDataRequest());
        }
    }

    const marketColumns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: 'District Name',
            field: 'market_name',
            type: 'text'
        }, {
            display_name: 'Managers',
            field: 'manager',
            type: 'text'
        },
        {
            display_name: "Covering",
            field: "covering",
            type: "icon",
            icon: "checkbox",
            width: 100,
        },
        {
            display_name: 'Region Name',
            field: 'region_name',
            type: 'text',
            sort: 'desc'
        }, {
            display_name: 'Division Name',
            field: 'division_name',
            type: 'text'
        }, {
            display_name: 'Ulti District Code',
            field: 'ulti_market_code',
            type: 'text'
        }, {
            display_name: 'Start Date',
            field: 'start_date',
            type: 'date'
        }, {
            display_name: 'End Date',
            field: 'end_date',
            type: 'date'
        }, {
            display_name: 'Status',
            field: 'active',
            type: 'active'
        }
    ];

    useEffect(() => {
        if (selectedCompanyId === 0) {
            setMarketData([...markets])
        } else {
            setMarketData([...markets].filter(
                (market) => {
                    return market.company_id === selectedCompanyId
                }
            ))
        }
    }, [selectedCompanyId, markets])

    const [showImportModal, setShowImportModal] = useState(false);

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Market";
    let permission = Permissions(emp_no, module_name);
    localStorage.setItem("selectedId", selectedCompanyId)

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { label: MARKET }
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission ? (
                        <>
                            <DefaultButton
                                buttonClass={"default-btn-color me-1"}
                                buttonTitle={'Import'}
                                actionBlock={() => { setShowImportModal(true) }}
                            />
                            <DefaultButton buttonClass={'btn btn-primary default-btn-color'} buttonTitle={CREATENEW} actionBlock={() => { setShowAddModal(true) }}></DefaultButton>
                        </>
                    ) : (<></>)}
                </Col>
            </Row>
            <Row>
                <Card className='table-card'>
                    <Card.Body>
                        <select value={selectedCompanyId}  style={{ width: 250 }} className="float-end form-control" onChange={(e) => { setSelectedCompanyId(Number.parseInt(e.target.value)) }}>
                            <option value={0}>{ALL_COMPANIES}</option>
                            {companies.map((company) =>
                                <option key={company.id} value={company.id} >{company.company_name}</option>
                            )
                            }
                        </select>
                        <ListView
                            exportTitle="Districts"
                            columnNames={marketColumns}
                            rowData={marketData}
                            shouldSetNextPrevIds
                            rowClick={(row) => {
                                navigate('./' + row.data['id'])
                            }}
                            tableHeight = '70vh'
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup actionTitle="Add District" show={showAddModal} handleClose={closeModal}>
                <MarketAdd handleClose={closeModal} />
            </Modalpopup>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName={"market"}
                modalTitle={IMPORTMARKET}
                importAction={loadMarketImportDataRequest}
            />
        </>
    )
}

export default Market
