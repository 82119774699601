import { takeLatest, put, call } from "redux-saga/effects";
import {
  PRODUCT_DATA,
  PRODUCT_GET_API,
  ACCESSORY_DATA,
  ACCESSORY_GET_API,
  ACCESSORY_COUNT,
  ACCESSORY_COUNT_API,
  PRODUCT_IMPORT_API,
  PRODUCT_IMPORT_DATA,
  ACCESSORY_IMPORT_DATA,
  ACCESSORY_IMPORT_API,
  PRODUCT_ADD_DATA,
  PRODUCT_POST_API,
  PRODUCT_GET_DATA,
  PRODUCT_GET_DETAIL_API,
  PRODUCT_UPDATE_DATA,
  PRODUCT_PUT_API,
  PRODUCT_DELETE_DATA,
  PRODUCT_DELETE_API,
  PRODUCT_COUNT_API,
  PRODUCT_COUNT,
  CATEGORY_PRODUCT_DATA,
  CATEGORY_PRODUCT_GET_API,
  CATEGORY_PRODUCT_ADD_DATA,
  CATEGORY_PRODUCT_POST_API,
  CATEGORY_PRODUCT_DELETE_DATA,
  CATEGORY_PRODUCT_DELETE_API,
  PRODUCT_TYPE_DATA,
  PRODUCT_TYPE_GET_API,
  ACCOUNT_TYPE_DATA,
  ACCOUNT_TYPE_GET_API,
  SUB_CATEGORY_DATA,
  SUB_CATEGORY_GET_API,
  CATEGORY_DATA,
  CATEGORY_GET_API
} from "./Constants";
import {
  setProductDataSuccess,
  setProductDataFail,
  setProductRowCountSuccess,
  setProductRowCountFail,
  setAccessoryDataSuccess,
  setAccessoryDataFail,
  setAccessoryRowCountSuccess,
  setAccessoryRowCountFail,
  setProductImportDataSuccess,
  setProductImportDataFail,
  setAccessoryImportDataSuccess,
  setAccessoryImportDataFail,
  setProductAddDataFail,
  setProductAddDataSuccess,
  setProductGetDataFail,
  setProductGetDataSuccess,
  setProductDeleteDataFail,
  setProductDeleteDataSuccess,
  setProductUpdateDataFail,
  setProductUpdateDataSuccess,
  setCategoryProductDataSuccess,
  setCategoryProductDataFail,
  setCategoryProductAddDataSuccess,
  setCategoryProductAddDataFail,
  setCategoryProductDeleteDataSuccess,
  setCategoryProductDeleteDataFail,
  setProductTypeDataSuccess,
  setProductTypeDataFail,
  setAccountTypeDataSuccess,
  setAccountTypeDataFail,
  setSubCategoryDataSuccess,
  setSubCategoryDataFail,
  setCategoryDataSuccess,
  setCategoryDataFail
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
  postImportRequest,
} from "../../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../../alert/Actions";

function* getProductsDataService({ filters, page, sort }) {
  try {
    const response = yield call(postRequest, PRODUCT_GET_API, { filter: filters, page, sort });
    const status = response.status;
    if (status === 200) {
      const data = response.data;
      yield put(setProductDataSuccess(data.data, data.rowCount));
    } else {
      yield put(setProductDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setProductDataFail(error));
  }
}

function* getProductsCountService({ filters }) {
  try {
    const response = yield call(postRequest, PRODUCT_COUNT_API, { filter: filters });
    const status = response.status;
    if (status === 200) {
      const data = response.data;
      yield put(setProductRowCountSuccess(data.rowCount));
    } else {
      yield put(setProductRowCountFail(response.response.data));
    }
  } catch (error) {
    yield put(setProductRowCountFail(error));
  }
}

function* getAccessoryDataService({ filters, page, sort }) {
  try {
    const response = yield call(postRequest, ACCESSORY_GET_API, { filter: filters, page, sort });
    const status = response.status;
    if (status === 200) {
      const data = response.data;
      yield put(setAccessoryDataSuccess(data.data, data.rowCount));
    } else {
      yield put(setAccessoryDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setAccessoryDataFail(error));
  }
}

function* getAccessoryCountService({ filters }) {
  try {
    const response = yield call(postRequest, ACCESSORY_COUNT_API, { filter: filters });
    const status = response.status;
    if (status === 200) {
      const data = response.data;
      yield put(setAccessoryRowCountSuccess(data.rowCount));
    } else {
      yield put(setAccessoryRowCountFail(response.response.data));
    }
  } catch (error) {
    yield put(setAccessoryRowCountFail(error));
  }
}

function* postImportProductDataService({ request, url }) {
  try {
    const response = yield call(
      postImportRequest,
      url,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      let nU = response.data.msg.not_updated;
      let totalCount = response.data.msg.total_not_updated_count;
      let createdData = response.data.msg.created_data;
      let finalMsg = "Not Updated: " + JSON.stringify(nU) + ", Total Not Updated Count: " + totalCount + ", Created Data: " + JSON.stringify(createdData);
      yield put(addSuccessAlert(finalMsg))
      yield put(setProductImportDataSuccess(newData));
    } else {
      let aData = response.response.data.missing_account_type_data;
      let cData = response.response.data.missing_category_data;
      let pData = response.response.data.missing_product_type_data;
      let aD = '-';
      let cD = '-';
      let pD = '-';
      if (aData.length > 0) {
        aD = aData.toString()
      }
      if (cData.length > 0) {
        cD = cData.toString();
      }
      if (pData.length > 0) {
        pD = pData.toString();
      }
      let finalMsg = "Missing account type data: " + aD + ", Missing Category Data: " + cD + ", Missing Product Type Data: " + pD;
      yield put(addErrorAlert(finalMsg))
      yield put(setProductImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setProductImportDataFail(error));
  }
}

function* postImportAccessoryDataService({ request, url }) {
  try {
    const response = yield call(
      postImportRequest,
      url,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      let nU = response.data.msg.not_updated;
      let totalCount = response.data.msg.total_not_updated_count;
      let createdData = response.data.msg.created_data;
      let finalMsg = "Not Updated: " + JSON.stringify(nU) + ", Total Not Updated Count: " + totalCount + ", Created Data: " + JSON.stringify(createdData);
      yield put(addSuccessAlert(finalMsg))
      yield put(setAccessoryImportDataSuccess(newData));
    } else {
      let aData = response.response.data.missing_account_type_data;
      let cData = response.response.data.missing_category_data;
      let pData = response.response.data.missing_product_type_data;
      let aD = '-';
      let cD = '-';
      let pD = '-';
      if (aData.length > 0) {
        aD = aData.toString()
      }
      if (cData.length > 0) {
        cD = cData.toString();
      }
      if (pData.length > 0) {
        pD = pData.toString();
      }
      let finalMsg = "Missing account type data: " + aD + ", Missing Category Data: " + cD + ", Missing Product Type Data: " + pD;
      yield put(addErrorAlert(finalMsg))
      yield put(setAccessoryImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setAccessoryImportDataFail(error));
  }
}


function* postProductDataService({ request }) {
  const url = PRODUCT_POST_API + '/product';
  try {
    const response = yield call(
      postRequest,
      url,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert(JSON.stringify(newData.msg)))
      yield put(setProductAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setProductAddDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setProductAddDataFail(error));
  }
}


function* putProductDataService({ request, id }) {
  try {
    const putURL = PRODUCT_PUT_API + id
    const response = yield call(
      putRequest,
      putURL,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert('Updated Successfully'))
      yield put(setProductUpdateDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setProductUpdateDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setProductUpdateDataFail(error));
  }
}

function* deleteProductDataService({ request }) {
  try {
    const response = yield call(
      deleteRequest,
      PRODUCT_DELETE_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Product deactivated successfully"))
      yield put(setProductDeleteDataSuccess(newData));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setProductDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert(error))
    yield put(setProductDeleteDataFail(error));
  }
}

function* getProductDataService({ id }) {
  try {
    const getProductDataResponse = yield call(
      getDetailRequest,
      PRODUCT_GET_DETAIL_API + id
    );
    const status = getProductDataResponse.status;
    if (status === 200) {
      const newData = getProductDataResponse.data;
      yield put(setProductGetDataSuccess(newData));
    } else {
      yield put(setProductGetDataFail(getProductDataResponse.response.data));
    }
  } catch (error) {
    yield put(setProductGetDataFail(error));
  }
}

function* getCategoryProductDataService({id}) {
  const url = CATEGORY_PRODUCT_GET_API + id;
  try {
      const response = yield call(getRequest, url);
      if (response.status === 200) {
          const data = response.data;
          yield put(setCategoryProductDataSuccess(data));
      } else {
          yield put(addErrorAlert(response.response.data.msg))
          yield put(setCategoryProductDataFail(response.response.data));
      }
  } catch (error) {
      yield put(addErrorAlert("Error retrieving category product"))
      yield put(setCategoryProductDataFail(error));
  }
}

function* postCategoryProductDataService({ request }) {
  try {
      const response = yield call(postRequest, CATEGORY_PRODUCT_POST_API, request);
      if (response.status === 200) {
          const newData = response.data;
          yield put(addSuccessAlert("Category Product created"))
          yield put(setCategoryProductAddDataSuccess(newData));
      } else {
          yield put(addErrorAlert(response.response.data.msg))
          yield put(setCategoryProductAddDataFail(response.response.data));
      }
  } catch (error) {
      yield put(addErrorAlert("Error adding category product"))
      yield put(setCategoryProductAddDataFail(error));
  }
}

function* deleteCategoryProductDataService({ request }) {
  try {
      const response = yield call(deleteRequest, CATEGORY_PRODUCT_DELETE_API, request);
      if (response.status === 200) {
          yield put(addSuccessAlert(response.data.msg))
          yield put(setCategoryProductDeleteDataSuccess(response.data));
      } else {
          yield put(addErrorAlert(response.response.data.msg))
          yield put(setCategoryProductDeleteDataFail(response.response.data));
      }
  } catch (error) {
      yield put(addErrorAlert(error))
      yield put(setCategoryProductDeleteDataFail(error));
  }
}

function* getProductTypeDataService() {
  try {
      const response = yield call(getRequest, PRODUCT_TYPE_GET_API);
      if (response.status === 200) {
          const data = response.data;
          yield put(setProductTypeDataSuccess(data));
      } else {
          yield put(addErrorAlert(response.response.data.msg))
          yield put(setProductTypeDataFail(response.response.data));
      }
  } catch (error) {
      yield put(addErrorAlert("Error retrieving data"))
      yield put(setProductTypeDataFail(error));
  }
}

function* getAccountTypeDataService() {
  try {
      const response = yield call(getRequest, ACCOUNT_TYPE_GET_API);
      if (response.status === 200) {
          const data = response.data;
          yield put(setAccountTypeDataSuccess(data));
      } else {
          yield put(addErrorAlert(response.response.data.msg))
          yield put(setAccountTypeDataFail(response.response.data));
      }
  } catch (error) {
      yield put(addErrorAlert("Error retrieving data"))
      yield put(setAccountTypeDataFail(error));
  }
}

function* getSubCategoryDataService() {
  try {
      const response = yield call(getRequest, SUB_CATEGORY_GET_API);
      if (response.status === 200) {
          const data = response.data;
          yield put(setSubCategoryDataSuccess(data));
      } else {
          yield put(addErrorAlert(response.response.data.msg))
          yield put(setSubCategoryDataFail(response.response.data));
      }
  } catch (error) {
      yield put(addErrorAlert("Error retrieving data"))
      yield put(setSubCategoryDataFail(error));
  }
}

function* getCategoryDataService() {
  try {
      const response = yield call(getRequest, CATEGORY_GET_API);
      if (response.status === 200) {
          const data = response.data;
          yield put(setCategoryDataSuccess(data));
      } else {
          yield put(addErrorAlert(response.response.data.msg))
          yield put(setCategoryDataFail(response.response.data));
      }
  } catch (error) {
      yield put(addErrorAlert("Error retrieving data"))
      yield put(setCategoryDataFail(error));
  }
}


export function* watchProducts() {
  yield takeLatest(PRODUCT_DATA.PRODUCT_DATA_LOAD, getProductsDataService);
  yield takeLatest(PRODUCT_COUNT.PRODUCT_COUNT_LOAD, getProductsCountService);

  yield takeLatest(ACCESSORY_DATA.ACCESSORY_DATA_LOAD, getAccessoryDataService);
  yield takeLatest(ACCESSORY_COUNT.ACCESSORY_COUNT_LOAD, getAccessoryCountService);

  yield takeLatest(
    PRODUCT_IMPORT_DATA.PRODUCT_IMPORT_DATA_LOAD,
    postImportProductDataService
  );
  yield takeLatest(
    ACCESSORY_IMPORT_DATA.ACCESSORY_IMPORT_DATA_LOAD,
    postImportAccessoryDataService
  );

  yield takeLatest(
    PRODUCT_ADD_DATA.PRODUCT_ADD_DATA_LOAD,
    postProductDataService
  );
  yield takeLatest(
    PRODUCT_GET_DATA.PRODUCT_GET_DATA_LOAD,
    getProductDataService
  );
  yield takeLatest(
    PRODUCT_UPDATE_DATA.PRODUCT_UPDATE_DATA_LOAD,
    putProductDataService
  );
  yield takeLatest(
    PRODUCT_DELETE_DATA.PRODUCT_DELETE_DATA_LOAD,
    deleteProductDataService
  );
  // //Reload products after successfully importing
  // yield takeLatest(PRODUCT_IMPORT_DATA.PRODUCT_IMPORT_DATA_SUCCESS,
  //   getProductsDataService
  // );
  
  yield takeLatest(CATEGORY_PRODUCT_DATA.CATEGORY_PRODUCT_DATA_LOAD, getCategoryProductDataService);
  yield takeLatest(CATEGORY_PRODUCT_ADD_DATA.CATEGORY_PRODUCT_ADD_DATA_LOAD, postCategoryProductDataService);
  yield takeLatest(CATEGORY_PRODUCT_DELETE_DATA.CATEGORY_PRODUCT_DELETE_DATA_LOAD, deleteCategoryProductDataService); 
  yield takeLatest(PRODUCT_TYPE_DATA.PRODUCT_TYPE_DATA_LOAD, getProductTypeDataService);
  yield takeLatest(ACCOUNT_TYPE_DATA.ACCOUNT_TYPE_DATA_LOAD, getAccountTypeDataService);
  yield takeLatest(SUB_CATEGORY_DATA.SUB_CATEGORY_DATA_LOAD, getSubCategoryDataService);
  yield takeLatest(CATEGORY_DATA.CATEGORY_DATA_LOAD, getCategoryDataService);
}
