import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import RegionalSupport from "./RegionalSupport";
import GeneralInfo from "./GeneralInfo";
import DynamicTable from "./DynamicTable";
import { loadSearchSAPGetDataRequest } from "../redux/Actions";
import Loader from "../../../shared/components/Loader";
import jsonData from "./data.json";


const SearchSAP = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [flag, setFlag] = useState(false);
    const [sap, setSAP] = useState();
    const [storeData, setstoreData] = useState([]);
    const [currentTab, setCurrentTab] = useState('General Info');

    let { searchsap, error, loading } = useSelector((state) => state.searchsap);

    
    // useEffect(() => {
    //     if (storeData) {
    //         setstoreData([...storeData]);
    //     }
    // }, [storeData])
    
    // useEffect(() => {
    //     dispatch(loadSearchSAPGetDataRequest('287E'))
    // },[dispatch])

    const stores = [];

    const handleSAP = () => {  
        setFlag(true);
        setCurrentTab('General Info')
        dispatch(loadSearchSAPGetDataRequest(sap))
    }

    console.log("dispatch",dispatch)

    // let [data, setData] = useState([])
    // useEffect(() => {
    //     setData([...searchsap])
    // }, [searchsap])

    console.log(storeData)
    //console.log("sm", jsonData?.region_support);

    // Object.keys(jsonData?.region_support).map((key) => {
    //     console.log("key::", key);
    //     console.log("data::", jsonData?.region_support[key]);
    // });

    // {Object.keys(jsonData?.region_support).map((key) => (
    //     console.log('1', key)
    // ))}
    console.log(storeData?.general_info)
    console.log(storeData?.network_columns)

    let tabs = {
        "General Info": <GeneralInfo info={searchsap?.general_info} />,
        'Network and Phones':  <DynamicTable info={searchsap?.network?.data}  columns = {searchsap?.network?.columns} />,
        'Printers': <DynamicTable info={searchsap?.printer?.data}  columns ={searchsap?.printer?.columns} />,
        'Camera System': <DynamicTable info={searchsap?.camera?.data}  columns ={searchsap?.camera?.columns} />,
        'Other IT Devices': <DynamicTable info={searchsap?.other_devices?.data}  columns ={searchsap?.other_devices?.columns} />,
        'Regional Support': <RegionalSupport info={searchsap?.region_support} />,
    };
    console.log("searchsap", error)

    return (
        <>
            <Row>
                <Col>
                    <Breadcrumbs
                        children={[
                            { label: "Search SAP" },
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col>
                <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Enter SAP"
                    value={sap}
                    onChange={(e) => setSAP(e.target.value)}
                />
                </Col>
                <Col>
                <button type="button" class="btn btn-primary mb-3" onClick={handleSAP}>
                    Submit
                </button>
                </Col>
                <Col></Col>
            </Row>
            {flag && (
            <Row>
                
                {error ? (
                    <div className="alert alert-danger text-center mt-5"  role="alert" >
                    <p className="mb-1 "> {error.msg} </p>
                    </div>
                ) : (
                    <>
                    <Col>                    
                        {Object.keys(tabs).map(tab =>
                            <button className={currentTab === tab ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTab(tab) }}>{tab}</button>
                        )}
                    </Col>
                    <Card>
                        <Card.Body>
                            {tabs[currentTab]}
                        </Card.Body>
                    </Card>
                    </>
                )}
            </Row>
            )}
        </>
    );
};
 
export default SearchSAP;
