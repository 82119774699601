import {
  DASHBOARD_DATA, DASHBOARD_GET_RANGE, DASHBOARD_GET_STORE, DASHBOARD_GET_STORE_METRICS
} from "./Constants";

const initialState = {
  loading: false,
  error: null,
  dashboardCount: [],
  empCount: [],
  storeCount: [],
  storeMetrics: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_DATA.DASHBOARD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        dashboardCount: [],
      };
    case DASHBOARD_DATA.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardCount: action.dashboardCount,
        error: null,
      };
    case DASHBOARD_DATA.DASHBOARD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        dashboardCount: [],
      };
    case DASHBOARD_GET_RANGE.DASHBOARD_GET_RANGE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        empCount: []
      }
    case DASHBOARD_GET_RANGE.DASHBOARD_GET_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        empCount: action.empCount
      }
    case DASHBOARD_GET_RANGE.DASHBOARD_GET_RANGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case DASHBOARD_GET_STORE.DASHBOARD_GET_STORE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        storeCount: []
      }
    case DASHBOARD_GET_STORE.DASHBOARD_GET_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        storeCount: action.storeCount
      }
    case DASHBOARD_GET_STORE.DASHBOARD_GET_STORE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case DASHBOARD_GET_STORE_METRICS.DASHBOARD_GET_STORE_METRICS_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case DASHBOARD_GET_STORE_METRICS.DASHBOARD_GET_STORE_METRICS_SUCCESS:
      return {
        ...state,
        loading: false,
        storeMetrics: action.storeMetrics,
      }
    case DASHBOARD_GET_STORE_METRICS.DASHBOARD_GET_STORE_METRICS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
