import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadCompanyAddDataRequest } from "../redux/Actions";
import CompanyForm from "./CompanyForm";

const CompanyAdd = ({ handleClose }) => {
	const dispatch = useDispatch();
	let { error, loading } = useSelector(state => state.company)
	let [shouldCloseModal, setShouldCloseModal] = useState(false);
	let [errorMessage, setErrorMessage] = useState(false);


	// After submition, the post request is done loading, and there's no errors
	useEffect(() => {
		console.log(error);
		if (shouldCloseModal && !loading) {
			if (!error) {
				handleClose(true)
			} else {
				setErrorMessage(error?.msg?.toString());
			}
			setShouldCloseModal(false)
		}
	}, [loading, error, shouldCloseModal])
	function submit(values) {
		dispatch(loadCompanyAddDataRequest(values))
		setShouldCloseModal(true);
	}
	function cancel() {
		handleClose(false);
	}
	return <>
		<FormError>
			{errorMessage}
		</FormError>
		<CompanyForm submit={submit} cancel={cancel} />
	</>
};

export default CompanyAdd;
