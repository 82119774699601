import {
    PT_DATA,
    PT_GET_DATA,
    PT_ADD_DATA,
    PT_UPDATE_DATA,
    PT_DELETE_DATA,
    PTYPE_DATA,
    ATYPE_DATA,
    LTYPE_DATA
} from './Constants'


//For listing
const loadPTDataRequest = () => ({
    type: PT_DATA.PT_DATA_LOAD,
});
const setPTDataSuccess = (productTables) => ({
    type: PT_DATA.PT_DATA_SUCCESS,
    productTables,
});
const setPTDataFail = (error) => ({
    type: PT_DATA.PT_DATA_FAIL,
    error,
});

//for details
const loadPTGetDataRequest = (id) => ({
    type: PT_GET_DATA.PT_GET_DATA_LOAD,
    id,
});
const setPTGetDataSuccess = (response) => ({
    type: PT_GET_DATA.PT_GET_DATA_SUCCESS,
    empStatus: response.empStatus[0],
});
const setPTGetDataFail = (error) => ({
    type: PT_GET_DATA.PT_GET_DATA_FAIL,
    error,
});

//For add PT
const loadPTAddDataRequest = (request, url) => ({
    type: PT_ADD_DATA.PT_ADD_DATA_LOAD,
    request,
    url
});
const setPTAddDataSuccess = (productTable) => ({
    type: PT_ADD_DATA.PT_ADD_DATA_SUCCESS,
    productTable,
});
const setPTAddDataFail = (error) => ({
    type: PT_ADD_DATA.PT_ADD_DATA_FAIL,
    error,
});

//update PT
const loadPTUpdateDataRequest = (request, url) => ({
    type: PT_UPDATE_DATA.PT_UPDATE_DATA_LOAD,
    request,
    url
});
const setPTUpdateDataSuccess = (productTable) => ({
    type: PT_UPDATE_DATA.PT_UPDATE_DATA_SUCCESS,
    productTable,
});
const setPTUpdateDataFail = (error) => ({
    type: PT_UPDATE_DATA.PT_UPDATE_DATA_FAIL,
    error,
});

//Delete PT
const loadPTDeleteDataRequest = (request) => ({
    type: PT_DELETE_DATA.PT_DELETE_DATA_LOAD,
    request,
});
const setPTDeleteDataSuccess = (productTable) => ({
    type: PT_DELETE_DATA.PT_DELETE_DATA_SUCCESS,
    productTable,
});
const setPTDeleteDataFail = (error) => ({
    type: PT_DELETE_DATA.PT_DELETE_DATA_FAIL,
    error,
});

//For ptype listing
const loadPTypeDataRequest = () => ({
    type: PTYPE_DATA.PTYPE_DATA_LOAD,
});
const setPTypeDataSuccess = (pTypes) => ({
    type: PTYPE_DATA.PTYPE_DATA_SUCCESS,
    pTypes,
});
const setPTypeDataFail = (error) => ({
    type: PTYPE_DATA.PTYPE_DATA_FAIL,
    error,
});

//For Atype listing
const loadATypeDataRequest = () => ({
    type: ATYPE_DATA.ATYPE_DATA_LOAD,
});
const setATypeDataSuccess = (aTypes) => ({
    type: ATYPE_DATA.ATYPE_DATA_SUCCESS,
    aTypes,
});
const setATypeDataFail = (error) => ({
    type: ATYPE_DATA.ATYPE_DATA_FAIL,
    error,
});

//For Ltype listing
const loadLTypeDataRequest = () => ({
    type: LTYPE_DATA.LTYPE_DATA_LOAD,
});
const setLTypeDataSuccess = (lTypes) => ({
    type: LTYPE_DATA.LTYPE_DATA_SUCCESS,
    lTypes,
});
const setLTypeDataFail = (error) => ({
    type: LTYPE_DATA.LTYPE_DATA_FAIL,
    error,
});


export {
    loadPTDataRequest, setPTDataSuccess, setPTDataFail,
    loadPTGetDataRequest, setPTGetDataSuccess, setPTGetDataFail,
    loadPTAddDataRequest, setPTAddDataSuccess, setPTAddDataFail,
    loadPTUpdateDataRequest, setPTUpdateDataSuccess, setPTUpdateDataFail,
    loadPTDeleteDataRequest, setPTDeleteDataSuccess, setPTDeleteDataFail,
    loadPTypeDataRequest, setPTypeDataSuccess, setPTypeDataFail,
    loadATypeDataRequest, setATypeDataSuccess, setATypeDataFail,
    loadLTypeDataRequest, setLTypeDataSuccess, setLTypeDataFail
}