import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadTermReasonUpdateDataRequest } from "../redux/Actions";
import TermReasonForm from "./TermReasonForm";

const TermReasonEdit = ({ handleClose, termReason }) => {
  const dispatch = useDispatch();
  let [shouldCloseModal, setShouldCloseModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState(false);

  let { loading, error } = useSelector((state) => state.termReason);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true);
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false);
    }
  }, [loading, error, shouldCloseModal]);

  function submit(values) {
    dispatch(loadTermReasonUpdateDataRequest(values));
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return <>
    <FormError>
      {errorMessage}
    </FormError>
    <TermReasonForm submit={submit} cancel={cancel} termReason={termReason} />;
  </>
};

export default TermReasonEdit;
