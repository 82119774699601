import { takeLatest, put, call } from "redux-saga/effects";
import {
  FORM_ALL_DATA,
  FORM_ALL_GET_API,
  FORM_DATA,
  FORM_GET_API,  
  FORM_GET_DATA,
  FORM_GET_DETAIL_API,  
  FORM_GET_DATA_BY_SUB_ID,
  FORM_GET_BY_SUB_ID_DETAIL_API,
  FORM_ADD_DATA,
  FORM_ADD_API,
  FORM_EDIT_DATA,
  FORM_EDIT_API,
  FORM_DELETE_DATA,
  FORM_DELETE_API,
  FORM_IMPORT_DATA,
  FORM_IMPORT_API,
} from "./Constants";
import {
  setFormAllDataSuccess,
  setFormAllDataFail,  
  setFormDataSuccess,
  setFormDataFail,  
  setFormGetDataSuccess,
  setFormGetDataFail,  
  setFormGetDataBySubIDSuccess,
  setFormGetDataBySubIDFail  ,
  setFormAddDataSuccess,
  setFormAddDataFail,
  setFormEditDataSuccess,
  setFormEditDataFail,
  setFormDeleteDataSuccess,
  setFormDeleteDataFail,
  setFormAImportDataSuccess,
  setFormImportDataFail
} from "./Actions";
import {
  getRequest, 
  getDetailRequest,   
  postRequest,
  putRequest,
  deleteRequest
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getFormsAllDataService() {
  try {
    const formsResponse = yield call(getRequest, FORM_ALL_GET_API);
    const status = formsResponse.status;
    if (status === 200) {
      const data = formsResponse.data;
      yield put(setFormAllDataSuccess(data));
    } else {
      yield put(setFormAllDataFail(formsResponse.response.data));
    }
  } catch (error) {
    yield put(setFormAllDataFail(error));
  }
}

function* getFormsDataService() {
  try {
    const formsResponse = yield call(getRequest, FORM_GET_API);
    const status = formsResponse.status;
    if (status === 200) {
      const data = formsResponse.data;
      yield put(setFormDataSuccess(data));
    } else {
      yield put(setFormDataFail(formsResponse.response.data));
    }
  } catch (error) {
    yield put(setFormDataFail(error));
  }
}

function* getFormDataService({ id }) {
  try {
    const getFormDataResponse = yield call(
      getDetailRequest,
      FORM_GET_DETAIL_API + id
    );
    const status = getFormDataResponse.status;
    if (status === 200) {
      const newData = getFormDataResponse.data;
      yield put(setFormGetDataSuccess(newData));
    } else {
      yield put(setFormGetDataFail(getFormDataResponse.response.data));
    }
  } catch (error) {
    yield put(setFormGetDataFail(error));
  }
}


function* getFormDataBySubIdService({ id }) {
  try {
    console.log("id", id)
    const getFormDataResponse = yield call(      getDetailRequest,     FORM_GET_BY_SUB_ID_DETAIL_API + id    );
    const status = getFormDataResponse.status;
    if (status === 200) {
      const newData = getFormDataResponse.data;
      yield put(setFormGetDataBySubIDSuccess(newData));
    } else {
      yield put(setFormGetDataBySubIDFail(getFormDataResponse.response.data));
    }
  } catch (error) {
    yield put(setFormGetDataBySubIDFail(error));
  }
}

function* postFormDataService({ request }) {
  try {
    const response = yield call(postRequest, FORM_ADD_API, request);
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Data created successully"))
      yield put(setFormAddDataSuccess(newData));
    } else {
      yield put(setFormAddDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setFormAddDataFail(error));
  }
}

function* putFormDataService({ request }) {
  try {
    //let dataRequest = { "to_id": { "id": request.id }, "to_update": request }
    const response = yield call(putRequest, FORM_EDIT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Updated successfully"));
      yield put(setFormEditDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg));
      yield put(setFormEditDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not update"));
    yield put(setFormEditDataFail(error));
  }
}

function* deleteFormDataService({ request }) {
  try {
    console.log("delete request", request)
    const response = yield call(deleteRequest, FORM_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Data deleted successfully"))
      yield put(setFormDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setFormDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Could not delete data"))
    yield put(setFormDeleteDataFail(error));
  }
}

function* postFormImportDataService({ request }) {
  try {
    console.log("request form import API", request )
    const response = yield call(postRequest, FORM_IMPORT_API, request);
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Data created successully"))
      yield put(setFormAImportDataSuccess(newData));
    } else {
      yield put(setFormImportDataFail(response.response.data));
    }
  } catch (error) {
    yield put(setFormImportDataFail(error));
  }
}


export function* watchDynamicForm() {
  
  yield takeLatest(FORM_ALL_DATA.FORM_ALL_DATA_LOAD, getFormsAllDataService);  
  yield takeLatest(FORM_DATA.FORM_DATA_LOAD, getFormsDataService);  
  yield takeLatest(    FORM_GET_DATA.FORM_GET_DATA_LOAD,    getFormDataService  );    
  yield takeLatest(    FORM_GET_DATA_BY_SUB_ID.FORM_GET_DATA_BY_SUB_ID_LOAD,    getFormDataBySubIdService  );
  yield takeLatest(    FORM_ADD_DATA.FORM_ADD_DATA_LOAD,    postFormDataService  );
  yield takeLatest(    FORM_EDIT_DATA.FORM_EDIT_DATA_LOAD,    putFormDataService  );
  yield takeLatest(    FORM_DELETE_DATA.FORM_DELETE_DATA_LOAD,    deleteFormDataService  );
  yield takeLatest(    FORM_IMPORT_DATA.FORM_IMPORT_DATA_LOAD,    postFormImportDataService  );
}
