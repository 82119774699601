import { takeLatest, put, call } from "redux-saga/effects";
import {
    ROLE_MODULE_DATA, ROLE_MODULE_GET_API,
    ROLE_MODULE_GET_DATA, ROLE_MODULE_GET_DETAIL_API,
    ROLE_MODULE_ADD_DATA, ROLE_MODULE_POST_API,
    ROLE_MODULE_UPDATE_DATA, ROLE_MODULE_PUT_API,
    ROLE_MODULE_DELETE_DATA, ROLE_MODULE_DELETE_API,
} from './Constants'
import {
    setRoleModulesDataSuccess, setRoleModulesDataFail,
    setRoleModulesGetDataSuccess, setRoleModulesGetDataFail,
    setRoleModulesAddDataSuccess, setRoleModulesAddDataFail,
    setRoleModulesUpdateDataSuccess, setRoleModulesUpdateDataFail,
    setRoleModulesDeleteDataSuccess, setRoleModulesDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";

function* getAllRoleModulesDataService() {
    try {
        const response = yield call(getRequest, ROLE_MODULE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setRoleModulesDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRoleModulesDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving roles"))
        yield put(setRoleModulesDataFail(error));
    }
}

function* getRoleModulesDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            ROLE_MODULE_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setRoleModulesGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRoleModulesGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving roles info"))
        yield put(setRoleModulesGetDataFail(error));
    }
}

function* postRoleModulesDataService({ request }) {
    try {
        const response = yield call(postRequest, ROLE_MODULE_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Role module mapping created"))
            yield put(setRoleModulesAddDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRoleModulesAddDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error adding role modules mapping"))
        yield put(setRoleModulesAddDataFail(error));
    }
}
function* putRoleModulesDataService({ request }) {
    try {
        const response = yield call(putRequest, ROLE_MODULE_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Role updated"))
            yield put(setRoleModulesUpdateDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRoleModulesUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error editing role modules mapping"))
        yield put(setRoleModulesUpdateDataFail(error));
    }
}

function* deleteRoleModulesDataService({ request }) {
    try {
        const response = yield call(deleteRequest, ROLE_MODULE_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setRoleModulesDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setRoleModulesDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setRoleModulesDeleteDataFail(error));
    }
}


export function* watchRoleModules(){
    yield takeLatest(ROLE_MODULE_DATA.ROLE_MODULE_DATA_LOAD, getAllRoleModulesDataService);
    yield takeLatest(ROLE_MODULE_GET_DATA.ROLE_MODULE_GET_DATA_LOAD, getRoleModulesDataService);
    yield takeLatest(ROLE_MODULE_ADD_DATA.ROLE_MODULE_ADD_DATA_LOAD, postRoleModulesDataService);
    yield takeLatest(ROLE_MODULE_UPDATE_DATA.ROLE_MODULE_UPDATE_DATA_LOAD, putRoleModulesDataService);
    yield takeLatest(ROLE_MODULE_DELETE_DATA.ROLE_MODULE_DELETE_DATA_LOAD, deleteRoleModulesDataService); 
}