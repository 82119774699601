import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { loadDivisionDataRequest } from "../../division/redux/Actions";
import { CANCEL, DIVISION, END_DATE, REGION_NAME, SAVE, STARTAFTEREND, STARTNOTFUTURE, START_DATE, ULTI_REGION_CODE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";


const RegionForm = ({ region, submit, cancel, editType = null }) => {
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (!values.region_name) {
            errors.region_name = "Required";
        }
        if (values.division_id === -1 || values.division_id === '') {
            errors.division_id = 'Required';
        }
        if (!values.start_date || values.start_date === '') {
            errors.start_date = "Required";
        }
        if (!values.end_date || values.end_date === '') {
            errors.end_date = "Required";
        }
        if (new Date(values.start_date) >= new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        if (new Date() >= new Date(values.start_date)){
            // errors.start_date = STARTNOTFUTURE
        }
        return errors;
    };

    let { divisions } = useSelector(state => state.division);
    useEffect(() => {
        //don't reload companies if they're already loaded
        if (divisions.length === 0) {
            dispatch(loadDivisionDataRequest())
        }
    }, [dispatch, divisions.length])

    const formik = useFormik({});


    useEffect(() => {
        if (region) {
            formik.current.setFieldValue('id', region?.id);
            formik.current.setFieldValue('region_name', region?.region_name);
            formik.current.setFieldValue('ulti_region_code', region?.ulti_region_code);
            formik.current.setFieldValue('division_id', region?.division_id);
            if (region && region.start_date && region.end_date) {
                if (region?.start_date.includes("-") && region?.end_date.includes("-")) {
                    formik.current.setFieldValue('start_date', FormatDate(region?.start_date)); 
                    formik.current.setFieldValue('end_date', FormatDate(region?.end_date));
                }
            }
        }
    }, [region, formik])


    return <Formik
        innerRef={formik}
        initialValues={{
            region_name: '',
            ulti_region_code: '',
            division_id: '',
            start_date: '',
            end_date: '12/31/2099'
        }}
        validate={validate}
        onSubmit={submit}
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="region_name" className="form-label">
                        {REGION_NAME} <span className="error-text">*</span>
                    </label>
                    <Field className="form-control" type='text' name='region_name' placeholder="Enter Region Name" autoComplete="off" />
                    <span className="error-text">
                        <ErrorMessage name="region_name" />
                    </span>
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputCCode" className="form-label">
                        {ULTI_REGION_CODE}
                    </label>
                    <Field type="text" className="form-control" name="ulti_region_code" placeholder="Ulti Region Code" autoComplete="off" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputMDCode" className="form-label">
                        {DIVISION} <span className="error-text">*</span>
                    </label>
                    <FormikSelectField
                        name="division_id"
                        value={values.division_id}
                        onChange={setFieldValue}
                        placeholder={'Select Division'}
                        disabled={editType !== null}
                    >
                        {divisions.filter(division => division.active === 0 || division.active === 2).map((division) => {
                            return <option key={division.id} value={division.id}>{division.division_name}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name='division_id' />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        {START_DATE} <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="start_date" value={values.start_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose Start Date"} readOnly={(editType !== null) ? true : false} />
                    <span className='error-text'>
                        <ErrorMessage name="start_date" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        {END_DATE} <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="end_date" value={values.end_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose End Date"} readOnly={(editType !== null) ? true : false} />
                    <span className='error-text'>
                        <ErrorMessage name="end_date" />
                    </span>
                </div>
                <div className="col-md-12">
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </div>
            </Form>
        )}
    </Formik>
}

export default RegionForm