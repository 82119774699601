
import { ActiveFilter } from "./individualFilters/ActiveFilter";
import { CommisionableFilter } from "./individualFilters/CommisionableFilter";
import { CoveringFilter } from "./individualFilters/CoveringFilter";
import { BooleanFilter } from "./individualFilters/BooleanFilter";
import { DateFilter } from "./individualFilters/DateFilter";
import { DropdownFilter } from "./individualFilters/DropdownFilter";
import { TextFilter } from "./individualFilters/TextFilter";
import './SideBarFilters.css';

export default function IndividualFilter({ field, filterType, setFilter, existingFilter, dropdownOptions }) {
    // console.log(field, "-----",filterType )
    switch (filterType) {
        case "text":
        // case "number":
            return <TextFilter field={field} filterType={filterType} setFilter={setFilter} existingFilter={existingFilter} />
        case "date":
        case "custom_date":
            return <DateFilter field={field} filterType={filterType} setFilter={setFilter} existingFilter={existingFilter} />
        case "active":
            return <ActiveFilter field={field} filterType={filterType} setFilter={setFilter} existingFilter={existingFilter} />
        case "icon":
            return <CoveringFilter field={field} filterType={filterType} setFilter={setFilter} existingFilter={existingFilter} />
        case "commisionable":
            return <CommisionableFilter field={field} filterType={filterType} setFilter={setFilter} existingFilter={existingFilter} />
        case "dropdown":
            return <DropdownFilter field={field} filterType={filterType} setFilter={setFilter} existingFilter={existingFilter} options={dropdownOptions} />
        case "bool":
            return <BooleanFilter field={field} filterType={filterType} setFilter={setFilter} existingFilter={existingFilter} />
        default:
            return null
    }
}