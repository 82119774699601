const TSHIRT_DATA = {
    TSHIRT_DATA_LOAD:'TSHIRT_DATA_LOAD', 
    TSHIRT_DATA_SUCCESS:'TSHIRT_DATA_SUCCESS', 
    TSHIRT_DATA_FAIL:'TSHIRT_DATA_FAIL'
}

const TSHIRT_GET_API = '/employee/v1/EmployeeAdditionalInfo/report'


const TSHIRT_ADD_DATA = {
    TSHIRT_ADD_DATA_LOAD:'TSHIRT_ADD_DATA_LOAD',
    TSHIRT_ADD_DATA_SUCCESS:'TSHIRT_ADD_DATA_SUCCESS', 
    TSHIRT_ADD_DATA_FAIL:'TSHIRT_ADD_DATA_FAIL',
    TSHIRT_ADD_DATA_RESET:'TSHIRT_ADD_DATA_RESET'
}

const TSHIRT_POST_API = '/employee/v1/EmployeeAdditionalInfo/t-shirt'

const TSHIRT_UPDATE_DATA = {
    TSHIRT_UPDATE_DATA_LOAD:'TSHIRT_UPDATE_DATA_LOAD',
    TSHIRT_UPDATE_DATA_SUCCESS:'TSHIRT_UPDATE_DATA_SUCCESS', 
    TSHIRT_UPDATE_DATA_FAIL:'TSHIRT_UPDATE_DATA_FAIL',
    TSHIRT_UPDATE_DATA_RESET:'TSHIRT_UPDATE_DATA_RESET'
}

const TSHIRT_PUT_API = '/employee/v1/EmployeeAdditionalInfo/t-shirt'


const TSHIRT_GET_DATA = {
    TSHIRT_GET_DATA_LOAD:'TSHIRT_GET_DATA_LOAD',
    TSHIRT_GET_DATA_SUCCESS:'TSHIRT_GET_DATA_SUCCESS', 
    TSHIRT_GET_DATA_FAIL:'TSHIRT_GET_DATA_FAIL'
}

const TSHIRT_GET_DETAIL_API = '/employee/v1/EmployeeAdditionalInfo/t-shirt/GetByEmp/'

export {TSHIRT_DATA, TSHIRT_GET_API, TSHIRT_ADD_DATA, TSHIRT_POST_API, TSHIRT_UPDATE_DATA, TSHIRT_PUT_API, TSHIRT_GET_DATA, TSHIRT_GET_DETAIL_API}

