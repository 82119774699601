import React from "react";
import {Button} from 'react-bootstrap';

const DefaultButton = ({buttonClass, buttonTitle, buttonType="button", actionBlock, disabled=null}) => {

    let cName = buttonClass + ' pt-1 pb-1';

    return (
        <Button type={buttonType} className={cName} onClick={actionBlock} disabled={disabled} >
            {buttonTitle}
        </Button>
    );
}

export default DefaultButton;