import {
    MODULE_DATA,
    MODULE_GET_DATA,
    MODULE_ADD_DATA,
    MODULE_UPDATE_DATA,
    MODULE_DELETE_DATA,
} from './Constants'


//For listing
const loadModuleDataRequest = () => ({
    type: MODULE_DATA.MODULE_DATA_LOAD,
});
const setModuleDataSuccess = (modules) => ({
    type: MODULE_DATA.MODULE_DATA_SUCCESS,
    modules,
});
const setModuleDataFail = (error) => ({
    type: MODULE_DATA.MODULE_DATA_FAIL,
    error,
});

//for details
const loadModuleGetDataRequest = (id) => ({
    type: MODULE_GET_DATA.MODULE_GET_DATA_LOAD,
    id,
});
const setModuleGetDataSuccess = (response) => ({
    type: MODULE_GET_DATA.MODULE_GET_DATA_SUCCESS,
    module: response.module[0],
});
const setModuleGetDataFail = (error) => ({
    type: MODULE_GET_DATA.MODULE_GET_DATA_FAIL,
    error,
});

//For add Module
const loadModuleAddDataRequest = (request) => ({
    type: MODULE_ADD_DATA.MODULE_ADD_DATA_LOAD,
    request,
});
const setModuleAddDataSuccess = (module) => ({
    type: MODULE_ADD_DATA.MODULE_ADD_DATA_SUCCESS,
    module,
});
const setModuleAddDataFail = (error) => ({
    type: MODULE_ADD_DATA.MODULE_ADD_DATA_FAIL,
    error,
});

//update Module
const loadModuleUpdateDataRequest = (request) => ({
    type: MODULE_UPDATE_DATA.MODULE_UPDATE_DATA_LOAD,
    request,
});
const setModuleUpdateDataSuccess = (module) => ({
    type: MODULE_UPDATE_DATA.MODULE_UPDATE_DATA_SUCCESS,
    module,
});
const setModuleUpdateDataFail = (error) => ({
    type: MODULE_UPDATE_DATA.MODULE_UPDATE_DATA_FAIL,
    error,
});

//Delete Module
const loadModuleDeleteDataRequest = (request) => ({
    type: MODULE_DELETE_DATA.MODULE_DELETE_DATA_LOAD,
    request,
});
const setModuleDeleteDataSuccess = (module) => ({
    type: MODULE_DELETE_DATA.MODULE_DELETE_DATA_SUCCESS,
    module,
});
const setModuleDeleteDataFail = (error) => ({
    type: MODULE_DELETE_DATA.MODULE_DELETE_DATA_FAIL,
    error,
});


export {
    loadModuleDataRequest, setModuleDataSuccess, setModuleDataFail,
    loadModuleGetDataRequest, setModuleGetDataSuccess, setModuleGetDataFail,
    loadModuleAddDataRequest, setModuleAddDataSuccess, setModuleAddDataFail,
    loadModuleUpdateDataRequest, setModuleUpdateDataSuccess, setModuleUpdateDataFail,
    loadModuleDeleteDataRequest, setModuleDeleteDataSuccess, setModuleDeleteDataFail,
}