// Create
const LOGIN_DATA = {
  LOGIN_DATA_LOAD: "LOGIN_DATA_LOAD",
  LOGIN_DATA_SUCCESS: "LOGIN_DATA_SUCCESS",
  LOGIN_DATA_FAIL: "LOGIN_DATA_FAIL",
  LOGIN_DATA_REDIRECT: "LOGIN_DATA_REDIRECT",
};

const LOGIN_POST_API = "/login";

const ROLE_ACCESS_DATA = {
  ROLE_ACCESS_DATA_LOAD: "ROLE_ACCESS_DATA_LOAD",
  ROLE_ACCESS_DATA_SUCCESS: "ROLE_ACCESS_DATA_SUCCESS",
  ROLE_ACCESS_DATA_FAIL: "ROLE_ACCESS_DATA_FAIL",
};
const ROLE_ACCESS_GET_API = "/admin/roleAccess/";

const TOKEN_DATA = {
  TOKEN_DATA_LOAD: "TOKEN_DATA_LOAD",
  TOKEN_DATA_SUCCESS: "TOKEN_DATA_SUCCESS",
  TOKEN_DATA_FAIL: "TOKEN_DATA_FAIL",
};

const TOKEN_POST_API = "/login";

const SET_LOGGED_OUT = "LOGIN_SET_LOGGED_OUT"

export { LOGIN_DATA, LOGIN_POST_API, ROLE_ACCESS_DATA, ROLE_ACCESS_GET_API, TOKEN_DATA, TOKEN_POST_API, SET_LOGGED_OUT };
