import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect } from "react";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CANCEL, DENSITY_CLASS_NAME, SAVE } from "../../../shared/constants/Constants";

const DensityForm = ({ density, submit, cancel }) => {
  const validate = (values) => {
    const errors = {};
    if (!values.density_class_name) {
      errors.density_class_name = "Required";
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{
        id: density?.id ?? "",
        density_class_name: density?.density_class_name ?? "",
      }}
      validate={validate}
      onSubmit={submit}
    >
      {() => (
        <Form className="row g-4">
          <div className="col-md-6">
            <label htmlFor="density_class_name" className="form-label">
              {DENSITY_CLASS_NAME}<span className="error-text">*</span>
            </label>
            <Field
              className="form-control"
              type="text"
              name="density_class_name"
              placeholder="Enter Density Class Name"
              autoComplete="off"
            />
            <span className="error-text">
              <ErrorMessage name="density_class_name" />
            </span>
          </div>
          <div className="col-md-12">
            <DefaultButton
              buttonClass={"btn btn-secondary me-2"}
              buttonTitle={CANCEL}
              actionBlock={cancel}
            />
            <DefaultButton
              buttonClass={"default-btn-color"}
              buttonTitle={SAVE}
              buttonType={"submit"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DensityForm;
