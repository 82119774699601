import { Fragment, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select';
import DefaultButton from "../../DefaultButton";
import './SideBarFilters.css';

export function SaveForm({ name, submit }) {
    let [inputValue, setInputValue] = useState(name ?? "")
    return <Fragment>

        <Row className="gy-1">
            <Col xs={12}>
                <input className="form-control" onChange={(event) => setInputValue(event.target.value)} placeholder="Filter name"></input>
            </Col>
            <Col></Col>
            <Col xs="auto">
                <DefaultButton
                    buttonTitle={"Save"}
                    actionBlock={() => submit(inputValue)}
                />
            </Col>
        </Row>
    </Fragment>
}


export function LoadForm({ pathname, submit }) {
    let options = useMemo(() => {
        let filters = localStorage.getItem('filters')
        try {
            filters = JSON.parse(filters ?? '{}')
        } catch (error) {
            filters = {}
        }
        return filters[pathname] ?? {}
    })
    console.log(options)
    let [selected, setSelected] = useState(null)
    return <Fragment>
        <Row className="gy-1">
            <Col xs={12}>
                <Select
                    options={Object.keys(options).map((option) => ({ value: option, label: option }))}
                    onChange={({ value }) => { setSelected(value) }}
                />
            </Col>
            <Col></Col>
            <Col xs="auto">
                <DefaultButton
                    actionBlock={() => {
                        console.log(options[selected]);
                        submit(options[selected])
                    }}
                    buttonTitle="Load"
                />
            </Col>
        </Row>
    </Fragment>
}