import React,{ useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField"
import { loadClassificationDataRequest } from "../../store-classification/redux/Actions"
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { CANCEL, SAVE, STARTAFTEREND, STARTNOTFUTURE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";

const StoreClassificationTrackerForm = ({ tracker, submit, cancel, store }) => {
    const dispatch = useDispatch()
    const validate = (values) => {
        const errors = {};
        if (values.sap_id === -1 || values.sap_id === '') {
            errors.sap_id = 'Required';
        }
        if (values.store_classification_id === -1 || values.store_classification_id === '') {
            errors.store_classification_id = 'Required';
        }
        if (!values.start_date || values.start_date === '') {
            errors.start_date = "Required";
        }
        if (!values.end_date || values.end_date === '') {
            errors.end_date = "Required";
        }
        if (new Date(values.start_date) >= new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        if (new Date() >= new Date(values.start_date)){
            // errors.start_date = STARTNOTFUTURE
        }
        return errors;
    };

    let { classifications } = useSelector(store => store.classification);

    useEffect(() => {
        dispatch(loadClassificationDataRequest())
    }, dispatch)

    let trackStartDate = '';
    let trackEndDate = '12/31/2099';
    if (tracker && tracker?.start_date && tracker?.end_date) {
        if (tracker?.start_date.includes("-") && tracker?.end_date.includes("-")) {
            trackStartDate = FormatDate(tracker?.start_date);
            trackEndDate = FormatDate(tracker?.end_date);
        }
    }

    return <Formik
        initialValues={{
            store_classification_id: tracker?.store_classification_id?? '',
            sap_id: store?.id?? '',
            sap: store?.sap?? '',
            start_date: trackStartDate,
            end_date: trackEndDate,
        }}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="sap" className="form-label">
                        SAP
                    </label>
                    {/* <Field diabled={true} className="form-control" type='text' name='sap' readOnly={true} defaultValue={store.sap} autoComplete="off" /> */}
                    <Field className="form-control" type='text' name='store_name' readOnly={true} autoComplete="off" defaultValue={store.store_name + ", " + store.sap} />
                    <Field type="hidden" name="sap" />
                </div>
                <div className="col-md-6">
                    <label htmlFor="inputMDCode" className="form-label">
                        Store Classification <span className="error-text">*</span>
                    </label>
                    <FormikSelectField
                        name="store_classification_id"
                        value={values.store_classification_id}
                        onChange={setFieldValue}
                        placeholder={'Select Store Classification'}
                    >
                        {classifications.map((classification) => {
                            return <option key={classification.id} value={classification.id}>{classification.store_classification_name}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name='store_classification_id' />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        Start Date <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="start_date" value={values.start_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose Start Date"} readOnly={false} />
                    <span className='error-text'>
                        <ErrorMessage name="start_date" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">
                        End Date <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField name="end_date" value={values.end_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose End Date"} readOnly={false} />
                    <span className='error-text'>
                        <ErrorMessage name="end_date" />
                    </span>
                </div>
                <div className="col-md-12">
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"btn btn-secondary default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </div>
            </Form>
        )}
    </Formik>
}

export default StoreClassificationTrackerForm