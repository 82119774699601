import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../../shared/components/FormError";
import PTForm from "./PTForm";
import { loadPTUpdateDataRequest } from "../redux/Actions";

const PTEdit = ({  name, description, formName, categories, handleClose, ptData }) => {

  const dispatch = useDispatch();
  let [shouldCloseModal, setShouldCloseModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState(false);

  let { loading, error } = useSelector((state) => state.productTables);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true);
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false);
    }
  }, [loading, error, shouldCloseModal]);

  let empInfo = JSON.parse(localStorage.getItem("employee"));

  function submit(values) {
    delete values.id;
    if (!description) {
      delete values.description
    }
    if (values.category_id <= 0) {
      delete values.category_id
    }
    dispatch(loadPTUpdateDataRequest(values, formName))
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return (
    <>
      <FormError>{errorMessage}</FormError>
      <PTForm name={name} description={description} categories={categories} submit={submit} cancel={cancel} ptData={ptData} />
    </>
  );
};

export default PTEdit;
