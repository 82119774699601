import { COMPANY_DATA, COMPANY_ADD_DATA, COMPANY_UPDATE_DATA, COMPANY_GET_DATA, COMPANY_DELETE_DATA, COMPANY_IMPORT_DATA } from './Constants'

const initialState = {
    loading:false,
    error:null,
    companies:[],
    company:{},
    isImportFile: false
}

const Reducer = (state=initialState, action) => {
    switch(action.type) {
        //Company List Conditions
        case COMPANY_DATA.COMPANY_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                companies:[]
            }
        case COMPANY_DATA.COMPANY_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                companies:action.companies,
                error:null
            }
        case COMPANY_DATA.COMPANY_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                companies:[]
            }
        //Company Add Conditions
        case COMPANY_ADD_DATA.COMPANY_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                company:{},
                error:null
            }
        case COMPANY_ADD_DATA.COMPANY_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                company:action.company,
                error:null
            }
        case COMPANY_ADD_DATA.COMPANY_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                company:{},
                error:action.error
            }
        case COMPANY_ADD_DATA.COMPANY_ADD_DATA_RESET:
            return {
                ...state,
                loading:false,
                company:{},
                error:action.null
            }
        //Company Get Details Conditions
        case COMPANY_GET_DATA.COMPANY_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                company:{},
                error:null,
                isImportFile: false
            }
        case COMPANY_GET_DATA.COMPANY_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                company:action.company,
                error:null
            }
        case COMPANY_GET_DATA.COMPANY_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                company:{},
                error:action.error
            }
        //Company Update Conditions
        case COMPANY_UPDATE_DATA.COMPANY_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                company:{},
                error:null
            }
        case COMPANY_UPDATE_DATA.COMPANY_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                company:action.company,
                error:null
            }
        case COMPANY_UPDATE_DATA.COMPANY_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                company:{},
                error:action.error
            }
        //Company Delete Conditions
        case COMPANY_DELETE_DATA.COMPANY_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                company:{},
                error:null
            }
        case COMPANY_DELETE_DATA.COMPANY_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                company:action.company,
                error:null
            }
        case COMPANY_DELETE_DATA.COMPANY_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                company:{},
                error:action.error
            }
        //Company Import Conditions
        case COMPANY_IMPORT_DATA.COMPANY_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null,
                companies:[],
                isImportFile: false
            }
        case COMPANY_IMPORT_DATA.COMPANY_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                companies:action.companies,
                error:null,
                isImportFile: true
            }
        case COMPANY_IMPORT_DATA.COMPANY_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                companies:[],
                isImportFile: false
            }
        default: 
            return {
                ...state
            }
    }
}

export default Reducer;