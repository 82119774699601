import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { loadQuaterlyDataRequest } from "../redux/Actions"

const QuarterlyReview = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let { quaterly, loading } = useSelector((state) => state.quaterly);
  console.log("Helloo",quaterly);

  const [empId, setEmpId] = useState();

  const handleReviews = () => {
    dispatch(loadQuaterlyDataRequest({
      "employee_id":empId,
      "report":"quaterly",
      "requsted_by":JSON.parse(localStorage.getItem('emp_no'))
    }));
  }

  let columns = [
    {
      display_name: "Review_id",
      field: "id",
      type: "number",
      hide: true,
    },
    {
      display_name: "Employee",
      field: "employee_f_name",
      type: "text",
    },
    {
      display_name: "Supervisor",
      field: "supervisor_employee_name",
      type: "text",
    },
    {
      display_name: "Quarterly Review Period",
      field: "quarter",
      type: "text",
    },
    {
      display_name:"PDF Link",
      field: "pdf_link",
      type:"hyperlink",
      is_workflow_link:true,
      redirect_value:"link",
      width: 90
    },
    {
      display_name: "Review Submitted Date",
      field: "submit_date",
      type: "text",
    },
   // {
    //  display_name:"PDF Link",
     // field: "pdf_link",
    //  type:"hyperlink",
    //  is_workflow_link:true,
    //  redirect_value:"link",
    //  width: 90
   // },
  ];

  return (
    <>
      <Row>
        <Col>
          <Breadcrumbs children={[{ label: "Quarterly Review" }]} />
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Employee ID"
            value={empId}
            onChange={(e) => setEmpId(e.target.value)}
          />
        </Col>
        <Col>
          <button type="button" class="btn btn-primary mb-3" onClick={handleReviews}>
             Submit
          </button>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Card>
          <Card.Body >
          {Array.isArray(quaterly) ?
            (<ListView
              exportTitle="Quaterly Review"
              pdfBtnUrl={"https://wvservicetest.wirelessvision.com/QuarterlyReviews/viewAllPdf?employee="+empId+"&ids="}
              columnNames={columns}
              rowData={quaterly}
            />): (<p className="text-center">{quaterly}</p>)}
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default QuarterlyReview;
