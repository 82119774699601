import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { EMPLOYEE_STATUS } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CREATENEW } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadEmployeeStatusDataRequest, loadEmployeeStatusDeleteDataRequest } from "../redux/Actions";
import EmployeeStatusAdd from "./EmployeeStatusAdd";
import EmployeeStatusEdit from "./EmployeeStatusEdit";
import DeleteModal from "../../../shared/components/DeleteModal";
import Permissions from "../../../shared/components/Permissions";

const EmployeeStatus = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let { employeeStatuses, loading } = useSelector((state) => state.employeeStatus);
    let [selectedRow, setSelectedRow] = useState({})
    const [shouldReload, setShouldReload] = useState(true)

    useEffect(() => {
        if (!loading && shouldReload){
            setShouldReload(false)
            dispatch(loadEmployeeStatusDataRequest());
        }
    }, [dispatch, loading, shouldReload]);

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Employee Status";
    let permission = Permissions(emp_no, module_name);
    
    let actionsPermission = !(permission.write_permission || permission.delete_permission);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: EMPLOYEE_STATUS,
            field: "name",
            type: "text",
        },
        {
            display_name: "",
            field: 'actions',
            type: 'actions',
            hide: actionsPermission
        }
    ];

    const handleDelete = () => {
        dispatch(loadEmployeeStatusDeleteDataRequest({ id: [selectedRow] }))
        setShouldReload(true)
        setShowDeleteModal(false);
    }

    let [clickEditFunc, setClickEditFunc] = useState(null);
    let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    useEffect(()=>{
        if (permission.write_permission){
            setClickEditFunc(
                () => (row)=>{
                setSelectedRow(row);
                setShowEditModal(true);
            })
        }
        if (permission.delete_permission){
            setClickDeleteFunc(
                () => (id)=>{
                setSelectedRow(id);
                setShowDeleteModal(true);
            })
        }
    },[permission])
    return (
        <>
            <Row>
                <Col className="gx-0">
                <Breadcrumbs
                    children={[
                        { href: '/density', label: EMPLOYEE_STATUS },
                    ]}
                />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission?(
                        <DefaultButton
                            buttonClass="btn btn-primary default-btn-color"
                            buttonTitle={CREATENEW}
                            actionBlock={() => setShowCreateModal(true)}
                        />
                    ):""}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={EMPLOYEE_STATUS}
                            columnNames={columns}
                            rowData={employeeStatuses}
                            clickEdit={clickEditFunc}
                            clickDelete={clickDeleteFunc}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={showCreateModal}
                handleClose={() => setShowCreateModal(false)}
                actionTitle={"Create " + EMPLOYEE_STATUS}
            >
                <EmployeeStatusAdd
                    handleClose={(reload) => {
                        setShowCreateModal(false);
                        if (reload) {
                            dispatch(loadEmployeeStatusDataRequest());
                        }
                    }}
                />
            </Modalpopup>
            <Modalpopup
                show={showEditModal}
                handleClose={() => { setShowEditModal(false) }}
                actionTitle={"Edit " + EMPLOYEE_STATUS}
            >
                <EmployeeStatusEdit
                    employeeStatus={selectedRow}
                    handleClose={(reload) => {
                        if (reload) {
                            dispatch(loadEmployeeStatusDataRequest());
                        }
                        setShowEditModal(false)
                    }}
                />
            </Modalpopup>
            <DeleteModal
                handleClose={() => { setShowDeleteModal(false) }}
                handleDelete={handleDelete}
                show={showDeleteModal}
            >
                <div>
                    Delete {EMPLOYEE_STATUS}?
                </div>
            </DeleteModal>
        </>
    );
};

export default EmployeeStatus;
