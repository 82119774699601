import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import ListView from "../../../../shared/components/listview/ListView";
import AccountType from "../../account_type/components/AccountType";
import Category from "../../category/components/Category";
import Subcategory from "../../line_type/components/Subcategory";
import ProductType from "../../productType/components/ProductType";
import RelationshipType from "../../relationship_type/components/RelationshipType";
import ServiceType from "../../service_type/components/ServiceType";
import { loadPTDataRequest } from "../redux/Actions";


const ProductTables = () => {

  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [products, setProducts] = useState([]);

  let columns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true
    },
    {
      display_name: "Product Name",
      field: "name",
      type: "text",
    }
  ];

  let { productTables } = useSelector((state) => state.productTables);
  useEffect(() => {
    dispatch(loadPTDataRequest());
  }, [dispatch]);

  let [currentTracker, setCurrentTracker] = useState(1);
  let trackers = [
    <AccountType data={productTables?.accountTypeData} />,
    <Category data={productTables?.categoryData} />,
    <Subcategory data={productTables?.subCategoryData} />,
    <ProductType data={productTables?.productTypeData} />,
    // <RelationshipType data={productTables?.relationshipTypeData} />,
    // <ServiceType data={productTables?.serviceTypeData} />
  ];

  // console.log("pro::", productTables);


  return (
    <>
      <div>
        <Row>
          <Col className='gx-0'>
            <Breadcrumbs
              children={[
                { label: "Product Tables" },
              ]}
            />
          </Col>
        </Row>

        <Row>
            <Col>
                <button className={currentTracker === 1 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(1) }}>Account Type</button>
                <button className={currentTracker === 2 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(2) }}>Category</button>
                <button className={currentTracker === 3 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(3) }}>Sub Category</button>
                <button className={currentTracker === 4 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(4) }}>Product Type</button>
                {/* <button className={currentTracker === 5 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(5) }}>Relationship Type</button>
                <button className={currentTracker === 6 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(6) }}>Service Type</button> */}
            </Col>
            <Card >
                <Card.Body >
                    {trackers[currentTracker - 1]}
                </Card.Body>
            </Card>
        </Row>
      </div>
    </>
  );
};

export default ProductTables;
