import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import { ROLE_MODULE_MAPPING } from "../../../shared/constants/Constants";
import DefaultButton from "../../../shared/components/DefaultButton";
import { CREATENEW } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadRoleModulesDataRequest, loadRoleModulesDeleteDataRequest } from "../redux/Actions";
import DeleteModal from "../../../shared/components/DeleteModal";
import Permissions from "../../../shared/components/Permissions";

const RoleModuleMapping = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    let { roleModules, loading } = useSelector((state) => state.roleModules);
    let [selectedRow, setSelectedRow] = useState({})
    const [shouldReload, setShouldReload] = useState(true)

    useEffect(() => {
        if (!loading && shouldReload) {
            setShouldReload(false)
            dispatch(loadRoleModulesDataRequest());
        }
    }, [dispatch, loading, shouldReload]);

    // let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    // let module_name = "Role Module Mapping";
    // let permission = Permissions(emp_no, module_name); 
    // let actionsPermission = !(permission.write_permission || permission.delete_permission);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: "Role Name",
            field: "role_name",
            type: "text",
        },
        {
            display_name: "Group Name",
            field: "group_name",
            type: "text",
        },
        {
            display_name: "",
            field: 'actions',
            type: 'actions',
            // hide: actionsPermission
        }
    ];

    // let [clickEditFunc, setClickEditFunc] = useState(null);
    // let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
    // useEffect(() => {
    //     if (permission.write_permission) {
    //       setClickEditFunc(
    //         () => (row) => {
    //         //   setSelectedRow(row);
    //         //   setShowEditModal(true);
    //         navigate('edit/' + row.role_id)
    //         }
    //       )
    //     }
    //     if (permission.delete_permission) {
    //       setClickDeleteFunc(
    //         () => (id) => {
    //           setSelectedRow({ id });
    //           setShowDeleteModal(true);
    //         })
    //     }
    // }, [permission])
    
    const clickEdit = (row) => {
        navigate('edit/' + row.role_id)
    }
    const clickDelete = (id) => {
        setShowDeleteModal(true)
        setSelectedRow({ id });
    }

    const handleDelete = () => {
        dispatch(loadRoleModulesDeleteDataRequest({ id: [selectedRow.id] }))
        setShouldReload(true)
        setShowDeleteModal(false);
    }

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { label: ROLE_MODULE_MAPPING },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {/* {permission.create_permission ? ( */}
                        <DefaultButton
                            buttonClass="btn btn-primary default-btn-color"
                            buttonTitle={CREATENEW}
                            actionBlock={() => navigate('create')}
                        />
                    {/* ) : (<></>)} */}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle={ROLE_MODULE_MAPPING}
                            columnNames={columns}
                            rowData={roleModules}
                            // clickEdit={clickEditFunc}
                            // clickDelete={clickDeleteFunc}
                            clickEdit={clickEdit}
                            clickDelete={clickDelete}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <DeleteModal
                handleClose={() => { setShowDeleteModal(false) }}
                handleDelete={handleDelete}
                show={showDeleteModal}
            >
                <div>
                    Delete {ROLE_MODULE_MAPPING}?
                </div>
            </DeleteModal>
        </>
    );
};

export default RoleModuleMapping;
