import React from 'react'
import {Modal} from 'react-bootstrap';
import { Button } from "react-bootstrap";

const Modalpopup = ({show, handleClose, actionTitle, children, size, sampleFile=null, sampleFileURL=null}) => {
    return (
        <Modal
            size={size}
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> 
                    {actionTitle}
                </Modal.Title>
                {sampleFile === "yes" && (
                <a href={sampleFileURL} download className='sampleFile'>
                    <Button type="button" className="default-btn-color">
                        Sample File
                    </Button>
                </a>
                )}
            </Modal.Header>
            <Modal.Body>
               {children}
            </Modal.Body>
        </Modal>
    )
}

export default Modalpopup
