import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setNextPrevIds } from '../../../containers/next_prev_redux/Actions'
import { instance } from '../../utilites/ApiClient'
import './TableStyle.scss'
import SidebarFilters from './SidebarFilter/SidebarFilters'

import { actionValueRenderer, getValueFormatters, getFilterParams, getComparators, getFloatingFilterComponents, checkboxRenderer, iconRenderer, hyperlinkRenderer, onColumnVisible } from './tableFunctions'

//An object containing valueFormatters. Different formatting for diferent column types
let valueFormatters = getValueFormatters();
//an object containing filterParams. Different filterParams depending on the column type
let filterParams = getFilterParams();
let comparators = getComparators();
let floatingFilterComponents = getFloatingFilterComponents();


export default function ServerSideListView({ columnNames, rowData = [], rowClick, exportTitle, clickAdd, clickEdit, clickDelete,  shouldSetNextPrevIds = false, filterCallback, filterString, sort, exportUrl, tableHeight = '70vh' }) {
    const [hiddenColumns, setHiddenColumns] = useState(() => {
        let hiddenColumns = [];
        for (let col of columnNames) {
            if (col.hide) {
                hiddenColumns.push(col.field)
            }
        }
        return hiddenColumns
    })
    const [exportEnabled, setExportEnabled] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    let columnDefs = useMemo(() => createColumnDefs(columnNames, sort, clickAdd, clickEdit, clickDelete), [columnNames, sort])
    const [api, setApi] = useState(null)
    const [columnApi, setColumnApi] = useState(null)

    let [selectedIds, setSelectedIds] = useState([])

    const [filters, setFilters] = useState({}) // this is only used to pass to the sidebar

    const gridRef = useRef(null)
    useEffect(() => {
        let i = 0;
        let newData = rowData.map((row) => {
            return { ...row, row_id: i++ }
        });

        let ids = [];
        api?.forEachNode((node) => {
            ids.push({ row_id: node.data.row_id })
        })
        api?.applyTransaction({ remove: ids })
        api?.applyTransaction({ add: newData })

        api?.forEachNode((node) => {
            if (selectedIds.some((v) => v === node.data.id)) {
                node.setSelected(true);
            }
        })
    }, [rowData, api]);
    useEffect(() => {
        return () => {
        }
    }, [])

    const clearFilter = () => {
        api.setFilterModel(null)
    }
    const onGridReady = useCallback((event) => {
        setApi(event.api)
        setColumnApi(event.columnApi)
        event.api.setFilterModel(JSON.parse(filterString))
    }, [])


    // const onGridReady = useCallback((params) => {
    //     if (shouldSetNextPrevIds) {
    //         let ids = []
    //         params.api.forEachNodeAfterFilterAndSort((node) => {
    //             ids.push(node.data.id)
    //         })
    //         dispatch(setNextPrevIds(ids, exportTitle))
    //     }
    // }, []);
    const onFilterOrSortChanged = (event) => {
        console.log("event::", event);
        if (shouldSetNextPrevIds) {
            let ids = []
            event.api.forEachNodeAfterFilterAndSort((node) => {
                ids.push(node.data.id)
            })
            dispatch(setNextPrevIds(ids, exportTitle))
        }
        let currentFilters = event.api.getFilterModel();
        setFilters(currentFilters)
        console.log("hj", currentFilters)
        // if (JSON.stringify(tableFilters[tableKey]) != JSON.stringify(currentFilters)) {
        //     dispatch(addTableFilter(tableKey, currentFilters))
        // }
        let sortedColumn = event.columnApi.getColumnState().filter((column) => column.sort)[0];
        let sort = sortedColumn ? { name: sortedColumn.colId, sort: sortedColumn.sort } : null;
        if (filterCallback) {
            filterCallback({ filter: currentFilters, sort: sort });
        }
    };

    useEffect(() => {
        if (shouldSetNextPrevIds && Array.isArray(rowData)) {
            let ids = []
            for (let row of rowData) {
                ids.push(row.id);
            }
            dispatch(setNextPrevIds(ids, exportTitle));
        }
    }, [rowData])

    let download = (ids) => {
        let exportableColumns = columnApi.columnModel.getColumnState().map((col, index) => {
            if (!col.hide || col.colId == 'id') {
                if (col.colId != 'selection checkbox' && col.colId != 'actions') {
                    return col.colId
                }
            }
        }).filter(col => col != undefined)
        instance.post(exportUrl, { ids, filter: filterString, sort, fields: exportableColumns }, {
            responseType: 'blob'
        }).then((response) => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', exportTitle + '.csv');
            document.body.appendChild(link);
            link.click();
        })
    }

    useEffect(() => {
        setExportEnabled(selectedIds.length > 0)
    }, [selectedIds])
    const onSelectionChanged = (event) => {
        let onPageSelected = event.api.getSelectedNodes()
        let alreadySelected = [...selectedIds];

        //Add new ids
        for (let selection of onPageSelected) {
            if (!alreadySelected.some((v) => v === selection.data.id)) {
                alreadySelected.push(selection.data.id);
            }
        }
        //remove unselected ids
        let newSelectedIds = alreadySelected.filter((selection) => {
            let isOnPage = false;
            event.api.forEachNode((row) => {
                if (row.data.id === selection) {
                    isOnPage = true
                }
            })
            if (!isOnPage) {
                return true;
            }
            return onPageSelected.some((v) => v.data.id === selection)

        })
        selectedIds = newSelectedIds
        setSelectedIds(newSelectedIds)
    }

    return <div id="table-container" style={{ width: '100%', height: '100%' }}>
        <Button
            style={{ minWidth: 115 }}
            className={"default-btn-color mb-1 mr-1 py-1"}
            onClick={() => download(selectedIds)}
            disabled={!exportEnabled}
        >
            {`Export ${selectedIds.length > 0 ? selectedIds.length : ''}`}
        </Button>
        <Button
            style={{ minWidth: 115 }}
            className={"default-btn-color mb-1 mx-1 py-1"}
            onClick={() => download('all')}
        >
            {"Export All"}
        </Button>
        <Button

            style={{ width: 115 }}
            className={"default-btn-color mb-1 mx-1 py-1"}
            onClick={clearFilter}
        >
            Clear Filters
        </Button>
        <Row className="gx-0">
            <Col>
                <div
                    id="myGrid"
                    style={{                        
                        height: `${tableHeight}`, 
                        width: '100%',
                    }}
                    className="ag-theme-alpine"
                ><AgGridReact
                        ref={gridRef}
                        // rowData={visibleRowData}
                        onGridReady={onGridReady}
                        onRowClicked={rowClick}
                        onFilterChanged={onFilterOrSortChanged}
                        onSortChanged={onFilterOrSortChanged}
                        sortingOrder={['desc', 'asc']}
                        suppressRowClickSelection={true}
                        rowSelection="multiple"
                        columnDefs={columnDefs}
                        enableBrowserTooltips={true}
                        tooltipShowDelay={300}
                        // onSelectionChanged={onSelectionChanged}
                        rowBuffer={80}
                        getRowNodeId={(params) => params.row_id}
                        onSelectionChanged={onSelectionChanged}
                        onColumnVisible={(event) => onColumnVisible(event, setHiddenColumns)}
                    />
                </div >
            </Col>
            <Col className="col-auto">
                <SidebarFilters 
                colDefs={columnNames} 
                grid={gridRef.current} 
                filtersFromTable={filters} 
                hiddenColumns={hiddenColumns} 
                setHiddenColumns={setHiddenColumns} 
                barHeight = {tableHeight}/>
            </Col>
        </Row>
    </div >

}
function createColumnDefs(columns, sort, clickAdd, clickEdit, clickDelete) {
    if (sort) {
        sort = JSON.parse(sort)
    }
    let newColumnDefs = [
        {
            colId: 'selection checkbox',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            suppressMenu: true,
            width: "55px",
            pinned: 'left'
        },
        ...columns.map((column, index) => {
            if (column.field === 'actions') {
                return {
                    headerName: column.display_name,
                    field: column.field,
                    cellRendererFramework: actionValueRenderer,
                    cellRendererParams: { clickAdd, clickEdit, clickDelete },
                    hide: column.hide ?? false,
                    suppressMenu: true,
                    width: column.width,
                    pinned: column.pinned,
                    wrapText:true, // for text wrapping
                    autoHeight:true, // for text wrapping
                }
            } else if (column.type === 'checkbox') {
                return {
                    headerName: column.display_name,
                    field: column.field,
                    hide: column.hide ?? false,
                    floatingFilter: false,
                    suppressMenu: true,
                    cellRendererFramework: checkboxRenderer,
                    pinned: column.pinned,
                    cellClass: "cell-left-align",
                    wrapText:true, // for text wrapping
                    autoHeight:true, // for text wrapping
                }
            } else if (column.type === 'bool') {
                return {
                    headerName: column.display_name,
                    field: column.field,
                    hide: column.hide ?? false,
                    floatingFilter: false,
                    filter: column.field === 'covering' ? "checkbox" : 'text',
                    floatingFilterComponent: floatingFilterComponents[column.field === 'covering' ? "checkbox" : 'text'],
                    icon: column.icon ?? '',
                    suppressMenu: true,
                    width: column.width,
                    cellRendererFramework: iconRenderer,
                    pinned: column.pinned,
                    cellClass: "cell-left-align",
                    wrapText:true, // for text wrapping
                    autoHeight:true, // for text wrapping
                }
            } else if (column.type === 'icon') {
                return {
                    headerName: column.display_name,
                    field: column.field,
                    hide: column.hide ?? false,
                    floatingFilter: false,
                    filter: column.field === 'covering' ? "checkbox" : 'text',
                    floatingFilterComponent: floatingFilterComponents[column.field === 'covering' ? "checkbox" : 'text'],
                    icon: column.icon ?? '',
                    suppressMenu: true,
                    width: column.width,
                    cellRendererFramework: iconRenderer,
                    pinned: column.pinned,
                    cellClass: "cell-left-align",
                    wrapText:true, // for text wrapping
                    autoHeight:true, // for text wrapping
                }
            } else if (column.type === 'hyperlink') {
                let redirect_link = column.redirect_link;
                let redirect_value = column.redirect_value;
                return {
                    headerName: column.display_name,
                    field: column.field,
                    suppressMenu: true,
                    hide: column.hide ?? false,
                    floatingFilter: false,
                    cellRendererFramework: hyperlinkRenderer,
                    cellRendererParams: { redirect_link, redirect_value },
                    pinned: column.pinned,
                    wrapText:true, // for text wrapping
                    autoHeight:true, // for text wrapping
                    cellClass: "cell-left-align",
                    cellStyle: {
                        'white-space': 'pre-wrap',
                        'word-wrap': 'break-word',
                        'line-height' : 'normal',
                        'padding-bottom': '7px',
                        "text-align": "left"
                      }
                }
            } else {
                return {
                    comparator: comparators[column.type],
                    sort: sort && sort.name === column.field ? sort.sort : column.sort,
                    sortable: true,
                    headerName: column.display_name,
                    valueFormatter: valueFormatters[column.type],
                    field: column.field,
                    filter: (column.type === 'active' || column.type === 'checkbox') ? 'text' : column.type === 'date' ? 'custom_date' : column.type,
                    floatingFilter: false,
                    floatingFilterComponent: floatingFilterComponents[column.type],
                    suppressMenu: true,
                    floatingFilterComponentParams: {
                        suppressFilterButton: column.type === 'active' || column.type === 'checkbox' || column.type === 'dropdown',
                        dropdownOptions: column.type === 'dropdown' ? column.dropdownOptions : null,
                        dropdownFunction: column.dropdownFunction
                    },
                    filterParams: {
                        ...(filterParams[column.type]),
                        // buttons:['apply'],
                        // debounceMs: 1750
                    },
                    hide: column.hide ?? false,
                    tooltipField: column.field,
                    width: column.width,
                    pinned: column.pinned,
                    cellClass: "cell-left-align",
                    wrapText:false, // for text wrapping
                    autoHeight:true, // for text wrapping,
                    cellStyle: {
                        'white-space': 'pre-wrap',
                        'word-wrap': 'break-word',
                        'line-height' : 'normal',
                        'padding-bottom': '7px',
                        "text-align": "left"
                      }
                }
            }
        })
    ]
    return newColumnDefs;
}