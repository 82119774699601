import { MARKET_DATA, MARKET_ADD_DATA, MARKET_UPDATE_DATA, MARKET_GET_DATA, MARKET_DELETE_DATA, MARKET_GET_TRACKER_DATA, MARKET_DELETE_HISTORY, MARKET_IMPORT_DATA, MARKET_TRACKER_IMPORT_DATA, MARKET_GET_STORES } from './Constants'

const initialState = {
    loading: false,
    error: null,
    markets: [],
    market: {},
    tracker: [],
    managers: [],
    marketStores: [],
    activeStores: []
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case MARKET_DATA.MARKET_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                markets: []
            }
        case MARKET_DATA.MARKET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                markets: action.markets,
                error: null
            }
        case MARKET_DATA.MARKET_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                markets: []
            }
        case MARKET_ADD_DATA.MARKET_ADD_DATA_LOAD:
            return {
                ...state,
                loading: true,
                market: {},
                error: null
            }
        case MARKET_ADD_DATA.MARKET_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                market: action.market,
                error: null
            }
        case MARKET_ADD_DATA.MARKET_ADD_DATA_FAIL:
            return {
                ...state,
                loading: false,
                market: {},
                error: action.error
            }

        case MARKET_GET_DATA.MARKET_GET_DATA_LOAD:
            return {
                ...state,
                loading: true,
                market: {},
                error: null
            }
        case MARKET_GET_DATA.MARKET_GET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                market: action.market,
                tracker: action.tracker,
                managers: action.managers,
                activeStores: action.activeStores,
                error: null
            }
        case MARKET_GET_DATA.MARKET_GET_DATA_FAIL:
            return {
                ...state,
                loading: false,
                market: {},
                error: action.error
            }
        case MARKET_UPDATE_DATA.MARKET_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null
            }
        case MARKET_UPDATE_DATA.MARKET_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            }
        case MARKET_UPDATE_DATA.MARKET_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case MARKET_DELETE_DATA.MARKET_DELETE_DATA_LOAD:
            return {
                ...state,
                loading: true,
                market: {},
                error: null
            }
        case MARKET_DELETE_DATA.MARKET_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                market: action.market,
                error: null
            }
        case MARKET_DELETE_DATA.MARKET_DELETE_DATA_FAIL:
            return {
                ...state,
                loading: false,
                market: {},
                error: action.error
            }
        case MARKET_GET_TRACKER_DATA.MARKET_GET_TRACKER_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                tracker: []
            }
        case MARKET_GET_TRACKER_DATA.MARKET_GET_TRACKER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                tracker: action.tracker
            }
        case MARKET_GET_TRACKER_DATA.MARKET_GET_TRACKER_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case MARKET_DELETE_HISTORY.MARKET_DELETE_HISTORY_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                market: null
            }
        case MARKET_DELETE_HISTORY.MARKET_DELETE_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                market: action.market
            }
        case MARKET_DELETE_HISTORY.MARKET_DELETE_HISTORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        //Import 
        case MARKET_IMPORT_DATA.MARKET_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                markets: []
            }
        case MARKET_IMPORT_DATA.MARKET_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                markets: action.markets,
                error: null
            }
        case MARKET_IMPORT_DATA.MARKET_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                markets: []
            }
        //Import Tracker
        case MARKET_TRACKER_IMPORT_DATA.MARKET_TRACKER_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading: true,
                error: null,
                tracker: []
            }
        case MARKET_TRACKER_IMPORT_DATA.MARKET_TRACKER_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                tracker: action.tracker,
                error: null
            }
        case MARKET_TRACKER_IMPORT_DATA.MARKET_TRACKER_IMPORT_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                tracker: []
            }
        //Load market's stores
        case MARKET_GET_STORES.MARKET_GET_STORES_LOAD:
            return {
                ...state,
                loading: true,
                stores: [],
                error: null
            }
        case MARKET_GET_STORES.MARKET_GET_STORES_SUCCESS:
            return {
                ...state,
                loading: false,
                stores: action.stores
            }
        case MARKET_GET_STORES.MARKET_GET_STORES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return {
                ...state
            }
    }
}

export default Reducer;