//Get
const DEPARTMENT_DATA = {
    DEPARTMENT_DATA_LOAD: "DEPARTMENT_DATA_LOAD",
    DEPARTMENT_DATA_SUCCESS: "DEPARTMENT_DATA_SUCCESS",
    DEPARTMENT_DATA_FAIL: "DEPARTMENT_DATA_FAIL",
};
const DEPARTMENT_GET_API = "/employee/v1/department";

// Get one by id
const DEPARTMENT_GET_DATA = {
    DEPARTMENT_GET_DATA_LOAD: "DEPARTMENT_GET_DATA_LOAD",
    DEPARTMENT_GET_DATA_SUCCESS: "DEPARTMENT_GET_DATA_SUCCESS",
    DEPARTMENT_GET_DATA_FAIL: "DEPARTMENT_GET_DATA_FAIL",
};

const DEPARTMENT_GET_DETAIL_API = "/employee/v1/department/";

// Create / Add / Post
const DEPARTMENT_ADD_DATA = {
    DEPARTMENT_ADD_DATA_LOAD: "DEPARTMENT_ADD_DATA_LOAD",
    DEPARTMENT_ADD_DATA_SUCCESS: "DEPARTMENT_ADD_DATA_SUCCESS",
    DEPARTMENT_ADD_DATA_FAIL: "DEPARTMENT_ADD_DATA_FAIL",
};

const DEPARTMENT_POST_API = "/employee/v1/department";

//Update
const DEPARTMENT_UPDATE_DATA = {
  DEPARTMENT_UPDATE_DATA_LOAD: "DEPARTMENT_UPDATE_DATA_UPLOAD",
  DEPARTMENT_UPDATE_DATA_SUCCESS: "DEPARTMENT_UPDATE_DATA_SUCCESS",
  DEPARTMENT_UPDATE_DATA_FAIL: "DEPARTMENT_UPDATE_DATA_FAIL",
};

const DEPARTMENT_PUT_API = "/employee/v1/department";

// Delete
const DEPARTMENT_DELETE_DATA = {
  DEPARTMENT_DELETE_DATA_LOAD: "DEPARTMENT_DELETE_DATA_LOAD",
  DEPARTMENT_DELETE_DATA_SUCCESS: "DEPARTMENT_DELETE_DATA_SUCCESS",
  DEPARTMENT_DELETE_DATA_FAIL: "DEPARTMENT_DELETE_DATA_FAIL",
};

const DEPARTMENT_DELETE_API = "/employee/v1/department";

//Import
const DEPARTMENT_IMPORT_DATA = {
  DEPARTMENT_IMPORT_DATA_LOAD: "DEPARTMENT_IMPORT_DATA_LOAD",
  DEPARTMENT_IMPORT_DATA_SUCCESS: "DEPARTMENT_IMPORT_DATA_SUCCESS",
  DEPARTMENT_IMPORT_DATA_FAIL: "DEPARTMENT_IMPORT_DATA_FAIL",
};

const DEPARTMENT_IMPORT_API = "/upload";

export{
    DEPARTMENT_DATA,
    DEPARTMENT_GET_API,
    DEPARTMENT_GET_DATA,
    DEPARTMENT_GET_DETAIL_API,
    DEPARTMENT_ADD_DATA,
    DEPARTMENT_POST_API,
    DEPARTMENT_UPDATE_DATA,
    DEPARTMENT_PUT_API,
    DEPARTMENT_DELETE_DATA,
    DEPARTMENT_DELETE_API,
    DEPARTMENT_IMPORT_DATA,
    DEPARTMENT_IMPORT_API,
}