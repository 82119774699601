import { DASHBOARD_DATA, DASHBOARD_GET_RANGE, DASHBOARD_GET_STORE, DASHBOARD_GET_STORE_METRICS } from "./Constants";

//For listing
const loadDashboardDataRequest = (company_id) => ({
  type: DASHBOARD_DATA.DASHBOARD_DATA_LOAD,
  company_id,
});
const setDashboardDataSuccess = (dashboardCount) => ({
  type: DASHBOARD_DATA.DASHBOARD_DATA_SUCCESS,
  dashboardCount,
});
const setDashboardDataFail = (error) => ({
  type: DASHBOARD_DATA.DASHBOARD_DATA_FAIL,
  error,
});

const loadDashboardGetRangeDataRequest = (start_date, end_date, company_id) => ({
  type: DASHBOARD_GET_RANGE.DASHBOARD_GET_RANGE_LOAD,
  start_date,
  end_date,
  company_id,
})
const setDashboardGetRangeDataSuccess = (empCount) => ({
  type: DASHBOARD_GET_RANGE.DASHBOARD_GET_RANGE_SUCCESS,
  empCount,
})
const setDashboardGetRangeDataFail = (error) => ({
  type: DASHBOARD_GET_RANGE.DASHBOARD_GET_RANGE_FAIL,
  error,
})

const loadDashboardGetStoreDataRequest = (year, company_id) => ({
  type: DASHBOARD_GET_STORE.DASHBOARD_GET_STORE_LOAD,
  year,
  company_id,
})
const setDashboardGetStoreDataSuccess = (storeCount) => ({
  type: DASHBOARD_GET_STORE.DASHBOARD_GET_STORE_SUCCESS,
  storeCount,
})
const setDashboardGetStoreDataFail = (error) => ({
  type: DASHBOARD_GET_STORE.DASHBOARD_GET_STORE_FAIL,
  error,
})
const loadDashboardGetStoreMetrics = (company_id) => ({
  type: DASHBOARD_GET_STORE_METRICS.DASHBOARD_GET_STORE_METRICS_LOAD,
  company_id
})
const setDashboardGetStoreMetricsSuccess = (storeMetrics) => ({
  type: DASHBOARD_GET_STORE_METRICS.DASHBOARD_GET_STORE_METRICS_SUCCESS,
  storeMetrics
})
const setDashboardGetStoreMetricsFail = (error) => ({
  error
})


export {
  loadDashboardDataRequest, setDashboardDataSuccess, setDashboardDataFail,
  loadDashboardGetRangeDataRequest, setDashboardGetRangeDataSuccess, setDashboardGetRangeDataFail,
  loadDashboardGetStoreDataRequest, setDashboardGetStoreDataSuccess, setDashboardGetStoreDataFail,
  loadDashboardGetStoreMetrics, setDashboardGetStoreMetricsSuccess, setDashboardGetStoreMetricsFail,
};
