import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { BACKTOLIST, STORES } from "../../../shared/constants/Constants";
import StoreForm from "./StoreForm";
import { loadStoreAddDataRequest } from "../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";

const StoresAdd = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let [submitted, setSubmitted] = useState(false);
  let [errorMessage, setErrorMessage] = useState(false);
  
  let { loading, error } = useSelector(state => state.store)

  // After submition, the post request is done loading, and there's no errors
	useEffect(() => {
		if (submitted && !loading) {
			if (!error) {
				setSubmitted(false)
        navigate('/stores')
			} else {
				setErrorMessage(error?.msg?.toString());
			}
			setSubmitted(true)
		}
	}, [loading, error, submitted])

  function submit(values) {
    let store_status;
    if (values.acquisition_date != null) { 
      if (new Date(values.acquisition_date) > new Date()) {
        store_status = "future";
      }    
      if (new Date(values.acquisition_date) < new Date()) {
        store_status = "active";
      }
    }
    dispatch(loadStoreAddDataRequest({
        ...values,
        store_status: store_status,
    }))
    setSubmitted(true);
  }

  // if (submitted && !loading) {
  //   setSubmitted(false)
  // }

  return (
    <>
      <FormError> 
			{errorMessage}
		  </FormError>
      <Row>
        <Col>
          <Breadcrumbs
            children={[
              { href: '/stores', label: STORES },
              { label: "Create" },
            ]}
          />
        </Col>
        <Col>
          <div className="float-end">
            <DefaultButton
              buttonClass={"default-btn-color"}
              buttonTitle={BACKTOLIST}
              actionBlock={() => {
                navigate("/stores");
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <StoreForm submit={submit} />
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default StoresAdd;
