import {
  STORE_ADD_DATA,
  STORE_DATA,
  STORE_DELETE_DATA,
  STORE_GET_DATA,
  STORE_IMPORT_DATA,
  STORE_UPDATE_DATA,
  STORE_SAP_TRACKER_ADD,
  STORE_SAP_TRACKER_UPDATE,
  STORE_SAP_TRACKER_DELETE,
  STORE_DENSITY_TRACKER_ADD,
  STORE_DENSITY_TRACKER_UPDATE,
  STORE_DENSITY_TRACKER_DELETE,
  STORE_COMM_TRACKER_ADD,
  STORE_COMM_TRACKER_UPDATE,
  STORE_COMM_TRACKER_DELETE,
  STORE_CLASSIFICATION_TRACKER_ADD,
  STORE_CLASSIFICATION_TRACKER_UPDATE,
  STORE_CLASSIFICATION_TRACKER_DELETE,
  STORE_TRACKER_GET_ALL_DATA,
  STORE_TRACKER_IMPORT_DATA,
  DENSITYCLASS_TRACKER_GET_ALL_DATA,
  COMMCOMP_TRACKER_GET_ALL_DATA,
  SCLASSIFICATION_TRACKER_GET_ALL_DATA,
} from "./Constants";

const initialState = {
  loading: false,
  error: null,
  stores: [],
  store: {},
  sap_tracker:[],
  density_tracker:[],
  comm_comp_tracker:[],
  store_class_tracker:[],
  manager_history_tracker:[],
  trackers:[],
  importTrackerData:[]
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_DATA.STORE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        stores: [],
        errors: null,
      };
    case STORE_DATA.STORE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: action.stores,
      };
    case STORE_DATA.STORE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        stores: [],
      };

    case STORE_GET_DATA.STORE_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        store: {},
        sap_tracker:[],
        density_tracker: [],
        comm_comp_tracker:[],
        store_class_tracker:[],
        manager_history_tracker:[],
        error: null,
      };
    case STORE_GET_DATA.STORE_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        store: action.store,
        sap_tracker:action.sap_tracker,
        density_tracker: action.density_tracker,
        comm_comp_tracker:action.comm_comp_tracker,
        store_class_tracker:action.store_class_tracker,
        manager_history_tracker:action.manager_history,
        error: null,
      };
    case STORE_GET_DATA.STORE_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        store: {},
      };

    case STORE_ADD_DATA.STORE_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        store: {},
      };
    case STORE_ADD_DATA.STORE_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        store: action.store,
        error: null,
      };
    case STORE_ADD_DATA.STORE_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        store: {},
      };

    case STORE_UPDATE_DATA.STORE_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        store: {},
      };
    case STORE_UPDATE_DATA.STORE_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        store: action.store,
        error: null,
      };
    case STORE_UPDATE_DATA.STORE_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        store: {},
      };

    case STORE_DELETE_DATA.STORE_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        store: {},
      };
    case STORE_DELETE_DATA.STORE_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        store: action.store,
        error: null,
      };
    case STORE_DELETE_DATA.STORE_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        store: {},
      };

    case STORE_IMPORT_DATA.STORE_IMPORT_DATA_LOAD:
      return {
        ...state,
        loading: true,
        store: [],
        error: null,
      };
    case STORE_IMPORT_DATA.STORE_IMPORT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: action.stores,
        error: null,
      };
    case STORE_IMPORT_DATA.STORE_IMPORT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        stores: [],
      };

    case STORE_SAP_TRACKER_ADD.STORE_SAP_TRACKER_ADD_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_SAP_TRACKER_ADD.STORE_SAP_TRACKER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_SAP_TRACKER_ADD.STORE_SAP_TRACKER_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_SAP_TRACKER_UPDATE.STORE_SAP_TRACKER_UPDATE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_SAP_TRACKER_UPDATE.STORE_SAP_TRACKER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_SAP_TRACKER_UPDATE.STORE_SAP_TRACKER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_SAP_TRACKER_DELETE.STORE_SAP_TRACKER_DELETE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_SAP_TRACKER_DELETE.STORE_SAP_TRACKER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_SAP_TRACKER_DELETE.STORE_SAP_TRACKER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_DENSITY_TRACKER_ADD.STORE_DENSITY_TRACKER_ADD_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_DENSITY_TRACKER_ADD.STORE_DENSITY_TRACKER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_DENSITY_TRACKER_ADD.STORE_DENSITY_TRACKER_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_DENSITY_TRACKER_UPDATE.STORE_DENSITY_TRACKER_UPDATE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_DENSITY_TRACKER_UPDATE.STORE_DENSITY_TRACKER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_DENSITY_TRACKER_UPDATE.STORE_DENSITY_TRACKER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_DENSITY_TRACKER_DELETE.STORE_DENSITY_TRACKER_DELETE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_DENSITY_TRACKER_DELETE.STORE_DENSITY_TRACKER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_DENSITY_TRACKER_DELETE.STORE_DENSITY_TRACKER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    
    case STORE_COMM_TRACKER_ADD.STORE_COMM_TRACKER_ADD_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_COMM_TRACKER_ADD.STORE_COMM_TRACKER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_COMM_TRACKER_ADD.STORE_COMM_TRACKER_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_COMM_TRACKER_UPDATE.STORE_COMM_TRACKER_UPDATE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_COMM_TRACKER_UPDATE.STORE_COMM_TRACKER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_COMM_TRACKER_UPDATE.STORE_COMM_TRACKER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_COMM_TRACKER_DELETE.STORE_COMM_TRACKER_DELETE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_COMM_TRACKER_DELETE.STORE_COMM_TRACKER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_COMM_TRACKER_DELETE.STORE_COMM_TRACKER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_CLASSIFICATION_TRACKER_ADD.STORE_CLASSIFICATION_TRACKER_ADD_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_CLASSIFICATION_TRACKER_ADD.STORE_CLASSIFICATION_TRACKER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_CLASSIFICATION_TRACKER_ADD.STORE_CLASSIFICATION_TRACKER_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_CLASSIFICATION_TRACKER_UPDATE.STORE_CLASSIFICATION_TRACKER_UPDATE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_CLASSIFICATION_TRACKER_UPDATE.STORE_CLASSIFICATION_TRACKER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_CLASSIFICATION_TRACKER_UPDATE.STORE_CLASSIFICATION_TRACKER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case STORE_CLASSIFICATION_TRACKER_DELETE.STORE_CLASSIFICATION_TRACKER_DELETE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
        sapTrackers: []
      }
    case STORE_CLASSIFICATION_TRACKER_DELETE.STORE_CLASSIFICATION_TRACKER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        sapTrackers: action.trackers
      }
    case STORE_CLASSIFICATION_TRACKER_DELETE.STORE_CLASSIFICATION_TRACKER_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    //Get all 
    case STORE_TRACKER_GET_ALL_DATA.STORE_TRACKER_GET_ALL_DATA_LOAD:
      return {
          ...state,
          loading: true,
          error: null,
          trackers: []
      }
    case STORE_TRACKER_GET_ALL_DATA.STORE_TRACKER_GET_ALL_DATA_SUCCESS:
      return {
          ...state,
          loading: false,
          trackers: action.trackers,
          error: null
      }
    case STORE_TRACKER_GET_ALL_DATA.STORE_TRACKER_GET_ALL_DATA_FAIL:
      return {
          ...state,
          loading: false,
          error: action.error,
          trackers: []
      }
    //Import Tracker
    case STORE_TRACKER_IMPORT_DATA.STORE_TRACKER_IMPORT_DATA_LOAD:
      return {
          ...state,
          loading:true,
          error:null,
          importTrackerData:[]
      }
    case STORE_TRACKER_IMPORT_DATA.STORE_TRACKER_IMPORT_DATA_SUCCESS:
      return {
          ...state,
          loading:false,
          importTrackerData:action.importTrackerData,
          error:null
      }
    case STORE_TRACKER_IMPORT_DATA.STORE_TRACKER_IMPORT_DATA_FAIL:
      return {
          ...state,
          loading:false,
          error:action.error,
          importTrackerData:[]
      }
    //Get all Density tracker
    case DENSITYCLASS_TRACKER_GET_ALL_DATA.DENSITYCLASS_TRACKER_GET_ALL_DATA_LOAD:
      return {
          ...state,
          loading: true,
          error: null,
          trackers: []
      }
    case DENSITYCLASS_TRACKER_GET_ALL_DATA.DENSITYCLASS_TRACKER_GET_ALL_DATA_SUCCESS:
      return {
          ...state,
          loading: false,
          trackers: action.trackers,
          error: null
      }
    case DENSITYCLASS_TRACKER_GET_ALL_DATA.DENSITYCLASS_TRACKER_GET_ALL_DATA_FAIL:
      return {
          ...state,
          loading: false,
          error: action.error,
          trackers: []
      }
    //Get all Comm Comp tracker
    case COMMCOMP_TRACKER_GET_ALL_DATA.COMMCOMP_TRACKER_GET_ALL_DATA_LOAD:
      return {
          ...state,
          loading: true,
          error: null,
          trackers: []
      }
    case COMMCOMP_TRACKER_GET_ALL_DATA.COMMCOMP_TRACKER_GET_ALL_DATA_SUCCESS:
      return {
          ...state,
          loading: false,
          trackers: action.trackers,
          error: null
      }
    case COMMCOMP_TRACKER_GET_ALL_DATA.COMMCOMP_TRACKER_GET_ALL_DATA_FAIL:
      return {
          ...state,
          loading: false,
          error: action.error,
          trackers: []
      }
    //Get all Store Classification tracker
    case SCLASSIFICATION_TRACKER_GET_ALL_DATA.SCLASSIFICATION_TRACKER_GET_ALL_DATA_LOAD:
      return {
          ...state,
          loading: true,
          error: null,
          trackers: []
      }
    case SCLASSIFICATION_TRACKER_GET_ALL_DATA.SCLASSIFICATION_TRACKER_GET_ALL_DATA_SUCCESS:
      return {
          ...state,
          loading: false,
          trackers: action.trackers,
          error: null
      }
    case SCLASSIFICATION_TRACKER_GET_ALL_DATA.SCLASSIFICATION_TRACKER_GET_ALL_DATA_FAIL:
      return {
          ...state,
          loading: false,
          error: action.error,
          trackers: []
      }
    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
