import { DEPARTMENT_DATA, DEPARTMENT_GET_DATA, DEPARTMENT_ADD_DATA, DEPARTMENT_UPDATE_DATA, DEPARTMENT_DELETE_DATA, DEPARTMENT_IMPORT_DATA, } from './Constants'
const initialState = {
    loading: false,
    error: null,
    departments: [],
    department:{}
}
const Reducer = (state = initialState, action) => {
    switch (action.type) {
        //load all
        case DEPARTMENT_DATA.DEPARTMENT_DATA_LOAD:
            return {
                ...state,
                loading: true,
                departments:[],
                error:null
            }
        case DEPARTMENT_DATA.DEPARTMENT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error:null,
                departments:action.departments
            }
        case DEPARTMENT_DATA.DEPARTMENT_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error:action.error,
                departments:[]
            }
        //load single
        case DEPARTMENT_GET_DATA.DEPARTMENT_GET_DATA_LOAD:
            return {
                ...state,
                loading:true,
                department:{},
                error:null
            }                

        case DEPARTMENT_GET_DATA.DEPARTMENT_GET_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                department:action.department,
                error:null
            }                

        case DEPARTMENT_GET_DATA.DEPARTMENT_GET_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                department:{}
            }
        case DEPARTMENT_ADD_DATA.DEPARTMENT_ADD_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case DEPARTMENT_ADD_DATA.DEPARTMENT_ADD_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                error:null,
                department:action.department
            }
        case DEPARTMENT_ADD_DATA.DEPARTMENT_ADD_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error,
                department:{}
            }
        case DEPARTMENT_UPDATE_DATA.DEPARTMENT_UPDATE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case DEPARTMENT_UPDATE_DATA.DEPARTMENT_UPDATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                error:null,
            }
        case DEPARTMENT_UPDATE_DATA.DEPARTMENT_UPDATE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case DEPARTMENT_DELETE_DATA.DEPARTMENT_DELETE_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case DEPARTMENT_DELETE_DATA.DEPARTMENT_DELETE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
            }
        case DEPARTMENT_DELETE_DATA.DEPARTMENT_DELETE_DATA_FAIL:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        case DEPARTMENT_IMPORT_DATA.DEPARTMENT_IMPORT_DATA_LOAD:
            return {
                ...state,
                loading:true,
                error:null
            }
        case DEPARTMENT_IMPORT_DATA.DEPARTMENT_IMPORT_DATA_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case DEPARTMENT_IMPORT_DATA.DEPARTMENT_IMPORT_DATA_:
            return {
                ...state,
                loading:false,
                error:action.error
            }
        default:
            return {
                ...state
            }
    }
}
export default Reducer