import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { BACKTOLIST, CREATE, ROLE_MODULE_MAPPING } from "../../../shared/constants/Constants";
import { loadRoleModulesAddDataRequest, loadRoleModulesGetDataRequest } from "../redux/Actions";
import { RoleModuleMappingForm } from "./RoleModuleMappingForm";

export const RoleModuleMappingCreatePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submit = (values) => {
        let request = {
            roles: {
                role_name: values.role_name,
                group_id: Number(values.group_id),
            },
            module_metadata: values.modules.map((module) => {
                return module.module_metadata_id
            }),
            mapping: values.modules.map((module) => {
                delete module.module_name
                return module
            })
        }
        dispatch(loadRoleModulesAddDataRequest(request))
        navigate("/role-module-mapping");
    }

    const cancel = () => {
        navigate("/role-module-mapping")
    }

    let { roleModules, roleModule } = useSelector((state) => state.roleModules);
    let [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        setSelectedRoleModule({
            data: roleModule.data,
        })
    }, [roleModule])

    useEffect(() => {
        if (selectedId) {
            dispatch(loadRoleModulesGetDataRequest(selectedId));
        } else {
            setSelectedRoleModule(null)
        }
    }, [selectedId])

    let [selectedRoleModule, setSelectedRoleModule] = useState(null)

    return (
        <>
            <Row>
                <Col xl={4} xs={12}>
                    <Breadcrumbs
                        children={[
                            { href: '/role-module-mapping', label: ROLE_MODULE_MAPPING },
                            { label: CREATE },
                        ]}
                    />
                </Col>
                <Col xl={8} xs={12}>
                    <div className="float-end">
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={BACKTOLIST}
                            actionBlock={() => {
                                navigate("/role-module-mapping")
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col xl={4} xs={12}>
                                Inherit from:
                                <ReactSelect
                                    onChange={(value) => { setSelectedId(value?.value) }}
                                    options={roleModules.map((roleModule) => {
                                        return { value: roleModule.role_id, label: roleModule.role_name }
                                    })}
                                    isClearable
                                />
                            </Col>
                        </Row><br />
                        <RoleModuleMappingForm roleModule={selectedRoleModule} submit={submit} cancel={cancel} />
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
}