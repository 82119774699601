import { takeLatest, put, call } from "redux-saga/effects";
import {
    EMPLOYEE_ROLE_DATA, EMPLOYEE_ROLE_GET_API,
    EMPLOYEE_ROLE_GET_DATA, EMPLOYEE_ROLE_GET_DETAIL_API,
    EMPLOYEE_ROLE_ADD_DATA, EMPLOYEE_ROLE_POST_API,
    EMPLOYEE_ROLE_UPDATE_DATA, EMPLOYEE_ROLE_PUT_API,
    EMPLOYEE_ROLE_DELETE_DATA, EMPLOYEE_ROLE_DELETE_API,
} from './Constants'
import {
    setEmployeeRolesDataSuccess, setEmployeeRolesDataFail,
    setEmployeeRolesGetDataSuccess, setEmployeeRolesGetDataFail,
    setEmployeeRolesAddDataSuccess, setEmployeeRolesAddDataFail,
    setEmployeeRolesUpdateDataSuccess, setEmployeeRolesUpdateDataFail,
    setEmployeeRolesDeleteDataSuccess, setEmployeeRolesDeleteDataFail,
} from './Actions'
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";
import { getRequest,getDetailRequest, postRequest, putRequest, deleteRequest } from "../../../shared/components/Service";

function* getAllEmployeeRolesDataService() {
    try {
        const response = yield call(getRequest, EMPLOYEE_ROLE_GET_API);
        if (response.status === 200) {
            const data = response.data;
            yield put(setEmployeeRolesDataSuccess(data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeRolesDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee role mapping"))
        yield put(setEmployeeRolesDataFail(error));
    }
}

function* getEmployeeRolesDataService({ id }) {
    try {
        const response = yield call(
            getDetailRequest,
            EMPLOYEE_ROLE_GET_DETAIL_API + id
        );
        if (response.status === 200) {
            const newData = response.data;
            yield put(setEmployeeRolesGetDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeRolesGetDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error retrieving employee roles mapping info"))
        yield put(setEmployeeRolesGetDataFail(error));
    }
}

function* postEmployeeRolesDataService({ request }) {
    try {
        const response = yield call(postRequest, EMPLOYEE_ROLE_POST_API, request);
        if (response.status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("Employee roles mapping created"))
            yield put(setEmployeeRolesAddDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeRolesAddDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error adding employee roles mapping"))
        yield put(setEmployeeRolesAddDataFail(error));
    }
}
function* putEmployeeRolesDataService({ request }) {
    try {
        const response = yield call(putRequest, EMPLOYEE_ROLE_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("Role updated"))
            yield put(setEmployeeRolesUpdateDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeRolesUpdateDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Error editing employee roles mapping"))
        yield put(setEmployeeRolesUpdateDataFail(error));
    }
}

function* deleteEmployeeRolesDataService({ request }) {
    try {
        const response = yield call(deleteRequest, EMPLOYEE_ROLE_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert(response.data.msg))
            yield put(setEmployeeRolesDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setEmployeeRolesDeleteDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert(error))
        yield put(setEmployeeRolesDeleteDataFail(error));
    }
}


export function* watchEmployeeRoles(){
    yield takeLatest(EMPLOYEE_ROLE_DATA.EMPLOYEE_ROLE_DATA_LOAD, getAllEmployeeRolesDataService);
    yield takeLatest(EMPLOYEE_ROLE_GET_DATA.EMPLOYEE_ROLE_GET_DATA_LOAD, getEmployeeRolesDataService);
    yield takeLatest(EMPLOYEE_ROLE_ADD_DATA.EMPLOYEE_ROLE_ADD_DATA_LOAD, postEmployeeRolesDataService);
    yield takeLatest(EMPLOYEE_ROLE_UPDATE_DATA.EMPLOYEE_ROLE_UPDATE_DATA_LOAD, putEmployeeRolesDataService);
    yield takeLatest(EMPLOYEE_ROLE_DELETE_DATA.EMPLOYEE_ROLE_DELETE_DATA_LOAD, deleteEmployeeRolesDataService); 
}