import {
  TSHIRT_DATA,
  TSHIRT_ADD_DATA,
  TSHIRT_GET_DATA,
  TSHIRT_UPDATE_DATA
} from "./Constants";

//For Listing
const loadTShirtDataRequest = () => ({
  type: TSHIRT_DATA.TSHIRT_DATA_LOAD,
});

const setTShirtDataSuccess = (empAdds) => ({
  type: TSHIRT_DATA.TSHIRT_DATA_SUCCESS,
  empAdds,
});

const setTShirtDataFail = (error) => ({
  type: TSHIRT_DATA.TSHIRT_DATA_FAIL,
  error,
});

//For Add TShirt
const loadTShirtAddDataRequest = (request) => ({
  type: TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_LOAD,
  request,
});

const setTShirtAddDataSuccess = (empAdd) => ({
  type: TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_SUCCESS,
  empAdd,
});

const loadTShirtAddReset = () => ({
  type: TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_RESET,
});

const setTShirtAddDataFail = (error) => ({
  type: TSHIRT_ADD_DATA.TSHIRT_ADD_DATA_FAIL,
  error,
});

//For Edit
const loadTShirtUpdateDataRequest = (request) => ({
  type: TSHIRT_UPDATE_DATA.TSHIRT_UPDATE_DATA_LOAD,
  request,
});

const setTShirtUpdateDataSuccess = (empAdd) => ({
  type: TSHIRT_UPDATE_DATA.TSHIRT_UPDATE_DATA_SUCCESS,
  empAdd,
});

const setTShirtUpdateDataFail = (error) => ({
  type: TSHIRT_UPDATE_DATA.TSHIRT_UPDATE_DATA_FAIL,
  error,
});

//To Get TShirt Details
const loadTShirtGetDataRequest = (id) => ({
  type: TSHIRT_GET_DATA.TSHIRT_GET_DATA_LOAD,
  id,
});

const setTShirtGetDataSuccess = (empAdd) => ({
  type: TSHIRT_GET_DATA.TSHIRT_GET_DATA_SUCCESS,
  empAdd,
});

const setTShirtGetDataFail = (error) => ({
  type: TSHIRT_GET_DATA.TSHIRT_GET_DATA_FAIL,
  error,
});


export {
  loadTShirtDataRequest,
  setTShirtDataSuccess,
  setTShirtDataFail,
  loadTShirtAddDataRequest,
  setTShirtAddDataSuccess,
  setTShirtAddDataFail,
  loadTShirtGetDataRequest,
  setTShirtGetDataSuccess,
  setTShirtGetDataFail,
  loadTShirtUpdateDataRequest,
  setTShirtUpdateDataSuccess,
  setTShirtUpdateDataFail
  // loadTShirtAddReset,
};
