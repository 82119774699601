import { useEffect, useState } from "react";

/**
 * This function is necessary to update the list of options in the select dropdown filter
 * @param {*} data The data that should be passed to the select field
 * @returns A function to pass to the 'dropdownFunction' field of the select column definition
 */
export default function (data) {
    // console.log("DropDown", data)
    let [callbackFunc, setCallbackFunc] = useState(() => () => { })
    useEffect(() => {
        callbackFunc(data);
    }, [data, callbackFunc]);
    return setCallbackFunc
}