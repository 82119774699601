import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import { QUEUE_TABLE } from '../../../shared/constants/Constants'
import ListView from '../../../shared/components/listview/ListView'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { loadQueueTableDataRequest, loadQTUpdateDataRequest } from '../redux/Actions'
import Modalpopup from '../../../shared/components/Modalpopup'
import QTUpdate from './QTUpdate'
import Permissions from '../../../shared/components/Permissions'

const QueueTable = () => {

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Queue Table";
    let permission = Permissions(emp_no, module_name); 
    let actionsPermission = !(permission.write_permission); 

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true,
            sort: 'desc'
        },
        {
            display_name: "Actions",
            field: 'actions',
            type: 'actions',
            hide: actionsPermission,
            width: 90
        },
        {
            display_name:"View",
            field: "hyperlink",
            type:"hyperlink",
            is_workflow_link:true,
            redirect_value:"reference_id",
            width: 90
        },
        {
            display_name: 'Updated Employee Number',
            field: 'updated_employee_no',
            type: 'text',
        },
        {
            display_name: 'Command',
            field: 'command',
            type: 'text'
        }, {
            display_name: 'Object Type',
            field: 'object_type',
            type: 'text'
        }, {
            display_name: 'Reference Id',
            field: 'reference_id',
            type: 'text',
        }, {
            display_name: 'Event Date',
            field: 'event_date',
            type: 'date',
        }, {
            display_name: 'Updated Date',
            field: 'updated_date',
            type: 'date'
        }, {
            display_name: 'Log Message',
            field: 'log_message',
            type: 'text'
        }, {
            display_name: 'Log Category',
            field: 'log_category',
            type: 'text'
        }, {
            display_name: 'Status',
            field: 'status',
            type: 'text'
        }, {
            display_name: 'Priority',
            field: 'priority',
            type: 'text'
        }
    ];

    let dispatch = useDispatch();
    let { loading, queue_data } = useSelector((state) => state.queueTable);    
    
    useEffect(() => {
        dispatch(loadQueueTableDataRequest());
    }, [dispatch])

    let [submitted, setSubmitted] = useState(false);
    let [selectedRow, setSelectedRow] = useState({})
    const [showEditModal, setShowEditModal] = useState(false);

    let [clickEditFunc, setClickEditFunc] = useState(null);
    useEffect(() => {
        if (permission.write_permission) {
          setClickEditFunc(
            () => (row) => {
              setSelectedRow(row);
              setShowEditModal(true);
            }
          )
        }
    }, [permission])
    // const clickEdit = (row) => {
    //     setSelectedRow(row)
    //     setShowEditModal(true)
    // }

    //Load Data if it is updated
    useEffect(() => {
        if (submitted && !loading) {
            dispatch(loadQueueTableDataRequest());
            setSubmitted(false);
        }
    },[loading, submitted])

    function submit(values) { 
        dispatch(loadQTUpdateDataRequest(values));
        setSubmitted(true);
        setShowEditModal(false)
    }

    return (
        <>
            <Row>
                <Col>
                    <Breadcrumbs
                        label={"Workflow Queue"}
                        children={[
                            { label: "Workflow Queue" }
                        ]}
                    />
                </Col>
                <Col>
                    {/* <div className="float-end">
                        <DefaultButton
                            buttonClass={"default-btn-color me-2"}
                            buttonTitle={IMPORT}
                            actionBlock={() => { setShowImportModal(true) }}
                        />
                        <DefaultButton buttonClass={'default-btn-color'} buttonTitle={CREATENEW} actionBlock={handleShow}></DefaultButton>
                    </div> */}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle="queue_table"
                            columnNames={columns}
                            rowData={queue_data}
                            clickEdit={clickEditFunc}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                show={showEditModal}
                handleClose={() => { setShowEditModal(false) }}
                actionTitle={"Update Queue Table"}
            >
                <QTUpdate
                    qData={selectedRow}
                    submit={submit}
                    handleClose={() => { setShowEditModal(false) }}
                />
            </Modalpopup>
        </>
    )
}

export default QueueTable
