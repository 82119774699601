import {
    CLASSIFICATION_DATA,
    CLASSIFICATION_ADD_DATA,
    CLASSIFICATION_UPDATE_DATA,
    CLASSIFICATION_GET_DATA,
    CLASSIFICATION_DELETE_DATA,
  } from "./Constants";
  
  const initialState = {
    loading: false,
    error: null,
    classifications: [],
    classification: {},
  };
  
  const Reducer = (state = initialState, action) => {
    switch (action.type) {
      case CLASSIFICATION_DATA.CLASSIFICATION_DATA_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
          classifications: [],
        };
      case CLASSIFICATION_DATA.CLASSIFICATION_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          classifications: action.classifications,
          error: null,
        };
      case CLASSIFICATION_DATA.CLASSIFICATION_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
          classifications: [],
        };

      //Add
      case CLASSIFICATION_ADD_DATA.CLASSIFICATION_ADD_DATA_LOAD:
        return {
          ...state,
          loading: true,
          classification: {},
          error: null,
        };
      case CLASSIFICATION_ADD_DATA.CLASSIFICATION_ADD_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          classification: action.classification,
          error: null,
        };
      case CLASSIFICATION_ADD_DATA.CLASSIFICATION_ADD_DATA_FAIL:
        return {
          ...state,
          loading: false,
          classification: {},
          error: action.error,
        };
      //Get
      case CLASSIFICATION_GET_DATA.CLASSIFICATION_GET_DATA_LOAD:
        return {
          ...state,
          loading: true,
          classification: {},
          error: null,
        };
      case CLASSIFICATION_GET_DATA.CLASSIFICATION_GET_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          classification: action.classification,
          error: null,
        };
      case CLASSIFICATION_GET_DATA.CLASSIFICATION_GET_DATA_FAIL:
        return {
          ...state,
          loading: false,
          classification: {},
          error: action.error,
        };
      //Update
      case CLASSIFICATION_UPDATE_DATA.CLASSIFICATION_UPDATE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          classification: {},
          error: null,
        };
      case CLASSIFICATION_UPDATE_DATA.CLASSIFICATION_UPDATE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          classification: action.classification,
          error: false,
        };
      case CLASSIFICATION_UPDATE_DATA.CLASSIFICATION_UPDATE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          classification: {},
          error: action.error,
        };
      //Delete
      case CLASSIFICATION_DELETE_DATA.CLASSIFICATION_DELETE_DATA_LOAD:
        return {
          ...state,
          loading: true,
          classification: {},
          error: null,
        };
      case CLASSIFICATION_DELETE_DATA.CLASSIFICATION_DELETE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          classification: action.classification,
          error: null,
        };
      case CLASSIFICATION_DELETE_DATA.CLASSIFICATION_DELETE_DATA_FAIL:
        return {
          ...state,
          loading: false,
          classification: {},
          error: action.error,
        };
      default:
        return {
          ...state,
        };
    }
  };
  
  export default Reducer;
  