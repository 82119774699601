export const QUEUE_TABLE_DATA = {
   QUEUE_TABLE_DATA_LOAD: "QUEUE_TABLE_DATA_LOAD",
   QUEUE_TABLE_DATA_SUCCESS: "QUEUE_TABLE_DATA_SUCCESS",
   QUEUE_TABLE_DATA_FAIL: "QUEUE_TABLE_DATA_FAIL"
}

export const QUEUE_UPDATE_TABLE_DATA = {
   QUEUE_UPDATE_TABLE_DATA_LOAD: "QUEUE_UPDATE_TABLE_DATA_LOAD",
   QUEUE_UPDATE_TABLE_DATA_SUCCESS: "QUEUE_UPDATE_TABLE_DATA_SUCCESS",
   QUEUE_UPDATE_TABLE_DATA_FAIL: "QUEUE_UPDATE_TABLE_DATA_FAIL"
}


export const QUEUE_TABLE_API = 'https://0sylzdpaf7.execute-api.us-east-1.amazonaws.com/test/mdstestqueue'
