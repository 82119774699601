const initialState = {
    ids: {}
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "NEXT_PREV_SET":
            let newIds = {
                ...state.ids
            }
            newIds[action.title] = [...new Set(action.ids)];
            return {
                ...state,
                ids: newIds
            }
        default:
            return {
                ...state
            }
    }
}

export default Reducer;