import {
    COMP_GROUP_DATA,
    COMP_GROUP_ADD_DATA,
    COMP_GROUP_UPDATE_DATA,
    COMP_GROUP_GET_DATA,
    COMP_GROUP_DELETE_DATA,
  } from "./Constants";
  
  //For listing
  const loadCompGroupDataRequest = () => ({
    type: COMP_GROUP_DATA.COMP_GROUP_DATA_LOAD,
  });
  const setCompGroupDataSuccess = (compgroups) => ({
    type: COMP_GROUP_DATA.COMP_GROUP_DATA_SUCCESS,
    compgroups,
  });
  const setCompGroupDataFail = (error) => ({
    type: COMP_GROUP_DATA.COMP_GROUP_DATA_FAIL,
    error,
  });
  
  //For add
  const loadCompGroupAddDataRequest = (request) => ({
    type: COMP_GROUP_ADD_DATA.COMP_GROUP_ADD_DATA_LOAD,
    request,
  });
  const setCompGroupAddDataSuccess = (compgroup) => ({
    type: COMP_GROUP_ADD_DATA.COMP_GROUP_ADD_DATA_SUCCESS,
    compgroup,
  });
  
  const setCompGroupAddDataFail = (error) => ({
    type: COMP_GROUP_ADD_DATA.COMP_GROUP_ADD_DATA_FAIL,
    error,
  });
  
  //For Edit
  const loadCompGroupUpdateDataRequest = (request) => ({
    type: COMP_GROUP_UPDATE_DATA.COMP_GROUP_UPDATE_DATA_LOAD,
    request,
  });
  
  const setCompGroupUpdateDataSuccess = (compgroup) => ({
    type: COMP_GROUP_UPDATE_DATA.COMP_GROUP_UPDATE_DATA_SUCCESS,
    compgroup,
  });
  
  const setCompGroupUpdateDataFail = (error) => ({
    type: COMP_GROUP_UPDATE_DATA.COMP_GROUP_UPDATE_DATA_FAIL,
    error,
  });
  
  //To Get
  const loadCompGroupGetDataRequest = (id) => ({
    type: COMP_GROUP_GET_DATA.COMP_GROUP_GET_DATA_LOAD,
    id,
  });
  
  const setCompGroupGetDataSuccess = (compgroup) => ({
    type: COMP_GROUP_GET_DATA.COMP_GROUP_GET_DATA_SUCCESS,
    compgroup,
  });
  
  const setCompGroupGetDataFail = (error) => ({
    type: COMP_GROUP_GET_DATA.COMP_GROUP_GET_DATA_FAIL,
    error,
  });
  
  //Delete
  const loadCompGroupDeleteDataRequest = (request) => ({
    type: COMP_GROUP_DELETE_DATA.COMP_GROUP_DELETE_DATA_LOAD,
    request,
  });
  
  const setCompGroupDeleteDataSuccess = (compgroup) => ({
    type: COMP_GROUP_DELETE_DATA.COMP_GROUP_DELETE_DATA_SUCCESS,
    compgroup,
  });
  
  const setCompGroupDeleteDataFail = (error) => ({
    type: COMP_GROUP_DELETE_DATA.COMP_GROUP_DELETE_DATA_FAIL,
    error,
  });
  
  export {
    loadCompGroupDataRequest,
    setCompGroupDataSuccess,
    setCompGroupDataFail,
    loadCompGroupAddDataRequest,
    setCompGroupAddDataSuccess,
    setCompGroupAddDataFail,
    loadCompGroupUpdateDataRequest,
    setCompGroupUpdateDataSuccess,
    setCompGroupUpdateDataFail,
    loadCompGroupGetDataRequest,
    setCompGroupGetDataSuccess,
    setCompGroupGetDataFail,
    loadCompGroupDeleteDataRequest,
    setCompGroupDeleteDataSuccess,
    setCompGroupDeleteDataFail,
  };
  