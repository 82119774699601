import { takeLatest, put, call } from 'redux-saga/effects'
import {
    MARKET_DATA, MARKET_GET_API,
    MARKET_ADD_DATA, MARKET_POST_API,
    MARKET_GET_DATA, MARKET_GET_DETAIL_API,
    MARKET_UPDATE_DATA, MARKET_PUT_API,
    MARKET_DELETE_DATA, MARKET_DELETE_API,
    MARKET_GET_TRACKER_DATA, MARKET_GET_TRACKER_API,
    MARKET_DELETE_HISTORY, MARKET_DELETE_HISTORY_API,
    MARKET_IMPORT_DATA, MARKET_IMPORT_API,
    MARKET_TRACKER_IMPORT_DATA,
    MARKET_GET_STORES_API,
    MARKET_GET_STORES
} from './Constants';
import {
    setMarketDataSuccess, setMarketDataFail,
    setMarketAddDataSuccess, setMarketAddDataFail,
    setMarketGetDataSuccess, setMarketGetDataFail,
    setMarketUpdateDataSuccess, setMarketUpdateDataFail,
    setMarketTrackerSuccess, setMarketTrackerFail,
    setMarketDeleteDataSuccess, setMarketDeleteDataFail,
    setMarketDeleteHistorySuccess, setMarketDeleteHistoryFail,
    setMarketImportDataSuccess, setMarketImportDataFail,
    setMarketTrackerImportDataSuccess, setMarketTrackerImportDataFail, setMarketGetStoresFail, setMarketGetStoresSuccess
} from './Actions'
import { getRequest, postRequest, putRequest, deleteRequest, postImportRequest } from '../../../shared/components/Service'
import { addErrorAlert, addSuccessAlert } from '../../alert/Actions';

function* getMarketsDataService() {
    try {
        const marketsResponse = yield call(getRequest, MARKET_GET_API);
        const status = marketsResponse.status
        if (status === 200) {
            const data = marketsResponse.data;
            yield put(setMarketDataSuccess(data));
        } else {
            yield put(setMarketDataFail(marketsResponse.response.data))
        }
    } catch (error) {
        yield put(setMarketDataFail(error))
    }
}

function* postMarketDataService({ request }) {
    try {
        let dataRequest = { "market": { "market_name": request.market_name, "ulti_market_code": request.ulti_market_code }, "tracker": { "start_date": request.start_date, "end_date": request.end_date, "region_id": request.region_id } }
        //const response = yield call(postRequest, MARKET_POST_API, request);
        const response = yield call(postRequest, MARKET_POST_API, dataRequest);
        const status = response.status;
        if (status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("District created"))
            yield put(setMarketAddDataSuccess(newData));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setMarketAddDataFail(response.response.data))
        }
    } catch (error) {
        // yield put(addErrorAlert("Could not create district"))
        yield put(setMarketAddDataFail(error))
    }
}

function* getMarketDataService({ id }) {
    try {
        const getMarketDataResponse = yield call(getRequest, MARKET_GET_DETAIL_API + id)
        const status = getMarketDataResponse.status;
        if (status === 200) {
            const newData = getMarketDataResponse.data;
            yield put(setMarketGetDataSuccess(newData));
        } else {
            yield put(setMarketGetDataFail(getMarketDataResponse.response.data));
        }
    } catch (error) {
        yield put(setMarketGetDataFail(error))
    }
}

function* putMarketDataService({ request }) {
    try {
        const response = yield call(putRequest, MARKET_PUT_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("District updated"))
            yield put(setMarketUpdateDataSuccess(response.data));
        } else {
            // yield put(addErrorAlert(response.response.data.msg))
            yield put(setMarketUpdateDataFail(response.response.data))
        }
    } catch (error) {
        // yield put(addErrorAlert("Could not update district"))
        yield put(setMarketUpdateDataFail(error))
    }
}

function* deleteMarketDataService({ request }) {
    try {
        const response = yield call(deleteRequest, MARKET_DELETE_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("District deleted"))
            yield put(setMarketDeleteDataSuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setMarketDeleteDataFail(response.response.data))
        }
    } catch (error) {
        yield put(addErrorAlert("Could not delete district"))
        yield put(setMarketDeleteDataFail(error))
    }
}

function* getMarketTrackerService() {
    try {
        const response = yield call(getRequest, MARKET_GET_TRACKER_API);
        if (response.status === 200) {
            yield put(setMarketTrackerSuccess(response.data))
        } else {
            yield put(setMarketTrackerFail(response.response.data))
        }
    } catch (error) {
        yield put(setMarketTrackerFail(error))
    }
}
function* deleteMarketHistoryService({ request }) {
    try {
        const response = yield call(deleteRequest, MARKET_DELETE_HISTORY_API, request);
        if (response.status === 200) {
            yield put(addSuccessAlert("District tracker deleted"))
            yield put(setMarketDeleteHistorySuccess(response.data));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setMarketDeleteHistoryFail(response.response.data))
        }
    } catch (error) {
        yield put(addErrorAlert("Could not delete district tracker"))
        yield put(setMarketDeleteHistoryFail(error))
    }
}

function* postImportMarketDataService({ request }) {
    try {
        const response = yield call(
            postImportRequest,
            MARKET_IMPORT_API,
            request
        );
        const status = response.status;
        if (status === 200) {
            yield put(addSuccessAlert("Districts imported"))
            const newData = response.data;
            yield put(setMarketImportDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setMarketImportDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Could not import districts"))
        yield put(setMarketImportDataFail(error));
    }
}

function* postImportMarketTrackerDataService({ request }) {
    try {
        const response = yield call(
            postImportRequest,
            MARKET_IMPORT_API,
            request
        );
        const status = response.status;
        if (status === 200) {
            const newData = response.data;
            yield put(addSuccessAlert("District trackers imported"))
            yield put(setMarketTrackerImportDataSuccess(newData));
        } else {
            yield put(addErrorAlert(response.response.data.msg))
            yield put(setMarketTrackerImportDataFail(response.response.data));
        }
    } catch (error) {
        yield put(addErrorAlert("Could not import district trackers"))
        yield put(setMarketTrackerImportDataFail(error));
    }
}

function* getMarketStoresDataService({ id }) {
    try {
        const response = yield call(getRequest, MARKET_GET_STORES_API + id);
        if (response.status === 200) {
            yield put(setMarketGetStoresSuccess(response.data))
        } else {
            yield put(addErrorAlert(response.response.data.msg));
            yield put(setMarketGetStoresFail(response.response.data))
        }
    } catch (error) {
        yield put(addErrorAlert("Could not load market's stores"))
        yield put(setMarketGetStoresFail(error))
    }
}


export function* watchMarket() {
    yield takeLatest(MARKET_DATA.MARKET_DATA_LOAD, getMarketsDataService);
    yield takeLatest(MARKET_ADD_DATA.MARKET_ADD_DATA_LOAD, postMarketDataService);
    yield takeLatest(MARKET_GET_DATA.MARKET_GET_DATA_LOAD, getMarketDataService);
    yield takeLatest(MARKET_UPDATE_DATA.MARKET_UPDATE_DATA_LOAD, putMarketDataService);
    yield takeLatest(MARKET_DELETE_DATA.MARKET_DELETE_DATA_LOAD, deleteMarketDataService);
    yield takeLatest(MARKET_GET_TRACKER_DATA.MARKET_GET_TRACKER_DATA_LOAD, getMarketTrackerService);
    yield takeLatest(MARKET_DELETE_HISTORY.MARKET_DELETE_HISTORY_LOAD, deleteMarketHistoryService);
    yield takeLatest(MARKET_IMPORT_DATA.MARKET_IMPORT_DATA_LOAD, postImportMarketDataService);
    yield takeLatest(MARKET_TRACKER_IMPORT_DATA.MARKET_TRACKER_IMPORT_DATA_LOAD, postImportMarketTrackerDataService);
    //Reload markets after successfully importing
    yield takeLatest(
        MARKET_IMPORT_DATA.MARKET_IMPORT_DATA_SUCCESS,
        getMarketsDataService
    );
    yield takeLatest(
        MARKET_TRACKER_IMPORT_DATA.MARKET_TRACKER_IMPORT_DATA_SUCCESS,
        getMarketTrackerService
    );
    yield takeLatest(MARKET_GET_STORES.MARKET_GET_STORES_LOAD, getMarketStoresDataService);
}