import {
    ROLES_DATA,
    ROLES_GET_DATA,
    ROLES_ADD_DATA,
    ROLES_UPDATE_DATA,
    ROLES_DELETE_DATA,
} from './Constants'


//For listing
const loadRolesDataRequest = () => ({
    type: ROLES_DATA.ROLES_DATA_LOAD,
});
const setRolesDataSuccess = (roles) => ({
    type: ROLES_DATA.ROLES_DATA_SUCCESS,
    roles,
});
const setRolesDataFail = (error) => ({
    type: ROLES_DATA.ROLES_DATA_FAIL,
    error,
});

//for details
const loadRolesGetDataRequest = (id) => ({
    type: ROLES_GET_DATA.ROLES_GET_DATA_LOAD,
    id,
});
const setRolesGetDataSuccess = (response) => ({
    type: ROLES_GET_DATA.ROLES_GET_DATA_SUCCESS,
    roles: response.roles[0],
});
const setRolesGetDataFail = (error) => ({
    type: ROLES_GET_DATA.ROLES_GET_DATA_FAIL,
    error,
});

//For add Roles
const loadRolesAddDataRequest = (request) => ({
    type: ROLES_ADD_DATA.ROLES_ADD_DATA_LOAD,
    request,
});
const setRolesAddDataSuccess = (roles) => ({
    type: ROLES_ADD_DATA.ROLES_ADD_DATA_SUCCESS,
    roles,
});
const setRolesAddDataFail = (error) => ({
    type: ROLES_ADD_DATA.ROLES_ADD_DATA_FAIL,
    error,
});

//update Roles
const loadRolesUpdateDataRequest = (request) => ({
    type: ROLES_UPDATE_DATA.ROLES_UPDATE_DATA_LOAD,
    request,
});
const setRolesUpdateDataSuccess = (roles) => ({
    type: ROLES_UPDATE_DATA.ROLES_UPDATE_DATA_SUCCESS,
    roles,
});
const setRolesUpdateDataFail = (error) => ({
    type: ROLES_UPDATE_DATA.ROLES_UPDATE_DATA_FAIL,
    error,
});

//Delete Roles
const loadRolesDeleteDataRequest = (request) => ({
    type: ROLES_DELETE_DATA.ROLES_DELETE_DATA_LOAD,
    request,
});
const setRolesDeleteDataSuccess = (roles) => ({
    type: ROLES_DELETE_DATA.ROLES_DELETE_DATA_SUCCESS,
    roles,
});
const setRolesDeleteDataFail = (error) => ({
    type: ROLES_DELETE_DATA.ROLES_DELETE_DATA_FAIL,
    error,
});


export {
    loadRolesDataRequest, setRolesDataSuccess, setRolesDataFail,
    loadRolesGetDataRequest, setRolesGetDataSuccess, setRolesGetDataFail,
    loadRolesAddDataRequest, setRolesAddDataSuccess, setRolesAddDataFail,
    loadRolesUpdateDataRequest, setRolesUpdateDataSuccess, setRolesUpdateDataFail,
    loadRolesDeleteDataRequest, setRolesDeleteDataSuccess, setRolesDeleteDataFail,
}