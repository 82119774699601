import { Fragment, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select';
import { getFilterOptions } from "../../tableFunctions";
export function TextFilter({ field, filterType, setFilter, existingFilter }) {
    let [type1, setType1] = useState('contains');
    let [value1, setValue1] = useState(['']);

    let [type2, setType2] = useState('contains');
    let [value2, setValue2] = useState(['']);
    let [shouldShowInput2, setShouldShowInput2] = useState(false);

    let typeOptions = useMemo(() => {
        return getFilterOptions(filterType === 'active' ? 'text' : filterType).map((val) => {
            if (typeof val === 'string') {
                return { value: val, label: val }
            } else {
                return { value: val.displayKey, label: val.displayName }
            }
        });
    }, [getFilterOptions, filterType]);

    useEffect(() => {
        setShouldShowInput2(
            ['blank', 'notBlank'].includes(type1) ||
            value1.filter(v => v !== '').length > 0
        )
    }, [value1, type1])


    useEffect(() => {
        // console.log(existingFilter)
        if (existingFilter?.operator) {
            if (existingFilter.condition1.type !== type1) {
                setType1(existingFilter.condition1.type)
            }
            if (existingFilter.condition2.type !== type1) {
                setType2(existingFilter.condition2.type)
            }

            if (existingFilter.condition1.filter && existingFilter.condition1.filter !== JSON.stringify(value1.filter(v => v !== ''))) {
                setValue1([...JSON.parse(existingFilter.condition1.filter), '']);
            }
            if (existingFilter.condition2.filter && existingFilter.condition2.filter !== JSON.stringify(value1.filter(v => v !== ''))) {
                setValue2([...JSON.parse(existingFilter.condition2.filter), '']);
            }
        } else if (existingFilter) {
            if (existingFilter.type && existingFilter.type !== type1) {
                setType1(existingFilter.type)
            }

            if (existingFilter.filter && existingFilter.filter !== JSON.stringify(value1.filter(v => v !== ''))) {
                setValue1([...JSON.parse(existingFilter.filter), '']);
            }
            // setValue1(existingFilter.filter)
            // setType1((type1) => existingFilter.type ?? type1)
            // setValue2(null)
        }
    }, [existingFilter])
    // useEffect(() => {
    //     console.log(existingFilter)
    // }, [existingFilter])

    useEffect(() => {
        let filter = {};
        if (['blank', 'notBlank'].includes(type1) || value1.filter(v => v !== '').length) {
            if (['blank', 'notBlank'].includes(type2) || value2.filter(v => v !== '').length) {
                filter = {
                    condition1: {
                        filterType,
                        type: type1,
                        filter: JSON.stringify(value1.filter(val => val !== '')),
                    },
                    condition2: {
                        filterType,
                        type: type2,
                        filter: JSON.stringify(value2.filter(val => val !== '')),
                    },
                    filterType,
                    operator: "OR"
                }
            } else {
                filter = {
                    filterType,
                    type: type1,
                    filter: JSON.stringify(value1.filter(val => val !== '')),
                }
            }
        }
        changeParentFilter(filter)
    }, [value1, type1, value2, type2])


    function changeParentFilter(filter) {
        setFilter((oldFilter) => {
            if (JSON.stringify(oldFilter?.[field]) === JSON.stringify(filter)) {
                return oldFilter
            }
            let newFilter = { ...oldFilter }
            newFilter[field] = { ...filter }
            return newFilter;
        })
    }
    return <div>
        <Select
            value={typeOptions.find((v) => v.value === type1) ?? { value: type1, label: type1 }}
            onChange={(event) => {
                setType1(event.value);
            }}
            options={typeOptions} />
        {['contains', 'notContains', 'startsWith', 'endsWith', 'equals', 'notEqual'].includes(type1) ?
            createInputs(value1, setValue1)
            : ''}
        {shouldShowInput2 ? <Fragment>
            <hr></hr>
            <Select
                value={type2 ? { value: type2, label: type2 } : null}
                onChange={(event) => {
                    setType2(event.value);
                }}
                options={getFilterOptions(filterType === 'text' || filterType === 'active' ? 'text' : filterType)?.map((val) => {
                    if (typeof val === 'string') {
                        return { value: val, label: val }
                    } else {
                        return { value: val.displayKey, label: val.displayName }
                    }
                })} />
            {filterType === 'text' && ['contains', 'notContains', 'startsWith', 'endsWith', 'equals', 'notEqual'].includes(type2) ?
                createInputs(value2, setValue2)
                : ''}

        </Fragment> : ""}

    </div>
    function createInputs(values, setValues) {
        return values.map((value, index) =>
            <div className="sidebar-value-input" >
                <input
                    className="form-control"
                    value={value}
                    onChange={(event) => {
                        setValues((arr) => {
                            let arr2 = [...arr]
                            arr2[index] = event.target.value;
                            if (arr2[arr2.length - 1] !== '') {
                                arr2.push('')
                            }
                            for (let [i, input] of Object.entries(arr2)) {
                                if (input === '' && i != arr2.length - 1) {
                                    arr2.splice(i, 1)
                                }
                            }
                            return arr2
                        })
                    }}
                ></input>
                {index < values.length - 1 ?
                    <div className='x-icon-container' onClick={() => {
                        setValues((arr) => {
                            let arr2 = [...arr];
                            arr2.splice(index, 1);
                            return arr2;
                        })
                    }}>
                        <i className='fa fa-x'></i>
                    </div>
                    : ''
                }
            </div>
        )
    }
}
