const DASHBOARD_DATA = {
  DASHBOARD_DATA_LOAD: "DASHBOARD_DATA_LOAD",
  DASHBOARD_DATA_SUCCESS: "DASHBOARD_DATA_SUCCESS",
  DASHBOARD_DATA_FAIL: "DASHBOARD_DATA_FAIL",
};

const DASHBOARD_GET_API = "/dashboard/activecount";

const DASHBOARD_GET_RANGE = {
  DASHBOARD_GET_RANGE_LOAD: "DASHBOARD_GET_RANGE_LOAD",
  DASHBOARD_GET_RANGE_SUCCESS: "DASHBOARD_GET_RANGE_SUCCESS",
  DASHBOARD_GET_RANGE_FAIL: "DASHBOARD_GET_RANGE_FAIL",
}

const DASHBOARD_GET_RANGE_API = "/dashboard/employee";


const DASHBOARD_GET_STORE = {
  DASHBOARD_GET_STORE_LOAD: "DASHBOARD_GET_STORE_LOAD",
  DASHBOARD_GET_STORE_SUCCESS: "DASHBOARD_GET_STORE_SUCCESS",
  DASHBOARD_GET_STORE_FAIL: "DASHBOARD_GET_STORE_FAIL",
}

const DASHBOARD_GET_STORE_API = "/dashboard/closedStore";

const DASHBOARD_GET_STORE_METRICS = {
  DASHBOARD_GET_STORE_METRICS_LOAD: "DASHBOARD_GET_STORE_METRICS_LOAD",
  DASHBOARD_GET_STORE_METRICS_SUCCESS: "DASHBOARD_GET_STORE_METRICS_SUCCESS",
  DASHBOARD_GET_STORE_METRICS_FAIL: "DASHBOARD_GET_STORE_METRICS_FAIL",
}
const DASHBOARD_GET_STORE_METRICS_API = '/dashboard/storeMetrics'

export {
  DASHBOARD_DATA, DASHBOARD_GET_API,
  DASHBOARD_GET_RANGE, DASHBOARD_GET_RANGE_API,
  DASHBOARD_GET_STORE, DASHBOARD_GET_STORE_API,
  DASHBOARD_GET_STORE_METRICS, DASHBOARD_GET_STORE_METRICS_API,
};