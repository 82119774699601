import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../shared/components/FormError";
import { loadEmployeeTypeUpdateDataRequest } from "../redux/Actions";
import EmployeeTypeForm from "./EmployeeTypeForm";

const EmployeeTypeEdit = ({ handleClose, employeeType }) => {
  const dispatch = useDispatch();
  let [shouldCloseModal, setShouldCloseModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState(false);

  let { loading, error } = useSelector((state) => state.employeeType);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true)
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false)
    }
  }, [loading, error, shouldCloseModal])

  function submit(values) {
    dispatch(loadEmployeeTypeUpdateDataRequest(values));
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return (
    <>
      <FormError>
        {errorMessage}
      </FormError>
      <EmployeeTypeForm
        submit={submit}
        cancel={cancel}
        employeeType={employeeType}
      />
      ;
    </>
  );
};

export default EmployeeTypeEdit;
