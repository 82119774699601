import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import ImportModal from "../../../shared/components/ImportModal";
import ListView from "../../../shared/components/listview/ListView";
import Loader from "../../../shared/components/Loader";
import Permissions from "../../../shared/components/Permissions";
import {
  STORETRACKER,
  IMPORTSTORETRACKER,
  IMPORT,
} from "../../../shared/constants/Constants";
import {
  loadGetStoreTrackerDataRequest,
  loadStoreTrackerImportDataRequest,
} from "../redux/Actions";

const StoreTracker = () => {
  let [showImportModal, setShowImportModal] = useState(false);
  let dispatch = useDispatch();
  let { loading, trackers } = useSelector((state) => state.store);

  let columns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true,
    },
    {
      display_name: "SAP",
      field: 'sap',
      type: 'text'
    },
    {
      display_name: "Store Name",
      field: "store_name",
      type: "text",
    },
    {
      display_name: "District Name",
      field: "market_name",
      type: "text",
    },
    {
      display_name: "Start Date",
      field: "start_date",
      type: "date",
    },
    {
      display_name: "End Date",
      field: "end_date",
      type: "date",
    },
  ];

  useEffect(() => {
    dispatch(loadGetStoreTrackerDataRequest());
  }, [dispatch]);

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "SAP Tracker";
  let permission = Permissions(emp_no, module_name);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col className="gx-0">
              <Breadcrumbs
                children={[
                  { label: STORETRACKER },
                ]}
              />
            </Col>
            <Col xs="auto" className="gx-0">
              {permission.create_permission ? (
                  <DefaultButton
                    buttonClass={"default-btn-color me-2"}
                    buttonTitle={IMPORT}
                    actionBlock={() => {
                      setShowImportModal(true);
                    }}
                  />
              ) : (<></>)}
            </Col>
          </Row>
          <Row>
            <Card>
              <Card.Body>
                <ListView
                  exportTitle="Store Tracker"
                  columnNames={columns}
                  rowData={trackers}
                />
              </Card.Body>
            </Card>
          </Row>
          <ImportModal
            show={showImportModal}
            handleClose={() => setShowImportModal(false)}
            tableName={"sap_tracker"}
            modalTitle={IMPORTSTORETRACKER}
            importAction={loadStoreTrackerImportDataRequest}
            loadData={loadGetStoreTrackerDataRequest}
          />
        </>
      )}
    </>
  );
};

export default StoreTracker;
