import { LOGIN_DATA, ROLE_ACCESS_DATA, SET_LOGGED_OUT, TOKEN_DATA } from "./Constants";

let login = {};
try {
  let parsed = JSON.parse(localStorage.getItem('employee'))
  login = parsed;
}catch(e){

}

const initialState = {
  loading: false,
  error: null,
  login,
  isLoggedIn: localStorage.getItem('employee') ? true : false,
  roleAccess: [],
  redirect:false,
  // token: false,
  // tokenValue: {}
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_DATA.LOGIN_DATA_LOAD:
      return {
        ...state,
        loading: true,
        login: {},
        error: null,
        redirect:false,
      };
    case LOGIN_DATA.LOGIN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        login: action.login,
        error: null,
        redirect:false,
        isLoggedIn: true,
      };
    case LOGIN_DATA.LOGIN_DATA_FAIL:
      return {
        ...state,
        loading: false,
        login: {},
        error: action.error,
        redirect:false,
        isLoggedIn: false,
      };
    case LOGIN_DATA.LOGIN_DATA_REDIRECT:
      return {
        ...state,
        loading: false,
        login: {},
        error: action.error,
        redirect:true,
        isLoggedIn: false,
      };
    //set logged out. If jwt expires or logout is otherwise needed
    case SET_LOGGED_OUT:
      return {
        ...state,
        isLoggedIn: false,
        redirect:false,
      }

    //Role Access
    case ROLE_ACCESS_DATA.ROLE_ACCESS_DATA_LOAD:
      return {
        ...state,
        loading: true,
        roleAccess: [],
        error: null,
        redirect:false,
      };
    case ROLE_ACCESS_DATA.ROLE_ACCESS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        roleAccess: action.roleAccess,
        error: null,
        redirect:false,
      };
    case ROLE_ACCESS_DATA.ROLE_ACCESS_DATA_FAIL:
      return {
        ...state,
        loading: false,
        roleAccess: [],
        error: action.error,
        redirect:false,
      };
    // //Token
    // case TOKEN_DATA.TOKEN_DATA_LOAD:
    //   return {
    //     ...state,
    //     loading: true,
    //     token: false,
    //     error: null,
    //     tokenValue: {}
    //   };
    // case TOKEN_DATA.TOKEN_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     tokenValue: action.tokenValue,
    //     error: null,
    //     token: true,
    //   };
    // case TOKEN_DATA.TOKEN_DATA_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error,
    //     token: false,
    //     tokenValue: {}
    //   };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
