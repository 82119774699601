
export const addSuccessAlert = (message) => {
    if (typeof (message) === 'string') {
        return {
            type: 'Alerts/AddAlert',
            alert: {
                alertType: 'success',
                message: message
            }
        }
    }
    return {
        type:null
    }
}

export const addErrorAlert = (message) => {
    if (typeof (message) === 'string') {
        return {
            type: 'Alerts/AddAlert',
            alert: {
                alertType: 'error',
                message: message
            }
        }
    }else{
        return {
            type:null
        }
    }
}
