import {
    JOBTITLE_DATA,
    JOBTITLE_ADD_DATA,
    JOBTITLE_UPDATE_DATA,
    JOBTITLE_GET_DATA,
    JOBTITLE_DELETE_DATA,
    JOBTITLE_IMPORT_DATA
  } from "./Constants";
  
  //For listing
  const loadJobTitlesDataRequest = () => ({
    type: JOBTITLE_DATA.JOBTITLE_DATA_LOAD,
  });
  const setJobTitlesDataSuccess = (jobtitles) => ({
    type: JOBTITLE_DATA.JOBTITLE_DATA_SUCCESS,
    jobtitles,
  });
  const setJobTitlesDataFail = (error) => ({
    type: JOBTITLE_DATA.JOBTITLE_DATA_FAIL,
    error,
  });
  
  //For add
  const loadJobTitlesAddDataRequest = (request) => ({
    type: JOBTITLE_ADD_DATA.JOBTITLE_ADD_DATA_LOAD,
    request,
  });
  const setJobTitlesAddDataSuccess = (jobtitle) => ({
    type: JOBTITLE_ADD_DATA.JOBTITLE_ADD_DATA_SUCCESS,
    jobtitle,
  });
  
  const setJobTitlesAddDataFail = (error) => ({
    type: JOBTITLE_ADD_DATA.JOBTITLE_ADD_DATA_FAIL,
    error,
  });
  
  //For Edit
  const loadJobTitlesUpdateDataRequest = (request) => ({
    type: JOBTITLE_UPDATE_DATA.JOBTITLE_UPDATE_DATA_LOAD,
    request,
  });
  
  const setJobTitlesUpdateDataSuccess = (jobtitle) => ({
    type: JOBTITLE_UPDATE_DATA.JOBTITLE_UPDATE_DATA_SUCCESS,
    jobtitle,
  });
  
  const setJobTitlesUpdateDataFail = (error) => ({
    type: JOBTITLE_UPDATE_DATA.JOBTITLE_UPDATE_DATA_FAIL,
    error,
  });
  
  //To Get
  const loadJobTitlesGetDataRequest = (id) => ({
    type: JOBTITLE_GET_DATA.JOBTITLE_GET_DATA_LOAD,
    id,
  });
  
  const setJobTitlesGetDataSuccess = (jobtitle) => ({
    type: JOBTITLE_GET_DATA.JOBTITLE_GET_DATA_SUCCESS,
    jobtitle,
  });
  
  const setJobTitlesGetDataFail = (error) => ({
    type: JOBTITLE_GET_DATA.JOBTITLE_GET_DATA_FAIL,
    error,
  });
  
  //Delete
  const loadJobTitlesDeleteDataRequest = (request) => ({
    type: JOBTITLE_DELETE_DATA.JOBTITLE_DELETE_DATA_LOAD,
    request,
  });
  
  const setJobTitlesDeleteDataSuccess = (jobtitle) => ({
    type: JOBTITLE_DELETE_DATA.JOBTITLE_DELETE_DATA_SUCCESS,
    jobtitle,
  });
  
  const setJobTitlesDeleteDataFail = (error) => ({
    type: JOBTITLE_DELETE_DATA.JOBTITLE_DELETE_DATA_FAIL,
    error,
  });

//Import 
const loadJobTitlesImportDataRequest = (request) => ({
  type: JOBTITLE_IMPORT_DATA.JOBTITLE_IMPORT_DATA_LOAD,
  request,
});

const setJobTitlesImportDataSuccess = (jobtitles) => ({
  type: JOBTITLE_IMPORT_DATA.JOBTITLE_IMPORT_DATA_SUCCESS,
  jobtitles,
});

const setJobTitlesImportDataFail = (error) => ({
  type: JOBTITLE_IMPORT_DATA.JOBTITLE_IMPORT_DATA_FAIL,
  error,
});

  
  export {
    loadJobTitlesDataRequest,
    setJobTitlesDataSuccess,
    setJobTitlesDataFail,
    loadJobTitlesAddDataRequest,
    setJobTitlesAddDataSuccess,
    setJobTitlesAddDataFail,
    loadJobTitlesUpdateDataRequest,
    setJobTitlesUpdateDataSuccess,
    setJobTitlesUpdateDataFail,
    loadJobTitlesGetDataRequest,
    setJobTitlesGetDataSuccess,
    setJobTitlesGetDataFail,
    loadJobTitlesDeleteDataRequest,
    setJobTitlesDeleteDataSuccess,
    setJobTitlesDeleteDataFail,
    loadJobTitlesImportDataRequest,
    setJobTitlesImportDataSuccess,
    setJobTitlesImportDataFail
  };
  