const STORE_HOURS_DATA = {
  STORE_HOURS_DATA_LOAD: "STORE_HOURS_DATA_LOAD",
  STORE_HOURS_DATA_SUCCESS: "STORE_HOURS_DATA_SUCCESS",
  STORE_HOURS_DATA_FAIL: "STORE_HOURS_DATA_FAIL",
};

const STORE_HOURS_GET_API = "/store/hour";

// Delete
const STORE_HOURS_DELETE_DATA = {
  STORE_HOURS_DELETE_DATA_LOAD: "STORE_HOURS_DELETE_DATA_LOAD",
  STORE_HOURS_DELETE_DATA_SUCCESS: "STORE_HOURS_DELETE_DATA_SUCCESS",
  STORE_HOURS_DELETE_DATA_FAIL: "STORE_HOURS_DELETE_DATA_FAIL",
};

const STORE_HOURS_DELETE_API = "/store/hour";

//Import Store Hours
const STORE_HOURS_IMPORT_DATA = {
  STORE_HOURS_IMPORT_DATA_LOAD: "STORE_HOURS_IMPORT_DATA_LOAD",
  STORE_HOURS_IMPORT_DATA_SUCCESS: "STORE_HOURS_IMPORT_DATA_SUCCESS",
  STORE_HOURS_IMPORT_DATA_FAIL: "STORE_HOURS_IMPORT_DATA_FAIL",
};

const STORE_HOURS_IMPORT_API = "/upload";

export {
  STORE_HOURS_DATA,
  STORE_HOURS_GET_API,
  STORE_HOURS_DELETE_DATA,
  STORE_HOURS_DELETE_API,
  STORE_HOURS_IMPORT_DATA,
  STORE_HOURS_IMPORT_API,
};
