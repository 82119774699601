import { takeLatest, put, call } from "redux-saga/effects";
import { instance } from "../../../shared/utilites/WvgoApiClient";
import { setQuaterlyDataFail, setQuaterlyDataSuccess } from "./Actions";
import { QUATERLY_API, QUATERLY_DATA} from "./Constants";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getQuaterlyDataService({request}) {
    try {
        console.log("request::", request);
        const response = yield call(
            async () => {
                try {
                    
                    const response = await instance.post(QUATERLY_API,request)
                    return response;
                } catch (error) {
                    return error;
                }
            }
        );
        if (response.status === 200) {
            yield put(setQuaterlyDataSuccess(response.data.body));
        } else {
            yield put(addErrorAlert(response.response.data));
            yield put(setQuaterlyDataFail(response.response.data));
        }
    } catch (error) {
        yield put(setQuaterlyDataFail(error));
    }
}



export function* watchQuaterly() {
    yield takeLatest(QUATERLY_DATA.QUATERLY_DATA_LOAD, getQuaterlyDataService);
   
}