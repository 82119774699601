import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import ImportModal from "../../../shared/components/ImportModal";
import ListView from "../../../shared/components/listview/ListView";
import Modalpopup from "../../../shared/components/Modalpopup";
import Permissions from "../../../shared/components/Permissions";
import { CANCEL, DELETE, IMPORT, STOREHOURS, SAVE, REGION, MARKET, DELETE_CONFIRM } from "../../../shared/constants/Constants";
import {
  loadStoreHoursDataRequest,
  loadStoreHoursImportDataRequest,
  loadStoreHoursDeleteDataRequest,
} from "../redux/Actions";

const StoreHours = () => {
  let dispatch = useDispatch();
  let { shours, loading } = useSelector((state) => state.storeHours);
  let [shouldReload,setShouldReload] = useState(true)
  const [showImportModal, setShowImportModal] = useState(false);
  useEffect(() => {
    if (shouldReload && !loading){
      console.log('reload')
      dispatch(loadStoreHoursDataRequest());
      setShouldReload(false)
    }
  }, [dispatch,shouldReload,loading]);

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "Store Hours";
  let permission = Permissions(emp_no, module_name); 
  let actionsPermission = !(permission.write_permission || permission.delete_permission);

  let hoursColumns = [
    {
      display_name:"id",
      field:"id",
      type:"number",
      hide:true
    },
    // {
    //   display_name: "Email",
    //   field: "email",
    //   type: "text",
    // },
    {
      display_name: REGION,
      field: "region_name",
      type: "text",
    },
    {
      display_name:MARKET,
      field:"market_name",
      type:'text'
    },
    {
      display_name: "SAP",
      field: "sap",
      type: "text",
    },
    {
      display_name: "Date Requested",
      field: "date_requested",
      type: "text",
    },
    {
      display_name: "Through Date",
      field: "through_date",
      type: "text",
    },
    {
      display_name: "Sunday Open",
      field: "sunday_open",
      type: "text",
    },
    {
      display_name: "Sunday Close",
      field: "sunday_close",
      type: "text",
    },
    {
      display_name: "Monday Open",
      field: "monday_open",
      type: "text",
    },
    {
      display_name: "Monday Close",
      field: "monday_close",
      type: "text",
    },
    {
      display_name: "Tuesday Open",
      field: "tuesday_open",
      type: "text",
    },
    {
      display_name: "Tuesday Close",
      field: "tuesday_close",
      type: "text",
    },
    {
      display_name: "Wednesday Open",
      field: "wednesday_open",
      type: "text",
    },
    {
      display_name: "Wednesday Close",
      field: "wednesday_close",
      type: "text",
    },
    {
      display_name: "Thursday Open",
      field: "thursday_open",
      type: "text",
    },
    {
      display_name: "Thursday Close",
      field: "thursday_close",
      type: "text",
    },
    {
      display_name: "Friday Open",
      field: "friday_open",
      type: "text",
    },
    {
      display_name: "Friday Close",
      field: "friday_close",
      type: "text",
    },
    {
      display_name: "Comments",
      field: "comments",
      type: "text",
    },
    {
      display_name: "Status",
      field: "status",
      type: "text",
    },
    {
      display_name: "Actions",
      field: "actions",
      hide: actionsPermission
    },
  ];

  let [showDeleteModal, setShowDeleteModal] = useState(false)
  let [selectedId,setSelectedId] = useState(null)
  const handleDelete = () => {
    setShowDeleteModal(false)
    dispatch(loadStoreHoursDeleteDataRequest({
      id:[selectedId]
    }))
    setShouldReload(true)
  }

  return (
    <>
      <Row>
        <Col className="gx-0">
          <Breadcrumbs
            children={[
              {label: STOREHOURS },
            ]}
          />
        </Col>
        <Col xs="auto" className="gx-0">
          {permission.create_permission?(
            <DefaultButton
              buttonClass={"default-btn-color me-2"}
              buttonTitle={IMPORT}
              actionBlock={() => {
                setShowImportModal(true);
              }}
            />
          ):(<></>)}
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <ListView
              exportTitle="Store Hours"
              columnNames={hoursColumns}
              rowData={shours}
              clickDelete={(id)=>{
                setShowDeleteModal(true);
                setSelectedId(id)
              }}
            />
          </Card.Body>
        </Card>
      </Row>
      <ImportModal
        show={showImportModal}
        handleClose={() => setShowImportModal(false)}
        tableName={"store_hours"}
        modalTitle={"Import Store Hours"}
        importAction={loadStoreHoursImportDataRequest}
      />
      <Modalpopup
        show={showDeleteModal}
        handleClose={()=>setShowDeleteModal(false)}
        actionTitle='Delete row?'
      >
        <p>{DELETE_CONFIRM}</p> 
        <div className="col-md-12">
          <DefaultButton
            buttonClass={"btn btn-secondary me-2"}
            buttonTitle={CANCEL}            
            actionBlock={() => { setShowDeleteModal(false) }}
          />
          <DefaultButton
            buttonClass={"btn btn-danger"}
            buttonTitle={DELETE}
            actionBlock={handleDelete}
          />
        </div>
      </Modalpopup>
    </>
  );
};

export default StoreHours;
