import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import DefaultButton from '../../../shared/components/DefaultButton';
import ImportModal from '../../../shared/components/ImportModal';
import ListView from '../../../shared/components/listview/ListView';
import Permissions from '../../../shared/components/Permissions';
import { COMMCOMPTRACKER, IMPORT_COMMCOMPTRACKER, IMPORT } from '../../../shared/constants/Constants'
import { loadGetCommCompTrackerDataRequest, loadStoreTrackerImportDataRequest } from '../redux/Actions'

const CommCompTracker = () => {

    let [showImportModal, setShowImportModal] = useState(false);
    let dispatch = useDispatch();
    let { trackers } = useSelector((state) => state.store);

    let columns = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true
        },
        {
            display_name: "SAP",
            field: 'sap',
            type: 'text'
        },
        {
            display_name: 'Store Name',
            field: 'store_name',
            type: 'text'
        }, {
            display_name: 'Comm Comp Group Name',
            field: 'commission_comp_group_name',
            type: 'text'
        }, {
            display_name: 'Start Date',
            field: 'start_date',
            type: 'date'
        }, {
            display_name: 'End Date',
            field: 'end_date',
            type: 'date'
        }
    ];

    useEffect(() => {
        dispatch(loadGetCommCompTrackerDataRequest());
    }, [dispatch])

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Comm Comp Tracker";
    let permission = Permissions(emp_no, module_name);

    return (
        <>
            <Row>
                <Col className="gx-0">
                    <Breadcrumbs
                        children={[
                            { label: COMMCOMPTRACKER },
                        ]}
                    />
                </Col>
                <Col xs="auto" className="gx-0">
                    {permission.create_permission ? (
                        <DefaultButton
                            buttonClass={"default-btn-color me-2"}
                            buttonTitle={IMPORT}
                            actionBlock={() => { setShowImportModal(true) }}
                        />
                    ) : (<></>)}
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <ListView
                            exportTitle="Comm Comp Tracker"
                            columnNames={columns}
                            rowData={trackers}
                        />
                    </Card.Body>
                </Card>
            </Row>
            <ImportModal
                show={showImportModal}
                handleClose={() => setShowImportModal(false)}
                tableName={"comm_comp_tracker"}
                modalTitle={IMPORT_COMMCOMPTRACKER}
                importAction={loadStoreTrackerImportDataRequest}
                loadData={loadGetCommCompTrackerDataRequest}
            />
        </>
    )
}

export default CommCompTracker
