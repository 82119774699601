import {
    HIRE_TERM_QUEUE_DATA, UPDATE_HIRE_TERM_QUEUE
} from './Constants';


//For listing
export const loadHireTermQueueDataRequest = () => ({
    type: HIRE_TERM_QUEUE_DATA.HIRE_TERM_QUEUE_DATA_LOAD,
});
export const setHireTermQueueDataSuccess = (hireTermQueue) => ({
    type: HIRE_TERM_QUEUE_DATA.HIRE_TERM_QUEUE_DATA_SUCCESS,
    hireTermQueue,
});
export const setHireTermQueueDataFail = (error) => ({
    type: HIRE_TERM_QUEUE_DATA.HIRE_TERM_QUEUE_DATA_FAIL,
    error,
});

export const loadHireTermQueueUpdate = (request) => ({
    type: UPDATE_HIRE_TERM_QUEUE.UPDATE_HIRE_TERM_QUEUE_LOAD,
    request
})
export const setHireTermQueueUpdateSuccess = (response) =>({
    type: UPDATE_HIRE_TERM_QUEUE.UPDATE_HIRE_TERM_QUEUE_SUCCESS,
    response
})
export const setHireTermQueueUpdateFail = (error) => ({
    type: UPDATE_HIRE_TERM_QUEUE.UPDATE_HIRE_TERM_QUEUE_FAIL,
    error
})