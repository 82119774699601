import {
    ODOO_STORE_TRANSFER_TRACKER_DATA,
    ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA,
} from './Constants'

//For listing
export const loadOdooStoreTransferTrackerDataRequest = () => ({
    type: ODOO_STORE_TRANSFER_TRACKER_DATA.ODOO_STORE_TRANSFER_TRACKER_DATA_LOAD,
});
export const setOdooStoreTransferTrackerDataSuccess = (odooStoreTransferTrackers) => ({
    type: ODOO_STORE_TRANSFER_TRACKER_DATA.ODOO_STORE_TRANSFER_TRACKER_DATA_SUCCESS,
    odooStoreTransferTrackers,
})
export const setOdooStoreTransferTrackerDataFail = (error) => ({
    type: ODOO_STORE_TRANSFER_TRACKER_DATA.ODOO_STORE_TRANSFER_TRACKER_DATA_FAIL,
    error,
});

//for details
export const loadOdooStoreTransferTrackerGetDataRequest = (id) => ({
    type: ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA.ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_LOAD,
    id,
});
export const setOdooStoreTransferTrackerGetDataSuccess = (response) => ({
    type: ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA.ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_SUCCESS,
    odooStoreTransferTracker: response,
});
export const setOdooStoreTransferTrackerGetDataFail = (error) => ({
    type: ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA.ODOO_STORE_TRANSFER_TRACKER_DETAILS_DATA_FAIL,
    error,
});
